import { createReducer, on } from '@ngrx/store';
import { FinancialTransactionActions } from './financial-transactions.action-types';
import { updateStoreState } from 'src/app/shared/util/ngrx.util';

export interface State {
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  savingSucceeded: boolean;
  savingMode: 'create' | 'edit';
  savingEntityId: string;
  financialTransactions: any | null;
  lastErrorMessage: any | null;
  lastErrorStatus: any | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  saving: undefined,
  savingSucceeded: undefined,
  savingMode: undefined,
  savingEntityId: undefined,
  financialTransactions: undefined,
  lastErrorMessage: undefined,
  lastErrorStatus: undefined
};

export const _financialTransactionReducer = createReducer(
    initialState,
    on(FinancialTransactionActions.LoadAll, (state, action) => {
        return {
          ...state,
          loading: true
        }
    }),
    on(FinancialTransactionActions.LoadAllSuccess, (state, action) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          financialTransactions: action.financialTransactions
        }
    }),
    on(FinancialTransactionActions.Saving, (state, action) => {
        return {
          ...state,
          saving: true,
          savingSucceeded: undefined,
          savingMode: action.mode,
          savingEntityId: action.entity.id
        }
    }),
    on(FinancialTransactionActions.ReadAnnouncement, (state, action) => {
      return {
        ...state,
        saving: true,
        savingSucceeded: undefined,
        savingEntityId: action.entity.id
      }
  }),
    on(FinancialTransactionActions.SaveSuccess, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: true,
          savingMode: undefined,
          financialTransactions: updateStoreState(state.financialTransactions, action.entity, state.savingMode)
        }
    }),
    on(FinancialTransactionActions.SaveFailed, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: false,
          savingMode: undefined,
          lastErrorMessage: action.error.error,
          lastErrorStatus: action.error.status
        }
    }),
    on(FinancialTransactionActions.Investing, (state, action) => {
        return {
          ...state,
          saving: true,
          savingSucceeded: undefined,
          savingMode: action.mode,
          savingEntityId: action.entity.id
        }
    }),
    on(FinancialTransactionActions.InvestSuccess, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: true,
          savingMode: undefined,
          financialTransactions: updateStoreState(state.financialTransactions, action.entity, state.savingMode)
        }
    }),
    on(FinancialTransactionActions.InvestFailed, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: false,
          savingMode: undefined,
          lastErrorMessage: action.error.error,
          lastErrorStatus: action.error.status
        }
    }),
    on(FinancialTransactionActions.Reset, (state, action) => {
      return initialState;
    })
);

export function financialTransactionReducer(state, action) {
  return _financialTransactionReducer(state, action)
}