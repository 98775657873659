<h1>Editar Factura</h1>

<form [formGroup]="form">
    <app-date-picker [default]="invoice.paymentDate" formControlName="paymentDate" label="Fecha de cobro">
    </app-date-picker>

    <table class="invoice-table">
        <thead>
            <tr>
                <th class="col-invoice">Factura</th>
                <th class="col-amount">Monto total</th>
                <th class="col-net-amount">Monto neto</th>
            </tr>
        </thead>
        <tbody>
            <tr formArrayName="physicalInvoices" *ngFor="let inv of invoice.physicalInvoices;let i=index"
                class="table-row">
                <td class="blue bold">
                    <lib-text *ngIf="inv?.code.length > 12" color="blue" weight="medium" [tooltipText]="inv?.code"
                        tooltip="true" tooltipSize="120" [content]="inv?.code | limitTo: '12'">
                    </lib-text>

                    <lib-text *ngIf="inv?.code.length <= 12" color="blue" weight="medium" [content]="inv?.code">
                    </lib-text>
                </td>
                <td>{{ inv?.totalAmount | numberSpacing}}</td>
                <td [formGroupName]="i">
                    <div class="custom-input-field">
                        <mat-label class="error"
                            *ngIf="!form?.controls?.physicalInvoices?.controls[i]?.get('netAmount')?.valid">
                            {{ getErrorMessage(form?.controls?.physicalInvoices?.controls[i].get('netAmount')?.errors)}}
                        </mat-label>
                        <input class="netAmount" matInput type="text" formControlName="netAmount" label="Monto neto"
                            placeholder="Monto neto" />
                    </div>
                    <!-- [ngClass]="form?.controls?.physicalInvoices?.controls[i]?.get('netAmount')?.valid ? 'netAmount' : 'netAmount-error'" -->

                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="evaluationDays!=null" class="cushion">
        <span>Colchón de días</span>
        <span style="font-weight: 400;" [ngStyle]="{'color': getCushionColor(evaluationDays)}">{{evaluationDays}} {{evaluationDays == '1' ? 'día'
            : 'días'}}</span>
    </div>

    <div *ngIf="showAlertDisclaimer" class="alert-disclaimer" style="margin-top:24px">
        <lib-icon icon="warning" size="s"></lib-icon>
        <span style="margin-left: 16px">El colchón de días es negativo. ¿Estás seguro que deseas
            continuar?</span>
    </div>

    <div style="margin-top: 30px;" class="button-container button-direction-column">
        <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Continuar'" [type]="'secondary'">
        </fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>

<!-- 
                    (focusout)="focusOutRetention($event.target.value, inv)"
                    (keyup.enter)="focusOutRetention($event.target.value, inv)" -->
<!-- (input)="retentionChange($event.target.value,inv)" -->