import { Clipboard } from "@angular/cdk/clipboard";
import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppState } from "src/app/app.states";
import { UsersSelectors } from "src/app/features/users/state/users.selector-types";
import { Roles } from "src/app/shared/enums/Roles.enum";
import { OverlayService } from "src/app/shared/modules/overlay/services/overlay.service";
import { WindowScrollService } from "src/app/shared/services/window-scroll.service";
import { RiskService } from "../../../services/risk.service";
import { EvaluationsActions } from "../../../states/evaluations.action-types";
import { EvaluationSelectors } from "../../../states/evaluations.selector-types";
import { EvaluationDocumentsDialogComponent } from "../../organismos/evaluation-documents-dialog/evaluation-documents-dialog.component";
import { EvaluationItemComponent } from "../../organismos/evaluation-item/evaluation-item.component";
import { FinalizeEvaluationComponent } from "../../organismos/finalize-evaluation/finalize-evaluation.component";
import { RiskCompleteInformationDialogComponent } from "../../organismos/risk-complete-information-dialog/risk-complete-information-dialog.component";
import { UpdatePadronConfirmationScreenComponent } from "../../organismos/update-padron-confirmation-screen/update-padron-confirmation-screen.component";
type NewType = Observable<any>;

@Component({
  selector: "app-evaluation-detail",
  templateUrl: "./evaluation-detail.component.html",
  styleUrls: ["./evaluation-detail.component.scss"],
})
export class EvaluationDetailComponent implements OnInit {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  numberCopied = new BehaviorSubject<any>(false);
  cciCopied = new BehaviorSubject<any>(false);
  selectedEvaluationId;
  selectedEvaluation$: NewType;
  importAverage;
  exportAverage;
  importAverageOps;
  exportAverageOps;
  evaluation;
  lastEvaluation;
  subscription;
  openedModal = false;
  openedModalSector = false;
  loggedInUserRole;
  twoYearsAgo;
  oneYearsAgo;
  saveEvaluation;
  threeYearsAgo;
  fourYearsAgo;
  fiveYearsAgo;
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private clipboard: Clipboard,
    private service: RiskService,
    public dialog: MatDialog,
    public overlayService: OverlayService,
    private store: Store<AppState>,
    private windowScrollService: WindowScrollService
  ) {}

  ngOnInit(): void {
    this.getYears();
    this.route.params.subscribe((params) => {
      this.selectedEvaluationId = params.evaluationId;
      this.fetchEvaluation(this.selectedEvaluationId);
    });

    this.windowScrollService.previousUrl$.subscribe((previousUrl: string) => {
      if (previousUrl) {
        localStorage.setItem("previousURL", previousUrl.split("/")[1]);
        localStorage.setItem("previousURLID", previousUrl.split("/")[2]);
      } else {
        localStorage.setItem("previousURL", "operations");
      }
    });
    this.getRoleUser();

    this.subscription = this.overlayService
      .getCloseEmitter()
      .subscribe((obs) => {
        this.loading = false;

        // open modal evaluation after click cancel on confirmation screen
        if (
          this.loggedInUserRole === Roles.RISK_ANALYST &&
          this.openedModal &&
          !this.openedModalSector
        ) {
          this.evaluate(this.lastEvaluation);
        } else if (
          this.loggedInUserRole === Roles.RISK_ANALYST &&
          this.openedModalSector &&
          !this.openedModal
        ) {
          //console.log("Onhere",  this.loggedInUserRole, this.openedModalSector )
          this.complete(this.lastEvaluation);
        }
      });
  }

  getYears() {
    const fiveYearsAgo = new Date().getFullYear() - 5;
    const fourYearsAgo = new Date().getFullYear() - 4;
    const threeYears = new Date().getFullYear() - 3;
    const twoYears = new Date().getFullYear() - 2;
    const oneYear = new Date().getFullYear() - 1;

    this.fiveYearsAgo = fiveYearsAgo;
    this.fourYearsAgo = fourYearsAgo;
    this.threeYearsAgo = threeYears;
    this.twoYearsAgo = twoYears;
    this.oneYearsAgo = oneYear;
  }

  fetchEvaluation(evaluationId) {
    this.loading = false;
    this.service.byId(evaluationId).subscribe((data: any) => {
      this.evaluation = data;
      this.getAverage();
    });
  }

  getRoleUser() {
    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        //console.log(profile)
        if (profile) {
          this.loggedInUserRole = profile?.role;
        } else {
          this.loggedInUserRole = "";
        }
      });
  }

  reFetchEvaluation() {
    this.fetchEvaluation(this.selectedEvaluationId);
  }

  copyCCI() {
    this.copyToClipBoard(this.evaluation.operationCode);
    this.cciCopied.next(true);
    setTimeout(() => {
      this.cciCopied.next(false);
    }, 1000);
  }

  copyToClipBoard(value) {
    this.clipboard.copy(value);
  }

  getAverage() {
    this.importAverage = this.calculateAverage([
      this.evaluation.additionalInfo[0]?.importsValue,
      this.evaluation.additionalInfo[1].importsValue,
    ]);

    this.exportAverage = this.calculateAverage([
      this.evaluation.additionalInfo[0]?.exportsValue,
      this.evaluation.additionalInfo[1].exportsValue,
    ]);

    this.importAverageOps = this.calculateAverage([
      this.evaluation.additionalInfo[0]?.importsOpsValue,
      this.evaluation.additionalInfo[1].importsOpsValue,
    ]);

    this.exportAverageOps = this.calculateAverage([
      this.evaluation.additionalInfo[0]?.exportsOpsValue,
      this.evaluation.additionalInfo[1].exportsOpsValue,
    ]);
  }

  calculateAverage(items) {
    let sum = items.reduce((acc, item) => parseFloat(acc) + parseFloat(item));
    let average = (sum / items.length).toString();

    if (Math.round(parseFloat(average)) != parseFloat(average)) {
      average = parseFloat(average).toFixed(2);
    }

    return average;
  }

  back(): void {
    this.location.back();
  }

  evaluate(evaluation) {
    let dialogRef = this.openDialogHelper(FinalizeEvaluationComponent, {
      evaluation,
    });
    //pass supplier to confirmation screen to show in the table
    let evaluationOb = {
      supplier: evaluation.supplier,
      createdBy: evaluation.createdBy,
      debtor: evaluation.debtor,
      totalValue: evaluation.totalValue,
      _id: evaluation._id,
      companyDetail: undefined,
      detailUpdatedAt: undefined,
    };

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        if (result.event === "Cancel") {
          this.openedModal = false;
          this.reFetchEvaluation();
          return;
        }

        if (result.companyDetail) {
          evaluationOb = {
            ...evaluationOb,
            debtor: {
              ...evaluation.debtor,
              companyDetail: result.companyDetail,
              detailUpdatedAt: result.detailUpdatedAt,
            },
          };
        }

        //pass evaluation array to confirmation screen to show in the table
        let lastEvaluation = {
          ...evaluationOb,
          ...result,
        };

        //send to BE the evaluation with only properties the BE needs
        let saveEvaluation = {
          addedValue: result.addedValue,
          comment: result.comment,
          creditLine: result.creditLine,
          creditLineType: result.creditLineType,
          rating: result.rating,
          status: result.status,
          totalRating: result.totalRating,
          companyDetail: undefined,
        };
        if (saveEvaluation.comment === "") {
          delete saveEvaluation.comment;
        }
        if (result.status === "approved") {
          saveEvaluation.companyDetail = result.companyDetail;
        }

        this.openedModal = true;
        this.lastEvaluation = lastEvaluation;
        const callbackEmail = () => {
          this.reFetchEvaluation();
          this.resetDialog();
        };
        let id = localStorage.getItem("previousURLID") || null;
        let url =
          localStorage.getItem("previousURL") === "invoices"
            ? `invoices/${id}`
            : `risk-debtors/${id}`;

        this.overlayService.startConfirmation(
          {
            ...saveEvaluation,
            _id: this.selectedEvaluationId,
          },
          {
            component: EvaluationItemComponent,
            entity: [lastEvaluation],
          },
          "details",
          "edit",
          null,
          EvaluationsActions.Saving,
          `${url}`,
          EvaluationSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: "Evaluación realizada",
            },
            onConfirm: {
              title: "¿Estás seguro?",
              message: "Vas a realizar la siguiente evaluación:",
            },
          },
          callbackEmail.bind(this)
        );
      }
    });
  }

  complete(evaluation) {
    //console.log(this.loading)

    if (!this.loading) {
      this.loading = true;
      let dialogRef = this.openDialogHelper(
        RiskCompleteInformationDialogComponent,
        {
          evaluation,
        }
      );
      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          //  console.log(result)
          if (result !== "closed") {
            // if (result.event === 'Cancel') {
            //   this.openedModal = false
            //  // dialogRef= ""
            //   return;
            // }
            //pass evaluation array to confirmation screen to show in the table
            let lastEvaluation = {
              ...evaluation,
              debtor: {
                sector: evaluation?.debtor?.sector,
                activity: result.activity,
              },
              employeeCount: result.employeeCount,
              regimeType: result.regimeType,
              sectorFilled: result.sectorFilled,
            };

            //send to BE the data points with only properties the BE needs
            let saveEvaluation = {
              sector: result.sectorFilled,
              activity: result.activity,
              regimeType: result.regimeType,
              employeeCount: result.employeeCount,
              _id: evaluation._id,
            };

            this.openedModalSector = true;
            this.lastEvaluation = lastEvaluation;
            this.saveEvaluation = saveEvaluation;
            this.getTotalValue();
          } else {
            this.loading = false;
            this.openedModalSector = false;
          }
        }
      });
    }
  }
  getTotalValue() {
    //this.loading = false
    // let body = {
    //   regimeType:  this.saveEvaluation.regimeType,
    //   employeeCount:  this.saveEvaluation.employeeCount,
    //   _id:  this.saveEvaluation._id
    // }
    const callbackEmail = () => {
      this.reFetchEvaluation();
      this.resetDialogSector();
    };

    let dataEvaluation = {
      ...this.lastEvaluation,
      regimeType: this.saveEvaluation.regimeType,
      employeeCount: this.saveEvaluation.employeeCount,
    };

    this.overlayService.startConfirmation(
      {
        ...this.saveEvaluation,
        _id: this.selectedEvaluationId,
      },
      {
        component: UpdatePadronConfirmationScreenComponent,
        entity: dataEvaluation,
      },
      "details",
      "edit",
      null,
      EvaluationsActions.Saving,
      `risk-pending-evaluations/${this.lastEvaluation._id}`,
      EvaluationSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: "Información actualizada",
        },
        onConfirm: {
          title: "¿Estás seguro?",
          message: "Vas a realizar la siguiente evaluación:",
        },
      },
      callbackEmail.bind(this)
    );
    // this.service.getTotalValue(body).subscribe(data => {
    //   if(data){

    //   }
    // })
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      position: {
        left: "0",
      },
      data: data,
      autoFocus: false,
    });

    return dialogRef;
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  resetDialog() {
    this.openedModal = false;
  }

  resetDialogSector() {
    //console.log(this.openedModalSector)
    this.openedModalSector = false;
  }

  ngOndestroy() {
    // console.log("Destroy")
    this.subscription.unsubscribe();
    this.resetDialog();
    this.loggedInUserRole = "";
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openDialogDocument(evaluationRequest) {
    this.reFetchEvaluation();
    let evaluation = this.evaluation;
    let dialogRef = this.openDialogHelper(EvaluationDocumentsDialogComponent, {
      evaluation,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result.event === "Closed") {
        this.reFetchEvaluation();
      }
    });
  }

  getAccountName(ev: any) {
    if (ev?.invoiceNetAmount) {
      return ev?.accountManagerName;
    } else {
      return ev?.createdBy?.names + " " + ev.createdBy?.familyNames;
    }
  }
}
