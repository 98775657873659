<div *ngIf="!newRegistration" class="intercom-success-container">
    <img class="tiny-logo" src="../../../../../../assets/logos/small.png">

    <img *ngIf="showIcon" [src]="getIconClass()" class="img-icon">

    <h1 [class]="getCssClass()">{{getTitle()}}</h1>

    <p class="message" [innerHTML]="messsage" *ngIf="messsage"></p>

    <img *ngIf="!showIcon && mode!='createInvoice'" [src]="getImgSrc()" [class]="getCssClass()">

    <div *ngIf="mode!='createInvoice' && mode!='createInvoiceNew'" class="button-container">
        <fs-ui-button class="back" (click)="done()" [label]="'Regresar'"></fs-ui-button>
    </div>

    <div *ngIf="mode=='createInvoice' && (!profile.whatsappStatus || profile.whatsappStatus=='error')"
        style="display: flex;justify-content: center;">
        <app-ws-widget [state]="getWSWidgetStatus(profile)" (clickButton)="openPhoneModal($event)"
            body="Activa las notificaciones por Whatsapp y entérate en tiempo real sobre lo que falta para financiar tus facturas.">
        </app-ws-widget>
    </div>
    <div *ngIf="mode=='createInvoice'" class="invoice-detail-button" (click)="done()">
        Ver mis operaciones
    </div>

    <div *ngIf="mode=='createInvoiceNew'" class="button-container">
        <fs-ui-button class="back" (click)="done()"
            [label]="success.savingSucceeded ? 'Ver mis operaciones' : 'Regresar'"></fs-ui-button>
    </div>
</div>

<div *ngIf="newRegistration" class="intercom-success-container">
    <img class="tiny-logo-user" src="../../../../../../assets/logos/small.png">

    <p class="title" [class]="getCssClass()">{{getTitle()}}</p>

    <p class="message-register" [innerHTML]="messsage" *ngIf="messsage"></p>

    <div class="information-layout">
        <img src="../../../../../../assets/icons/info-new.svg">
        <div class="title-container">
            <span class="title-information">¿No encuentras el correo de confirmación?</span>
            <span class="description-information">Asegúrate de revisar la carpeta de spam o correos no deseados.</span>
        </div>
    </div>
    <div class="button-container button-width">
        <lib-ui-button [fullWidth]="true" class="back" (click)="done()" variant="outline"
            label="Regresar"></lib-ui-button>
    </div>
</div>