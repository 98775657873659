import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-addtelegram',
  templateUrl: './addtelegram.component.html',
  styleUrls: ['./addtelegram.component.scss'],
})
export class AddtelegramComponent implements OnInit {
  form: FormGroup;
  companyDetail;
  isEdited;
  constructor(
    public dialogRef: MatDialogRef<AddtelegramComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      companyDetail: ['', [Validators.required]],
    });

    if (this.data.debtor.companyDetail) {
      this.companyDetail = _.cloneDeep(this.data.debtor.companyDetail);
    }
   
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
