import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-solid-label',
  templateUrl: './solid-label.component.html',
  styleUrls: ['./solid-label.component.scss']
})
export class SolidLabelComponent implements OnInit {
  @Input() content;
  @Input() background;
  @Input() width;
  constructor() { }

  ngOnInit() {
  }

  getBackgroundClass() {
    return `container ${this.background}`;
  }

}
