<div class="input-wrapper">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-form-field appearance="standard">
            <mat-label>{{label}}</mat-label>
            <input matInput (keydown)="onKeydown($event)" formControlName="ruc" placeholder="20603506180">
            <button mat-icon-button matSuffix type="submit">
                <img [className]="iconClass">
            </button>
        </mat-form-field>
    </form>
    <span *ngIf="errorMessage" class="error-label">{{errorMessage}}</span>
    <span *ngIf="alertMessage" class="alert-label">
        <mat-icon>check_circle_outline</mat-icon>&nbsp;{{alertMessage}}
    </span>
</div>