import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-manager-icon',
  templateUrl: './account-manager-icon.component.html',
  styleUrls: ['./account-manager-icon.component.scss']
})
export class AccountManagerIconComponent implements OnInit {

  @Input() accountName;
  @Input() isActive : boolean;
  @Input() isCartera : boolean;
  constructor() { }

  ngOnInit(): void {
  }


  public get classes(): string[] {
    let mode = ''
      switch (this.isActive) {
            case true:
                mode = 'active'
                break;

            case false:
                mode = 'no-active'
              break;
      

            default:
                mode = 'no-active'
                
              break;
      }

    return ['rounded', mode];
  }

}
