<div class="disclaimer-container" [ngClass]="(alert) ? 'alert' : ''">
    <div class="disclaimer-layout-middle">
        <img [src]="getImgSrc()" class="img-icon">
        <div>
            <p class="disclaimer-text">{{ content | capitalizeOnlyFirstLetter }}</p>
        </div>
    </div>
    <div *ngIf="closedIcon" class="dismiss" (click)="dismiss()">
        <img src="../../../../../assets/icons/gray-x.svg" class="img-icon">
    </div>
</div>