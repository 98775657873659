import { AbstractControl } from '@angular/forms';

export function LettersOnlyValidator(control: AbstractControl) {
  const pattern = new RegExp(/^[áéíóúüñÁÉÍÓÚÜÑAa-zA-Z ]*$/);
  const isValid = pattern.test(control.value);

  if (!isValid) {
    return { lettersOnly: true };
  }
  return null;
}
//asd énfa32@_ñáéíóúüñ
//aasd adé2@/#_
