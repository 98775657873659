export enum FinancialTransactionStatus {
    "In Process" = 'in process',
    "Approved" = 'approved',
    "Rejected" = 'rejected',
    
    "Investment Start" = 'investment start',
    "In Progress" = 'in progress',
    "Capital Refunded" = 'capital refunded',
    "Cancelled" = 'cancelled',
}
