import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { InvestInfoDetailComponent } from '../../../../../shared/UI/organisms/invest-info-detail/invest-info-detail.component';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { INVOICE_STATUS } from 'src/app/features/invoices/UI/enums/INVOICE_STATUS.enum';
import { financialTransactions } from '../../../../financial-transactions/state/financial-transactions.selectors';
import { InvoiceService } from '../../../../invoices/services/invoice.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-list-investments',
  templateUrl: './list-investments.component.html',
  styleUrls: ['./list-investments.component.scss'],
})
export class ListInvestmentsComponent implements OnInit {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  invoices;
  buttonActive = 'actual';
  currency = 'pen';
  financialTransactioins;
  displayToggle = false;
  invoiceFiltered;
  pageTitle = 'Mis inversiones';
  default = true;
  penLenght;
  usdLenght;
  selected = 0;
  pagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  defaultPagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  investmentActualLength;
  investmentHistoricalLength;
  investmentMoraLength;
  dontTriggerTabEvent = false;
  checkURLFlag = false;
  /*
  actualInvesmtentsStatus = ['sale closed', 'awaiting collection', 'collected'];
  historicalInvesmtentsStatus = ['finalized'];
  */
  actualInvesmtentsStatus = [
    INVOICE_STATUS.AWAITING_COLLECTION,
    INVOICE_STATUS.COLLECTED,
  ];
  historicalInvesmtentsStatus = [INVOICE_STATUS.FINALIZED];
  public activeSelection = this.actualInvesmtentsStatus;

  constructor(
    private overlayService: OverlayService,
    private store: Store<AppState>,
    public invoiceService: InvoiceService,
    private windowScrollService: WindowScrollService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    // this.store.select(financialTransactions).subscribe((data) => {
    //   this.financialTransactioins = data;
    //   this.checkforCurrency();
    // });

    // this.store.select(InvoiceSelectors.all).subscribe((data) => {
    //   this.invoices = data;
    //   // console.log('data', data)
    //   if (!this.checkURLFlag) {
    //     let madeAction = this.checkURL();
    //     if (!madeAction) {
    //       this.getInvoiceByCurrency();
    //     }
    //   }
    // });

    this.store
      .select(financialTransactions)
      .pipe(
        switchMap((financialData) => {
          this.financialTransactioins = financialData;
          this.checkforCurrency();

          return this.store.select(InvoiceSelectors.all);
        })
      )
      .subscribe((invoiceData) => {
        this.invoices = this.financialTransactioins
          .filter(
            (trx: any) =>
              trx.type === 'investment' &&
              (trx.status === 'in progress' ||
                trx.status === 'capital refunded')
          )
          .map((trx: any) => {
            const invoice = {
              ...invoiceData.find(
                (inv: any) => String(inv._id) === String(trx.invoice?._id)
              ),
              investmentAmount: trx.amount,
            };

            if (invoice.advancePaymentDate) {
              const investmentDate = moment(trx.investmentDate);

              if (moment(investmentDate).isAfter(invoice.advancePaymentDate)) {
                invoice.advancePaymentDate =
                  investmentDate.format('YYYY-MM-DD');

                invoice.minimumDuration = moment(invoice.paymentDate).diff(
                  investmentDate,
                  'days'
                );

                if (invoice.actualPaymentDate) {
                  invoice.actualDuration = moment(
                    invoice.actualPaymentDate
                  ).diff(investmentDate, 'days');
                }
              }
            }

            return invoice;
          });

        if (!this.checkURLFlag) {
          let madeAction = this.checkURL();
          if (!madeAction) {
            this.getInvoiceByCurrency();
          }
        }
      });

    this.getLocalStorage();
    this.getDefaultToggle();

    this.overlayService.getCloseEmitter().subscribe((obs) => {
      if (obs && obs === 'InvestmentDetail') {
        this.sendEventPageView(this.currency);
      }
    });
  }

  checkURL() {
    this.checkURLFlag = true;
    let redirectUrl = sessionStorage.getItem('redirectUrl');
    // console.log('redirectUrl', redirectUrl);
    if (this.router.url.includes('#')) {
      let routsContain = this.router.url.split('#');
      let invoiceId = routsContain[1];

      let targetInvoice = this.invoices?.find(
        (inv) => inv._id.toString() == invoiceId.toString()
      );

      if (!targetInvoice) {
        this.router.navigateByUrl('/opportunities');
        return false;
      }

      this.location.replaceState(routsContain[0]);
      this.currency = targetInvoice.currency;
      // this.dontTriggerTabEvent = true;
      if (redirectUrl) {
        if (!redirectUrl.includes('#')) {
          return false;
        }
        sessionStorage.removeItem('redirectUrl');
        //sessionStorage.setItem('redirectUrl', routsContain[0]);
      }

      if (targetInvoice.status === 'finalized') {
        this.activateClass(2);
      } else if (
        targetInvoice.status !== 'finalized' &&
        targetInvoice.toBeCollectedIn === 'En mora'
      ) {
        this.activateClass(1);
      } else {
        this.activateClass(0);
      }

      this.showDetails(targetInvoice, 'link');

      return true;
    }
    return false;
  }

  activateClass(option) {
    // debugger;
    // console.log("option", option)
    this.selected = option;

    if (!this.dontTriggerTabEvent) {
      this.sendEventPageView(this.currency);
    } else {
      this.dontTriggerTabEvent = false;
    }
    this.resetPagination();
  }

  showDetails(selectedInvoice, origin = 'platform') {
    let selectedInvoiceCopy = cloneDeep(selectedInvoice);
    selectedInvoiceCopy.parentTab = this.getTabName();
    selectedInvoiceCopy.origin = origin;

    this.overlayService.showComponent(
      InvestInfoDetailComponent,
      selectedInvoiceCopy
    );
  }
  setCurrency($event) {
    this.currency = $event;
    this.selected = 0;
    this.resetPagination();
    this.getInvoiceByCurrency();
    this.setLocalStorage();
    this.dontTriggerTabEvent = true;
    this.sendEventPageView(this.currency);
  }

  checkforCurrency() {
    // debugger;

    if (this.financialTransactioins) {
      this.penLenght = this.financialTransactioins.filter(
        (inv) => inv.currency === 'pen' && inv.status !== 'rejected'
      ).length;
      this.usdLenght = this.financialTransactioins.filter(
        (inv) => inv.currency === 'usd' && inv.status !== 'rejected'
      ).length;
      this.displayToggle = true;
      if (this.penLenght > 0 && this.usdLenght === 0) {
        this.currency = 'pen';
      } else if (this.penLenght === 0 && this.usdLenght > 0) {
        this.currency = 'usd';
      }
      // else if(this.currency == 'usd' || this.currency == 'pen'){
      //   this.currency = 'pen'
      // }
    }
  }

  getInvoiceByCurrency() {
    let actualInvoices = this.invoices
      .filter(
        (i) =>
          i.status !== 'finalized' &&
          i.currency === this.currency &&
          i.toBeCollectedIn !== 'En mora'
      )
      .sort((a: any, b: any) =>
        isNaN(a.toBeCollectedIn) || isNaN(a.toBeCollectedIn)
          ? 0
          : a.toBeCollectedIn - b.toBeCollectedIn
      );

    let delayedInvoices = this.invoices
      .filter(
        (i) =>
          i.status !== 'finalized' &&
          i.currency === this.currency &&
          i.toBeCollectedIn === 'En mora'
      )
      .sort((a: any, b: any) =>
        moment(b.paymentDate).diff(a.paymentDate, 'days')
      );

    let finalizedInvoices = this.invoices
      .filter((i) => i.status === 'finalized' && i.currency === this.currency)
      .sort((a: any, b: any) =>
        moment(b.actualPaymentDate).diff(a.actualPaymentDate, 'days')
      );

    if (this.invoices) {
      if (this.selected === 0) {
        this.invoiceFiltered = actualInvoices;
        this.pagination.total = this.invoiceFiltered.length || 0;
      } else if (this.selected === 1) {
        this.invoiceFiltered = delayedInvoices;
        this.pagination.total = this.invoiceFiltered.length || 0;
      } else if (this.selected === 2) {
        this.invoiceFiltered = finalizedInvoices;
        this.pagination.total = this.invoiceFiltered.length || 0;
      }

      this.invoiceFiltered = this.invoiceFiltered.slice(
        this.pagination.skip,
        this.pagination.limit + this.pagination.skip
      );

      this.investmentActualLength = actualInvoices.length || 0;
      this.investmentHistoricalLength = finalizedInvoices.length || 0;
      this.investmentMoraLength = delayedInvoices.length || 0;
    }
  }

  setLocalStorage() {
    localStorage.setItem('toggle_currency_investment', this.currency);
  }

  setLocalStoragePagination() {
    if (this.selected === 0) {
      localStorage.setItem(
        'actual_investments_pagination',
        JSON.stringify(this.pagination)
      );
    }
  }

  resetPagination() {
    this.pagination.total = 0;
    this.pagination.skip = 0;
    this.pagination.limit = 10;
    localStorage.removeItem('actual_investments_pagination');
    this.getInvoiceByCurrency();
  }

  getLocalStorage() {
    const toogleLocal = localStorage.getItem('toggle_currency_investment');
    if (toogleLocal != null) {
      this.currency = toogleLocal;
      this.getInvoiceByCurrency();
    }

    const pagination = localStorage.getItem('actual_investments_pagination');
    if (pagination != null) {
      this.pagination = JSON.parse(pagination);
      this.getInvoiceByCurrency();
    }
  }

  getDefaultToggle() {
    // debugger;
    if (this.currency === 'pen') {
      this.default = false;
    } else {
      this.default = true;
    }
  }

  sendEventPageView(currency: string) {
    this.windowScrollService.sendNewEvent(
      'My Investments',
      `Overview`,
      this.getTabName(),
      currency.toUpperCase()
    );
  }

  paginationHandler(event) {
    this.pagination.skip = event.skip;
    this.setLocalStoragePagination();
    this.getInvoiceByCurrency();
  }

  getTabName() {
    switch (this.selected) {
      case 0:
        return 'En curso';
      case 1:
        return 'Vencidas';
      case 2:
        return 'Cobradas';
      default:
        return 'En curso';
    }
  }
}
