import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-side-image',
  templateUrl: './signup-side-image.component.html',
  styleUrls: ['./signup-side-image.component.scss']
})
export class SignupSideImageComponent implements OnInit {
  @Input() image;
  @Input() description;


  constructor() { }

  ngOnInit(): void {
  }

}
