import { createAction, props } from '@ngrx/store';

export const LogIn = createAction(
    '[Auth] Login',
    props<{loginInfo: any}>()
);
export const SessionReinistated = createAction(
    '[Auth] Session Reinistated',
    props<{accessToken: any}>()
);
export const LogInSuccess = createAction(
    '[Auth] Login Success',
    props<{accessToken: any, role: any}>()
);
export const LoginFailed = createAction(
    '[Auth] Login Failed',
    props<{error: any}>()
);
export const SaveSession = createAction(
    '[Auth] Save Session',
    props<{token: any}>()
);
export const RestoreSession = createAction(
    '[Auth] Restore Session',
    props<{token: any}>()
);
export const LogOut = createAction(
    '[Auth] Logout'
);
export const ManualLogOut = createAction(
    '[Auth] Manual Logout'
);

export const ShowPopUp = createAction(
    '[Auth] ShowPopUp'
);