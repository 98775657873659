<ng-container [ngSwitch]="state">
  <ng-container *ngSwitchCase="'activated'">
    <div class="ws-widget-container-2">
      <div class="ws-text-container-no-icon">
        <p class="body-paragraph-xs-light" [innerHTML]="body"></p>
      </div>
      <div class="d-flex flex-items-center" [ngClass]="isMobile ? 'u-pt-6 ws-full-width' : 'u-pl-6'">
        <lib-ui-button class="d-block ws-full-width" variant="outline" [fullWidth]="isMobile ? true : false"
          (click)="actionToDo('resend')" label="Reenviar"></lib-ui-button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'blocked'">
    <div class="ws-widget-container-2">
      <div class="ws-text-container-no-icon">
        <p class="body-paragraph-xs-light" [innerHTML]="body"></p>
      </div>
      <div class="d-flex flex-items-center flex-column" [ngClass]="isMobile ? 'u-pt-6 ws-full-width' : 'u-pl-6'">
        <lib-ui-button class="d-block ws-full-width" variant="outline" [fullWidth]="isMobile ? true : false"
          [isDisabled]="true" label="Reenviar"></lib-ui-button>
        <p class="u-pt-1 body-caption-light text-color-dark-weak" style="white-space: nowrap;">
          {{ captionMsg }}
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="ws-widget-container">
      <div style="display: flex; align-items: center;">
        <div *ngIf="icon" class="u-pr-4 d-block icon-ws">
          <img src="../../../../../assets/icons/whatsapp.svg" alt="" />
        </div>
        <div class="ws-text-container">
          <p *ngIf="title" class="subheading-s-regular">{{ title }}</p>
          <p class="body-paragraph-xs-light" [innerHTML]="body"></p>
        </div>
      </div>

      <div class="d-flex flex-items-center" [ngClass]="isMobile ? 'u-pt-6 ws-full-width' : 'u-pl-6'">
        <lib-ui-button class="d-block ws-full-width" (click)="actionToDo('activate')" variant="outline"
          [fullWidth]="isMobile ? true : false" label="Activar"></lib-ui-button>
      </div>
    </div>
  </ng-container>
</ng-container>