<div class="mt-25">
    <div class="container pl-20">
        <div class="content">
            <app-operation-invoice [isConfirming]="false"></app-operation-invoice>
            <p>Adelanta el cobro de tus facturas en menos de 48 horas.</p>    
        </div>
    
        <div class="content mt-25">
            <app-operation-invoice [isConfirming]="true"></app-operation-invoice>
            <p>Gestiona el pago a tus proveedores de forma rápida y segura.</p>    
        </div>
    </div>
</div>
