import { FinancialtransactionsService } from 'src/app/features/financial-transactions/services/financial-transactions.service';
import {
  financialTransactionsLoaded,
  financialTransactions,
} from './../../../../financial-transactions/state/financial-transactions.selectors';
import { ConfigureInvoiceBankDialogComponent } from './../../../../invoices/UI/organisms/configure-invoice-bank/configure-invoice-bank-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceService } from './../../../../invoices/services/invoice.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import {
  bankAccounts,
  savingSuccessStateById,
} from '../../../state/bank-accounts.selectors';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { BankAccountsActions } from '../../../state/bank-accounts.action-types';
import { take, takeUntil, timeout } from 'rxjs/operators';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { DeleteBankAccountsTemplate } from '../delete-bank-accounts/delete-bank-accounts.template';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';

@Component({
  selector: 'app-list-bank-accounts',
  templateUrl: './list-bank-accounts.template.html',
  styleUrls: ['./list-bank-accounts.template.scss'],
})
export class ListBankAccountsTemplate implements OnInit {
  bankAccounts$: Observable<any>;
  pageTitle = "Cuentas bancarias";
  pageDescription = "Agrega las cuentas bancarias que quieres asociar a tu cuenta.";
  disableButton = false;

  constructor(
    private store: Store<AppState>,
    private overlayService: OverlayService,
    private invoiceService: InvoiceService,
    public dialog: MatDialog,
    private transactionService: FinancialtransactionsService,
    private windowScrollService: WindowScrollService
  ) { }

  ngOnInit() {
    this.fetchBankAccounts();
  }

  async deleteHandler(bankAccount) {
    this.disableButton = true;
    const role = await this.store
      .select(UsersSelectors.profileProperty('role'))
      .pipe(take(1))
      .toPromise();

    if (role === 'client') {
      const isInvoiceReplace = (
        await this.invoiceService
          .get(0, 0, {
            bankAccount: bankAccount._id,
          })
          .toPromise()
      ).filter(
        (inv: any) => inv.status !== 'disapproved' && inv.status !== 'finalized'
      ).length > 0;

      if (!isInvoiceReplace) {
        let hasRelationship = false;
        hasRelationship =
          (
            await this.invoiceService
              .get(0, 0, {
                bankAccount: bankAccount._id,
              })
              .toPromise()
          ).length > 0;

        bankAccount = { ...bankAccount, hasRelationship };
        this.sendEventPageView(1)

        this.overlayService.startConfirmation(
          bankAccount,
          {
            component: DeleteBankAccountsTemplate,
            entity: { bankAccount },
          },
          'details',
          'delete',
          null,
          BankAccountsActions.Saving,
          'bank-accounts',
          savingSuccessStateById,
          null,
          this.callBacksFunction.bind(this)
        );
      } else {
        const userId = await this.store
          .select(UsersSelectors.profileProperty('_id'))
          .pipe(take(1))
          .toPromise();

        let dialogRef = this.openDialogHelper(
          ConfigureInvoiceBankDialogComponent,
          {
            bankAccount,
            title: 'Eliminar cuenta',
            userId,
            role
          }
        );
        this.sendEventPageView(0);
        dialogRef.afterClosed().subscribe(e => {
          this.disableButton = false;

        })


        this.connectDialogListeners(dialogRef, (result) => {
          if (result) {
            result.bankAccount = { ...result.bankAccount, hasRelationship: true };
            this.sendEventPageView(1)
            this.overlayService.startConfirmation(
              {
                ...bankAccount,
                newBankAccount: result.newBankAccount._id,
              },
              {
                component: DeleteBankAccountsTemplate,
                entity: result,
              },
              'details',
              'delete',
              null,
              BankAccountsActions.Saving,
              `bank-accounts`,
              savingSuccessStateById,
              {
                onConfirm: {
                  title: 'Confirmación de eliminación',
                },
                onSuccess: {
                  showCheck: true,
                  title: 'Eliminación Realizada',
                },
              },
              this.callBacksFunction.bind(this)
            );
          }
        });
      }

    } else {
      let hasRelationship = false;
      hasRelationship = (
        await this.transactionService.getAll().toPromise()
      ).financialTransactions.some(
        (trx: any) => trx.bankAccount?._id === bankAccount._id && trx.status === 'in process'
          && (trx.type === 'withdraw' || trx.type == 'deposit')
      );

      if (!hasRelationship) {
        bankAccount = { ...bankAccount, hasRelationship };
        this.sendEventPageView(1)

        this.overlayService.startConfirmation(
          bankAccount,
          {
            component: DeleteBankAccountsTemplate,
            entity: { bankAccount },
          },
          'details',
          'delete',
          null,
          BankAccountsActions.Saving,
          'bank-accounts',
          savingSuccessStateById,
          null,
          this.callBacksFunction.bind(this)
        );
      } else {
        const userId = await this.store
          .select(UsersSelectors.profileProperty('_id'))
          .pipe(take(1))
          .toPromise();

        let dialogRef = this.openDialogHelper(
          ConfigureInvoiceBankDialogComponent,
          {
            bankAccount,
            title: 'Eliminar cuenta',
            userId,
            role
          }
        );
        this.sendEventPageView(0)

        this.connectDialogListeners(dialogRef, (result) => {
          if (result) {
            result.bankAccount = { ...result.bankAccount, hasRelationship };
            this.sendEventPageView(1)
            this.overlayService.startConfirmation(
              {
                ...bankAccount,
                newBankAccount: result.newBankAccount._id,
              },
              {
                component: DeleteBankAccountsTemplate,
                entity: result,
              },
              'details',
              'delete',
              null,
              BankAccountsActions.Saving,
              `bank-accounts`,
              savingSuccessStateById,
              {
                onConfirm: {
                  title: 'Confirmación de eliminación',
                },
                onSuccess: {
                  showCheck: true,
                  title: 'Eliminación Realizada',
                },
              },
              this.callBacksFunction.bind(this)
            );
          }
        });


      }

    }

    setTimeout(() => {
      this.disableButton = false;
    }, 1500);

  }

  callBacksFunction() {
    this.fetchBankAccounts();
    this.sendEventPageView(2);
    this.disableButton = false;
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: data,
      autoFocus: false
    });

    return dialogRef;
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  fetchBankAccounts() {
    this.bankAccounts$ = this.store.select(bankAccounts);
  }

  sendEventPageView(step) {
    this.windowScrollService.sendNewEvent('Bank Accounts', `Delete Step ${step}`)
  }
}
