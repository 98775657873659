import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-system-settings-page',
    template: `
        <app-page-title [title]="pageTitle"> </app-page-title>
        <router-outlet></router-outlet>
    `
})
export class SystemSettingsPageComponent implements OnInit {
    pageTitle = "Configuración";

    constructor( ) { }

    ngOnInit() {
      
    }

}
