import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store, select } from '@ngrx/store';
import { take, map, tap, filter, first, finalize } from 'rxjs/operators';
import { TermsAndConditionsSelectors } from '../state/terms-and-conditions.selector-types';
import { TermsAndConditionsActions } from '../state/terms-and-conditions.action-types';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsResolver implements Resolve<boolean> {
    loading = false;

    constructor (private store: Store<AppState>) { }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<boolean> {

              return this.store
              .pipe(
                  select(TermsAndConditionsSelectors.loaded),
                  tap(_loadedEntities => {
                      if(!this.loading && !_loadedEntities) {
                          this.loading = true;
                          this.store.dispatch(TermsAndConditionsActions.LoadAll());
                      }
                  }),
                  filter(_loadedEntities => _loadedEntities),
                  first(),
                  finalize(() => this.loading = false)
              );

    }
}
