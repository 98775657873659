<div class="container">
    <p class="title">¡Bienvenido, {{ userFirstName$ | async | capitalizeFirstLetters }}!</p>
    <p class="description">Para iniciar, completa lo siguiente:</p>

    <form [formGroup]="form">
        <h6>¿Eres el representante legal de tu empresa?</h6>
        <div class="container-radio">
            <app-radiolist-circle formControlName="legalRep" [options]="legalOptions" ></app-radiolist-circle>
        </div>

        <h6>¿Cuál es tu cargo?</h6>
        <app-select (OnSelectionChanged)="changeProfession($event)" formControlName="profession"  valueField="value" textField="label" [options]="professionList" label="Selecciona un cargo"></app-select>

        <app-input *ngIf="displayInput" type="text" label="Ingresa otro cargo"
                                formControlName="legalComment" ></app-input>


        <fs-ui-button [action]="'submit'" (click)="submit()"  [label]="'Guardar'"></fs-ui-button>

        <a class="firts-modal-later" (click)="close()"><h6>Quiero hacerlo más tarde</h6></a>
    </form>

</div>
