import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-new-paginator',
  templateUrl: './new-paginator.component.html',
  styleUrls: ['./new-paginator.component.scss']
})
export class NewPaginatorComponent implements OnInit, OnChanges {
  @Input() totalCount = 0;
  @Input() currentPage = 1;
  @Input() pageSize = 1;
  @Output() pagination = new EventEmitter();

  totalPages = 1;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
  }

  ngOnInit(): void {
  }

  pageDown() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;

      this.pagination.emit({
        skip: Math.round((this.currentPage - 1) * this.pageSize),
        currentPage: this.currentPage,
      });
    }
  }

  pageUp() {
    if (this.currentPage < (this.totalCount / this.pageSize)) {
      this.currentPage = this.currentPage + 1;

      this.pagination.emit({
        skip: Math.round((this.currentPage - 1) * this.pageSize),
        currentPage: this.currentPage,
      });
    }
  }

  isEnabled(side) {
    if (side === 'right') {
      if (this.currentPage === this.totalPages) {
        return false
      } else {
        return true
      }
    } else {
      if (this.currentPage === 1) {
        return false
      } else {
        return true
      }
    }
  }
}
