import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { ActivatedRoute } from '@angular/router';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { Observable } from 'rxjs';
import { UsersSelectors } from '../../../state/users.selector-types';
import { UserActionsMapping } from '../../../mapping/actions.mapping';
import { DisplayFileComponent } from 'src/app/shared/UI/organisms/display-file/display-file.component';
import { UsersActions } from '../../../state/users.action-types';
import { USER_STATUS } from '../../../enums/USER_STATUS.enum';
import { environment } from 'src/environments/environment';
import { UsersService } from '../../../services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GTMService } from 'src/app/shared/services/gtm.service';

@Component({
  selector: 'app-user-full-details',
  templateUrl: './user-full-details.component.html',
  styleUrls: ['./user-full-details.component.scss']
})
export class UserFullDetailsComponent implements OnInit {
  selectedUserAccount
  userActions = Object.keys(UserActionsMapping);
  selectedUserId;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private service: UsersService,
    private spinner: NgxSpinnerService,
    private gtmService: GTMService
  ) {}

  ngOnInit(): void {
    //console.log(`${environment.clientUrl}/${environment.emailConfirmationUri}`);
    //console.log(environment.clientUrl);
    //console.log(environment.emailConfirmationUri);
    
    this.route.params.subscribe(params => {
      this.selectedUserId = params.userId;
      //this.selectedUserAccount$ = this.store.select(UsersSelectors.selectUserById(selectedUserId));
      this.fetchUser(this.selectedUserId);

    });
  }

  showFile(propertyKey, itemId) {
    this.spinner.show();

    this.service.getUserDocument(propertyKey, itemId).subscribe(result => {

      if(result.file) {
        this.overlayService.showComponent(DisplayFileComponent, result.file)  
      } else {
        window.alert("¡No se encuentra el archivo!");
      }

      this.spinner.hide();
    });
  }

  executeUnBlock(userId) {
      this.overlayService.startConfirmation(
        { _id: userId, isBlocked: false },
        null,
        'question',
        'edit',
        null,
        UsersActions.Saving,
        `/users-accounts/${userId}`,
        UsersSelectors.savingSuccessStateById,
        {
          onSuccess: {
              showCheck: true,
              title: "Usuario desbloqueado!",
              messsage: 'Este usuario podrá iniciar sesión, solo si el estado de la cuenta está "confirmado"',
          }
        },
        this.reFetchUser.bind(this)
      )
  }
  executeBlock(userId) {
    this.overlayService.startConfirmation(
      { _id: userId, isBlocked: true },
      null,
      'question',
      'edit',
      null,
      UsersActions.Saving,
      `/users-accounts/${userId}`,
      UsersSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: "Usuario bloqueado!",
          messsage: "Este usuario no podrá iniciar sesión",
        }
      },
      this.reFetchUser.bind(this)
    )
  }
  executeApprove(userId) {
    this.overlayService.startConfirmation(
      { _id: userId, status: USER_STATUS.APPROVED, homeUrl: `${environment.clientUrl}` },
      null,
      'question',
      'edit',
      null,
      UsersActions.Saving,
      `/users-accounts/${userId}`,
      UsersSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: "Usuario aprobado!",
          messsage: "Este usuario no podrá iniciar sesión hasta que confirme su correo electrónico (el correo está en camino)",
        }
      },
      this.reFetchUser.bind(this)
    )
  }

  executeDisapprove(userId) {
    this.overlayService.startConfirmation(
      { _id: userId, status: USER_STATUS.DISAPPROVED },
      null,
      'question',
      'edit',
      null,
      UsersActions.Saving,
      `/users-accounts/${userId}`,
      UsersSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: "Usuario desaprobado!",
          messsage: "Este usuario no podrá iniciar sesión, esta acción es reversible",
        }
      },
      this.reFetchUser.bind(this)
    )
  }

  executeResendConfirmation(userId) {
    //A HACK TO BE IMPROVED
    this.overlayService.startConfirmation(
      { _id: userId, clientUrl: `${environment.clientUrl}/${environment.emailConfirmationUri}` },
      null,
      'resultOnly',
      'edit',
      null,
      UsersActions.Saving,
      `/users-accounts/${userId}`,
      UsersSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: "El correo de confirmación ha sido reenviado"
        }
      },

    )
  }

  reFetchUser() {
    this.fetchUser(this.selectedUserId);
  }

  fetchUser(userId) {
    this.service.byId(userId).subscribe(user => {
      this.selectedUserAccount = user
    });
  }

  contractSignedChanged(contractSigned, userId){

    const callbackGrouping = () => {
      this.reFetchUser();
      this.sendLastSetupStep();
    };
    

    this.overlayService.startConfirmation(
      { contractSigned: contractSigned, _id: userId },
      null,
      'question',
      'edit',
      null,
      UsersActions.Saving,
      `/users-accounts/${userId}`,
      UsersSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'El usuario se ha actualizado',
        },
        
      },
      callbackGrouping.bind(this)
    );
  }


  sendLastSetupStep() {
    if(!this.selectedUserAccount.setupbarClosed && !this.selectedUserAccount.contractSigned){
      let event = {
        event: '[Platform][Setup][Bar]',
        _inputCategory: `General / Setup Bar`,
        _inputName: `Changed Firmar Contratos To Done In Setup Bar`,
        _userId: this.selectedUserId
      };
      this.gtmService.newEvent(event)
    }
  }

  isConfirmingChanged(isConfirming, userId) {
    this.overlayService.startConfirmation(
      { isConfirming: isConfirming, _id: userId },
      null,
      'question',
      'edit',
      null,
      UsersActions.Saving,
      `/users-accounts/${userId}`,
      UsersSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'El usuario se ha actualizado',
        },
        
      },
      this.reFetchUser.bind(this)
    );

  }

}

//¿Estás seguro de que quieres bloquear al usuario?