import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatchValidator } from 'src/app/shared/validators/must-match.validator';
import { PasswordCriteriaValidator } from 'src/app/shared/validators/password-criteria.validator';
import { RequiredValidator } from 'src/app/shared/validators/required.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  form;
  
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      actualPassword: ['', [Validators.required]],
      newPassword: ['', [RequiredValidator, Validators.minLength(8), Validators.maxLength(64)]],
      repeatPassword: ['', [RequiredValidator, MustMatchValidator('newPassword'), Validators.minLength(8), Validators.maxLength(64)]]
    });
  }

  submit(e) {
    e.preventDefault();
    this.form.markAllAsTouched();

    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].updateValueAndValidity();
    });

    if(this.form.valid) {
      const entity = {
        actualPassword: this.form.get('actualPassword').value,
        password: this.form.get('newPassword').value,
        homeUrl: environment.clientUrl
      };
      this.onSubmit.emit(entity);
    }

  }
}
