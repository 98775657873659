<h1>Adjuntar archivo</h1>

<ng-container *ngIf="!data.evaluation.documents || data.evaluation.documents?.length === 0">
    <div class="container">
        <div class="item">
            <h6>Archivo adjunto (0)</h6>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="data.evaluation.documents && data.evaluation.documents.length > 0">
    <div class="container">
        <div class="item">
            <h6>Archivos adjuntos ({{ data.evaluation.documents.length }})</h6>
        </div>

        <div class="item">
            <span *ngIf="data.evaluation?.documents && data.evaluation.documents.length > 1" (click)="downloadAllDocuments(data.evaluation)" class="action">
                <span class="title">Descargar todo</span>
                <img src="../../../../../../assets/icons/file_download_black_24dp.svg" />
            </span>
        </div>
    </div>
    <ng-container *ngIf="data.evaluation.documents">
        <ng-container *ngFor='let file of data.evaluation.documents; index as i'>
            <div class="row">
                <div class="name">
                   <span class="title" (click)="downloadItem(file, data.evaluation)">
                       Archivo adjunto {{i+1}}.{{ getMimeType(file?.file) }}
                   </span>
                </div>
                <div class="delete">
                    <span (click)="deleteOneDocument(file, data.evaluation, i)" class="action">
                        <img src="../../../../../../assets/icons/delete-file.svg" />
                    </span>
                </div>
              
            </div>
        </ng-container>
    </ng-container>
   

</ng-container>


<div class="upload-file-container" (click)="fileInput.click()" appDragDrop (onFileDropped)="fileUpload($event)">
    <div class="upload-text">
        <p class="bold">Subir archivo</p>&nbsp;<mat-icon>cloud_upload</mat-icon>
    </div>

    <input hidden type="file" #fileInput (change)="fileUpload($event.target.files)" accept=".jpg,.pdf,.png,.jpeg,.doc,.xlsx,.docx" multiple (click)="fileInput.value=null">
</div>
<fs-ui-button (click)="cancel()" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
