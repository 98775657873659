import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-stepper',
  templateUrl: './tab-stepper.component.html',
  styleUrls: ['./tab-stepper.component.scss'],
})
export class TabStepperComponent implements OnInit {
  @Input() currentStepIndex;
  @Input() totalSteps;
  total;
  constructor() {}

  ngOnInit(): void {
    this.total = Array.from({ length: this.totalSteps }, (v, k) => k + 1);
  }
}
