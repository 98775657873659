import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { isLoggedIn } from 'src/app/features/authentication/state/auth.selectors';
import { tap, map } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/features/authentication/state/auth-selector-types';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate{
constructor (
    private router : Router,
    private store: Store<AppState>
    ) {}

canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.store
    .pipe(
      select(AuthSelectors.isLoggedIn),
      tap(loggedIn => {
        if(loggedIn) {
          this.router.navigateByUrl('/');
        }
      }),
      map(loggedIn => !loggedIn)
    );
  }
}
