import { Injectable } from '@angular/core';
import { RESTService } from '../../../shared/services/rest.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private rest: RESTService) {}

  createDebtor(entity: any): Observable<any> {
    return this.rest.post(`/debtors`, entity);
  }

  getAllDebtors(): Observable<any> {
    return this.rest.get(`/debtors`);
  }

  createContactDebtor(entity, mode?): Observable<any> {
    if (mode == 'delete') {
      return this.rest.patch(`/contacts/${entity._id}`, {
        validate: false,
        companyRuc: entity.companyRuc,
        email: entity.email,
        rejectedEmails: [
          {
            email: entity.email,
            reason: entity.rejectionReason.selectedReason,
            reasonComment:
              entity.rejectionReason.reasonComment ??
              entity.rejectionReason.selectedReason,
          },
        ],
      });
    }
    if (mode === 'edit') {
      return this.rest.patch(`/contacts`, entity);
    } else {
      return this.rest.post(`/contacts`, entity);
    }
  }

  validateContact(contact, mode): Observable<any> {
    return this.rest.patch(`/contacts/${contact?._id || contact.user?._id}`, {
      validate: true,
      companyRuc: contact.companyRuc,
      jobTitle: contact.jobTitle,
      contactTypes: contact.contactTypes,
      name: contact.name,
      phone: contact.phone,
    });
  }

  checkOnValidEmail(entity): Observable<any> {
    return this.rest.patch(`/contacts`, entity);
  }

  byRuc(id) {
    return this.rest.get(`/debtors/${id}`);
  }

  getAllContacts(id) {
    return this.rest.get(`/debtors/${id}/contacts`);
  }

  getContactsByClient(clientId: string, debtorId: string) {
    return this.rest.get(`/debtors/${debtorId}/contacts?clientId=${clientId}`);
  }
  // byId(id) {}

  // getAll(): Observable<any> {}

  // getAllOpportunities(): Observable<any> {}

  // update(entity): Observable<any> {
  //   const entityId = entity._id;
  //   return this.rest.patch(`/invoices/${entityId}`, entity);
  // }

  // save(entity, mode): Observable<any> {
  //   switch(mode) {
  //     case 'create':
  //         return this.create(entity);
  //     case 'edit':
  //       return this.update(entity);
  //     }
  // }
}
