import { financialTransactions } from './../../../../financial-transactions/state/financial-transactions.selectors';
import { GAService } from './../../../../../shared/services/ga.service';
import { MixpanelService } from './../../../../../shared/services/mixpanel.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { MoneyValidator } from 'src/app/shared/validators/money.validator';
import { FinancialTransactionActions } from 'src/app/features/financial-transactions/state/financial-transactions.action-types';
import {
  savingSuccessStateById,
  balanceAvailable,
} from 'src/app/features/financial-transactions/state/financial-transactions.selectors';
import { ConfirmInvestmentComponent } from '../../organisms/confirm-investment/confirm-investment.component';
import { InvoiceService } from '../../../../invoices/services/invoice.service';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { Subject } from 'rxjs';
import { ObservableInvestmentValidator } from 'src/app/shared/validators/observe-investment.validator';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { FormService } from 'src/app/shared/services/form.service';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { UsersActions } from 'src/app/features/users/state/users.action-types';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import moment from 'moment';
import { CapitalizeOnlyFirstLetter } from '../../../../../shared/pipes/capitalize-only-first-letter.pipe';
import { NumberSpacingPipe } from '../../../../../shared/pipes/number-spacing.pipe';
import { HistoricalInvestmentDialogComponent } from '../../organisms/historical-investment-dialog/historical-investment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

@Component({
  selector: 'app-invest-in-invoice',
  templateUrl: './invest-in-invoice.component.html',
  styleUrls: ['./invest-in-invoice.component.scss'],
})
export class InvestInInvoiceComponent implements OnInit {
  @Input() payload;
  form: FormGroup;
  @Output() destroySelf = new EventEmitter();
  calculatedROI;
  availableBalance;
  debtorStats;
  InvoiceAvailableBalance;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  userStatus = '';
  disclaimerAlert = false;
  isDisclaimerVisible = false;
  subscription;
  userRole = '';
  parentTab;
  codes = '';
  operationYear = new Date().getFullYear() - 1;
  tabInvoiceDetail = true;
  tabRiskData = false;
  tabOperations = false;
  tabHistory = false;
  selectedTab;
  ratingDisclaimerOpen = false;
  isMobile;
  constructor(
    private fb: FormBuilder,
    private overlayService: OverlayService,
    private invoiceService: InvoiceService,
    private store: Store<AppState>,
    private investmentValidator: ObservableInvestmentValidator,
    private gtmService: GTMService,
    private formService: FormService,
    private mixpanelService: MixpanelService,
    private gaService: GAService,
    private windowScrollService: WindowScrollService,
    private capitalizeOnlyFirstLetter: CapitalizeOnlyFirstLetter,
    private numberSpacing: NumberSpacingPipe,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.parentTab = this.payload.parentTab;
    this.store
      .select(InvoiceSelectors.opportunitiesById(this.payload._id))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((opportunity) => {
        this.payload = opportunity;

        this.InvoiceAvailableBalance = opportunity.availableBalanceAmount;
      });
    this.invoiceService
      .getDebtorStatsByRUC(this.payload.debtor._id)
      .subscribe((debtorStats) => {
        this.debtorStats = debtorStats;
      });

    this.store
      .select(balanceAvailable(this.payload.currency))
      .pipe(take(1))
      .subscribe((balance) => {
        this.availableBalance = balance;

        this.form = this.fb.group({
          amount: [
            '',
            [Validators.required, MoneyValidator(1, 2)],
            [
              this.investmentValidator.validate(
                this.payload._id,
                balance,
                this.payload.currency
              ),
            ],
          ],
        });

        this.form.get('amount').valueChanges.subscribe((newValue) => {
          if (newValue && Math.sign(Number(newValue)) === 1) {
            this.calculatedROI = this.invoiceService.calculateROI(
              newValue,
              this.payload.tem,
              this.payload.minimumDuration
            );
          } else {
            this.calculatedROI = '00.00';
          }
        });

        this.breakpointObserver
          .observe([Breakpoints.XSmall])
          .subscribe((state: BreakpointState) => {
            if (state.matches) {
              this.isMobile;
              this.defaultLauncher(true);
            }
          });
      });

    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        this.userStatus = user.status;
        this.userRole = user.role;
        this.isDisclaimerVisible =
          user.status === 'email verified' ? true : false;
      });

    this.subscription = this.overlayService
      .getCloseEmitter()
      .subscribe((obs) => {
        if (obs && obs === 'ConfirmInvestmentComponent') {
          this.sendEventPageView('Detail');
        }
      });
    this.codes = this.payload.physicalInvoices.map((i) => i.code).join('\n');
  }

  submit(event) {
    event.preventDefault();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.valid && !this.getDisable()) {
      this.invest();
      //this.destroySelf.emit();
    } else {
      this.activateDisclaimerAlert();
      let event = {
        event: '[Platform][Form][Input][Error]',
        _inputError: this.formService.getFormValidationErrors(this.form),
        _inputName: 'Opportunities - detail - invertir - error',
        _inputCategory: 'Opportunities - detail - invertir',
      };
      this.gtmService.newEvent(event);
    }
  }

  invest() {
    this.sendEventPageView('Invest Step 1');
    this.overlayService.startConfirmation(
      {
        type: 'investment',
        amount: this.form.value.amount,
        currency: this.payload.currency,
        invoice: this.payload._id,
      },
      ConfirmInvestmentComponent,
      'details',
      'create',
      null,
      FinancialTransactionActions.Investing,
      `/` /* a hack because we create two modals and it will destory the last one only, didn't dig deeper into why it doesn't go back to opportunities list */,
      savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'Inversión en proceso',
          messsage:
            'La inversión se hará efectiva cuando la factura se financie completamente.',
        },
        onConfirm: {
          title: 'Confirmación de inversión',
        },
      },
      this.investCallback.bind(this)
    );

    let event = {
      event: '[Platform][Form][Input]',
      _inputError: this.formService.getFormValidationErrors(this.form),
      _inputName: 'Opportunities - detail - invertir',
      _inputCategory: 'Opportunities - detail - invertir',
    };
    this.gtmService.newEvent(event);
  }

  async investCallback(success) {
    if (success) {
      this.destroySelf.emit();

      const role = await this.store
        .select(UsersSelectors.profileProperty('role'))
        .pipe(take(1))
        .toPromise();

      if (this.isMobile) this.defaultLauncher(false);

      if (role === 'investor') {
        this.store
          .select(financialTransactions)
          .pipe(take(1))
          .subscribe((trx) => {
            const eventName = 'new_investment';
            const eventData = {
              create_date: new Date().toISOString(),
              operation_type: trx[0].invoice?.isConfirming
                ? 'Confirming'
                : 'Factoring',
              currency: trx[0].currency.toUpperCase(),
              amount: trx[0].amount,
              is_success: true,
              error_description: null,
            };
            this.gaService.sendEvent(eventName, eventData);
            this.mixpanelService.newEvent(eventName, eventData);
            this.sendEventSetupbar(trx);
            this.sendEventPageView('Invest Step 2');
          });
      }
    }
  }

  sendEventSetupbar(trx) {
    this.store
      .select(UsersSelectors.profile())
      .pipe(take(1))
      .subscribe((profile) => {
        let investment = trx.filter(
          (transaction) => transaction.type === 'investment'
        );
        if (profile) {
          if (!profile.setupbarClosed && investment.length == 1) {
            let event = {
              event: '[Platform][Setup][Bar]',
              _inputCategory: 'General / Setup Bar',
              _inputName: 'Invested In An Oportunity In Setup Bar',
            };
            this.gtmService.newEvent(event);
            this.store.dispatch(UsersActions.LoadProfile());
          }
        }
      });
  }

  sendEvent(field) {
    let event;
    if (this.form.get(field).errors) {
      event = {
        event: '[Platform][Form][Input][Error]',
        _inputError: this.form.get(field).errors,
        _inputName: 'Opportunities - detail - monto - error',
        _inputCategory: 'Inversionista - 0pportunities - detail',
      };
    } else {
      event = {
        event: '[Platform][Form][Input]',
        _inputName: 'Opportunities - detail - monto',
        _inputCategory: 'Inversionista - 0pportunities - detail',
      };
    }
    this.gtmService.newEvent(event);
  }

  activateDisclaimerAlert() {
    console.log('entra');
    this.disclaimerAlert = true;
    setTimeout(() => {
      this.disclaimerAlert = false;
    }, 1000);
  }

  ngAfterViewInit() {
    this.sendEventPageView('Detail');
  }

  sendEventPageView(title) {
    this.windowScrollService.sendNewEvent(
      'Opportunities',
      `${title}`,
      this.getTabName()
    );
  }

  getContent() {
    let hour = this.payload?.onSaleSlot === '1' ? '12:30' : '17:30';
    let date =
      moment(this.payload?.onSaleDate).format('dddd DD [de] MMMM, ') + hour;
    return (
      'Fecha de publicación de la factura: ' +
      this.capitalizeOnlyFirstLetter.transform(date)
    );
  }

  getClass() {
    if (
      this.userStatus === 'email verified' ||
      (this.userStatus !== 'email verified' &&
        this.payload.hasOwnProperty('onSale') &&
        this.payload?.onSale === false)
    ) {
      return 'grayed-out';
    } else {
      return '';
    }
  }

  getDisable() {
    if (
      this.payload.hasOwnProperty('onSale') &&
      this.payload?.onSale === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  updateTabSelected(e) {
    this.selectedTab = e;
    this.sendEventPageView('Detail');
  }

  getRiskData(opportunity, criteria, attribute = 'data') {
    if (opportunity?.evaluation?.criterias) {
      let value =
        opportunity?.evaluation?.criterias?.find((c) => c.item === criteria)?.[
          attribute
        ] || '0';
      if (
        criteria === 'directDebt' ||
        criteria === 'indirectDebt' ||
        criteria === 'mortgageGuarantees' ||
        criteria === 'unclearProtests'
      ) {
        if (!isNaN(value)) value = Number(value).toFixed(2);
        return 'S/ ' + this.numberSpacing.transform(value);
      } else {
        return value;
      }
    } else {
      if (
        criteria === 'directDebt' ||
        criteria === 'indirectDebt' ||
        criteria === 'mortgageGuarantees' ||
        criteria === 'unclearProtests'
      ) {
        return 'S/ 0.00';
      } else {
        return '';
      }
    }
  }

  getAdditionalInfo(opportunity, attribute = 'data') {
    if (opportunity?.evaluation?.additionalInfo) {
      let value =
        opportunity?.evaluation?.additionalInfo?.find(
          (c) => c.year == this.operationYear
        )?.[attribute] || '0.00';
      if (!isNaN(value)) value = Number(value);
      if (attribute == 'exportsOpsValue' || attribute === 'importsOpsValue') {
        return value == '0.00' ? '0' : value;
      } else {
        value = Number(value).toFixed(2);
        return '$ ' + this.numberSpacing.transform(value);
      }
    } else {
      if (attribute == 'exportsOpsValue' || attribute === 'importsOpsValue') {
        return '0';
      } else {
        return '$ 0.00';
      }
    }
  }

  openHistoric($event) {
    $event.stopPropagation();
    console.log('Open');
    let dialogRef = this.openDialogHelper(HistoricalInvestmentDialogComponent, {
      data: {
        ...this.payload?.debtor,
        pageSection: 'Mi historial Detail',
      },
    });
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: data,
    });

    return dialogRef;
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  getTabName() {
    switch (this.selectedTab) {
      case 0:
        return `${this.parentTab} Detalle de la factura`;
      case 1:
        if (this.payload.evaluation) {
          return `${this.parentTab} Data de riesgos`;
        } else {
          return `${this.parentTab} Historial con Finsmart`;
        }
      case 2:
        return `${this.parentTab} Historial con Finsmart`;
      default:
        return `${this.parentTab} Detalle de la factura`;
    }
  }

  defaultLauncher(state) {
    this.windowScrollService.setDefaultLauncher(state);
  }
}
