<app-page-title title="Retirar"></app-page-title>

<app-available-balance [forUserId]="selectedUserId"></app-available-balance>

<app-financial-transaction-form *ngIf="userStatus === 'approved'" mode="withdraw"
    (OnSelectedUserChanged)="selectedUserChanged($event)" (onSubmit)="submitHandler($event)">
</app-financial-transaction-form>

<div *ngIf="userStatus !== 'approved'">
    <p class="disclaimer-title">Tu cuenta está en proceso de validación</p>
    <p class="disclaimer-description">Estamos en la fase final de verificar tu identidad para aprobar tu cuenta. Pronto
        podrás realizar retiros sin
        problemas. ¡Gracias por tu paciencia!</p>
    <div class="disclaimer-additional">
        Para saber más sobre el estado de tu cuenta, contáctate con soporte haciendo <span class="intercom"
            (click)="openIntercom()">clic aquí</span>
    </div>
</div>

<div class="button-container" *ngIf="userStatus === 'approved'">
    <fs-ui-button routerLink="/financial-transactions" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
</div>