import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { SharedActions } from 'src/app/shared/state/shared.action-types';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.organism.html',
  styleUrls: ['./top-bar.organism.scss']
})
export class TopBarOrganism implements OnInit {
  userFirstName$: Observable<string>;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  profile
  isMobile
  hideByDefault = false
  constructor(
    private store: Store<AppState>,
    private breakpointObserver: BreakpointObserver,
    ) {}

  ngOnInit() {    
    this.userFirstName$ = this.store
    .pipe(
      select(UsersSelectors.profileProperty('names'))
    );

    this.store.select(UsersSelectors.profile()).pipe(
      (takeUntil(this.ngUnsubscribe))
      ).subscribe((profile) => {
        if(profile) {
          //console.log(profile);
          this.profile = profile;
          if((this.profile.role === 'client' || this.profile.role === 'investor') && this.profile.setupbarClosed === false) {
            this.hideByDefault = true
          }
        }
    });
    
    this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .subscribe((state: BreakpointState) => {
      // console.log(state);
      if (state.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  showMenu() {
    this.store.dispatch(SharedActions.ShowSideMenu());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
