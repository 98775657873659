import { createFeatureSelector } from '@ngrx/store';

import * as auth from './features/authentication/state/auth.reducers';
import * as bankAccounts from './features/bank-accounts/state/bank-accounts.reducers';
import * as users from './features/users/state/users.reducers';
import * as financialTransactions from './features/financial-transactions/state/financial-transactions.reducers';
import * as termsAndConditionsState from './features/system-settings/state/terms-and-conditions.reducers';
import * as invoicesState from './features/invoices/state/invoices.reducers';
import * as evaluationsState from './features/risk-pending-evaluations/states/evaluations.reducers';
import * as shared from './shared/state/shared.reducers';
import { AuthEffects } from './features/authentication/state/auth.effects';
import { BankAccountsEffects } from './features/bank-accounts/state/bank-accounts.effects';
import { UsersEffects } from './features/users/state/users.effects';
import { FinancialTransactionsEffects } from './features/financial-transactions/state/financial-transactions.effects';
import { TermsAndConditionsEffects } from './features/system-settings/state/terms-and-conditions.effects';
import { InvoicesEffects } from './features/invoices/state/invoices.effects';
import { EvaluationsEffects } from './features/risk-pending-evaluations/states/evaluations.effects';

export interface AppState {
  authState: auth.State;
  bankAccountsState: bankAccounts.State;
  financialTransactionsState: financialTransactions.State;
  usersState: users.State;
  sharedState: shared.State;
  termsAndConditionsState: termsAndConditionsState.State;
  invoicesState: invoicesState.State;
  evaluationsState: evaluationsState.State;
}

export const reducers = {
  auth: auth.authReducer,
  bankAccounts: bankAccounts.bankAccountsReducer,
  financialTransactions: financialTransactions.financialTransactionReducer,
  users: users.usersReducer,
  shared: shared.sharedReducer,
  termsAndConditions: termsAndConditionsState.reducer,
  invoices: invoicesState.reducer,
  evaluations: evaluationsState.evaluationsRiskReducer
};

export const effects = [
  AuthEffects,
  BankAccountsEffects,
  FinancialTransactionsEffects,
  UsersEffects,
  TermsAndConditionsEffects,
  InvoicesEffects,
  EvaluationsEffects
];

