import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-padron-confirmation-screen',
  templateUrl: './update-padron-confirmation-screen.component.html',
  styleUrls: ['./update-padron-confirmation-screen.component.scss']
})
export class UpdatePadronConfirmationScreenComponent implements OnInit {

  @Input() entity;
  constructor() {
  }

  ngOnInit(): void {
   // console.log(this.entity)
  }

}
