import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-add-bank-dialog',
  templateUrl: './add-bank-dialog.component.html',
  styleUrls: ['./add-bank-dialog.component.scss'],
})
export class AddBankDialogComponent implements OnInit {
  isConfirming;
  availableCurrency = ['pen', 'usd'];
  constructor(
    public dialogRef: MatDialogRef<AddBankDialogComponent>,
    private gtmService: GTMService,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isConfirming = this.data?.isConfirming;
    if (this.data?.availableCurrency) {
      this.availableCurrency = this.data.availableCurrency;
    }
  }

  submitHandler(formValue) {
    this.dialogRef.close(formValue);

    if (this.isConfirming !== undefined) {
      let event = {
        event: '[Platform][Form][Input]',
        _inputName: 'Clicked Agregar Cuenta In Confirming',
        _inputCategory: 'Invoice Upload / Confirming / New Bank Account',
      };
      this.gtmService.newEvent(event);
    }
  }

  sendError($form) {
    let eventError = {
      event: '[Platform][Form][Input][Error]',
      _inputName: 'Clicked Agregar Cuenta In Confirming Error',
      _inputCategory: 'Invoice Upload / Confirming / New Bank Account',
      _inputError: this.formService.getFormValidationErrors($form),
    };
    this.gtmService.newEvent(eventError);
  }

  cancel() {
    if (this.isConfirming !== undefined) {
      let event = {
        event: '[Platform][Form][Input]',
        _inputName: 'Clicked Regresar In Confirming ',
        _inputCategory: 'Invoice Upload / Confirming / New Bank Account',
      };
      this.gtmService.newEvent(event);
    }
    this.dialogRef.close();
  }
}
