import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { TermsAndConditionsActions } from '../../../state/terms-and-conditions.action-types';
import { TermsAndConditionsSelectors } from '../../../state/terms-and-conditions.selector-types';

@Component({
  selector: 'app-create-term-condition',
  templateUrl: './create-term-condition.component.html',
  styleUrls: ['./create-term-condition.component.scss']
})
export class CreateTermConditionComponent implements OnInit {


  constructor(
    private overlayService: OverlayService
  ) { }
  
  ngOnInit(): void {
  }

  submitHandler(formValue) {
    this.overlayService.startConfirmation(
      formValue,
      null,
      'question',
      'create',
      '',
      TermsAndConditionsActions.Saving,
      '/system-settings/terms-and-conditions',
      TermsAndConditionsSelectors.savingSuccessStateById
    )
  }

}
