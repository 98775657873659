import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-delete-bank-accounts',
  templateUrl: './delete-bank-accounts.template.html',
  styleUrls: ['./delete-bank-accounts.template.scss'],
})
export class DeleteBankAccountsTemplate implements OnInit {
  @Input() entity: any;
  constructor() {}

  ngOnInit() {}
}
