import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { MoneyValidator } from 'src/app/shared/validators/money.validator';
import { RiskService } from '../../../services/risk.service';

@Component({
  selector: 'app-finalize-evaluation',
  templateUrl: './finalize-evaluation.component.html',
  styleUrls: ['./finalize-evaluation.component.scss'],
})
export class FinalizeEvaluationComponent implements OnInit {
  form;
  evaluation;
  addedValue;
  totalValue;
  raiting;
  dictamen = 'initial';
  label;
  color;
  companyDetail;
  comment;
  detailUpdatedAt;
  formTelegram;
  savedFlag = new BehaviorSubject<any>(false);
  creaditLineOptions = [
    { text: 'Puntual', value: 'punctual' },
    { text: 'Linea', value: 'line' },
  ];
  constructor(
    public dialogRef: MatDialogRef<FinalizeEvaluationComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: RiskService
  ) {}

  ngOnInit(): void {
    if (this.data.evaluation.comment) {
      //this.calculateEvaluation(this.data.evaluation.addedValue)
      this.comment = this.data.evaluation.comment;
    }

    if (this.data.evaluation.addedValue) {
      this.addedValue = this.data.evaluation.addedValue;
    }
    this.setDataTelegram();
    this.form = this.formBuilder.group({
      addedValue: [
        '',
        [Validators.required, Validators.min(-100), Validators.max(100)],
      ],
      creditLine: [''],
      comment: [''],
      creditLineType: [''],
    });

    this.formTelegram = this.formBuilder.group({
      companyDetail: ['', [Validators.required]],
    });
    this.mapStatus(this.dictamen);
  }

  setDataTelegram() {
    if (
      this.data.evaluation.debtor?.companyDetail ||  this.data.evaluation.companyDetail  ) {
        console.log(this.data)
      //this.calculateEvaluation(this.data.evaluation.addedValue)
      this.companyDetail =
        this.data.evaluation.debtor?.companyDetail
          ? this.data.evaluation.debtor.companyDetail
          : this.data.evaluation.companyDetail;
      this.detailUpdatedAt = this.data.evaluation.detailUpdatedAt || this.data.evaluation?.debtor?.detailUpdatedAt;
    }

  }

  calculateDictamen() {
    if (this.totalValue && this.totalValue >= 70) {
      this.dictamen = 'approved';
      this.updateValidorFields();
    } else {
      this.dictamen = 'rejected';
      this.resetValidators();
    }
    this.mapStatus(this.dictamen);
  }

  updateValidorFields() {
    this.form
      .get('creditLine')
      .setValidators([
        Validators.required,
        MoneyValidator(1, 2),
        Validators.min(0),
        Validators.max(100000000),
      ]);
    this.form.get('creditLineType').setValidators([Validators.required]);
    // this.form.get('companyDetail').setValidators([Validators.required]);
    this.form.get('creditLine').updateValueAndValidity();
    this.form.get('creditLineType').updateValueAndValidity();
    //this.form.get('companyDetail').updateValueAndValidity();
  }

  resetValidators() {
    this.form.get('creditLine').setValidators([]);
    //  this.form.get('companyDetail').setValidators([]);
    this.form.get('creditLineType').setValidators([]);
    this.form.get('creditLine').updateValueAndValidity();
    this.form.get('creditLineType').updateValueAndValidity();
    //  this.form.get('companyDetail').updateValueAndValidity();
  }

  calculateTotalRate() {
    this.totalValue =
      Number(this.addedValue || 0) + Number(this.data.evaluation.totalValue);
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      let status = this.dictamen;
      let totalRating = this.totalValue.toFixed(0);
      let rating = this.raiting;
      let finalForm;
      let companyDetail = this.data.evaluation.debtor?.companyDetail || this.data.evaluation.companyDetail;
      let detailUpdatedAt = this.detailUpdatedAt;
      let evaluationFinishedAt = new Date();
      if (totalRating >= 70) {
        finalForm = {
          totalRating,
          rating,
          status,
          companyDetail,
          detailUpdatedAt,
          evaluationFinishedAt,
          ...this.form.value,
        };
      } else {
        let comment = this.form.get('comment').value;
        let addedValue = this.form.get('addedValue').value;
        finalForm = {
          totalRating,
          rating,
          status,
          comment,
          addedValue,
          evaluationFinishedAt,
        };
      }

      this.dialogRef.close(finalForm);
    }
  }

  mapStatus(val) {
    if (val === 'approved') {
      this.label = 'Aprobado';
      this.color = 'green';
    } else if (val === 'rejected') {
      this.label = 'Denegado';
      this.color = 'red';
    } else {
      this.label = '---';
      this.color = 'yellow';
    }
  }

  calculateEvaluation(value) {
    if (value) {
      this.addedValue = value;
      this.calculateTotalRate();
      this.raiting = this.service.calculateRating(this.totalValue);
      this.calculateDictamen();
    }
  }

  updateTelegram() {
    this.formTelegram.markAllAsTouched();
    this.formTelegram.updateValueAndValidity();

    if (this.formTelegram.valid) {
      let obj = {
        companyDetail: this.formTelegram.get('companyDetail').value,
        _id: this.data.evaluation.debtor._id,
      };

      this.companyDetail = this.formTelegram.get('companyDetail').value;
      this.service.saveNote(obj, 'edit').subscribe((data) => {
        this.data.evaluation = data;
        this.setDataTelegram();
        this.savedFlag.next(true);
        setTimeout(() => {
          this.savedFlag.next(false);
        }, 1000);
      });
    }
  }
}
