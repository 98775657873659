<p class="subtitle-page">Vas a realizar la siguiente evaluación:</p>
<div class="code-table matrix-table">
    <table>
        <tr>
            <th>Fecha y dictamen</th>
            <th>Girador</th>
            <th>Línea otorgada</th>
            <th>P. parcial</th>
            <th>C. experto</th>
            <th>P. total</th>
            <th>Rating</th>
        </tr>
        <ng-container *ngIf="entity && entity.length > 0">
           
            <tr *ngFor="let eval of entity">
                <td>
                    <app-date-dictament [entity]="eval"></app-date-dictament>
                </td>

                <td>
                    <div class="tooltip-container-not-dots" *ngIf="eval?.supplier && eval?.supplier.companyName; else na_value">{{eval.supplier?.companyName | limitTo:"15" | uppercase}}
                        <span class="tooltiptext-bottom">RUC: {{ eval.supplier?.companyRuc }}</span>
                    </div>
                </td>
                <td>
                    <div class="container">
                        <div *ngIf="eval.creditLine; else na_value" class="credit-line">
                            <div class="text">
                                <span>
                                    {{(eval.creditLine | numberSpacing) }}
                                </span>
                            </div>
                        </div>
                        <div *ngIf="eval.creditLine" class="tooltip-container-not-dots icon">
                            <app-rounded-button-action [text]="mapCreditLine(eval.creditLineType)" [background]="'black'">
                            </app-rounded-button-action>
                            <span class="tooltiptext-bottom">{{ eval.creditLineType === 'line' ? 'Linea' : 'Puntual'}}</span> 
                        </div>

                        <div *ngIf="!eval.creditLine" class="tooltip-container-not-dots icon">
                            <app-rounded-button-action [text]="mapCreditLine(eval.creditLineType)" [background]="'black'">
                            </app-rounded-button-action>
                            <span class="tooltiptext-bottom">-</span> 
                        </div>
                    </div>
                   
                </td>
                <td>
                    <div *ngIf="eval.totalValue; else na_value">
                        {{eval.totalValue}}%
                    </div>
                </td>
                <td>
                    <div *ngIf="eval.addedValue; else na_value">
                        {{eval.addedValue}}%
                    </div>
                </td>
                <td>
                    <div *ngIf="eval.totalRating && eval.status !== 'pending'; else na_value">
                        {{eval.totalRating}}%
                    </div>
                </td>
                <td>
                    <div *ngIf="eval.rating && eval.status !== 'pending'; else na_value">
                        {{eval.rating | uppercase}}
                    </div>
                </td>
            </tr>
        </ng-container>
        
    </table>
    <ng-container *ngIf="entity[0].totalRating >= 70">
        <div class="message-container">
            <h6>Telegram</h6>
           <p class="telegram-message">{{ entity[0].companyDetail || entity[0].debtor?.companyDetail || '---' }}</p>
        </div>
        <div class="container-date">
            <p class="title-date">Fecha de actualización</p>
            <div class="item">
                <app-date displayStyle='plain' [date]="getDate()">
                </app-date>
            </div>
        </div>

    </ng-container>
</div>
<ng-template #na_value>
    <span class="value">---</span>
</ng-template>