<div class="d-flex flex-justify-between margin-bottom-24">
  <lib-data-point class="v-margin-group">
    <div class="title">
      <lib-text color="dark-gray" content="Monto de adelanto"></lib-text>
    </div>
    <div class="value">
      <lib-text
        size="s"
        [content]="
          (entity?.currency | currencyToSign) +
          ' ' +
          (totalAmount | numberSpacing)
        "
      >
      </lib-text>
    </div>
  </lib-data-point>

  <lib-data-point aligned="right" class="v-margin-group">
    <div class="title">
      <lib-text color="dark-gray" content="Monto neto"></lib-text>
    </div>
    <div class="value">
      <lib-text
        size="s"
        [content]="
          (entity?.currency | currencyToSign) +
          ' ' +
          (netAmount | numberSpacing)
        "
      >
      </lib-text>
    </div>
  </lib-data-point>
</div>

<div class="progress-section margin-bottom-24">
  <app-progress-bar
    class="investment-progress-ch"
    [progress]="100 - entity?.availableBalancePercentage"
  >
  </app-progress-bar>

  <p class="investment-progress-label">
    Invertido
    {{ entity?.currency | currencyToSign }}
    {{
      investmentAmount === 0
        ? '0.00'
        : (investmentAmount.toFixed(2) | numberSpacing)
    }}
  </p>
</div>

<div class="partitions-section">
  <div class="margin-bottom-24">
    <h2>Particiones a crear</h2>
    <p class="description u-mb-3">
      Los montos son calculados en base al monto a adelantar al cliente.
    </p>
  </div>

  <div>
    <div
      *ngFor="
        let partition of entity?.partitions;
        let i = index;
        let isLast = last;
        let isFirst = first
      "
      class="partition-container margin-bottom-24"
    >
      <div class="partition-item">
        <div class="amount">
          <div class="secuence">
            {{ partition?.code }}
          </div>
          <div class="d-flex flex-justify-between">
            <div class="u-mt-2">
              <p class="label">Monto de adelanto</p>

              <lib-text
                color="black"
                size="s"
                weight="medium"
                [content]="partition?.amount | numberSpacing"
              >
              </lib-text>
            </div>
            <div class="u-mt-2">
              <p class="label">Monto neto</p>

              <lib-text
                color="black"
                size="s"
                weight="medium"
                [content]="calculateNetAmount(partition?.amount, isLast) | numberSpacing"
              >
              </lib-text>
            </div>
          </div>

          <div *ngIf="isFirst && isWithInvestment" class="warning-message u-mt-1">
            Reservado para el monto invertido
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
