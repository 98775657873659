<div class="container">
    <div class="status online" *ngIf=isSocketOnline>
        <div class="icon tooltip-container-not-dots">
            <svg xmlns="http://www.w3.org/2000/svg" width="17.272" height="12.604" viewBox="0 0 17.272 12.604"><defs><style>.a{fill:#12ab64 !important;}</style></defs><path class="a" d="M1.99,9.126a1.155,1.155,0,0,0,1.558.083A10.621,10.621,0,0,1,16.99,9.2a1.179,1.179,0,1,0,1.492-1.825,12.99,12.99,0,0,0-16.408,0,1.176,1.176,0,0,0-.083,1.75Zm6.467,6.467,1.225,1.225a.83.83,0,0,0,1.175,0l1.225-1.225a.832.832,0,0,0-.192-1.325,3.567,3.567,0,0,0-3.258,0A.844.844,0,0,0,8.457,15.593ZM5.348,12.485a1.176,1.176,0,0,0,1.525.108,5.886,5.886,0,0,1,6.8,0,1.186,1.186,0,0,0,1.525-.108l.008-.008a1.161,1.161,0,0,0-.108-1.758,8.258,8.258,0,0,0-9.65,0,1.164,1.164,0,0,0-.1,1.767Z" transform="translate(-1.64 -4.458)"/></svg>
        </div>
    </div>
    <div  class="status offline" *ngIf=!isSocketOnline>
        <div class="icon tooltip-container-not-dots">
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><style>.a{opacity:0.5;}.b{fill:none;}.c{fill:#6c727c;}</style></defs><g class="a"><path class="b" d="M0,0H20V20H0Z"/><path class="c" d="M16.991,8.791a1.179,1.179,0,1,0,1.492-1.825A12.938,12.938,0,0,0,8.024,4.25l2.158,2.158a10.563,10.563,0,0,1,6.808,2.383ZM15.1,10.316A7.736,7.736,0,0,0,13,9.233l2.458,2.458A1.139,1.139,0,0,0,15.1,10.316Zm-3.2,3.55a3.567,3.567,0,0,0-3.258,0,.836.836,0,0,0-.192,1.325l1.225,1.225a.83.83,0,0,0,1.175,0l1.225-1.225a.821.821,0,0,0-.175-1.325Zm4.775,1.392L3.707,2.291A.831.831,0,0,0,2.532,3.466l1.95,1.958A12.551,12.551,0,0,0,2.074,6.966a1.183,1.183,0,0,0-.083,1.75A1.155,1.155,0,0,0,3.549,8.8,10.57,10.57,0,0,1,6.257,7.191L8.116,9.05a8.026,8.026,0,0,0-2.658,1.258,1.162,1.162,0,0,0-.108,1.758l.008.008a1.176,1.176,0,0,0,1.525.108,5.946,5.946,0,0,1,3.308-1.075L15.5,16.416a.83.83,0,0,0,1.175,0,.81.81,0,0,0,0-1.158Z" transform="translate(-0.274 -0.341)"/></g></svg>
            <span  class="tooltiptext-right">La actualización automática no está funcionando. Por favor, actualiza la página.</span>
        </div>
    </div>
</div>
