<p class="title">{{ isTeaSelected ? 'Aplica la nueva tasa anualizada' : 'Edita la tasa anualizada'}}</p>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="!isTeaSelected" style="margin-top: 24px;">
        <app-input formControlName="tea" label="Tasa anualizada"></app-input>
    </div>

    <div *ngIf="isTeaSelected" style="margin-bottom: 20px;">
        <div class="subtitle" style="margin-top: 8px;">Selecciona las operaciones donde deseas aplicar la nueva
            tasa.</div>

        <div class="box" style="margin-top: 24px;">
            <div class="datapoint-title">Nueva tasa anualizada</div>
            <div class="datapoint-big-value">{{form.controls.tea.value}}</div>
        </div>

        <div class="operation-info" style="margin-top: 32px;">
            <div class="datapoint">
                <div class="datapoint-title">
                    Empresa pagadora
                </div>
                <div class="datapoint-value">
                    {{invoice.debtor.companyName | uppercase}}
                </div>
            </div>
            <div class="datapoint2">
                <div class="datapoint">
                    <div class="datapoint-title">
                        Moneda
                    </div>
                    <lib-chip background="blue" width="xxs" [content]="invoice.currency | uppercase"></lib-chip>
                </div>
                <div class="datapoint">
                    <div class="datapoint-title">
                        Operación
                    </div>
                    <lib-chip [background]="invoice.isConfirming ? 'black' : 'blue'" width="s"
                        [content]="invoice.isConfirming ? 'Confirming': 'Factoring'"></lib-chip>
                </div>
            </div>
        </div>

        <div class="code-table" style="margin-top: 24px;">
            <table>
                <tr>
                    <th>
                        <mat-checkbox (change)="selectAll()" [checked]="isAllChecked()"
                            [indeterminate]="isSomeChecked()" color="accent">
                        </mat-checkbox>
                    </th>
                    <th>Código</th>
                    <th>Monto neto</th>
                    <th>Fecha pago</th>
                    <th>Tasa actual</th>
                </tr>
                <tr *ngFor="let code of invoices; let i = index">
                    <td>
                        <app-checkbox [disabled]="i===0" (selectionChanged)="setSelection(code)"
                            [checked]="code.selected" content="">
                        </app-checkbox>
                    </td>
                    <td>
                        <span [matTooltip]="getCodes(code)">
                            <span class="code"
                                *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                                <ng-container *ngIf="index<2">
                                    {{ subInvoice.code }}<span *ngIf="!isLast">, </span>
                                </ng-container>
                                <ng-container *ngIf="isLast && index >= 2">
                                    ...
                                </ng-container>
                            </span>
                        </span>
                    </td>
                    <td style="font-weight: 500;">
                        {{code.currency === 'pen' ? 'S/ ' : '$ ' }} {{code.netAmount}}
                    </td>
                    <td>{{ code.paymentDate | formatDate2 }}</td>
                    <td>{{ code.tea }}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="button-container">
        <fs-ui-button (click)="isTeaSelected ? onSubmit() : continue()"
            [label]="isTeaSelected ? 'Aplicar nueva tasa' : 'Continuar'"></fs-ui-button>
        <fs-ui-button (click)="isTeaSelected ? goBack() : cancel()" [label]="isTeaSelected ? 'Regresar' : 'Cancelar'"
            [type]="'tertiary'"></fs-ui-button>
    </div>

</form>