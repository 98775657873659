import { Big } from 'big.js';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { MoneyValidator } from 'src/app/shared/validators/money.validator';
import { InvoiceService } from '../../../../invoices/services/invoice.service';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { MinTcemValue } from 'src/app/shared/validators/min-tcem-value.validators';
import { ReservaValidation } from 'src/app/shared/validators/reserva-value.validators';
import { MaxTcemValue } from 'src/app/shared/validators/max-tcem-value.validators';
import moment from 'moment';

@Component({
  selector: 'app-configure-invoice-dialog',
  templateUrl: './configure-invoice-dialog.component.html',
  styleUrls: ['./configure-invoice-dialog.component.scss'],
})
export class ConfigureInvoiceDialogComponent implements OnInit {
  form;
  invoiceId;
  tdmPercentage;
  reservationPercentage;
  distributionPercentage;
  evaluation;
  invoice;
  configuration;
  tea = 10.0;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  profile;
  constructor(
    public dialogRef: MatDialogRef<ConfigureInvoiceDialogComponent>,
    private formBuilder: FormBuilder,
    private service: InvoiceService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;
        }
      });
    if (this.profile.role === Roles.ACCOUNT_MANGER) {
      this.form = this.formBuilder.group({
        tdmPercentage: [
          '',
          [
            Validators.required,
            MinTcemValue(1.3),
            MaxTcemValue(10),
            MoneyValidator(1, 2),
          ],
        ],
        reservationPercentage: [
          '',
          [Validators.required, MoneyValidator(1, 2)],
        ],
        //tea: ['', [Validators.required, MoneyValidator(1, 2)]]
      });
    } else {
      this.form = this.formBuilder.group({
        tdmPercentage: [
          '',
          [Validators.required, MaxTcemValue(10), MoneyValidator(1, 2)],
        ],
        reservationPercentage: [
          '',
          [Validators.required, MoneyValidator(1, 2)],
        ],
      });
    }

    if (this.data._id) {
      this.invoiceId = this.data._id;
      this.fetchInvoice();
      this.fetchConfiguration();
    }
  }

  fetchInvoice() {
    this.service.byId(this.invoiceId).subscribe((data) => {
      if (data) {
        this.invoice = data;
        if (this.data?.tdm && this.data?.reservation && this.invoice) {
          this.tdmPercentage = this.data.tdm;
          this.reservationPercentage = this.data?.reservation;
          this.form.get('tdmPercentage').setValue(this.tdmPercentage);
          this.form
            .get('reservationPercentage')
            .setValue(this.reservationPercentage);
          this.calculateEvaluation();
        }
      }
    });
  }

  fetchConfiguration() {
    this.service.getConfiguration(this.invoiceId).subscribe((data) => {
      if (data) {
        // console.log(data);
        this.configuration = data;
      }
    });
    // this.configuration = {
    //   tdmPercentage: "3.00",
    //   reservationPercentage: "15.00",
    //   distributionPercentage: "35.00",
    // }
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      const payload = {
        tdmPercentage: this.form.value.tdmPercentage,
        reservationPercentage: this.form.value.reservationPercentage,
        distributionPercentage: this.getDistribution(
          this.tea,
          this.form.value.tdmPercentage
        ),
      };
      if (this.profile.role === Roles.ACCOUNT_MANGER && this.evaluation <= 15) {
        console.log('error');
      } else {
        // console.log(payload);
        this.dialogRef.close(payload);
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  calculateEvaluation($value?) {
    if (
      this.tdmPercentage !== undefined &&
      this.tdmPercentage !== '' &&
      this.reservationPercentage !== undefined &&
      this.reservationPercentage !== ''
    ) {
      this.evaluation = this.getEvaluation();
    } else {
      this.evaluation = '';
    }
  }

  getEvaluation() {
    this.distributionPercentage = this.getDistribution(
      this.tea,
      this.tdmPercentage
    );

    const tem = this.service.getTem(
      this.tdmPercentage,
      this.distributionPercentage
    );

    const commissionPercentage = this.service.getCommissionPercentage(
      this.tdmPercentage,
      this.invoice.actualDuration || this.invoice.minimumDuration
    );
    const commissionAmount = this.service.getAmountFromPercentage(
      this.invoice.netAmount,
      commissionPercentage
    );
    const advancePercentage = this.service.getAdvancePercentage(
      this.reservationPercentage
    );

    const advanceAmount = this.service.getAmountFromPercentage(
      this.invoice.netAmount,
      advancePercentage
    );

    const investorsReturn = this.service.getInvestorsReturnAmount(
      advanceAmount,
      this.invoice.actualDuration || this.invoice.minimumDuration,
      tem
    );
    // const revenueAmount = this.service.getFinsmartRevenueAmount(
    //   commissionAmount,
    //   investorsReturn
    // );

    let commissionAdjustment: any;

    if (!this.invoice.isConfirming) {
      Big.DP = 40;
      Big.RM = 2;

      const threshold = this.invoice.currency === 'pen' ? 150 : 40;

      commissionAdjustment = new Big(threshold).minus(commissionAmount);

      if (commissionAdjustment.gt(0)) {
        commissionAdjustment = commissionAdjustment.toFixed(2);
      } else {
        commissionAdjustment = '0.00';
      }
    }

    // const taxAmount = this.service.getTaxAmount(revenueAmount);
    const reservationAmount = this.service.getAmountFromPercentage(
      this.invoice.netAmount,
      this.reservationPercentage
    );

    return this.service.isNewFormula(this.invoice.createdAt)
      ? this.service.getBackupDaysNewV2(
          this.invoice.netAmount,
          this.tdmPercentage / 100,
          this.reservationPercentage / 100,
          this.distributionPercentage / 100,
          Number(reservationAmount),
          this.invoice.paymentDate,
          moment().format('YYYY-MM-DD'),
          commissionAdjustment || this.invoice.commissionAdjustment,
          Number(tem)
        )
      : this.service.getBackupDays(
          this.invoice.netAmount,
          this.invoice.paymentDate,
          this.reservationPercentage,
          this.tdmPercentage
        );
  }

  getDistribution(tea: any, tdm: any) {
    let normalizedTea = Number(tea) / 100;
    let normalizedTdm = Number(tdm) / 100;

    let tem = Number((Math.pow(normalizedTea + 1, 1 / 12) - 1).toFixed(6));

    return ((tem / normalizedTdm) * 100).toFixed(10);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isNumber(val: any) {
    return typeof val === 'number';
  }
}
