import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { AppState } from 'src/app/app.states';
import { FinancialTransactionActions } from 'src/app/features/financial-transactions/state/financial-transactions.action-types';
import { transactionField } from 'src/app/features/financial-transactions/state/financial-transactions.selectors';
import { InvoiceService } from 'src/app/features/invoices/services/invoice.service';
import { InvoicesActions } from 'src/app/features/invoices/state/invoices.action-types';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { InvoiceStatus } from 'src/app/shared/enums/InvoiceStatus.enum';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { showToast } from 'src/app/shared/util/toaster.util';
import { NumberSpacingPipe } from '../../../../shared/pipes/number-spacing.pipe';
import { DisplayFileComponent } from '../display-file/display-file.component';

@Component({
  selector: 'app-invest-info-detail',
  templateUrl: './invest-info-detail.component.html',
  styleUrls: ['./invest-info-detail.component.scss'],
})
export class InvestInfoDetailComponent implements OnInit {
  @Input() payload;
  investmentsSum$;
  debtorStats;
  mode;
  transaction;
  operationYear = new Date().getFullYear() - 1;
  tabInvoiceDetail = true;
  tabRiskData = false;
  tabOperations = false;
  tabHistory = false;
  selectedTab;
  ratingDisclaimerOpen = false;
  parentTab;
  codes = '';
  isDisclaimerVisible = false;
  edit = false;
  constructor(
    private spinner: NgxSpinnerService,
    public invoiceService: InvoiceService,
    private overlayService: OverlayService,
    private store: Store<AppState>,
    private windowScrollService: WindowScrollService,
    private numberSpacing: NumberSpacingPipe,
    private toaster: Toaster
  ) {}

  ngOnInit(): void {
    this.getInvoiceById();
    this.getConfigurations();
  }

  getConfigurations() {
    this.mode =
      this.payload.status === InvoiceStatus.Finalized ? 'historic' : 'actual';

    this.invoiceService
      .getDebtorStatsByRUC(this.payload.debtor._id)
      .subscribe((debtorStats) => {
        this.debtorStats = debtorStats;
      });
    this.investmentsSum$ = this.payload.investmentAmount;
    this.store
      .select(transactionField(this.payload._id, 'isNewAnnouncement', true))
      .subscribe((data) => {
        if (data) {
          this.transaction = data;
        }
      });
    this.parentTab = this.payload.parentTab;
    this.codes = this.payload.physicalInvoices.map((i) => i.code).join('\n');

    this.readAnnouncement();

    this.isDisclaimerVisible = this.payload?.announcements?.some(
      (a: any) => a.type !== 'automatic'
    );
  }

  getInvoiceById() {
    this.store
      .select(InvoiceSelectors.byId(this.payload._id))
      .subscribe((invoice) => {
        if (invoice) {
          // this.payload = invoice
          if (this.edit) {
            showToast(this.toaster, 'Gracias por tu respuesta', true, true);
            this.edit = false;
          }
        }
      });
  }
  readAnnouncement() {
    if (this.transaction?.isNewAnnouncement) {
      let entity = {
        isNewAnnouncement: false,
        _id: this.transaction._id,
      };
      this.store.dispatch(
        FinancialTransactionActions.ReadAnnouncement({ entity })
      );
    }
    this.updateAnnouncement();
  }
  ngAfterViewInit() {
    this.sendEventPageView();
  }

  updateAnnouncement() {
    this.invoiceService
      .updateAnnouncementLog(this.payload?._id, this.payload?.origin)
      .subscribe();
  }

  sendEventPageView() {
    this.windowScrollService.sendNewEvent(
      'My Investments',
      `Detail`,
      this.getTabName()
    );
  }

  updateTabSelected(e) {
    this.selectedTab = e;
    this.sendEventPageView();
  }

  getRiskData(opportunity, criteria, attribute = 'data') {
    if (opportunity?.evaluation?.criterias) {
      let value =
        opportunity?.evaluation?.criterias?.find((c) => c.item === criteria)?.[
          attribute
        ] || '0';
      if (
        criteria === 'directDebt' ||
        criteria === 'indirectDebt' ||
        criteria === 'mortgageGuarantees' ||
        criteria === 'unclearProtests'
      ) {
        if (!isNaN(value)) value = Number(value).toFixed(2);
        return 'S/ ' + this.numberSpacing.transform(value);
      } else {
        return value;
      }
    } else {
      if (
        criteria === 'directDebt' ||
        criteria === 'indirectDebt' ||
        criteria === 'mortgageGuarantees' ||
        criteria === 'unclearProtests'
      ) {
        return 'S/ 0.00';
      } else {
        return '';
      }
    }
  }

  getAdditionalInfo(opportunity, attribute = 'data') {
    if (opportunity?.evaluation?.additionalInfo) {
      let value =
        opportunity?.evaluation?.additionalInfo?.find(
          (c) => c.year == this.operationYear
        )?.[attribute] || '0.00';
      if (!isNaN(value)) value = Number(value);
      if (attribute == 'exportsOpsValue' || attribute === 'importsOpsValue') {
        return value == '0.00' ? '0' : value;
      } else {
        value = Number(value).toFixed(2);
        return '$ ' + this.numberSpacing.transform(value);
      }
    } else {
      if (attribute == 'exportsOpsValue' || attribute === 'importsOpsValue') {
        return '0';
      } else {
        return '$ 0.00';
      }
    }
  }

  getTabName() {
    switch (this.selectedTab) {
      case 0:
        return `${this.payload.parentTab} Detalle de la factura`;
      case 1:
        if (this.payload.evaluation) {
          return `${this.payload.parentTab} Data de riesgos`;
        } else {
          return `${this.payload.parentTab} Historial con Finsmart`;
        }
      case 2:
        return `${this.payload.parentTab} Historial con Finsmart`;
      default:
        return `${this.payload.parentTab} Detalle de la factura`;
    }
  }

  openIntercom() {
    (<any>window).Intercom('show');
  }

  openViewer(announcement) {
    this.spinner.show();
    this.invoiceService
      .getAnnouncementFile(announcement.file)
      .subscribe((voucherFile: any) => {
        if (voucherFile.file) {
          this.overlayService.showComponent(
            DisplayFileComponent,
            voucherFile.file
          );
        } else {
          window.alert('¡No se encuentra el archivo!');
        }

        this.spinner.hide();
      });
  }

  updateReply(data) {
    let entity = { ...data };
    entity._id = this.payload._id;
    this.store.dispatch(
      InvoicesActions.SaveAnnouncement({ entity, mode: 'edit' })
    );

    this.edit = true;
  }
}
