import { MixpanelService } from './../../../services/mixpanel.service';
import { Component, OnInit } from '@angular/core';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { AuthActions } from 'src/app/features/authentication/state/auth.action-types';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { SharedActions } from 'src/app/shared/state/shared.action-types';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.organism.html',
  styleUrls: ['./side-menu.organism.scss'],
})
export class SideMenuOrganism implements OnInit {
  isFromInvoice = false;
  menuItems = [
    {
      type: 'link',
      label: 'Operaciones',
      allowedRoles: [
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.RISK_ANALYST,
        Roles.CLIENT_HAPPINESS,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
      ],
      target: '/operations',
      imgIcon: 'ops3.svg',
      // fontIconClass: 'icon-invoices'
    },
    {
      type: 'link',
      label: 'Facturas',
      allowedRoles: [Roles.ADMIN, Roles.SUPER_ADMIN, Roles.CLIENT_HAPPINESS],
      target: '/invoices',
      imgIcon: 'invoices.svg',
      // fontIconClass: 'icon-invoices'
    },
    {
      type: 'link',
      label: 'Operaciones',
      allowedRoles: [Roles.CLIENT],
      target: '/invoices',
      imgIcon: 'invoices.svg',
      // fontIconClass: 'icon-invoices'
    },
    {
      type: 'link',
      label: 'Oportunidades',
      allowedRoles: [Roles.INVESTOR],
      target: '/opportunities',
      imgIcon: 'opportunities.svg',
      // fontIconClass: 'icon-opportunities',
    },
    {
      type: 'link',
      label: 'Mis inversiones',
      allowedRoles: [Roles.INVESTOR],
      target: '/my-investments',
      imgIcon: 'investments.svg',
      // fontIconClass: 'icon-my-investments'
    },
    {
      type: 'link',
      label: 'Clientes',
      allowedRoles: [Roles.CLIENT],
      target: '/my-clients',
      imgIcon: 'my-clients.svg',
    },
    {
      type: 'link',
      label: 'Proveedores',
      allowedRoles: [Roles.CLIENT],
      target: '/my-suppliers',
      imgIcon: 'my-supliers.svg',
    },
    {
      type: 'link',
      label: 'Cuentas bancarias',
      allowedRoles: [Roles.CLIENT],
      target: '/bank-accounts',
      imgIcon: 'bank-accounts.svg',
      // fontIconClass: 'icon-bank-accounts'
    },
    {
      type: 'link',
      label: 'Cuentas bancarias',
      allowedRoles: [Roles.INVESTOR],
      target: '/bank-accounts',
      imgIcon: 'bank-accounts.svg',
      // fontIconClass: 'icon-bank-accounts'
    },
    {
      type: 'link',
      label: 'Transacciones financieras',
      allowedRoles: [Roles.ADMIN, Roles.SUPER_ADMIN, Roles.INVESTOR],
      target: '/financial-transactions',
      imgIcon: 'transactions.svg',
      // fontIconClass: 'icon-financial-transactions'
    },
    {
      type: 'link',
      label: 'Ajustes del sistema',
      allowedRoles: [Roles.ADMIN, Roles.SUPER_ADMIN],
      target: '/system-settings',
      imgIcon: 'settings.svg',
      // fontIconClass: 'icon-system-settings'
    },
    {
      type: 'link',
      label: 'Cuentas de Usuarios',
      allowedRoles: [Roles.ADMIN, Roles.SUPER_ADMIN],
      target: '/users-accounts',
      imgIcon: 'my-clients.svg',
      // fontIconClass: 'icon-user-accounts'
    },
    {
      type: 'link',
      label: 'Pendientes',
      allowedRoles: [Roles.RISK_ANALYST],
      target: '/risk-pending-evaluations',
      imgIcon: 'opportunities.svg',
    },
    {
      type: 'link',
      label: 'Pagadores',
      allowedRoles: [Roles.RISK_ANALYST, Roles.ACCOUNT_MANGER, Roles.ACCOUNT_MANAGER_SUPERVISOR],
      target: '/risk-debtors',
      imgIcon: 'my-clients.svg',
    },
    {
      type: 'separator',
      allowedRoles: [
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.INVESTOR,
        Roles.CLIENT,
        Roles.RISK_ANALYST,
        Roles.CLIENT_HAPPINESS,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
      ],
    },
    {
      type: 'feedback',
      label: 'Feedback',
      allowedRoles: [Roles.CLIENT, Roles.INVESTOR],
      target: '',
      imgIcon: 'feedback-icon.svg',
    },
    {
      type: 'newTab',
      label: 'Preguntas frecuentes',
      allowedRoles: [Roles.CLIENT, Roles.INVESTOR],
      target: 'https://ayuda.finsmart.pe/es/',
      imgIcon: 'repeated-questions.svg',
    },
    {
      type: 'link',
      label: 'Mis datos personales',
      allowedRoles: [
        Roles.CLIENT,
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.INVESTOR,
        Roles.RISK_ANALYST,
        Roles.CLIENT_HAPPINESS,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
      ],
      target: '/profile-settings',
      imgIcon: 'my-profile.svg',
      // fontIconClass: 'icon-profile'
    },
    {
      type: 'button',
      label: 'Cerrar sesión',
      allowedRoles: [
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.INVESTOR,
        Roles.CLIENT,
        Roles.RISK_ANALYST,
        Roles.CLIENT_HAPPINESS,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
      ],
      imgIcon: 'logout.svg',
      // fontIconClass: 'icon-logout',
      action: AuthActions.ManualLogOut,
    },
  ];
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private mixpanelService: MixpanelService,
    private windowScrollService: WindowScrollService
  ) {}

  ngOnInit() {
    this.windowScrollService.previousUrl$.subscribe((previousUrl: string) => {
      // console.log('previous url: ', previousUrl);
      if (previousUrl === '/invoices') {
        this.isFromInvoice = true;
      }
    });
  }

  clicked(payload, item) {
    if (this.router.url !== payload.target) {
      switch (payload.type) {
        case 'link':
          this.router.navigateByUrl(payload.target);
          break;
        case 'button':
          this.store.dispatch(payload.action());
          break;
      }
    } else {
      this.store.dispatch(SharedActions.HideSideMenu());
    }

    this.store.dispatch(SharedActions.HideToggleMenu());

    this.mixpanelService.newEvent('Side Menu', { $option: item.label });
    this.removeLocalStorage();
  }

  isActive(target) {
    // console.log('target', this.router.url.split('/'));
    let routeLength = this.router.url.split('/')?.length;
    const activeRoute = '/' + this.router.url.split('/')[1];
    let activeRouteWithoutQuery = activeRoute.split('?')[0];
    activeRouteWithoutQuery = activeRoute.split('#')[0];
    if (activeRouteWithoutQuery === '/invoices' && routeLength > 2) {
      if (this.isFromInvoice && target === '/invoices') {
        return 'active';
      }
      if (!this.isFromInvoice && target === '/operations') {
        return 'active';
      }
    } else {
      return activeRouteWithoutQuery === target ? 'active' : '';
    }
  }

  hideMenu() {
    this.store.dispatch(SharedActions.HideSideMenu());
  }

  removeLocalStorage() {
    localStorage.removeItem('tab_transactions');
    localStorage.removeItem('tab_opportunities_pen');
    localStorage.removeItem('tab_opportunities_usd');
    localStorage.removeItem('tab_operation_detail');
    localStorage.removeItem('productTourStart');
  }
}
