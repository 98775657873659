import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequiredValidator } from 'src/app/shared/validators/required.validator';
import { InvoiceService } from '../../../services/invoice.service';
import { GreaterThanZeroValidator } from 'src/app/shared/validators/greater-than-zero.validators';
import { TEAValidation } from 'src/app/shared/validators/tea-value.validators';
import { AmountValidator } from 'src/app/shared/validators/amount.validator';

@Component({
  selector: 'app-invoice-tea-configuration-dialog',
  templateUrl: './invoice-tea-configuration-dialog.component.html',
  styleUrls: ['./invoice-tea-configuration-dialog.component.scss'],
})
export class InvoiceTeaConfigurationDialogComponent implements OnInit {
  form: FormGroup;
  direction = 'column';
  _selectedValue;
  invoices = [];
  invoice;
  isTeaSelected = false;

  constructor(
    public dialogRef: MatDialogRef<InvoiceTeaConfigurationDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _service: InvoiceService
  ) {}

  ngOnInit(): void {
    this.invoice = this.data.invoice;

    const filters = {
      debtor: this.invoice.debtor._id,
      currency: this.invoice.currency,
      isConfirming: this.invoice.isConfirming,
      statuses: ['pending confirmation', 'configured'],
    };

    this._service.getGroupInvoice(filters, null).subscribe((result: any) => {
      this.invoices = result.entities;

      this.invoices.sort((a, b) => {
        if (String(a._id) === String(this.invoice._id)) return -1;
        if (String(b._id) === String(this.invoice._id)) return 1;
        return 0;
      });

      this.invoices.find(
        (i) => String(i._id) === String(this.invoice._id)
      ).selected = true;
    });

    this.form = this.formBuilder.group({
      tea: [
        '',
        [
          RequiredValidator,
          AmountValidator,
          GreaterThanZeroValidator,
          TEAValidation(18),
        ],
      ],
    });

    this.form.controls['tea'].setValue(this.invoice.tea);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      let result = {
        teaPercentage: this.form.controls['tea'].value,
        codes: this.invoices
          .filter((invoice) => invoice.selected)
          .map((invoice) => {
            return String(invoice._id);
            // return {
            //   _id: String(invoice._id),
            // };
          })
          .join(','),
        _id: String(this.invoice._id),
      };
      this.dialogRef.close(result);
    }
  }

  continue() {
    this.isTeaSelected = true;
  }

  goBack() {
    this.isTeaSelected = false;
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  getCodes(invoice) {
    return invoice.physicalInvoices.map((i) => i.code).join('\n');
  }

  selectAll() {
    if (this.invoices.some((i) => !i.selected)) {
      this.invoices.forEach((invoice) => (invoice.selected = true));
    } else {
      this.invoices.forEach(
        (invoice) =>
          (invoice.selected = String(invoice._id) === String(this.invoice._id))
      );
    }
  }

  isSomeChecked(): boolean {
    return this.invoices.some((i) => !i.selected);
  }

  isAllChecked() {
    return this.invoices.every((i) => i.selected);
  }

  setSelection(invoice) {
    this.invoices.find(
      (inv) => String(inv._id) === String(invoice._id)
    ).selected = !this.invoices.find(
      (inv) => String(inv._id) === String(invoice._id)
    ).selected;
  }

  propagateChange = (_: any) => {};
}
