import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { FinancialtransactionsService } from 'src/app/features/financial-transactions/services/financial-transactions.service';

@Injectable({ providedIn: 'root' })
export class UniqueDepositDocumentNumber {
  constructor(private transactionService: FinancialtransactionsService) {}

  public isDocumentNumberUnique(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return new Promise((resolve, reject) => {
        return timer(0)
          .pipe(
            switchMap(() =>
              this.transactionService.checkDocumentNumberDuplication(control.value)
            )
          )
          .subscribe(
            (res) => {
              console.log(res);
              resolve(null);
            },
            (err) => resolve({ isDuplicateNumber: true })
          );
      });
    };
  }
}
