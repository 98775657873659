<h1>Modificar mensaje</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <div class="comment-container">
            <mat-form-field [style.fontSize]="12" appearance="outline">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="companyDetail"
                    cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" [(ngModel)]="companyDetail">
                    </textarea>
            </mat-form-field>
            <mat-hint *ngIf="form.controls.companyDetail?.touched && form.controls.companyDetail?.invalid">
                <p [ngClass]="{'custom-mat-invalid-style': form.controls.companyDetail?.invalid === true}">Mensaje (obligatorio)</p>
            </mat-hint>
        </div>
        <div class="container-date">
            <p class="title-date">Fecha de actualización</p>
            <div class="item">
                <app-date displayStyle='plain' [date]="data?.debtor.detailUpdatedAt">
                </app-date>
            </div>
        </div>
    </div>

    <input type="submit" class="button button-blue" value="Guardar" />
    <input type="button" class="button button-gray" value="Cancelar" (click)="cancel()" />
</form>