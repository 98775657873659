import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterIn'
})
export class FilterInPipe implements PipeTransform {

    transform(items: any[], key: string, posibilities: string[]): any {
      if(items) {
        return items.filter(item => posibilities.includes(item[key]))
      } else {
        return []
      }
    }

}