import { updateOperations } from './../util/ngrx.util';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { FinancialTransactionActions } from 'src/app/features/financial-transactions/state/financial-transactions.action-types';
import { LoadAllOpportunities } from 'src/app/features/invoices/state/invoices.actions';
import { InvoicesActions } from 'src/app/features/invoices/state/invoices.action-types';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { filter, first, map, take } from 'rxjs/operators';
import { Roles } from '../enums/Roles.enum';
import { UsersActions } from 'src/app/features/users/state/users.action-types';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  baseEndpoint = environment.socketEndpoint;
  socket: Socket;
  loggedInUserRole;
  loggedInUserID;
  isConnected$: Subject<any> = new BehaviorSubject<any>(false);
  socketEvent: EventEmitter<any> = new EventEmitter();

  constructor(private store: Store<AppState>) {}

  isRealTimeEnabledForCurrentUserRole() {
    this.getCurrentUserId();

    return this.store
      .pipe(
        select(UsersSelectors.profileProperty('role')),
        map((currentRole) =>
          [
            Roles.INVESTOR,
            Roles.CLIENT,
            Roles.CLIENT_HAPPINESS,
            Roles.RISK_ANALYST,
            Roles.ADMIN,
            Roles.SUPER_ADMIN,
            Roles.ACCOUNT_MANGER,
            Roles.ACCOUNT_MANAGER_SUPERVISOR,
          ].includes(currentRole)
        ),
        first()
      )
      .toPromise();
  }

  async getCurrentUserId() {
    await this.store
      .select(UsersSelectors.profileProperty('_id'))
      .pipe(take(1))
      .subscribe((user) => (this.loggedInUserID = user));
  }

  async disconnect() {
    if (await this.isRealTimeEnabledForCurrentUserRole()) {
      if (this.socket && !this.socket.disconnected) this.socket.disconnect();
    }
  }

  async setupSocketConnection(accessToken) {
    //console.log('!!!!REALTIME', await this.getCurrentUserId());

    if (await this.isRealTimeEnabledForCurrentUserRole()) {
      this.socket = io(this.baseEndpoint, {
        query: { accessToken },
      });

      /***********************/
      /* CORE EVENT LISTENERS */
      /***********************/
      this.socket.on('connect', () => {
        this.isConnected$.next(true);
      });

      this.socket.on('reconnect', () => {
        console.log('reconnect');
        //this.isConnected$.next(true);
      });

      this.socket.on('disconnect', () => {
        this.isConnected$.next(false);
        console.log('disconnect');
      });

      /*
      this.socket.onAny((event, ...args) => {
        console.log(`got ${event}`);
      });
      */

      /**********************/
      /* REAL TIME TRIGGERS */
      /**********************/

      this.socket.on('rt_opportunities', (args) => {
        //console.log(args)
        //this.store.dispatch(FinancialTransactionActions.LoadAll());
        this.store.dispatch(InvoicesActions.LoadAllOpportunities());
      });

      this.socket.on(
        'financialTransactions_investments_new_success',
        (payload) => {
          this.store.dispatch(
            InvoicesActions.OpportunityUpdated(payload.recalculatedOpportunity)
          );
        }
      );

      this.socket.on('user_approved', (args) => {
        this.store.dispatch(UsersActions.UpdateProfile(args));
      });

      this.socket.on('update_operation', (args) => {
        this.store.dispatch(InvoicesActions.UpdateOperations(args));
        this.socketEvent.emit('update_operation');
      });

      this.socket.on('remove_operation', (args) => {
        this.store.dispatch(InvoicesActions.RemoveOperations(args));
      });

      this.socket.on('investments_update', (payload) => {
        this.store.dispatch(
          InvoicesActions.InvestmentsUpdated(payload.recalculatedOpportunity)
        );
        this.socketEvent.emit({
          name: 'investments_update',
          data: payload.recalculatedOpportunity,
        });
      });

      this.socket.on('update_client', (args) => {
        this.socketEvent.emit('update_client');
      });

      this.socket.on('rt_transactions', (args) => {
        if (args._id) {
          if (this.loggedInUserID === args._id) {
            this.store.dispatch(FinancialTransactionActions.LoadAll());
          }
        } else if (args.userIds) {
          if (args.userIds.some((u: any) => u === this.loggedInUserID)) {
            this.store.dispatch(FinancialTransactionActions.LoadAll());
            this.store.dispatch(InvoicesActions.LoadAll());
          }
        }
      });

      /*****************/
      /* SOCKET EVENTS */
      /*****************/

      this.socket.on('connecting', () => {
        console.log('connecting');
      });
      this.socket.on('connection', () => {
        console.log('connection');
      });
      this.socket.on('connect_error', (err) => {
        console.log('connect_error');
        console.log(err);
      });
      this.socket.on('connect_timeout', () => {
        console.log('connect_timeout');
      });
      this.socket.on('reconnect_attempt', () => {
        console.log('reconnect_attempt');
      });
      this.socket.on('reconnect_error', () => {
        console.log('reconnect_error');
      });
      this.socket.on('reconnect_failed', () => {
        console.log('reconnect_failed');
      });
      this.socket.on('reconnecting', () => {
        console.log('reconnecting');
      });
      this.socket.on('ping', () => {
        console.log('ping');
      });
      this.socket.on('pong', () => {
        console.log('pong');
      });
    }
  }

  isSocketConnected() {
    return this.socket.connected;
  }

  sendMessage() {
    if (this.socket.disconnected) {
      this.socket.connect();
    }
    console.log(this.socket);
    this.socket.emit('my message', 'Hello there from Angular!!!!!');
  }
}
