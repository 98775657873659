<div class="layout">
    <span class="text" style="color:#6C727C;">{{totalCount}} operaciones</span>

    <lib-icon (click)="pageDown()" [ngStyle]="{'cursor': (isEnabled('left') ? 'pointer' : 'default')}"
        [color]="isEnabled('left') ? 'dark-gray' : 'light-gray'" style="margin-left: 12px;" icon="arrow_left" size="m">
    </lib-icon>

    <span class="text" style="margin-left:16px;color:#2D2D2D;">Pág. {{currentPage}}</span>


    <lib-icon (click)="pageUp()" [ngStyle]="{'cursor': (isEnabled('right') ? 'pointer' : 'default')}"
        [color]="isEnabled('right') ? 'dark-gray' : 'light-gray'" style="margin-left: 16px;" icon="arrow_right"
        size="m">
    </lib-icon>

    <span class="text" style="margin-left:12px;color:#6C727C;">{{totalPages == 1 ? '1 página' : totalPages +'
        páginas'}}</span>
</div>