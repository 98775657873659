import { Injectable } from '@angular/core';
import { RESTService } from 'src/app/shared/services/rest.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BankAccountsService {
  constructor(private rest: RESTService) {}

  getAllByUserId(userId): Observable<any> {
    return this.rest.get(`/users/${userId}/bank-accounts`);
  }
  getByAccountId(accountId): Observable<any> {
    return this.rest.get(`/bank-accounts/${accountId}`);
  }
  getAll(): Observable<any> {
    return this.rest.get(`/bank-accounts`);
  }
  createForUser(bankAccount): Observable<any> {
    const userId = _.pick(bankAccount, 'user').user;
    const data = _.pick(bankAccount, ['name', 'cci', 'currency', 'number']);
    return this.rest.post(`/users/${userId}/bank-accounts`, data);
  }
  updateForUser(bankAccount): Observable<any> {
    const userId = _.pick(bankAccount, 'user').user;
    const data = _.pick(bankAccount, ['name', 'cci', 'currency', 'number']);
    const bankAccountId = _.pick(bankAccount, '_id')._id;

    return this.rest.patch(`/bank-accounts/${bankAccountId}`, data);
  }
  deleteForUser(bankAccount): Observable<any> {
    const bankAccountId = _.pick(bankAccount, '_id')._id;

    return this.rest.delete(`/bank-accounts/${bankAccountId}`, {
      body: { newBankAccount: bankAccount.newBankAccount },
    });
  }

  saveForUser(bankAccount, mode): Observable<any> {
    switch (mode) {
      case 'create':
        return this.createForUser(bankAccount);
      case 'edit':
        return this.updateForUser(bankAccount);
      case 'delete':
        return this.deleteForUser(bankAccount);
    }
  }
}
