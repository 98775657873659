

<div class="container">
    <div [ngClass]="(isLoggedIn$ | async) ? 'content left-side' : 'left-side'">
        <img *ngIf="!(isLoggedIn$ | async)" class="logo" src="../../../../../assets/icons/Grupo919.svg" alt="">
        <div class="fh">
            <img class="wrong-icon" src="../../../../../assets/icons/link_off_black.svg" alt="">
            <p class="something-wrong">Algo salió mal...</p>
        </div>
        <p *ngIf="!(isLoggedIn$ | async)" class="not-found-logout"><span>¡Esta página no existe!</span> Si llegaste por error,</p>
        <p *ngIf="(isLoggedIn$ | async)" class="not-found"><span>¡Esta página no existe!</span></p>
        <div  *ngIf="!(isLoggedIn$ | async)" class="button-container button-direction-column">
            <fs-ui-button [label]="'Regresa al Login'" routerLink="/"></fs-ui-button>
        </div>
    </div>

    <div *ngIf="!isMobile" class="right-side">
        <img [ngClass]="(isLoggedIn$ | async) ? 'small img-run' : 'mt img-run'" src="../../../../../assets/images/img.png" alt="">
    </div>
</div>
