<div class="box">
    <lib-tooltip *ngIf="tooltip" width="160" [content]="tooltip">
        <div class="info-icon"></div>
    </lib-tooltip>

    <lib-tooltip *ngIf="longValue" width="100" [content]="longValue" class="dots">
        <div class="more-dots"></div>
    </lib-tooltip>
  
    <div class="box-content" [style.top.%]="top">
        <span class="tiny tiny-title">{{label}}</span>
        <span *ngIf="!isCustomValue" class="tiny tiny-title value">
            {{value || '---'}}
        </span>

        <ng-content *ngIf="isCustomValue" select=".box-value"></ng-content>
        <span class="tiny tiny-title value" *ngIf="others">({{others}})</span>
    </div>
</div>