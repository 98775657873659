import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RiskService } from '../../../services/risk.service';
import { NgxSpinnerService } from 'ngx-spinner';
import FileSaver from 'file-saver';
import b64toBlob from 'b64-to-blob';
import { EvaluationsActions } from '../../../states/evaluations.action-types';
import { EvaluationSelectors } from '../../../states/evaluations.selector-types';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { DeleteDocConfirmationComponent } from '../delete-doc-confirmation/delete-doc-confirmation.component';
@Component({
  selector: 'app-evaluation-documents-dialog',
  templateUrl: './evaluation-documents-dialog.component.html',
  styleUrls: ['./evaluation-documents-dialog.component.scss']
})
export class EvaluationDocumentsDialogComponent implements OnInit {

  files = []
  documents
  isLoading = false
  constructor(
    public dialogRef: MatDialogRef<EvaluationDocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public riskService: RiskService,
    private spinner: NgxSpinnerService,
    public overlayService: OverlayService,


  ) { }

  ngOnInit(): void {
    // this.riskService.getAllBase64(this.data.evaluation._id).subscribe((data) => {
    //   console.log(data)
    //   this.documents = data.file
    // })
  }

  fileUpload(files){
  
    const filesPromises = [];

    try {
      Array.from(files).forEach((file: any) => {
        let filePromise = new Promise((resolve) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
        });
        filesPromises.push(filePromise);
      });

      Promise.all(filesPromises).then(async (files) => {
        for (let file of files) {
          let obj = {
            file: file,
            code: Date.now() + '' + Math.random()
          }
          this.files.push(obj)
        }
        this.saveFiles()
      });
    } catch (error) {
      console.log(error)
    }
  }

  saveFiles(){
    let documents = {
      documents: this.files
    }
    this.spinner.show();

    this.riskService.saveFiles(this.data.evaluation._id, documents).subscribe(data => {
      if(data){
        this.spinner.hide();
        this.data.evaluation.documents = data.documents;
        this.files = []
      }
    })

  }

  cancel(){
    this.dialogRef.close({event: 'Closed'});

  }

  getMimeType(file) {
    if(file){
      let extension = this.riskService.getMimeTypes(file)
      return extension
    }
  }

  downloadAllDocuments(evaluation){
      this.spinner.show();
  
      this.riskService.downloadDocumentsFiles(evaluation._id).subscribe((result) => {
        if (result.file) {
          const fileName = `evaluation_${new Date().toISOString()}_${evaluation._id
            }.zip`;
          saveAs(result.file, fileName);
        } else {
          window.alert('¡No se encuentra el archivo!');
        }
  
        this.spinner.hide();
      });
  
  }

  downloadItem(itemDownload, evaluation){
    if(this.isLoading) return;
    this.spinner.show();

    this.isLoading = true;

    this.riskService.downloadOneDocument(evaluation._id, itemDownload).subscribe((result) => {
      if (result.file) {
        const fileName = `evaluation_${new Date().toISOString()}_${evaluation._id
          }.zip`;
        saveAs(result.file, fileName);
        this.isLoading = false;

      } else {
        window.alert('¡No se encuentra el archivo!');
      }

      this.spinner.hide();
    });
  }

  deleteOneDocument(itemDownload, evaluation, index){
    this.spinner.show();

    this.overlayService.startConfirmation(
      {
        file: itemDownload.file,
        code: itemDownload.code,
        _id: evaluation._id,
      },
      {
        component: DeleteDocConfirmationComponent,
        entity: {index, itemDownload}
      },
      'details',
      'edit',
      null,
      EvaluationsActions.DeleteDocument,
      `risk-pending-evaluations/${evaluation._id}`,
      EvaluationSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'Archivo eliminado',
        },
        onConfirm: {
          title: '¿Estás seguro?',
          message: 'Vas a eliminar el siguiente archivo:',
        },
      },
      this.reFetchEvaluation.bind(this)
    );
      this.spinner.hide();
  }
  reFetchEvaluation(){
    this.dialogRef.close({event: 'Closed'});
  }

}
