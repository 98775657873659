import { Pipe, PipeTransform } from '@angular/core';
import { capitalizeFirstLetters } from '../util/string.util';

@Pipe({
  name: 'capitalizeFirstLetters'
})
export class CapitalizeFirstLettersPipe implements PipeTransform {

  transform(value: any): string {
    if(value) {
      return capitalizeFirstLetters(value);
    }
  }

}
