<div class="card">
    <div class="data">
        <div class="data-points">
            <div class="data-block">
                <ng-container *ngIf="getCompanyName(invoice).length > 25">
                    <lib-tooltip width="160" [content]="getCompanyName(invoice) | uppercase">
                        <span class="text-highlight">
                            {{getCompanyName(invoice) | uppercase | limitTo: '25'}}
                        </span>
                    </lib-tooltip>
                </ng-container>
                <ng-container *ngIf="getCompanyName(invoice).length < 26">
                    <span class="text-highlight">
                        {{getCompanyName(invoice) | uppercase}}
                    </span>
                </ng-container>

                <ng-container *ngIf="getCodes(invoice).length > 15">
                    <lib-tooltip width="160" [content]="getCodes(invoice) | uppercase">
                        <span class="text" style="margin-top: 8px;">
                            {{getCodes(invoice) | limitTo: '15'}}
                        </span>
                    </lib-tooltip>
                </ng-container>
                <ng-container *ngIf="getCodes(invoice).length <= 15">
                    <span class="text" style="margin-top: 8px;">
                        {{getCodes(invoice)}}
                    </span>
                </ng-container>
            </div>
            <div class="data-block" style="margin-left: 8px;white-space:nowrap;">
                <span class="text-blue">
                    {{invoice.currency | currencySymbol}} {{invoice.netAmount |
                    numberSpacing}}
                </span>

                <div *ngIf="type==='finalized';else paymentDate" style="margin-top: 8px" class="actualPaymentDate">
                    <span class="text-highlight" style="">
                        {{invoice.actualPaymentDate | formatDate}}
                    </span>
                    <span class="text-highlight" style="margin-left: 3px;text-align: right;" *ngIf="invoice.moraDays">({{invoice.moraDays}} {{(invoice.moraDays === 1) ? ' día' : ' días'}})</span>
                </div>

                <ng-template #paymentDate>
                    <span class="text-highlight" style="margin-top: 8px;">
                        {{invoice.paymentDate | formatDate}}
                    </span>
                </ng-template>
            </div>
        </div>
        <ng-container *ngIf="type==='in process'">
            <div style="display: flex;align-items: center;margin-top: 28px;">
                <mat-progress-bar class="audio-progress-bar" mode="determinate"
                    [value]="invoice.clientChecks?.progress">
                </mat-progress-bar>
                <img style="z-index: 10; margin-left: -3px;" width="32px" height="32px" matTooltipClass="fs-tooltip"
                    matTooltip="Desembolso" src='../../../../../assets/icons/attach_money.svg'>
            </div>

            <span class="in-progress-bar-text">
                {{invoice.clientChecks?.isFinanceReady ? 'Financiando tu operación' : 'En proceso de
                validación'}}</span>
        </ng-container>
        <ng-container *ngIf="type==='collection'">
            <div style="display: flex;align-items: center;margin-top: 28px;">
                <img matTooltipClass="fs-tooltip" matTooltip="Desembolso"
                    style="position: absolute;margin-top:-4px ; margin-left: -5px; z-index: 10;"
                    src="../../../../../../assets/icons/ellipse_blue.svg" width="12px" height="12px">
                <mat-progress-bar class="audio-progress-bar" mode="determinate"
                    [value]="invoice?.toBeCollectedIn === 'En mora' ? 100 : datesPercentage(invoice?.advancePaymentDate, invoice?.paymentDate)">
                </mat-progress-bar>
                <img style="z-index: 10; margin-left: -3px;" width="32px" height="32px" matTooltipClass="fs-tooltip"
                    matTooltip="Pago"
                    [src]="(invoice?.toBeCollectedIn === 'En mora' || invoice?.toBeCollectedIn === 0) ? '../../../../../assets/icons/attach_credit_blue.svg' : '../../../../../assets/icons/attach_credit.svg'">
            </div>
            <span style="font-size: 12px; display: block; margin-top: -12px;"
                [ngStyle]="{'color': getRemainingDays(invoice?.toBeCollectedIn, invoice?.moraDays).color}">
                {{getRemainingDays(invoice?.toBeCollectedIn, invoice?.moraDays).text}}</span>
        </ng-container>
    </div>
    <ng-container *ngIf="type==='in process'">
        <div style="text-align: right;">
            <div *ngIf="invoice?.clientChecks?.totalDenied > 0" class="red-badge">
                <span style="position: absolute; margin-left: -41px; margin-top: 14px;"
                    [matBadge]="invoice?.clientChecks?.totalDenied > 9 ? '9+' : invoice?.clientChecks?.totalDenied"
                    matBadgeOverlap="false"></span>
            </div>
            <lib-icon icon="arrow_right" size="m" color="dark-gray">
            </lib-icon>
        </div>
    </ng-container>
    <ng-container *ngIf="type==='collection' || type==='finalized'">
        <div style="text-align: right;">
            <img *ngIf="invoice.hasNotification" style="position: absolute; margin-left: -33px; margin-top: 6px;"
                src="../../../../../../assets/icons/ellipse_blue.svg" width="12px" height="12px">
            <lib-icon icon="arrow_right" size="m" color="dark-gray">
            </lib-icon>
        </div>
    </ng-container>
    <ng-container *ngIf="type==='disapproved'">
        <div style="text-align: right;">
            <img *ngIf="invoice.hasNotification"
                style="margin-left: 10px;filter: invert(16%) sepia(92%) saturate(7426%) hue-rotate(6deg) brightness(98%) contrast(117%);"
                src="../../../../../../assets/icons/ellipse_blue.svg" width="12px" height="12px">
            <lib-icon icon="arrow_right" size="m" color="dark-gray">
            </lib-icon>
        </div>
    </ng-container>
</div>