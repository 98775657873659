<div class="container" [style.width]="width">
    <img class="tiny-logo" src="../../../../../assets/logos/small.png">

    <h1>{{ title }}</h1>


    <div [hidden]="!componentRef" class="display-item">
        <template #entityDisplay></template>
    </div>

    <p *ngIf="disclaimer" class="disclaimer">{{disclaimer}}</p>
    <p *ngIf="displayErrorInvestment" class="disclaimer-error">{{componentRef?.instance?.investmentError}}</p>

    <div class="form-actions">
        <fs-ui-button *ngIf="!isDisabled" class="intercom-button-confirm" (click)="confirm()"  [label]="buttonTitle" [type]="'secondary'"></fs-ui-button>

        <fs-ui-button class="cancel-button" (click)="cancel()"  [label]="buttonBackTitle" [type]="'tertiary'"></fs-ui-button>
    </div>
</div>