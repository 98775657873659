import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UsersService } from 'src/app/features/users/services/users.service';

@Injectable({ providedIn: 'root' })
export class UniqueDocumentValidator {
  constructor(private usersService: UsersService) {}

  public isUniqueDocument(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return new Promise((resolve, reject) => {
        const idType = control.parent.get('idType').value;

        return timer(0)
          .pipe(
            switchMap(() =>
              this.usersService.documentExist(idType, control.value)
            )
          )
          .subscribe(
            (res) => {
              resolve(null);
            },
            (err) => {
              resolve({ isUniqueDocument: true });
            }
          );
      });
    };
  }
}
