import { Component, OnInit } from '@angular/core';
import { Banks } from 'src/app/shared/enums/Banks.enum';
import { Currency } from 'src/app/shared/enums/Currency.enum';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';

@Component({
  selector: 'app-our-bank-accounts',
  templateUrl: './our-bank-accounts.component.html',
  styleUrls: ['./our-bank-accounts.component.scss']
})
export class OurBankAccountsComponent implements OnInit {

  ourBankAccounts = [
    {
      name: Banks["BBVA Continental"],
      currency: Currency.PEN,
      number: "001101420100097160",
      cci: "01114200010009716074"
    },
    {
      name: Banks["BBVA Continental"],
      currency: Currency.USD,
      number: "001101420100097179",
      cci: "01114200010009717974"
    },
    {
      name: Banks['Banco de Crédito del Perú'],
      currency: Currency.PEN,
      number: "1948756966099",
      cci: "00219400875696609993"
    },

    {
      name: Banks['Banco de Crédito del Perú'],
      currency: Currency.USD,
      number: "1948744887199",
      cci: "00219400874488719993"
    }
  ]
  newBanks

  constructor(private windowScrollService: WindowScrollService) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.sendPageViewEvent()
  }

  sendPageViewEvent(){
    this.windowScrollService.sendNewEvent('Financial Transactions', `Deposit Show Accounts`)

  }

}
