import { Component } from '@angular/core';

@Component({
  selector: 'app-my-clients-page',
  template: `
    <!--<app-page-title [title]="pageTitle"></app-page-title>-->
    <router-outlet></router-outlet>
  `,
})
export class MyClientsPageComponent {
  pageTitle = 'Mis clientes';
  constructor() {}
}
