import { Component, OnInit, Input } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-invoices-progress-bar',
  templateUrl: './invoices-progress-bar.component.html',
  styleUrls: ['./invoices-progress-bar.component.scss']
})
export class InvoicesProgressBarComponent implements OnInit {

  @Input() entity;
  @Input() roi;
  @Input() amount;
  @Input() percentage?;
  @Input() totalAmount;
  @Input() type;
  @Input() status;
  @Input() currency;
  @Input() animate;
  @Input() toBeCollectedIn;
  @Input() dates;
  @Input()moraDays;
  realPercentage
  constructor() { }

  ngOnInit(): void {
  }

  getDate(entity){
    let hour = entity?.onSaleSlot === "1" ? '12:30' : '17:30'
    return moment(entity?.onSaleDate).format('dddd DD [de] MMMM, ') + hour;
  }

}
