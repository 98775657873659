import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const showCount = args[0] || 4;
    const hideCharacterCount = args[1] || 4;
    const hideCharacter = args[2] || '*';
    const direction = args[3] || 'start';

    if(!value || value.length <= showCount) {
      return value;
    }

    if(direction === 'start') {
      return hideCharacter.repeat(hideCharacterCount) + value.substr(value.length-showCount, value.length);;
    } else if (direction === 'end') {
      return value.substr(0, showCount) + hideCharacter.repeat(hideCharacterCount);
    }
  }

}
