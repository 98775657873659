import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringNextLine',
})
export class StringNextLinePipe {
  transform(value: string, separator: string): string {
    let regex = new RegExp(separator, "g");
    value = value.replace(regex, "\n").trim();
    
    return value;
  }
}
