<div class="container" *ngIf="entity">
    <table>
        <tr class="gray">
            <th>Tipo de operación</th>
            <th>Empresa giradora</th>
            <th>Empresa pagadora</th>
            <th>Moneda</th>
            <th>Estado</th>
        </tr>
        <tr>
            <td>
                <app-operation-invoice
                    [isConfirming]="entity.isConfirming">
                </app-operation-invoice>
            </td>
            <td>
                <p *ngIf="entity.isConfirming">
                    {{(entity.supplier?.companyName | uppercase | limitTo: '15')}}
                </p>
                <p *ngIf="!entity.isConfirming">
                    {{entity.issuer?.companyName | uppercase | limitTo: '15'}}
                </p>
            </td>
            <td>
                <p *ngIf="entity.isConfirming">
                    {{entity.issuer?.companyName | uppercase | limitTo: '15'}}
                </p>
                <p *ngIf="!entity.isConfirming">
                    {{entity.debtor?.companyName | uppercase | limitTo: '15'}}
                </p>
            </td>
            <td>
                <app-currency [currency]="entity.currency"></app-currency>
            </td>
            <td>
                <div class="status">
                    <app-invoice-status [status]="entity.status"></app-invoice-status>
                </div>
            </td>
        </tr>
    </table>
</div>