import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investments-page',
  template: `
    <router-outlet></router-outlet>
  `
})
export class InvestmentsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
