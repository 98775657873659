<ng-container *allowedRoles="item.allowedRoles">
    <li *ngIf="item.type === 'link'||  item.type === 'button'||  item.type === 'newTab' ||  item.type === 'feedback'"
        class="intercom-nav-item-{{key}}" (click)="click()">
        <a>
            <span *ngIf="item?.fontIconClass"
                [class]="'font-menu-icon icon-bank-accounts ' + item.fontIconClass"></span>
            <img *ngIf="item?.imgIcon" [src]="getIconUrl(item.imgIcon)" class="img-icon">
            <span>{{item.label}}</span>
            <span *ngIf="item.type == 'feedback' && getActive(item)" class="feedback-container pulse"><span class="feedback">Nuevo</span></span>
            <span *ngIf="item.type == 'newTab'"> &nbsp; <img class="newtabIcon"
                    [src]="getIconUrl('open_in_new-24px.svg')"></span>
        </a>
    </li>
    <hr *ngIf="item.type ==='separator'">
</ng-container>