<div class="box" *ngIf="title">
    <div class="one">
        <p class="bold title">{{title}} </p>
    </div>
    <div class="two">
        <ng-container *ngIf="sendTo?.length > 15">
            <lib-tooltip [content]="sendTo" width="300">
                <p>
                    <span class="noWrap">
                        Para: {{(sendTo | limitTo: '15')}}
                    </span>
                </p>
            </lib-tooltip>

        </ng-container>
        <ng-container *ngIf="sendTo?.length <= 15">
            <span class="noWrap">
                Para: {{sendTo ? sendTo.trim() !== '' ? sendTo : '---' : '---'}}
            </span>
        </ng-container>
        <!-- <p>Para: {{sendTo}}</p> -->
    </div>
    <div class="three" *ngIf="cc">
        <ng-container *ngIf="cc?.length > 15">
            <lib-tooltip [content]="cc" width="300">
                <p>
                    <span class="noWrap">
                        CC: {{(cc | limitTo: '15')}}
                    </span>
                </p>
            </lib-tooltip>
        </ng-container>
        <ng-container *ngIf="cc?.length <= 15">
            <span class="noWrap">
                CC: {{cc ? cc.trim() !== '' ? cc : '---' : '---'}}
            </span>
        </ng-container>
        <!-- <p class="tooltip-container-not-dots">
            CC:
            <ng-container *ngIf="cc?.length < 3">
                <span *ngFor="let email of cc; let isLast = last; let index = index">
                    <ng-container>
                        {{ email}}<span *ngIf="!isLast">, </span>
                    </ng-container>
                </span>
            </ng-container>
            <ng-container *ngIf="cc?.length > 2">
                <span *ngFor="let email of cc; let isLast = last; let index = index">
                    <ng-container *ngIf="index<2">
                        {{ email}}<span *ngIf="!isLast">, </span>
                    </ng-container>
                    <ng-container *ngIf="isLast && index >= 2">
                        ...
                    </ng-container>
                </span>
                <span class="tooltiptext">{{cc}}</span>
            </ng-container> -->

        <!-- </p> -->
    </div>
    <div class="three" *ngIf="!cc">
        <p>CC: --</p>
    </div>
    <div class="four">
        <div *ngIf="status === 'Enviado' || status === 'Reenviado'" class="tooltip-container-not-dots">
            <p class="sent" *ngIf="status === 'Enviado'">{{status}}</p>
            <p class="sent" *ngIf="status === 'Reenviado'">{{status}} ({{getCount(count)}})</p>
            <span class="tooltiptext-left">
                <ng-container *ngFor="let item of detail; let isLast = last; let index = index">
                    <ng-container *ngIf="detail.length < 2">
                        <span>Fecha: {{item.date | formatDate}}</span><br />
                        <span>Hora: {{ getHour(item.date) }}</span><br />
                    </ng-container>
                    <ng-container *ngIf="detail.length > 1">
                        <p class="title-email">{{item.label}}:</p>
                        <span>Fecha: {{item.date | formatDate}}</span><br />
                        <span>Hora: {{ getHour(item.date) }}</span><br />
                    </ng-container>
                </ng-container>
            </span>
        </div>
        <p class="ready-send" *ngIf="status === 'Por enviar'">{{status}}</p>
    </div>
    <div class="five" (click)="sendEmail(isExternal, type)">
        <ng-container *ngIf="(status  === 'Enviado' || status  === 'Reenviado') && isDisabledEmail">
            <svg class="disabled" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                <defs>
                    <style>
                        .a-operation-disabled {
                            fill: #C6CBD4;
                        }

                        .b-operation-disabled {
                            fill: none;
                        }

                        .c-operation-disabled {
                            fill: #f2f2f2;
                        }
                    </style>
                </defs>
                <g transform="translate(-1572 -839)">
                    <circle class="a-operation-disabled" cx="17" cy="17" r="17"
                        transform="translate(1572 839)" />
                    <g transform="translate(1579 846)">
                        <rect class="b-operation-disabled" width="20" height="20" />
                        <g transform="translate(1.667 3.333)">
                            <path class="c-operation-disabled"
                                d="M17,4H3.667A1.664,1.664,0,0,0,2.008,5.667L2,15.667a1.672,1.672,0,0,0,1.667,1.667H17a1.672,1.672,0,0,0,1.667-1.667v-10A1.672,1.672,0,0,0,17,4Zm-.333,3.542-5.892,3.683a.843.843,0,0,1-.883,0L4,7.542a.708.708,0,1,1,.75-1.2l5.583,3.492,5.583-3.492a.708.708,0,1,1,.75,1.2Z"
                                transform="translate(-2 -4)" />
                        </g>
                    </g>
                </g>
            </svg>
        </ng-container>

        <ng-container *ngIf="(status  === 'Enviado' || status  === 'Reenviado') && !isDisabledEmail">
            <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                <defs>
                    <style>
                        .a-operation-sent {
                            fill: #6c727c;
                        }

                        .b-operation-sent {
                            fill: none;
                        }

                        .c-operation-sent {
                            fill: #f2f2f2;
                        }
                    </style>
                </defs>
                <g transform="translate(-1572 -839)">
                    <circle class="a-operation-sent" cx="17" cy="17" r="17" transform="translate(1572 839)" />
                    <g transform="translate(1579 846)">
                        <rect class="b-operation-sent" width="20" height="20" />
                        <g transform="translate(1.667 3.333)">
                            <path class="c-operation-sent"
                                d="M15.375,18.408a.83.83,0,0,1-1.175,0l-1.767-1.767a.83.83,0,0,1,0-1.175h0a.83.83,0,0,1,1.175,0l1.175,1.175,2.95-2.95a.83.83,0,0,1,1.175,0h0a.83.83,0,0,1,0,1.175ZM10.4,17.333H3.667A1.672,1.672,0,0,1,2,15.667v-10A1.672,1.672,0,0,1,3.667,4H17a1.672,1.672,0,0,1,1.667,1.667v5.567A5.827,5.827,0,0,0,10.333,16.5,5.528,5.528,0,0,0,10.4,17.333Zm-.508-6.108a.843.843,0,0,0,.883,0l5.892-3.683a.708.708,0,1,0-.75-1.2L10.333,9.833,4.75,6.342A.708.708,0,1,0,4,7.542Z"
                                transform="translate(-2 -4)" />
                        </g>
                    </g>
                </g>
            </svg>
        </ng-container>

        <ng-container *ngIf="status === 'Por enviar' && isDisabledEmail">
            <div class="disabledEmail" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
                <ng-container *ngIf="displayBubble && abbleToDisplay">
                    <div>
                        <div class="bubble sb1">
                            <p class="title-bubble u-mb-3 text-color-dark-base">
                                Debes corregir estos puntos:</p>
                                <ul class="blocker-bubble" >
                                  <ng-container *ngFor="let blocker of blockersInvoiceForSale">
                                    <li *ngIf="blocker.value" class="u-mb-3"  > 
                                      <p  class="body-paragraph-xs-light text-color-dark-base">
                                      {{blocker.msg}}</p>
                                    </li>
                                  </ng-container>
                                </ul>
                            <br>
                        </div>
                    </div>
                </ng-container>
                <svg class="disabled" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <defs>
                        <style>
                            .a-operation-disabled {
                                fill: #C6CBD4;
                            }

                            .b-operation-disabled {
                                fill: none;
                            }

                            .c-operation-disabled {
                                fill: #f2f2f2;
                            }
                        </style>
                    </defs>
                    <g transform="translate(-1572 -839)">
                        <circle class="a-operation-disabled" cx="17" cy="17" r="17"
                            transform="translate(1572 839)" />
                        <g transform="translate(1579 846)">
                            <rect class="b-operation-disabled" width="20" height="20" />
                            <g transform="translate(1.667 3.333)">
                                <path class="c-operation-disabled"
                                    d="M17,4H3.667A1.664,1.664,0,0,0,2.008,5.667L2,15.667a1.672,1.672,0,0,0,1.667,1.667H17a1.672,1.672,0,0,0,1.667-1.667v-10A1.672,1.672,0,0,0,17,4Zm-.333,3.542-5.892,3.683a.843.843,0,0,1-.883,0L4,7.542a.708.708,0,1,1,.75-1.2l5.583,3.492,5.583-3.492a.708.708,0,1,1,.75,1.2Z"
                                    transform="translate(-2 -4)" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </ng-container>

        <ng-container *ngIf="status === 'Por enviar' && !isDisabledEmail">
            <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                <defs>
                    <style>
                        .a-operation {
                            fill: #0256ff;
                        }

                        .b-operation {
                            fill: none;
                        }

                        .c-operation {
                            fill: #f2f2f2;
                        }
                    </style>
                </defs>
                <g transform="translate(-1572 -839)">
                    <circle class="a-operation" cx="17" cy="17" r="17" transform="translate(1572 839)" />
                    <g transform="translate(1579 846)">
                        <rect class="b-operation" width="20" height="20" />
                        <g transform="translate(1.667 3.333)">
                            <path class="c-operation"
                                d="M17,4H3.667A1.664,1.664,0,0,0,2.008,5.667L2,15.667a1.672,1.672,0,0,0,1.667,1.667H17a1.672,1.672,0,0,0,1.667-1.667v-10A1.672,1.672,0,0,0,17,4Zm-.333,3.542-5.892,3.683a.843.843,0,0,1-.883,0L4,7.542a.708.708,0,1,1,.75-1.2l5.583,3.492,5.583-3.492a.708.708,0,1,1,.75,1.2Z"
                                transform="translate(-2 -4)" />
                        </g>
                    </g>
                </g>
            </svg>
        </ng-container>
    </div>

</div>