import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-signup-get-type-form',
  templateUrl: './signup-get-type-form.component.html',
  styleUrls: ['./signup-get-type-form.component.scss'],
})
export class SignupGetTypeFormComponent implements OnInit {
  @Output() previousStep = new EventEmitter();
  @Output() nextStep = new EventEmitter();

  @Input() userType: string = '';

  constructor() {}

  ngOnInit(): void {}

  userTypeEvent(type) {
    this.userType = type;
    this.nextStep.emit();
  }

  backward() {
    this.previousStep.emit();
  }

  forward(data) {
    this.nextStep.emit(data);
  }
}
