<h1>Redirigir mensaje</h1>

    <div class="controls">
        <ng-container>
            <div class="icon-row icon-gap">
                <div class="icon-box">
                    <p class="p-title">
                        <img src="../../../../../../assets/icons/business_gray.svg">
                        Pagador
                    </p>
                </div>
                <div class="icon-text">
                    <p class="p-value"> {{ data?.announcementDetail?.mainInvoiceId?.debtor?.companyName | uppercase }} </p>
                </div>
            </div>
            <div class="icon-row icon-gap activity">
                <div class="icon-box">
                    <p class="p-title">
                        <img src="../../../../../../assets/icons/list_gray.svg">
                        Estado
                    </p>
                </div>
                <div class="icon-text">
                    <p class="p-value">{{ data?.announcementDetail?.mainInvoiceId?.status | toSpanish : 'FEMALE' | uppercase }}</p>
                </div>
            </div>
            <ng-container *ngIf="linkedInvoices?.length > 0">
                <p class="title mt-20">Operaciones redirigidas ({{linkedInvoices?.length}}):</p>
                <app-invoice-announcement-table 
                    [withScroll]="true"
                    [invoices]="linkedInvoices">
                </app-invoice-announcement-table>
            </ng-container>
            <ng-container *ngIf="invoices?.length > 0; else empty_invoice">
                <p class="title" [ngClass]="linkedInvoices?.length > 1 ? 'mt--20' : 'mt-20' ">Selecciona las operaciones disponibles a redirigir:</p>
                <div class="test">
                    <div class="code-table" [ngClass]="linkedInvoices.length > 1 ? 'small-height' : 'big-height'">
                        <table>
                            <tr>
                                <th>
                                    <mat-checkbox 
                                    (change)="selectAll()" 
                                    [checked]="checkedAll" 
                                    [indeterminate]="someComplete()"
                                    color="accent">
                                    </mat-checkbox>
                                </th>
                                <th>Girador</th>
                                <th>Códigos</th>
                                <th>Fecha de pago</th>
                                <th>Moneda</th>
                                <th>Operación</th>
                            </tr>
                            <tr *ngFor="let code of invoices;  let last = last;  let first = first  ">
                                <td>
                                    <app-checkbox (selectionChanged)="setSelection(code)"
                                        [checked]="code.selected" content="">
                                    </app-checkbox>
                                </td>
                                <td>
                                    <ng-container *ngIf="code?.isConfirming && code?.supplier?.companyName?.length > 25">
                                        <lib-tooltip  width="160" [content]="code?.supplier?.companyName | capitalizeFirstLetters">
                                            {{ code.supplier?.companyName | uppercase | limitTo: '25'}}
                                        </lib-tooltip>
                                    </ng-container>
                                    <ng-container *ngIf="code?.isConfirming && code?.supplier?.companyName?.length < 26">
                                        {{ code.supplier?.companyName | uppercase }}
                                    </ng-container>

                                    <ng-container *ngIf="!code?.isConfirming && code.issuer?.companyName?.length > 25">
                                        <lib-tooltip  width="160" [content]="code.issuer?.companyName | capitalizeFirstLetters">
                                            {{ code.issuer?.companyName | uppercase | limitTo: '25'}}
                                        </lib-tooltip>
                                    </ng-container>
                                    <ng-container *ngIf="!code?.isConfirming && code?.issuer?.companyName?.length < 26">
                                        {{ code.issuer?.companyName | uppercase}}
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngIf="code?.physicalInvoices && code?.physicalInvoices?.length > 2;">
                                        <span [matTooltip]="getCodes(code)">
                                            <span class="code"
                                            *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                                            <ng-container *ngIf="index<2">
                                                {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                                            </ng-container>
                                            <ng-container *ngIf="isLast && index >= 2">
                                                ...
                                            </ng-container>
                                            </span>
                                        </span>
                                        
                                    </ng-container>

                                    <ng-container *ngIf="code?.physicalInvoices && code?.physicalInvoices.length < 3;">
                                        <span class="code"
                                        *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                                        <ng-container *ngIf="index<2">
                                            {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                                        </ng-container>
                                        <ng-container *ngIf="isLast && index >= 2">
                                            ...
                                        </ng-container>
                                        </span>
                                    </ng-container>
                                </td>
                                <td>{{ code?.paymentDate | formatDate }}</td>
                                <td>{{ code.currency | uppercase }}</td>
                                <td>{{ code.isConfirming ? 'CONFIRMING' : 'FACTORING'}}</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
                <div class="container-flex">
                    <p class="title-operation-count">Operaciones seleccionadas</p>
                    <div class="container-box"><p>{{ invoicesSelected?.length || '---'}}</p></div>
                </div>
            </ng-container>

            <ng-template #empty_invoice>
                <div class="container-disclaimer">
                    <p class="disclaimer">No hay facturas.</p>
                </div>
            </ng-template>
        </ng-container>

    </div>

    <div class="button-container button-direction-column">
        <fs-ui-button  *ngIf="invoices?.length > 0" [action]="'submit'" (click)="onSubmit()"  [label]="'Siguiente'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>