import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-signup-type-user-step',
  templateUrl: './signup-type-user-step.component.html',
  styleUrls: ['./signup-type-user-step.component.scss'],
})
export class SignupTypeUserStepComponent implements OnInit {
  @Output() userTypeEvent = new EventEmitter();
  @Output() nextStep = new EventEmitter();
  @Output() previousStep = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  }

  setUser(type: string) {
    this.userTypeEvent.emit(type);
    this.nextStep.emit();
  }

  backward() {
    this.previousStep.emit();
  }

}
