import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from "src/app/app.states";
import { UsersSelectors } from "src/app/features/users/state/users.selector-types";

@Component({
  selector: "app-invoice-detail-landing",
  templateUrl: "./invoice-detail-landing.component.html",
  styleUrls: ["./invoice-detail-landing.component.scss"],
})
export class InvoiceDetailLandingComponent implements OnInit {
  loggedInUserRole;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getRoleUser();
    });
  }

  getRoleUser() {
    this.store
      .select(UsersSelectors.profileProperty("role"))
      .pipe(take(1))
      .subscribe((role) => (this.loggedInUserRole = role));
  }
}
