<div class="intercom-dialog-form">
  <div class="header">
    <h1>Rechazar contacto</h1>
  </div>
  <div class="contactCard">
    <lib-contact-item
      [isAllowedValidate]="false"
      [isAllowedToCopyEmail]="false"
      [isValid]="true"
      [name]="data.data.name"
      [currentUserRole]="'viewerRole'"
      [jobTitle]="data.data.jobTitle"
      [email]="data.data.email"
      [phone]="data.data.phone"
    >
    </lib-contact-item>
  </div>

  <form [formGroup]="form">
    <div>
      <div class="title">
        <lib-text
          color="black"
          size="xs"
          weight="body"
          [content]="'Selecciona el motivo:'"
        >
        </lib-text>
      </div>
      <mat-radio-group
        formControlName="selectedReason"
        class="radio-group"
        (change)="radioChange($event)"
      >
        <div
          [ngClass]="{
            error:
              form.controls.selectedReason.touched &&
              form.controls.selectedReason.invalid
          }"
        >
          <mat-radio-button
            class="radio-buttons"
            *ngFor="let reason of reasons"
            [value]="reason"
          >
            {{ reason }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
      <app-input
        *ngIf="selectedReason == 'Otro'"
        formControlName="reason"
        autocomplete="off"
        type="text"
        label="Otro"
      ></app-input>
    </div>
    <div class="u-mb-3" *ngIf="form.controls.selectedReason?.touched &&
    form.controls.selectedReason?.invalid">
      <p class="error-label">Obligatorio</p>
    </div>
    <div class="button-container button-direction-column">
      <fs-ui-button
        class="intercom-dialog-confirm"
        [action]="'submit'"
        (click)="onSubmit()"
        [label]="'Rechazar contacto'"
      ></fs-ui-button>
      <fs-ui-button
        class="cancelButton"
        (click)="cancel()"
        [label]="'Cerrar'"
        [type]="'outline'"
      ></fs-ui-button>
    </div>
  </form>
</div>
