import { cloneDeep } from 'lodash';

export const updateStoreState = (state, newEntity, mode) => {
  let newState;
  if (Array.isArray(state)) {
    newState = updateStoreStateForArrays(state, newEntity, mode);
  } else {
    newState = updateStoreStateForObjects(state, newEntity, mode);
  }

  return newState;
};

export const updateStoreStateForObjects = (state, newEntity, mode) => {
  let newState;

  if (!newEntity) {
    newState = state;
  } else if (mode === 'editOwn' || mode === 'edit') {
    newState = Object.assign({}, state, newEntity);
  } else if (mode === 'deleteOwn' || mode === 'delete') {
    newState = Object.assign({});
  }

  return newState;
};
export const updateStoreStateForArrays = (state, newEntity, mode) => {
  let newState;

  if (state === undefined) {
    newState = [];
  } else {
    newState = state.slice();
  }

  if (mode === 'create') {
    newState.unshift(newEntity);
  } else if (mode === 'edit') {
    const EntityPosition = newState.findIndex(
      (item) => item._id === newEntity._id
    );

    if (EntityPosition > -1) {
      newState.splice(EntityPosition, 1, newEntity);
    } else {
      console.log(`Couldn't find ${newEntity} in ${newState}`);
    }
  } else if (mode === 'delete') {
    const EntityPosition = newState.findIndex((item) => item._id === newEntity); //It's not really an entity, just the id of the deleted item as string
    if (EntityPosition > -1) {
      newState.splice(EntityPosition, 1);
    } else {
      console.log("Couldn't find");
      console.log(newEntity);
      console.log('in');
      console.log(newState);
    }
  }

  return newState;
};

export const updateRecalulatedOpportunity = (opportunities, changes) => {
  let newState;

  if (opportunities === undefined) {
    newState = [];
  } else {
    newState = opportunities.slice();
  }

  let targetOpportunityIndex = newState?.findIndex(
    (item) => item._id === changes.invoiceId
  );

  if (targetOpportunityIndex > -1) {
    let updatedOpportunity = Object.assign(
      {},
      newState[targetOpportunityIndex]
    );
    updatedOpportunity.availableBalanceAmount = changes.newAvailBalance;
    updatedOpportunity.availableBalancePercentage =
      changes.newAvailBalancePercentage;
    updatedOpportunity.status = changes.newInvoiceStatus;

    newState.splice(targetOpportunityIndex, 1, updatedOpportunity);
  }

  return newState;
};

export const updateOperationInvestments = (state: any, changes: any) => {
  let operations = cloneDeep(state?.operations);

  try {
    const operationIndex = operations?.findIndex(
      (operation: any) => changes.invoiceId === operation._id
    );

    if (operationIndex !== -1) {
      operations[operationIndex].availableBalanceAmount =
        changes.newAvailBalance;
      operations[operationIndex].availableBalancePercentage =
        changes.newAvailBalancePercentage;
      operations[operationIndex].status = changes.newInvoiceStatus;
    }
  } catch (error) {
    console.log(error);
  }

  return operations;
};

export const updateOperations = (state: any, update: any) => {
  let operations = cloneDeep(state?.operations);
  let { realtimeOperation } = update;

  try {
    if (Array.isArray(realtimeOperation)) {
      realtimeOperation.forEach((realtimeOp) => {
        let isNewOperation = true;

        for (let index = 0; index < operations?.length; index++) {
          const operation = operations[index];

          if (String(operation._id) === String(realtimeOp._id)) {
            operations[index] = realtimeOp;
            isNewOperation = false;
            break;
          }
        }

        if (isNewOperation) operations?.unshift(realtimeOp);
      });
    } else {
      let isNewOperation = true;

      for (let index = 0; index < operations?.length; index++) {
        const operation = operations[index];

        if (String(operation._id) === String(realtimeOperation._id)) {
          operations[index] = realtimeOperation;
          isNewOperation = false;
          break;
        }
      }

      if (isNewOperation) operations.unshift(realtimeOperation);
    }
  } catch (error) {
    console.log(error);
  }

  return operations;
};

export const removeOperations = (state, update) => {
  let operations = cloneDeep(state?.operations);
  let { invoiceIds } = update;

  try {
    if (Array.isArray(invoiceIds)) {
      operations = operations.filter(
        (operation: any) =>
          !invoiceIds.some(
            (invId: any) =>
              String(invId?._id || invId) === String(operation._id)
          )
      );
    } else {
      operations = operations.filter(
        (operation: any) =>
          String(invoiceIds?._id || invoiceIds) !== String(operation._id)
      );
    }
  } catch (error) {
    console.log(error);
  }
  return operations;
};

export const updateProfile = (profile, changes) => {
  let updatedProfile = Object.assign({}, profile);
  updatedProfile.status = changes.status;

  return updatedProfile;
};
