import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { UsersService } from 'src/app/features/users/services/users.service';

@Injectable({ providedIn: 'root' })
export class UniqueEmailByEmailValidator {
  constructor(private usersService: UsersService) {}

  public isUserEmailUnique(emails): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return new Promise((resolve, reject) => {
        return timer(0)
          .pipe(
            switchMap(() =>
              this.usersService.emailDuplicate(control.value, emails)
            )
          )
          .subscribe(
            (res) => {
              console.log(res);
              resolve(null);
            },
            (err) => resolve({ isUserEmailUnique: true })
          );
      });
    };
  }
}
