<div class="container" (click)="toDetails()">
    <div class="item">
        <div class="row">
            <div>
                <p class="tiny title">{{ entity.role | toSpanish : 'NA' | capitalizeFirstLetters }}</p>
                <div class="icon-text-align">
                    <p class="tiny blue">{{ entity.names | capitalizeFirstLetters }} {{ entity.familyNames |
                        capitalizeFirstLetters }}</p>&nbsp;
                    <mat-icon *ngIf="entity.isPipedriveSynced" [ngStyle]="{'color':'#099659'}">sync
                    </mat-icon>
                    <mat-icon *ngIf="!entity.isPipedriveSynced" [ngStyle]="{'color':'#a0a5a3'}">sync_problem
                    </mat-icon>
                </div>
            </div>
            <div>
                <span class="badge">{{entity.status | toSpanish : 'FEMALE' | capitalizeFirstLetters}}</span>
            </div>
        </div>
        <div class="row" *ngIf="entity.role === 'client' || entity.role === 'investor'">
            <div *ngIf="entity.role === 'client'">
                <p class="tiny title">RUC de la empresa</p>
                <p class="big">{{entity.companyRuc}}</p>
            </div>
            <div *ngIf="entity.role === 'client'">
                <p class="tiny title">Razón social</p>
                <p class="tiny">{{entity.companyName | uppercase}}</p>
            </div>
            <div *ngIf="entity.role === 'investor'">
                <p class="tiny title">Correo electrónico</p>
                <p class="tiny">{{entity.email}}</p>
            </div>
            <div *ngIf="entity.role === 'investor'">
                <p class="tiny title">Número de {{ entity.idType | toSpanish : 'MALE' }}</p>
                <p class="big">{{entity.idNumber}}</p>
            </div>
        </div>
        <div class="row">
            <div class="creation-date">
                <span class="tiny label">Fecha de registro</span>
            </div>
            <div class="creation-date">
                <app-date [date]="entity.createdAt"></app-date>
            </div>
        </div>
    </div>
    <ng-container *ngIf="false">
        <div class="manage">
            <app-user-action *ngFor="let action of userActions" [name]="action">
            </app-user-action>
        </div>
    </ng-container>
</div>