<app-page-title title="Nuevo cliente" description="Ingresa el RUC de tu cliente y buscaremos los datos de la SUNAT.">
</app-page-title>
<app-ruc-search (onSubmit)="setCompanyInfo($event)"></app-ruc-search>

<div class="container">
    <p class="tiny" *ngIf="companyInfo?.companyName">Resultado de la búsqueda</p>
</div>
<app-sunat-ruc-item [companyInfo]="companyInfo"></app-sunat-ruc-item>
<div class="button-group intercom-button-save">
    <fs-ui-button *ngIf="companyInfo?.companyName && !companyInfo?.exists" (click)="saveClient()" [label]="'Guardar cliente'"></fs-ui-button>
    <fs-ui-button  routerLink=".." [type]="'tertiary'" [label]="'Cancelar'"></fs-ui-button>
</div>