import { AbstractControl, ValidatorFn } from '@angular/forms';

//We only enforce this on DNI to be exactly 8, CE is 9 and passport
//DNI and CE should be numbers only but passport is flexible (restrict it to 15 characters in general)
//and minimum 5
export function IDFormatValidator(control: AbstractControl) {
  if(control.parent) {
    const idType = control.parent.get('idType').value;

    if(idType === 'national id') {
      const numbersOnly = new RegExp(/^-?(0|[0-9]\d*)?$/).test(control.value);
      if(!numbersOnly) {
        return {
          numbersOnly: true
        }
      }
    } else if(idType === 'foreigner card') {
      //Verified from https://sel.migraciones.gob.pe/servmig-valreg/VerificarCE
      const numbersOnly = new RegExp(/^-?(0|[0-9]\d*)?$/).test(control.value);
      if(!numbersOnly) {
        return {
          numbersOnly: true
        }
      }
    }
  }
}
