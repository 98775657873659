
<div class="container">
    <img class="tiny-logo" src="../../../../../assets/logos/small.png">

    <h1>{{ title }}</h1>
    <p class="subtitle">{{ subtitle }}</p>

    <div class="container-invoices">
        <div class="container-table">
            <app-invoice-descript-operation [entity]="data.mainInvoice"></app-invoice-descript-operation>
        </div>
        <div class="container-column">
            <div class="left">
                <h1 class="title-invoice">Facturas agrupadas ({{ungroupList.length === 0 ? 1 : ungroupList.length + 1 }})</h1>
                <div class="container-scroll">
                    <app-grouping-invoices-card [main]="true" [entity]="data.mainInvoice"></app-grouping-invoices-card>
                    <ng-container *ngIf="ungroupList;">
                        <app-grouping-invoices-card *ngFor="let inv of ungroupList"  
                            [main]="false" 
                            [isGroup]="true" 
                            [entity]="inv"
                            (ungroupEvent)="ungroupInvoice($event)"
                            (groupEvent)="groupInvoice($event)">
                        </app-grouping-invoices-card>
                    </ng-container>
                </div>
            </div>
            <hr> 
            <div class="right">
                <h1 class="title-invoice">Factura(s) disponible(s) para agrupar ({{groupList.length}})</h1>
                <div class="container-scroll">
                    <ng-container *ngIf="groupList;">
                        <app-grouping-invoices-card *ngFor="let inv of groupList"  
                        [main]="false" 
                        [isGroup]="false" 
                        [entity]="inv"
                        (ungroupEvent)="ungroupInvoice($event)"
                        (groupEvent)="groupInvoice($event)">
                    </app-grouping-invoices-card>
                    </ng-container>
                </div>
            </div>  
        </div>
    </div>

    <div class="button-container button-direction-column submit-button">
        <fs-ui-button [isDisabled]="ungroupList.length === 0"  (click)="submit()"  [label]="'Siguiente'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</div>