import { createSelector } from '@ngrx/store';

export const sideMenuVisibility = createSelector(
    state => state["shared"]["showSideMenu"],
    (visible) => visible
);

export const toggleMenuVisibility = createSelector(
    state => state["shared"]["showCollapsed"],
    (visible) => visible
);
