<ul>
    <span class="hideMenuBtn" (click)="hideMenu()">
        <img src="../../../../../assets/icons/cancel-icon.svg" />
    </span>

    <img class="big-logo" src="../../../../../assets/logos/small.png">

    <ng-container *ngFor="let item of menuItems; let i = index">
        <ng-container *allowedRoles="item.allowedRoles">
            <app-side-menu-item 
                [item]="item"
                [key]="i"
                [class]="isActive(item.target)"
                (clicked)="clicked($event, item)">
            </app-side-menu-item>
        </ng-container>
       
    </ng-container>
</ul>
