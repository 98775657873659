<div [class]="(main==true)? 'black-container container':'gray-container container'">
    <div class="row" *ngIf="main">
        <h1 class="title">Factura principal</h1>
    </div>
    <div class="row">
        <div class="readyonly">
            <app-date title="Fecha de cobro" 
                displayStyle='plain' 
                [date]="entity.paymentDate"
                [titleColor]="'white'">
            </app-date>
        </div>
        <div class="readyonly">
            <span class="tiny-title">Códigos  ({{entity.physicalInvoices.length}})</span>
              <span class="code"
                *ngFor="let subInvoice of entity.physicalInvoices; let isLast = last; let index = index">
                <ng-container *ngIf="index<2">
                    {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                </ng-container>
                <ng-container *ngIf="isLast && index >= 2">
                    ...
                </ng-container>
            </span>
        </div>
    </div>
  
    <div class="row">
        <div class="readyonly">
            <p class="tiny tiny-title">TCEM</p>
            <span class="value" *ngIf="entity.tdmPercentage">{{ entity.tdmPercentage
            }}%</span>
            <span class="value" *ngIf="!entity.tdmPercentage">-</span>
        </div>
        <div class="readyonly right">
            <p class="tiny tiny-title">Reserva</p>
            <span class="value" *ngIf="entity.reservationPercentage">{{ entity.reservationPercentage
            }}%</span>
            <span class="value" *ngIf="!entity.reservationPercentage">-</span>
        </div>
    </div>

    <div class="row">
        <div class="readyonly">
            <p class="tiny tiny-title">Reparto</p>
            <span class="value" *ngIf="entity.distributionPercentage">{{
                entity.distributionPercentage }}%
            </span>
            <span class="value" *ngIf="!entity.distributionPercentage">-</span>

        </div>
        <div class="readyonly right">
            <p class="tiny tiny-title">Correo de contacto</p>
            <span class="value" *ngIf="entity.isConfirming">{{
                entity.supplierContact?.email }}
            </span>
            <span class="value" *ngIf="!entity.isConfirming">{{
                entity.debtorContact?.email }}
            </span>
        </div>
    </div>

    <div *ngIf="!isGroup && !main">
        <span (click)="activateGrouping(entity)" class="action">
            <img src="../../../../../../assets/icons/west_black_24dp.svg" />
            <span class="text-right">Agrupar</span>
        </span>
    </div>
    <div *ngIf="isGroup && !main" class="f-right">
        <span (click)="activateUnGrouping(entity)" class="action">
            <span class="text-left">Desagrupar</span>
            <img *ngIf="isGroup"  class="rotate" src="../../../../../../assets/icons/west_black_24dp.svg" />
        </span>
    </div>
</div>