import { createSelector } from '@ngrx/store';
import { state } from '@angular/animations';

export const loaded = createSelector(
    state => state["termsAndConditions"],
    (state) => state.loaded
);
export const entities = createSelector(
    state => state["termsAndConditions"],
    (state) => state.entities
);
export const entityById = (id) => createSelector(
    entities,
    (items) => {
        return items.filter(item => item._id === id)[0]
    }
);
export const savingSuccessStateById = (savingEntityId) => createSelector(
    state => {
        return  { 
            saving: state['termsAndConditions']['saving'],
            savingEntityId: state['termsAndConditions']['savingEntityId'],
            savingSucceeded: state['termsAndConditions']['savingSucceeded'],
            lastErrorMessage: state['termsAndConditions']['lastErrorMessage'],
            lastErrorStatus: state['termsAndConditions']['lastErrorStatus']
        }
    },
    (savingState) => {
        if(savingEntityId === savingState.savingEntityId && savingState.saving === false) {
            return {
                savingSucceeded: savingState.savingSucceeded,
                lastErrorMessage: savingState.lastErrorMessage,
                lastErrorStatus: savingState.lastErrorStatus
            }
        }
    }
);
