<ng-container *ngIf="selectedUserAccount as user">
    <div class="header">
        <div class="left">
            <div class="blocked" *ngIf="user.isBlocked">
                <div class="unblock" (click)="executeUnBlock(user._id)">
                    <img src="../../../../../../assets/icons/final/bold-green-cancel.png">
                </div>
                <div class="badge red">Bloqueado</div>
            </div>
            <div class="badge blue" *ngIf="!user.isBlocked">{{ user.status | toSpanish : 'FEMALE' |
                capitalizeFirstLetters }}</div>
            <div class="app-page-title">
                <h1>{{ user.role | toSpanish : 'NA' | capitalizeFirstLetters }}</h1>
                <p>{{user.names | capitalizeFirstLetters }} {{ user.familyNames | capitalizeFirstLetters }}</p>
                <div class="mt-reduce">
                    <div class="icon-text-align" *ngIf="user.isPipedriveSynced">
                        <mat-icon class="icon" [ngStyle]="{'color':'#099659'}">sync
                        </mat-icon>
                        <p class="ml green">Coincidencia encontrada en Pipedrive</p>
                    </div>
                    <div class="icon-text-align" *ngIf="!user.isPipedriveSynced">
                        <mat-icon class="icon" [ngStyle]="{'color':'#a0a5a3'}">sync_problem
                        </mat-icon>
                        <p class="ml">Coincidencia no encontrada en Pipedrive</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="available-action" *ngIf="!user.isBlocked">
                <span (click)="executeBlock(user._id)" class="action" *ngIf="!user.isBlocked">

                    <span class="title">Bloquear</span>
                    <img src="../../../../../../assets/icons/final/block.png" />
                </span>
                <span (click)="executeApprove(user._id)" class="action"
                    *ngIf="user.status === 'email verified' || user.status === 'disapproved' ">

                    <span class="title">Aprobar</span>
                    <img src="../../../../../../assets/icons/final/approve.png" />
                </span>
                <span (click)="executeDisapprove(user._id)" class="action" *ngIf="user.status === 'email verified'">

                    <span class="title">{{ 'disapprove' | toSpanish | capitalizeFirstLetters}}</span>
                    <img class="disapprove-icon" src="../../../../../../assets/icons/final/disapprove.png" />
                </span>
                <span (click)="executeResendConfirmation(user._id)" class="action" *ngIf="user.status === 'created'">

                    <span class="title">Reenviar confirmación</span>
                    <img src="../../../../../../assets/icons/final/send.png" />
                </span>
                <div class="confirming" *ngIf="user.role === 'client'">
                    <app-checkbox [disabled]="user.isConfirming"
                        (selectionChanged)="isConfirmingChanged($event, user._id)" [checked]="user.isConfirming"
                        content="Confirming">
                        {{user.isConfirming}}
                    </app-checkbox>
                    <div class="contractSigned">
                        <app-checkbox [disabled]="user.contractSigned == true"
                            (selectionChanged)="contractSignedChanged($event, user._id)"
                            [checked]="user.contractSigned == true" content="Documentos">
                        </app-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="documents" *ngIf="user.role === 'investor'">
        <img src="../../../../../../assets/icons/final/id-front.png" (click)="showFile('idFrontPicture', user._id)" />
        <img *ngIf="user.idBackPicture" src="../../../../../../assets/icons/final/id-back.png"
            (click)="showFile('idBackPicture', user._id)" />
    </div>

    <hr>

    <div class="profile">
        <div *ngIf="user.role === 'client'" class="companyName">{{ user.companyName }}</div>
        <div class="one-line" *ngIf="user.role === 'client'">
            <div class="readyonly">
                <span class="tiny-title">RUC (Empresa)</span>
                <span class="value blue bold">{{ user.companyRuc }}</span>
            </div>
            <div class="readyonly">
                <span class="tiny-title">Departamento</span>
                <span class="value">{{ user.companyDepartment | capitalizeFirstLetters }}</span>
            </div>
        </div>

        <div class="one-line">
            <div class="readyonly">
                <span class="tiny-title">Teléfono celular</span>
                <span class="value">{{ user.phoneCode || ''}}{{ user.phone }}</span>
            </div>
            <div class="readyonly">
                <span class="tiny-title">Canales</span>
                <span class="value" *ngIf="user.channel !== 'na'">{{ user.channel | toSpanish : 'NA' |
                    capitalizeFirstLetters }}</span>
                <span class="value" *ngIf="user.channel === 'na'">NA</span>
            </div>
        </div>

        <div class="one-line">
            <div class="readyonly">
                <span class="tiny-title">Correo electrónico</span>
                <span class="value">{{ user.email}}</span>
            </div>
            <div class="readyonly">
                <app-date title="Fecha registro" titlePoistion="right" [date]="user.createdAt" displayStyle="plain">
                </app-date>
            </div>
        </div>
        <div class="one-line">
            <div class="readyonly" *ngIf="user.role === 'client'">
                <span class="tiny-title">Dirección (Empresa)</span>
                <span class="value">{{ user.companyAddress | capitalizeFirstLetters }}</span>
            </div>
            <div class="readyonly" *ngIf="user.role === 'investor'">
                <span class="tiny-title">Número de {{ user.idType | toSpanish : 'MALE' }}</span>
                <span class="value blue bold">{{ user.idNumber }}</span>
            </div>
            <div class="readyonly" *ngIf="user.role === 'investor'">
                <span class="tiny-title">Segmento</span>
                <span class="value">{{ user.segment ? (user.segment | capitalizeOnlyFirstLetter) : '---' }}</span>
            </div>
        </div>
    </div>


    <div class="controls">

        <div class="filter-row">
            <fs-ui-button  [routerLink]="'/users-accounts/edit/' + user._id" *ngIf="!user.isBlocked" [label]="'Modificar'" [type]="'secondary'"></fs-ui-button>

            <fs-ui-button routerLink="/users-accounts"  [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
        </div>


        <div class="available-actions">
            <app-user-action *ngFor="let action of userActions | allowedActions" [name]="action">
            </app-user-action>
        </div>
    </div>

</ng-container>