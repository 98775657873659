<ng-container *ngIf="showForm">
    <img src="../../../../../../assets/logos/small.png" class="logo">
    <app-page-title 
        title="Restablecer contraseña"
        note="Por seguridad, la nueva contraseña debe contener al menos una mayúscula, una minúscula y un número."
    ></app-page-title>
    
    <app-password-reset-form (onSubmit)="passwordChanged($event)"></app-password-reset-form>
</ng-container>

<app-save-result
*ngIf="!showForm"
[success]="success" 
[resultConfig]="resultConfig"
(onDone)="backToAuth()"
></app-save-result>

<!--
    <ng-container *ngIf="!tokenExpired">
    <p>Este enlace ha caducado o es incorrecto</p>
    <a type="button" routerLink="/auth/forgot-password" class="button button-dark-blue">
        Solicitar otro
    </a>
</ng-container>
-->