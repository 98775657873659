<div class="modal">
    <div class="title">Ajustes del contrato</div>
    <!-- change the date of the contract change-->
    <div class="info">
        Te informamos que hemos realizado algunos ajustes en nuestro contrato de servicios. Estos cambios entrarán
        en vigencia a partir del 08 de julio del 2024. Puedes revisar el contrato actualizado haciendo clic en el
        enlace a continuación:</div>
    <a target="_blank" href="https://finsmart.pe/legal" class="link">Ver
        contrato actualizado</a>
    <div class="disclaimer">Al continuar utilizando nuestros servicios, estarás
        aceptando automáticamente los términos del nuevo contrato.</div>
    <div (click)="close()" class="button">Cerrar</div>
</div>