import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-grouping-invoices-card',
  templateUrl: './grouping-invoices-card.component.html',
  styleUrls: ['./grouping-invoices-card.component.scss']
})
export class GroupingInvoicesCardComponent implements OnInit {
  @Input() main = false;
  @Input() entity;
  @Input() isGroup = false;
  @Output() ungroupEvent = new EventEmitter();
  @Output() groupEvent = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    
  }

  activateGrouping(invoice){
    this.groupEvent.emit(invoice)
  }

  activateUnGrouping(invoice){
    this.ungroupEvent.emit(invoice)
  }
}
