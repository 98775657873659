<h1>Poner en venta</h1>


<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <ng-container>
        <ng-container *ngIf="invoices?.length > 0; else empty_invoice">
            <p class="title subtitle-date" [ngClass]="invoices?.length > 1 ? 'mt-20' : 'mt-28' ">Selecciona las operaciones
                disponibles para publicar:</p>
            <div>
                <div class="code-table" [ngClass]="invoices.length > 1 ? 'small-height' : 'big-height'">
                    <table>
                        <tr>
                            <th>
                                <mat-checkbox (change)="selectAll()" [checked]="checkedAll"
                                    [indeterminate]="someComplete()" color="accent">
                                </mat-checkbox>
                            </th>
                            <th>Girador</th>
                            <th>Códigos</th>
                            <th>Operación</th>
                            <th>Monto neto</th>
                            <th>TEA</th>
                        </tr>
                        <tr *ngFor="let code of invoices;  let last = last;  let first = first  ">
                            <td>
                                <app-checkbox (selectionChanged)="setSelection(code)" [checked]="code.selected"
                                    content="">
                                </app-checkbox>
                            </td>
                            <td>
                                <ng-container *ngIf="code?.isConfirming && code?.supplier?.companyName?.length > 25">
                                    <lib-tooltip width="160"
                                        [content]="code?.supplier?.companyName | capitalizeFirstLetters">
                                        {{ code.supplier?.companyName | uppercase | limitTo: '25'}}
                                    </lib-tooltip>
                                </ng-container>
                                <ng-container *ngIf="code?.isConfirming && code?.supplier?.companyName?.length < 26">
                                    {{ code.supplier?.companyName | uppercase }}
                                </ng-container>

                                <ng-container *ngIf="!code?.isConfirming && code.issuer?.companyName?.length > 25">
                                    <lib-tooltip width="160"
                                        [content]="code.issuer?.companyName | capitalizeFirstLetters">
                                        {{ code.issuer?.companyName | uppercase | limitTo: '25'}}
                                    </lib-tooltip>
                                </ng-container>
                                <ng-container *ngIf="!code?.isConfirming && code?.issuer?.companyName?.length < 26">
                                    {{ code.issuer?.companyName | uppercase}}
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="code?.physicalInvoices && code?.physicalInvoices?.length > 2;">
                                    <span [matTooltip]="getCodes(code)">
                                        <span class="code"
                                            *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                                            <ng-container *ngIf="index<2">
                                                {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                                            </ng-container>
                                            <ng-container *ngIf="isLast && index >= 2">
                                                ...
                                            </ng-container>
                                        </span>
                                    </span>

                                </ng-container>

                                <ng-container *ngIf="code?.physicalInvoices && code?.physicalInvoices.length < 3;">
                                    <span class="code"
                                        *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                                        <ng-container *ngIf="index<2">
                                            {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                                        </ng-container>
                                        <ng-container *ngIf="isLast && index >= 2">
                                            ...
                                        </ng-container>
                                    </span>
                                </ng-container>
                            </td>
                            <td>{{ code.isConfirming ? 'CONFIRMING' : 'FACTORING'}}</td>
                            <td>{{ code.currency | currencyToSign }} {{ code.netAmount | numberSpacing }}</td>
                            <td>{{ code?.tea || '-'}}</td>
                        </tr>

                    </table>
                </div>
            </div>
            <div class="container-flex counter">
                <p class="title-operation-count">Operaciones seleccionadas</p>
                <div class="container-box">
                    <p>{{ invoicesSelected?.length || '---'}}</p>
                </div>
            </div>
        </ng-container>

        <ng-template #empty_invoice>
            <div class="container-disclaimer">
                <p class="disclaimer">No hay facturas.</p>
            </div>
        </ng-template>
    </ng-container>
    <p class="subtitle">Elige un corte de publicación:</p>
    <div class="controls">
        <ng-container *ngIf="timeFrames">
            <mat-radio-group formControlName="onSaleTime">
                <div
                    [ngClass]="{'error' : (form.controls.onSaleTime?.touched && form.controls.onSaleTime?.invalid), 'container column': direction === 'column'}">
                    <mat-radio-button ngDefaultControl *ngFor="let option of timeFrames" [value]="option.value"
                       (change)="changeSelection($event.value)" [checked]="option.value === defaultOption"
                        >
                        <span class="wrap-mat-radio-label">
                            {{ option.text | capitalizeOnlyFirstLetter }}
                        </span>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
            <p class="duration" *ngIf="duration"><img src="../../../../../../assets/icons/info-yellow.svg"> La
                factura se publicará en {{ duration }} hrs.</p>
        </ng-container>
    </div>

    <div class="telegram-date">
        <p class="title-operation-count">Fecha de actualización de Telegram</p>
        <app-date displayStyle='plain' [date]="data?.debtor.detailUpdatedAt">
        </app-date>
    </div>

    <div class="button-container">
        <div class="error" *ngIf="telegramError">{{telegramError}}</div>
        <div>
            <fs-ui-button (click)="onSubmit()" [label]="'Siguiente'"></fs-ui-button>
        </div>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>

</form>