import { Component, OnInit, Input } from '@angular/core';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { InvoiceService } from '../../../../invoices/services/invoice.service';
import { InvoiceSelectors } from '../../../../invoices/state/invoices.selector-types';
import { TermsAndConditionsService } from 'src/app/features/system-settings/services/terms-and-conditions.service';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { DisplayFileComponent } from 'src/app/shared/UI/organisms/display-file/display-file.component';

@Component({
  selector: 'app-confirm-investment',
  templateUrl: './confirm-investment.component.html',
  styleUrls: ['./confirm-investment.component.scss']
})
export class ConfirmInvestmentComponent implements OnInit {
  @Input() entity;
  @Input() invoice$;
  terms;

  constructor(
    private store: Store<AppState>,
    public invoiceService: InvoiceService,
    private termsAndConditionsService: TermsAndConditionsService,
    private overlayService: OverlayService
  ) { }

  ngOnInit(): void {
    this.invoice$ = this.store.select(InvoiceSelectors.opportunitiesById(this.entity.invoice));

    this.termsAndConditionsService.getInvestmentTermsAndConditions().subscribe(terms => {
      this.terms = terms;
    });
  }

  showTerm(termId) {
    this.termsAndConditionsService.getAcceptInvestmentTermFileById(termId).subscribe(file => {
      this.overlayService.showComponent(DisplayFileComponent, file.file)
    })
  }
}
