import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sunat-ruc-item',
  templateUrl: './sunat-ruc-item.component.html',
  styleUrls: ['./sunat-ruc-item.component.scss'],
})
export class SunatRucItemComponent {
  @Input() companyInfo: any;
  constructor() {}
}
