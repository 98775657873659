import { capitalize } from 'lodash';
import { RucService } from './../../services/ruc.service';
import { InvoiceService } from './../../../features/invoices/services/invoice.service';
import { SuplierService } from '../../../features/my-supliers/services/supliers.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { take } from 'rxjs/operators';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { decode } from 'js-base64';
import { NgxSpinnerService } from 'ngx-spinner';
import _ from 'lodash';
import { IStepOption, TourService } from 'ngx-ui-tour-core';
import { WindowScrollService } from '../../services/window-scroll.service';
import { environment } from 'src/environments/environment';
import { MixpanelService } from '../../services/mixpanel.service';

@Component({
  selector: 'app-invoice-upload',
  templateUrl: './invoice-upload.component.html',
  styleUrls: ['./invoice-upload.component.scss'],
})
export class InvoiceUploadComponent implements OnInit, OnChanges {
  @Input() clientRuc: string;
  @Input() step: number;
  @Input() operationType = '';
  @Input() invoiceGroups = [];
  @Input() invoiceErrors = [];
  @Output() upload = new EventEmitter();

  _operationType = '';
  _invoiceGroups = [];
  _invoiceErrors = [];

  fileReader = new FileReader();

  constructor(
    private store: Store<AppState>,
    private invoiceService: InvoiceService,
    private rucService: RucService,
    private gtmService: GTMService,
    public supplierService: SuplierService,
    private spinner: NgxSpinnerService,
    public tourService: TourService,
    private windowScrollService: WindowScrollService,
    private mixPanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this._operationType = _.cloneDeep(this.operationType);
    this._invoiceGroups = _.cloneDeep(this.invoiceGroups);
    this._invoiceErrors = _.cloneDeep(this.invoiceErrors);

    if (this.step === 0) {
      this.sendEventPageView('Select invoices to upload');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoiceGroups?.currentValue) {
      this._invoiceGroups = _.cloneDeep(changes.invoiceGroups?.currentValue);
    }

    if (changes.invoiceErrors?.currentValue) {
      this._invoiceErrors = _.cloneDeep(changes.invoiceErrors?.currentValue);
    }

    if (changes.operationType?.currentValue) {
      this._operationType = _.cloneDeep(changes.operationType?.currentValue);
    }
  }

  async fileUpload(files: any) {
    try {
      if (this.step === 0) {
        this._operationType = '';
        this._invoiceGroups = [];
        this._invoiceErrors = [];
      }

      const filesPromises = [];

      Array.from(files).forEach((file: any) => {
        let filePromise = new Promise((resolve) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () =>
            resolve({ data: reader.result, name: file.name });
        });
        filesPromises.push(filePromise);
      });

      await Promise.all(filesPromises).then(async (files) => {
        this.spinner.show();

        for (let index = 0; index < files.length; index++) {
          const file = files[index];

          try {
            const fileText = decode(file.data.split(';base64,')[1]);
            const parsedInvoice = this.invoiceService.parseXmlInvoice(fileText);
            parsedInvoice.amount = parseFloat(parsedInvoice.amount).toFixed(2);
            parsedInvoice.file = file.data;
            const operationType = this.getOperationType(parsedInvoice);

            if (operationType && !this._operationType) {
              this._operationType = operationType;
            }

            if (!operationType) {
              this.addError(
                'No participas en esta factura',
                'El RUC del girador o pagador de la factura no concuerdan con tu RUC registrado con nosotros.',
                file.name
              );

              continue;
            } else if (operationType !== this._operationType) {
              this.addError(
                'Operación distinta al grupo',
                `El conjunto de facturas identificado es de tipo ${capitalize(
                  operationType
                )}. Las operaciones de ${
                  operationType === 'factoring' ? 'Confimring' : 'Factoring'
                } se deben subir en otro bloque.`,
                file.name
              );
              continue;
            }

            const isDuplicateInCurrentGroup = this._invoiceGroups.some(
              (group) =>
                group?.some(
                  (inv: any) =>
                    inv.code === parsedInvoice.code &&
                    inv.debtorRuc === parsedInvoice.debtorRuc &&
                    inv.issuerRuc === parsedInvoice.issuerRuc
                )
            );

            if (isDuplicateInCurrentGroup) {
              this.addError(
                'Ya existe esta factura',
                'La factura ya fue enviada a financiar o ya se encuentra dentro del grupo de facturas que acabas de subir.',
                file.name
              );
              continue;
            }

            const isDuplicateInDB: any = await this.invoiceService
              .checkDuplicate(
                parsedInvoice.issuerRuc,
                parsedInvoice.debtorRuc,
                parsedInvoice.code,
                operationType === 'confirming'
              )
              .toPromise();
            // const isDuplicateInDB =
            //   await this.doesInvoiceExistAndInValidStatus(
            //     invoiceJson.code,
            //     invoiceJson.issuerRuc
            //   );

            if (isDuplicateInDB.result) {
              this.addError(
                'Ya existe esta factura',
                'La factura ya fue enviada a financiar o ya se encuentra dentro del grupo de facturas que acabas de subir.',
                file.name
              );
              continue;
            }

            let company: any = {};
            try {
              company = await this.rucService
                .getRUC(
                  operationType === 'factoring'
                    ? parsedInvoice.debtorRuc
                    : parsedInvoice.issuerRuc
                )
                .toPromise();

              if (company) {
                parsedInvoice.companyId = company?._id;
                if (operationType === 'factoring') {
                  parsedInvoice.debtorCompanyName =
                    company?.companyName?.toUpperCase();
                } else {
                  parsedInvoice.issuerCompanyName =
                    company?.companyName?.toUpperCase();
                }
              }
            } catch (error) {
              this.addError(
                'RUC no existe',
                `El RUC de la empresa ${
                  operationType === 'factoring' ? 'pagadora' : 'giradora'
                } no esta registrada en SUNAT.`,
                file.name
              );
              continue;
            }

            const group = this._invoiceGroups.find((group) =>
              group.some(
                (inv: any) =>
                  inv.currency === parsedInvoice.currency &&
                  (operationType === 'factoring'
                    ? inv.debtorRuc === parsedInvoice.debtorRuc
                    : inv.issuerRuc === parsedInvoice.issuerRuc)
              )
            );

            if (!Array.isArray(group)) {
              this._invoiceGroups.push([parsedInvoice]);
            } else {
              group.push(parsedInvoice);
            }
          } catch (error) {
            this.addError(
              'Archivo XML no válido',
              'El archivo XML está dañado o fue generado de manera errónea. Por favor contáctate con soporte para este caso.',
              file.name
            );

            let event = {
              event: '[Platform][Invoice][Upload][Error]',
              _inputError: 'Archivo XML no válido',
            };
            this.gtmService.newEvent(event);
            console.log(error);
          }
        }
      });
    } catch (error) {
      this.addError(
        'Error de procesamiento',
        'Se produjo un error al procesar la factura.',
        ''
      );

      let event = {
        event: '[Platform][Invoice][Upload][Error]',
        _inputError: 'Error de procesamiento',
      };
      this.gtmService.newEvent(event);
      console.log(error);
    } finally {
      this.upload.emit({
        operationType: this._operationType,
        invoiceGroups: this._invoiceGroups,
        invoiceErrors: this._invoiceErrors,
      });
      this.spinner.hide();
      const operationType = this._operationType
      const currencyArray = this.determineCurrency(this._invoiceGroups);

      if(this._invoiceGroups.length > 0){
        this.sendEventPageView(
          'Add invoices data',
          operationType.charAt(0).toUpperCase() + operationType.slice(1),
          currencyArray
        );
      }
    
    }
  }

  addError(errorTitle: string, errorDescription: string, filename: string) {
    const error = this._invoiceErrors.find(
      (err) => err.errorTitle === errorTitle
    );
    if (error) {
      error.filename += `, ${filename}`;
    } else {
      this._invoiceErrors.push({
        errorTitle,
        errorDescription,
        filename,
      });
    }
  }

  getOperationType(parsedInvoice: any) {
    if (this.clientRuc === parsedInvoice.debtorRuc) {
      return 'confirming';
    } else if (this.clientRuc === parsedInvoice.issuerRuc) {
      return 'factoring';
    } else {
      return '';
    }
  }

  doesInvoiceExistAndInValidStatus(code, ruc) {
    return this.store
      .select(InvoiceSelectors.existsByCodeAndNotDisapproved(code, ruc))
      .pipe(take(1))
      .toPromise();
  }

  sendEventPageView(section, operationType?, currency?) {
    const baseUrl = environment.clientUrl;
    const eventName = 'page_view';
    const eventData: any = {
      page_url: baseUrl + '/invoices/create',
      page_title: 'New invoices',
      section_step: section,
      operationType: operationType,
      currency: currency,
    };
    this.mixPanelService.newEvent(eventName, eventData);
  }

  determineCurrency(array) {
    let results = [];
    for (let subarray of array) {
      results.push(this.checkCurrency(subarray));
    }

    let uniqueCurrencies = new Set(results);
    let result;
    if (uniqueCurrencies.size === 1) {
      result = uniqueCurrencies.has('PEN') ? 'PEN' : 'USD';
    } else {
      result = 'PEN & USD';
    }

    return result;
  }

  checkCurrency(subArray) {
    let hasPEN = subArray.some((obj) => obj.currency.toLowerCase() === 'pen');
    let hasUSD = subArray.some((obj) => obj.currency.toLowerCase() === 'usd');
    if (hasPEN && hasUSD) return 'PEN & USD';
    else if (hasPEN) return 'PEN';
    else if (hasUSD) return 'USD';
    else return 'None';
  }
}
