import { Component, OnInit } from '@angular/core';
import { FinancialTransactionActions } from '../../../state/financial-transactions.action-types';
import { FinancialTransactionItemComponent } from '../../organisms/financial-transaction-item/financial-transaction-item.component';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { savingSuccessStateById } from '../../../state/financial-transactions.selectors';
import { OurBankAccountsComponent } from 'src/app/shared/UI/organisms/our-bank-accounts/our-bank-accounts.component';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { Banks } from 'src/app/shared/enums/Banks.enum';
import { Currency } from 'src/app/shared/enums/Currency.enum';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-financial-transaction-deposit',
  templateUrl: './create-financial-transaction-deposit.component.html',
  styleUrls: ['./create-financial-transaction-deposit.component.scss']
})
export class CreateFinancialTransactionDepositComponent implements OnInit {

  ourBankAccounts = [
    {
      name: Banks["BBVA Continental"],
      currency: Currency.PEN,
      number: "001101420100097160",
      cci: "01114200010009716074"
    },
    {
      name: Banks["BBVA Continental"],
      currency: Currency.USD,
      number: "001101420100097179",
      cci: "01114200010009717974"
    },
    {
      name: Banks['Banco de Crédito del Perú'],
      currency: Currency.PEN,
      number: "1948756966099",
      cci: "00219400875696609993"
    },

    {
      name: Banks['Banco de Crédito del Perú'],
      currency: Currency.USD,
      number: "1948744887199",
      cci: "00219400874488719993"
    }
  ]
  constructor(
    private overlayService: OverlayService,
    private windowScrollService: WindowScrollService,
    private gmtService: GTMService,
    private location: Location,

  ) { }

  ngOnInit() {
  }

  showOurBankAccounts() {
    this.overlayService.showComponent(OurBankAccountsComponent);
  }

  submitHandler(formValue) {
    this.sendEventPageView(2)
    this.overlayService.startConfirmation(
      formValue,
      FinancialTransactionItemComponent,
      'details',
      'create',
      'Tu depósito se encuentra en validación. Dependiendo del banco podrá tomar hasta dos días hábiles, aunque suele ser más rápido. ;)',
      FinancialTransactionActions.Saving,
      '/financial-transactions',
      savingSuccessStateById,
      null,
      this.sendEventPage.bind(this)
    )
  }
  sendEventPage(){
    this.sendEventPageView(3)
    // this.sendEventSprig()
  }

  // sendEventSprig(){
  //   localStorage.setItem("send_survey", "true");
  // }

  sendEventPageView(title){
    this.windowScrollService.sendNewEvent('Financial Transactions', `Deposit Step ${title}`)
  }
  goBack(){
    this.location.back();
  }
}
