import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirmation-cancel',
  templateUrl: './modal-confirmation-cancel.component.html',
  styleUrls: ['./modal-confirmation-cancel.component.scss'],
})
export class ModalConfirmationCancelComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmationCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
