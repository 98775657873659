import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { createPopup, createWidget } from '@typeform/embed';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';

@Component({
  selector: 'app-side-menu-item',
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
})
export class SideMenuItemComponent implements OnInit {
  @Output() clicked = new EventEmitter();
  @Input() item;
  @Input() isActive;
  @Input() key;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  profile;
  constructor(
    private windowScrollService: WindowScrollService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;
        }
      });
  }

  click() {
    let data: any = {
      type: this.item.type,
    };

    if (this.item.type === 'button') {
      data = { ...data, action: this.item.action };
    } else if (this.item.type === 'link') {
      data = { ...data, target: this.item.target };
    } else if (this.item.type === 'feedback') {
      this.openModal();
    } else {
      this.goToLink(this.item.target);
    }

    this.clicked.emit(data);
  }

  getActive(item) {
    if (item.type === 'feedback' && this.profile) {
      if (
        !this.profile.hasOwnProperty('feedbackAnimationClosed') ||
        !this.profile.feedbackAnimationClosed
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  openModal() {
    this.windowScrollService.openFeedbackModal();
    // const pop = createPopup('kEqFv5UH', {
    //   width: 500,
    //   height: 500,
    //   hidden: {
    //     userid: '',
    //     role: '',
    //     //  pageurl: baseUrl + this.resourceURI || '',
    //   },
    //   keepSession: false,
    //   // onSubmit:  (data) => {
    //   //   console.log('forms submitted with id:', data.responseId)
    //   //   // to retrieve the response use `data.responseId` (you have to do it server-side)
    //   //   // more details: https://developer.typeform.com/responses/
    //   // },
    // });
    // pop.open(); // call open() on created popup
  }
  goToLink(url: string) {
    window.open(url, '_blank');
  }

  getIconUrl(name) {
    return '../../../../../assets/icons/' + name;
  }
}
