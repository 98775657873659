import { Injectable } from '@angular/core';
import gtag, { install } from 'ga-gtag';

@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor() { }

  initialize() {
    install("G-CEE1K9WCWP");
  }

  sendEvent(eventName: string, eventData: any) {
    gtag('event', eventName, eventData)
  }
}
