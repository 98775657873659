import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financial-transactions-page',
  template: `
  <div class="intercom-container-ft">
    <router-outlet></router-outlet>
  </div>`
})
export class FinancialTransactionsPageComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
