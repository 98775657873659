<div *ngIf="companyInfo" class="container">
    <div class="item intercom-contact-item">
        <div class="row">
            <div>
                <p class="tiny title bold">Razón social</p>
                <p class="tiny blue bold">{{companyInfo?.companyName | uppercase}}</p>
            </div>
        </div>
        <div class="row">
            <div>
                <p class="tiny"><span class="bold">RUC:</span> {{companyInfo?.companyRuc}}</p>
            </div>
        </div>
        <div class="row">
            <div>
                <p class="tiny"><span class="bold">Departamento:</span> {{companyInfo?.companyDepartment | capitalizeFirstLetters}}</p>
            </div>
        </div>
        <div class="row">
            <div>
                <p class="tiny"><span class="bold">Dirección:</span> {{companyInfo?.companyAddress | uppercase}}{{((companyInfo?.companyDistrict !== '-' && companyInfo?.companyDistrict) ? (', ' + companyInfo?.companyDistrict) : '') | capitalizeFirstLetters}}</p>
            </div>
        </div>
    </div>
</div>