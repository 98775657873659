<ng-container *ngIf="!autoComplete">
  <mat-form-field floatLabel="auto" [ngClass]="{'custom-mat-invalid-style': _errorStatus === true}">
    <mat-label>{{label}}</mat-label>
    <mat-select [disabled]="isDisabled" [(value)]="selectedValue" (onSelectionChange)="selectionChanged($event.value)" *ngIf="hasTooltip">
      <mat-option *ngFor="let option of parsedOptions" [value]="option.value" [matTooltip]="option.text"
        matTooltipPosition="below">{{ option.text }}</mat-option>
    </mat-select>
    <mat-select  [disabled]="isDisabled" [(value)]="selectedValue" (onSelectionChange)="selectionChanged($event.value)" *ngIf="!hasTooltip">
      <mat-option *ngFor="let option of parsedOptions" [value]="option.value">{{ option.text }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="autoComplete">
  <mat-form-field class="example-full-width" [ngClass]="{'custom-mat-invalid-style': _errorStatus === true}">
    <mat-label>{{label}}</mat-label>
    <input type="text" [value]="selectedValue" matInput (focusout)="autcompleteFocusOut($event.target.value)"
      (focus)="autoCompleteValueChanged($event.target.value)" (input)="autoCompleteValueChanged($event.target.value)"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectionChanged($event.option.value.value)"
      [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.text}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>