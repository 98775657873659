<h1>Evaluar</h1>

<div class="controls">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <app-input [(ngModel)]="addedValue" (ngModelChange)="calculateEvaluation(addedValue)"
            (keyup.enter)="calculateEvaluation($event.target.value)"
            (focusout)="calculateEvaluation($event.target.value)" formControlName="addedValue" autocomplete="off"
            [default]="data?.evaluation?.addedValue" type="number" label="Criterio experto (%)">
        </app-input>

        <div class="box-container">
            <h5>Resultado</h5>
            <div class="one-line evaluation-label">
                <p class="evaluation-text">
                    Puntaje total
                </p>
                <p>
                    <span *ngIf="!totalValue">
                        ---
                    </span>
                    <span>
                        {{totalValue}}
                    </span>
                </p>
            </div>
            <div class="one-line evaluation-label">
                <p class="evaluation-text">
                    Rating
                </p>
                <p>
                    <span *ngIf="!raiting">
                        ---
                    </span>
                    <span>
                        {{raiting}}
                    </span>
                </p>
            </div>
            <div class="one-line evaluation-label">
                <p class="evaluation-text">
                    Dictamen
                </p>
                <p>
                    <app-solid-label [content]="label" [background]="color" [width]="'109'"></app-solid-label>
                </p>
            </div>
        </div>
        <ng-container *ngIf="totalValue >= 70">
            <div class="line-container">
                <h6>Otorgar Linea</h6>
                <app-input formControlName="creditLine" [default]="data?.evaluation?.creditLine" class="credit-field"
                    autocomplete="off" type="number" label="Linea otorgada">
                </app-input>
                <div class="container-radio">
                    <app-radiolist-circle formControlName="creditLineType"
                        [defaultOption]="data?.evaluation?.creditLineType" [options]="creaditLineOptions">
                    </app-radiolist-circle>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="totalValue > 0">
            <div class="comment-container">
                <h6>Comentario</h6>
                <mat-form-field [style.fontSize]="12" appearance="outline">
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="comment"
                        cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" [(ngModel)]="comment">
                        </textarea>
                </mat-form-field>
            </div>
        </ng-container>
    </form>

    <ng-container *ngIf="totalValue >= 70">
        <form [formGroup]="formTelegram">
            <div class="message-container">
                <div class="container-button">
                    <p class="title-date ">Telegram</p>
                    <span class="copied-message" *ngIf="savedFlag | async">¡Se ha actualizado exitosamente!</span>
                    <lib-button-fab (click)="updateTelegram()" icon="save" label="Actualizar">
                    </lib-button-fab>
                    <!-- <div>
                        <a class="hyperlink" (click)="updateTelegram()">Actualizar</a>
                    </div> -->
                </div>
                <mat-form-field [style.fontSize]="12" appearance="outline">
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        formControlName="companyDetail" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                        [(ngModel)]="companyDetail">
                        </textarea>
                </mat-form-field>
                <mat-hint *ngIf="formTelegram.controls.companyDetail?.touched && formTelegram.controls.companyDetail?.invalid">
                    <p [ngClass]="{'custom-mat-invalid-style': formTelegram.controls.companyDetail?.invalid === true}">Telegram
                        (obligatorio)</p>
                </mat-hint>

            </div>
        </form>

        <!-- <input type="button" class="button button-blue button-update" value="Actualizar" /> -->

        <div class="container-date">
            <p class="title-date">Fecha de actualización</p>
            <div class="item">
                <app-date [pulsing]="savedFlag | async" displayStyle='plain' [date]="detailUpdatedAt">
                </app-date>
            </div>
        </div>

    </ng-container>

</div>

<div class="button-container button-direction-column">
    <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Siguiente'"></fs-ui-button>
    <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
</div>