import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../../../environments/environment';
import { Roles } from '../enums/Roles.enum';

@Injectable({
  providedIn: 'root',
})
export class GTMService {
  constructor(private gtmService: GoogleTagManagerService) {}

  setIdentity(_userId, _userFullName?, _userRole?, _userEmail?, _balance?, _userChannel?, _userNeswletter?, _userCompanyName?, _userStatus?, _userIsConfirming?, _userCompanyRuc?, _userConversionCount?, _userCoversionAmount?, _userConversionLastTime?, _userHasDeposit?, _userHasInvestment?,_userHasInvestmentDelayed?, _userDepositsAmount?, _userBalancePEN?, _userBalanceUSD?, _userPhoneNumber? , _segment?, _accountManager?) {
    this.gtmService.pushTag({
      event: '[Platform][Auth][New]',
      _userId,
      _userFullName,
      _userRole,
      _userEmail,
      _balance,
      _userChannel, 
      _userNeswletter, 
      _userCompanyName, 
      _userStatus, 
      _userIsConfirming,
      _userCompanyRuc, 
      _userConversionCount, 
      _userCoversionAmount, 
      _userConversionLastTime,
      _userHasDeposit, 
      _userHasInvestment, 
      _userHasInvestmentDelayed, 
      _userDepositsAmount, 
      _userBalancePEN, 
      _userBalanceUSD, 
      _userPhoneNumber,
      _segment: _segment,
      _accountManager
    });

    // console.log('Pushing',
    // _userHasDeposit, 
    // _userHasInvestment, 
    // _userHasInvestmentDelayed, 
    // _userDepositsAmount, 
    // _userBalancePEN, 
    // _userBalanceUSD, 
    // _userPhoneNumber)
  }

  resetIdentity() {
    this.gtmService.pushTag({
      _userId: undefined,
      _userFullName: undefined,
      _userRole: undefined,
      _userEmail: undefined,
    });
  }

  platformReady() {
    this.gtmService.pushTag({
      event: '[Platform][General][Ready]',
    });
  }

  pageView(resourceURI) {
    const baseUrl = environment.clientUrl;

    this.gtmService.pushTag({
      event: '[Platform][Page][View]',
      pageUrl: baseUrl + resourceURI,
    });
  }

  newRegistration(role, _userId) {
    let eventName;

    if (role === Roles.CLIENT) {
      eventName = '[Platform][User/Client][New]';
    } else if (role === Roles.INVESTOR) {
      eventName = '[Platform][User/Investor][New]';
    } else {
      console.error(`Unkown role: ${role}`);
    }

    if (eventName && _userId) {
      this.gtmService.pushTag({
        event: eventName,
        _userId,
      });
    }
  }

  newEvent(event) {
    if (event) {
      //console.log('Event', event);
      this.gtmService.pushTag(event);
    }
  }
}
