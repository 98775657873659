import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() content
  @Input() imgIcon
  @Input() closedIcon = true;
  @Input() alert = false;
  @Output() dismissChip = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  dismiss(){
    this.dismissChip.emit();
  }

  getImgSrc() {
    const baseLocation = '../../../../../../assets/icons/';
    return baseLocation + this.imgIcon
  }
}
