<div class="op-list">
    <div class="one-line-admin-title">
        <!-- (selectedTabChange)="filterTabs($event);" -->
        <!-- [(selectedIndex)]="selectedTab" -->
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                    En proceso ({{filteredOperations?.length}})
                </ng-template>

                <ng-template matTabContent>
                    <lib-filter-container [filterArray]="filterItemsArray" (filterUpdate)="filterUpdates($event)"
                        [attribute]="attribute" [operator]="operator" [accountManagers]="allAccountManagers"
                        [companies]="allCompanies">
                    </lib-filter-container>

                    <ng-container *ngIf="filteredOperations?.length > 0; else emptyOperation">
                        <lib-row-scrollable class="titles" [border]="true">
                            <div class="child-row first-column">
                                <lib-text weight="medium" size="xxs" color="dark-gray" content="NEGOCIO"></lib-text>
                            </div>
                            <div class="child-row">
                                <lib-text weight="medium" size="xxs" color="dark-gray" content="INFORMACIÓN"></lib-text>
                            </div>
                            <div class="child-row">
                                <lib-text weight="medium" size="xxs" color="dark-gray" content="CHECKLIST"></lib-text>
                            </div>
                            <div class="child-row">
                                <lib-text weight="medium" size="xxs" color="dark-gray" content="ACCIONES"></lib-text>
                            </div>
                        </lib-row-scrollable>
                        <cdk-virtual-scroll-viewport itemSize="200">
                            <lib-operations-item (goPayerDetail)="openDetailPayer($event)"
                                (goDetail)="openDetail($event)" (goSlack)="gotoSlack($event)"
                                (goPipeDrive)="gotoPipeDrive($event)" (modalEvent)="openModal($event)"
                                (triggerTransferDate)="setTransferDate($event)"
                                *cdkVirtualFor="let entity of filteredOperations" [entity]="entity"
                                [role]="profile.role">
                            </lib-operations-item>
                        </cdk-virtual-scroll-viewport>
                    </ng-container>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    OP. Listas ({{operationsReady?.length}})
                </ng-template>
                <ng-template matTabContent>
                    <div style="margin-top: 11px;">
                        <lib-filter-layout [filterOptions]="filterOptions" [inputOptions]="inputOptions"
                            [selectedOption]="filteredInput" [inputLabel]="inputLable"
                            (onFilterOptionsChange)="filterOptionsChanges($event)"
                            (onFilterInputChange)="filterInputChanges($event)"></lib-filter-layout>

                        <ng-container *ngIf="operationsReady?.length > 0; else emptyOperation">
                            <lib-row-scrollable class="titles" [border]="true">
                                <div class="child-row first-column">
                                    <lib-text weight="medium" size="xxs" color="dark-gray" content="NEGOCIO"></lib-text>
                                </div>
                                <div class="child-row">
                                    <lib-text weight="medium" size="xxs" color="dark-gray"
                                        content="INFORMACIÓN"></lib-text>
                                </div>
                                <div class="child-row" style="width:250px">
                                    <lib-text weight="medium" size="xxs" color="dark-gray"
                                        content="CHECKLIST"></lib-text>
                                </div>
                                <div class="child-row">
                                    <lib-text weight="medium" size="xxs" color="dark-gray"
                                        content="ACCIONES"></lib-text>
                                </div>
                            </lib-row-scrollable>
                            <cdk-virtual-scroll-viewport itemSize="200">
                                <lib-operations-item (quickAction)="newAction($event)" [opsReady]="true"
                                    (goPayerDetail)="openDetailPayer($event)" (goDetail)="openDetail($event)"
                                    (goSlack)="gotoSlack($event)" (goPipeDrive)="gotoPipeDrive($event)"
                                    (modalEvent)="openModal($event)" (triggerTransferDate)="setTransferDate($event)"
                                    *cdkVirtualFor="let entity of operationsReady" [entity]="entity"
                                    [role]="profile.role">
                                </lib-operations-item>
                            </cdk-virtual-scroll-viewport>
                        </ng-container>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>



<ng-template #emptyOperation>
    <div class="titles-empty container-flex">
        <lib-text weight="medium" size="m" content="No se encontraron operaciones"></lib-text>
        <lib-text class="space-top-m" weight="medium" size="s" content="Intenta con otros filtros"></lib-text>
    </div>
</ng-template>