import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard'
import { Observable, BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-bank-account-item',
  templateUrl: './bank-account-item.organism.html',
  styleUrls: ['./bank-account-item.organism.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankAccountItemOrganism implements OnInit {
  @Input() entity;
  @Input() noControls = false;
  @Output() onDelete = new EventEmitter();
  @Input() copyAccounts = false;
  @Input() isHide = false
  numberCopied = new BehaviorSubject<any>(false);
  cciCopied = new BehaviorSubject<any>(false);
  @Input() isDisabled = false

  constructor(private clipboard: Clipboard) { }

  ngOnInit() {
    if (this.copyAccounts) {
      this.noControls = true;
    }
  }

  delete() {
    if (!this.isDisabled) this.onDelete.emit(this.entity)
  }

  copyNumber() {
    this.copyToClipBoard(this.entity.number);
    this.numberCopied.next(true)
    setTimeout(() => {
      this.numberCopied.next(false)
    }, 1000);
  }
  copyCCI() {
    this.copyToClipBoard(this.entity.cci);
    this.cciCopied.next(true)
    setTimeout(() => {
      this.cciCopied.next(false)
    }, 1000);
  }
  copyToClipBoard(value) {
    this.clipboard.copy(value);
  }


}
