<!--Company Information-->
<ng-container *ngIf="debtorInformation">
    <app-client-information [debtorInformation]="debtorInformation">
    </app-client-information>
    <div class="row-flex mt-15">
        <div class="vl"></div>
        <p class="warning-message">Para modificar datos del cliente, <a class="container-link"
                (click)="openIntercom()">escríbenos aquí.</a></p>
    </div>
</ng-container>
<!--Sumary operations-->
<ng-container *ngIf="debtorInformation">
    <div class="intercom-section-operations">
        <div class="one-line">
            <div>
                <h3>Resumen de operaciones</h3>
            </div>
        </div>
        <ng-container *ngIf="invoices">
            <app-invoice-operations-overview *ngIf="invoices.length > 0; else no_summary_invoices" [invoice]="invoices">
            </app-invoice-operations-overview>
            <ng-template #no_summary_invoices>
                <div class="container-disclaimer">
                    <p class="disclaimer">Este cliente no tiene operaciones.</p>
                </div>
            </ng-template>
        </ng-container>
    </div>
</ng-container>


<!--Collections dates-->
<ng-container *ngIf="debtorInformation">
    <ng-container *ngIf="invoices | filterIn:'status':actualInvoicesStatus as invoicesToBeCollected">
        <ng-container *ngIf="invoiceService.calculateCollectedDateForClient(invoicesToBeCollected) as invoicesFinal">
            <div *ngIf="invoicesFinal.length > 0" class="one-line mt-20">
                <div>
                    <h3>Fechas próximas de pago</h3>
                </div>
            </div>
            <app-collection-client-dates *ngIf="invoicesFinal.length > 0"
                [collections]="invoicesFinal | sort:'toBeCollectedInClient':'smallest'">
            </app-collection-client-dates>
        </ng-container>
    </ng-container>
</ng-container>

<!--Contacts client-->
<ng-container *ngIf="debtorInformation">
    <div class="one-line mt-20">
        <div>
            <h3>Contactos del cliente</h3>
        </div>
        <div>
            <a class="add-contact" (click)="addContact(debtorInformation.companyRuc)"> <img
                    src="../../../../../../assets/icons/plus-black.svg" /></a>
        </div>
    </div>
    <ng-container *ngIf="contactsDebtor.length > 0; else no_contacts_client">
        <div class="contacts-section">
            <ng-container *ngIf="contactsDebtor.length > 0; else no_contacts_client">
                <ng-container *ngFor="let contact of contactsDebtor">
                    <lib-contact-item [name]="contact.name" [jobTitle]="contact.jobTitle" [email]="contact.email"
                        [phone]="contact.phone" [owner]="contact.owner">
                    </lib-contact-item>
                </ng-container>
            </ng-container>
        </div>


        <div class="row-flex mt">
            <div class="vl"></div>
            <p class="warning-message">Para editar o eliminar contactos, <a class="container-link"
                    (click)="openIntercom()">escríbenos aquí.</a></p>
        </div>
    </ng-container>
    <ng-template #no_contacts_client>
        <div class="container-disclaimer intercom-container-empty-contacts">
            <p class="disclaimer">Este cliente no tiene contactos.</p>
            <div class="container-link icon-text-align" *ngIf="debtorInformation">
                <a class="cp add-contact" (click)="addContact(debtorInformation.companyRuc)">Nuevo
                    contacto </a>
                <mat-icon class="add-contact">add</mat-icon>
            </div>
        </div>
    </ng-template>
</ng-container>

<!--Invoices by debtors-->
<ng-container *ngIf="debtorInformation">
    <div class="intercom-section-invoices">
        <div class="one-line mt-20">
            <div class="intercom-title-invoice">
                <h3>Facturas</h3>
            </div>
            <div>
                <a class="add-invoice" (click)="openInvoice()"> <img
                        src="../../../../../../assets/icons/plus-black.svg" /></a>
            </div>
        </div>
        <ng-container *ngIf="invoices">
            <ng-container *ngIf=" invoices.length > 0; else no_invoices_client">
                <div class="list-invoice">
                    <app-invoice-by-debtor-item *ngFor="let invoice of invoicesPaginated"
                        (click)="openInvoiceDetail(invoice._id)" [entity]="invoice">
                    </app-invoice-by-debtor-item>

                    <app-paginator *ngIf="invoicesPaginated?.length > 0" [totalCount]="pagination.total"
                        [pageSize]="pagination.limit" [skip]="pagination.skip" (pagination)="paginationHandler($event)">
                    </app-paginator>
                </div>


            </ng-container>

            <ng-template #no_invoices_client>
                <p class="disclaimer">¡Aún no has subido ninguna factura!</p>
            </ng-template>
        </ng-container>
    </div>
</ng-container>

<div *ngIf="debtorInformation" class="button-group">
    <div class="button-container">
        <fs-ui-button routerLink="/my-clients" class="client-detail-back" [type]="'tertiary'" [label]="'Regresar'">
        </fs-ui-button>
    </div>

</div>