import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UniqueDepositDocumentNumber } from "src/app/shared/validators/unique-deposit-number";

@Component({
  selector: "app-approve-deposit-dialog",
  templateUrl: "./approve-deposit-dialog.component.html",
  styleUrls: ["./approve-deposit-dialog.component.scss"],
})
export class ApproveDepositDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ApproveDepositDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private documentValidation: UniqueDepositDocumentNumber
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      documentNumber: ["", [Validators.required], [this.documentValidation.isDocumentNumberUnique()]],
    });
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      let documentNumber = this.form.get("documentNumber").value;

      this.dialogRef.close({ data: documentNumber });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
