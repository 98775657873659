import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { UsersSelectors } from '../../../state/users.selector-types';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserStatus } from 'src/app/shared/enums/UserStatus.enum';
import { UsersService } from '../../../services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-users-list-template',
  templateUrl: './users-list-template.component.html',
  styleUrls: ['./users-list-template.component.scss']
})
export class UsersListTemplateComponent implements OnInit {
  users: any[];
  filtersForm: FormGroup;
  rolesList = [Roles.CLIENT, Roles.INVESTOR];
  statusesList = Object.values(UserStatus);
  showBlocked = false;
  showNotSyncedOnly = false;
  selectedUser;
  pagination = {
    total: 0,
    limit: 10,
    skip: 0
  }
  defaultPagination = {
    total: 0,
    limit: 10,
    skip: 0
  }
  filterDefault = {
    roles: this.rolesList,
    statuses: [UserStatus["Email Verified"]],
    showBlocked: false,
    showNotSyncedOnly:false,
    user: undefined,
    pagination: undefined

  };
  
  /*
  selectedRole: Roles.INVESTOR | Roles.INVESTOR = Roles.INVESTOR;

  filters = [
    { property: 'status', text: 'Creados', value: 'created' },
    { property: 'status', text: 'Aprobados', value: 'approved' },
    { property: 'isBlocked', text: 'Bloqueados', value: 'true' },
  ]*/

  constructor(
    private store: Store<AppState>,
    private overlayService: OverlayService,
    private router: Router,
    private fb: FormBuilder,
    private service: UsersService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    const previousFilter = JSON.parse(localStorage.getItem('users_filter'))
    this.filtersForm = this.fb.group({
      roles: [this.filterDefault.roles],
      statuses: [this.filterDefault.statuses],
      showBlocked: [this.filterDefault.showBlocked],
      showNotSyncedOnly: [this.filterDefault.showNotSyncedOnly],
      user: [this.filterDefault.user],
      pagination: [this.filterDefault.pagination],
    });
    if (previousFilter) {
      this.filtersForm.controls['roles'].setValue(previousFilter.roles);
      this.filtersForm.controls['statuses'].setValue(previousFilter.statuses);
      this.filtersForm.controls['showBlocked'].setValue(previousFilter.showBlocked);
      this.filtersForm.controls['showNotSyncedOnly'].setValue(previousFilter.showNotSyncedOnly);
      this.filtersForm.controls['user'].setValue(previousFilter.user_id);
      this.filtersForm.controls['pagination'].setValue(previousFilter.pagination);
      this.selectedUser = previousFilter.user;
      this.pagination = {
        total: 0,
        limit: 10,
        skip: previousFilter.pagination
      }
    }

    this.applyFilter(true);
  }
  paginationHandler(data) {
    this.filtersForm.controls['pagination'].setValue(data.skip);
    this.pagination.skip = data.skip;
    this.pagination.limit = data.limit;
    this.applyFilter(true);
  }

  resetFiltersToDefaults() {
    this.filtersForm.controls['roles'].setValue(this.filterDefault.roles);
    this.filtersForm.controls['statuses'].setValue(this.filterDefault.statuses);
    this.filtersForm.controls['showBlocked'].setValue(this.filterDefault.showBlocked);
    this.filtersForm.controls['showNotSyncedOnly'].setValue(this.filterDefault.showNotSyncedOnly);
    this.filtersForm.controls['pagination'].setValue(this.filterDefault.pagination);
    this.filtersForm.controls['user'].setValue(this.filterDefault.user);
    this.selectedUser = undefined;

    this.applyFilter();
  }
  resetFilters() {
    this.filtersForm.reset();
    this.selectedUser = undefined;

    this.applyFilter();
  }
  applyFilter(paginate = false) {
    this.spinner.show();
    
    if(!paginate) {
      this.pagination = Object.assign({}, this.defaultPagination);
    }

    this.service.get(this.pagination.skip, this.pagination.limit, this.filtersForm.value)
    .subscribe((result:any) => {
      if(result?.users && result?.users?.entities?.length > 0) {
        this.users = result.users.entities;
      
        this.pagination.total = result.users.count;
        const keys = Object.keys(this.filtersForm.value).filter(key => this.filtersForm.value[key] !== null && this.filtersForm.value[key] !== undefined)
        const userFilter = {}
        userFilter['pagination'] = this.pagination.skip;
        keys.map(key => {
          if (key === 'user') {
            userFilter['user_id'] = this.filtersForm.value.user;
            userFilter['user'] = {
              familyNames: result.users.entities[0]['familyNames'],
              names: result.users.entities[0]['names'],
              idNumber: result.users.entities[0]['idNumber'],
              idType: result.users.entities[0]['idType'],
              role: result.users.entities[0]['role'],
              _id: result.users.entities[0]['_id']
            }
            return
          }
          userFilter[key] = this.filtersForm.value[key]
        })
        /*
        console.log(keys)
        console.log(userFilter)
        */

        localStorage.setItem('users_filter', JSON.stringify(userFilter))
      } else {
        if(this.pagination.skip != 0){
          this.pagination.skip -= 10;
          this.applyFilter(true)
        }
        this.users = undefined;
      }

      this.spinner.hide();
    });
  }

  /*
  roleSelectedChanged(role) {
    console.log(role)
    this.selectedRole = role;
  }*/
}
