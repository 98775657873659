import { createAction, props } from '@ngrx/store';

export const LoadAll = createAction(
    '[Users] Load All',
);
export const LoadedAllSuccess = createAction(
    '[Users] Loaded All Success',
    props<{ users: any }>()
);
export const SetProfile = createAction(
    '[Users] Set Profile',
    props<{ _id: any, role: any, names: any, isBlocked: any, status: any, companyRuc?: any, familyNames?: any, legalRep: any, profession: any, legalCounter: number, legalComment: any, balance: number, isConfirming?: any, channel?: any, isNewsletter?: any, companyName?: any, conversionAmount?: any, conversionCount?: any, conversionLastTime?: any, hasDeposit?: any, hasInvestment?: any, hasInvestmentDelayed?: any, depositsAmount?: any, balancePEN?: any, balanceUSD?: any, phoneNumber?: any, segment?: any, accountManager?: any, taxResidency?: any, idType?: any, feedbackAnimationClosed?: any, showNewAnimationOnDownloadButton?: any, whatsappStatus?: any, whatsappActivationResentAt?: any, whatsappActivatedOnce?: any , isContractUpdateSeen?:any}>()
);

export const UpdateProfile = createAction(
    '[Users] Update Profile',
    props<{ changes: any }>()
);

export const SetIdentity = createAction(
    '[Users] Set Profile',
);
export const LoadProfile = createAction(
    '[Users] Load Profile',
);
export const LoadProfileSuccess = createAction(
    '[Users] Load Profile Success',
    props<{ entity: any }>()
);
export const Reset = createAction(
    '[Users] Reset'
);
export const Saving = createAction(
    '[Users] Saving',
    props<{ entity: any, mode: 'edit' | 'create', 'editOwn' }>()
);
export const SaveSucceeded = createAction(
    '[Users] Save Succeeded',
    props<{ result: any }>()
);
export const SaveFailed = createAction(
    '[Users] Save Failed',
    props<{ error: any }>()
);
export const SavingProfile = createAction(
    '[Users] Saving Profile',
    props<{ entity: any, mode: 'editOwn' }>()
);
export const SaveProfileSucceeded = createAction(
    '[Users] Save Profile Succeeded',
    props<{ result: any }>()
);
export const SaveProfileFailed = createAction(
    '[Users] Save Profile Failed',
    props<{ error: any }>()
);
