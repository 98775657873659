import { Directive, OnInit, ViewContainerRef, TemplateRef, Input, OnDestroy } from '@angular/core';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { take, skip, skipUntil, filter, takeUntil } from 'rxjs/operators';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { Subject } from 'rxjs';

@Directive({
    selector: '[allowedRoles]'
  })
export class AllowedRoleDirective implements OnInit, OnDestroy {
  @Input() allowedRoles: any;

  isVisible = false;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.store.select(UsersSelectors.profileProperty('role'))
    .pipe(
      (takeUntil(this.ngUnsubscribe)),
      filter(currentUserRole => !!currentUserRole),
    )
    .subscribe((currentUserRole) => {
      if(this.allowedRoles.includes(currentUserRole)) {
        if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
            this.isVisible = false;
            this.viewContainerRef.clear();
        }
    });
  }
  
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}