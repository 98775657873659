
<app-page-title 
[title]="pageTitle"
[description]="pageDescription">
</app-page-title>
<ng-container *ngFor="let bankAccount of bankAccounts$ | async">
  
  <app-bank-account-item
  [attr.data-bank-id]="bankAccount._id"
  [entity]="bankAccount"
  (onDelete)="deleteHandler(bankAccount)"
  [isDisabled]="disableButton">
  </app-bank-account-item>
</ng-container>

<div class="button-container">
  <fs-ui-button class="intercom-button-add" routerLink="create"  [label]="'Agregar Cuenta'"></fs-ui-button>
</div>

