import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { UsersActions } from '../../../state/users.action-types';
import { UsersSelectors } from '../../../state/users.selector-types';

@Component({
  selector: 'app-change-password-template',
  templateUrl: './change-password-template.component.html',
  styleUrls: ['./change-password-template.component.scss']
})
export class ChangePasswordTemplateComponent implements OnInit {

  constructor(
    private overlayService: OverlayService,
    private windowScrollService: WindowScrollService
  ) { }

  ngOnInit(): void {
  }

  changePassword(passwordSet) {
    this.overlayService.startConfirmation(
      passwordSet,
      null, //details view
      'resultOnly',
      'editOwn',
      null, //disclaimer message
      UsersActions.SavingProfile,
      '/profile-settings',
      UsersSelectors.savingProfileSuccessState,
      null,
      this.sendEventPageView.bind(this)
    )
  }

  sendEventPageView(){
    this.windowScrollService.sendNewEvent('Profile Settings', `Password Change Step 2`)
  }
}
