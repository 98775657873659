<form [formGroup]="form">
  <div class="container">
    <div class="title">Confirma tu cuenta bancaria y los grupos de facturas a crear</div>
    <div style="display: flex; flex-direction: row;gap:12px;">
      <div class="sub-title">{{processType === 'factoring' ? 'Selecciona la cuenta donde deseas recibir el dinero' :
        'Selecciona la cuenta para la devolución de garantía'}}</div>
      <div class="add-button" (click)="addBankAccount()">
        <span>Nueva cuenta bancaria</span>
        <img src="../../../../../../assets/icons/add-plus.svg" alt="">
      </div>
    </div>
    <div style="display: flex; flex-direction: row;gap:32px;">
      <app-select *ngIf="availableCurrency.includes('pen')" style="min-width: 400px;" [hasTooltip]="true"
        formControlName="bankAccountPEN" [options]="penBankAccounts" valueField="_id" textField="bankSummary"
        label="Cuenta bancaria en soles"></app-select>
      <app-select *ngIf="availableCurrency.includes('usd')" style="min-width: 400px;" [hasTooltip]="true"
        formControlName="bankAccountUSD" [options]="usdBankAccounts" valueField="_id" textField="bankSummary"
        label="Cuenta bancaria en dólares"></app-select>
    </div>
    <div>
      <div class="sub-title">
        Grupos de facturas a crear:
      </div>
      <div class="text" style="margin-top: 4px;">
        Agrupamos las facturas por: Empresa, moneda, fecha de pago y tipo de proceso (Proceso SUNAT o CAVALI)
      </div>
    </div>
    <div>
      <lib-invoice-upload-group [invoices]="invoices" [processType]="processType"></lib-invoice-upload-group>
    </div>
  </div>
</form>