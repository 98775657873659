import { Component, Input, OnInit } from '@angular/core';
import { RiskService } from '../../../services/risk.service';

@Component({
  selector: 'app-delete-doc-confirmation',
  templateUrl: './delete-doc-confirmation.component.html',
  styleUrls: ['./delete-doc-confirmation.component.scss']
})
export class DeleteDocConfirmationComponent implements OnInit {

  @Input() entity;
  
  constructor(
    private riskService: RiskService
  ) { }

  ngOnInit(): void {
  }

  getMimeType(file) {
    if(file){
      let extension = this.riskService.getMimeTypes(file)
      return extension
    }
  }

}
