import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'pay-client-advance-dialog',
  templateUrl: './pay-client-advance-dialog.component.html',
  styleUrls: ['./pay-client-advance-dialog.component.scss']
})
export class PayClientAdvanceDialogComponent implements OnInit {
  form;

  today = new Date();

  constructor(
    public dialogRef: MatDialogRef<PayClientAdvanceDialogComponent>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      advancePaymentDate: [moment().format("YYYY-MM-DD"), [Validators.required]],
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.dialogRef.close(this.form.value)
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
