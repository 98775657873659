export function getFormattedDate(nativeDate) {
  const date = new Date(nativeDate);
  let day;
  if (date.getUTCDate() < 10) {
    day = '0' + date.getUTCDate();
  } else {
    day = date.getUTCDate();
  }

  const month = getFormattedMonth(date.getUTCMonth());
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

export function getCalendarFormatDate(nativeDate) {
  const date = new Date(nativeDate);
  let day;
  day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export function getFormattedDateinInvoice(nativeDate) {
  const date = new Date(nativeDate);

  let day;
  if (date.getUTCDate() < 10) {
    day = '0' + date.getUTCDate();
  } else {
    day = date.getUTCDate();
  }

  const month = getFormattedMonth(date.getUTCMonth());
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

export function getFormattedMonth(monthId) {
  switch (monthId) {
    case 0:
      return 'ENE';
    case 1:
      return 'FEB';
    case 2:
      return 'MAR';
    case 3:
      return 'ABR';
    case 4:
      return 'MAY';
    case 5:
      return 'JUN';
    case 6:
      return 'JUL';
    case 7:
      return 'AGO';
    case 8:
      return 'SET';
    case 9:
      return 'OCT';
    case 10:
      return 'NOV';
    case 11:
      return 'DIC';
  }
}

export function getDiffDays() {
  let date = new Date();
  let today = date;
  let nextMonthDate = nextMonth();

  let Difference_In_Time = nextMonthDate.getTime() - today.getTime();
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Math.trunc(Difference_In_Days);
}

export function nextMonth() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
}
