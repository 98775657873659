<div class="header">
    <h1>¿Qué tipo de cuenta necesitas?</h1>
</div>
<p class="description">Selecciona lo que más se adecúa a ti:</p>

<div class="container client" (click)="setUser('client')">
    <div class="container-card">
        <img src="../../../../../../assets/icons/business-white24px.svg" alt="">
        <p class="title">Cuenta Empresa</p>
        <p>Ideal si quieres adelantar tus facturas o gestionar el pago de tus proveedores.</p>
    </div>
</div>

<div class="container investor" (click)="setUser('investor')">
    <div class="container-card">
        <img src="../../../../../../assets/icons/savings-white.svg" alt="">
        <p class="title">Cuenta Inversionista</p>
        <p>Rentabiliza tu capital invirtiendo en facturas seguras.</p>
    </div>
</div>

<div class="actionButtons center-dp">
    <fs-ui-button (click)="backward()" [label]="'Atrás'" [type]="'tertiary'"></fs-ui-button>
</div>