import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bank-accounts-page',
  template: `
  <div class="intercom-container-bk">
    
    <router-outlet></router-outlet>
  </div>`
})
export class BankAccountsPage implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
