<div class="box-text">
  <p class="title">
    {{ !isConfirming ? 'Agrega contactos de tu cliente' : 'Completa información de tu proveedor' }}
  </p>
  <p class="subtitle">
    {{ !isConfirming ? 'Mientras más contactos tengamos, más rápido será el desembolso.' : 'Agrega las cuentas
    bancarias de tus proveedores para transferir el dinero y sus contactos para informarles sobre la operación.'}}
  </p>
</div>
<div class="cards" style="margin-top: 26px;">
  <div *ngFor="let company of companies" class="box-container">
    <div class="d-flex flex-justify-between flex-items-center">
      <div class="company-info">
        <div class="u-mb-1">
          <lib-text weight="medium" size="s" [color]="'blue'" [content]="company.name"></lib-text>
        </div>
        <div>
          <lib-text size="xs" [color]="'dark-gray'" [content]="'RUC: ' + (company.ruc)"></lib-text>
        </div>
      </div>
    </div>
    <div *ngIf="isConfirming" class="banks-container d-flex" style="margin-top: 24px;">
      <lib-text weight="medium" size="s" [color]="'black'" [content]="'Cuentas bancarias'"></lib-text>
      <div (click)="addBankAccount(company)" class="link" style="margin-left: 12px;">
        <span>Nueva cuenta bancaria</span>
        <img src="../../../../../../assets/icons/add-plus.svg" alt="" />
      </div>
    </div>
    <div *ngIf="isConfirming" style="display: flex; flex-direction: row; gap: 32px;margin-top: 12px;">
      <mat-form-field *ngIf="company.availableCurrency.includes('pen')" floatLabel="auto" style="min-width: 400px;">
        <mat-label>Cuenta bancaria en soles</mat-label>
        <mat-select [(value)]="company.supplierBankAccountPEN" (selectionChange)="checkValidity()">
          <mat-option *ngFor="let option of company.penBankAccounts" [value]="option._id"
            [matTooltip]="option.bankSummary">{{ option.bankSummary }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="company.availableCurrency.includes('usd')" floatLabel="auto" style="min-width: 400px;">
        <mat-label>Cuenta bancaria en dólares</mat-label>
        <mat-select [(value)]="company.supplierBankAccountUSD" (selectionChange)="checkValidity()">
          <mat-option *ngFor="let option of company.usdBankAccounts" [value]="option._id"
            [matTooltip]="option.bankSummary">{{ option.bankSummary }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="banks-container d-flex" style="margin-top: 16px; margin-bottom: 16px">
      <lib-text weight="medium" size="s" [color]="'black'" [content]="'Contactos'"></lib-text>
      <div class="link" style="margin-left: 16px;" (click)="addContact(company)">
        <span>Añadir nuevo</span>
        <img src="../../../../../../assets/icons/add-plus.svg" alt="" />
      </div>
    </div>
    <div class="cards-row" *ngIf="company?.contacts?.length;else noContactsError" style="margin-top: 16px;">
      <lib-contact-item *ngFor="let contact of company.contacts" [name]="contact.name" [jobTitle]="contact.jobTitle"
        [email]="contact.email" [phone]="contact.phone">
      </lib-contact-item>
    </div>
    <ng-template #noContactsError>
      <div class="subtitle-zero-state">
        Se requiere mínimo 1 contacto para continuar
      </div>
    </ng-template>
  </div>
  <div class="box-container">
    <div class="subtitle">
      Para editar o eliminar los contactos.
    </div>
    <div style="margin-top: 4px;" class="link" (click)="openIntercom()">
      Escríbenos al chat
    </div>
  </div>
</div>