import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/features/users/services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Overlay } from '@angular/cdk/overlay';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  jwtHelper: JwtHelperService;
  decodedToken;
  tokenExpired;
  token;
  errorMessage;

  showForm = true;
  success; // savingSucceeded lastErrorMessage
  resultConfig; //onSuccess.title onFailure.title

  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private spinner: NgxSpinnerService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      try {

        this.token = params.token;
        this.decodedToken = this.jwtHelper.decodeToken(params.token);
        console.log(this.decodedToken);
        if(this.jwtHelper.isTokenExpired(params.token)) throw('Link expired');


      } catch (ex) {
        console.error(ex);
        this.showForm = false;
        this.success = {
          savingSucceeded: false,
          lastErrorMessage: 'PASSWORD_RESET.TOKEN_NOT_FOUND_OR_ALREADY_USED'
        }
      }
    });
  }

  passwordChanged(password) {
    this.spinner.show();

    console.log(password)

    this.userService.resetUserPassword(this.token, password, this.decodedToken._id).subscribe(
      res => {
        this.success = {
          savingSucceeded: true
        }
        this.resultConfig = {
          onSuccess: {
            title: 'La contraseña se ha restablecido correctamente.'
          }
        }

        this.spinner.hide();
        this.showForm = false;
      },
      err => {
        if(err.error === "Unauthorized") {
          this.errorMessage = 'PASSWORD_RESET.TOKEN_NOT_FOUND_OR_ALREADY_USED';
        } else {
          this.errorMessage = err.error;
        }

        this.success = {
          savingSucceeded: false,
          lastErrorMessage: this.errorMessage
        }

        this.spinner.hide();
        this.showForm = false;
      }
    );
  }

  setFailure() {
    this.tokenExpired = true;
  }
  backToAuth() {
    if(this.success.savingSucceeded) {
      this.router.navigateByUrl('/');
    } else {
      this.router.navigateByUrl('/auth/forgot-password');
    }
  }

}
