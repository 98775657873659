import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { bankAccountById } from 'src/app/features/bank-accounts/state/bank-accounts.selectors';
import { take } from 'rxjs/operators';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { selectUserById } from 'src/app/features/users/state/users.selectors';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { UsersService } from 'src/app/features/users/services/users.service';

@Component({
  selector: 'app-financial-transaction-invest-item',
  templateUrl: './financial-transaction-invest-item.component.html',
  styleUrls: ['./financial-transaction-invest-item.component.scss'],
})
export class FinancialTransactionInvestItemComponent implements OnInit {
  @Input() entity;
  @Input() role;
  @Input() noControls = false;
  @Output() onApprove = new EventEmitter();
  @Output() onReject = new EventEmitter();
  @Output() onShowAttachement = new EventEmitter();
  @Output() onFilterByUser = new EventEmitter();
  allowedRoles = [Roles.ADMIN, Roles.SUPER_ADMIN];
  transaction;
  investorName;
  loggedInUserRole;

  invoiceCodes;
  investor: any;
  newAmount;
  percentageInvested;

  constructor(
    private store: Store<AppState>,
    private userService: UsersService
  ) { }

  ngOnInit() {
    this.store
      .select(UsersSelectors.profile())
      .pipe(take(1))
      .subscribe((loggedUser) => {
        if (loggedUser) {
          this.loggedInUserRole = loggedUser.role;
        }
      });

    if (this.entity.type === 'deposit' || this.entity.type === 'withdraw') {
      if (this.entity.bankAccount.hasOwnProperty('_id')) {
        this.transaction = this.entity;
      } else if (this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN) {
        this.transaction = this.entity;
      } else {
        this.store
          .select(bankAccountById(this.entity.bankAccount))
          .pipe(take(1))
          .subscribe((bankAccount) => {
            if (bankAccount) {
              this.transaction = { ...this.entity, bankAccount: bankAccount };
            }
          });
      }
    } else if (this.entity.type === 'investment') {
      if (this.entity.invoice.hasOwnProperty('_id')) {
        this.transaction = this.entity;
      } else if (this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN) {
        this.transaction = this.entity;
      } else {
        this.store
          .select(InvoiceSelectors.byId(this.entity.invoice))
          .pipe(take(1))
          .subscribe((invoice) => {
            if (invoice) {
              this.transaction = { ...this.entity, invoice: invoice };
            }
          });
      }
    } else {
      this.transaction = this.entity;
    }

    this.invoiceCodes = this.entity.invoiceObject.physicalInvoices
      .map((invoice: any) => invoice.code)
      .toString();

    this.userService
      .getUserById(this.entity.user)
      .subscribe((investor: any) => {
        this.investor = investor;
      });

    this.newAmount = this.round(
      this.entity.invoiceObject.availableBalanceAmount - this.entity.amount
    );

    this.percentageInvested = this.round(
      (1 -
        ((1 -
          (100 - this.entity.invoiceObject.availableBalancePercentage) / 100) *
          (this.entity.invoiceObject.availableBalanceAmount -
            this.entity.amount)) /
        this.entity.invoiceObject.availableBalanceAmount) *
      100
    );
  }

  showAttachement() {
    this.onShowAttachement.emit(this.entity._id);
  }
  approve() {
    this.onApprove.emit(this.entity);
  }
  reject() {
    this.onReject.emit(this.entity);
  }
  filter(transactionUser, transactionType?) {
    this.onFilterByUser.emit({ transactionUser, transactionType });
  }

  round(num: number) {
    return Math.round(num * 100) / 100;
  }
}
