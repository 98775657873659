<p *ngIf="title" 
    [ngClass]="setTitleClasses()"
>{{ title }}</p>

<app-solid-label *ngIf="displayStyle === 'badge'"
    width="90" background="dark-gray" [content]="date | formatDate">
</app-solid-label>
<span *ngIf="displayStyle === 'plain'"
    [ngClass]="setPlainDateClasses()">{{ date | formatDate }}
</span>
