import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-physical-invoice-table',
  templateUrl: './physical-invoice-table.organism.html',
  styleUrls: ['./physical-invoice-table.organism.scss'],
})
export class PhysicalInvoiceTableOrganism implements OnInit {
  @Input() entity;
  @Input() color = 'black';
  needsConfirmation = false;
  investmentError =
    'No se pueden desagrupar las facturas porque el monto invertido es mayor que el monto a adelantar de las facturas no seleccionadas.';
  checked = false;
  hasError = false;
  displayErrorInvestment = false;
  constructor() {}

  ngOnInit(): void {
    if (this.entity.investments) {
      this.validateInvestmentAmount();
    }
    if (this.entity.hasConfirmation) {
      this.needsConfirmation =
        !this.entity.isSunatProcess &&
        this.entity.physicalInvoices.some(
          (i: any) => i.cavaliPayerResponse == 'express compliance'
        );
    }
  }

  validateInvestmentAmount() {
    const physicalInvoicesRemaining =
      this.entity.invoice.physicalInvoices.filter((inv): any => {
        if (!this.entity.physicalInvoices.includes(inv)) return inv;
      });
    const totalAmountRemaining = physicalInvoicesRemaining.reduce(
      (total, inv) => Number(total) + Number(inv.advanceAmount),
      0
    );

    const totalInvestment = this.entity.investments.reduce(
      (total, inv) => Number(total) + Number(inv.amount),
      0
    );

    if (Number(totalInvestment) > Number(totalAmountRemaining)) {
      this.displayErrorInvestment = true;
    } else {
      this.displayErrorInvestment = false;
    }
  }
}
