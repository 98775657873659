export const environment = {
  production: false,
  deployable: true,
  name: 'staging',
  socketEndpoint: 'https://api.staging.finsmart.pe/',
  endpoint: 'https://api.staging.finsmart.pe/api/v1',
  clientUrl: 'https://staging.finsmart.pe',
  emailConfirmationUri: '/signup/confirm-email',
  passwordResetUri: 'auth/reset-password',
  googleTagManagerKey: 'GTM-5D9KGQJ',
  mixpanelKey: '9bc6b7c1c230aa6c0cd40b0bbf598849',
  deploymentDate: '2024-07-08T00:00:00Z',
};
