import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store, select } from '@ngrx/store';
import { take, map, tap, filter, first, finalize } from 'rxjs/operators';
import { bankAccountsLoaded } from '../../bank-accounts/state/bank-accounts.selectors';
import { UsersSelectors } from '../../users/state/users.selector-types';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { InvoiceSelectors } from '../state/invoices.selector-types';
import { InvoicesActions } from '../state/invoices.action-types';
import { BankAccountsActions } from '../../bank-accounts/state/bank-accounts.action-types';
import { financialTransactionsLoaded } from '../../financial-transactions/state/financial-transactions.selectors';
import { FinancialTransactionActions } from '../../financial-transactions/state/financial-transactions.action-types';

@Injectable({
  providedIn: 'root'
})
export class InvoicesResolver implements Resolve<boolean> {
  bankAccountsLoading = false;
  financialTransactionsLoading = false;
  invoicesLoading = false;
  
  constructor(private store: Store<AppState>) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> {
      let userRole;
        
      this.store.select(UsersSelectors.profileProperty('role')).pipe(take(1)).subscribe(loggedInUserRole => {
          userRole = loggedInUserRole;
      });

      if(userRole === Roles.ADMIN || userRole === Roles.SUPER_ADMIN) {
        return this.resolveForAdmin();
      } else if(userRole === Roles.CLIENT) {
        return this.resolveForClient();
      } else {
        return of(false);
      }
  }

  resolveForAdmin() {
    return of(true);
    
    return this.store
    .pipe(
        select(InvoiceSelectors.loaded),
        tap(_loadedInvoiceEntites => {
            if(!this.invoicesLoading && !_loadedInvoiceEntites) {
                this.invoicesLoading = true;
                this.store.dispatch(InvoicesActions.LoadAll());
            }
        }),
        filter(_loadedInvoiceEntites => _loadedInvoiceEntites),
        first(),
        finalize(() => this.invoicesLoading = false)
    );

  }
  
  resolveForClient() {
    return combineLatest(
      this.store.select(bankAccountsLoaded), 
      this.store.select(InvoiceSelectors.loaded)
    )
    .pipe(tap(
        ([_loadedBankAccountEntities, _loadedInvoiceEntites]) => {
            if(!_loadedBankAccountEntities && !this.bankAccountsLoading) { 
                this.bankAccountsLoading = true;
                this.store.dispatch(BankAccountsActions.LoadAll());
                }
            if(!_loadedInvoiceEntites && !this.invoicesLoading) { 
                this.invoicesLoading = true;
                this.store.dispatch(InvoicesActions.LoadAll());
            }
        }),
        filter(([_loadedBankAccountEntities, _loadedInvoiceEntites]) => _loadedBankAccountEntities && _loadedInvoiceEntites),
        first(),
        finalize(() => this.bankAccountsLoading = this.invoicesLoading = false)
    );
  }
}
