import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-payer-description',
  templateUrl: './payer-description.component.html',
  styleUrls: ['./payer-description.component.scss']
})
export class PayerDescriptionComponent implements OnInit {

  @Input() title;
  @Input() payer;
  @Input() currency;
  @Input() description;

  constructor() { }

  ngOnInit(): void {
  }

}
