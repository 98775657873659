import { createSelector } from '@ngrx/store';

export const bankAccountsLoaded = createSelector(
  (state) => state['bankAccounts'],
  (state) => state.loaded
);
export const bankAccounts = createSelector(
  (state) => state['bankAccounts'],
  (state) => state.bankAccounts
);
export const bankAccountById = (id) =>
  createSelector(bankAccounts, (items) => {
    return items.filter((bankAccount) => bankAccount._id === id)[0];
  });
export const bankAccountByUserId = (userId) =>
  createSelector(bankAccounts, (items) => {
    if (items) {
      return items.filter((bankAccount) => bankAccount.user === userId);
    } else {
      return null;
    }
  });
export const savingSuccessStateById = (savingEntityId) =>
  createSelector(
    (state) => {
      return {
        saving: state['bankAccounts']['saving'],
        savingEntityId: state['bankAccounts']['savingEntityId'],
        savingSucceeded: state['bankAccounts']['savingSucceeded'],
        lastErrorMessage: state['bankAccounts']['lastErrorMessage'],
        lastErrorStatus: state['bankAccounts']['lastErrorStatus'],
      };
    },
    (savingState) => {
      if (
        savingEntityId === savingState.savingEntityId &&
        savingState.saving === false
      ) {
        return {
          savingSucceeded: savingState.savingSucceeded,
          lastErrorMessage: savingState.lastErrorMessage,
          lastErrorStatus: savingState.lastErrorStatus,
        };
      }
    }
  );
