import { createReducer, on } from '@ngrx/store';
import { updateStoreState } from 'src/app/shared/util/ngrx.util';
import { TermsAndConditionsActions } from './terms-and-conditions.action-types';

export interface State {
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  savingSucceeded: boolean;
  savingMode: 'create' | 'edit' | 'delete';
  savingEntityId: string;
  entities: any | null;
  lastErrorMessage: any | null;
  lastErrorStatus: any | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  saving: undefined,
  savingSucceeded: undefined,
  savingMode: undefined,
  savingEntityId: undefined,
  entities: undefined,
  lastErrorMessage: undefined,
  lastErrorStatus: undefined
};

export const _reducer = createReducer(
    initialState,
    on(TermsAndConditionsActions.LoadAll, (state, action) => {
        return {
          ...state,
          loading: true
        }
    }),
    on(TermsAndConditionsActions.LoadedAllSuccess, (state, action) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          entities: action.entities
        }
    }),
    on(TermsAndConditionsActions.Saving, (state, action) => {
        return {
          ...state,
          saving: true,
          savingSucceeded: undefined,
          savingMode: action.mode,
          savingEntityId: action.entity.id
        }
    }),
    on(TermsAndConditionsActions.SaveSucceeded, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: true,
          savingMode: undefined,
          entities: updateStoreState(state.entities, action.result, state.savingMode)
        }
    }),
    on(TermsAndConditionsActions.SaveFailed, (state, action) => {
      console.log(action.error.error)
      console.log(action.error.status)
        return {
          ...state,
          saving: false,
          savingSucceeded: false,
          savingMode: undefined,
          lastErrorMessage: action.error.error,
          lastErrorStatus: action.error.status
        }
    }),
    on(TermsAndConditionsActions.Reset, (state, action) => {
      return initialState;
    })
);

export function reducer(state, action) {
  return _reducer(state, action)
}