<div class="container" *ngIf="entity">
    <table>
        <tr [class]="color">
            <th>Código</th>
            <th>Importe total</th>
            <th>Detracción/retención</th>
            <th>Monto neto</th>
            <ng-container *allowedRoles="['admin']">
                <th>CAVALI</th>
            </ng-container>
        </tr>
        <tr *ngFor="let invoice of entity.physicalInvoices">
            <td>{{ invoice.code }}</td>
            <td>{{ invoice.totalAmount | numberSpacing}}</td>
            <td>{{ invoice.retentionAmount | numberSpacing}}</td>
            <td>{{ invoice.netAmount | numberSpacing}}</td>
            <ng-container *allowedRoles="['admin']">
                <td>
                    <ng-container *ngIf="!invoice.cavaliStatus || invoice.cavaliStatus === 'withdrawn'">
                        <span>---</span>
                    </ng-container>
                    <ng-container *ngIf="invoice.cavaliStatus">
                        <div class="container-status">
                            <app-statuses-label [payerResponse]="invoice.cavaliPayerResponse"
                                [status]="invoice.cavaliStatus"></app-statuses-label>
                        </div>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
    </table>

    <ng-container *ngIf="entity.hasConfirmation && needsConfirmation">
        <div class="checkbox" style="margin-top: 32px;">
            <mat-checkbox [(ngModel)]="checked" color="primary" [ngClass]="{'error': hasError}">
            </mat-checkbox>
            <div class="checkbox-label">Ya he coordinado con el ejecutivo para retirar la factura de CAVALI y perder la
                conformidad EXPRESA.</div>
        </div>
    </ng-container>
</div>