import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UsersActions } from '../../../state/users.action-types';
import { UsersSelectors } from '../../../state/users.selector-types';
import { Validators, FormBuilder, Form } from '@angular/forms';
import { NumbersOnlyValidator } from 'src/app/shared/validators/numbers-only.validators';
import { RequiredValidator } from 'src/app/shared/validators/required.validator';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LettersOnlyValidator } from 'src/app/shared/validators/letters-only.validators';
import { UniqueUserByEmailAdminValidator } from 'src/app/shared/validators/unique-user-by-email-admin.validator';
import { UniqueUserByRuclAdminValidator } from 'src/app/shared/validators/unique-user-by-ruc-admin.validator';
import { RucExactLengthValidator } from 'src/app/shared/validators/ruc-exact-length.validator';
import { Departments } from 'src/app/shared/enums/Departments.enum';
import { EmailValidator } from '../../../../../../app/shared/validators/email.validators';
import { UsersService } from '../../../services/users.service';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-edit-user-template',
  templateUrl: './edit-user-template.component.html',
  styleUrls: ['./edit-user-template.component.scss']
})
export class EditUserTemplateComponent implements OnInit {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  form;
  selectedUser;
  investorId;
  channelsOptions
  departmentsOptions = Object.keys(Departments);
  
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private overlayService: OverlayService,
    private route: ActivatedRoute,
    private uniqueUserByEmailValidator: UniqueUserByEmailAdminValidator,
    private uniqueUserByRuclValidator: UniqueUserByRuclAdminValidator,
    private service: UsersService,
    private formService : FormService
  ) { }

  ngOnInit() {
    this.channelsOptions = this.formService.getChannels()
    this.route.params.subscribe(params => {
      const selectedUserId = params.userId;

      this.service.byId(selectedUserId).subscribe(_user => {
          this.selectedUser = _user;
          console.log('User info', this.selectedUser)
          if(this.selectedUser.role === Roles.INVESTOR) {
            this.investorId = {
              idType: this.selectedUser.idType,
              idNumber: this.selectedUser.idNumber,
              idFrontPicture: this.selectedUser.idFrontPicture,
              idBackPicture: this.selectedUser.idBackPicture
            }
            this.form = this.formBuilder.group({
              names: ['', [RequiredValidator, LettersOnlyValidator,  Validators.minLength(2), Validators.maxLength(50)]],
              familyNames: ['', [RequiredValidator, LettersOnlyValidator,  Validators.minLength(2), Validators.maxLength(50)]],
              email: ['', [RequiredValidator, EmailValidator], [this.uniqueUserByEmailValidator.isUserEmailUnique(this.selectedUser.email)]],
              phone: ['', [RequiredValidator]],
              channel: ['', [RequiredValidator]],
              identification: [''],      
            });
          } else if(this.selectedUser.role === Roles.CLIENT) {
            this.form = this.formBuilder.group({
              names: ['', [RequiredValidator, LettersOnlyValidator,  Validators.minLength(2), Validators.maxLength(50)]],
              familyNames: ['', [RequiredValidator, LettersOnlyValidator,  Validators.minLength(2), Validators.maxLength(50)]],
              email: ['', [RequiredValidator, EmailValidator], [this.uniqueUserByEmailValidator.isUserEmailUnique(this.selectedUser.email)]],
              phone: ['', [RequiredValidator]],
              channel: ['', [RequiredValidator]],
      
              companyName: ['', [RequiredValidator]],
              companyRuc: ['', [RequiredValidator, NumbersOnlyValidator, RucExactLengthValidator(11)], [this.uniqueUserByRuclValidator.isUserRucUnique(this.selectedUser.companyRuc)]],
              companyAddress: ['', [RequiredValidator]],
              companyDepartment: ['', [RequiredValidator]],
            });
          }
      });
    });
  }

  submit() {
    this.form.markAllAsTouched();
    /*
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].updateValueAndValidity();
    });*/

    if(this.form.valid) {
      let flattenFormValue = this.form.value;

      if(this.selectedUser.role === Roles.INVESTOR) {
        let identificationData = this.form.value.identification;
        delete this.form.value.identification;
    
        flattenFormValue.idType = identificationData.idType;
        flattenFormValue.idNumber = identificationData.idNumber;

        if(identificationData.idType !== 'ruc'){
          flattenFormValue.idFrontPicture = identificationData.idFrontPicture;
          if(identificationData.idBackPicture) {
            flattenFormValue.idBackPicture = identificationData.idBackPicture;
          } 
        }
      }
      
      this.overlayService.startConfirmation(
        {_id: this.selectedUser._id, role: this.selectedUser.role, ...flattenFormValue},
        null, //details view
        'resultOnly',
        'edit',
        null, //disclaimer message
        UsersActions.Saving,
        `users-accounts/${this.selectedUser._id}`,
        UsersSelectors.savingSuccessStateById
      )
    }


  }

  ngOnDestroy() {
    console.log("DESTORY")
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
