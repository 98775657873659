import { Injectable } from '@angular/core';
import { RESTService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class RucService {
  constructor(private rest: RESTService) {}

  getSunatRUC(ruc, type = 'debtor') {
    return this.rest.get(`/sunat/${ruc}?type=${type}`);
  }

  getRUC(ruc) {
    return this.rest.get(`/ruc/${ruc}`);
  }
}
