<div class="row mb-10">
    <div>
        <p *ngIf="type !== 'opportunity'" class="tiny tiny-title">Monto invertido</p>
        <p *ngIf="type === 'opportunity' && (entity?.onSale || entity?.onSale === undefined)" class="tiny tiny-title">Monto disponible</p>

        <app-amount *ngIf="!!entity?.onSale || entity?.onSale === undefined || !entity?.hasOwnProperty('onSale')" [amount]="amount" [animate]="animate"></app-amount>

        <p *ngIf="type === 'opportunity' && (!entity?.onSale && entity?.onSale !== undefined)" class="tiny tiny-title">Fecha de publicación</p>

        <span class="tiny value" *ngIf="type === 'opportunity' && (!entity?.onSale && entity?.onSale !== undefined)">{{getDate(entity) | capitalizeOnlyFirstLetter }}</span>
      
       
    </div>
    <div>
        <p *ngIf="type === 'opportunity'" class="tiny tiny-title">Monto total</p>
        <p *ngIf="type == 'invest' &&  status === 'approved'" class="tiny tiny-title">Retorno esperado</p>
        <p *ngIf="type == 'invoice' ||  type == 'invest' &&  status === 'closed'" class="tiny tiny-title">Retorno esperado</p>
       
        <p *ngIf="type === 'actual'" class="tiny tiny-title">Retorno bruto esperado</p>
        <p *ngIf="type === 'historic'" class="tiny tiny-title">Retorno bruto</p>

        <app-roi *ngIf="type === 'opportunity'" [mode]="'balance'" [amount]="totalAmount" [currency]="currency"></app-roi>
        <app-roi *ngIf="type != 'opportunity'"  [amount]="roi" [currency]="currency"></app-roi>
    </div>

</div>
<ng-container *ngIf="(!entity?.hasOwnProperty('onSale') || (entity?.hasOwnProperty('onSale') && entity?.onSale === true))">
    <app-progress-bar *ngIf="type != 'historic' && toBeCollectedIn != 'En mora'" [progress]="percentage"></app-progress-bar>
    <app-progress-bar *ngIf="type != 'historic' && toBeCollectedIn === 'En mora'" [progress]="'100'"></app-progress-bar>
    <app-progress-bar *ngIf="type === 'historic'" [progress]="'100'"></app-progress-bar>    
</ng-container>

<div class="row align-right" *ngIf="type === 'actual'">
    <div *ngIf="toBeCollectedIn != 'En mora'">
        <p class="tiny tiny-title bold" *ngIf="toBeCollectedIn === 1"><span>Queda {{toBeCollectedIn}} día</span></p>
        <p class="tiny tiny-title bold" *ngIf="toBeCollectedIn === 0 || toBeCollectedIn > 1"><span>Quedan {{toBeCollectedIn}} días</span></p>
    </div>
    <div *ngIf="toBeCollectedIn === 'En mora'">
        <p class="tiny tiny-title bold" *ngIf="moraDays > 0 && moraDays < 9">
            <span *ngIf='moraDays === 1'>{{moraDays}} día de mora |<span class="mora-blue"> Período de gracia (1 - 8 días)</span></span>
            <span *ngIf='moraDays > 1'>{{moraDays}} días de mora | <span class="mora-blue">Período de gracia (1 - 8 días)</span></span>
        </p>
        
        <p class="tiny tiny-title bold" *ngIf="moraDays > 8 && moraDays < 31">
            <span>{{moraDays}} días de mora | <span class="mora-yellow">Mora entre 9 y 30 días</span></span>
        </p>
        <p class="tiny tiny-title bold" *ngIf="moraDays > 30">
            <span>{{moraDays}} días de mora | <span class="mora-red"> Mora mayor a 30 días</span></span>
        </p>
    </div>
</div>

<div class="row  align-right" *ngIf="type === 'historic'">
    <div>
        <p class="tiny tiny-title bold" *ngIf="dates === 0 || dates > 1 "><span>Cobrada con {{dates}} días de mora</span></p>
        <p class="tiny tiny-title bold"  *ngIf="dates === 1"><span>Cobrada con {{dates}} día de mora</span></p>
    </div>
</div>