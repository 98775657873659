import { MixpanelService } from './../../../../../shared/services/mixpanel.service';
import { GAService } from './../../../../../shared/services/ga.service';
import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { bankAccountById, savingSuccessStateById, bankAccounts } from '../../../state/bank-accounts.selectors';
import { BankAccountsActions } from '../../../state/bank-accounts.action-types';
import { Router, ActivatedRoute } from '@angular/router';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { BankAccountItemOrganism } from '../../molecules/bank-account-item/bank-account-item.organism';
import { take } from 'rxjs/operators';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { FormService } from 'src/app/shared/services/form.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';

@Component({
  selector: 'app-create-bank-accounts',
  templateUrl: './create-bank-accounts.template.html',
  styleUrls: ['./create-bank-accounts.template.scss']
})
export class CreateBankAccountsTemplate implements OnInit {
  pageTitle = "Cuentas bancarias";
  pageDescription = "Agrega las cuentas bancarias que quieres asociar a tu cuenta.";

  constructor(
    private overlayService: OverlayService,
    private store: Store<AppState>,
    private gtmService: GTMService,
    private gaService: GAService,
    private mixpanelService: MixpanelService,
    private formService: FormService,
    private windowScrollService: WindowScrollService
  ) { }

  ngOnInit() {
  }

  submitHandler(formValue) {
    this.sendEventStepOne()
    this.sendEventPageView(2)

    this.store.select(UsersSelectors.profileProperty('_id')).pipe(take(1)).subscribe(selectedUserId => {
      this.overlayService.startConfirmation(
        { ...formValue, user: selectedUserId },
        BankAccountItemOrganism,
        'details',
        'create',
        'Recuerda que la cuenta bancaria debe estar a tu nombre, si no será denegada. Revisa atentamente la información antes de confirmar, recuerda que eres responsable único de que la información brindada es la correcta.',
        BankAccountsActions.Saving,
        '/bank-accounts',
        savingSuccessStateById,
        null,
        this.trackAnalytics.bind(this)
      );
    });

  }

  async trackAnalytics(result: any) {
    if (result) {
      const role = await this.store
        .select(UsersSelectors.profileProperty('role'))
        .pipe(take(1))
        .toPromise();
   
      this.store.select(bankAccounts).pipe(take(1)).subscribe((banks) => {
        const bankAccount = banks[0];
        const eventName = 'new_bank_account';
        const eventData = {
          create_date: new Date().toISOString(),
          bank_account_type: "User account",
          currency: bankAccount.currency.toUpperCase(),
          bank_name: bankAccount.name,
          is_success: true,
          error_description: null
        };
        this.gaService.sendEvent(eventName, eventData);
        this.mixpanelService.newEvent(eventName, eventData)
        this.sendEventGTMLastStep();
        this.sendEventPageView(3)

      });
    }
  }

  sendEventPageView(step){
    this.windowScrollService.sendNewEvent('Bank Accounts', `New Step ${step}`)
  }

  sendEventStepOne(){
    let eventError = {
      event: '[Platform][Form][Input]',
      _inputName: "Clicked Agregar Cuenta In Step 1",
      _inputCategory: "New Bank Account / Step 1",
    }
    this.gtmService.newEvent(eventError);
  }

  sendEvent($form) {
    let eventError = {
      event: '[Platform][Form][Input][Error]',
      _inputName: "Clicked Agregar Cuenta In Step 1 Error",
      _inputCategory: "New Bank Account / Step 1",
      _inputError: this.formService.getFormValidationErrors($form),
    }
    this.gtmService.newEvent(eventError);
  }

  sendEventGTMLastStep() {
    let event = {
      event: '[Platform][Form][Input]',
      _inputName: "Landed On Step 3 Page",
      _inputCategory: "New Bank Account / Step 3",
    }
    this.gtmService.newEvent(event);
  }
}
