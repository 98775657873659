import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-title',
  template: `
  <h1 *ngIf="title">{{ title }}</h1>
  <p class="note" *ngIf="note">{{ note }}</p>
  <p *ngIf="description">{{ description }}</p>
  <div><a *ngIf="callToAction" class="call-to-action" [routerLink]="callToAction.link">{{ callToAction.text }} <span><mat-icon class="rightIcon">arrow_right</mat-icon></span><span class="link-label">{{ callToAction.linkLabel }}</span></a></div>`,
  styleUrls: ['./page-title.molecule.scss']
})
export class PageTitleMolecule implements OnInit {
  @Input() title;
  @Input() description;
  @Input() note;
  @Input() callToAction;

  constructor() { }

  ngOnInit() {
  }

}
