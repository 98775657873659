import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { take } from 'rxjs/operators';
import { bankAccounts, savingSuccessStateById } from '../../../../../features/bank-accounts/state/bank-accounts.selectors';
import { BankAccountsActions } from '../../../../bank-accounts/state/bank-accounts.action-types';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { TermsAndConditionsSelectors } from '../../../state/terms-and-conditions.selector-types';
import { DisplayFileComponent } from 'src/app/shared/UI/organisms/display-file/display-file.component';
import { TermsAndConditionsService } from '../../../services/terms-and-conditions.service';
import { TermsAndConditionsActions } from '../../../state/terms-and-conditions.action-types';

@Component({
  selector: 'app-list-terms-conditions',
  templateUrl: './list-terms-conditions.component.html',
  styleUrls: ['./list-terms-conditions.component.scss']
})
export class ListTermsConditionsComponent implements OnInit {
  pageTitle = 'Términos y condiciones'
  $termsAndConditions;

  constructor(
    private store: Store<AppState>, 
    private overlayService: OverlayService) { }

  ngOnInit(): void {
    this.$termsAndConditions = this.store.select(TermsAndConditionsSelectors.entities)
  }

  //Only for testing banck account actions to overlay delete
  deleteHandler(termsId) {
    
    this.overlayService.startConfirmation(
      { _id: termsId },
      null,
      'question',
      'delete',
      null,
      TermsAndConditionsActions.Saving,
      '/system-settings/terms-and-conditions',
      TermsAndConditionsSelectors.savingSuccessStateById
    )
  }

  showFile(file) {
    this.overlayService.showComponent(DisplayFileComponent, file)
  }
}
