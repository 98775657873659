<p class="subtitle-page">Se aplicará notas de crédito a las siguientes facturas:</p>
<!-- <div class="container-table">
    <app-invoice-descript-operation  [entity]="entity.mainInvoice"></app-invoice-descript-operation>
</div>
 -->

<ng-container *ngIf="entity">
    <h1>Grupo de facturas ({{entity.invoicesCreditNote.length}})</h1>
    <span *ngIf="entity.newPaymentDate" class="tiny">Nueva fecha de pago: {{entity.newPaymentDate | formatDate}}</span>
    <div class="container" *ngIf="entity">
        <table>
            <tr class="black">
                <th>Código</th>
                <th>Importe total</th>
                <th>Detracción/retención</th>
                <th>Monto neto</th>
                <th>CAVALI</th>
                <th>N. Crédito</th>
            </tr>
            <tr *ngFor="let invoice of entity.invoicesCreditNote">
                <td>{{ invoice.code }}</td>
                <td>{{ invoice.totalAmount | numberSpacing}}</td>
                <td>
                    <div style="display: flex; align-items: center;">
                        {{ invoice.retentionAmount | numberSpacing}}
                        <lib-tooltip *ngIf="invoice.originalRetentionAmount" width="160"
                            [content]="'Original ' + (invoice.originalRetentionAmount | numberSpacing)">
                            <img style="margin-left: 8px;" class='info-icon' />
                        </lib-tooltip>
                    </div>
                </td>
                <td>
                    <div style="display: flex; align-items: center;">
                        {{ invoice.netAmount | numberSpacing}}
                        <lib-tooltip *ngIf="invoice.originalNetAmount" width="160"
                            [content]="'Original ' + (invoice.originalNetAmount | numberSpacing)">
                            <img style="margin-left: 8px;" class='info-icon' />
                        </lib-tooltip>
                    </div>
                </td>
                <td>
                    <ng-container *ngIf="!invoice.cavaliStatus || invoice.cavaliStatus === 'withdrawn'">
                        <span>---</span>
                    </ng-container>
                    <ng-container *ngIf="invoice.cavaliStatus">
                        <div class="container-status">
                            <app-statuses-label [payerResponse]="invoice.cavaliPayerResponse"
                                [status]="invoice.cavaliStatus"></app-statuses-label>
                        </div>
                    </ng-container>
                </td>
                <td>{{invoice?.creditNote?.code}}</td>
            </tr>
        </table>
    </div>
</ng-container>

<span *ngIf="entity.errorMessages.length" class="tiny error-message">{{entity.errorMessages.join('\n')}}</span>