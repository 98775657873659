import { AbstractControl } from '@angular/forms';

export function PasswordCriteriaValidator(control: AbstractControl) {
  const pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[!,.?":{}|/\\'/\][<>a-zA-Z\d#?!@$~%+±`^&*_)=(-]{8,}$/g);
  const isValid = pattern.test(control.value);

  if (!isValid) {
    return { passwordCriteria: true };
  }
  return null;
}
//asd énfa32@_ñáéíóúüñ
//aasd adé2@/#_
