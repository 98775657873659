import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UsersActions } from '../../../../features/users/state/users.action-types';
import { AuthActions } from 'src/app/features/authentication/state/auth.action-types';
import { RequiredValidator } from 'src/app/shared/validators/required.validator';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { FormService } from 'src/app/shared/services/form.service';


@Component({
  selector: 'app-client-legal-information',
  templateUrl: './client-legal-information.component.html',
  styleUrls: ['./client-legal-information.component.scss']
})
export class ClientLegalInformationComponent implements OnInit {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  form: FormGroup;
  profile
  legalOptions = [
    { text: 'Si', value: 'true' }, 
    { text: 'No', value: 'false' }
  ];
  professionList = [
    { label: 'Gerente General', value: 'gerente general'},
    { label: 'Jefe/Gerente de Finanzas', value: 'jefe de finanzas'},
    { label: 'Jefe/Gerente de Contabilidad', value: 'jefe de contabilidad'},
    { label: 'Tesorero', value: 'tesorero'},
    { label: 'Contador', value: 'contador'},
    { label: 'Analista', value: 'analista'},
    { label: 'Asistente', value: 'asistente'},
    { label: 'Otro...', value: 'otro'},
  ]
  displayInput = false; 
  userId
  userFirstName$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    public dialogRefModal: MatDialogRef<ClientLegalInformationComponent>,
    private gtmService: GTMService,
    private formService: FormService
    ) { }

  ngOnInit(): void {
    this.store.select(UsersSelectors.profile()).pipe(
      (takeUntil(this.ngUnsubscribe))
    ).subscribe((profile) => {
      if(profile) {
        this.profile = profile;
      }
    });


    this.store
    .pipe(
      select(UsersSelectors.profileProperty('_id'))
    ).subscribe(user => {
      this.userId = user
    });

    this.userFirstName$ = this.store
    .pipe(
      select(UsersSelectors.profileProperty('names'))
    );

    this.form = this.formBuilder.group({
      legalRep: ['', [RequiredValidator]],
      profession: ['', [Validators.required]],
      legalComment: ['', []]
    });
  }

  changeProfession(value){
    if(value === 'otro'){
      this.displayInput = true;
      this.form.get('legalComment').setValidators([Validators.required,  Validators.minLength(2), Validators.maxLength(30)]);
      this.form.get('legalComment').updateValueAndValidity();
    }else{
      this.form.get('legalComment').setValidators([]);
      this.form.get('legalComment').updateValueAndValidity();
      this.displayInput = false;
    }
  }

  submit(){

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if(this.form.valid){
      let event = {
        event: '[Platform][Address][Verification]',
        _inputCategory: 'First Signup / Modal',
        _inputName: 'Clicked Guardar In Modal',
        _inputValue: `${this.userId} / Representante legal: ${this.form.get('legalRep').value} / Cargo: ${ this.form.get('profession').value} / ${this.form.get('legalComment').value}`
      };
      this.sendEvent(event)
      this.dialogRefModal.close(this.form.value);
    }else{
      let event = {
        event: '[Platform][Address][Verification][Error]',
        _inputCategory: 'First Signup / Modal',
        _inputName: 'Clicked Guardar In Modal Error',
        _inputValue: `${this.userId}`,
        _inputError: this.formService.getFormValidationErrors(this.form),
      };
      this.sendEvent(event)
    }
  }

 
  close(){
    this.sendEventClose()
    this.dialogRefModal.close('closed');
    this.incrementCounter()
  }

  sendEventClose(){
      let event = {
        event: '[Platform][Modal][Legal][Later]',
        _inputValue: this.userId
      };
      this.gtmService.newEvent(event)
  }
  incrementCounter(){
    let actualCounter = Number(this.profile.legalCounter) || 0

    const entity= {
      legalCounter: (actualCounter++).toString(),
    }
    this.store.dispatch(UsersActions.SavingProfile({entity: entity, mode: 'editOwn'}));
    this.store.dispatch(UsersActions.LoadProfile());
    this.store.dispatch(AuthActions.ShowPopUp());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  sendEvent(event){
    this.gtmService.newEvent(event)
  }

}
