import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { FinancialTransactionItemComponent } from '../../organisms/financial-transaction-item/financial-transaction-item.component';
import { FinancialTransactionActions } from '../../../state/financial-transactions.action-types';
import { savingSuccessStateById } from '../../../state/financial-transactions.selectors';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';

@Component({
  selector: 'app-create-financial-transaction-donate',
  templateUrl: './create-financial-transaction-donate.component.html',
  styleUrls: ['./create-financial-transaction-donate.component.scss']
})
export class CreateFinancialTransactionDonateComponent implements OnInit {
  selectedUserId;

  constructor(
    private overlayService: OverlayService,
    private windowScrollService: WindowScrollService
  ) { }

  ngOnInit(): void {
  }

  selectedUserChanged(userId) {
    if(userId) {
      this.selectedUserId = userId;
    }
  }

  submitHandler(formValue) {
    this.sendEventPageView(2)
    this.overlayService.startConfirmation(
      formValue,
      FinancialTransactionItemComponent,
      'details',
      'create',
      '',
      FinancialTransactionActions.Saving,
      '/financial-transactions',
      savingSuccessStateById,
      null,
      this.sendEventPage.bind(this)
    )
  }

  sendEventPage(){
    this.sendEventPageView(3)
  }

  sendEventPageView(title){
    this.windowScrollService.sendNewEvent('Financial Transactions', `Donate Step ${title}`)
  }

}
