import { AbstractControl, ValidatorFn } from '@angular/forms';

//Must find a better way to to resue our validators (the problem was the error message and length in error msg)
//If we improve and centralize implemntation better it will be easieer
export function RucExactLengthValidator(Length: Number): ValidatorFn {
    return (control: AbstractControl) => {
  
      const pattern = new RegExp(/^[0-9]{11,11}$/);
  
      const isPatternValid = pattern.test(control.value);
    
      if (!isPatternValid) {
        return { rucExactLength: true };
      }
      return null;
    }  
}
