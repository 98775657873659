import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.states';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';

@Component({
  selector: 'app-add-contact-confirmation',
  templateUrl: './add-contact-confirmation.component.html',
  styleUrls: ['./add-contact-confirmation.component.scss'],
})
export class AddContactConfirmationComponent implements OnInit {
  @Input() entity;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  profile;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.profile = '';
  }
}
