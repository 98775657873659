<app-page-title title="Modificar cuenta usuario"></app-page-title>

<form [formGroup]="form" *ngIf="selectedUser; else user_not_found">
    <div class="inputs">
        <app-input formControlName="names" [default]="selectedUser.names | capitalizeFirstLetters" label="Nombres"></app-input>
        <app-input formControlName="familyNames" [default]="selectedUser.familyNames | capitalizeFirstLetters" label="Apellidos"></app-input>
        <app-input formControlName="email" [default]="selectedUser.email" label="Correo electrónico"></app-input>
        <app-input formControlName="phone" [default]="selectedUser.phone" label="Teléfono celular"></app-input>

        <ng-container *ngIf="selectedUser.role === 'investor'">
            <app-identification [role]="selectedUser.role" formControlName="identification" [default]="investorId"></app-identification>
        </ng-container>
        
        <ng-container *ngIf="selectedUser.role === 'client'">
            <app-input formControlName="companyRuc" [default]="selectedUser.companyRuc" label="RUC"></app-input>
            <app-input formControlName="companyName" [default]="selectedUser.companyName | capitalizeFirstLetters" label="Razón social"></app-input>
            <app-input formControlName="companyAddress" [default]="selectedUser.companyAddress | capitalizeFirstLetters" label="Dirección"></app-input>
            <app-select formControlName="companyDepartment" autoComplete="true" [options]="departmentsOptions" [defaultOption]="selectedUser.companyDepartment" label="Departamento"></app-select>
        </ng-container>

        <app-select formControlName="channel" [defaultOption]="selectedUser.channel" [options]="channelsOptions" valueField="value" textField="label" label="Canales"></app-select>
    </div>
    <div class="button-container">
        <fs-ui-button [action]="'submit'" (click)="submit()"  [label]="'Guardar Cambios'"></fs-ui-button>
    </div>
</form>    

<div class="button-container" *ngIf="selectedUser">
    <fs-ui-button [routerLink]="'/users-accounts/' + selectedUser._id" [type]="'tertiary'"  [label]="'Regresar'"></fs-ui-button>
</div>
<ng-template #user_not_found>
    <h1>No pudimos encontrar esta cuenta de usuario!</h1>
</ng-template>