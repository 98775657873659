import { UniqueDocumentValidator } from './../../../../../shared/validators/unique-document.validator';
import {
  Component,
  EventEmitter,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
  ControlValueAccessor,
} from '@angular/forms';
import { IDLengthValidator } from '../../../../../shared/validators/id-length.validator';
import { RequiredValidator } from '../../../../../shared/validators/required.validator';
import { IDFormatValidator } from '../../../../../shared/validators/id-format.validator';
import { MustBeTrue } from 'src/app/shared/validators/must-be-true.validator';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-sigup-investor-form',
  templateUrl: './sigup-investor-form.component.html',
  styleUrls: ['./sigup-investor-form.component.scss'],
})
export class SigupInvestorFormComponent implements OnInit {
  @Output() previousStep = new EventEmitter();
  @Output() nextStep = new EventEmitter();

  form: FormGroup;
  idOptions = [
    { value: 'national id', text: 'DNI' },
    { value: 'foreigner card', text: 'Carnet de Extranjería' },
    { value: 'passport', text: 'Pasaporte' },
    { value: 'ruc', text: 'RUC' },
  ];
  segments
  channels;

  checkBoxLabel =
    "No quiero <span class='bold'>recibir comunicaciones</span> con fines comerciales.";
  selectedIdType;

  constructor(
    private formBuilder: FormBuilder,
    public gtmService: GTMService,
    private formService: FormService,
    private uniqueDocumentValidator: UniqueDocumentValidator
  ) {}

  ngOnInit(): void {
    this.channels = this.formService.getChannels();
    this.segments = this.formService.getInvestorSegments();
    this.form = this.formBuilder.group({
      idType: ['', [Validators.required]],
      idNumber: [
        '',
        [RequiredValidator, IDFormatValidator, IDLengthValidator],
        [this.uniqueDocumentValidator.isUniqueDocument()],
      ],
      idFrontPicture: ['', [Validators.required]],
      idBackPicture: [''],
      channel: ['', [Validators.required]],
      segment: ['', [Validators.required]],
      isNewsletter: [''],
    });
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.form.value.isNewsletter = !this.form.value.isNewsletter
    if (this.form.valid) {
      this.nextStep.emit(this.form.value);
    } else {
      let event = {
        event: '[Platform][Next][Button]',
        _inputName: `Clicked Crear Mi Cuenta In Step 3 Error`,
        _inputCategory: `Signup Investor / Step 3`,
        _inputError: this.formService.getFormValidationErrors(this.form),
      };
      this.sendEvent(event);
    }
  }

  backward() {
    let event = {
      event: '[Platform][Next][Button]',
      _inputName: `Clicked Atrás In Step 3`,
      _inputCategory: `Signup Investor / Step 3`,
    };
    this.sendEvent(event);
    this.previousStep.emit();
  }

  idTypeChanged(newType) {
    this.form.patchValue({ idNumber: '' });
    this.selectedIdType = newType;
    if (this.selectedIdType !== 'passport' && this.selectedIdType !== 'ruc') {
      this.form.get('idBackPicture').setValidators([Validators.required]);
      this.form.get('idBackPicture').updateValueAndValidity();
    }else if(this.selectedIdType === 'ruc'){
      this.form.get('idFrontPicture').setValidators([]);
      this.form.get('idBackPicture').setValidators([]);
      this.form.get('idBackPicture').updateValueAndValidity();
      this.form.get('idFrontPicture').updateValueAndValidity();
    }else if(this.selectedIdType === 'passport'){
      this.form.get('idFrontPicture').setValidators([Validators.required]);
      this.form.get('idFrontPicture').updateValueAndValidity();
      this.form.get('idBackPicture').setValidators([]);
      this.form.get('idBackPicture').updateValueAndValidity();
    }  else {
      this.form.get('idBackPicture').clearValidators();
      this.form.get('idBackPicture').updateValueAndValidity();
    }
  }

  sendEvent(event) {
    this.gtmService.newEvent(event);
  }
}
