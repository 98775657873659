import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.scss']
})
export class InvoiceStatusComponent implements OnInit {
  @Input() status;
  backgroundColor;
  statusLabel;

  constructor() { }

  ngOnInit() {
    this.setInputs();
  }

  setInputs() {
    switch(this.status) {
      case 'approved':
        this.backgroundColor = 'dark-blue';
        //this.statusLabel = 'Aprobada';
        break;
      case 'rejected':
        this.backgroundColor = 'dark-gray';
        //this.statusLabel = 'Cancelada';
        break;
      case 'pending':
        this.backgroundColor = 'blue';
        //this.statusLabel = 'En Validación';
        break;
      case 'in process':
        this.backgroundColor = 'blue';
        //this.statusLabel = 'En Validación';
        break;
      case 'investment start':
        this.backgroundColor = 'blue';
        //this.statusLabel = 'Por Iniciar';
        break;
      case 'in progress':
        this.backgroundColor = 'blue';
        //this.statusLabel = 'En Curso';
        break;
      case 'cancelled':
        this.backgroundColor = 'dark-gray';
        //this.statusLabel = 'Cancelada';
        break;
      case 'capital refunded':
        this.backgroundColor = 'dark-blue';
        //this.statusLabel = 'Capital Reembolsado';
        break;
      default:
        this.backgroundColor = 'dark-gray';
    }
  }

}
