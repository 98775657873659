import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  AfterContentInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { FormService } from 'src/app/shared/services/form.service';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { EmailValidator } from 'src/app/shared/validators/email.validators';
import { LettersOnlyValidator } from 'src/app/shared/validators/letters-only.validators';
import { MustBeTrue } from 'src/app/shared/validators/must-be-true.validator';
import { PasswordCriteriaValidator } from 'src/app/shared/validators/password-criteria.validator';
import { RequiredValidator } from 'src/app/shared/validators/required.validator';
import { UniqueUserByEmailValidator } from 'src/app/shared/validators/unique-user-by-email-validator';

@Component({
  selector: 'app-signup-general-form',
  templateUrl: './signup-general-form.component.html',
  styleUrls: ['./signup-general-form.component.scss'],
})
export class SignupGeneralFormComponent implements OnInit, AfterContentInit {
  @Output() previousStep = new EventEmitter();
  @Output() nextStep = new EventEmitter();

  formSubmitSubject$ = new Subject();
  form: FormGroup;

  termConditionContent =
    '<span>Acepto los <a class="bold blue" href="https://storage.googleapis.com/finsmart-websitefiles/legal/T%26C%203.2.pdf" target="_blank">Términos y Condiciones</a> y el trato de mis datos personales por Finsmart según su <a class="bold blue" href="https://storage.googleapis.com/finsmart-websitefiles/legal/Poli%CC%81tica%20de%20Privacidad%20v2.1.docx.pdf" target="_blank">Política de Privacidad.</a></span>';

  preferredCountries: CountryISO[] = [CountryISO.Peru, CountryISO.Spain];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  phoneForm: FormGroup;
  maxPhoneChars = 9;
  resultText = '';
  phoneError = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private uniqueUserByEmailValidator: UniqueUserByEmailValidator,
    public gtmService: GTMService,
    private formService: FormService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      names: [
        '',
        [
          RequiredValidator,
          LettersOnlyValidator,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      familyNames: [
        '',
        [
          RequiredValidator,
          LettersOnlyValidator,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      cellphone: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, EmailValidator],
        [this.uniqueUserByEmailValidator.isUserEmailUnique()],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(64),
          PasswordCriteriaValidator,
        ],
      ],
      termCondition: ['', [MustBeTrue]],
    });

    this.formSubmitSubject$
      .pipe(
        tap(() => {
          this.form.markAsDirty();
          this.form.markAllAsTouched();

          Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].updateValueAndValidity();
          });
          console.log(this.form.valid);
          console.log(this.form.controls);
          if (this.form.status === 'PENDING' && !this.form.valid) {
            this.sendInvalidEvent(this.form);
          }
        }),
        switchMap(() =>
          this.form.statusChanges.pipe(
            startWith(this.form.status),
            filter((status) => status !== 'PENDING'),
            take(1)
          )
        ),
        filter((status) => status === 'VALID')
      )
      .subscribe((validationSuccessful) => {
        this.nextStep.emit(this.form.value);
      });
  }

  focusOutPhone(event: any) {
    if (this.form.controls?.cellphone?.touched) {
      if (this.form.controls?.cellphone?.errors?.required) {
        this.phoneError = 'obligatorio';
      } else if (
        this.form.controls?.cellphone?.errors?.hasOwnProperty(
          'validatePhoneNumber'
        ) &&
        !this.form.controls?.cellphone?.errors?.validatePhoneNumber?.valid
      ) {
        this.phoneError = 'formato incorrecto';
      } else {
        this.phoneError = '';
      }
    }
  }

  ngAfterContentInit(): void {
    this.form.valueChanges.subscribe((data: any) => {
      if (this.form.controls?.cellphone?.touched) {
        if (this.form.controls?.cellphone?.errors?.required) {
          this.phoneError = 'obligatorio';
        } else if (
          this.form.controls?.cellphone?.errors?.hasOwnProperty(
            'validatePhoneNumber'
          ) &&
          !this.form.controls?.cellphone?.errors?.validatePhoneNumber?.valid
        ) {
          this.phoneError = 'formato incorrecto';
        } else {
          this.phoneError = '';
        }
      }
    });
  }

  backward() {
    this.router.navigate(['..']);
    let event = {
      event: '[Platform][Back][Button]',
      _inputName: `Clicked Atrás In Step 1`,
      _inputCategory: `Signup / Step 1`,
    };
    this.sendEvent(event);
  }

  submit() {
    this.formSubmitSubject$.next();
  }

  sendInvalidEvent(form) {
    if (this.form.pending) {
      let sub = this.form.statusChanges.subscribe((res) => {
        if (this.form.invalid) {
          let eventName = '[Platform][Next][Button][Error]';
          let event = {
            event: eventName,
            _inputName: `Clicked Siguiente In Step 1 Error`,
            _inputCategory: `Signup / Step 1`,
            _inputError: this.formService.getFormValidationErrors(this.form),
          };
          sub.unsubscribe();
          this.sendEvent(event);
        }
      });
    }
  }

  sendEvent(event) {
    this.gtmService.newEvent(event);
  }

  countryChange(event: any) {
    if (event?.dialCode == '51') {
      this.maxPhoneChars = 9;
    } else {
      this.maxPhoneChars = 15;
    }
  }
}
