import { Component, OnInit } from '@angular/core';
import { SuplierService } from 'src/app/features/my-supliers/services/supliers.service';
import { Store } from '@ngrx/store';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { AppState } from 'src/app/app.states';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-list-suppliers',
  templateUrl: './list-suppliers.component.html',
  styleUrls: ['./list-suppliers.component.scss'],
})
export class ListSuppliersComponent implements OnInit {
  clients;
  isConfirming = false;
  constructor(
    private store: Store<AppState>,
    public supplierService: SuplierService
  ) {}

  ngOnInit(): void {
    this.store
      .select(UsersSelectors.profileProperty('isConfirming'))
      .pipe(take(1))
      .subscribe((selectedUserId) => {
        this.isConfirming = selectedUserId;
        if (this.isConfirming) {
          this.getAllSupliers();
        }
      });
  }

  getAllSupliers() {
    this.supplierService.getAllSuppliers().subscribe((myClients) => {
      this.clients = myClients;
    });
  }

  openIntercom() {
    (<any>window).Intercom('show');
  }
}
