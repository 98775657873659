import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { InvoiceService } from '../../../services/invoice.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewObservationDialogComponent } from '../../organisms/new-observation-dialog/new-observation-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss'],
})
export class EmailPreviewComponent implements OnInit, OnDestroy {
  @Input() entity;
  html;
  invoiceDetail;
  obs;
  ccArray;
  toArray;
  include = [];
  tableRowClicked = false;
  destroy$: Subject<void> = new Subject<void>();
  role: any;

  constructor(
    public invoiceService: InvoiceService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private store: Store<AppState>
  ) {}

  toggleTableRowClicked() {
    this.tableRowClicked = !this.tableRowClicked;
  }
  ngOnInit(): void {
    // console.log(this.entity)
    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile) => {
        if (profile) {
          this.role = profile.role;

          if (this.role === 'admin' || this.role === 'super admin') {
            this.getObservations();
          }
        }
      });

    this.spinner.show();
    this.fetchEmailtemplate();
    // this.getCCSeparate();
    // this.getTOSeparate();

    this.spinner.hide();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCCSeparate() {
    let str = this.entity.cc;
    let newStr;
    newStr = str.split(',');
    this.ccArray = newStr;
  }

  getTOSeparate() {
    let str = this.entity.to;
    let newStr;
    newStr = str.split(',');
    this.toArray = newStr;
  }

  setInclude($event) {
    let obs = this.obs,
      objIndex;
    objIndex = obs.findIndex((ob) => ob._id == $event._id);
    obs[objIndex].selected = !obs[objIndex].selected;
    this.obs = obs;
    if (!this.include.includes($event._id)) {
      this.include.push($event._id);
    } else {
      this.include.splice(this.include.indexOf($event._id), 1);
    }
    this.entity.observations = this.include;

    this.fetchEmailtemplate();
  }

  setIGV(obs) {
    obs.isTaxable = !obs.isTaxable;
    this.updateObservation(obs);
  }
  getObservations() {
    this.invoiceService.byId(this.entity._id).subscribe((res: any) => {
      if (res) {
        // console.log("res",res)
        this.invoiceDetail = res;
        this.invoiceService
          .getObservationsByCurrency(res.issuer._id, res.currency)
          .subscribe((data) => {
            if (data) {
              let obs = data;
              obs.map((ob) => (ob.selected = true));
              this.obs = obs;
              // console.log("obs",this.obs)
            }
          });
      }
    });
  }

  addObservation() {
    let data = {
      invoiceId: this.entity._id,
      userId: this.invoiceDetail.issuer._id,
    };
    let dialogRef = this.openDialogHelper(NewObservationDialogComponent, {
      data: data,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.invoiceService.saveObservations(result).subscribe((data) => {
          if (data) {
            //this.getObservations();
            if (data.currency === this.invoiceDetail.currency) {
              data.selected = true
              this.obs.unshift(data);
              this.fetchEmailtemplate();
            }
          }
        });
      }
    });
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: data,
    });

    return dialogRef;
  }

  fetchEmailtemplate() {
    // console.log("fetch-email",this.entity)
    this.invoiceService.getEmailPreview(this.entity).subscribe((data: any) => {
      if (data) {
        this.html = data.htmlTemplate;
      }
    });
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  updateObservation(obs) {
    this.invoiceService.updateObservation(obs).subscribe((data) => {
      if (data) {
        this.getObservations();
        this.fetchEmailtemplate();
      }
    });
  }
}
