import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { NumbersOnlyValidator } from 'src/app/shared/validators/numbers-only.validators';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { ShortenPipe } from 'src/app/shared/pipes/shorten.pipe';
import {
  takeUntil,
  tap,
  switchMap,
  startWith,
  filter,
  take,
} from 'rxjs/operators';
import { MoneyValidator } from 'src/app/shared/validators/money.validator';
import { SufficientBalanceValidator } from 'src/app/shared/validators/sufficient-balance.validator';
import { balanceAvailable } from '../../../state/financial-transactions.selectors';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { CurrencyNamePipe } from 'src/app/shared/pipes/currency-name.pipe';
import { GreaterThanZeroValidator } from 'src/app/shared/validators/greater-than-zero.validators';
import { BankAccountsService } from 'src/app/features/bank-accounts/services/bank-accounts.service';
import { SufficientBalanceValidator2 } from 'src/app/shared/validators/sufficient-balance.validator2';
import { FinancialtransactionsService } from '../../../services/financial-transactions.service';
import { UsersService } from 'src/app/features/users/services/users.service';
import { isNumber } from 'lodash';

@Component({
  selector: 'app-financial-transaction-invest-form',
  templateUrl: './financial-transaction-invest-form.component.html',
  styleUrls: ['./financial-transaction-invest-form.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialTransactionInvestFormComponent
  implements OnInit, OnDestroy
{
  @Input() invoice: any;
  @Output() onSubmit = new EventEmitter();
  @Output() OnSelectedUserChanged = new EventEmitter();
  ngUnsubscribe: Subject<void> = new Subject<void>();
  formSubmitSubject$ = new Subject();
  form: FormGroup;
  investedPercentage = 0;
  investedPercentageHelper = 0;
  availableAmount = 0;
  availableAmountHelper = 0;
  investorUserId;
  penBalance;
  usdBalance;
  investor
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private shortenPipe: ShortenPipe,
    private currencyNamePipe: CurrencyNamePipe,
    private sufficientValidator: SufficientBalanceValidator,
    private bankAccountService: BankAccountsService,
    private financialService: FinancialtransactionsService,
    private userService: UsersService
  ) {}

  ngOnInit() {
    console.log(this.invoice);

    this.form = this.fb.group({
      user: [''],
      supportFile: ['', [Validators.required]],
      amount: [
        '',
        [
          Validators.required,
          GreaterThanZeroValidator,
          MoneyValidator(1, 2),
          this.isSufficient(),
        ],
      ],
    });

    this.investedPercentage = this.round(
      100 - this.invoice.availableBalancePercentage
    );

    this.availableAmount = this.invoice.availableBalanceAmount;

    this.investedPercentageHelper = this.investedPercentage;
    this.availableAmountHelper = this.availableAmount;

    this.form.valueChanges.subscribe((val) => {
      this.investedPercentage = this.investedPercentageHelper;
      this.availableAmount = this.availableAmountHelper;
      if (!isNaN(val.amount) || val.amount) {
        this.availableAmount = this.round(this.availableAmount - val.amount);
        this.investedPercentage = val.amount
          ? this.round(
              (1 -
                ((1 - this.investedPercentage / 100) *
                  (this.invoice.availableBalanceAmount - val.amount)) /
                  this.invoice.availableBalanceAmount) *
                100
            )
          : this.round(100 - this.invoice.availableBalancePercentage);
      } else {
        this.availableAmount = this.invoice.availableBalanceAmount;
        this.investedPercentage = this.round(
          100 - this.invoice.availableBalancePercentage
        );
      }
    });

    this.formSubmitSubject$
      .pipe(
        tap(() => {
          this.form.markAllAsTouched();
          Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].updateValueAndValidity();
          });
        }),
        switchMap(() =>
          this.form.statusChanges.pipe(
            startWith(this.form.status),
            filter((status) => status !== 'PENDING'),
            take(1)
          )
        ),
        filter((status) => status === 'VALID')
      )
      .subscribe((validationSuccessful) => this.submit());
  }

  submit() {
    const entity = {
      status: 'investment start',
      amount: this.form.controls.amount.value,
      currency: this.invoice.currency,
      type: 'investment',
      user: this.investorUserId,
      invoice: this.invoice._id,
      invoiceObject: this.invoice,
      supportFile: this.form.controls.supportFile.value,
    };
    this.onSubmit.emit(entity);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  selectedInvestorChanged(investorUserId) {
    this.investorUserId = investorUserId;
    if (investorUserId) {
      this.OnSelectedUserChanged.emit(investorUserId);
    } else {
      this.OnSelectedUserChanged.emit(null);
    }

    this.financialService
      .getInvestorAvailableBalance(this.investorUserId)
      .subscribe((balance) => {
        this.penBalance = balance.pen;
        this.usdBalance = balance.usd;
      });

    this.userService
      .getUserById(this.investorUserId)
      .subscribe((investor: any) => {
        this.investor = investor;
        console.log(this.investor)
      });
  }

  round(num: number) {
    return Math.round(num * 100) / 100;
  }

  isSufficient(): ValidatorFn {
    return (control: AbstractControl) => {
      try {
        let hasSufficientBalance = false;

        hasSufficientBalance =
          this.invoice.currency === 'pen'
            ? parseFloat(control.value) <= parseFloat(this.penBalance)
            : parseFloat(control.value) <= parseFloat(this.usdBalance);

        let hasInvoiceSufficientBalance = false;

        hasInvoiceSufficientBalance =
          parseFloat(control.value) <=
          parseFloat(this.invoice.availableBalanceAmount);

        if (!hasInvoiceSufficientBalance) {
          return { invoiceSufficientBalance: true };
        }

        if (!hasSufficientBalance) {
          return { sufficientBalance: true };
        }

        return null;
      } catch (error) {
        return null;
      }
    };
  }
}
