<mat-form-field [ngClass]="{'custom-mat-invalid-style': _errorStatus === true}">
    <mat-label>{{ label }}</mat-label>
    <input 
        matInput 
        [matDatepickerFilter]="minimumDateFilter" 
        (click)="dp.open()" 
        [matDatepicker]="dp" 
        [value]="_value"
        [disabled]="disabled"
        (dateInput)="writeValue($event.value.toDate().toISOString())"
        [max]="max"
        readonly>
    <mat-datepicker-toggle [disabled]="disabled" matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp disabled="false"></mat-datepicker>
</mat-form-field>
