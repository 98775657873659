import { createSelector } from '@ngrx/store';
import { reduce, filter } from 'rxjs/operators';
import * as Big from 'big.js';
Big.RM = 2;
Big.DP = 2;

export const evaluationsLoaded = createSelector(
    state => state["evaluations"],
    (state) => state.loaded
);
export const evaluations = createSelector(
    state => state["evaluations"],
    (state) => state.entities
);

export const all = createSelector(
    state => state["evaluations"],
    (state) => state.entities
);

export const evaluationById = (id) => createSelector(
    evaluations,
    (items) => items.filter(evaluation => evaluation._id === id)[0]
);
export const evaluationsByUserAccountManager = (userId) => createSelector(
    evaluations,
    (items) => { 
        if(items) return items.filter(evaluation => evaluation.createdBy._id === userId)
    }
);

export const savingSuccessStateById = (savingEntityId) => createSelector(
    state => {
        return  { 
            saving: state['evaluations']['saving'],
            savingEntityId: state['evaluations']['savingEntityId'],
            savingSucceeded: state['evaluations']['savingSucceeded'],
            lastErrorMessage: state['evaluations']['lastErrorMessage'],
            lastErrorStatus: state['evaluations']['lastErrorStatus']
        }
    },
    (savingState) => {
        if(savingEntityId === savingState.savingEntityId && savingState.saving === false) {
            return {
                savingSucceeded: savingState.savingSucceeded,
                lastErrorMessage: savingState.lastErrorMessage,
                lastErrorStatus: savingState.lastErrorStatus
            }
        }
    }
);


/*

temp1.reduce((c, p) => {

    return Number(c.amount) + Number(p.amount)
})

*/