import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(
    items: any[],
    key: string,
    direction: 'asc' | 'desc' | 'closest' | 'smallest' = 'asc'
  ): any {
    //console.log(items);

    if (items) {
      if (direction === 'asc') return sortAscending(items, key);
      else if (direction === 'desc') return sortDescending(items, key);
      else if (direction === 'closest') return sortToClosest(items, key);
      else if (direction === 'smallest')
        return sortToSmallestNumber(items, key);
    } else {
      return [];
    }
  }
}
//
function sortToSmallestNumber(array, key) {
  return array.sort(function (a, b) {
    let x = typeof a[key] === 'string' ? Infinity : a[key];
    let y = typeof b[key] === 'string' ? Infinity : b[key];

    if (x > y) {
      return 1;
    } else if (y > x) {
      return -1;
    } else {
      return 0;
    }
  });
}
function sortToClosest(array, key) {
  return array.sort((a, b) => {
    let x = new Date(b[key]).getTime();
    let y = new Date(a[key]).getTime();
    if (x > y) {
      return 1;
    } else if (y > x) {
      return -1;
    } else {
      return 0;
    }
  });
}

function sortAscending(array, key) {
  return array.slice().sort(function (a, b) {
    const keyA = new Date(a[key]);
    const keyB = new Date(b[key]);
    
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;

    return 0;
  });
}

function sortDescending(array, key) {
  // console.log(array);
  // console.log(key);

  return array.slice().sort(function (a, b) {
    const keyA = new Date(a[key]);
    const keyB = new Date(b[key]);

    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;

    return 0;
  });
}
