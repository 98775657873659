import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent {
  @Input() date;
  @Input() title;
  @Input() titlePoistion;
  @Input() titleColor = 'default';
  @Input() oneLine;
  @Input() displayStyle: 'plain' | 'badge' = 'badge';
  @Input() pulsing =  false;

  constructor() { }

  setTitleClasses() {
    return {
      'tiny': true,
      'tiny-title': true,
      'sameline': this.oneLine,
      'right': this.titlePoistion === 'right',
      [this.titleColor]: true
    }
  }
  setPlainDateClasses() {
    return {
      'blue': this.date,
      'bold': this.date,
      'plainDate': true,
      'pulse': this.pulsing
    }
  }
}
