import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-group-confirmation',
  templateUrl: './invoice-group-confirmation.component.html',
  styleUrls: ['./invoice-group-confirmation.component.scss']
})
export class InvoiceGroupConfirmationComponent implements OnInit {

  @Input() entity
  constructor() { }

  ngOnInit(): void {
  }

}
