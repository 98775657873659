import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rounded-button-action',
  templateUrl: './rounded-button-action.component.html',
  styleUrls: ['./rounded-button-action.component.scss']
})
export class RoundedButtonActionComponent implements OnInit {

  @Input() imgIcon;
  @Input() text;
  @Input() background: 'dark-gray' | 'blue' | 'black' = 'dark-gray';
  
  constructor() { }

  ngOnInit(): void {
  }

  getIconUrl() {
    return "../../../../../assets/icons/" + this.imgIcon;
  }

  public get classes(): string[] {
    let mode = ''
    switch (this.background) {
      case 'dark-gray':
        mode = 'dark-gray'
        break;
      case 'blue':
        mode = 'blue'
        break;
      case 'black':
        mode = 'black'
        break;
      default:
        mode = 'dark-gray'
        break;
    }

    return ['rounded', mode];
  }

}
