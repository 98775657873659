<div class="card-container">
    <div class="card-layout">
        <div id="message" class="message innerHTML" [innerHTML]="(message) | safeHtml">
        </div>
    </div>

    <div class="info">
        <div class="one-line">
            <div *ngIf="showActions" class="icon-text mr">
                <img src="../../../../../../assets/icons/calendar.svg" />
                <span class="ml-4">{{announcement?.createdAt | formatDate}}</span>
            </div>
            <div *ngIf="showActions" class="icon-text mr">
                <img src="../../../../../../assets/icons/clock.svg" />
                <span class="ml-4">{{announcement?.createdAt | formatTime}}</span>
            </div>
            <div *ngIf="showActions" class="icon-text mr">
                <img src="../../../../../../assets/icons/paper_board.svg" />
                <span class="ml-4">{{announcement?.type === "automatic" ? 'Ingreso Automático': 'Ingreso
                    manual'}}</span>
            </div>
            <div *ngIf="announcement?.filename" (click)="showFile()" class="icon-text mr">
                <img src="../../../../../../assets/icons/clip.svg" />
                <span class="ml-4 hyperlink">{{announcement?.filename}}</span>
            </div>
            <div *ngIf="!announcement?.filename" class="icon-text mr">
                <img src="../../../../../../assets/icons/clip.svg" />
                <span class="ml-4">Sin adjunto</span>
            </div>
        </div>
        <div class="one-line" *ngIf="showActions">
            <div *ngIf="announcement?.type !== 'automatic'" class="icon-text clickable mr-10" (click)="resendAnnouncement()">
                <img src="../../../../../../assets/icons/send_black.svg" />
                <span class="ml-4">Redirigir {{announcement?.invoiceIds?.length > 0 ? '(' + (announcement?.invoiceIds?.length - 1) + ')': ''}}</span>
            </div>
            <div *ngIf="announcement?.type !== 'automatic'" class="icon-text clickable" (click)="editAnnouncement()">
                <img src="../../../../../../assets/icons/edit-gray.svg" />
                <span class="ml-4">Editar</span>
            </div>
        </div>
    </div>

</div>