import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { FinancialTransactionActions } from '../../../state/financial-transactions.action-types';
import { savingSuccessStateById } from '../../../state/financial-transactions.selectors';
import { Router } from '@angular/router';
import { FinancialTransactionInvestItemComponent } from '../../organisms/financial-transaction-invest-item/financial-transaction-invest-item.component';

@Component({
  selector: 'app-create-financial-transaction-invest',
  templateUrl: './create-financial-transaction-invest.component.html',
  styleUrls: ['./create-financial-transaction-invest.component.scss'],
})
export class CreateFinancialTransactionInvestComponent implements OnInit {
  selectedUserId;
  invoice;
  constructor(private overlayService: OverlayService, private router: Router) {
    this.invoice = this.router?.getCurrentNavigation()?.extras?.state?.invoice;
  }

  ngOnInit() {}

  selectedUserChanged(userId) {
    if (userId) {
      this.selectedUserId = userId;
    }
  }

  submitHandler(formValue) {
    this.overlayService.startConfirmation(
      formValue,
      FinancialTransactionInvestItemComponent,
      'details',
      'create',
      '',
      FinancialTransactionActions.Saving,
      `/invoices/${this.invoice._id}`,
      savingSuccessStateById,
      {
        onConfirm: {
          title: 'Confirmación de inversión',
        },
      }
    );
  }
}
