<ng-container *allowedRoles="['client']">
    <h1 style="margin-bottom: 30px;">{{title}}</h1>

    <br>
    <p class="disclaimer"><span class="bold">La cuenta que intentas eliminar está asociada a una factura en
            curso.</span> Por
        favor, selecciona otra cuenta bancaria
        de reemplazo.</p>
    <br>
    <p class="disclaimer">En caso que no tengas una cuenta bancaria en la misma moneda, tienes que <span
            class="bold">crear
            una cuenta primero</span>.</p>
    <br>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-select formControlName="bankAccount" [options]="bankAccounts" valueField="id" textField="bankSummary"
            [hasTooltip]=true [defaultOption]="selectedBankAccount" label="Cuenta bancaria"></app-select>
        <div class="button-container button-direction-column">
            <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Siguiente'" [type]="'secondary'">
            </fs-ui-button>
            <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
        </div>
    </form>
</ng-container>

<ng-container *allowedRoles="['investor']">
    <h1 *ngIf="bankAccounts?.length" style="margin-bottom: 30px;">{{title}}</h1>
    <br *ngIf="bankAccounts?.length">

    <h1 *ngIf="!bankAccounts?.length" style="margin-bottom: 10px;">{{title}}</h1>

    <p *ngIf="bankAccounts?.length" class="disclaimer"><span class="bold">La cuenta que intentas eliminar está asociada
            a
            una {{data.role === 'investor'?
            'transacción' : 'factura'}} en
            curso.</span> Por
        favor, selecciona otra cuenta bancaria
        de reemplazo.</p>
    <br>
    <p *ngIf="!bankAccounts?.length" class="disclaimer">En caso que no tengas una cuenta bancaria en la misma moneda,
        tienes
        que <a [href]="linkToCreateAccount"><span class="link">crear
                una cuenta primero</span>.</a></p>
    <br>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-select *ngIf="bankAccounts?.length" formControlName="bankAccount" [options]="bankAccounts" valueField="id"
            textField="bankSummary" [hasTooltip]=true [defaultOption]="selectedBankAccount" label="Cuenta bancaria">
        </app-select>
        <div class="button-container button-direction-column">
            <fs-ui-button *ngIf="bankAccounts?.length" [action]="'submit'" (click)="onSubmit()" [label]="'Siguiente'"
                [type]="'secondary'">
            </fs-ui-button>
            <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
        </div>
    </form>
</ng-container>