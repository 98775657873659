import { HotjarService } from 'src/app/shared/services/hotjar.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
@Component({
  selector: 'app-ws-widget',
  templateUrl: './ws-widget.component.html',
  styleUrls: ['./ws-widget.component.scss'],
})
export class WsWidgetComponent implements OnInit {
  @Input() state: 'default' | 'activated' | 'blocked' = 'blocked';
  @Input() title = '¡Mantente informado!';
  // @Input() body =
  //   'Activa las notificaciones por Whatsapp y entérate en tiempo real sobre lo que falta para financiar tus facturas.';
  @Input() body =
    'Para recibir notificaciones, <span class="body-paragraph-xs-regular">haz clic en “Sí”</span> en el mensaje que te enviamos por WhatsApp.';
  @Input() icon: Boolean = true;
  @Input() captionMsg = 'Vuelve a enviar en 10:00';
  @Output() clickButton = new EventEmitter();
  isMobile;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private hotjarService: HotjarService
  ) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  actionToDo(action) {
    if (action == 'activate') {
      this.hotjarService.newEvent("activate_whatsapp");
    }
    this.clickButton.emit({ action: action });
  }
}
