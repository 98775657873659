<h1>{{title}}</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <app-input *ngIf="data?.data.type !== 'OPERATION_START' && data?.data.type !== 'CONFORMIDAD_CAVALI'" formControlName="to" autocomplete="off" type="text" label="Para">
        </app-input>
        <div *ngIf="data?.data.type === 'OPERATION_START' || data?.data.type === 'CONFORMIDAD_CAVALI'" style="margin-bottom: 20px;">
            <p class="tiny" style="margin-bottom: 4px;">Para</p>
            <p class="break-line">{{data.data.to  | stringNextLine: ','}}</p>
        </div>
        <app-input formControlName="cc" autocomplete="off" type="text" label="CC"></app-input>
        <ng-container *ngIf="!data.data.isExternal">
            <app-input formControlName="notes" autocomplete="off" type="text" label="Notas"></app-input>
            <app-upload formControlName="file" initialLabel="Adjuntar archivo" loadedLabel="Archivo adjuntado">
            </app-upload>
        </ng-container>
        <ng-container *ngIf="data?.data.type === 'OPERATION_START'">
            <div class="container-sunat">
                <h1 class="title-sunat">Tipo de respuesta</h1>
                <app-radiolist-circle [direction]="'column'" formControlName="payerResponse" [options]="sunatResponse">
                </app-radiolist-circle>
            </div>
        </ng-container>
    </div>

    <div class="error" *ngIf="error">{{error}}</div>

    <div class="button-container button-direction-column">
        <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Continuar'" [type]="'secondary'">
        </fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>