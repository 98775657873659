<div>
    <h1>¡Bienvenido!</h1>
    <p>Ingresa la información de tu cuenta.</p>
    <form [formGroup]="form">
        <app-input formControlName="names" label="Nombres" maxlength="50"></app-input>
        <app-input formControlName="familyNames" label="Apellidos" maxlength="50"></app-input>
        <!-- <app-input formControlName="phone" label="Celular"></app-input> -->

        <div style="display: flex; flex-direction: column;margin-bottom: -4px;">
            <span style="font-size: 12px;margin-bottom: 4px"
                [ngStyle]="{'color':  phoneError ? '#FF0000' :'rgba(0,0,0,.6)'}">Teléfono celular<span *ngIf="phoneError"> ({{phoneError}})</span></span>
            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true" [enablePlaceholder]="true" customPlaceholder="Ingresa tu número"
                [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Peru" [maxLength]="maxPhoneChars"
                [phoneValidation]="true" [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.International"
                searchCountryPlaceholder="Elegir país" formControlName="cellphone" (focusout)="focusOutPhone($event)"
                (countryChange)="countryChange($event)">
            </ngx-intl-tel-input>
        </div>
        <app-input class="signup-email-input" formControlName="email" label="Correo electrónico"></app-input>
        <app-input class="lastInput" formControlName="password" encrypted="true" label="Contraseña"></app-input>

        <div class="passwordInfo">
            <app-signup-side-image [image]="'../../../../../assets/icons/info-24px.svg'"
                [description]="'Por seguridad, tu contraseña debe contener al menos una mayúscula, una minúscula y un número.'">
            </app-signup-side-image>
        </div>

        <app-checkbox [content]="termConditionContent" [errorMessage]="'Falta aceptar las condiciones.'"
            formControlName="termCondition" color="accent"></app-checkbox>

        <div class="actionButtons center-dp">
            <fs-ui-button (click)="backward()" [label]="'Atrás'" [type]="'tertiary'"></fs-ui-button>
            <fs-ui-button (click)="submit()" [label]="'Siguiente'"></fs-ui-button>
        </div>
    </form>

</div>