import {
  AfterContentInit,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { NewSginupFormComponent } from 'src/app/features/authentication/UI/organisms/new-sginup-form/new-sginup-form.component';
import { StepContentDirective } from '../step-content.directive';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements OnInit, AfterContentInit {
  @HostBinding('class.active') @Input() active: boolean;
  @ContentChild(StepContentDirective) stepContent: StepContentDirective;

  constructor() {}
  ngAfterContentInit(): void {
    // console.log(this.stepContent);
    this.stepContent?.onNext.subscribe((data) => {
      // console.log('test event from the step component', data);
    });
  }

  ngOnInit(): void {}
}
