import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-invertor-residency-dialog',
  templateUrl: './invertor-residency-dialog.component.html',
  styleUrls: ['./invertor-residency-dialog.component.scss'],
})
export class InvertorResidencyDialogComponent implements OnInit {
  isPeru = true;
  countries;
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRefModal: MatDialogRef<InvertorResidencyDialogComponent>,
    private service: FormService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      taxResidency: [''],
    });

    //this.getAllCountries()
  }

  getAllCountries() {
    this.service.getAllCountries().subscribe((data: any) => {
      //console.log(data)
      this.countries = data;
    });
  }

  setResindecyCountry(option) {
    this.isPeru = !this.isPeru;
    if (!this.isPeru) {
      this.getAllCountries();
      this.form.get('taxResidency').setValidators([Validators.required]);
      this.form.get('taxResidency').updateValueAndValidity();
    } else {
      this.form.get('taxResidency').setValidators([]);
      this.form.get('taxResidency').updateValueAndValidity();
    }
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    console.log(this.form.valid);
    if (this.form.valid) {
      if (this.isPeru) {
        let obj = {
          taxResidency: 'Perú',
        };
        this.dialogRefModal.close(obj);
      } else {
        this.dialogRefModal.close(this.form.value);
      }
    }
  }
}
