import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { FinancialtransactionsService } from "../../../../financial-transactions/services/financial-transactions.service";
@Component({
  selector: 'app-historical-investment-dialog',
  templateUrl: './historical-investment-dialog.component.html',
  styleUrls: ['./historical-investment-dialog.component.scss']
})
export class HistoricalInvestmentDialogComponent implements OnInit {
  toggle = 'pen'
  default = true
  historical
  isEmpty: boolean;
  isLoading = true;
  constructor( 
    public dialogRef: MatDialogRef<HistoricalInvestmentDialogComponent>,
    private service: FinancialtransactionsService,
    private windowScrollService : WindowScrollService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getDefaultToggle()
    this.getInvestorData()
  }

  
  getInvestorData(){
    this.service.historicInvestment(this.data?.data?._id, this.toggle).subscribe(data => {
      if(data){
        this.isLoading = false
        this.isEmpty = Object.values(data).every(x => x === null || x === '' || x === "0.00" || x === 0);
        this.historical = data

      }else{
        this.isLoading = false
        this.isEmpty = true
      }
    })
  }
  
  setCurrency($event){
    this.toggle = $event
    this.getInvestorData()
    this.sendEventPageView($event)
  }
  
  cancel() {
    this.dialogRef.close({event: 'Cancel'});
  }
  
  getDefaultToggle() {
    const toogleLocal = localStorage.getItem('toggle_currency');
    if (toogleLocal != null) {
      this.toggle = toogleLocal
      this.getInvestorData()
    }
    this.toggle == 'pen' ? this.default = false : this.default = true

    this.sendEventPageView(this.toggle)

  }
  sendEventPageView(currency){
    let pageSection = this.data?.data?.pageSection ? this.data?.data?.pageSection : 'Mi Historial Overview'
    if(currency){
      this.windowScrollService.sendNewEvent( "Opportunities", pageSection, null, currency.toUpperCase())
    }
  }
  
}
