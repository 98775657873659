import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-last-evaluation',
  templateUrl: './last-evaluation.component.html',
  styleUrls: ['./last-evaluation.component.scss']
})
export class LastEvaluationComponent implements OnInit {
  @Input() date
  @Input() status;


  constructor() {
  }

  ngOnInit(): void {
  }

  getClass(): string[] {
    let mode = ''
    switch (this.status) {
      case 'approved':
        mode = 'approved'
        break;

      case 'pending':
        mode = 'pending'
        break;

      case 'rejected':
        mode = 'denied'
        break;

      case 'denied':
        mode = 'denied'
        break;

      case 'observe':
        mode = 'pending'
        break;

      default:
        mode = 'pending'

        break;
    }

    return ['shape', mode];
  }

  getDateDiff(dateSent) {
    if (dateSent) {
      // let currentDate = new Date();
      // dateSent = new Date(dateSent);

      return moment().utc().diff(moment(dateSent), 'days');
      // return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    } else {
      return 'Invalid'
    }

  }

}
