import { AbstractControl, ValidatorFn } from '@angular/forms';

export function EmailValidator(control: AbstractControl) {
  const isValidEmail = new RegExp(/^[_a-zA-Z0-9+-]+(\.[_a-zA-Z0-9+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,24})$/)
  .test(control.value);
  
  if(!isValidEmail) {
    return { email: true };
  }
  return null;
}
