import { createAction, props } from '@ngrx/store';

export const LoadAll = createAction(
    '[Transactions] Load All'
);
export const LoadAllSuccess = createAction(
    '[Transactions] Loaded All Success',
    props<{financialTransactions: any}>()
);
export const Reset = createAction(
    '[Transactions] Reset'
);
export const Saving = createAction(
    '[Transactions] Saving',
    props<{entity: any, mode: 'edit' | 'create' }>()
);
export const Investing = createAction(
    '[Transactions] Investing',
    props<{entity: any, mode: 'create' }>()
);
export const InvestSuccess = createAction(
    '[Transactions] Invest Success',
    props<{entity: any}>()
);
export const InvestFailed = createAction(
    '[Transactions] Invest Failed',
    props<{error: any}>()
);
export const SaveSuccess = createAction(
    '[Transactions] Save Success',
    props<{entity: any}>()
);
export const SaveFailed = createAction(
    '[Transactions] Save Failed',
    props<{error: any}>()
);
export const ReadAnnouncement = createAction(
    '[Transactions] ReadAnnouncement',
    props<{entity: any}>()
);
