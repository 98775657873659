import { Injectable } from '@angular/core';
import { RESTService } from '../../../shared/services/rest.service';
import { Observable } from 'rxjs';
import { formatFilter } from '../../../shared/util/api.util';

@Injectable({
  providedIn: 'root',
})
export class DebtorService {
  constructor(private rest: RESTService) {}

  get(skip, limit, filter): Observable<any> {
    let url = `/debtors?skip=${skip}&limit=${limit}`;

    if (filter?.pagination) delete filter?.pagination;
    const filterHasValues = filter
      ? Object.values(filter).some((v) => {
          if (Array.isArray(v)) {
            return v.length > 0 ? true : false;
          } else {
            return !!v;
          }
        })
      : undefined;

    if (filterHasValues) {
      const filtersArray = [];

      if (filter.supplier) filtersArray.push(`supplier=${filter.supplier}`);
      if (filter.debtor) filtersArray.push(`debtor=${filter.debtor}`);

      if (filter.creditLineFrom && !isNaN(filter.creditLineFrom)) {
        filtersArray.push(
          `creditLineFrom=${Number(filter.creditLineFrom).toFixed(2)}`
        );
      }
      if (filter.creditLineTo && !isNaN(filter.creditLineTo)) {
        filtersArray.push(
          `creditLineTo=${Number(filter.creditLineTo).toFixed(2)}`
        );
      }
      if (filter.accountManagers)
        filtersArray.push(
          `accountManagers=${filter.accountManagers.toString()}`
        );

      if (filtersArray.length) {
        url += `&${filtersArray.join('&')}`;
      }
    }
    return this.rest.get(url);
  }

  getById(id): Observable<any> {
    return this.rest.get(`/debtors/${id}`);
  }

  getAccountManagers() {
    return this.rest.get(`/users`);
  }

  updateOneField(debtorId, field) {
    return this.rest.patch(`/debtors/${debtorId}`, field);
  }

  downloadExcel(filter) {
    let url = `/evaluations/file`;

    if (filter?.pagination) delete filter?.pagination;

    const filtersArray = [];

    if (filter.supplier) filtersArray.push(`supplier=${filter.supplier}`);
    if (filter.debtor) filtersArray.push(`debtor=${filter.debtor}`);
    if (filter.creditLineFrom && !isNaN(filter.creditLineFrom)) {
      filtersArray.push(
        `creditLineFrom=${Number(filter.creditLineFrom).toFixed(2)}`
      );
    }
    if (filter.creditLineTo && !isNaN(filter.creditLineTo)) {
      filtersArray.push(
        `creditLineTo=${Number(filter.creditLineTo).toFixed(2)}`
      );
    }
    if (filter.accountManagers)
      filtersArray.push(`accountManagers=${filter.accountManagers.toString()}`);

    if (filtersArray.length) {
      url += `?hasFilter=true&${filtersArray.join('&')}`;
    } else {
      url += '?hasFilter=false';
    }

    return this.rest.get(url);
  }

  createEvaluation(debtor): Observable<any> {
    return this.rest.post(`/evaluations`, debtor);
  }

  requestEvaluation(evaluation): Observable<any> {
    return this.rest.post(`/debtors`, evaluation);
  }

  getContacts(debtorRuc: string, supplierRuc?: string) {
    let query = '';
    if (debtorRuc) {
      query += `debtorRuc=${debtorRuc}`;
    }

    if (supplierRuc) {
      if (query.length > 0) {
        query += '&';
      }
      query += `supplierRuc=${supplierRuc}`;
    }

    return this.rest.get(`/contacts?${query}`);
  }

  createCrossSellingDeal(ruc: string): Observable<any> {
    return this.rest.get(`/debtor/cross-selling/${ruc}`);
  }
}
