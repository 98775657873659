import { RiskDebtorDetailComponent } from './features/risk-debtors/UI/organisms/risk-debtor-detail/risk-debtor-detail.component';
import { ListRiskDebtorTemplateComponent } from './features/risk-debtors/UI/templates/list-risk-debtor/list-risk-debtor-template.component';
import { RiskDebtorsPageComponent } from './features/risk-debtors/UI/pages/risk-debtors/risk-debtors-page.component';
import { CreateFinancialTransactionInvestComponent } from './features/financial-transactions/UI/templates/create-financial-transaction-invest/create-financial-transaction-invest.component';
import { AddClientComponent } from './features/my-clients/UI/templates/add-clients/add-client.component';
import { ListClientsComponent } from './features/my-clients/UI/templates/list-clients/list-clients.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoggedGuard } from './shared/guards/logged.guard';
import { AnonymousGuard } from './shared/guards/anonymous.guard';

import { BankAccountsPage } from './features/bank-accounts/UI/pages/bank-accounts.page';
import { ListBankAccountsTemplate } from './features/bank-accounts/UI/templates/list-bank-accounts/list-bank-accounts.template';
import { BankAccountsResolver } from './features/bank-accounts/services/bank-accounts.resolver';
import { AuthPage } from './features/authentication/UI/pages/auth.page';
import { LoginComponent } from './features/authentication/UI/templates/login/login.component';
import { RegisterComponent } from './features/authentication/UI/templates/register/register.component';
import { ForgotPasswordComponent } from './features/authentication/UI/templates/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './features/authentication/UI/templates/reset-password/reset-password.component';
import { NotFoundComponent } from './shared/UI/pages/not-found/not-found.component';
import { CreateBankAccountsTemplate } from './features/bank-accounts/UI/templates/create-bank-accounts/create-bank-accounts.template';
import { EditBankAccountsTemplate } from './features/bank-accounts/UI/templates/edit-bank-accounts/edit-bank-accounts.template';
import { FinancialTransactionsPageComponent } from './features/financial-transactions/UI/pages/financial-transactions-page/financial-transactions-page.component';
import { ListFinancialTransactionsTemplateComponent } from './features/financial-transactions/UI/templates/list-financial-transactions-template/list-financial-transactions-template.component';
import { CreateFinancialTransactionDepositComponent } from './features/financial-transactions/UI/templates/create-financial-transaction-deposit/create-financial-transaction-deposit.component';
import { CreateFinancialTransactionWithdrawComponent } from './features/financial-transactions/UI/templates/create-financial-transaction-withdraw/create-financial-transaction-withdraw.component';
import { InvoicesPageComponent } from './features/invoices/UI/pages/invoices-page/invoices-page.component';
import { InvestmentsPageComponent } from './features/my-investments/UI/pages/investments-page/investments-page.component';
import { ListInvoicesComponent } from './features/invoices/UI/templates/list-invoices/list-invoices.component';
import { CreateInvoiceComponent } from './features/invoices/UI/templates/create-invoice/create-invoice.component';
import { InvestInInvoiceComponent } from './features/opportunities/UI/templates/invest-in-invoice/invest-in-invoice.component';
import { SystemSettingsComponent } from './features/system-settings/system-settings.component';
import { OpporunitiesPageComponent } from './features/opportunities/UI/pages/opporunities-page/opporunities-page.component';
import { FinancialTransactionsResolver } from './features/financial-transactions/services/financial-transactions.resolver';
import { EvaluationsResolver } from './features/risk-pending-evaluations/services/risk.resolver';
import { ListInvestmentsComponent } from './features/my-investments/UI/templates/list-investments/list-investments.component';
import { InvoicesResolver } from './features/invoices/services/invoices.resolver';
import { SignupPageComponent } from './features/users/UI/pages/signup-page/signup-page.component';
import { InvestorSignupTemplateComponent } from './features/users/UI/templates/investor-signup-template/investor-signup-template.component';
import { ClientSignupTemplateComponent } from './features/users/UI/templates/client-signup-template/client-signup-template.component';
import { RoleSelectorComponent } from './features/users/UI/templates/role-selector/role-selector.component';
import { SystemSettingsPageComponent } from './features/system-settings/UI/pages/system-setting/system-settings.page.component';
import { ListTermsConditionsComponent } from './features/system-settings/UI/templates/list-terms-conditions/list-terms-conditions.component';
import { CreateTermConditionComponent } from './features/system-settings/UI/templates/create-term-condition/create-term-condition.component';
import { EditTermConditionComponent } from './features/system-settings/UI/templates/edit-term-condition/edit-term-condition.component';
import { UserProfilePageComponent } from './features/users/UI/pages/user-profile-page/user-profile-page.component';
import { UserProfileTemplateComponent } from './features/users/UI/templates/user-profile-template/user-profile-template.component';
import { ChangePasswordTemplateComponent } from './features/users/UI/templates/change-password-template/change-password-template.component';
import { UserProfileResolver } from './features/users/services/user-profile.resolver';
import { SystemSettingsResolver } from './features/system-settings/services/system-settings.resolver';
import { UsersPage } from './features/users/UI/pages/users-page/users-page.component';
import { UsersResolver } from './features/users/services/users.resolver';
import { UsersListTemplateComponent } from './features/users/UI/templates/users-list-template/users-list-template.component';
import { CreateUserTemplateComponent } from './features/users/UI/templates/create-user-template/create-user-template.component';
import { EditUserTemplateComponent } from './features/users/UI/templates/edit-user-template/edit-user-template.component';
import { UserFullDetailsComponent } from './features/users/UI/templates/user-full-details/user-full-details.component';
import { EmailConfirmationTemplateComponent } from './features/users/UI/templates/email-confirmation-template/email-confirmation-template.component';
import { AddInvoiceComponent } from './features/invoices/UI/templates/add-invoice/add-invoice.component';
import { InvoiceDetailsComponent } from './features/invoices/UI/templates/invoice-details/invoice-details.component';
import { HomeComponent } from './features/home/home/home.component';
import { OpportunitiesListComponent } from './features/opportunities/UI/templates/opportunities-list/opportunities-list.component';
import { OpportunitiesResolver } from './features/opportunities/services/opportunities.resolver';
import { Roles } from './shared/enums/Roles.enum';
import { RoleGuard } from './shared/guards/role.guard';
import { CreateFinancialTransactionDonateComponent } from './features/financial-transactions/UI/templates/create-financial-transaction-donate/create-financial-transaction-donate.component';
import { MyInvestmentsResolver } from './features/my-investments/services/my-invesments.resolver';
import { MyClientsPageComponent } from './features/my-clients/UI/pages/my-clients-page/my-clients-page.component';
import { ClientDetailComponent } from './features/my-clients/UI/templates/client-detail/client-detail.component';
import { NewSignupComponent } from './features/authentication/UI/templates/new-signup/new-signup.component';
import { MySupliersPageComponent } from './features/my-supliers/UI/pages/my-supliers-page.component';
import { ListSuppliersComponent } from './features/my-supliers/UI/templates/list-suppliers/list-suppliers.component';
import { AddSupplierComponent } from './features/my-supliers/UI/templates/add-supplier/add-supplier.component';
import { SupplierDetailComponent } from './features/my-supliers/UI/templates/supplier-detail/supplier-detail.component';
import { RiskPendingEvaluationsPageComponent } from './features/risk-pending-evaluations/UI/pages/risk-pending-evaluations/risk-pending-evaluations-page.component';
import { ListEvaluationsComponent } from './features/risk-pending-evaluations/UI/templates/list-evaluations/list-evaluations.component';
import { EvaluationDetailComponent } from './features/risk-pending-evaluations/UI/templates/evaluation-detail/evaluation-detail.component';
import { ListOperationsComponent } from './features/operations/UI/templates/list-operations/list-operations.component';
import { OperationsPageComponent } from './features/operations/UI/pages/operations-page.component';
import { OperationsResolver } from './features/operations/services/operations.resolver';
import { OperationDetailComponent } from './features/operations/UI/templates/operation-detail/operation-detail.component';
import { InvoiceDetailLandingComponent } from './features/invoices/UI/templates/invoice-detail-landing/invoice-detail-landing.component';
import { CrossSellingComponent } from './shared/UI/pages/crossSelling/cross-selling.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'bank-accounts',
    component: BankAccountsPage,
    canActivate: [LoggedGuard, RoleGuard],
    data: { allowedRoles: [Roles.INVESTOR, Roles.CLIENT] },
    resolve: {
      bankAccounts: BankAccountsResolver,
      profile: UserProfileResolver,
    },
    children: [
      { path: '', component: ListBankAccountsTemplate },
      { path: 'create', component: CreateBankAccountsTemplate },
      //{ path: 'edit/:bankAccountId', component: EditBankAccountsTemplate },
    ],
  },
  {
    path: 'financial-transactions',
    component: FinancialTransactionsPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: {
      allowedRoles: [
        Roles.INVESTOR,
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.CLIENT_HAPPINESS,
      ],
    },
    resolve: {
      data: FinancialTransactionsResolver,
      profile: UserProfileResolver,
    },
    children: [
      { path: '', component: ListFinancialTransactionsTemplateComponent },
      {
        path: 'deposit',
        component: CreateFinancialTransactionDepositComponent,
        data: { type: 'deposit' },
      },
      {
        path: 'withdraw',
        component: CreateFinancialTransactionWithdrawComponent,
        data: { type: 'withdraw' },
      },
      {
        path: 'donate',
        component: CreateFinancialTransactionDonateComponent,
        data: { type: 'donate' },
      },
      {
        path: 'invest',
        component: CreateFinancialTransactionInvestComponent,
        data: { type: 'investment' },
      },
    ],
  },
  {
    path: 'operations',
    component: OperationsPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: {
      allowedRoles: [
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.CLIENT_HAPPINESS,
        Roles.RISK_ANALYST,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
      ],
    },
    resolve: { profile: UserProfileResolver, invoices: OperationsResolver },
    children: [
      { path: '', component: ListOperationsComponent },
      // { path: ':invoiceId', component: OperationDetailComponent },
    ],
  },
  {
    path: 'invoices',
    component: InvoicesPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: {
      allowedRoles: [
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.CLIENT,
        Roles.CLIENT_HAPPINESS,
        Roles.RISK_ANALYST,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
      ],
    },
    resolve: { invoices: InvoicesResolver, profile: UserProfileResolver },
    children: [
      { path: '', component: ListInvoicesComponent },
      { path: 'create', component: AddInvoiceComponent },
      { path: ':invoiceId', component: InvoiceDetailLandingComponent },
    ],
  },
  {
    path: 'opportunities',
    component: OpporunitiesPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: { allowedRoles: [Roles.INVESTOR] },
    resolve: { invoices: OpportunitiesResolver, profile: UserProfileResolver },
    children: [
      { path: '', component: OpportunitiesListComponent },
      //{ path: 'create', component: CreateInvoiceComponent },
      { path: ':invoiceId', component: InvoiceDetailsComponent },
      //{ path: 'invest/:id', component: InvestInInvoiceComponent }
    ],
  },
  {
    path: 'my-investments',
    component: InvestmentsPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: { allowedRoles: [Roles.INVESTOR] },
    resolve: {
      transactions: MyInvestmentsResolver,
      profile: UserProfileResolver,
    },
    children: [{ path: '', component: ListInvestmentsComponent }],
  },
  {
    path: 'system-settings',
    component: SystemSettingsPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: { allowedRoles: [Roles.ADMIN, Roles.SUPER_ADMIN] },
    resolve: { redundant: SystemSettingsResolver },
    children: [
      { path: '', component: SystemSettingsComponent },
      { path: 'terms-and-conditions', component: ListTermsConditionsComponent },
      {
        path: 'terms-and-conditions/create',
        component: CreateTermConditionComponent,
      },
      {
        path: 'terms-and-conditions/edit/:termsAndConditionsId',
        component: EditTermConditionComponent,
      },
    ],
  },
  {
    path: 'users-accounts',
    component: UsersPage,
    canActivate: [LoggedGuard, RoleGuard],
    data: { allowedRoles: [Roles.ADMIN, Roles.SUPER_ADMIN] },
    //resolve: { redundant: UsersResolver },
    children: [
      { path: '', component: UsersListTemplateComponent },
      { path: ':userId', component: UserFullDetailsComponent },
      { path: 'create', component: CreateUserTemplateComponent },
      { path: 'edit/:userId', component: EditUserTemplateComponent },
    ],
  },
  {
    path: 'profile-settings',
    component: UserProfilePageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: {
      allowedRoles: [
        Roles.INVESTOR,
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.CLIENT,
        Roles.RISK_ANALYST,
        Roles.CLIENT_HAPPINESS,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
      ],
    },
    resolve: { profile: UserProfileResolver },
    children: [
      { path: '', component: UserProfileTemplateComponent },
      { path: 'change-password', component: ChangePasswordTemplateComponent },
    ],
  },
  {
    path: 'my-clients',
    component: MyClientsPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: { allowedRoles: [Roles.CLIENT] },
    // resolve: { profile: UserProfileResolver },
    resolve: { invoices: InvoicesResolver, profile: UserProfileResolver },
    children: [
      { path: '', component: ListClientsComponent },
      { path: 'create', component: AddClientComponent },
      { path: ':debtorId', component: ClientDetailComponent },
    ],
  },
  {
    path: 'risk-pending-evaluations',
    component: RiskPendingEvaluationsPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: {
      allowedRoles: [
        Roles.RISK_ANALYST,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
        Roles.ADMIN,
        Roles.SUPER_ADMIN,
        Roles.CLIENT_HAPPINESS,
      ],
    },
    resolve: { profile: UserProfileResolver },
    children: [
      { path: '', component: ListEvaluationsComponent },
      { path: ':evaluationId', component: EvaluationDetailComponent },
    ],
  },
  {
    path: 'risk-debtors',
    component: RiskDebtorsPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: {
      allowedRoles: [
        Roles.RISK_ANALYST,
        Roles.ACCOUNT_MANGER,
        Roles.ACCOUNT_MANAGER_SUPERVISOR,
      ],
    },
    resolve: { profile: UserProfileResolver },
    children: [
      { path: '', component: ListRiskDebtorTemplateComponent },
      { path: ':debtorId', component: RiskDebtorDetailComponent },
    ],
  },
  {
    path: 'my-suppliers',
    component: MySupliersPageComponent,
    canActivate: [LoggedGuard, RoleGuard],
    data: { allowedRoles: [Roles.CLIENT] },
    // resolve: { profile: UserProfileResolver },
    resolve: { invoices: InvoicesResolver, profile: UserProfileResolver },
    children: [
      { path: '', component: ListSuppliersComponent },
      { path: 'create', component: AddSupplierComponent },
      { path: ':supplierId', component: SupplierDetailComponent },
    ],
  },
  {
    path: 'auth',
    component: AuthPage,
    canActivate: [AnonymousGuard],
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
    ],
  },
  {
    path: 'signup',
    component: SignupPageComponent,
    canActivate: [AnonymousGuard],
    children: [
      { path: '', component: NewSignupComponent },
      // { path: '', component: RoleSelectorComponent },
      { path: 'investor', redirectTo: '/signup?userType=investor' },
      { path: 'client', redirectTo: '/signup?userType=client' },
      {
        path: 'confirm-email/:token',
        component: EmailConfirmationTemplateComponent,
      },
    ],
  },

  // V2-redirections
  {
    path: 'frontend',
    children: [
      { path: '', redirectTo: '/auth', pathMatch: 'full' },
      { path: 'loading', redirectTo: '/auth', pathMatch: 'full' },
      {
        path: 'oportunidades',
        redirectTo: '/opportunities',
        pathMatch: 'full',
      },
      { path: 'inversiones', redirectTo: '/my-investments', pathMatch: 'full' },
      { path: 'login', redirectTo: '/auth', pathMatch: 'full' },
      {
        path: 'depositar-dinero',
        redirectTo: '/financial-transactions',
        pathMatch: 'full',
      },
      {
        path: 'cuentas-bancarias',
        redirectTo: '/bank-accounts',
        pathMatch: 'full',
      },
    ],
  },
  { path: 'activacion', redirectTo: '/auth', pathMatch: 'full' },
  { path: 'cerrarSesion', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'cliente/crearCuenta',
    redirectTo: '/signup/client',
    pathMatch: 'full',
  },
  {
    path: 'inversionista/crearCuenta',
    redirectTo: '/signup/investor',
    pathMatch: 'full',
  },
  { path: 'cuentaAprobacionPendiente', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'cliente/facturas', redirectTo: 'invoices', pathMatch: 'full' },
  { path: 'administrador', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'crossSelling/:ruc', component: CrossSellingComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
