<span class="error-label">{{errorMessage}}</span>

<form [formGroup]="form">
    <app-select formControlName="idType" (OnSelectionChanged)="idTypeChanged($event)" [defaultOption]="default?.idType || 'national id'" [options]="idOptions" valueField="value" textField="text" label="Tipo de documento"></app-select>
    <app-input formControlName="idNumber" label="Número de documento" [default]="default?.idNumber"></app-input>

    <ng-container *ngIf="role === roles.INVESTOR && (selectedIdType !== 'ruc')">
        <span  class="size-and-format-warning">Sube una imagen de tu documento de identidad. Solo se aceptan archivos en formato jpg, jpeg, png y pdf que no sean mayores a 5MB.</span>

        <app-upload *ngIf="selectedIdType === 'passport'" formControlName="idFrontPicture" [default]="default?.idFrontPicture" initialLabel="Subir documento" loadedLabel="Documento cargado"></app-upload>
        <app-upload *ngIf="selectedIdType !== 'passport'" formControlName="idFrontPicture" [default]="default?.idFrontPicture" initialLabel="Subir parte delantera" loadedLabel="Parte delantera cargada"></app-upload>
        <app-upload *ngIf="selectedIdType !== 'passport'" formControlName="idBackPicture" [default]="default?.idBackPicture" initialLabel="Subir parte trasera" loadedLabel="Parte trasera cargada"></app-upload>
    
    </ng-container>
    
</form>
