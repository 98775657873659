<ng-container *ngIf="_invoiceGroups.length > 0">
  <div class="u-mt-6">
    <lib-banner-detail *ngIf="_invoiceErrors.length > 0" [invoiceErrorsArr]="_invoiceErrors"
      (removeBannerEv)="removeBanner()"></lib-banner-detail>
  </div>

  <div class="u-mt-4">
    <ng-container *ngFor="let item of _invoiceGroups; let index = index">
      <div class="u-mb-5">
        <lib-table-group-by-invoice [isConfirming]="_operationType === 'confirming' ? true : false"
          [invoiceGroup]="item" [indexTable]="index" (removeTable)="removeArray($event)"
          (isValidTable)="validationGroups($event)"></lib-table-group-by-invoice>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="
    _invoiceErrors.length > 0 &&
    _invoiceGroups.length === 0
  ">
  <div class="u-mt-6">
    <lib-error-state (goBackEv)="goBack()" [invoiceErrorsArr]="_invoiceErrors"></lib-error-state>
  </div>
</ng-container>