import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientService } from './../../../services/client.service';
import { BankAccountsService } from '../../../../bank-accounts/services/bank-accounts.service';
import { UsersService } from 'src/app/features/users/services/users.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppState } from 'src/app/app.states';
import { ShortenPipe } from 'src/app/shared/pipes/shorten.pipe';
import { Store } from '@ngrx/store';
import { CurrencyNamePipe } from 'src/app/shared/pipes/currency-name.pipe';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';

@Component({
  selector: 'app-client-invoice',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent {
  companyInfo: any;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private shortenPipe: ShortenPipe,
    private currencyNamePipe: CurrencyNamePipe,
    private overlayService: OverlayService,
    private bankAccountService: BankAccountsService,
    private clientService: ClientService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private gtmService: GTMService,
    private windowScrollService: WindowScrollService

  ) {}

  setCompanyInfo(companyInfo) {
    this.companyInfo = companyInfo;
    this.sendEventPageView()
  }

  sendEventPageView(){
    this.windowScrollService.sendNewEvent('My Clients', `New Step 2`)
  }

  saveClient() {
    if (this.companyInfo) {
      this.spinner.show();

      this.clientService.createDebtor(this.companyInfo).subscribe((debtor) => {
        this.spinner.hide();
        if (debtor) {
          this.sendEvent()
          this.router.navigateByUrl('my-clients');
        }
      });
    }
  }

  sendEvent() {
    let event = {
      event: '[Platform][Clients][Create]',
    };
    this.gtmService.newEvent(event)
  }
}
