<ng-container *ngIf="selectedInvoice as invoice">
  <ng-container>
    <div class="header">
      <div class="left">
        <div class="container-flex mb-15 aligment">
          <div class="container-flex">
            <lib-chip-status [status]="invoice.status"></lib-chip-status>
            <lib-scheduled-invoice class="sunat" [status]="invoice?.status" [sale]="invoice?.onSale" [entity]="invoice">
            </lib-scheduled-invoice>
          </div>
          <div class="sunat">
            <ng-container>
              <lib-chip *ngIf="invoice && invoice.isSunatProcess" content="Proceso vía SUNAT"></lib-chip>
              <lib-chip *ngIf="invoice && !invoice.isSunatProcess" content="Proceso vía CAVALI">
              </lib-chip>
            </ng-container>
          </div>
        </div>
        <div class="title-amount">
          <lib-money [currency]="invoice?.currency || 'pen'" [amount]="invoice?.netAmount || '0.00'">
          </lib-money>
        </div>
        <div class="bank-and-currency row">
          <lib-operation-type [operationType]="invoice?.isConfirming"></lib-operation-type>
        </div>

        <ng-container>
          <div class="container-flex">
            <div (click)="gotoSlack(invoice)" *ngIf="invoice.slackTimestamp"
              class="bank-and-currency container-flex c-pointer slack-container">
              <img src="../../../../../../assets/icons/slack-icon.svg" alt="" />
              <span class="ml-8 slack-button">Ver en Slack</span>
            </div>
            <ng-container *allowedRoles="[
                'account manager',
                'account manager supervisor',
                'risk analyst'
              ]">
              <div (click)="gotoPipedrive(invoice)" *ngIf="invoice.pipedriveDirectUrl"
                class="bank-and-currency container-flex c-pointer pipe-container">
                <img class="icon-pipe" src="../../../../../../assets/icons/pipe-icon.svg" alt="" />
                <span class="ml-8 slack-button">Ver en Pipedrive</span>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container>
          <div *ngIf="invoice.checklist && invoice?.checklist?.length > 0" style="display: flex">
            <span style="font-weight: 400">Checklist:</span>
            <div class="checklist">
              <div style="display: flex">
                <div class="checklist-group">
                  <lib-checklist-item [isActionable]="true" (open)="openCheckModal($event)" [isException]="
                      hasException(getCheck(invoice.checklist, 'documents'))
                    " [entity]="getCheck(invoice.checklist, 'documents')"></lib-checklist-item>
                  <lib-checklist-item class="check-small-screen" [isActionable]="false" (open)="openCheckModal($event)"
                    [isException]="false" [entity]="getCheck(invoice.checklist, 'conditions')">
                  </lib-checklist-item>
                </div>
                <div class="checklist-group">
                  <lib-checklist-item [isActionable]="true" (open)="openCheckModal($event)" [isException]="
                      hasException(getCheck(invoice.checklist, 'risk'))
                    " [entity]="getCheck(invoice.checklist, 'risk')"></lib-checklist-item>
                  <lib-checklist-item class="check-small-screen" [isActionable]="false" (open)="openCheckModal($event)"
                    [isException]="false" [entity]="getCheck(invoice.checklist, 'tea')"></lib-checklist-item>
                </div>
                <div class="checklist-group">
                  <lib-checklist-item [isActionable]="true" (open)="openCheckModal($event)" [isException]="
                      hasException(getCheck(invoice.checklist, 'cavali'))
                    " [entity]="getCheck(invoice.checklist, 'cavali')"></lib-checklist-item>
                  <lib-checklist-item class="check-small-screen" *ngIf="invoice.isConfirming" [isActionable]="false"
                    (open)="openCheckModal($event)" [isException]="false"
                    [entity]="getCheck(invoice.checklist, 'warranty')"></lib-checklist-item>
                </div>
              </div>
              <div class="checklist-section2">
                <lib-checklist-item [isActionable]="false" (open)="openCheckModal($event)" [isException]="false"
                  [entity]="getCheck(invoice.checklist, 'conditions')">
                </lib-checklist-item>
                <lib-checklist-item [isActionable]="false" (open)="openCheckModal($event)" [isException]="false"
                  [entity]="getCheck(invoice.checklist, 'tea')">
                </lib-checklist-item>
                <lib-checklist-item *ngIf="invoice.isConfirming" [isActionable]="false" (open)="openCheckModal($event)"
                  [isException]="false" [entity]="getCheck(invoice.checklist, 'warranty')"></lib-checklist-item>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container>
        <div *allowedRoles="[
            'account manager',
            'account manager supervisor',
            'admin',
            'super admin',
            'client happiness'
          ]">
          <div class="available-action">
            <ng-container *allowedRoles="['account manager supervisor']">
              <span *ngIf="invoice?.opsReady" (click)="sendDesembolsoEmail(invoice)" class="action c-pointer"
                (mouseenter)="mouseEnterDesembolso()" (mouseleave)="mouseLeaveDesembolso()">
                <span [ngClass]="getValidationInvoice(invoice)">{{ emailTitle }}</span>
                <lib-icon size="m" icon="mail"
                  [color]="getValidationInvoice(invoice).includes('red-filter') ? 'red' : 'black'"></lib-icon>
              </span>
            </ng-container>
           
            <ng-container *ngIf="displayBubbleDesembolso && abbleToDisplay">
              <div [ngClass]="[emailTitle === 'Correo de desembolso' ? 'bubble-email' : 'bubble-email-resent', 'sb1']">
                <div>
                  <p class="title-bubble u-mb-3 text-color-dark-base">
                    Debes corregir estos puntos:
                  </p>
                  <ul class="blocker-bubble">
                    <ng-container *ngFor="let blocker of blockersInvoiceForSale">
                      <li *ngIf="blocker.value" class="u-mb-3">
                        <p class="body-paragraph-xs-light text-color-dark-base">
                          {{ blocker.msg }}
                        </p>
                      </li>
                    </ng-container>
                  </ul>
                  <br />
                </div>
              </div>
            </ng-container>
            <ng-container *allowedRoles="[
                'admin',
                'super admin',
                'account manager',
                'account manager supervisor'
              ]">
              <span (click)="revertConfiguration()" class="action c-pointer" *ngIf="
                  invoice.status === 'configured' ||
                  invoice.status === 'pending confirmation'
                ">
                <span class="title">Revertir Configuración</span>
                <img src="../../../../../../assets/icons/final/redo-arrow.png" />
              </span>
              <span (click)="
                  configureInvoice(
                    invoice.tdmPercentage,
                    invoice.reservationPercentage,
                    invoice.tea
                  )
                " class="action c-pointer" *ngIf="invoice.status === 'approved'">
                <span class="title">Configurar</span>
                <img src="../../../../../../assets/icons/final/settings.svg" />
              </span>
            </ng-container>
            <ng-container *allowedRoles="['client happiness']">
              <span (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (click)="putInvoiceForSale(invoice)"
                class="action-sale action" *ngIf="invoice.status === 'pending confirmation' && invoice.opsReady">
                <span [ngClass]="getValidationInvoiceForSale(invoice)">Poner en venta</span>
                <img [ngClass]="iconClassInvoice" src="../../../../../../assets/icons/shopping_cart.svg" />
              </span>
              <span *ngIf="invoice.status === 'pending confirmation'" [ngClass]="getValidationSplitInvoice() ? 'c-pointer' : 'title red-filter .no-cursor'" (click)="openInvoiceSplit()"
                    class="action-sale action" >
                    <span class="title">Partir factura</span>
                    <img [ngClass]="getValidationSplitInvoice() ? 'c-pointer' : 'red-filter .no-cursor'" src="../../../../../../assets/icons/cut.svg" />
              </span>
              <ng-container *ngIf="displayBubble && abbleToDisplay">
                <div class="bubble sb1">
                  <div>
                    <p class="title-bubble u-mb-3 text-color-dark-base">
                      Debes corregir estos puntos:
                    </p>
                    <ul class="blocker-bubble">
                      <ng-container *ngFor="let blocker of blockersInvoiceForSale">
                        <li *ngIf="blocker.value" class="u-mb-3">
                          <p class="body-paragraph-xs-light text-color-dark-base">
                            {{ blocker.msg }}
                          </p>
                        </li>
                      </ng-container>
                    </ul>
                    <br />
                  </div>
                </div>
              </ng-container>

              <span (click)="cancelSale()" class="action c-pointer" *ngIf="
                  invoice.status === 'for sale' ||
                  invoice.status === 'partially sold' ||
                  invoice.status === 'sale closed'
                ">
                <span class="title">Cancelar venta</span>
                <img src="../../../../../../assets/icons/final/redo-arrow.png" />
              </span>
            </ng-container>
            <ng-container *allowedRoles="['admin', 'super admin']">
              <div class="available-action">
                <span (click)="approveInvoice()" class="action c-pointer" *ngIf="
                    invoice.status === 'in process' ||
                    invoice.status === 'disapproved'
                  ">
                  <span class="title">Aprobar</span>
                  <img src="../../../../../../assets/icons/final/approve.png" />
                </span>
                <span (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (click)="putInvoiceForSale(invoice)"
                  class="action-sale action" *ngIf="invoice.status === 'pending confirmation'">
                  <span [ngClass]="getValidationInvoiceForSale(invoice)">Poner en venta</span>
                  <img [ngClass]="iconClassInvoice" src="../../../../../../assets/icons/shopping_cart.svg" />
                </span>
                <ng-container *ngIf="displayBubble && abbleToDisplay">
                  <div class="bubble sb1">
                    <div>
                      <p class="title-bubble u-mb-3 text-color-dark-base">
                        Debes corregir estos puntos:
                      </p>
                      <ul class="blocker-bubble">
                        <ng-container *ngFor="let blocker of blockersInvoiceForSale">
                          <li *ngIf="blocker.value" class="u-mb-3">
                            <p class="body-paragraph-xs-light text-color-dark-base">
                              {{ blocker.msg }}
                            </p>
                          </li>
                        </ng-container>
                      </ul>
                      <br />
                    </div>
                  </div>
                </ng-container>
                <span (click)="navigateToInvest(invoice)" class="action c-pointer" *ngIf="
                    invoice.status === 'pending confirmation' ||
                    invoice.status === 'for sale' ||
                    invoice.status === 'partially sold'
                  ">
                  <span class="title">Nueva inversión</span>
                  <img src="../../../../../../assets/icons/investments.svg" />
                </span>

                <ng-container *allowedRoles="['admin', 'super admin']">
                  <span *ngIf="invoice.status === 'pending confirmation'" [ngClass]="getValidationSplitInvoice() ? 'c-pointer' : 'title red-filter .no-cursor'" (click)="openInvoiceSplit()"
                    class="action-sale action" >
                    <span class="title">Partir factura</span>
                    <img [ngClass]="getValidationSplitInvoice() ? 'c-pointer' : 'red-filter .no-cursor'" src="../../../../../../assets/icons/cut.svg" />
                  </span>
                  <!-- <ng-container *ngIf="displayBubble && abbleToDisplay">
                    <div class="bubble sb1">
                      <div>
                        <p class="title-bubble u-mb-3 text-color-dark-base">
                          Debes corregir estos puntos:
                        </p>
                        <ul class="blocker-bubble">
                          <ng-container *ngFor="let blocker of blockersInvoiceForSale">
                            <li *ngIf="blocker.value" class="u-mb-3">
                              <p class="body-paragraph-xs-light text-color-dark-base">
                                {{ blocker.msg }}
                              </p>
                            </li>
                          </ng-container>
                        </ul>
                        <br />
                      </div>
                    </div>
                  </ng-container> -->
                </ng-container>

                <span (click)="cancelSale()" class="action c-pointer" *ngIf="
                    invoice.status === 'for sale' ||
                    invoice.status === 'partially sold' ||
                    invoice.status === 'sale closed'
                  ">
                  <span class="title">Cancelar venta</span>
                  <img src="../../../../../../assets/icons/final/redo-arrow.png" />
                </span>

                <ng-container *allowedRoles="['super admin']">
                  <span (click)="payAdvanceToClient(invoice)" class="action-pay action" *ngIf="
                      !invoice.advancePaymentDate &&
                      (invoice.status === 'for sale' ||
                        invoice.status === 'sale closed' ||
                        invoice.status === 'partially sold' ||
                        invoice.status === 'pending confirmation')
                    ">
                    <span [ngClass]="
                        getValidationTitle(invoice, 'advancePaymentDate')
                      ">Pagar Adelanto</span>
                    <img [ngClass]="
                        getValidationClass(invoice, 'advancePaymentDate')
                      " height="25px" width="25px" src="../../../../../../assets/icons/money-send.svg" />
                  </span>
                </ng-container>
                <ng-container *allowedRoles="['admin', 'super admin']">
                  <span (click)="closeSale(invoice)" class="action c-pointer" *ngIf="
                      invoice.advancePaymentDate &&
                      (invoice.status === 'for sale' ||
                        invoice.status === 'sale closed' ||
                        invoice.status === 'partially sold' ||
                        invoice.status === 'pending confirmation')
                    ">
                    <span class="title">Cerrar Venta</span>
                    <img height="25px" width="25px" src="../../../../../../assets/icons/final/clock.png" />
                  </span>
                </ng-container>
                <span (click)="collectInvoice(invoice.paymentDate)" class="action c-pointer"
                  *ngIf="invoice.status === 'awaiting collection'">
                  <span class="title">Cobrar</span>
                  <img src="../../../../../../assets/icons/final/coin-pile.png" />
                </span>
                <span (click)="partialPayment(invoice.paymentDate)" class="action c-pointer" *ngIf="
                    invoice.status === 'awaiting collection' &&
                    !invoice?.partialPaymentDate
                  ">
                  <span class="title">Partir operación</span>
                  <img src="../../../../../../assets/icons/final/partial-payment.svg" />
                </span>

                <span (click)="finalizeInvoice()" class="action c-pointer" *ngIf="invoice.status === 'collected'">
                  <span class="title">Finalizar</span>
                  <img src="../../../../../../assets/icons/final/double-check.png" />
                </span>
              </div> 
              

              <div  appClickOutside  (clickOutside)="hideDownload()" class="default-actions available-action download-option">
                <span (click)="downloadInvoice(invoice._id)" class="action c-pointer">
                  <span class="title">Descargar</span>
                  <img src="../../../../../../assets/icons/final/download-zip2.png" />
                </span>
                <ng-container *ngIf="displayDownload">
                  <div  class="bubble-download sb1">
                      <div  class="action-download">
                            <div (click)="downloadInvoiceFile(invoice._id)" class="c-pointer u-mb-3 d-flex flex-justify-between flex-items-center">
                              <span class="body-paragraph-xs-light text-color-dark-base">Facturas en XML
                              </span>
                              <img src="../../../../../../assets/icons/file-download.svg" />
                            </div>
                            <div (click)="downloadFormalDocument('transfer_rights', invoice._id)" class="c-pointer  d-flex flex-justify-between flex-items-center">
                              <span class="body-paragraph-xs-light text-color-dark-base">Carta cesión de derechos</span>
                              <img src="../../../../../../assets/icons/file-download.svg" />
                            </div>
                      </div>
                  </div>
                </ng-container>
              </div>
              <div class="available-action">
                <span (click)="rejectInvoice()" *ngIf="
                    invoice.status === 'collected' ||
                    invoice.status === 'finalized' ||
                    invoice.status === 'awaiting collection' ||
                    invoice.status === 'approved' ||
                    invoice.status === 'partially sold' ||
                    invoice.status === 'sale closed' ||
                    invoice.status === 'for sale' ||
                    invoice.status === 'configured' ||
                    invoice.status === 'pending confirmation' ||
                    invoice.status === 'in process'
                  " class="action c-pointer">
                  <span class="title">Desaprobar</span>
                  <img class="disapprove-icon" src="../../../../../../assets/icons/final/disapprove.png" />
                </span>
              </div>
             
            </ng-container>

            <ng-container *allowedRoles="['client happiness']">
              <span (click)="navigateToInvest(invoice)" class="action c-pointer" *ngIf="
                  invoice.status === 'pending confirmation' ||
                  invoice.status === 'for sale' ||
                  invoice.status === 'partially sold'
                ">
                <span class="title">Nueva inversión</span>
                <img src="../../../../../../assets/icons/investments.svg" />
              </span>
            </ng-container>
        
          </div>
        </div>
      </ng-container>
     
    </div>
    <ng-container *allowedRoles="['client happiness']">
      <ng-container *ngIf="
          invoice.status === 'collected' ||
          invoice.status === 'awaiting collection' ||
          invoice.status === 'finalized'
        ">
        <lib-collection-days-bar class="investment-progress-ch" [toBeCollectedIn]="invoice?.toBeCollectedIn"
          [mode]="mode" [entity]="invoice">
        </lib-collection-days-bar>
      </ng-container>
    </ng-container>

    <ng-container *allowedRoles="['admin', 'super admin']">
      <ng-container *ngIf="
          invoice.status === 'for sale' ||
          invoice.status === 'partially sold' ||
          invoice.status === 'sale closed' ||
          invoice.status === 'pending confirmation' ||
          invoice.status === 'collected' ||
          invoice.status === 'awaiting collection' ||
          invoice.status === 'finalized'
        ">
        <app-progress-bar [ngClass]="
            invoice.status === 'pending confirmation'
              ? 'investment-progress'
              : 'investment-progress-ch'
          " [progress]="100 - invoice.availableBalancePercentage">
        </app-progress-bar>
        <p class="investment-progress-label">
          Invertido
          {{ getInvestedPercentage(invoice.availableBalancePercentage) }}%
        </p>
      </ng-container>
    </ng-container>
   
    <ng-container *allowedRoles="['client happiness']">
      <ng-container *ngIf="
          invoice.status === 'sale closed' ||
          invoice.status === 'for sale' ||
          invoice.status === 'partially sold' ||
          invoice.status === 'pending confirmation'
        ">
        <app-progress-bar class="investment-progress-ch" [progress]="100 - invoice.availableBalancePercentage">
        </app-progress-bar>
        <p class="investment-progress-label">
          Invertido
          {{ getInvestedPercentage(invoice.availableBalancePercentage) }}%
        </p>
      </ng-container>
    </ng-container>
    <hr />

    <ng-container [ngSwitch]="loggedInUserRole">
      <ng-container *ngSwitchCase="
          ['account manager',
            'account manager supervisor'].includes(loggedInUserRole)
            ? loggedInUserRole
            : !loggedInUserRole
        ">
        <lib-tab-operation-detail [viewerRole]="loggedInUserRole" (selectedTab)="updateTabSelected($event)" [displayPartial]="true"
          [selected]="selected">
          <div class="operation-detail-account">
            <lib-operation-detail [role]="loggedInUserRole" [invoice]="invoice" [lastConfiguration]="lastConfiguration">
            </lib-operation-detail>
          </div>
          <div class="risk-detail-account">
            <lib-risk-detail [role]="loggedInUserRole" [debtor]="debtor" (editContacts)="editContacts($event)"
              [newEvaluationLoading]="newEvaluationLoading" (editNote)="editNote($event)"
              (observePayer)="observePayer($event)" (deleteNote)="deleteNote($event)"
              (editTelegram)="editTelegram($event)" (saveSector)="saveSector($event)"
              (saveActivity)="saveActivity($event)" (saveRiskLevel)="saveRiskLevel($event)"
              (evaluationDetail)="evaluationDetail($event)" (newEvaluation)="newEvaluation($event)">
            </lib-risk-detail>
          </div>

          <div class="mt-22 email-detail-account email-detail-complete">
            <ng-container>
              <div class="email-detail-account section-email-sent">
                <div class="one-line">
                  <div>
                    <p class="title-email-p">Correos enviados</p>
                  </div>
                </div>

                <ng-container *ngIf="invoice.emailSent.length > 0; else defaultSent">
                  <ng-container *ngFor="let item of invoice.emailSent">
                    <app-email-row-operation  [role]="loggedInUserRole" [title]="item.title" [blockersInvoiceForSale]="blockersInvoiceForSale"
                      [subject]="item.subject" [sendTo]="item.to" [cc]="item.cc" [count]="item.count" [type]="item.type"
                      [isExternal]="item.isExternal" [checklist]="invoice.checklist" [status]="item.status"
                      [detail]="item.detail" (senEmail)="send($event, 'true')">
                    </app-email-row-operation>
                  </ng-container>
                </ng-container>
                <ng-template #defaultSent>
                  <div class="container-disclaimer">
                    <p class="disclaimer">No hay correos enviados.</p>
                  </div>
                </ng-template>
              </div>

              <div class="section-email-ready">
                <div class="one-line">
                  <div>
                    <p class="title-email-p">Correos listo para enviar</p>
                  </div>
                </div>
                <ng-container *ngIf="invoice.emailReady.length > 0; else default">
                  <ng-container *ngFor="let item of invoice.emailReady">
                    <app-email-row-operation [role]="loggedInUserRole" [title]="item.title" [blockersInvoiceForSale]="blockersInvoiceForSale"
                      [subject]="item.subject" [sendTo]="item.to" [cc]="item.cc" [type]="item.type"
                      [checklist]="invoice.checklist" [isExternal]="item.isExternal" [status]="item.status"
                      (senEmail)="send($event)">
                    </app-email-row-operation>
                  </ng-container>
                </ng-container>
                <ng-template #default>
                  <div class="container-disclaimer">
                    <p class="disclaimer">No hay correos listos para enviar.</p>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </div>

          <div class=""></div>
          <div class="contacts-detail-account">
            <div class="contact-group">
              <lib-contact-group [rejectedReason]="'testing'" (validateContactEv)="validate($event)"
                (addContacts)="addContact($event)" (editContactEvt)="editValidContacts($event)"
                [validEmailTypes]="!collectionEmailReady" [companyHeader]="'Empresa pagadora'"
                [companyName]="invoice?.debtor?.companyName" [viewerRole]="loggedInUserRole"
                [companyRuc]="invoice?.debtor?.companyRuc" [contacts]="contacts?.debtorContacts">
              </lib-contact-group>
            </div>
            <div class="contact-group">
              <lib-contact-group l [companyHeader]="'Proveedor'" [companyName]="
                  invoice?.isConfirming
                    ? invoice?.supplier?.companyName
                    : invoice?.issuer?.companyName
                " [companyRuc]="
                  invoice?.isConfirming
                    ? invoice?.supplier?.companyRuc
                    : invoice?.issuer?.companyRuc
                " [contacts]="contacts?.supplierContacts">
              </lib-contact-group>
            </div>
          </div>
          <div class="observations-detail-account">
            <lib-observations-detail [headers]="headersObs" [entity]="userObservations" [invoice]="invoice">
            </lib-observations-detail>
          </div>
        </lib-tab-operation-detail>
      </ng-container>

      <ng-container *ngSwitchCase="
          [
            'risk analyst'
          ].includes(loggedInUserRole)
            ? loggedInUserRole
            : !loggedInUserRole
        ">
        <lib-tab-operation-detail (selectedTab)="updateTabSelected($event)" [displayPartial]="true"
          [selected]="selected">
          <div class="operation-detail">
            <lib-operation-detail [role]="loggedInUserRole" [invoice]="invoice" [lastConfiguration]="lastConfiguration">
            </lib-operation-detail>
          </div>
          <div class="risk-detail">
            <lib-risk-detail [role]="loggedInUserRole" [debtor]="debtor" (editContacts)="editContacts($event)"
              [newEvaluationLoading]="newEvaluationLoading" (editNote)="editNote($event)"
              (observePayer)="observePayer($event)" (deleteNote)="deleteNote($event)"
              (editTelegram)="editTelegram($event)" (saveSector)="saveSector($event)"
              (saveActivity)="saveActivity($event)" (saveRiskLevel)="saveRiskLevel($event)"
              (evaluationDetail)="evaluationDetail($event)" (newEvaluation)="newEvaluation($event)">
            </lib-risk-detail>
          </div>
          <div class=""></div>
          <div class="contacts-detail">
            <div class="contact-group">
              <lib-contact-group [rejectedReason]="'testing'" (validateContactEv)="validate($event)"
                (addContacts)="addContact($event)" (editContactEvt)="editValidContacts($event)"
                [validEmailTypes]="!collectionEmailReady" [companyHeader]="'Empresa pagadora'"
                [companyName]="invoice?.debtor?.companyName" [viewerRole]="loggedInUserRole"
                [companyRuc]="invoice?.debtor?.companyRuc" [contacts]="contacts?.debtorContacts">
              </lib-contact-group>
            </div>
            <div class="contact-group">
              <lib-contact-group l [companyHeader]="'Proveedor'" [companyName]="
                  invoice?.isConfirming
                    ? invoice?.supplier?.companyName
                    : invoice?.issuer?.companyName
                " [companyRuc]="
                  invoice?.isConfirming
                    ? invoice?.supplier?.companyRuc
                    : invoice?.issuer?.companyRuc
                " [contacts]="contacts?.supplierContacts">
              </lib-contact-group>
            </div>
          </div>
          <div class="observations-detail">
            <lib-observations-detail [headers]="headersObs" [entity]="userObservations" [invoice]="invoice">
            </lib-observations-detail>
          </div>
        </lib-tab-operation-detail>
      </ng-container>

      <ng-container *ngSwitchCase="
          ['admin', 'super admin'].includes(loggedInUserRole)
            ? loggedInUserRole
            : !loggedInUserRole
        ">
        <lib-tab-operation-detail [viewerRole]="loggedInUserRole" (selectedTab)="updateTabSelected($event)"
          [displayPartial]="true" [selected]="selected">
          <div class="mt-22 operation-detail-admin">
            <lib-operation-detail [role]="loggedInUserRole" [invoice]="invoice" (event)="operationDetailEvent($event)"
              [lastConfiguration]="lastConfiguration" [userBankAccounts]="userBankAccounts"
              [supplierBankAccounts]="supplierBankAccounts">
            </lib-operation-detail>
            <input hidden type="file" #creditNoteFile (change)="uploadCreditNote($event.target.files)" accept="text/xml"
              multiple />
          </div>
          <div class="mt-22 risk-detail-admin">
            <lib-risk-detail [role]="loggedInUserRole" [debtor]="debtor" (editContacts)="editContacts($event)"
              [newEvaluationLoading]="newEvaluationLoading" (editNote)="editNote($event)"
              (observePayer)="observePayer($event)" (deleteNote)="deleteNote($event)"
              (editTelegram)="editTelegram($event)" (saveSector)="saveSector($event)"
              (saveActivity)="saveActivity($event)" (saveRiskLevel)="saveRiskLevel($event)"
              (evaluationDetail)="evaluationDetail($event)" (newEvaluation)="newEvaluation($event)">
            </lib-risk-detail>
          </div>
          <div class="mt-22 email-detail-admin email-detail-complete">
            <ng-container>
              <div class="email-detail-admin section-email-sent">
                <div class="one-line">
                  <div>
                    <p class="title-email-p">Correos enviados</p>
                  </div>
                </div>

                <ng-container *ngIf="invoice.emailSent.length > 0; else defaultSent">
                  <ng-container *ngFor="let item of invoice.emailSent">
                    <app-email-row-operation [title]="item.title" [blockersInvoiceForSale]="blockersInvoiceForSale"
                      [subject]="item.subject" [sendTo]="item.to" [cc]="item.cc" [count]="item.count" [type]="item.type"
                      [isExternal]="item.isExternal" [checklist]="invoice.checklist" [status]="item.status"
                      [detail]="item.detail" (senEmail)="send($event, 'true')">
                    </app-email-row-operation>
                  </ng-container>
                </ng-container>
                <ng-template #defaultSent>
                  <div class="container-disclaimer">
                    <p class="disclaimer">No hay correos enviados.</p>
                  </div>
                </ng-template>
              </div>

              <div class="section-email-ready">
                <div class="one-line">
                  <div>
                    <p class="title-email-p">Correos listo para enviar</p>
                  </div>
                </div>
                <ng-container *ngIf="invoice.emailReady.length > 0; else default">
                  <ng-container *ngFor="let item of invoice.emailReady">
                    <app-email-row-operation [title]="item.title" [blockersInvoiceForSale]="blockersInvoiceForSale"
                      [subject]="item.subject" [sendTo]="item.to" [cc]="item.cc" [type]="item.type"
                      [checklist]="invoice.checklist" [isExternal]="item.isExternal" [status]="item.status"
                      (senEmail)="send($event)">
                    </app-email-row-operation>
                  </ng-container>
                </ng-container>
                <ng-template #default>
                  <div class="container-disclaimer">
                    <p class="disclaimer">No hay correos listos para enviar.</p>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </div>
          <div class="mt-22 observations-detail-admin">
            <lib-observations-detail [headers]="headersObs" [entity]="userObservations" [invoice]="invoice"
              [isChangeable]="true" (toggleChanged)="activeObservation($event)" (checkboxChanged)="setIGV($event)"
              (deleteClicked)="deleteObs($event)">
            </lib-observations-detail>
            <div class="new-observation-button button-container">
              <fs-ui-button class="remove-space" (click)="addObservation(invoice)" [label]="'Nueva observación'">
              </fs-ui-button>
            </div>
          </div>
          <div class="mt-22 contacts-detail-admin">
            <!-- <lib-text size="s" content="Próximamente verás los contactos acá"></lib-text> -->

            <div class="contact-group">
              <lib-contact-group (validateContactEv)="validate($event)" (addContacts)="addContact($event)"
                [companyHeader]="'Empresa pagadora'" [companyName]="invoice?.debtor?.companyName"
                [viewerRole]="loggedInUserRole" [companyRuc]="invoice?.debtor?.companyRuc"
                [contacts]="contacts?.debtorContacts">
              </lib-contact-group>
            </div>
            <div class="contact-group">
              <lib-contact-group l [companyHeader]="'Proveedor'" [companyName]="
                  invoice?.isConfirming
                    ? invoice?.supplier?.companyName
                    : invoice?.issuer?.companyName
                " [companyRuc]="
                  invoice?.isConfirming
                    ? invoice?.supplier?.companyRuc
                    : invoice?.issuer?.companyRuc
                " [contacts]="contacts?.supplierContacts">
              </lib-contact-group>
            </div>
          </div>

          <div class="mt-22 investors-detail-admin">
            <div class="investors-detail-ch">
              <div class="investors">
                <div class="row container-transaction">
                  <div>
                    <h6>
                      Detalle de inversionistas ({{ investments?.length || 0 }})
                    </h6>
                  </div>
                  <div *ngIf="investments?.length > 0; else empty_table" class="code-table">
                    <table>
                      <tr>
                        <th>Inversionista</th>
                        <th>Correo</th>
                        <th>Celular</th>
                        <th>Monto invertido</th>
                        <th>Distribución</th>
                      </tr>
                      <tr *ngFor="let invoice of investments">
                        <td>
                          <lib-text *ngIf="
                              (invoice?.names + invoice?.familyNames).length >
                              25
                            " [tooltipText]="
                              invoice?.names + ' ' + invoice?.familyNames
                                | capitalizeFirstLetters
                            " tooltip="true" tooltipSize="160" [content]="
                              invoice?.names + ' ' + invoice?.familyNames
                                | limitTo : '25'
                                | capitalizeFirstLetters
                            ">
                          </lib-text>

                          <lib-text *ngIf="
                              (invoice.names + invoice.familyNames).length < 26
                            " [content]="
                              invoice.names + ' ' + invoice.familyNames
                                | capitalizeFirstLetters
                            ">
                          </lib-text>
                        </td>
                        <td>
                          <lib-text *ngIf="invoice?.email.length > 35" [tooltipText]="invoice?.email" tooltip="true"
                            tooltipSize="260" [content]="invoice?.email | limitTo : '35'">
                          </lib-text>

                          <lib-text *ngIf="invoice.email.length < 36" [content]="invoice.email">
                          </lib-text>
                        </td>
                        <td>
                          <lib-text *ngIf="invoice?.phone.length > 15" [tooltipText]="invoice?.phone" tooltip="true"
                            tooltipSize="160" [content]="invoice?.phone | limitTo : '15'">
                          </lib-text>

                          <lib-text *ngIf="invoice.phone.length < 16" [content]="invoice.phone">
                          </lib-text>
                        </td>
                        <td>
                          <lib-text *ngIf="invoice?.amount.length > 15" [tooltipText]="invoice?.amount" tooltip="true"
                            tooltipSize="160" [content]="
                              invoice?.amount | numberSpacing | limitTo : '15'
                            ">
                          </lib-text>

                          <lib-text *ngIf="invoice.amount.length < 16" [content]="invoice.amount | numberSpacing">
                          </lib-text>
                        </td>

                        <td>
                          <lib-text [content]="invoice.distributionPercentage + '%'">
                          </lib-text>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </lib-tab-operation-detail>
      </ng-container>
      <ng-container *ngSwitchCase="'client happiness'">
        <lib-tab-operation-detail [viewerRole]="loggedInUserRole" (selectedTab)="updateTabSelected($event)"
          [displayPartial]="true" [selected]="selected">
          <div class="mt-22 operation-detail-ch">
            <lib-operation-detail [role]="loggedInUserRole" (event)="updateTea($event)" [invoice]="invoice"
              [lastConfiguration]="lastConfiguration">
            </lib-operation-detail>
          </div>
          <div class="risk-detail-ch">
            <lib-risk-detail [role]="loggedInUserRole" [debtor]="debtor" (editContacts)="editContacts($event)"
              [newEvaluationLoading]="newEvaluationLoading" (editNote)="editNote($event)"
              (observePayer)="observePayer($event)" (deleteNote)="deleteNote($event)"
              (editTelegram)="editTelegram($event)" (saveSector)="saveSector($event)"
              (saveActivity)="saveActivity($event)" (saveRiskLevel)="saveRiskLevel($event)"
              (evaluationDetail)="evaluationDetail($event)" (newEvaluation)="newEvaluation($event)">
            </lib-risk-detail>
          </div>
          <div class="announcements-detail-ch">
            <div class="annoucement">
              <div class="row container-transaction">
                <div class="one-line">
                  <div class="icon-text">
                    <lib-icon icon="annoucement" size="m" [color]="'black'"></lib-icon>&nbsp;
                    <h6>
                      Actualizaciones de cobranzas ({{ announcements?.length }})
                    </h6>
                  </div>
                  <div (click)="newAnnouncement(invoice)" class="icon-text hyperlink">
                    <mat-icon class="new-announcement">add</mat-icon>
                    <a class="hyperlink">Nueva actualización</a>
                  </div>
                </div>
                <div *ngIf="announcements?.length > 0; else empty_announcements" class="announcements-component">
                  <ng-container *ngFor="
                      let item of announcements | sort : 'createdAt' : 'desc'
                    ">
                    <app-announcement-card [announcement]="item" (editEvent)="editAnnouncement($event)"
                      (resendEvent)="resendAnnouncement($event)">
                    </app-announcement-card>
                  </ng-container>
                </div>

                <ng-template #empty_announcements>
                  <div class="investments">
                    <p class="disclaimer">
                      No hay actualizaciones registradas.
                    </p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="investors-detail-ch">
            <div class="investors">
              <div class="row container-transaction">
                <div>
                  <h6>
                    Detalle de inversionistas ({{ investments?.length || 0 }})
                  </h6>
                </div>
                <div *ngIf="investments?.length > 0; else empty_table" class="code-table">
                  <table>
                    <tr>
                      <th>Inversionista</th>
                      <th>Correo</th>
                      <th>Celular</th>
                      <th>Monto invertido</th>
                      <th>Distribución</th>
                      <th></th>
                    </tr>
                    <tr *ngFor="let invoice of investments">
                      <td>
                        <lib-text *ngIf="
                            (invoice?.names + invoice?.familyNames).length > 25
                          " [tooltipText]="
                            invoice?.names + ' ' + invoice?.familyNames
                              | capitalizeFirstLetters
                          " tooltip="true" tooltipSize="160" [content]="
                            invoice?.names + ' ' + invoice?.familyNames
                              | limitTo : '25'
                              | capitalizeFirstLetters
                          ">
                        </lib-text>

                        <lib-text *ngIf="
                            (invoice.names + invoice.familyNames).length < 26
                          " [content]="
                            invoice.names + ' ' + invoice.familyNames
                              | capitalizeFirstLetters
                          ">
                        </lib-text>
                      </td>
                      <td>
                        <lib-text *ngIf="invoice?.email.length > 25" [tooltipText]="invoice?.email" tooltip="true"
                          tooltipSize="160" [content]="invoice?.email | limitTo : '25'">
                        </lib-text>

                        <lib-text *ngIf="invoice.email.length < 26" [content]="invoice.email">
                        </lib-text>
                      </td>
                      <td>
                        <lib-text *ngIf="invoice?.phone.length > 15" [tooltipText]="invoice?.phone" tooltip="true"
                          tooltipSize="160" [content]="invoice?.phone | limitTo : '15'">
                        </lib-text>

                        <lib-text *ngIf="invoice.phone.length < 16" [content]="invoice.phone">
                        </lib-text>
                      </td>
                      <td>
                        <lib-text *ngIf="invoice?.amount.length > 15" [tooltipText]="invoice?.amount" tooltip="true"
                          tooltipSize="160" [content]="
                            invoice?.amount | numberSpacing | limitTo : '15'
                          ">
                        </lib-text>

                        <lib-text *ngIf="invoice.amount.length < 16" [content]="invoice.amount | numberSpacing">
                        </lib-text>
                      </td>

                      <td>
                        <lib-text [content]="invoice.distributionPercentage + '%'">
                        </lib-text>
                      </td>
                      <td>
                        <div style="display: flex; flex-direction: row">
                          <lib-icon icon="annoucement" [color]="
                              invoice?.isNewAnnouncement
                                ? 'yellow'
                                : 'dark-gray'
                            " tooltip="true" tooltipWidth="160" [tooltipText]="
                              invoice?.isNewAnnouncement
                                ? 'Actualización por revisar'
                                : 'Sin actualizaciones por revisar'
                            ">
                          </lib-icon>
                          <lib-icon (click)="resendEmail(invoice)" style="margin-left: 5px" icon="mail" color="blue"
                            tooltipWidth="100" tooltip="true" tooltipText="Reenviar correo">
                          </lib-icon>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </lib-tab-operation-detail>
      </ng-container>
    </ng-container>

    <div *ngIf="displayBackButton" class="controls button-container">
      <ng-container>
        <fs-ui-button class="space-top" (click)="goBack()" [label]="'Regresar'" [type]="'tertiary'">
        </fs-ui-button>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #empty_table>
  <div class="investments">
    <p class="disclaimer">No hay inversiones registradas.</p>
  </div>
</ng-template>