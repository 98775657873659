import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { CapitalizeOnlyFirstLetter } from "../../../../../shared/pipes/capitalize-only-first-letter.pipe";

@Component({
  selector: 'app-invest-schedule-alert',
  templateUrl: './invest-schedule-alert.component.html',
  styleUrls: ['./invest-schedule-alert.component.scss']
})
export class InvestScheduleAlertComponent implements OnInit {

  @Input() payload;
  @Input() alert = false;
  date
  constructor(
    private capitalizeOnlyFirstLetter: CapitalizeOnlyFirstLetter
  ) { }

  ngOnInit(): void {
    this.getContent()
  }


  getContent(){
    let hour = this.payload?.onSaleSlot === "1" ? '12:30' : '17:30'
    let date = moment(this.payload?.onSaleDate).format('dddd DD [de] MMMM, ') + hour
    this.date = this.capitalizeOnlyFirstLetter.transform(date);
  }


}
