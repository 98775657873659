<form [formGroup]="form" (ngSubmit)="formSubmitSubject$.next()"
    *ngIf="(bankAccountsLoaded() || !hideFormIfNoBankAccounts()) && (userHasBankAccounts() || !hideFormIfNoBankAccounts())">
    <app-autocomplete *allowedRoles="['admin']" searchFor='investor'
        (OnSelectionChanged)="selectedInvestorChanged($event)" label="Elige un inversionista" formControlName="user">
    </app-autocomplete>


    <ng-container *ngIf="bankAccountsLoaded() && userHasBankAccounts()">
        <div class="intercom-form-transaction">
            <app-select *ngIf="!(mode === 'donate')" formControlName="bankAccount" [options]="bankAccounts"
                valueField="_id" textField="bankSummary"
                [label]="mode === 'deposit'? 'Cuenta de origen' : 'Cuenta de destino'"></app-select>
            <div class="intercom-input-ammount-donate">
                <app-input formControlName="amount" (focusout)="sendEvent('amount')" autocomplete="off" type="number"
                    label="Monto"></app-input>
            </div>
        </div>
        <div class="intercom-button-upload container-upload u-mt-9">
            <lib-file-upload formats="image+pdf" *ngIf="mode === 'deposit'" [getFileName]="true" [loadedLabel]="'Voucher cargado'" [initialLabel]="'Subir voucher'" formControlName="voucher">
            </lib-file-upload>
        </div>
        <div class="intercom-both-currencies">
            <app-radiolist *ngIf="mode === 'donate'" formControlName="currency" [options]="currencyOptions">
            </app-radiolist>
        </div>

        <div class="intercom-button-upload" *ngIf="mode === 'withdraw'" >
            <p class="reason-label">Motivo de retiro</p>
            <app-radiolist-circle  (OnSelectionChanged)="changeReason($event)"  [direction]="'column'" formControlName="reason" [options]="reasonOptions" ></app-radiolist-circle>

            <app-input *ngIf="displayInput" type="text" label="Ingresa otro motivo"
            formControlName="otherReason" ></app-input>
        </div>

        <div class="form-actions">
            <fs-ui-button class="intercom-button-accept" (click)="formSubmitSubject$.next()" [label]="'Siguiente'">
            </fs-ui-button>
        </div>
    </ng-container>
    <ng-container *allowedRoles="['admin']">
        <p *ngIf="bankAccountsLoaded() && !userHasBankAccounts()" class="no-bank-accounts">{{ noBankAccountsError }}</p>
    </ng-container>

</form>
<ng-container *allowedRoles="['client', 'investor']">
    <ng-container *ngIf="bankAccountsLoaded() && !userHasBankAccounts()">
        <div class="container-bank">
            <img src="../../../../../../assets/icons/bank-acc.svg" alt="">
            <p>{{ noBankAccountsError }}</p>
            <a href="#" routerLink="/bank-accounts/create">Agrega tu cuenta aquí.</a>
        </div>
    </ng-container>
</ng-container>