<span class="error-label">{{errorMessage}}</span>
<div class="one-line">
    <div 
        *ngFor="let option of options"
        [attr.data-value]="option"
        class="button button-gray"
        (click)="changeSelection(option);"
        [class.selected]="option === selectedValue">
        <span>{{option | uppercase}}</span>
    </div>
</div>
