<div class="header">
    <h1>Factura {{data.code.code}}</h1>
    <app-statuses-label [payerResponse]="data.code.cavaliPayerResponse"  [status]="data.code.cavaliStatus"></app-statuses-label>
</div>

<div class="row">
    <div class="one-line">
        <div class="readyonly">
            <app-date title="Fecha de rechazo" *ngIf="data.code.cavaliCreatedAt && data.code.cavaliStatus === 'error'" displayStyle='plain' [date]="data.code.cavaliCreatedAt"
             [titleColor]="'gray'">
            </app-date>
        </div>
    </div>
    <div class="one-line" *ngIf="data.code.cavaliCreatedAt && data.code.cavaliStatus !== 'error'">
        <div class="readyonly">
            <app-date  title="Fecha de registro" displayStyle='plain' [titleColor]="'gray'"
                [date]="data.code.cavaliCreatedAt">
            </app-date>
        </div>
    </div>

    <div class="one-line"  *ngIf="data.code.cavaliPayerResponseDate && data.code.cavaliStatus !== 'error'" >
        <div class="readyonly">
            <app-date title="Fecha de respuesta"displayStyle='plain' [date]="data.code.cavaliPayerResponseDate"  [titleColor]="'gray'">
            </app-date>
        </div>
    </div>

    <div class="one-line">
        <div class="readyonly">
            <span class="tiny-title">Observación</span>
            <span class="value" *ngIf="data.code.cavaliObservation">
                {{ data.code.cavaliObservation  }}
            </span>
            <span class="value" *ngIf="!(data.code.cavaliObservation)">---</span>
        </div>
    </div>

</div>
<div class="available-action-cavali">
    <span *ngIf="data.code.cavaliStatus === 'error'" (click)="uploadToCavali()" class="action">
        <img [className]="iconClass"/>
        <span class="title c-blue">Subir a Cavali</span>
    </span>

    <span *ngIf="data.code.cavaliStatus === 'in progress'" (click)="removeFromCavali()" class="action">
        <img [className]="iconClassWithdraw"/>
        <span class="title c-red">Retirar de Cavali</span>
    </span>
</div>

<input type="button" class="button button-dark-gray" value="Regresar" (click)="cancel()" />
