import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { RiskService } from "../../../services/risk.service";

@Component({
  selector: "app-evaluation-information-popup",
  templateUrl: "./evaluation-information-popup.component.html",
  styleUrls: ["./evaluation-information-popup.component.scss"],
})
export class EvaluationInformationPopupComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EvaluationInformationPopupComponent>,
    private spinner: NgxSpinnerService,
    public riskService: RiskService,
    @Inject(MAT_DIALOG_DATA) public evaluation: any
  ) {}

  ngOnInit(): void {
    // console.log("Evaluation", this.evaluation);
  }

  close() {
    this.dialogRef.close(false);
  }

  stringToList(value: string) {
    return value
      .trim()
      .split(",")
      .map((item) => item.trim());
  }

  getDocuments(docs) {
    return docs
      .map((i) => {
        const fileName = i.key.split("/").at(-1);
        return fileName;
      })
      .join(", ");
  }

  downloadDocuments() {
    this.spinner.show();

    this.riskService
      .downloadDebtorDocumentsFiles(this.evaluation._id)
      .subscribe((result) => {
        if (result.file) {
          const fileName = `DOCUMENTOS-${this.evaluation?.debtor?.companyName?.toUpperCase()}.zip`;
          saveAs(result.file, fileName);
        } else {
          window.alert("¡No se encuentra el archivo!");
        }

        this.spinner.hide();
      });
  }
}
