import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatchValidator } from 'src/app/shared/validators/must-match.validator';
import { PasswordCriteriaValidator } from 'src/app/shared/validators/password-criteria.validator';

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss']
})
export class PasswordResetFormComponent implements OnInit {
  form;
  @Output() onSubmit = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder
  ) { }
  
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64), PasswordCriteriaValidator]],
      repeatPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64), MustMatchValidator('password')]]
    });
  }

  submit() {
    this.form.markAllAsTouched();

    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].updateValueAndValidity();
    });

    if(this.form.valid) {
      console.log({password: this.form.get('password').value})
      this.onSubmit.emit(this.form.get('password').value);
    }
  }

}
