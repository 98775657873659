import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AppState } from 'src/app/app.states';
import { Store, Action } from '@ngrx/store';
import { tap, map, catchError, mergeMap, switchMap, shareReplay, concatMap, take, skipWhile } from 'rxjs/operators';
import { Observable, pipe, of } from 'rxjs';
import { FinancialtransactionsService } from '../services/financial-transactions.service';
import { FinancialTransactionActions } from './financial-transactions.action-types';
import { InvoicesActions } from '../../invoices/state/invoices.action-types';
import { SocketService } from 'src/app/shared/services/socket.service';
import { AuthSelectors } from '../../authentication/state/auth-selector-types';
import { UsersSelectors } from '../../users/state/users.selector-types';
import { financialTransactions } from './financial-transactions.selectors';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { GAService } from 'src/app/shared/services/ga.service';

@Injectable()
export class FinancialTransactionsEffects {
    $saving = createEffect(
        () => this.actions$
            .pipe(
                ofType(FinancialTransactionActions.Saving),
                concatMap(action => this.financialtransactionsService.save(action.entity, action.mode)
                    .pipe(
                        map(receivedEntity => FinancialTransactionActions.SaveSuccess({entity: receivedEntity})),
                        catchError(error => of(FinancialTransactionActions.SaveFailed({error})))
                    )
                )
            ),
            {dispatch: true}
    );

    $readAnnouncement = createEffect(
        () => this.actions$
            .pipe(
                ofType(FinancialTransactionActions.ReadAnnouncement),
                concatMap(action => this.financialtransactionsService.reading(action.entity)
                    .pipe(
                        switchMap(receivedEntity => 
                            [
                                FinancialTransactionActions.SaveSuccess({entity: receivedEntity}),
                                InvoicesActions.LoadAll(),
                                FinancialTransactionActions.LoadAll()
                            ]
                        ),
                        catchError(error => of(FinancialTransactionActions.SaveFailed({error})))
                    )
                )
            ),
            {dispatch: true}
    );
    $investing = createEffect(
        () => this.actions$
            .pipe(
                ofType(FinancialTransactionActions.Investing),
                tap(() => {
                    this.socketService.disconnect();
                }),
                concatMap(action => this.financialtransactionsService.save(action.entity, action.mode)
                    .pipe(
                        switchMap(receivedEntity => 
                            [
                                FinancialTransactionActions.InvestSuccess({entity: receivedEntity}),
                                InvoicesActions.LoadAllOpportunities()
                            ]
                        ),
                        catchError(error => 
                            {
                                this.store.select(UsersSelectors.profile()).pipe(take(1)).subscribe(profile => {
                                    if (profile.role === "investor") {
                                          const eventName = 'new_investment';
                                          const eventData = {
                                            create_date: new Date().toISOString(),
                                            opeartion_type: null,
                                            currency: null,
                                            amount: null,
                                            is_success: false,
                                            error_description: `${error.error}`
                                          };
                                          this.gaService.sendEvent(eventName, eventData);
                                          this.mixpanelService.newEvent(eventName, eventData);
                                      }
                                })
                                return of(FinancialTransactionActions.InvestFailed({error}))
                            }
                            
                        )
                    )
                )
            ),
            {dispatch: true}
    );

    $investSuccess = createEffect(
        () => this.actions$
            .pipe(
                ofType(FinancialTransactionActions.InvestSuccess, FinancialTransactionActions.InvestFailed),
                tap((action) => {
                    this.store.select(AuthSelectors.accessToken)
                    .pipe(
                        skipWhile((res)=> res === null),
                        take(1)
                    ).subscribe(accessToken => {
                        this.socketService.setupSocketConnection(accessToken);
                    });
                }),
            ),
            {dispatch: false}
    );
    $loadAll = createEffect(
        () => this.actions$
            .pipe(
                ofType(FinancialTransactionActions.LoadAll),
                concatMap(action => this.financialtransactionsService.getAll()),
                map(financialTransactions => FinancialTransactionActions.LoadAllSuccess({...financialTransactions}))
            )
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private financialtransactionsService: FinancialtransactionsService,
        private socketService: SocketService,
        private mixpanelService: MixpanelService,
        private gaService: GAService
    ) { }
}