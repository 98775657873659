<div class="container">
    <div class="item">
        <div class="payer-and-currency row">
            <div class="title-term">
                <span class="tiny label">Nombre</span>
                <div class="term-name">
                    <p class="term description-name">{{entity.title }}</p>
                </div>
            </div>
            <div class="status" flex='30'>
                <app-solid-label width="140" background="blue" [content]="entity.type | toSpanish : 'MALE' | uppercase"></app-solid-label>
            </div>
        </div>
        <div  class="row">
            <div class="description-title">
                <span class="tiny label">Descripción</span>
                <div class="description-name">
                    <p class="description-name">{{entity.description }}</p>
                </div>
            </div>
        </div>
        <div  class="row">
            <div class="creation-date">
                <span class="tiny label">Fecha de registro</span>
                <div class="creation-date">
                    <app-date width="100" 
                        [date]="entity.createdAt">
                    </app-date>
                </div>
            </div>
            <div class="action-buttons">
                <div class="one-line actions">
                    <button class="search icon-button" (click)="showFileClicked()" mat-mini-fab>
                        <img src="../../../../../../assets/icons/search.svg" alt="">
                    </button>
                    <button class="edit icon-button" mat-mini-fab routerLink="edit/{{entity._id}}">
                        <img src="../../../../../../assets/icons/edit.svg" alt="">
                    </button>
                    <button (click)="delete()" class="delete icon-button" mat-mini-fab>
                        <img src="../../../../../../assets/icons/delete.svg" alt="">
                    </button>
                </div>
            </div>           
        </div>
    </div>
</div>