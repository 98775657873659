<div style="margin-bottom: 24px" *ngIf="step === 0">
  <app-page-title title="Nuevas facturas"></app-page-title>
</div>
<div *allowedRoles="['admin']">
  <app-autocomplete
    *ngIf="step === 0"
    searchFor="client"
    (OnSelectionChanged)="selectedClientChanged($event)"
    label="Elige un cliente"
  >
  </app-autocomplete>
</div>
<app-invoice-upload
  *ngIf="userId"
  [operationType]="operationType"
  [invoiceErrors]="invoiceErrors"
  [invoiceGroups]="invoiceGroups"
  class="upload-invoice"
  [step]="step"
  [clientRuc]="clientRuc"
  (upload)="setUpload($event)"
></app-invoice-upload>

<app-add-invoice-step1
  *ngIf="step === 1"
  [operationType]="operationType"
  [invoiceErrors]="invoiceErrors"
  [invoiceGroups]="invoiceGroups"
  (validation)="stepValid = $event"
  (setOperationsUploadEvent)="setUpload($event)"
  (goBackEvent)="returnToCreate()"
></app-add-invoice-step1>

<app-add-invoice-step2
  *ngIf="step === 2"
  [isLoggedInUserAnAdmin]="isLoggedInUserAnAdmin"
  [userId]="userId"
  [invoices]="invoices"
  [processType]="isConfirming ? 'confirming' : 'factoring'"
  (updateInvoices)="setUpload($event)"
></app-add-invoice-step2>

<app-add-invoice-step3
  *ngIf="step === 3"
  [isLoggedInUserAnAdmin]="isLoggedInUserAnAdmin"
  [userId]="userId"
  [isConfirming]="isConfirming"
  [invoices]="invoices"
  (updateInvoices)="setUpload($event)"
></app-add-invoice-step3>

<ng-container *ngIf="errorMessage">
  <div>
    <p class="error-message">{{ errorMessage }}</p>
  </div>
</ng-container>
<div class="button-group" *ngIf="invoiceGroups.length">
  <div class="button-container">
    <fs-ui-button
      (click)="return()"
      [type]="'tertiary'"
      [label]="step === 1 ? 'Cancelar' : 'Regresar'"
    ></fs-ui-button>
    <div class="button-container u-ml-5">
      <fs-ui-button
        class="intercom-button-confirm"
        (click)="submit()"
        [type]="'secondary'"
        [label]="nextButton"
      ></fs-ui-button>
    </div>
  </div>
</div>
<div style="margin-top: 31px" *ngIf="step === 0" class="button-container">
  <fs-ui-button
    (click)="goBack()"
    type="tertiary"
    label="Cancelar"
  ></fs-ui-button>
</div>


<tour-step-template>
  <ng-template let-step="step">
    <div (click)="$event.stopPropagation()" class="u-pt-3 u-pb-3 u-px-3 tour-container-step">
      <div class="header d-flex flex-justify-between flex-items-start u-pb-2">
        <p class="subheading-xs-bold text-color-dark-base">{{ step.title }}</p>
      </div>

      <div class="body-caption-light text-color-dark-base" [innerHTML]="step.content"></div>

      <div class="actions d-flex flex-justify-end flex-items-end u-pt-3">
        <!-- <lib-ui-button variant="text" [fullWidth]="false" size="s" [hidden]="!tourService?.hasPrev(step)"
          (click)="close()" [label]="step.prevBtnTitle"></lib-ui-button>

        <lib-ui-button variant="text" [fullWidth]="false" size="s"
          *ngIf="tourService?.hasNext(step) && !step?.nextOnAnchorClick" (click)="close()"
          [label]="step.nextBtnTitle"></lib-ui-button> -->
        <lib-ui-button variant="text" [fullWidth]="false" size="s" (click)="tourService.end()"
          *ngIf="!tourService?.hasNext(step)" [label]="step?.endBtnTitle"></lib-ui-button>
      </div>
    </div>
  </ng-template>
</tour-step-template>
