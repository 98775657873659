import { Big } from 'big.js';
import { getTodayDateOnly, getDaysDiff } from 'src/app/shared/util/date.util';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-invoice-card',
  templateUrl: './client-invoice-card.component.html',
  styleUrls: ['./client-invoice-card.component.scss']
})
export class ClientInvoiceCardComponent implements OnInit {

  @Input() invoice: any;
  @Input() type = '';

  constructor() { }

  ngOnInit(): void { }

  getCodes(invoice: any) {
    return invoice.physicalInvoices.map((i: any) => i.code).join(", ");
  }
  getCompanyName(invoice: any) {
    return invoice.isConfirming ? invoice?.supplier?.companyName : invoice?.debtor?.companyName;
  }

  getRemainingDays(toBeCollectedIn: any, duration: number) {
    const daysLabel = duration === 1 ? 'día' : 'días';
    const quedaLabel = duration === 1 ? 'Queda' : 'Quedan';

    if (toBeCollectedIn === 'En mora') {
      switch (true) {
        case duration >= 1 && duration < 31:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#FFB330'
          }
        case duration >= 31:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#FF0000'
          }

        default:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#2D2D2D'
          }
      }
    } else {
      return {
        text: `${quedaLabel} ${duration} ${daysLabel}`,
        color: '#2D2D2D'
      }
    }
  }

  datesPercentage(initialDate: any, finalDate: any) {
    if (initialDate && finalDate) {
      const todaysDateInPeru = getTodayDateOnly();
      let initDate = getDaysDiff(finalDate, todaysDateInPeru)

      const final = getDaysDiff(finalDate, initialDate)

      const result = new Big(initDate)
        .abs()
        .div(final)
        .abs()
        .minus(1)
        .abs()
        .times(100)
        .toFixed(2);

      return result;
    } else {
      return 0;
    }
  }

}
