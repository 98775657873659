import { environment } from 'src/environments/environment';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { Router } from '@angular/router';
import moment from 'moment';
import { PhoneConfirmationModalComponent } from './../../../../UI/molecules/phone-confirmation-modal/phone-confirmation-modal.component';
import { Toaster } from 'ngx-toast-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { take } from 'rxjs/operators';
import { AppState } from './../../../../../app.states';
import { Store } from '@ngrx/store';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-save-result',
  templateUrl: './save-result.component.html',
  styleUrls: ['./save-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveResultComponent implements OnInit {
  @Input() success;
  @Input() mode;
  @Input() resultConfig;
  @Output() onDone = new EventEmitter();
  showIcon = false;
  iconName;
  messsage;
  profile;
  newRegistration;
  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private router: Router,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    if (this.success.savingSucceeded && this.resultConfig) {
      this.messsage = this.resultConfig?.onSuccess?.messsage;
      if (this.resultConfig?.onSuccess?.showIcon) {
        this.showIcon = true;
        this.iconName = this.resultConfig.onSuccess.iconName;
      }

      if (this.resultConfig?.onSuccess?.newRegistration) {
        this.newRegistration = true;
      } else {
        this.newRegistration = false;
      }
    }

    this.store
      .select(UsersSelectors.profile())
      .pipe(take(1))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;

          if (
            profile.role === 'client' &&
            this.resultConfig?.origin == 'invoices'
          ) {
            const eventData: any = {
              page_url: environment.clientUrl + this.router.url,
              page_title: 'Invoice upload',
              section_step: 'step 3',
              whatsapp_widget_status:
                !profile.whatsappStatus || profile.whatsappStatus == 'error'
                  ? '0-state'
                  : 'none',
              activated_before: profile.whatsappActivatedOnce || false,
            };

            this.mixpanelService.newEvent('page_view', eventData);
          }
        }
      });
  }

  getTitle() {
    if (this.success.savingSucceeded) {
      if (this.mode === 'createInvoice' || this.mode === 'createInvoiceNew') {
        return 'Factura(s) subida(s)';
      }

      if (this.mode === 'registerUser') {
        return 'Revisa todas tus bandejas de entrada y confirma tu correo electrónico';
      }

      try {
        return this.resultConfig.onSuccess.title;
      } catch (ex) {
        return this.getGenericSuccessTitle();
      }
    } else {
      try {
        return this.resultConfig.onFailure.title;
      } catch (ex) {
        return this.getGenericFailureTitle();
      }
    }
  }

  getGenericSuccessTitle() {
    let postfix = ' Realizada';
    let action;

    switch (this.mode) {
      case 'create':
        action = 'Creación';
        break;
      case 'edit':
        action = 'Modificación';
        break;
      case 'editOwn':
        action = 'Modificación';
        break;
      case 'delete':
        action = 'Eliminación';
        break;
    }
    return action + postfix;
  }
  getGenericFailureTitle() {
    let errorMessage;
    switch (this.success.lastErrorMessage) {
      case 'PROFILE.INCORRECT_CURRENT_PASSWORD':
        errorMessage = 'La contraseña actual es incorrecta.';
        break;
      case 'PASSWORD_RESET.TOKEN_NOT_FOUND_OR_ALREADY_USED':
        errorMessage = 'El enlace ha caducado o ya se ha utilizado.';
        break;
      case 'PASSWORD_RESET_REQUEST.A_VALID_TOKEN_ALREADY_EXISTS':
        errorMessage =
          'Ya ha solicitado un restablecimiento de contraseña, verifique su correo electrónico o vuelva a intentarlo más tarde.';
        break;

      case 'INVESTMENTS.TARGET_INVOICE_NOT_FOUND':
        errorMessage =
          'La factura en la que está intentando invertir ya no está disponible.';
        break;
      case 'INVESTMENTS.TARGET_INVOICE_STATUS_DOESNT_ALLOW_NEW_INVESTMENTS':
        errorMessage =
          'La factura en la que está intentando invertir parece no estar disponible de forma permanente o temporal, actualice y vuelva a comprobar.';
        break;
      case 'INVESTMENTS.TARGET_INVOICE_AVAILABLE_BALANCE_IS_ZERO':
        errorMessage =
          'El monto disponible de la factura en la que deseas invertir está en 0. Ya no se puede invertir.';
        break;
      case 'INVESTMENTS.INVESTMENT_AMOUNT_IS_BIGGER_THAN_TARGET_INVOICE_AVAILABLE_BALANCE':
        errorMessage =
          'El monto a invertir debe ser menor o igual al monto disponible de la factura.';
        break;
      case 'INVESTMENTS.INVESTMENT_AMOUNT_IS_BIGGER_THAN_USER_AVAILABLE_BALANCE':
        errorMessage = 'No cuentas con suficiente saldo.';
        break;
      case 'INVOICE.INVOICE_ALREADY_EXISTS':
        errorMessage = 'Se encontró un duplicado de esta factura.';
        break;
      case 'TRANSACTION_IS_DUPLICATED':
        errorMessage = 'El código del movimiento está duplicado.';
        break;
      case 'EVALUATION.EVALUATION_ALREADY_COMPLEATED':
        errorMessage = 'Otro analista ya llenó los datos';
        break;

      default:
        errorMessage =
          'Algo mal sucedió, por favor intente nuevamente más tarde!';
    }

    return errorMessage;
  }

  done() {
    this.onDone.emit();
  }

  getIconClass() {
    const baseLocation = '../../../../../../assets/icons/';

    if (this.iconName === 'withdraw') {
      return baseLocation + 'withdraw-sucess.svg';
    }

    if (this.iconName === 'big-check-mark-icon') {
      return baseLocation + 'big-check-mark-icon.svg';
    }
  }

  getImgSrc() {
    const baseLocation = '../../../../../../assets/icons/';

    if (this.success.savingSucceeded) {
      return baseLocation + 'big-check-mark-icon.svg';
    } else {
      return baseLocation + 'big-cancel-icon.svg';
    }
  }

  getCssClass() {
    if (this.success.savingSucceeded) {
      return 'success';
    } else {
      return 'failure';
    }
  }

  openPhoneModal(event: any) {
    if (event.action == 'activate') {
      const phoneDialog = this.dialog.open(PhoneConfirmationModalComponent, {
        maxWidth: '720px',
        height: 'auto',
        autoFocus: false,
        disableClose: true,
        panelClass: 'custom-modalbox',
        data: {
          user: this.profile,
          mode: 'operation',
        },
      });

      const eventData: any = {
        page_url: environment.clientUrl + this.router.url,
        origin: 'Invoice upload',
        activated_before: this.profile.whatsappActivatedOnce || false,
      };

      this.mixpanelService.newEvent('Open whatsapp modal', eventData);

      phoneDialog.afterClosed().subscribe((res) => {
        if (res.close) {
          this.onDone.emit();
        }
      });
    }
  }

  getWSWidgetStatus(profile: any) {
    switch (profile.whatsappStatus) {
      case 'pending':
        return 'activated';
      case 'resent':
        if (
          moment
            .utc()
            .diff(moment.utc(profile.whatsappActivationResentAt), 'minutes') <
          10
        ) {
          return 'blocked';
        } else {
          return 'activated';
        }
      default:
        return 'default';
    }
  }
}
