import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-collection-client-dates',
  templateUrl: './collection-client-dates.component.html',
  styleUrls: ['./collection-client-dates.component.scss']
})
export class CollectionClientDatesComponent implements OnInit {

  @Input() collections;
  display = true
  constructor() { }

  ngOnInit(): void {
  }

  showMore(){
    this.display = !this.display
  }
}
