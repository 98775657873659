<div class="intercom-dialog-form">
  <div class="header">
    <h1>
      {{
        data?.data?.mode === 'validate'
          ? 'Validar contacto'
          : data?.data?.mode === 'edit'
          ? 'Editar contacto'
          : 'Nuevo contacto'
      }}
    </h1>

    <p
      class="body-paragraph-s-regular text-color-dark-weak u-mt-2"
      *ngIf="data?.data?.mode === 'validate' || data?.data?.mode === 'edit'"
    >
      {{ data?.data?.email }}
    </p>
  </div>

  <form [formGroup]="form">
    <div class="controls intercom-add-contact-dialog">
      <app-input
        [default]="data?.data?.name"
        formControlName="name"
        autocomplete="off"
        type="text"
        label="Nombre"
        [helperText]="
          data?.data?.duplication?.names.length > 0
            ? 'Sugerencias: ' + data?.data?.duplication?.names.join(', ')
            : ''
        "
      ></app-input>
      <app-input
        [ngClass]="
          data?.data?.duplication?.names.length > 0
            ? 'd-block u-mt-5'
            : 'u-mt-1'
        "
        [default]="data?.data?.jobTitle"
        formControlName="jobTitle"
        autocomplete="off"
        type="text"
        label="Cargo"
        [helperText]="
          data?.data?.duplication?.jobTitles?.length > 0
            ? 'Sugerencias: ' + data?.data?.duplication?.jobTitles.join(', ')
            : ''
        "
      ></app-input>
      <app-input
        *ngIf="data?.data?.mode !== 'validate' && data?.data?.mode !== 'edit'"
        [ngClass]="
          data?.data?.duplication?.jobTitles.length > 0
            ? 'd-block u-mt-5'
            : 'u-mt-1'
        "
        [default]="data?.data?.email"
        formControlName="email"
        autocomplete="off"
        type="text"
        label="Correo electrónico"
        [isDisabled]="
          data?.data?.mode === 'validate' || data?.data?.mode === 'edit'
        "
      ></app-input>
      <app-input
        [ngClass]="
          data?.data?.duplication?.jobTitles.length > 0
            ? 'd-block u-mt-5'
            : 'u-mt-1'
        "
        [default]="data?.data?.phone"
        formControlName="phone"
        autocomplete="off"
        type="text"
        label="Teléfono"
        [helperText]="
          data?.data?.duplication?.phones.length > 0
            ? 'Sugerencias: ' + data?.data?.duplication?.phones.join(', ')
            : ''
        "
      ></app-input>
      <ng-container *ngIf="data?.showContactTypes">
        <div
          [ngClass]="
            data?.data?.duplication?.phones.length > 0
              ? 'd-block u-mt-5'
              : 'u-mt-1'
          "
        >
          <lib-text
            class="typesHeader"
            size="xxs"
            weight="body"
            [content]="'Comunicaciones de:'"
          ></lib-text>
          <div
            class="checkbox space-checkbox"
            [class.invalid]="data?.showContactTypes"
          >
            <lib-checkbox
              [checked]="getCheck('conformity')"
              (onCheckboxClicked)="checkboxClicked($event, 'conformity')"
              type="blue_checked"
            >
            </lib-checkbox>
            <lib-text
              size="xs"
              weight="body"
              [content]="'Conformidad'"
            ></lib-text>
          </div>
          <div class="checkbox">
            <lib-checkbox
              [checked]="getCheck('collection')"
              (onCheckboxClicked)="checkboxClicked($event, 'collection')"
              type="blue_checked"
            >
            </lib-checkbox>
            <lib-text size="xs" weight="body" [content]="'Cobranza'"></lib-text>
          </div>
          <div *ngIf="!contactTypesValidation" class="checkboxError">
            <lib-text
              size="xs"
              weight="body"
              content="Debes seleccionar uno."
              color="red"
            ></lib-text>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="showDuplicateError">
      <lib-text
        size="xs"
        content="Ya existe un contacto con ese correo."
        color="red"
      ></lib-text>
    </ng-container>
    <div class="button-container button-direction-column">
      <fs-ui-button
        class="intercom-dialog-confirm"
        [action]="'submit'"
        (click)="onSubmit()"
        [label]="
          data?.data?.mode === 'validate' ? 'Guardar y validar' : 'Guardar'
        "
      ></fs-ui-button>
      <fs-ui-button
        (click)="cancel()"
        [label]="'Cancelar'"
        [type]="'tertiary'"
      ></fs-ui-button>
    </div>
  </form>
</div>
