<div class="container">
    <div class="bank-and-currency row">
        <div class="bank-container">
            <p class="bank-name">{{debtorInformation.companyName | uppercase}}</p>
            <p class="small-font mt">RUC: {{debtorInformation.companyRuc}}</p>
        </div>
    </div>
    <div class="bank-and-currency one-line">
        <div class="bank-container icon-text-align">
            <img class="map" src="../../../../../../assets/icons/map.svg" />
            <p class="small-font">{{debtorInformation.companyDepartment | capitalizeFirstLetters}}</p>
        </div>
        <div class="bank-container icon-text-align address" *ngIf="debtorInformation.companyAddress">
            <div class="bank-container" *ngIf="debtorInformation.companyAddress">
                <img class="sub-filter-icon" src="../../../../../../assets/icons/location.svg" />
            </div>
            <div class="bank-container" *ngIf="debtorInformation.companyAddress">
                <p class="small-font">{{debtorInformation.companyAddress | capitalizeFirstLetters}}{{((debtorInformation.companyDistrict !== '-' && debtorInformation?.companyDistrict) ? (', ' + debtorInformation.companyDistrict) : '') | capitalizeFirstLetters}}</p>
            </div>
        </div>
        
    </div>
</div>