import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/app.states';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { EvaluationStatus } from 'src/app/shared/enums/EvaluationStatus.enum';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { RiskService } from '../../../services/risk.service';
import { EvaluationsActions } from '../../../states/evaluations.action-types';
import { EvaluationSelectors } from '../../../states/evaluations.selector-types';
@Component({
  selector: 'app-list-evaluations',
  templateUrl: './list-evaluations.component.html',
  styleUrls: ['./list-evaluations.component.scss']
})
export class ListEvaluationsComponent implements OnInit {


  evaluations$
  evaluations
  evaluationsMonitoring
  pagination = {
    total: 0,
    limit: 5,
    skip: 0
  }
  defaultPagination = {
    total: 0,
    limit: 5,
    skip: 0
  }
  active
  filtersForm: FormGroup;
  filterDefault = {
    statuses: [EvaluationStatus.Pending], 
    pagination: undefined,
  };

  paginationMonitoring = {
    total: 0,
    limit: 5,
    skip: 0
  }
  defaultPaginationMonitoring = {
    total: 0,
    limit: 5,
    skip: 0
  }
  dataSort = {
    property: 'usedCreditLine',
    order:'desc'
  }
  filtersFormMonitoring: FormGroup;
  filterDefaultMonitoring = {
    statuses: [EvaluationStatus.Pending], 
    pagination: undefined,
  };
  usedCreditLine = false;
  evaluationFinishedAt = false;
  constructor(
    private overlayService: OverlayService,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private service: RiskService) { }

  ngOnInit(): void {

    const previousFiltersMonitoring = JSON.parse(localStorage.getItem('monitoring_filters'))
    const previousFiltersEvaluation = JSON.parse(localStorage.getItem('pagination_pending_evaluation'))

    if(previousFiltersEvaluation){
      this.pagination = {
        total: 0,
        limit: 5,
        skip: previousFiltersEvaluation,
      }
    }
    if(previousFiltersMonitoring){
      this.active = previousFiltersMonitoring.property
      this[previousFiltersMonitoring.property] = previousFiltersMonitoring.order === 'asc' ? true : false
      this.dataSort = previousFiltersMonitoring
      this.paginationMonitoring = {
        total: 0,
        limit: 5,
        skip: previousFiltersMonitoring.pagination
      }
    }else{
      this.active = 'evaluationFinishedAt'
    }

    this.store.select(UsersSelectors.profileProperty('role')).pipe(take(1)).subscribe(loggedInUserRole => {

      if (loggedInUserRole === Roles.RISK_ANALYST) {
        this.filtersForm = this.fb.group({
          statuses: [this.filterDefault.statuses],
          pagination: [this.filterDefault.pagination],
        });
        this.applyFilter(true);
        this.applyFilterMonitoring()
      }else{
        this.evaluations$ = this.store.select(EvaluationSelectors.all)
      }
    })

   
   
  }  


  fetchEvaluation(filterFormattedValue){
    this.service.get(this.pagination.skip, this.pagination.limit, filterFormattedValue) .subscribe((result: any) => {
      this.evaluations = result.entities;
      this.pagination.total = result.count;
    })
  }


  paginationHandler(data) {
    this.pagination.skip = data.skip;
    this.pagination.limit = data.limit;
   this.applyFilter(true);
  }

  paginationHandlerMonitoring(data) {
    this.paginationMonitoring.skip = data.skip;
    this.paginationMonitoring.limit = data.limit;
    this.applyFilterMonitoring()
  }

  applyFilter(paginate = false) {

    const { ...rest } = this.filtersForm.value;
    let filterFormattedValue = rest;

    if (!paginate) {
      this.pagination = Object.assign({}, this.defaultPagination);
      filterFormattedValue['pagination'] = this.pagination.skip
    }
    localStorage.setItem('pagination_pending_evaluation', JSON.stringify(this.pagination.skip))
    this.fetchEvaluation(filterFormattedValue)
  }

  applyFilterMonitoring() {
    
    let data = {}
    data = {
      property : this.dataSort.property,
      order: this.dataSort.order,
      pagination: this.paginationMonitoring.skip
    }
    localStorage.setItem('monitoring_filters', JSON.stringify(data))
    this.service.sort(this.paginationMonitoring.skip, this.paginationMonitoring.limit, this.dataSort) .subscribe((result: any) => {
      this.evaluationsMonitoring = result.entities;
      this.paginationMonitoring.total = result.count;
    })
  }

  posponeEval(data) {
    let id = data.evaluationId;

    this.overlayService.startConfirmation(
      {
        status: data.status,
        _id: id,
      },
      null,
      'question',
      'edit',
      null,
      EvaluationsActions.Saving,
      `risk-pending-evaluations`,
      EvaluationSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La evaluación se ha postergado',
        },
      },
      this.reFetchEvaluation.bind(this)
    );
  }

  reFetchEvaluation(){
    this.applyFilterMonitoring()
  }

  sortEvaluation(data){
    this.dataSort = data
    data['pagination'] = this.defaultPagination.skip,
    this.paginationMonitoring = Object.assign({}, this.defaultPagination)
    localStorage.setItem('monitoring_filters', JSON.stringify(data))
    this.service.sort(this.paginationMonitoring.skip, this.paginationMonitoring.limit, data).subscribe((result: any) => {
      this.evaluationsMonitoring = result.entities;
      this.paginationMonitoring.total = result.count;
    })
  }

  
}
