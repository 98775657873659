import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-announcement-table',
  templateUrl: './invoice-announcement-table.component.html',
  styleUrls: ['./invoice-announcement-table.component.scss']
})
export class InvoiceAnnouncementTableComponent implements OnInit {

  @Input() invoices;
  @Input() withScroll = false;
  constructor() { }

  ngOnInit(): void {
  }

  getCodes(invoice){
    return invoice.physicalInvoices.map(i => i.code).join("\n");
  }

}
