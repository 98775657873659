<p class="subtitle" *ngIf="entity.newBankAccount">CUENTA BANCARIA POR ELIMINAR</p>

<app-bank-account-item [noControls]="true" [entity]="entity.bankAccount">
</app-bank-account-item>


<div *ngIf="entity.bankAccount.hasRelationship">
  <p *allowedRoles="['client']" class="tiny disclaimer"><span class="bold">Esta cuenta está
      vinculada a una
      factura.</span> Guardaremos la
    información básica para cumplir
    con las normas de prevención de lavado de activos.</p>

  <p *allowedRoles="['investor']" class="tiny disclaimer"><span class="bold">Esta cuenta
      está vinculada a una
      transacción.</span>
    Guardaremos la información básica para cumplir con las normas de prevención de lavado de activos.</p>
</div>


<div *ngIf="entity.newBankAccount">
  <p class="subtitle">CUENTA BANCARIA DE REEMPLAZO</p>

  <app-bank-account-item [noControls]="true" [entity]="entity.newBankAccount">
  </app-bank-account-item>

</div>