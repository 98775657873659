import { cloneDeep } from 'lodash';
import { createSelector } from '@ngrx/store';
import { investmentTransactions } from '../../financial-transactions/state/financial-transactions.selectors';
import { INVOICE_STATUS } from '../UI/enums/INVOICE_STATUS.enum';

export const loaded = createSelector(
  (state) => state['invoices'],
  (state) => state.loaded
);
export const opportunitiesLoaded = createSelector(
  (state) => state['invoices'],
  (state) => state.opportunitiesLoaded
);
export const opportunitiesLoading = createSelector(
  (state) => state['invoices'],
  (state) => state.opportunitiesLoading
);
export const all = createSelector(
  (state) => state['invoices'],
  (state) => state.entities
);
export const allOpportunities = createSelector(
  (state) => state['invoices'],
  (state) => state.opportunities
);
export const operationsLoading = createSelector(
  (state) => state['invoices'],
  (state) => state.operationsLoading
);
export const operationsLoaded = createSelector(
  (state) => state['invoices'],
  (state) => state.operationsLoaded
);
export const allOperations = createSelector(
  (state) => {
    return {
      operations: state['invoices'].operations,
      profile: state['users'].profile,
    };
  },
  (state) => {
    if (state?.profile?.role === 'account manager') {
      const operations = cloneDeep(state.operations)?.filter(
        (operation: any) =>
          String(state.profile.pipedriveId) ===
          String(
            operation.issuer?.pipedriveAM?.sort(
              (a: any, b: any) =>
                new Date(b.linkedAt).getTime() - new Date(a.linkedAt).getTime()
            )[0]?.pipedriveId
          )
      );

      return operations;
    } else {
      return state.operations;
    }
  }
);

export const byId = (id) =>
  createSelector(all, (items) => {
    if (items) return items.filter((entity) => entity._id === id)[0];
  });
export const opportunitiesById = (id) =>
  createSelector(allOpportunities, (items) => {
    if (items) return items.filter((entity) => entity._id === id)[0];
  });
export const existsByCodeAndNotDisapproved = (code, ruc) =>
  createSelector(all, (items) => {
    if (items)
      return items.some((entity) =>
        entity.physicalInvoices.some((subInvoice) => {
          return (
            subInvoice.code === code &&
            entity.status !== INVOICE_STATUS.DISAPPROVED &&
            (entity.isConfirming === true
              ? entity.supplier.companyRuc === ruc
              : entity.issuer.companyRuc === ruc)
          );
        })
      );
  });

export const savingSuccessStateById = (savingEntityId) =>
  createSelector(
    (state) => {
      return {
        saving: state['invoices']['saving'],
        savingEntityId: state['invoices']['savingEntityId'],
        savingSucceeded: state['invoices']['savingSucceeded'],
        lastErrorMessage: state['invoices']['lastErrorMessage'],
        lastErrorStatus: state['invoices']['lastErrorStatus'],
      };
    },
    (savingState) => {
      if (
        savingEntityId === savingState.savingEntityId &&
        savingState.saving === false
      ) {
        return {
          savingSucceeded: savingState.savingSucceeded,
          lastErrorMessage: savingState.lastErrorMessage,
          lastErrorStatus: savingState.lastErrorStatus,
        };
      }
    }
  );
