<div class="title container-title">
    <app-page-title [title]="pageTitle"></app-page-title>
    <div class="toggle toggle-desk" *ngIf="displayToggle">
        <app-slide-toggle [checked]="default" [firstLabel]="'pen'" [secondLabel]="'usd'"
            (selectionChanged)="setCurrency($event)">
        </app-slide-toggle>
    </div>
</div>

<div class="toggle toggle-mob" *ngIf="displayToggle">
    <app-slide-toggle [checked]="default" [firstLabel]="'pen'" [secondLabel]="'usd'"
        (selectionChanged)="setCurrency($event)">
    </app-slide-toggle>
</div>


<ng-container *ngIf="(currency === 'pen' && penLenght > 0) || (currency === 'usd' && usdLenght > 0)">
    <app-available-to-invest-balance [currency]="currency"></app-available-to-invest-balance>

    <lib-tab-my-investments [selected]="selected" [actual]="investmentActualLength" [mora]="investmentMoraLength"
        [history]="investmentHistoricalLength" (selectedTab)="activateClass($event)">
        <div class="actual">
            <ng-container *ngIf="invoiceFiltered | filterIn:'status':actualInvesmtentsStatus as invoicesActive">
                <ng-container *ngIf="invoicesActive.length > 0; else no_investment_actual">
                    <div class="invoices-list">
                        <app-invoice-item-investor class="invoice-item" *ngFor="let invoice of invoicesActive"
                            mode="actual" 
                            (click)="showDetails(invoice)"
                            [entity]="invoice">
                        </app-invoice-item-investor>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="mora">
            <ng-container *ngIf="invoiceFiltered | filterIn:'status':actualInvesmtentsStatus as invoicesActive">
                <ng-container *ngIf="invoicesActive.length > 0; else no_investment_delayed">
                    <div class="invoices-list">
                        <app-invoice-item-investor class="invoice-item" *ngFor="let invoice of invoicesActive"
                            mode="delayed" 
                            (click)="showDetails(invoice)" 
                            [entity]="invoice">
                        </app-invoice-item-investor>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="history">
            <ng-container *ngIf="invoiceFiltered | filterIn:'status':historicalInvesmtentsStatus as invoicesActive">
                <ng-container *ngIf="invoicesActive.length > 0; else no_investment_historic">
                    <div class="invoices-list">
                        <app-invoice-item-investor class="invoice-item" *ngFor="let invoice of invoicesActive"
                        mode="historic" (click)="showDetails(invoice)" [entity]="invoice">
                        </app-invoice-item-investor>
                    </div>
                </ng-container>
            </ng-container>
        </div>

    </lib-tab-my-investments>
    <app-paginator *ngIf="invoiceFiltered?.length > 0" [totalCount]="pagination.total" [pageSize]="pagination.limit" [skip]="pagination.skip"
        (pagination)="paginationHandler($event)">
    </app-paginator>
    <!--ng-template #no_investment>
        <ng-container>
            <div class="empty-opportunities container">
                <p class="disclaimer">No cuentas con facturas invertidas actualmente</p>
            </div>
        </ng-container>
    </ng-template-->

    <ng-template #no_investment_actual>
        <ng-container>
            <div class="empty-opportunities container">
                <p class="disclaimer">No cuentas con facturas invertidas actualmente</p>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #no_investment_delayed>
        <ng-container>
            <div class="empty-opportunities container">
                <p class="disclaimer">No cuentas con facturas vencidas actualmente</p>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #no_investment_historic>
        <ng-container>
            <div class="empty-opportunities container">
                <p class="disclaimer">No cuentas con facturas cobradas actualmente</p>
            </div>
        </ng-container>
    </ng-template>

</ng-container>
<ng-container
    *ngIf="(currency === 'pen' && penLenght === 0 && invoiceFiltered.length === 0) || (currency === 'usd' && usdLenght === 0 && invoiceFiltered.length === 0)  ">
    <div class="empty-opportunities container">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="68" viewBox="0 0 100 68">
                <defs>
                    <style>
                        .a {
                            fill: #6c727c;
                        }
                    </style>
                </defs>
                <g transform="translate(-1 -12.1)">
                    <path class="a"
                        d="M94.538,12.214,73.3,12.1a4.638,4.638,0,0,0-4.707,4.67,4.544,4.544,0,0,0,1.378,3.3A4.617,4.617,0,0,0,73.3,21.44h9.989L60.439,43.879,48.5,31.805a4.739,4.739,0,0,0-6.659,0L12.678,60.281a4.69,4.69,0,0,0,6.659,6.606L45.169,41.715,57.224,53.788a4.739,4.739,0,0,0,6.659,0L90.06,28.16v8.657a4.544,4.544,0,0,0,1.378,3.3,4.739,4.739,0,0,0,6.659,0,4.544,4.544,0,0,0,1.378-3.3L99.36,17a4.544,4.544,0,0,0-1.378-3.3A5,5,0,0,0,94.538,12.214Z"
                        transform="translate(1.525 0)" />
                    <path class="a"
                        d="M5.707,63.7h0a4.617,4.617,0,0,0-3.33,1.367,4.512,4.512,0,0,0,0,6.492,4.617,4.617,0,0,0,3.33,1.367,4.638,4.638,0,0,0,4.707-4.67,4.544,4.544,0,0,0-1.378-3.3A4.812,4.812,0,0,0,5.707,63.7Z"
                        transform="translate(0 7.174)" />
                </g>
            </svg>
        </div>
        <h5 class="title-not-investments" *ngIf="currency === 'pen'">No cuentas con inversiones en PEN</h5>
        <h5 class="title-not-investments" *ngIf="currency === 'usd'">No cuentas con inversiones en USD</h5>


    </div>
</ng-container>

<!--ng-container *ngIf="invoiceFiltered | filterIn:'status':activeSelection as invoicesActive">
        <div class="row investment-type-controls">
            <div class="form-actions space-right">
                <button type="submit" class="button button-gray actual"
                    [ngClass]="buttonActive === 'actual' ? 'active' : 'none'"
                    (click)="activateClass('actual')"></button>
            </div>
            <div class="form-actions space-left">
                <button type="submit" class="button button-gray historial"
                    [ngClass]="buttonActive === 'historic' ? 'active' : 'none'"
                    (click)="activateClass('historic')">Históricas</button>
            </div>
        </div>
        <ng-container *ngIf="invoicesActive.length > 0; else no_investment">
            <ng-container *ngIf="buttonActive === 'actual'">
                <ng-container *ngIf="invoicesActive | sort:('toBeCollectedIn'):'smallest' as sortedInvoices">
                    <div class="invoices-list">
                        <app-invoice-item-investor class="invoice-item" *ngFor="let invoice of sortedInvoices"
                            [mode]="buttonActive" (click)="showDetails(invoice)" [entity]="invoice">
                        </app-invoice-item-investor>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="buttonActive === 'historic'">
                <ng-container *ngIf="invoicesActive | sort:('actualPaymentDate'):'closest' as sortedInvoices">
                    <div class="invoices-list">
                        <app-invoice-item-investor class="invoice-item" *ngFor="let invoice of sortedInvoices"
                            [mode]="buttonActive" (click)="showDetails(invoice)" [entity]="invoice">
                        </app-invoice-item-investor>
                    </div>
                </ng-container>
            </ng-container>
            

        </ng-container>

       
    </ng-container>
</ng-container-->



<!--Empty ooportunities template-->

<!--
<ng-container *ngFor="let entity of investments$">
    <app-invoice-item 
        [entity]="entity" 
        [type]="type"
        (showInvestment)="showInvest()">
        
    </app-invoice-item>
</ng-container>
-->

<!--
<ng-container *ngIf="investments$ == undefined || investments$.length == 0">
    <div class="empty-opportunities container">
        <p class="disclaimer">No cuentas con facturas invertidas actualmente</p>
    </div>
</ng-container>
-->