import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(fullName: string): any {
    if (fullName) {

      let names = fullName.split(" ");

      if (names.length > 2) {
        return names
          .filter((element, index, array) => index % 2 === 0)
          .map(n => n[0])
          .join("");
      } else {
        return fullName
          .split(" ")
          .map(n => n[0])
          .join("");
      }
    }

  }

}
