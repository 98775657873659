import { Component, OnInit, Input } from '@angular/core';
import { UserActionsMapping } from '../../../mapping/actions.mapping';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})
export class UserItemComponent implements OnInit {
  @Input() entity;
  userActions = Object.keys(UserActionsMapping);

  constructor(private router: Router) { }

  ngOnInit(): void {
    //console.log(this.userActions)
  }

  toDetails() {
    this.router.navigateByUrl(`users-accounts/${this.entity._id}`);
  }
}
