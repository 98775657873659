<h1 class="header">Motivo de la desaprobación</h1>


<div class="form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="intercom-button-upload">
        <app-radiolist-circle (OnSelectionChanged)="changeReason($event)" [direction]="'column'"
            formControlName="reason" [options]="reasonOptions" [defaultOption]="reason"></app-radiolist-circle>

        <app-input *ngIf="displayInput" type="text" label="Ingresa otro motivo" formControlName="otherReason">
        </app-input>
    </div>
    <div class="button-container button-direction-column">
        <fs-ui-button  [action]="'submit'" (click)="submit()"  [label]="'Siguiente'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</div>