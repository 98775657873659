import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickoutsideDirective {
  @Output() clickOutside = new EventEmitter()

  constructor(private elementRef : ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement){
    const clickInside = this.elementRef.nativeElement.contains(targetElement)
    if(!clickInside){
      // console.log('outside', targetElement)
      this.clickOutside.emit('')
    }
  }

}