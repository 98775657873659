import { getFormattedDate } from './date.helper';
import moment from 'moment';
import mongoose from 'mongoose';

export enum INVOICE_STATUS {
  'IN_VALIDATION' = 'in validation',
  'APPROVED' = 'approved',
  'DISAPPROVED' = 'disapproved',
  'CONFIGURED' = 'configured',
  'PENDING CONFIRMATION' = 'pending confirmation',
  'FOR SALE' = 'for sale',
  'PARTIALLY SOLD' = 'partially sold',
  'SALE CLOSED' = 'sale closed',
  'AWAITING COLLECTION' = 'awaiting collection',
  'COLLECTED' = 'collected',
  'FINALIZED' = 'finalized',
  'IN_PROCESS' = 'in process',
}

export const INVESTOR = {
  amount_deposit_pen: 100000,
};

export const INVOICE = {
  amount: 95715.79,
  retention: 0,
};

export const OPPORTUNITY = {
  amount_number: 81358.42,
  amount_whole: '81 358',
  amount_fraction: '42',
  return_number: 1122.75,
  return_whole: '958',
  return_fraction: '40',
  debtor: 'CENCOSUD RETAIL PERU S.A.',
  currency: 'PEN',
  tea: '15.09%',
  tem: '1.1780%',
  payment_date: getFormattedDate(moment().utc().add(30, 'days').toDate()),
};

export const BAD_INVESTMENT = {
  greater_opportunity: 99999,
  greater_balance: 999999999,
};

export const INVESTMENT_BANK_ACCOUNTS = [
  {
    _id: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde1c'),
    user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
    cci: '4343434343434',
    currency: 'pen',
    name: 'BBVA Continental',
    number: '4343434343434',
  },
  {
    user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
    cci: '4343434343434',
    currency: 'usd',
    name: 'MiBanco',
    number: '4343434343434',
  },
  {
    _id: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
    user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
    cci: '212121212',
    currency: 'pen',
    name: 'Interbank',
    number: '4343434343434',
  },
];

export const INVESTMENT_BANK_ACCOUNTS_SECOND_INVESTOR = [
  {
    user: mongoose.Types.ObjectId('5e2154d7d238dd379cdfc711'),
    cci: '4343434343434',
    currency: 'pen',
    name: 'BBVA Continental',
    number: '4343434343434',
  },
  {
    user: mongoose.Types.ObjectId('5e2154d7d238dd379cdfc711'),
    cci: '4343434343434',
    currency: 'usd',
    name: 'MiBanco',
    number: '4343434343434',
  },
  {
    user: mongoose.Types.ObjectId('5e2154d7d238dd379cdfc711'),
    cci: '212121212',
    currency: 'pen',
    name: 'Interbank',
    number: '4343434343434',
  },
];

export const TRANSACTION_BANK_ACCOUNTS = [
  {
    _id: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde3c'),
    user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
    cci: '8348958343',
    currency: 'usd',
    name: 'Banco de Crédito del Perú',
    number: '0112333393434',
  },
  {
    _id: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde2d'),
    user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
    cci: '212121212',
    currency: 'pen',
    name: 'BBVA Continental',
    number: '4343434343434',
  },
];

export const INVOICE_PEN: any = {
  _id: mongoose.Types.ObjectId('5f512c16d840b1332cd19354'),
  physicalInvoices: [
    {
      code: 'E001-1089',
      totalAmount: '95715.79',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
  ],
  bankAccount: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde1c'),
  //debtorAddress: 'lima',
  debtor: mongoose.Types.ObjectId('5fd769fb3983e6cb3f82a9d4'),
  // debtorContactEmail: '',
  // debtorContactName: '',
  // debtorContactPhone: '',
  expirationDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  paymentDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  currency: 'pen',
  // debtorRuc: '12121212121',
  // debtorCompanyName: 'cencosud retail peru s.a.',
  issuer: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
  status: 'for sale',
  emissionDate: '2020-05-22',
  distributionPercentage: '58.90',
  reservationPercentage: '15.00',
  tdmPercentage: '2.00',
  advancePaymentDate: moment().utc().add(1, 'days').format('YYYY-MM-DD'),
  actualPaymentDate: moment()
    .utc()
    .subtract(5, 'hours')
    .add(31, 'days')
    .format('YYYY-MM-DD'),
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const INVOICE_USD: any = {
  _id: mongoose.Types.ObjectId('5f512c16d840b1332cd19355'),
  physicalInvoices: [
    {
      code: 'E001-1089',
      totalAmount: '95715.79',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
  ],
  bankAccount: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde1c'),
  // debtorAddress: 'lima',
  // debtorContactEmail: '',
  debtor: mongoose.Types.ObjectId('5fd769fb3983e6cb3f82a9d4'),
  // debtorContactName: '',
  // debtorContactPhone: '',
  expirationDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  paymentDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  currency: 'usd',
  // debtorRuc: '12121212121',
  // debtorCompanyName: 'cencosud retail peru s.a.',
  issuer: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
  status: 'for sale',
  emissionDate: '2020-05-22',
  distributionPercentage: '58.90',
  reservationPercentage: '15.00',
  tdmPercentage: '2.00',
  createdAt: new Date(),
  updatedAt: new Date(),
  actualPaymentDate: moment()
    .utc()
    .subtract(5, 'hours')
    .add(31, 'days')
    .format('YYYY-MM-DD'),
};

export const INVOICE_PEN_255: any = {
  _id: mongoose.Types.ObjectId('5f512c16d840b1332cd19356'),
  physicalInvoices: [
    {
      code: 'E001-1089',
      totalAmount: '300',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
  ],
  bankAccount: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde1c'),
  // debtorAddress: 'lima',
  // debtorContactEmail: '',
  // debtorContactName: '',
  // debtorContactPhone: '',
  expirationDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  paymentDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  currency: 'pen',
  // debtorRuc: '12121212121',
  // debtorCompanyName: 'cencosud retail peru s.a.',
  debtor: mongoose.Types.ObjectId('5fd769fb3983e6cb3f82a9d4'),
  issuer: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
  status: 'for sale',
  emissionDate: '2020-05-22',
  distributionPercentage: '58.90',
  reservationPercentage: '15.00',
  tdmPercentage: '2.00',
  createdAt: new Date(),
  actualPaymentDate: moment()
    .utc()
    .subtract(5, 'hours')
    .add(31, 'days')
    .format('YYYY-MM-DD'),
  updatedAt: new Date(),
};

export const INVOICE_PEN_REAL_TIME: any = {
  _id: mongoose.Types.ObjectId('5f512c16d840b1332cd19354'),
  physicalInvoices: [
    {
      code: 'E001-1089',
      totalAmount: '95715.79',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
  ],
  bankAccount: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde1c'),
  // debtorAddress: 'lima',
  // debtorContactEmail: '',
  // debtorContactName: '',
  // debtorContactPhone: '',
  debtor: mongoose.Types.ObjectId('5fd769fb3983e6cb3f82a9d4'),
  expirationDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  paymentDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  currency: 'pen',
  // debtorRuc: '12121212121',
  // debtorCompanyName: 'cencosud retail peru s.a.',
  issuer: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
  status: 'for sale',
  emissionDate: '2020-05-22',
  distributionPercentage: '58.90',
  reservationPercentage: '15.00',
  tdmPercentage: '2.00',
  createdAt: new Date(),
  actualPaymentDate: moment()
    .utc()
    .subtract(5, 'hours')
    .add(31, 'days')
    .format('YYYY-MM-DD'),
  updatedAt: new Date(),
  advancePaymentDate: getFormattedDate(moment().utc().add(1, 'days').format('YYYY-MM-DD'),
  
  
  ),
};

export const INVOICE_USD_REAL_TIME: any = {
  _id: mongoose.Types.ObjectId('5f512c16d840b1332cd19355'),
  physicalInvoices: [
    {
      code: 'E001-1089',
      totalAmount: '95715.79',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
  ],
  bankAccount: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde1c'),
  // debtorAddress: 'lima',
  // debtorContactEmail: '',
  // debtorContactName: '',
  // debtorContactPhone: '',
  debtor: mongoose.Types.ObjectId('5fd769fb3983e6cb3f82a9d4'),
  expirationDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  paymentDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  currency: 'usd',
  // debtorRuc: '12121212121',
  // debtorCompanyName: 'cencosud retail peru s.a.',
  issuer: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
  status: 'for sale',
  emissionDate: '2020-05-22',
  distributionPercentage: '58.90',
  reservationPercentage: '15.00',
  tdmPercentage: '2.00',
  createdAt: new Date(),
  updatedAt: new Date(),
  actualPaymentDate: moment()
    .utc()
    .subtract(5, 'hours')
    .add(31, 'days')
    .format('YYYY-MM-DD'),
};

export const INVOICE_PEN_255_REAL_TIME: any = {
  _id: mongoose.Types.ObjectId('5f512c16d840b1332cd19356'),
  physicalInvoices: [
    {
      code: 'E001-1089',
      totalAmount: '300',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
  ],
  bankAccount: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde1c'),
  // debtorAddress: 'lima',
  // debtorContactEmail: '',
  // debtorContactName: '',
  // debtorContactPhone: '',
  debtor: mongoose.Types.ObjectId('5fd769fb3983e6cb3f82a9d4'),
  expirationDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  paymentDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  currency: 'pen',
  // debtorRuc: '12121212121',
  // debtorCompanyName: 'cencosud retail peru s.a.',
  issuer: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
  status: 'pending confirmation',
  emissionDate: '2020-05-22',
  distributionPercentage: '58.90',
  reservationPercentage: '15.00',
  tdmPercentage: '2.00',
  createdAt: new Date(),
  updatedAt: new Date(),
  actualPaymentDate: moment()
    .utc()
    .subtract(5, 'hours')
    .add(31, 'days')
    .format('YYYY-MM-DD'),
};

export const INVOICE_GROUP: any = {
  _id: mongoose.Types.ObjectId('5f512c16d840b1332cd19380'),
  debtor: mongoose.Types.ObjectId('5fd769fb3983e6cb3f82a9d4'),
  physicalInvoices: [
    {
      code: 'E001-1081',
      totalAmount: '100.00',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
    {
      code: 'E001-1082',
      totalAmount: '200.00',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
    {
      code: 'E001-1083',
      totalAmount: '300.00',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
    {
      code: 'E001-1084',
      totalAmount: '400.00',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
    {
      code: 'E001-1085',
      totalAmount: '500.00',
      retentionAmount: '0',
      file: 'facturas/FACTURAE001-23420600409361_1311820200921.XML',
    },
  ],
  bankAccount: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde1c'),
  expirationDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  paymentDate: moment().utc().add(30, 'days').format('YYYY-MM-DD'),
  currency: 'pen',
  issuer: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
  status: 'in process',
  emissionDate: '2020-05-22',
  distributionPercentage: '58.90',
  reservationPercentage: '15.00',
  tdmPercentage: '2.00',
  advancePaymentDate: getFormattedDate(
    moment().utc().add(1, 'days').format('YYYY-MM-DD')
  ),
  createdAt: new Date(),
  updatedAt: new Date(),
  actualPaymentDate: moment()
    .utc()
    .subtract(5, 'hours')
    .add(31, 'days')
    .format('YYYY-MM-DD'),
};

export const INVESTMENT = {
  status: 'investment start',
  amount: '81158.42',
  currency: 'pen',
  type: 'investment',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  invoice: mongoose.Types.ObjectId('5f512c16d840b1332cd19354'),
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const INVESTMENT_USD_INCOMPLETE = {
  status: 'investment start',
  amount: '81325.42',
  currency: 'usd',
  type: 'investment',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc712'),
  invoice: mongoose.Types.ObjectId('5f512c16d840b1332cd19355'),
  __v: 0,
};

export const INVESTMENT_INVOICES: any = [
  INVOICE_PEN,
  INVOICE_USD,
  INVOICE_PEN_255,
];

export const INVESTMENT_INVOICES_REAL_TIME: any = [
  INVOICE_PEN_REAL_TIME,
  INVOICE_USD_REAL_TIME,
  INVOICE_PEN_255_REAL_TIME,
];
export const DEPOSIT_PEN = {
  _id: mongoose.Types.ObjectId('5f2157a2a49ea620c4e7ccbd'),
  status: 'approved',
  amount: '100000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  voucher: '',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
};

export const DEPOSIT_USD = {
  _id: mongoose.Types.ObjectId('5f2157a2a49ea620c4e7ccbe'),
  status: 'approved',
  amount: '100000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  voucher: '',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'usd',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
};

export const DEPOSIT_USD_200 = {
  _id: mongoose.Types.ObjectId('5f2157a2a49ea620c4e7ccbe'),
  status: 'approved',
  amount: '30',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  voucher: '',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'usd',
};

export const TERM_INVESTMENT = {
  type: 'investment',
  title: 'Terminos',
  description: 'Terminos',
  file:
    'data:application/zip;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPEludm9pY2U+Cgo8L0ludm9pY2U+',
};

export const WITHDRAW = {
  status: 'approved',
  amount: '200.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'withdraw',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const WITHDRAW_IN_PROCESS = {
  status: 'in process',
  amount: '400.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'withdraw',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const REJECTED_WITHDRAW = {
  status: 'rejected',
  amount: '200.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'withdraw',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const RETENTION = {
  status: 'approved',
  amount: '1.00',
  type: 'retention',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const DONATE = {
  status: 'approved',
  amount: '200.00',
  type: 'donate',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const INVESTMENT_IN_PROGRESS = {
  status: 'in progress',
  amount: '200.00',
  type: 'investment',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  invoice: mongoose.Types.ObjectId('5f512c16d840b1332cd19354'),
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const INVESTMENT_START = {
  status: 'investment start',
  amount: '200.00',
  type: 'investment',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  invoice: mongoose.Types.ObjectId('5f512c16d840b1332cd19354'),
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const DEPOSIT = {
  status: 'approved',
  amount: '1000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const DEPOSIT_REJECTED_USD = {
  status: 'rejected',
  amount: '1000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'usd',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};


export const DEPOSIT_IN_PROCESS = {
  status: 'in process',
  amount: '500.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const REJECTED_DEPOSIT = {
  status: 'rejected',
  amount: '1000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const INVESTMENT_RETURN = {
  status: 'approved',
  amount: '100.00',
  type: 'investment return',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  invoice: mongoose.Types.ObjectId('5f512c16d840b1332cd19354'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const INVESTMENT_REFUNDED = {
  status: 'capital refunded',
  amount: '100.00',
  type: 'investment',
  invoice: mongoose.Types.ObjectId('5f512c16d840b1332cd19354'),
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const INVOICE_BANK_ACCOUNTS_PEN = [
  {
    _id: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
    user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc711'),
    cci: '212121212',
    currency: 'pen',
    name: 'Interbank',
    number: '4343434343434',
  },
];

export const WITHDRAW_BANK_ACCOUNTS_PEN = [
  {
    _id: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
    user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
    cci: '212121212',
    currency: 'pen',
    name: 'Interbank',
    number: '4343434343434',
  },
];

export const WITHDRAW_BANK_ACCOUNTS_USD = [
  {
    _id: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde3e'),
    user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
    cci: '85858585858585',
    currency: 'usd',
    name: 'BBVA Continental',
    number: '4747474747474',
  },
];

export const DEPOSIT_FOR_WITHDRAW_PEN = {
  status: 'approved',
  amount: '1000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const DEPOSIT_FOR_WITHDRAW_USD = {
  status: 'approved',
  amount: '1000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f463890b9c82c6cbdde3e'),
  type: 'deposit',
  user: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  createdBy: mongoose.Types.ObjectId('5f2154d7d238dd379cdfc713'),
  currency: 'usd',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  updatedAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
  __v: 0,
};

export const DEPOSIT_PEN_SECOND_INVESTOR = {
  status: 'approved',
  amount: '100000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  voucher: '',
  user: mongoose.Types.ObjectId('5e2154d7d238dd379cdfc711'),
  createdBy: mongoose.Types.ObjectId('5e2154d7d238dd379cdfc711'),
  currency: 'pen',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
};

export const DEPOSIT_USD_SECOND_INVESTOR = {
  status: 'approved',
  amount: '100000.00',
  bankAccount: mongoose.Types.ObjectId('5f1f464e90b9c82c6cbdde1e'),
  type: 'deposit',
  voucher: '',
  user: mongoose.Types.ObjectId('5e2154d7d238dd379cdfc711'),
  createdBy: mongoose.Types.ObjectId('5e2154d7d238dd379cdfc711'),
  currency: 'usd',
  createdAt: new Date(moment().utc().subtract(5, 'hours').toDate()),
};

export const INVESTMENT_TRANSACTIONS = [DEPOSIT_PEN, DEPOSIT_USD];
export const INVESTMENT_TRANSACTIONS_SECOND_INVESTOR = [
  DEPOSIT_PEN_SECOND_INVESTOR,
  DEPOSIT_USD_SECOND_INVESTOR,
];

export const COLOR_RED = 'rgba(255, 0, 0, 1)';
