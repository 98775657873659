import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SuplierService } from '../../../services/supliers.service';
import { Component, OnInit } from '@angular/core';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';

@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.scss']
})
export class AddSupplierComponent implements OnInit {
  companyInfo: any;
  
  constructor(
    private suplierService: SuplierService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private windowScrollService: WindowScrollService
  ) { }

  
  ngOnInit(): void {
  }

   saveClient() {
    if (this.companyInfo) {
      this.spinner.show();

      this.suplierService.createSuplier(this.companyInfo).subscribe((debtor) => {
        this.spinner.hide();
        if (debtor) {
          this.router.navigateByUrl('my-suppliers');
        }
      });
    }
  }

  setCompanyInfo(companyInfo) {
    this.companyInfo = companyInfo;
    this.sendEventPageView()
  }

  sendEventPageView(){
    this.windowScrollService.sendNewEvent('My Suppliers', `New Step 2`)
  }

}
