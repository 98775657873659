<app-available-balance></app-available-balance>
<hr />
<div #invoicesList>
    <div class="title-and-fetch">
        <div class="realtime-icon">
            <app-page-title class="investor-title" title="Oportunidades"></app-page-title>
            <app-realtime-connection-status></app-realtime-connection-status>
        </div>
        <!--app-manual-reload-button></app-manual-reload-button-->
        <div class="row toggle toggle-desk" *ngIf="displayToggle">
            <app-slide-toggle [checked]="default" [firstLabel]="firtsLabel" [secondLabel]="secondLabel"
                (selectionChanged)="setCurrency($event)">
            </app-slide-toggle>
        </div>
    </div>
    <div class="row toggle toggle-mob" *ngIf="displayToggle">
        <app-slide-toggle [checked]="default" [firstLabel]="firtsLabel" [secondLabel]="secondLabel"
            (selectionChanged)="setCurrency($event)">
        </app-slide-toggle>
    </div>

    <lib-tab-opportunities [notPublishedLenght]="opportunities?.length || '0'"
        [publishedLenght]="opportunitiesNotPublished.length || '0'" (selectedTab)="updateTabSelected($event)"
        [selected]="selected">
        <div class="published">
            <ng-container *ngIf="opportunities && opportunities.length > 0; else no_available_opportunities">
                <ng-container>
                    <div class="invoices-list">
                        <lib-scroll-indicator text="Más facturas"
                            [isVisible]="opportunities.length > 2 && scrollVisibility && !scrollDismissed">
                            <div class="list">
                                <app-invoice-item-investor
                                    *ngFor="let invoice of opportunities | sort:'publishAt':'asc'; let i = index"
                                    (click)="showInvest(invoice)" [key]="i" [entity]="invoice" mode="published">
                                </app-invoice-item-investor>
                            </div>
                        </lib-scroll-indicator>

                        <div class="greyed-out"
                            *ngIf="opportunities.length > 2 && scrollVisibility && !scrollDismissed"></div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="not_published">
            <ng-container
                *ngIf="opportunitiesNotPublished && opportunitiesNotPublished.length > 0; else no_available_opportunities">
                <ng-container>

                    <div class="invoices-list">
                        <lib-scroll-indicator text="Más facturas"
                            [isVisible]="opportunitiesNotPublished.length > 2 && scrollVisibilityNotPublished && !scrollDismissed">
                            <div class="list">
                                <app-invoice-item-investor
                                    *ngFor="let invoice of opportunitiesNotPublished | sort:'publishAt':'asc'; let i = index"
                                    (click)="showInvest(invoice)" [key]="i" [entity]="invoice" mode="not_published">
                                </app-invoice-item-investor>
                            </div>
                        </lib-scroll-indicator>
                        <div class="greyed-out"
                            *ngIf="opportunitiesNotPublished.length > 2 && scrollVisibilityNotPublished && !scrollDismissed">
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </lib-tab-opportunities>

    <!--app-tab (selectedTab)="updateTabSelected($event)" [selectedIndex]="selected">
    <app-tab-item [isActive]="tabPulished">
      <app-tab-label imgIcon="publish">Publicadas ({{opportunities?.length || 0}})</app-tab-label>
      <app-tab-body>
        <ng-container *ngIf="opportunities && opportunities.length > 0; else no_available_opportunities">
            <ng-container>
                <div class="invoices-list" >
                    <app-invoice-item-investor *ngFor="let invoice of opportunities | sort:'publishAt':'asc'; let i = index"
                        (click)="showInvest(invoice)" [key]="i" [entity]="invoice">
                    </app-invoice-item-investor>
                </div>
            </ng-container>
            
        </ng-container>
      </app-tab-body>
    </app-tab-item>
    <app-tab-item  [isActive]="tabNotPulished">
      <app-tab-label imgIcon="not_published">Por publicar ({{opportunitiesNotPublished?.length || 0}})</app-tab-label>
      <app-tab-body>
        <ng-container *ngIf="opportunitiesNotPublished && opportunitiesNotPublished.length > 0; else no_available_opportunities">
            <ng-container>
                <div class="invoices-list" >
                    <app-invoice-item-investor *ngFor="let invoice of opportunitiesNotPublished | sort:'publishAt':'asc'; let i = index"
                        (click)="showInvest(invoice)" [key]="i" [entity]="invoice">
                    </app-invoice-item-investor>
                </div>
            </ng-container>
        </ng-container>
      </app-tab-body>
    </app-tab-item>
</app-tab-->

</div>



<ng-template #no_available_opportunities>
    <!--Empty ooportunities template-->
    <ng-container *ngIf="opportunitiesNotPublished.length === 0 && opportunities.length === 0">
        <div class="empty-opportunities container">
            <img class="" src='../../../../../assets/icons/no-opportunities.svg'>
            <p class="disclaimer">¡Volaron las facturas! 💸 Muy pronto tendrás más Oportunidades de Inversión. 💪</p>

            <p class="disclaimer">📢 Atento a nuestro canal de <a href="https://t.me/joinchat/AAAAAEnHC4gLmAXHBI9C5g"
                    target="_blank">Telegram.</a> y visita nuestro <a href="https://blog.finsmart.pe/tag/actualidad/"
                    target="_blank">Blog de Actualidad</a> donde compartimos noticias relevantes sobre el panorama
                económico del Perú. </p>
        </div>
    </ng-container>

    <!--Empty ooportunities template-->
    <ng-container *ngIf="opportunitiesNotPublished.length === 0 && opportunities.length > 0">
        <div class="empty-opportunities container">
            <img class="" src='../../../../../assets/icons/invest.svg'>
            <p class="disclaimer">¿Con ganas de más facturas? Tranqui, muy pronto las tendrás. 😉</p>
            <p class="disclaimer">Aprovecha las <a (click)="openPublished()" target="_blank">oportunidades
                    publicadas</a> y genera rentabilidad ahora. 💰</p>

            <p class="disclaimer">☝️ Recuerda revisar la info de las próximas facturas en nuestro canal de <a
                    href="https://t.me/joinchat/AAAAAEnHC4gLmAXHBI9C5g" target="_blank">Telegram.</a> </p>
        </div>
    </ng-container>

    <!--Empty ooportunities template-->
    <ng-container *ngIf="opportunities.length === 0 && opportunitiesNotPublished.length > 0">
        <div class="empty-opportunities container">
            <img class="" src='../../../../../assets/icons/only-publish.svg'>
            <p class="disclaimer">¡Volaron las facturas! 💸 Revisa las Oportunidades de Inversión que muy pronto estarán
                <a (click)="openNotPublished()" target="_blank">publicadas.</a>😉
            </p>

            <p class="disclaimer">📢 Atento a nuestro canal de <a href="https://t.me/joinchat/AAAAAEnHC4gLmAXHBI9C5g"
                    target="_blank">Telegram.</a> y obtén información de las próximas facturas.</p>
        </div>
    </ng-container>
</ng-template>