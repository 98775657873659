import { AbstractControl, ValidatorFn, AsyncValidator, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Injector, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { bankAccountById, bankAccounts } from 'src/app/features/bank-accounts/state/bank-accounts.selectors';
import { balanceAvailable, balanceAvailableByUserId } from 'src/app/features/financial-transactions/state/financial-transactions.selectors';
import { take } from 'rxjs/operators';
import { FinancialtransactionsService } from 'src/app/features/financial-transactions/services/financial-transactions.service';
import { Currency } from '../enums/Currency.enum';
import { BankAccountsService } from 'src/app/features/bank-accounts/services/bank-accounts.service';
import { combineLatest, Subject, Observable, of } from 'rxjs';

export class SufficientBalanceValidator2 {

    static createValidator(
        bankService: BankAccountsService,
        financialService: FinancialtransactionsService): AsyncValidatorFn {
            return async (control: AbstractControl): Promise<ValidationErrors> => {

                if(!control.parent) {
                    return null;
                }

                const selectedBankAccountId = control.parent.controls['bankAccount']?.value;
                const forUserId = control.parent.controls['user'].value;
                const amount = Number(control.value);

                let balance, bankAccount, currencyBasedBalance;

                if(selectedBankAccountId && amount && forUserId) {
                    balance = await financialService.getInvestorAvailableBalance(forUserId).toPromise();
                    bankAccount = await bankService.getByAccountId(selectedBankAccountId).toPromise();
                    currencyBasedBalance = Number(balance[bankAccount.currency]);    
                }

                const isSffucientBalance = (currencyBasedBalance >= amount) ? true : false;    
                
                if(isSffucientBalance) {
                    return null;
                } else {
                    return { sufficientBalance: true };
                }

            };
    }






}
