import { AbstractControl } from '@angular/forms';

export function RequiredValidator(control: AbstractControl) {
  if (!control.value) return { required: true };
  let isValid;

  if (control.value.constructor === Object) {
    const isEmptyObject = Object.keys(control.value).length === 0;
    isValid = !isEmptyObject;
  } else {
    const isWhitespace = (String(control.value) || '').trim().length === 0;
    isValid = !isWhitespace;
  }

  return isValid ? null : { required: true };
}
