import { Injectable } from '@angular/core';
import { RESTService } from 'src/app/shared/services/rest.service';
import { Observable, EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { formatFilter } from 'src/app/shared/util/api.util';
import { retry, catchError, shareReplay } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FinancialtransactionsService {
  constructor(private rest: RESTService) {}

  get(skip, limit, filter): Observable<any> {
    delete filter.pagination
    const filterHasValues = filter
      ? Object.values(filter).some((v) => {
          if (Array.isArray(v)) {
            return v.length > 0 ? true : false;
          } else {
            return !!v;
          }
        })
      : undefined;
    if (filterHasValues) {
      const filtersArray = [];

      const statusFilter = formatFilter(filter.statuses, 'status', 'in');
      const typesFilter = formatFilter(filter.types, 'type', 'in');
      const createdFromFilter = formatFilter(
        filter.createdFrom,
        'createdAt',
        'gte'
      );
      const createdToFilter = formatFilter(
        filter.createdTo,
        'createdAt',
        'lte'
      );
      const investorFilter = formatFilter(filter.investor, 'user', 'eq');
      const amountFilter = filter.amount
        ? formatFilter(Number(filter.amount).toFixed(2), 'amount', 'eq')
        : undefined;
      const currenciesFilter = formatFilter(
        filter.currencies,
        'currency',
        'in'
      );

      if (statusFilter) filtersArray.push(statusFilter);
      if (typesFilter) filtersArray.push(typesFilter);
      if (createdFromFilter) filtersArray.push(createdFromFilter);
      if (createdToFilter) filtersArray.push(createdToFilter);
      if (investorFilter) filtersArray.push(investorFilter);
      if (amountFilter) filtersArray.push(amountFilter);
      if (currenciesFilter) filtersArray.push(currenciesFilter);

      return this.rest.get(
        `/financial-transactions?skip=${skip}&limit=${limit}&filter=${filtersArray.join(
          ','
        )}`
      );
    } else {
      return this.rest.get(
        `/financial-transactions?skip=${skip}&limit=${limit}`
      );
    }
  }

  getAll(): Observable<any> {
    return this.rest.get(`/financial-transactions`);
  }
  getInvestorAvailableBalance(userId): Observable<any> {
    return this.rest.get(`/investors/available-balance/${userId}`);
  }

  getDepositVoucher(transactionId): Observable<any> {
    return this.rest.get(`/financial-transactions/${transactionId}/voucher`);
  }

  create(data): Observable<any> {
    return this.rest.post(`/financial-transactions`, data);
  }

  edit(data): Observable<any> {
    // console.log(data)
    if (data.hasOwnProperty("rejectionReason")){
      return this.rest.patch(`/financial-transactions/${data._id}`, {
        status: data.status,
        rejectionReason: data.rejectionReason
      });
    }

    if (data.hasOwnProperty("documentNumber")){
      return this.rest.patch(`/financial-transactions/${data._id}`, {
        status: data.status,
        documentNumber: data.documentNumber
      });
    }

    return this.rest.patch(`/financial-transactions/${data._id}`, {
      status: data.status,
    });
  }

  reading(data): Observable<any> {
    //console.log(data)
    return this.rest.patch(`/financial-transactions/${data._id}`,
    {
      isNewAnnouncement: data?.isNewAnnouncement,
    });
  }

  save(data, mode): Observable<any> {
    let dataFinal = data
    if(data.reason === ""){
      dataFinal = _.omit(data, 'reason');
    }
    if(data.otherReason === ""){
      dataFinal = _.omit(dataFinal, 'otherReason');
    }
    switch (mode) {
      case 'create':
        return this.create(dataFinal);
      case 'edit':
        return this.edit(dataFinal);
    }
  }

  downloadExcel() {
    return this.rest.get(`/financial-transactions/file`);
  }

  historicInvestment(debtorId, currency): Observable<any> {
    return this.rest.get(`/debtor/history/${debtorId}?currency=${currency}`,);
  }

  checkDocumentNumberDuplication(documentNumber): Observable<any> {
    return this.rest.get(`/financial-transactions/duplications/${documentNumber}`)
  }
}
