import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { InvoiceSelectors } from '../../../../invoices/state/invoices.selector-types';
import { INVOICE_STATUS } from 'src/app/features/invoices/UI/enums/INVOICE_STATUS.enum';
import { InvoiceService } from 'src/app/features/invoices/services/invoice.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddContactDialogComponent } from '../../../../my-clients/UI/organisms/add-contact-dialog/add-contact-dialog.component';
import { ClientService } from './../../../../my-clients/services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { AddContactConfirmationScreenComponent } from '@finsmart/ui-lib/lib/components/v1/3-organisms/add-contact-confirmation-screen/add-contact-confirmation-screen.component';
import { AddContactConfirmationComponent } from '../../organisms/add-contact-confirmation/add-contact-confirmation.component';
import { InvoicesActions } from 'src/app/features/invoices/state/invoices.action-types';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss'],
})
export class ClientDetailComponent implements OnInit {
  debtorId;
  debtorStats;
  invoices$;
  debtorInformation;
  contactsDebtor = [];
  actualInvoicesStatus = [
    INVOICE_STATUS.AWAITING_COLLECTION,
    INVOICE_STATUS.COLLECTED,
  ];
  loggedInUserID;
  invoices;
  eventClient = {
    event: '[Platform][Form][Input]',
    _inputCategory: 'Review Client / Detail',
    _inputName: undefined,
  };
  contactInformation;
  subscription;
  openModalContact = false;
  invoicesPaginated;

  pagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  defaultPagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  constructor(
    private store: Store<AppState>,
    public invoiceService: InvoiceService,
    public dialog: MatDialog,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    public gtmService: GTMService,
    private windowScrollService: WindowScrollService,
    private overlayService: OverlayService
  ) { }

  ngOnInit() {
    this.store
      .select(UsersSelectors.profileProperty('_id'))
      .pipe(take(1))
      .subscribe((role) => (this.loggedInUserID = role));

    this.subscription = this.overlayService
      .getCloseEmitter()
      .subscribe((obs) => {
        if (this.openModalContact) {
          this.addContact(this.debtorInformation?.companyRuc, this.contactInformation);
        }
      });
    this.route.params.subscribe((params) => {
      this.debtorId = params.debtorId;
      if (this.debtorId) {
        this.spinner.show();
        this.fetchDebtor();
        this.fetchContact();
      }
    });

  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',

      position: {
        left: '0',
      },
      data: data,
    });
    return dialogRef;
  }

  fetchDebtor() {
    this.openModalContact = false;
    this.clientService.byRuc(this.debtorId).subscribe((debtor) => {
      this.debtorInformation = debtor;
      this.fetchContact();
      this.fetchInvoice();
    });
  }

  addContact(ruc, data?) {
    this.sendEventClickContact();
    let dialogRef = this.openDialogHelper(AddContactDialogComponent, {
      ruc,
      debtor: this.debtorInformation._id,
      data
    });

    this.connectDialogListeners(dialogRef, (result) => {
      this.openModalContact = true;

      if (result) {
        this.contactInformation = { ...result };

        result.debtor = this.debtorInformation._id;

        this.overlayService.startConfirmation(
          { ...result, _id: this.debtorInformation._id },
          {
            component: AddContactConfirmationComponent,
            entity: { ...result },
          },
          'details',
          'create',
          null,
          InvoicesActions.SaveContact,
          `my-clients/${this.debtorInformation._id}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onConfirm: {
              title: 'Confirmación datos del contacto',
            },
            onSuccess: {
              showCheck: true,
              title: 'Se agregó el contacto correctamente',
            },
          },
          this.fetchDebtor.bind(this)
        );
        // this.clientService.createContactDebtor(result).subscribe((contact) => {
        //   this.fetchDebtor();
        // });
      } else {
        this.openModalContact = false;
      }
    });
    this.sendEventPageView();
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  openIntercom() {
    (<any>window).Intercom('show');
  }

  fetchContact() {
    this.clientService
      .getAllContacts(this.debtorId)
      .subscribe((data: any) => {
        this.contactsDebtor = data;
      });
  }

  fetchInvoice() {
    this.store.select(InvoiceSelectors.all).subscribe((invoiceDB) => {
      const debtorRuc = this.debtorInformation.companyRuc;
      this.invoices = invoiceDB.filter(function (item) {
        if (item['debtor']) {
          return item['debtor'].companyRuc === debtorRuc;
        }
      });

      this.pagination.total = this.invoices.length || 0;

      this.invoicesPaginated = this.invoices;

      this.invoicesPaginated = this.invoicesPaginated.slice(
        this.pagination.skip,
        this.pagination.limit + this.pagination.skip
      );

      this.spinner.hide();
    });
  }

  openInvoice() {
    this.sendEventClickInvoice();
    this.router.navigateByUrl('/invoices/create');
  }

  openInvoiceDetail(id) {
    this.sendEventClickInvoiceDetail();
    this.router.navigateByUrl('/invoices/' + id);
  }

  sendEventClickContact() {
    let eventCategory = 'Review Client / New Contact';
    let eventName = 'Clicked Nuevo Contacto In New Contact';
    this.sendEvent(eventName, eventCategory);
  }

  sendEventClickInvoice() {
    let eventCategory: 'Review Client / Detail';
    let eventName = 'Clicked Nueva Factura In Detail';
    this.sendEvent(eventName, eventCategory);
  }

  sendEventClickInvoiceDetail() {
    let eventCategory: 'Review Client / Detail';
    let eventName = 'Clicked Una Factura In Detail';
    this.sendEvent(eventName, eventCategory);
  }

  sendEvent(eventName, eventCategory?) {
    let event = this.eventClient;
    event._inputName = eventName;
    if (eventCategory) {
      event._inputCategory = eventCategory;
    }
    this.gtmService.newEvent(event);
  }

  sendEventPageView() {
    this.windowScrollService.sendNewEvent('My Clients', `Detail New Contact`);
  }

  ngOndestroy() {
    this.subscription.unsubscribe();
    this.openModalContact = false;
  }

  paginationHandler(event) {
    this.pagination.skip = event.skip;
    this.invoicesPaginated = this.invoices;
    this.invoicesPaginated = this.invoicesPaginated.slice(
      this.pagination.skip,
      this.pagination.limit + this.pagination.skip
    );
  }

}
