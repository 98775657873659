<h1>Partir operación</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="controls">
    <app-input
      inputError="true"
      formControlName="partialAmount"
      autocomplete="off"
      type="number"
      [default]="data?.data?.partialAmount"
      label="Monto de pago"
    >
    </app-input>
    <app-date-picker
      formControlName="partialPaymentDate"
      label="Fecha Cobro Real"
      [default]="data?.data?.partialPaymentDate"
    ></app-date-picker>
    <p class="tiny tiny-date">
      Fecha cobro proyectada: {{ data.paymentDate | formatDate }}
    </p>
  </div>

  <div *ngIf="showDateWarning">
    <h3>AVISO!</h3>
    <p class="warning">
      La fecha de cobro real seleccionada es anterior a la fecha de cobro
      prevista.
    </p>
    <input type="submit" class="button button-red" value="¡Sí, proceder!" />
  </div>
  <div class="button-container">
    <div *ngIf="!showDateWarning">
      <fs-ui-button
        [action]="'submit'"
        (click)="onSubmit()"
        [label]="'Confirmar'"
        [type]="'secondary'"
      ></fs-ui-button>
    </div>
    <fs-ui-button
      (click)="cancel()"
      [label]="'Cancelar'"
      [type]="'tertiary'"
    ></fs-ui-button>
  </div>
</form>
