import { createAction } from '@ngrx/store';

export const ShowSideMenu = createAction(
    '[Shared] Show Side Menu'
);
export const HideSideMenu = createAction(
    '[Shared] Hide Side Menu'
);
export const ResetShared = createAction(
    '[Shared] Reset'
);
export const HideToggleMenu = createAction(
    '[Shared] HideToggle'
);
export const ShowToggleMenu = createAction(
    '[Shared] ShowToggle'
);




