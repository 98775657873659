<div class="container" *ngIf="entity.mode !== 'edit'">
    <p class="title">Vas a registrar la siguiente actualización:</p>
    <div class="one-line">
        <p class="comment">Comentario</p>
        <div class="message innerHTML" [innerHTML]="(entity?.message) | safeHtml"></div>
    </div>
    <hr>
    <div class="icon-text mr">
        <img src="../../../../../../assets/icons/clip.svg" />
        <span [ngClass]="entity?.file ? 'blue-text ml-4' : 'ml-4'">
            {{ entity?.filename || 'Sin adjunto' | capitalizeFirstLetters }}
        </span>
    </div>
</div>
<div class="container" *ngIf="entity.mode === 'edit'">
    <p class="title">Vas a registrar la siguiente edición:</p>

    <div class="compare">
        <div class="compare-item">
            <div class="one-line">
                <p class="compare-title">Antes</p>
                <p class="comment">Comentario</p>
                <div class="message innerHTML" [innerHTML]="(entity?.savedOriginalMessage?.message) | safeHtml"></div>
            </div>
            <hr>
            <div class="icon-text mr">
                <img src="../../../../../../assets/icons/clip.svg" />
                <span [ngClass]="entity?.savedOriginalMessage?.file ? 'blue-text ml-4' : 'ml-4'">
                    {{ entity?.savedOriginalMessage?.filename || 'Sin adjunto' | capitalizeFirstLetters }}
                </span>
            </div>
        </div>
        <div class="compare-item">
            <div class="one-line">
                <p class="compare-title">Ahora</p>
                <p class="comment">Comentario</p>
                <div class="message innerHTML" [innerHTML]="(entity?.message) | safeHtml"></div>
            </div>
            <hr>
            <div class="icon-text mr">
                <img src="../../../../../../assets/icons/clip.svg" />
                <span [ngClass]="entity?.file ? 'blue-text ml-4' : 'ml-4'">
                    {{ entity?.filename || 'Sin adjunto' | capitalizeFirstLetters }}
                </span>
            </div>
        </div>
    </div>

</div>