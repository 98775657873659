import { ClientService } from './../../../services/client.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { Observable, Subject } from 'rxjs';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { InvestInInvoiceComponent } from '../../../../opportunities/UI/templates/invest-in-invoice/invest-in-invoice.component';
import { takeUntil, take } from 'rxjs/operators';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { createDateOnly } from 'src/app/shared/util/date.util';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceStatus } from 'src/app/shared/enums/InvoiceStatus.enum';
import moment from 'moment';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ListClientsComponent implements OnInit {
  clients = [];
  _clientRuc

  constructor(
    private clientService: ClientService,
    private spinner: NgxSpinnerService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {

    this.store
      .select(UsersSelectors.profileProperty('companyRuc'))
      .pipe(take(1))
      .subscribe((clientCompanyRuc) => {
        this._clientRuc = clientCompanyRuc;
      });


    this.clientService.getAllDebtors().subscribe((myClients) => {
      let clients = myClients.filter( client => client.companyRuc != this._clientRuc)
      this.clients = clients;
    });
    
  }
}
