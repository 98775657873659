import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-montoring-active-debtors-table',
  templateUrl: './montoring-active-debtors-table.component.html',
  styleUrls: ['./montoring-active-debtors-table.component.scss']
})
export class MontoringActiveDebtorsTableComponent implements OnInit {
  @Input() evaluations;
  @Output() pospone = new EventEmitter();
  @Output() sortEval = new EventEmitter();
  @Input() usedCreditLine: boolean = false;
  @Input() evaluationFinishedAt: boolean = false;
  @Input() active = 'evaluationFinishedAt'
  currentValue
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
   
  }
  
  formateDate(date){
    return moment(date).format('DD/MM/YYYY');
  }

  posponeEvaluation(data){
    this.pospone.emit(data)
  }

  goTo(evaluation){
    const navigationExtra: NavigationExtras = {
      state: { showPostpone: true },
    };
    this.router.navigate([`/risk-debtors/${evaluation.debtor._id}`], navigationExtra);
  }
  sortByCreditLine(val){
    this.currentValue = val
    if(this.active  === this.currentValue){
      this[this.currentValue] = !this[this.currentValue];
    }else{
      this[val] = false;
    }
    this.active = this.currentValue;
    let data = {
      property: val,
      order: this[val] === true ? 'asc' : 'desc'
    }
    this.sortEval.emit(data)
  }

  sortByEvaluation(val){
    this.currentValue = val
    if(this.active  === this.currentValue){
      this[this.currentValue] = !this[this.currentValue];
    }else{
      this[val] = false;
    }
    this.active = this.currentValue;
    let data = {
      property: val,
      order: this[val] === true ? 'asc' : 'desc',
    }
    localStorage.setItem('monitoring_filters', JSON.stringify(data))
    this.sortEval.emit(data)
  }
  
}
