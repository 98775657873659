import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-descript-operation',
  templateUrl: './invoice-descript-operation.component.html',
  styleUrls: ['./invoice-descript-operation.component.scss']
})
export class InvoiceDescriptOperationComponent implements OnInit {

  @Input() entity;
  constructor() { }

  ngOnInit(): void {
  }

}
