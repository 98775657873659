<ng-container [ngSwitch]="data?.type">
    <ng-container *ngSwitchCase="'risk'">
        <div class="container">
            <div class="row">
                <p class="main-title">{{title}} ({{percentage}})</p>
                <div class="icon-text" (click)="goToDetail()" *ngIf="profile.role == 'risk analyst'">
                    <lib-icon color="blue" class="right-space" icon="open_new"></lib-icon>
                    <lib-text color="blue" size="xs" weight="medium" [content]="'Revisar pagador'">
                    </lib-text>
                </div>
            </div>

            <lib-kanban-dashboard columnHeight="l" [data]="componentData.checklist"
                [isDisable]="(profile.role !== 'risk analyst') || statusEnabledRisk()" (dropEvent)="drop($event)">
            </lib-kanban-dashboard>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'documents'">
        <div class="container">
            <div class="row">
                <p class="main-title">{{title}} ({{percentage}})</p>
                <div class="icon-text" (click)="goToDetail()" *ngIf="profile.role == 'admin'">
                    <lib-icon color="blue" class="right-space" icon="open_new"></lib-icon>
                    <lib-text color="blue" size="xs" weight="medium" [content]="'Revisar documentos'">
                    </lib-text>
                </div>
            </div>

            <lib-documents-modal [viewerRole]="profile.role" (dropEvent)="drop($event)"
                [supplierInformation]="componentData?.invoice?.isConfirming ? componentData?.invoice?.supplier : ''"
                [companyInformation]="componentData?.invoice?.issuer" [isDisabled]="statusEnabled()"
                [isConfirming]="componentData?.invoice?.isConfirming" [dataSupplier]="componentData.checklistSupplier"
                [data]="componentData.checklist"></lib-documents-modal>

        </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <div class="container">
            <div class="row">
                <p class="main-title">{{title}} ({{percentage}})</p>
                <div class="icon-text" (click)="goToDetail()" *ngIf="profile.role == 'admin'">
                    <lib-icon color="blue" class="right-space" icon="open_new"></lib-icon>
                    <lib-text color="blue" size="xs" weight="medium" [content]="'Revisar operación'">
                    </lib-text>
                </div>
            </div>

            <lib-kanban-dashboard columnHeight="xs" [data]="componentData.checklist"
                [isDisable]="(profile.role != 'admin' && profile.role != 'super admin') || statusEnabled()" (dropEvent)="drop($event)">
            </lib-kanban-dashboard>
        </div>
    </ng-container>

</ng-container>