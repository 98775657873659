import { ThrowStmt } from '@angular/compiler';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-email-row-operation',
  templateUrl: './email-row-operation.component.html',
  styleUrls: ['./email-row-operation.component.scss'],
})
export class EmailRowOperationComponent implements OnInit, OnChanges {
  @Input() role;
  @Input() title: String;
  @Input() subject: String;
  @Input() status: String;
  @Input() sendTo;
  @Input() cc;
  @Input() count: String;
  @Input() type;
  @Input() checklist;
  @Input() isExternal;
  @Input() detail;
  @Input() blockersInvoiceForSale;
  displayBubble = false;
  abbleToDisplay = false;
  isDisabledEmail = new Boolean();
  originalEmail;
  originalcc;
  @Output() senEmail = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    if (this.sendTo) {
      this.originalEmail = this.sendTo;
      this.cleanEmail();
    }

    if (this.cc) {
      this.originalcc = this.cc;
      this.cc = this.cc.map((el) => el.slice(0, el.indexOf('@'))).join(', ');
      // this.cleanEmailCC();
    }
  }

  checkForDisable() {
    this.isDisabledEmail = this.isDisabled();
  }

  cleanEmail() {
    let newEmail = this.sendTo;

    if (newEmail.includes(',')) {
      newEmail = newEmail.split(',');

      newEmail = newEmail.map((e: any) => e.split('@')[0]).join('\n');
    } else {
      newEmail = newEmail.split('@')[0];
    }

    this.sendTo = newEmail;
  }

  cleanEmailCC() {
    let newEmail = this.cc;
    newEmail = newEmail.map((e) => e.split('@')[0]);
    this.cc = newEmail;
  }

  sendEmail(eventName, type) {
    if (type == 'DESEMBOLSO' && this.isDisabled()) {
      return;
    }
    let data = {
      isExternal: eventName,
      type: type,
      sendTo: this.originalEmail,
      cc: this.originalcc ? this.originalcc.toString() : '',
      subject: this.subject,
    };
    this.senEmail.emit(data);
  }

  getHour(time) {
    return moment(time).format('hh:mm a');
  }

  getCount(count) {
    return Number(count) - 1;
  }

  isDisabled() {
    const isAccountManagerRole = ['account manager', 'account manager supervisor', 'admin', 'super admin'].includes(this.role);
    const isCorreoBType = this.type === 'CORREO_B';
    
    if (isAccountManagerRole && isCorreoBType) {
      return false;
    }
  
    if ((this.role === 'account manager' || this.role === 'account manager supervisor') && !isCorreoBType) {
      return true;
    }
  
    if (!isCorreoBType) {
      if (this.type !== 'DESEMBOLSO') {
        return false;
      }
  
      const hasBlockers = this.blockersInvoiceForSale.some(item => item.value === true);
      this.abbleToDisplay = hasBlockers;
      return hasBlockers;
    }
   
  }

  mouseEnter() {
    this.displayBubble = true;
  }

  mouseLeave() {
    this.displayBubble = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.blockersInvoiceForSale.currentValue) {
      this.blockersInvoiceForSale = changes.blockersInvoiceForSale.currentValue
      setTimeout(() => {
        this.checkForDisable()
      }, 500);
    }

    if (changes.role) {
      this.checkForDisable();
    }
  }
}
