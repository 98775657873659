import { totalRetention } from './../../../../features/financial-transactions/state/financial-transactions.selectors';
import { Component, OnInit, Input} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { totalInInvestment, balanceAvailable, totalInPending, totalInInvestmentStart, grossProfit } from 'src/app/features/financial-transactions/state/financial-transactions.selectors';
import { totalProfit } from 'src/app/features/financial-transactions/state/financial-transactions.selectors';


@Component({
  selector: 'app-available-to-invest-balance',
  templateUrl: './available-to-invest-balance.component.html',
  styleUrls: ['./available-to-invest-balance.component.scss']
})
export class AvailableToInvestBalanceComponent implements OnInit {
  penInInvestment$;
  usdInInvestment$;
  penAvailable$;
  usdAvailable$;
  penInInvestmentStart$;
  usdInInvestmentStart$;
  penInEvaluation$;
  usdInEvaluation$;
  penTotalRetention$
  penBalance$;
  penGrossBalance$;
  totalRetention$;
  usdBalance$;
  usdGrossBalance$;
  usdTotalRetention$;
  
  @Input() currency: 'pen' | 'usd' = 'pen';

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
      this.getPenBalance()
      this.getUsdbalance()
  }

  getUsdbalance(){
    this.usdInInvestment$ = this.store.select(totalInInvestment('usd'));
    this.usdAvailable$ = this.store.select(balanceAvailable('usd'));
    this.usdInEvaluation$ = this.store.select(totalInPending('usd'));
    this.usdInInvestmentStart$ = this.store.select(totalInInvestmentStart('usd'));
    this.usdBalance$ = this.store.select(totalProfit('usd'));
    this.usdGrossBalance$ = this.store.select(grossProfit('usd'));
    this.usdTotalRetention$ = this.store.select(totalRetention('usd'));
  }

  getPenBalance(){
    this.penInInvestment$ = this.store.select(totalInInvestment('pen'));
    this.penAvailable$ = this.store.select(balanceAvailable('pen'));
    this.penInEvaluation$ = this.store.select(totalInPending('pen'));
    this.penInInvestmentStart$ = this.store.select(totalInInvestmentStart('pen'));
    this.penBalance$ = this.store.select(totalProfit('pen'));
    this.penGrossBalance$ = this.store.select(grossProfit('pen'));
    this.penTotalRetention$ = this.store.select(totalRetention('pen'));
  }
  
}
