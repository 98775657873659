<h1>Configurar Factura</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <app-input [(ngModel)]="tdmPercentage" (change)="calculateEvaluation($event?.target?.value)"
            (ngModelChange)="calculateEvaluation($event?.target?.value)" formControlName="tdmPercentage"
            autocomplete="off" type="number"
            [label]="invoice.isConfirming ? '% Costo mensual de Confirming (Antes TCEM)' : '% Costo mensual de Factoring (Antes TCEM)'">
        </app-input>
        <div class="previous-values">
            <p class="previo-data">Valor anterior: {{ configuration?.tdmPercentage || '---'}} </p>
        </div>
        <app-input [(ngModel)]="reservationPercentage" (change)="calculateEvaluation($event?.target?.value)"
            (ngModelChange)="calculateEvaluation($event?.target?.value)" formControlName="reservationPercentage"
            autocomplete="off" type="number" label="% Garantía">
        </app-input>
        <div class="previous-values">
            <p class="previo-data">Valor anterior: {{ configuration?.reservationPercentage || '---'}} </p>
        </div>
        <!-- <app-input [(ngModel)]="tea" (focusout)="calculateEvaluation($event.target.value)"
            formControlName="tea" [default]="data?.tea" autocomplete="off" type="number"
            label="% Tasa anualizada">
        </app-input>
        <div class="previous-values">
            <p class="previo-data">Valor anterior: {{ configuration?.tea || '---'}} </p>
        </div> -->
        <div class="one-line evaluation-label">
            <p class="evaluation-text">
                Colchón de días
            </p>
            <p>
                <span class="evaluation-text" *ngIf="!isNumber(evaluation)">
                    ---
                </span>
                <span
                    [ngClass]="{'eval-red': evaluation <=15, 'eval-yellow': evaluation >15 && evaluation <=30, 'eval-green':evaluation > 30}"
                    *ngIf="isNumber(evaluation)" class="evaluation-text">
                    {{evaluation}}
                </span>
            </p>
        </div>
        <ng-container *allowedRoles="['account manager']">
            <div *ngIf="isNumber(evaluation) && evaluation <= 15" class="error">
                <span class="error-label">El colchón de días no puede ser menor o igual a 15 días. Por favor, revise la
                    garantía colocada.</span>
            </div>
        </ng-container>

    </div>

    <div class="button-container button-direction-column">
        <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Siguiente'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>