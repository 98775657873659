<app-page-title title="Donación a Acción Emprendedora"></app-page-title>

<div class="donate">
    <p>Con esta donación ayudarás a transformar la vida de cientos de emprendedores peruanos.</p>
    <a target="_blank" href="https://finsmart.pe/campana-ae/">¡Conoce más aquí!</a>
</div>

<app-available-balance [forUserId]="selectedUserId"></app-available-balance>

<app-financial-transaction-form
    mode="donate" 
    (OnSelectedUserChanged)="selectedUserChanged($event)"
    (onSubmit)="submitHandler($event)">
</app-financial-transaction-form>

<div class="button-container">
    <fs-ui-button routerLink="/financial-transactions" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
</div>