import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AppState } from 'src/app/app.states';
import { Store, Action } from '@ngrx/store';
import {
  tap,
  map,
  catchError,
  mergeMap,
  switchMap,
  shareReplay,
  concatMap,
  take,
  skipWhile,
} from 'rxjs/operators';
import { Observable, pipe, of } from 'rxjs';
import { UsersActions } from './users.action-types';
import { UsersService } from '../services/users.service';
import { AuthSelectors } from '../../authentication/state/auth-selector-types';
import { CapitalizeFirstLettersPipe } from 'src/app/shared/pipes/capitalize-first-letters.pipe';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { GAService } from 'src/app/shared/services/ga.service';
import { HotjarService } from 'src/app/shared/services/hotjar.service';
import { FacebookService } from 'src/app/shared/services/facebook.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersEffects {
  $loadAll = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.LoadAll),
      concatMap((action) => this.usersService.getAll()),
      map((entities) => UsersActions.LoadedAllSuccess({ ...entities }))
    )
  );
  $loadProfile = createEffect(() =>
    this.actions$.pipe(
      tap((action) => {
        //console.log(action)
      }),
      ofType(UsersActions.LoadProfile),
      concatMap((action) =>
        this.usersService.getProfile().pipe(
          map((profile) => {
            if (
              !profile.hasOwnProperty('isConfirming') &&
              profile.role === Roles.CLIENT
            ) {
              profile.isConfirming = false;
            }

            if (
              !profile.hasOwnProperty('legalRep') &&
              !profile.hasOwnProperty('legalCounter') &&
              !profile.hasOwnProperty('legalComment') &&
              !profile.hasOwnProperty('profession') &&
              profile.role === Roles.CLIENT
            ) {
              profile.legalRep = null;
              profile.legalCounter = 0;
              profile.profession = null;
            }

            if (profile.role === 'investor' && profile.idType !== 'ruc') {
              profile.gettingFromAPi = true;
            }

            return UsersActions.LoadProfileSuccess({ entity: profile });
          }),
          catchError((error) => of(UsersActions.SaveFailed({ error })))
        )
      )
    )
  );

  $saving = createEffect(
    () =>
      this.actions$.pipe(
        tap((action) => {
          //console.log(action)
        }),
        ofType(UsersActions.Saving),
        concatMap((action) =>
          this.usersService.saveUser(action.entity, action.mode).pipe(
            map((receivedEntity) => {
              if (action.mode === 'create') {
                this.gtmService.newRegistration(
                  receivedEntity.user.role,
                  receivedEntity.user._id
                );
                this.fbService.newEvent(environment.name === 'production' ?
                    (receivedEntity.user.role === 'investor' ? 'InversionistaRegistrado' : 'ClienteRegistrado')
                    : 'TEST', receivedEntity.user._id)
              }

              return UsersActions.SaveSucceeded({ result: receivedEntity });
            }),
            catchError((error) => {
              const eventName = 'new_account';
              const eventData = {
                create_date: new Date().toISOString(),
                page_url: action.entity.urlType,
                account_type: action.entity.role,
                is_success: false,
                error_description: `Unkown error ${error.status}`,
              };
              this.gaService.sendEvent(eventName, eventData);
              this.mixpanelService.newEvent(eventName, eventData);
              return of(UsersActions.SaveFailed({ error }));
            })
          )
        )
      ),
    { dispatch: true }
  );
  $savingProfile = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UsersActions.SavingProfile),
        concatMap((action) =>
          this.usersService.saveProfile(action.entity, action.mode).pipe(
            map((receivedEntity) =>
              UsersActions.SaveProfileSucceeded({ result: receivedEntity })
            ),
            catchError((error) => of(UsersActions.SaveProfileFailed({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  $setProfile = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UsersActions.SetProfile),
        tap((action) => {
          this.store
            .select(AuthSelectors.accessToken)
            .pipe(
              skipWhile((res) => res === null),
              take(1)
            )
            .subscribe((accessToken) => {
              this.socketService.setupSocketConnection(accessToken);
            });

          this.store
            .select(AuthSelectors.authEmail)
            .pipe(take(1))
            .subscribe((email) => {
              // console.log('SetProfile', action._id, action, email)
              this.gtmService.setIdentity(
                action._id,
                this.capitalizeFirstLettersPipe.transform(action.names),
                action.role,
                email,
                action.balance,
                action.channel,
                action.isNewsletter,
                action.companyName,
                action.status,
                action.isConfirming,
                action.companyRuc,
                action.conversionCount,
                action.conversionAmount,
                action.conversionLastTime,
                action.hasDeposit,
                action.hasInvestment,
                action.hasInvestmentDelayed,
                action.depositsAmount,
                action.balancePEN,
                action.balanceUSD,
                action.phoneNumber,
                action.segment,
                action.accountManager
              );
              this.mixpanelService.setIdentity(
                action._id,
                email,
                action.role,
                this.capitalizeFirstLettersPipe.transform(
                  `${action.names} ${action.familyNames}`
                ),
                action.channel,
                action.isNewsletter,
                action.companyName,
                action.status,
                action.isConfirming,
                action.companyRuc,
                action.conversionCount,
                action.conversionAmount,
                action.conversionLastTime,
                action.segment
              );

              this.hotjarService.setIdentity(action._id, { role: action.role });
            });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private usersService: UsersService,
    private gtmService: GTMService,
    private capitalizeFirstLettersPipe: CapitalizeFirstLettersPipe,
    private socketService: SocketService,
    private mixpanelService: MixpanelService,
    private gaService: GAService,
    private hotjarService: HotjarService,
    private fbService: FacebookService
  ) {}
}
