import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(value.toLowerCase()) {
      case 'pen':
        return 'S/';
      case 'usd':
        return '$';
    }
  }

}
