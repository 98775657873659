import { isArray } from 'lodash';

export const formatFilter = (values, entity, operator) => {
    if(values !== undefined && values !== null) {

        if(isArray(values)) {
            return values.reduce((acc, curr, index) => {
                acc += `${entity}:${operator}:${curr}`;
                if(values.length -1 > index) acc+=',';
            
                return acc;
            }, '');    
        } else {
            return `${entity}:${operator}:${values}`;
        }

    }
}