<div class="container">
    <div class="item invoice-item">
        <div class="bank-and-currency row">
            <div>
                <p class="tiny tiny-title">Monto neto</p>
                <lib-money  [amount]="entity.netAmount" [currency]="entity.currency"></lib-money>

            </div>
            <div class="status">
                <app-invoice-status [status]="entity.status"></app-invoice-status>
            </div>
        </div>
        <div class="bank-and-currency row">
            <div>
                <p *ngIf="entity.physicalInvoices.length === 1" class="tiny tiny-title lh">Código de la factura</p>
                <p *ngIf="entity.physicalInvoices.length > 1" class="tiny tiny-title lh">Códigos de las facturas
                    ({{entity.physicalInvoices.length}})</p>
                <span class="code"
                    *ngFor="let subInvoice of entity.physicalInvoices; let isLast = last; let index = index">
                    <ng-container *ngIf="index<2">
                        {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                    </ng-container>
                    <ng-container *ngIf="isLast && index >= 2">
                        ...
                    </ng-container>
                </span>
            </div>
            <div *ngIf="entity.status === 'finalized' || entity.status === 'collected'" class="creation-date">
                <app-date title="Fecha efectiva de pago" [date]="entity.actualPaymentDate"></app-date>
            </div>
            <div *ngIf="entity.status != 'finalized' && entity.status != 'collected'" class="creation-date">
                <app-date title="Fecha de pago" [date]="entity.paymentDate"></app-date>
            </div>
        </div>
    </div>
</div>