<div class="mt-25 pl-20">
    <p><span>Si eres una empresa</span> haz Factoring para adelantar tus facturas o Confirming para agilizar el pago a tus proveedores.</p>
    <p class="mt-25"><span>Si eres inversionista</span> rentabiliza tu dinero invirtiendo en facturas negociables y recibe una tasa anualizada de hasta 18%.</p>
</div>

<div class="container pl-20">
    <div class="content">
        <p class="black">Selecciona tu cuenta ideal</p>    
        <img class="icon" [src]="'../../../../../assets/artwork/icon-double-arrow.svg'">
    </div>
</div>