import { USER_STATUS, } from '../enums/USER_STATUS.enum';
import { USER_ACTION } from '../enums/USER_ACTION.enum';
import { Roles } from 'src/app/shared/enums/Roles.enum';

export const UserActionsMapping = {
    [USER_ACTION.APPROVE] : {
        status: [USER_STATUS.CREATED, USER_STATUS.DISAPPROVED],
        role: [Roles.ADMIN]
    },
    [USER_ACTION.DISAPPROVE] : {
        status: [USER_STATUS.CREATED],
        role: [Roles.ADMIN]
    },
    [USER_ACTION.EDIT] : {
        status: [USER_STATUS.CREATED, USER_STATUS.APPROVED, USER_STATUS.DISAPPROVED, USER_STATUS.CONFIRMED],
        role: [Roles.ADMIN]
    },
    [USER_ACTION.BLOCK] : {
        status: [USER_STATUS.CREATED, USER_STATUS.APPROVED, USER_STATUS.DISAPPROVED, USER_STATUS.CONFIRMED],
        role: [Roles.ADMIN]
    },
    [USER_ACTION.SHOW_DETAILS] : {
        status: [USER_STATUS.CREATED, USER_STATUS.APPROVED, USER_STATUS.DISAPPROVED, USER_STATUS.CONFIRMED],
        role: [Roles.ADMIN]
    },
    [USER_ACTION.RESEND_CONFIRMATION_EMAIL] : {
        status: [USER_STATUS.APPROVED],
        role: [Roles.ADMIN]
    }    
}
