<div class="row">
    <div class="">
        <span class="tiny-title">Empresa pagadora</span>
        <span class="label">{{entity.invoiceObject.debtor.companyName | uppercase}}</span>
    </div>
    <div class="">
        <span class="tiny-title">Códigos({{entity.invoiceObject.physicalInvoices.length}})</span>
        <span class="tiny">{{invoiceCodes}}</span>
    </div>
</div>
<hr>

<div class="row">
    <div class="">
        <span class="tiny-title">Inversionista</span>
        <span class="tiny">{{ investor?.names | capitalizeFirstLetters }} {{ investor?.familyNames |
            capitalizeFirstLetters }}</span>
    </div>
    <div class="">
        <span class="tiny-title">Nuevo monto disponible</span>
        <span class="tiny">{{newAmount | number : '1.2-2'}}</span>
    </div>
</div>
<div class="row">
    <div class="">
        <span class="tiny-title">Teléfono</span>
        <span class="tiny">{{ investor?.phone  || '-'}}</span>
    </div>
</div>
<div class="row">
    <div class="">
        <span class="tiny-title">Monto</span>
        <lib-money [currency]="entity.invoiceObject.currency || 'pen'" [amount]="entity.amount || '0.00'"></lib-money>
    </div>
    <div class="">
        <span class="tiny-title">Porcentaje invertido</span>
        <div class="percentage">%&nbsp;<app-amount [amount]="percentageInvested"></app-amount>
        </div>
    </div>
</div>