<ng-container *ngIf="collections.length > 0">
    <ng-container *ngFor='let collection of collections | slice:0:3'>
        <div class="row">
            <div class="amount m-width">
                <lib-money [amount]="collection.netAmount" [currency]="collection.currency"></lib-money>
            </div>
            <div class="amount">
                <app-solid-label
                    *ngIf="collection.toBeCollectedInClient !== 'En mora' && collection.toBeCollectedInClient !== 'Plazo de gracia'"
                    width="100" background="dark-gray" [content]="'En ' + (collection.toBeCollectedInClient) + ' días'">
                </app-solid-label>
                <app-solid-label *ngIf="collection.toBeCollectedInClient === 'En mora'" width="100"
                    background="dark-gray" [content]="(collection.toBeCollectedInClient)"></app-solid-label>

                <app-solid-label *ngIf="collection.toBeCollectedInClient === 'Plazo de gracia'" width="100"
                    background="dark-gray" [content]="(collection.toBeCollectedInClient)"></app-solid-label>
            </div>
            <button class="button button-view" [routerLink]="['/invoices', collection._id]">...</button>
        </div>
    </ng-container>
    <div class="container" *ngIf="collections.length > 3">
        <a *ngIf="display" class="show-more" (click)="showMore()">Mostrar más <img class="success"
                src='../../../../../assets/icons/forward_arrow_item.svg'> </a>
        <a *ngIf="!display" class="show-less" (click)="showMore()">Mostrar menos <img class="success"
                src='../../../../../assets/icons/forward_arrow_item_1.svg'> </a>
    </div>
    <ng-container *ngIf="!display">
        <ng-container *ngFor='let collection of collections | slice:3'>
            <div class="row">
                <div class="amount m-width">
                    <lib-money [amount]="collection.netAmount" [currency]="collection.currency"></lib-money>
                </div>
                <div class="amount">
                    <app-solid-label
                        *ngIf="collection.toBeCollectedInClient !== 'En mora' && collection.toBeCollectedInClient !== 'Plazo de gracia'"
                        width="100" background="dark-gray" [content]="'En ' + (collection.toBeCollectedInClient) + ' días'">
                    </app-solid-label>
                    <app-solid-label *ngIf="collection.toBeCollectedInClient === 'En mora'" width="100" background="dark-gray"
                        [content]="(collection.toBeCollectedInClient)"></app-solid-label>
                    
                    <app-solid-label *ngIf="collection.toBeCollectedInClient === 'Plazo de gracia'" width="100" background="dark-gray"
                        [content]="(collection.toBeCollectedInClient)"></app-solid-label>
                </div>
                <button class="button button-view" [routerLink]="['/invoices', collection._id]">...</button>
            </div>
        </ng-container>
    </ng-container>
</ng-container>