import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SocketService } from 'src/app/shared/services/socket.service';

@Component({
  selector: 'app-realtime-connection-status',
  templateUrl: './realtime-connection-status.component.html',
  styleUrls: ['./realtime-connection-status.component.scss']
})
export class RealtimeConnectionStatusComponent implements OnInit, OnDestroy {
  isSocketOnline = false;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private socketService: SocketService) {
  }

  displayPop = false;
  ngOnInit(): void {
    this.socketService.isConnected$.pipe(
      (takeUntil(this.ngUnsubscribe))
    ).subscribe(isConnected => {
      this.isSocketOnline = isConnected;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


}
