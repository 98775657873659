<div class="container">
    <p class="title-text">Por favor, completa la siguiente información:</p>

    <form [formGroup]="form">
        <div class="form-container">
            <div class="body">
                <h6 class="mt-20">Información tributaria</h6>
                <div class="container-flex">
                    <div>
                        <p class="question">¿Es Perú mi país de residencia fiscal?</p>
                    </div>
                    <div>
                        <app-slide-toggle [firstLabel]="'Sí'" [secondLabel]="'No'" [checked]="!isPeru"
                            (selectionChanged)="setResindecyCountry($event)">
                        </app-slide-toggle>
                    </div>

                </div>

                <!-- <h6>¿Cuál es tu cargo?</h6> -->
                <app-select *ngIf="!isPeru" formControlName="taxResidency" valueField="nameSpanish" textField="nameSpanish"
                    [options]="countries" label="País de residencia fiscal"></app-select>
            </div>
        </div>
    </form>
    <div class="footer">
        <p class="know-more">Conoce más sobre las retenciones <a class="hyperlink" href="https://ayuda.finsmart.pe/es/collections/2499163-inversion" target="_blank" rel="noopener noreferrer">aquí.</a></p>
        <span class="terms">Al Confirmar, declaro bajo juramento que la información es verdadera y actualizada, y
            que
            informaré
            cualquier cambio inmediatamente.</span>
        <fs-ui-button [action]="'submit'" (click)="submit()" [label]="'Confirmar'"></fs-ui-button>
    </div>

</div>