import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RiskService } from 'src/app/features/risk-pending-evaluations/services/risk.service';
import { emailsValidator } from 'src/app/shared/validators/multiple-emails.validator';
import { UniqueEmailByEmailValidator } from 'src/app/shared/validators/unique.email.validator';

@Component({
  selector: 'app-add-contacts-emails',
  templateUrl: './add-contacts-emails.component.html',
  styleUrls: ['./add-contacts-emails.component.scss'],
})
export class AddContactsEmailsComponent implements OnInit {
  form: FormGroup;
  emailValid = false;
  emails = [];
  constructor(
    public dialogRef: MatDialogRef<AddContactsEmailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private uniqueEmailByEmailValidator: UniqueEmailByEmailValidator,
    private riskService: RiskService
  ) { }

  ngOnInit(): void {
    // console.log(this.data);
    if (this.data) {
      this.emails = this.data.debtor.emails;
    }
    this.form = this.formBuilder.group({
      emails: ['', [Validators.required, emailsValidator]],
    });

    this.form.get('emails').valueChanges.subscribe((newValue) => {
      if (this.emailValid) {
        this.emailValid = false;
      }
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      let emails = this.form.value?.emails?.split(',');
      const emailArrays = emails.map((email) => ({
        email: email,
      }));
      this.checkDuplicates(emailArrays);
      if (!this.emailValid) {
        // this.dialogRef.close(emailArrays);
        this.sendEmails(emailArrays);
      }
    }
  }

  sendEmails(condition) {
    let data = {
      emails: condition,
      _id: this.data.debtor._id,
    };
    this.riskService.saveNote(data, 'edit').subscribe((data) => {
      if (data) {
        this.emails = data?.emails;
        this.emailValid = false;
        this.resetForm(this.form)
      }
    });
  }

  deleteEmail(email) {
    let payload = {
      _id: this.data.debtor._id,
      email
    }

    this.riskService.updateNote(payload).subscribe((data) => {
      this.emails = data?.emails;
    });

  }


  checkDuplicates(emailArrays) {
    if (this.emails) {
      emailArrays.forEach((element) => {
        if (this.emails.map((email) => email.email).includes(element.email)) {
          this.emailValid = true;
        } else {
          this.emailValid = false;
        }
      });
    } else {
      this.emailValid = false;
    }
  }

  cancel() {
    this.dialogRef.close({ event: 'close' });
  }

  // you can put this method in a module and reuse it as needed
  resetForm(form: FormGroup) {
    form.reset();

    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }
}
