import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() content;
  @Input() checked;
  @Input() disabled = false;
  @Output() selectionChanged = new EventEmitter();
  @Input() class;
  @Input() errorMessage?= '';
  @Input() color: 'accent' | 'primary' | 'warn' = 'primary';
  isChecked = false;
  _errorStatus: Boolean = false;

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.ngControl) {
      this.writeValue(this.checked ? this.checked : false);
      this.ngControl.control.statusChanges.subscribe((formStatus) => {
        if (
          formStatus === 'INVALID' &&
          this.ngControl.errors &&
          (this.ngControl.dirty || this.ngControl.touched)
        ) {
          this._errorStatus = true;
          this.class = 'error';
        } else {
          this._errorStatus = false;
          this.class = '';
        }
      });
    }
  }

  get value() {
    return this.isChecked;
  }

  set value(val) {
    if (val !== undefined) {
      this.isChecked = val;
      this.propagateChange(this.isChecked);
    }
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.isChecked = value;
      this.propagateChange(this.isChecked);
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void { }

  checkChanged(event: MatCheckboxChange): void {
    this.writeValue(event.checked);
    this.isChecked = event.checked;
    this.selectionChanged.emit(event.checked);
  }
}