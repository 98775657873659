<span class="error-label">{{errorMessage}}</span>

<div class="one-line">
    <div (click)="uploadInput.click()" class="button button-blue">
        <span>{{ buttonLabel$ | async }}</span>
    </div>
    <div class="cancel" *ngIf="value" (click)="cancelFile()">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><clipPath id="990na"><path d="M11.945 10.003l7.047 7.196s.768 1.01.02 1.798c-.749.788-1.83 0-1.83 0l-7.183-7.049-7.11 7.05s-.989.874-1.851 0c-.863-.875.019-1.8.019-1.8l6.996-7.195-7.015-7.166s-.86-.935-.02-1.802c.841-.866 1.851 0 1.851 0L10 8.057l7.124-7.022s.95-.846 1.83 0c.88.846-.02 1.764-.02 1.764z"/></clipPath></defs><g><g><g opacity=".01"><path fill="#6C727C" fill-opacity=".01" d="M-2 2a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H1a3 3 0 0 1-3-3z"/><path fill="none" stroke="#6C727C" stroke-miterlimit="20" stroke-width=".01" d="M-2 2a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H1a3 3 0 0 1-3-3z"/></g><g><path fill="#6C727C" d="M11.945 10.003l7.047 7.196s.768 1.01.02 1.798c-.749.788-1.83 0-1.83 0l-7.183-7.049-7.11 7.05s-.989.874-1.851 0c-.863-.875.019-1.8.019-1.8l6.996-7.195-7.015-7.166s-.86-.935-.02-1.802c.841-.866 1.851 0 1.851 0L10 8.057l7.124-7.022s.95-.846 1.83 0c.88.846-.02 1.764-.02 1.764z"/></g><g clip-path="url(#990na)"><g><path fill="#6C727C" d="M-2-2h24v24H-2z"/></g></g></g></g></svg>
    </div>
</div>

<input 
    #uploadInput
    hidden="true"
    class="form-control" 
    type="file" 
    (change)="onFileChange($event)"
    [accept]="getMimeTypes()">