<div class="container" *ngIf="debtorStats.currency== 'pen'">
    <div class="item operation-item">
        <div class="bank-and-currency one-line">
            <div class="m-width">
                <p class="bank-name mt-5">En curso</p>
                <p class="tiny bank-label" *ngIf="debtorStats.invoicesAwaitingCollectionCount == 0">
                    {{debtorStats.invoicesAwaitingCollectionCount}}
                    facturas</p>
                <p class="tiny bank-label" *ngIf="debtorStats.invoicesAwaitingCollectionCount == 1">
                    {{debtorStats.invoicesAwaitingCollectionCount}}
                    factura</p>
                <p class="tiny bank-label" *ngIf="debtorStats.invoicesAwaitingCollectionCount > 1">
                    {{debtorStats.invoicesAwaitingCollectionCount}}
                    facturas</p>
            </div>
            <div class="amount" *ngIf="debtorStats.amountInProgress">
                <lib-money [amount]="debtorStats.amountInProgress" [currency]="debtorStats.currency"></lib-money>
            </div>
        </div>
    </div>
    <div class="item operation-item">
        <div class="bank-and-currency one-line">
            <div class="m-width">
                <p class="bank-name mt-5">Finalizadas</p>
                <p class="tiny bank-label" *ngIf="debtorStats.finalizedInvoicesCount == 0">
                    {{debtorStats.finalizedInvoicesCount}} facturas</p>
                <p class="tiny bank-label" *ngIf="debtorStats.finalizedInvoicesCount == 1">
                    {{debtorStats.finalizedInvoicesCount}} factura</p>
                <p class="tiny bank-label" *ngIf="debtorStats.finalizedInvoicesCount > 1">
                    {{debtorStats.finalizedInvoicesCount}} facturas</p>
            </div>
            <div class="amount" *ngIf="debtorStats.amountFinalized">
                <lib-money [amount]="debtorStats.amountFinalized" [currency]="debtorStats.currency"></lib-money>
            </div>
        </div>
    </div>
    <div class="item operation-item">
        <div class="bank-and-currency one-line">
            <div class="m-width">
                <p class="bank-name mt-5">En mora</p>
                <p class="tiny bank-label" *ngIf="debtorStats.delayedInvoicesCount == 0">
                    {{debtorStats.delayedInvoicesCount}}
                    facturas</p>
                <p class="tiny bank-label" *ngIf="debtorStats.delayedInvoicesCount == 1">
                    {{debtorStats.delayedInvoicesCount}}
                    factura</p>
                <p class="tiny bank-label" *ngIf="debtorStats.delayedInvoicesCount > 1">
                    {{debtorStats.delayedInvoicesCount}}
                    facturas</p>
            </div>
            <div class="amount" *ngIf="debtorStats.amountDelayed">
                <lib-money [amount]="debtorStats.amountDelayed" [currency]="debtorStats.currency"></lib-money>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="debtorStats.currency== 'usd'">
    <div class="item operation-item">
        <div class="bank-and-currency one-line">
            <div class="m-width">
                <p class="bank-name mt-5">En curso</p>
                <p class="tiny bank-label" *ngIf="debtorStats.invoicesAwaitingCollectionCount == 0">
                    {{debtorStats.invoicesAwaitingCollectionCount}}
                    facturas</p>
                <p class="tiny bank-label" *ngIf="debtorStats.invoicesAwaitingCollectionCount == 1">
                    {{debtorStats.invoicesAwaitingCollectionCount}}
                    factura</p>
                <p class="tiny bank-label" *ngIf="debtorStats.invoicesAwaitingCollectionCount > 1">
                    {{debtorStats.invoicesAwaitingCollectionCount}}
                    facturas</p>
            </div>
            <div class="amount" *ngIf="debtorStats.amountInProgress">
                <lib-money [amount]="debtorStats.amountInProgress" [currency]="debtorStats.currency"></lib-money>
            </div>
        </div>
    </div>
    <div class="item operation-item">
        <div class="bank-and-currency one-line">
            <div class="m-width">
                <p class="bank-name mt-5">Finalizadas</p>
                <p class="tiny bank-label" *ngIf="debtorStats.finalizedInvoicesCount == 0">
                    {{debtorStats.finalizedInvoicesCount}} facturas</p>
                <p class="tiny bank-label" *ngIf="debtorStats.finalizedInvoicesCount == 1">
                    {{debtorStats.finalizedInvoicesCount}} factura</p>
                <p class="tiny bank-label" *ngIf="debtorStats.finalizedInvoicesCount > 1">
                    {{debtorStats.finalizedInvoicesCount}} facturas</p>
            </div>
            <div class="amount" *ngIf="debtorStats.amountFinalized">
                <lib-money  [amount]="debtorStats.amountFinalized" [currency]="debtorStats.currency"></lib-money>
            </div>
        </div>
    </div>
    <div class="item operation-item">
        <div class="bank-and-currency one-line">
            <div class="m-width">
                <p class="bank-name mt-5">En mora</p>
                <p class="tiny bank-label" *ngIf="debtorStats.delayedInvoicesCount == 0">
                    {{debtorStats.delayedInvoicesCount}}
                    facturas</p>
                <p class="tiny bank-label" *ngIf="debtorStats.delayedInvoicesCount == 1">
                    {{debtorStats.delayedInvoicesCount}}
                    factura</p>
                <p class="tiny bank-label" *ngIf="debtorStats.delayedInvoicesCount > 1">
                    {{debtorStats.delayedInvoicesCount}}
                    facturas</p>
            </div>
            <div class="amount" *ngIf="debtorStats.amountDelayed">
                <lib-money  [amount]="debtorStats.amountDelayed" [currency]="debtorStats.currency"></lib-money>
            </div>
        </div>
    </div>
</div>