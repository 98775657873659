<img src="../../../../../../assets/logos/finsmart.svg" class="logo">
<ng-container *ngIf="showSucces">
    <div class="status">
        <img src="../../../../../../assets/icons/check_circle.svg" alt="">
        <p>Correo verificado</p>
    </div>
    <app-page-title class="login-title" [title]="pageTitle"></app-page-title>
    <p class="initial"><span>Inicia sesión con tus credenciales </span>y explora nuestra plataforma.</p>
    <div class="email">
        <img src="../../../../../../assets/icons/mail_outline.svg" alt="">
        <p>{{email}}</p>
    </div>
</ng-container>
<ng-container *ngIf="!showSucces">
    <app-page-title [title]="pageTitle"></app-page-title>
</ng-container>

<app-login-form (submitted)='submitted($event)' [email]="email" [isVerified]="showSucces"></app-login-form>

<div class="container-flex">
    <a class="call-to-action" routerLink="/signup">
        ¿No tienes cuenta?
        <span class="link-label">  Regístrate ahora</span>
    </a>
</div>