<!--
<div class="row role-selector">
    <div>
        Elige un rol
    </div>
    <div>
        <mat-button-toggle-group name="role-selector" value="investor" (change)="roleSelectedChanged($event.value)">
            <mat-button-toggle value="investor">Inversionista</mat-button-toggle>
            <mat-button-toggle value="client">Cliente</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
<div class="row role-selector">
    <mat-form-field>
        <mat-label>Filtros</mat-label>
        <mat-select multiple>
          <mat-option *ngFor="let filter of filters" [value]="filter">{{filter.text}}</mat-option>
        </mat-select>
      </mat-form-field>
</div>
-->
<form [formGroup]="filtersForm" (ngSubmit)="applyFilter()">
    <div class="filter-row">
        <mat-form-field appearance="fill">
            <mat-label>Rol</mat-label>
            <mat-select formControlName="roles" multiple>
                <mat-option *ngFor="let type of rolesList" [value]="type">
                    {{ type | toSpanish : 'NA' | capitalizeFirstLetters }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="statuses" multiple>
                <mat-option *ngFor="let status of statusesList" [value]="status">
                    {{ status | toSpanish : 'FEMALE' | capitalizeFirstLetters }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="filter-row">
        <app-autocomplete label="Buscar" searchFor="user" [selectedOption]="selectedUser" formControlName="user">
        </app-autocomplete>

        <div class="filter-column">
            <mat-checkbox formControlName="showBlocked">Mostrar Bloqueadas</mat-checkbox>
            <mat-checkbox formControlName="showNotSyncedOnly">Mostrar solo No Sincronizadas</mat-checkbox>
        </div>
    </div>

    <div class="filter-row">
        <fs-ui-button  (onClick)="resetFiltersToDefaults()" [label]="'Predeterminados'" [type]="'secondary'"></fs-ui-button>
        <fs-ui-button  (onClick)="applyFilter()" [label]="'Aplicar'"></fs-ui-button>
        <fs-ui-button (onClick)="resetFilters()"  [label]="'Borrar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>
<div class="list" *ngIf="users; else no_items_found">
    <!--<ng-container *ngFor="let user of users | filterBy:'role':selectedRole">-->
    <ng-container *ngFor="let user of users">
        <app-user-item [entity]="user">
        </app-user-item>
    </ng-container>

    <app-paginator [totalCount]="pagination.total" [pageSize]="pagination.limit" [skip]="pagination.skip"
        (pagination)="paginationHandler($event)"></app-paginator>
</div>
<ng-template #no_items_found>
    <div class="no-items-found">
        No se encontraron artículos con estos criterios
    </div>
</ng-template>