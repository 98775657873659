<ng-container *ngIf="selectedInvoice as invoice">

    <div class="header" *allowedRoles="['admin', 'client happiness']">

        <div class="left">
            <div class="container-flex mb-15 aligment">
                <div class="container-flex">
                    <lib-chip-status [status]="invoice.status"></lib-chip-status>
                    <lib-scheduled-invoice [status]="invoice?.status" [sale]="invoice?.onSale" [entity]="invoice">
                    </lib-scheduled-invoice>
                </div>
                <div class="sunat">
                    <ng-container *allowedRoles="['admin']">
                        <lib-chip *ngIf="invoice && invoice.isSunatProcess" content="Proceso vía SUNAT"></lib-chip>
                    </ng-container>
                </div>

            </div>
            <div class="title-amount">

                <lib-money [currency]="invoice?.currency || 'pen'" [amount]="invoice?.netAmount || '0.00'"></lib-money>


                <ng-container *allowedRoles="['admin']">
                    <div *ngIf="!emailPanel && !observationPanel" class="container-flex">
                        <p>{{(invoice.issuer.companyName) | uppercase }}
                        </p>
                        <p *ngIf="!invoice.issuer">NA</p>
                        <div class="icon mr-8 tooltip-container-not-dots"
                            (click)="copyToClipboard(invoice.issuer?.companyRuc)">
                            <svg class="svg-blue icon-copy" xmlns="http://www.w3.org/2000/svg" width="12" height="16"
                                viewBox="0 0 22 25">
                                <g>
                                    <g>
                                        <path fill="#6c727c"
                                            d="M19.163 6.818H6.767v15.91h12.396zm2.266 0v15.91c0 1.25-1.015 2.272-2.256 2.272H6.767c-1.24 0-2.256-1.023-2.256-2.273V6.818c0-1.25 1.015-2.273 2.256-2.273h12.406c1.24 0 2.256 1.023 2.256 2.273zm-5.64-4.545H2.256v15.909H0V2.272C0 1.023 1.015 0 2.256 0h13.533z" />
                                    </g>
                                </g>
                            </svg>
                            <span class="tooltiptext-bottom">RUC: {{ invoice.issuer?.companyRuc }}</span>
                            <span class="copied-message" *ngIf="numberCopied | async">Copiado al Portapapeles</span>
                        </div>
                        <div (click)="openObsPanel()" class="obs-icon c-pointer tooltip-container-not-dots">
                            <img src="../../../../../../assets/icons/obs.svg" alt="">
                            <ng-container *ngIf="userObservations">
                                <span *ngIf="userObservations.length === 0" class="tooltiptext-bottom">No hay
                                    observaciones</span>
                                <span *ngIf="userObservations.length === 1"
                                    class="tooltiptext-bottom">{{userObservations.length}}
                                    observación</span>
                                <span *ngIf="userObservations.length > 1"
                                    class="tooltiptext-bottom">{{userObservations.length}}
                                    observaciones</span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="container-flex" *ngIf="emailPanel || observationPanel">
                        <p class="mr-16">{{(invoice.issuer.companyName) | uppercase }}
                        </p>
                        <p *ngIf="!invoice.issuer">NA</p>
                    </div>
                </ng-container>

                <!-- <ng-container *allowedRoles="['client happiness']">
                    <div (click)="openObsPanel()" class="container-flex c-pointer ">
                        <p class="mr-16">{{(invoice.issuer.companyName) | uppercase }}
                        </p>
                        <p *ngIf="!invoice.issuer">NA</p>
                    </div>
                </ng-container> -->


                <ng-container *allowedRoles="['client happiness']">
                    <div class="container-flex">
                        <p class="mr-16">{{ invoice.issuer?.companyName | uppercase }}
                        </p>
                    </div>
                </ng-container>

            </div>
            <div class="bank-and-currency row">
                <lib-operation-type [operationType]="invoice?.isConfirming"></lib-operation-type>
            </div>

            <ng-container *allowedRoles="['admin', 'client happiness']">
                <div (click)="gotoSlack(invoice)" *ngIf="invoice.slackTimestamp"
                    class="bank-and-currency container-flex c-pointer slack-container">
                    <img src="../../../../../../assets/icons/slack-icon.svg" alt="">
                    <span class="ml-8 slack-button">Ir a Slack</span>
                </div>
            </ng-container>

            <ng-container *allowedRoles="['admin']">
                <div *ngIf="invoice.checklist" style="display: flex;">
                    <span style="font-weight: 400;">Checklist:</span>
                    <div class="checklist">
                        <div style="display: flex;">
                            <div class="checklist-group">
                                <lib-checklist-item [isActionable]="true" (open)="openCheckModal($event)"
                                    [isException]="hasException(getCheck(invoice.checklist, 'documents'))"
                                    [entity]="getCheck(invoice.checklist, 'documents')"></lib-checklist-item>
                                <lib-checklist-item class="check-small-screen" [isActionable]="false"
                                    (open)="openCheckModal($event)" [isException]="false"
                                    [entity]="getCheck(invoice.checklist, 'conditions')">
                                </lib-checklist-item>
                            </div>
                            <div class="checklist-group">
                                <lib-checklist-item [isActionable]="true" (open)="openCheckModal($event)"
                                    [isException]="hasException(getCheck(invoice.checklist, 'risk'))"
                                    [entity]="getCheck(invoice.checklist, 'risk')"></lib-checklist-item>
                                <lib-checklist-item class="check-small-screen" [isActionable]="false"
                                    (open)="openCheckModal($event)" [isException]="false"
                                    [entity]="getCheck(invoice.checklist, 'tea')"></lib-checklist-item>

                            </div>
                            <div class="checklist-group">
                                <lib-checklist-item [isActionable]="true" (open)="openCheckModal($event)"
                                    [isException]="hasException(getCheck(invoice.checklist, 'cavali'))"
                                    [entity]="getCheck(invoice.checklist, 'cavali')"></lib-checklist-item>
                                <lib-checklist-item class="check-small-screen" *ngIf="invoice.isConfirming"
                                    [isActionable]="false" (open)="openCheckModal($event)" [isException]="false"
                                    [entity]="getCheck(invoice.checklist, 'warranty')"></lib-checklist-item>
                            </div>
                        </div>
                        <div class="checklist-section2">
                            <lib-checklist-item [isActionable]="false" (open)="openCheckModal($event)"
                                [isException]="false" [entity]="getCheck(invoice.checklist, 'conditions')">
                            </lib-checklist-item>
                            <lib-checklist-item [isActionable]="false" (open)="openCheckModal($event)"
                                [isException]="false" [entity]="getCheck(invoice.checklist, 'tea')">
                            </lib-checklist-item>
                            <lib-checklist-item *ngIf="invoice.isConfirming" [isActionable]="false"
                                (open)="openCheckModal($event)" [isException]="false"
                                [entity]="getCheck(invoice.checklist, 'warranty')"></lib-checklist-item>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>



        <ng-container *ngIf="!emailPanel && !observationPanel">
            <div *allowedRoles="['admin']" class="right">
                <div class="available-action">
                    <span (click)="approveInvoice()" class="action"
                        *ngIf="invoice.status === 'in process' || invoice.status === 'disapproved'">

                        <span class="title">Aprobar</span>
                        <img src="../../../../../../assets/icons/final/approve.png" />
                    </span>

                    <!-- <span (click)="revertInvoiceToPDC()" class="action" *ngIf="invoice.status === 'configured'">

                        <span class="title">Aceptar por usuario</span>
                        <img src="../../../../../../assets/icons/priority_high_black_24dp.svg" />
                    </span> -->

                    <span (click)="revertConfiguration()" class="action"
                        *ngIf="invoice.status === 'configured'||invoice.status === 'pending confirmation'">

                        <span class="title">Revertir Configuración</span>
                        <img src="../../../../../../assets/icons/final/redo-arrow.png" />
                    </span>

                    <span (click)="configureInvoice(invoice.tdmPercentage, invoice.reservationPercentage, invoice.tea)"
                        class="action" *ngIf="invoice.status === 'approved'">

                        <span class="title">Configurar</span>
                        <img src="../../../../../../assets/icons/final/settings.svg" />
                    </span>

                    <span (click)="cancelSale()" class="action"
                        *ngIf="invoice.status === 'for sale' || invoice.status === 'partially sold' || invoice.status === 'sale closed'">

                        <span class="title">Cancelar venta</span>
                        <img src="../../../../../../assets/icons/final/redo-arrow.png" />
                    </span>

                    <span (click)="putInvoiceForSale(invoice)" class="action-sale"
                        *ngIf="invoice.status === 'pending confirmation'">
                        <span [ngClass]="getValidationTitle(invoice)">Poner en venta</span>
                        <img [ngClass]="getValidationClass(invoice)"
                            src="../../../../../../assets/icons/shopping_cart.svg" />
                    </span>

                    <span (click)="navigateToInvest(invoice)" class="action"
                        *ngIf="invoice.status === 'pending confirmation' || invoice.status === 'for sale' || invoice.status === 'partially sold'">
                        <span class="title">Nueva inversión</span>
                        <img src="../../../../../../assets/icons/investments.svg" />
                    </span>

                    <span (click)="payAdvanceToClient(invoice)" class="action-pay"
                        *ngIf="invoice.status === 'sale closed' || invoice.status === 'partially sold'|| invoice.status === 'pending confirmation'">

                        <span [ngClass]="getValidationTitle(invoice)">Pagar Adelanto</span>
                        <img [ngClass]="getValidationClass(invoice)" height="25px" width="25px"
                            src="../../../../../../assets/icons/final/clock.png" />
                    </span>

                    <span (click)="collectInvoice(invoice.paymentDate)" class="action"
                        *ngIf="invoice.status === 'awaiting collection'">

                        <span class="title">Cobrar</span>
                        <img src="../../../../../../assets/icons/final/coin-pile.png" />
                    </span>

                    <span (click)="finalizeInvoice()" class="action" *ngIf="invoice.status === 'collected'">

                        <span class="title">Finalizar</span>
                        <img src="../../../../../../assets/icons/final/double-check.png" />
                    </span>
                </div>
                <div class="default-actions available-action">
                    <span (click)="downloadInvoice(invoice._id)" class="action">

                        <span class="title">Descargar</span>
                        <img src="../../../../../../assets/icons/final/download-zip2.png" />
                    </span>
                    <span (click)="rejectInvoice()"
                        *ngIf="invoice.status === 'collected' || invoice.status === 'finalized' || invoice.status === 'awaiting collection' || invoice.status === 'approved' || invoice.status === 'partially sold' || invoice.status === 'sale closed' || invoice.status === 'for sale' || invoice.status === 'configured' || invoice.status === 'pending confirmation' || invoice.status === 'in process'"
                        class="action">

                        <span class="title">{{ 'disapprove' | toSpanish : 'FEMALE' | capitalizeFirstLetters}}</span>
                        <img class="disapprove-icon" src="../../../../../../assets/icons/final/disapprove.png" />
                    </span>
                </div>
            </div>
        </ng-container>

        <div *allowedRoles="['client happiness']" class="right">
            <div class="available-action">
                <span (click)="navigateToInvest(invoice)" class="action"
                    *ngIf="invoice.status === 'pending confirmation' || invoice.status === 'for sale' || invoice.status === 'partially sold' ">
                    <span class="title">Nueva inversión</span>
                    <img src="../../../../../../assets/icons/investments.svg" />
                </span>
            </div>
        </div>
    </div>

    <!--Invoice Detail for ch portal -->
    <ng-container *allowedRoles="['client happiness']">
        <ng-container *ngIf="invoice.status === 'collected'
        || invoice.status === 'awaiting collection'
        || invoice.status === 'finalized'">
            <lib-collection-days-bar [toBeCollectedIn]="invoice?.toBeCollectedIn" [mode]="mode" [entity]="invoice">
            </lib-collection-days-bar>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!emailPanel && !observationPanel">
        <ng-container *allowedRoles="['admin']">
            <ng-container *ngIf="invoice.status === 'for sale' || invoice.status === 'partially sold' || invoice.status === 'sale closed'
                || invoice.status === 'pending confirmation'
                || invoice.status === 'collected'
                || invoice.status === 'awaiting collection'
                || invoice.status === 'finalized'">
                <app-progress-bar class="investment-progress" [progress]="(100-invoice.availableBalancePercentage)">
                </app-progress-bar>
                <p class="investment-progress-label">Invertido
                    {{ getInvestedPercentage(invoice.availableBalancePercentage) }}%</p>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!emailPanel && !observationPanel">
        <ng-container *allowedRoles="['client happiness']">
            <ng-container *ngIf="invoice.status === 'sale closed'
                || invoice.status === 'for sale' 
                || invoice.status === 'partially sold' 
                || invoice.status === 'pending confirmation'">
                <app-progress-bar class="investment-progress" [progress]="(100-invoice.availableBalancePercentage)">
                </app-progress-bar>
                <p class="investment-progress-label">Invertido
                    {{ getInvestedPercentage(invoice.availableBalancePercentage) }}%</p>
            </ng-container>
        </ng-container>
    </ng-container>

    <div *allowedRoles="['admin', 'client happiness']">
        <hr *ngIf="!(
            invoice.status === 'for sale' || 
            invoice.status === 'partially sold' ||
            invoice.status === 'sale closed') && !emailPanel && !observationPanel">
    </div>

    <div *allowedRoles="['admin']">
        <hr *ngIf="observationPanel">
    </div>

    <div *allowedRoles="['admin']">
        <hr *ngIf="emailPanel">
    </div>

    <div *ngIf="!observationPanel" class="profile">
        <div>
            <ng-container *allowedRoles="['admin', 'client happiness']">
                <div class="one-line mb-30 companyNameDiv">
                    <div class="companyNameConfirming tooltip-container-not-dots">
                        {{(invoice.isConfirming ? invoice.supplier.companyName :
                        invoice.debtor.companyName) | uppercase }}
                        <div *allowedRoles="['admin']" class="icon" (click)="copyToClipboardDebtor(invoice.isConfirming ? invoice.supplier.companyRuc :
                        invoice.debtor.companyRuc)">
                            <svg class="svg-blue icon-copy" xmlns="http://www.w3.org/2000/svg" width="12" height="16"
                                viewBox="0 0 22 25">
                                <g>
                                    <g>
                                        <path fill="#6c727c"
                                            d="M19.163 6.818H6.767v15.91h12.396zm2.266 0v15.91c0 1.25-1.015 2.272-2.256 2.272H6.767c-1.24 0-2.256-1.023-2.256-2.273V6.818c0-1.25 1.015-2.273 2.256-2.273h12.406c1.24 0 2.256 1.023 2.256 2.273zm-5.64-4.545H2.256v15.909H0V2.272C0 1.023 1.015 0 2.256 0h13.533z" />
                                    </g>
                                </g>
                            </svg>
                            <span class="tooltiptext-bottom">RUC: {{ (invoice.isConfirming ? invoice.supplier.companyRuc
                                :
                                invoice.debtor.companyRuc) | uppercase }}</span>
                            <span class="copied-message" *ngIf="numberCopiedDebtor | async">Copiado al
                                Portapapeles</span>
                        </div>
                        <span *ngIf="invoice?.evaluation?.hasPending" class="circle"></span><span
                            class="pending-evaluation" *ngIf="invoice?.evaluation?.hasPending">Evaluación en
                            curso</span>
                    </div>

                    <div *ngIf="!emailPanel && !observationPanel">
                        <div class="container-flex c-pointer" (click)="openEmailPanel()"
                            *ngIf="invoice.emailReady.length === 0">
                            <span class="mail-quantity-empty">Envío de correos</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <defs>
                                    <style>
                                        .a-empty {
                                            fill: none;
                                        }

                                        .b-empty {
                                            fill: #2d2d2d;
                                        }
                                    </style>
                                </defs>
                                <rect class="a-empty" width="24" height="24" />
                                <path class="b-empty"
                                    d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm-.4,4.25-7.07,4.42a1.011,1.011,0,0,1-1.06,0L4.4,8.25a.849.849,0,1,1,.9-1.44L12,11l6.7-4.19a.849.849,0,1,1,.9,1.44Z" />
                            </svg>
                        </div>
                        <ng-container *allowedRoles="['admin']">
                            <div class="container-flex c-pointer" (click)="openEmailPanel()"
                                *ngIf="invoice.emailReady.length > 0">
                                <span class="mail-quantity">Envío de correos</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <defs>
                                        <style>
                                            .a-not-empty {
                                                fill: none;
                                            }

                                            .b-not-empty {
                                                fill: #ffb330;
                                            }
                                        </style>
                                    </defs>
                                    <rect class="a-not-empty" width="24" height="24" />
                                    <path class="b-not-empty"
                                        d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm-.4,4.25-7.07,4.42a1.011,1.011,0,0,1-1.06,0L4.4,8.25a.849.849,0,1,1,.9-1.44L12,11l6.7-4.19a.849.849,0,1,1,.9,1.44Z" />
                                </svg>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>

    <ng-container *allowedRoles="['admin', 'client happiness']">
        <div *ngIf="!emailPanel && !observationPanel">
            <app-risk-header-information [debtor]="invoice.debtor" [entity]="invoice.evaluation">
            </app-risk-header-information>
        </div>
    </ng-container>

    <ng-container *allowedRoles="['client']">
        <div style="display: flex; justify-content: space-between; margin-bottom: 18px; align-items: center;">
            <div style="display: flex;">
                <lib-chip [background]="invoice.isConfirming ? 'black' : 'blue'"
                    [content]="invoice.isConfirming ? 'Confirming' : 'Factoring'"></lib-chip>
                <lib-chip style="margin-left: 8px;"
                    [content]="invoice.isSunatProcess ? 'Proceso SUNAT' : 'Proceso CAVALI'">
                </lib-chip>
                <lib-chip class="big-screen" *ngIf="invoice.status !== 'disapproved'" style="margin-left: 8px;"
                    [content]="getStatusInvoice(invoice)">
                </lib-chip>
                <lib-chip class="big-screen" *ngIf="invoice.status === 'disapproved'" style="margin-left: 8px;"
                    content="Desaprobada" background="red">
                </lib-chip>
            </div>

            <div class="big-screen" style="font-size: 20px;font-weight: 400;color: #0256FF;">
                {{invoice.currency | currencySymbol}} {{invoice.netAmount | numberSpacing}}
            </div>
        </div>

        <lib-chip class="small-screen" *ngIf="invoice.status === 'disapproved'"
            style="margin-top: -8px;margin-bottom: 18px;" content="Desaprobada" background="red">
        </lib-chip>

        <div class="header-company-name">
            <div class="header-title" style="flex-basis: 50%;">
                {{(invoice.isConfirming ? invoice.supplier.companyName :
                invoice.debtor.companyName) | uppercase }}
            </div>
            <div class="header-data small-screen" style="font-size: 16px;font-weight: 400;color: #0256FF;">
                {{invoice.currency | currencySymbol}} {{invoice.netAmount | numberSpacing}}
            </div>
            <div class="header-codes" style="color: #2D2D2D;">
                <lib-tooltip *ngIf="getCodes(invoice.physicalInvoices).length > 25; else codes"
                    [content]="getCodes(invoice.physicalInvoices)" width="200" position="left">
                    <span> {{ getCodes(invoice.physicalInvoices) | limitTo: '25'}}</span>
                </lib-tooltip>
                <ng-template #codes>
                    <span style="font-size: 14px;">{{ getCodes(invoice.physicalInvoices) | limitTo: '25'}}</span>
                </ng-template>
            </div>
        </div>

        <div class="progress-container" *ngIf="hasProgressSection">
            <span style="font-weight: 400;color: #2D2D2D;font-size: 14px;">Progreso</span>

            <div *ngIf="!(invoice.status === 'awaiting collection');else paymentDateProgressBar"
                style="margin-top: 22px;padding-left: 2px;">
                <div style="display: flex;align-items: center;">
                    <mat-progress-bar class="audio-progress-bar" mode="determinate"
                        [value]="invoiceNewStatus === 'finance' ? getAvailableBalance(invoice) : invoice.clientChecks?.progress">
                    </mat-progress-bar>
                    <img *ngIf="invoiceNewStatus === 'finance'" style="z-index: 10; margin-left: -3px;" width="32px"
                        height="32px" matTooltipClass="fs-tooltip" matTooltip="Financiado"
                        src='../../../../../assets/icons/new-money.svg'>
                    <img *ngIf="invoiceNewStatus === 'in process'" style="z-index: 10; margin-left: -3px;" width="32px"
                        height="32px" matTooltipClass="fs-tooltip" matTooltip="Por financiar"
                        src='../../../../../assets/icons/attach-check.svg'>
                </div>
                <span style="font-size: 12px; display: block; margin-top: -7px;">
                    {{invoiceNewStatus === 'finance' ? getAvailableBalance(invoice)+'% ' + 'financiado' :
                    'Validando...'}}</span>

                <div *ngIf="!invoice.advancePaymentDate">
                    <div *ngIf="invoice.clientChecks?.isFinanceReady" style="text-align: center;margin-top: 25px;">
                        <p style="font-size: 14px; font-weight: 400;line-height: 20px;">¡Tenemos todo listo!</p>
                        <p style="font-size: 14px;line-height:20px">Estamos preparando el financiamiento de
                            tu operación.<br>
                            Pronto te comunicaremos la fecha y hora de la transferencia.</p>
                    </div>

                    <div style="margin-top:22px;display: flex;align-items: center; justify-content: center;"
                        class="pointer" (click)="isMoreDetailOpen=!isMoreDetailOpen">
                        <span style="color: #0256ff; font-weight: 400;">Ver {{isMoreDetailOpen ? 'menos' : 'más'}}
                            detalles</span>
                        <lib-icon [ngClass]="{'rotate-down': isMoreDetailOpen }" style="margin-left: 10px" color="blue"
                            icon="arrow_down" size="xxxs"></lib-icon>
                    </div>

                    <div [ngClass]="isMoreDetailOpen ? 'drawer-show' : 'drawer-hide'">
                        <div class="client-checklist" style="margin-top:22px;">
                            <div class="panel-header pointer" style="font-size: 14px;" (click)="openPanel('risk')">
                                <span>Evaluación de riesgos</span>
                                <div style="display: flex; align-items: center;">
                                    <span style="font-size: 12px;"
                                        *ngIf="invoice?.clientChecks?.checks?.risk?.count.denied == 0 && 
                            invoice?.clientChecks?.checks?.risk?.count.total != invoice?.clientChecks?.checks?.risk?.count.approved;else showIconRisk">
                                        {{invoice?.isConfirming ? (invoice?.clientChecks?.checks?.risk?.count.approved +
                                        '/'
                                        +
                                        invoice?.clientChecks?.checks?.risk?.count.total) :
                                        (invoice?.clientChecks?.checks?.risk?.count.approved + '/' +
                                        (invoice?.clientChecks?.checks?.risk?.count.total)) }}
                                    </span>

                                    <ng-template #showIconRisk>
                                        <img [src]="invoice?.clientChecks?.checks?.risk?.count.denied > 0 ?
                                    '../../../../../../assets/icons/red-alert-circle.svg' 
                                : '../../../../../../assets/icons/green-check-circle.svg'" width="15px" height="15px">
                                    </ng-template>

                                    <lib-icon style="margin-left: 10px;"
                                        [ngClass]="{'rotate-down': activePanel=='risk'}" color="black" icon="arrow_down"
                                        size="xxxs">
                                    </lib-icon>
                                </div>
                            </div>
                            <div [ngClass]="activePanel=='risk' ? 'drawer-show' : 'drawer-hide'">
                                <div class="panel-body">
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.risk?.checks?.evaluationApproved)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">{{invoice.isConfirming ? 'Tienes una evaluación
                                            aprobada'
                                            : 'Evaluación aprobada del pagador' }}</span>
                                    </div>
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.risk?.checks?.expiredGlobal)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">
                                            {{invoice.isConfirming ? 'No presentas deudas vencidas en el sistema
                                            financiero'
                                            : 'El pagador no presenta deudas vencidas en el sistema
                                            financiero' }}
                                        </span>
                                    </div>
                                    <div class="client-check"
                                        *ngIf="invoice?.clientChecks?.checks?.risk?.checks?.expiredUser">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.risk?.checks?.expiredUser)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">
                                            No tienes operaciones vencidas con Finsmart
                                        </span>
                                    </div>
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.risk?.checks?.expiredFinsmart)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">
                                            {{invoice.isConfirming ? 'No presenta deudas vencidas con
                                            Finsmart'
                                            : 'El pagador no presenta deudas vencidas con
                                            Finsmart' }}
                                        </span>
                                    </div>
                                    <div *ngIf="invoice.isConfirming" class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.risk?.checks?.creditLine)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Tienes suficiente línea de crédito
                                            disponible</span>
                                    </div>
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.risk?.checks?.invoiceValidation)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Validación de la(s) factura(s)</span>
                                    </div>
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.risk?.checks?.coactiveDebt)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">
                                            {{invoice.isConfirming ? 'Validación de deuda coactiva de tu proveedor'
                                            : 'Validación de deuda coactiva' }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="client-checklist" style="margin-top:8px;">
                            <div class="panel-header pointer" style="font-size: 14px;" (click)="openPanel('documents')">
                                <span>Validación de documentos</span>
                                <div style="display: flex; align-items: center;">
                                    <span style="font-size: 12px;"
                                        *ngIf="invoice?.clientChecks?.checks?.documents?.count.denied == 0 && 
                            invoice?.clientChecks?.checks?.documents?.count.total != invoice?.clientChecks?.checks?.documents?.count.approved;else showIconDocuments">
                                        {{invoice?.clientChecks?.checks?.documents?.count.approved + '/' +
                                        invoice?.clientChecks?.checks?.documents?.count.total}}
                                    </span>

                                    <ng-template #showIconDocuments>
                                        <img [src]="invoice?.clientChecks?.checks?.documents?.count.denied > 0 ?
                                    '../../../../../../assets/icons/red-alert-circle.svg' 
                                : '../../../../../../assets/icons/green-check-circle.svg'" width="15px" height="15px">
                                    </ng-template>

                                    <lib-icon [ngClass]="{'rotate-down': activePanel=='documents'}"
                                        style="margin-left: 10px;" color="black" icon="arrow_down" size="xxxs">
                                    </lib-icon>
                                </div>
                            </div>
                            <div [ngClass]="activePanel=='documents' ? 'drawer-show' : 'drawer-hide'">
                                <div class="panel-body">
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.documents?.checks?.contract)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Contrato firmado y actualizado</span>
                                    </div>
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.documents?.checks?.literalCopy)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Copia literal/vigencia de poderes
                                            actualizada</span>
                                    </div>
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.documents?.checks?.dniCopy)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Copia de DNI vigente</span>
                                    </div>

                                    <div class="client-check" *ngIf="invoice.isConfirming">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.documents?.checks?.providerAgreement)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Notificación al proveedor sobre el financiamiento
                                            de
                                            la
                                            operación</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="client-checklist" style="margin-top:8px;">
                            <div class="panel-header pointer" style="font-size: 14px;" (click)="openPanel('cavali')">

                                <span>{{invoice.isConfirming ? 'Tu conformidad en ' + (invoice.isSunatProcess ? 'SUNAT'
                                    :
                                    'CAVALI') : 'Respuesta del pagador'}}</span>
                                <div style="display: flex; align-items: center;">
                                    <span style="font-size: 12px;"
                                        *ngIf="invoice?.clientChecks?.checks?.cavali?.count.denied == 0 && 
                            invoice?.clientChecks?.checks?.cavali?.count.total != invoice?.clientChecks?.checks?.cavali?.count.approved;else showIconCavali">
                                        {{invoice?.clientChecks?.checks?.cavali?.count.approved + '/' +
                                        invoice?.clientChecks?.checks?.cavali?.count.total}}
                                    </span>

                                    <ng-template #showIconCavali>
                                        <img [src]="invoice?.clientChecks?.checks?.cavali?.count.denied > 0 ?
                                    '../../../../../../assets/icons/red-alert-circle.svg' 
                                : '../../../../../../assets/icons/green-check-circle.svg'" width="15px" height="15px">
                                    </ng-template>

                                    <lib-icon [ngClass]="{'rotate-down': activePanel=='cavali'}"
                                        style="margin-left: 10px;" color="black" icon="arrow_down" size="xxxs">
                                    </lib-icon>
                                </div>
                            </div>
                            <div [ngClass]="activePanel=='cavali' ? 'drawer-show' : 'drawer-hide'">
                                <div class="panel-body">
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.cavali?.checks?.upload)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Subimos las facturas a CAVALI</span>
                                    </div>
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.cavali?.checks?.contacts)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Riesgos valida {{invoice.isConfirming ? 'tus
                                            contactos
                                            asignados' : 'los contactos del pagador'}}</span>
                                    </div>
                                    <div class="client-check" *ngIf="!invoice.isConfirming">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.cavali?.checks?.emailSent)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Enviamos el correo al pagador</span>
                                    </div>
                                    <div class="client-check"
                                        [ngStyle]="{'margin-top': invoice.isSunatProcess ?  '-2px' :'inherit' }">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.cavali?.checks?.compliance)"
                                            width="15px" height="15px">

                                        <div style="display: flex; justify-content: center; align-items: center">
                                            <span style="font-size: 14px;">{{invoice.isConfirming ? 'Tu' : 'Pagador
                                                da'}}
                                                conformidad Expresa {{invoice.isSunatProcess ?
                                                'o Presunta en SUNAT' : 'en CAVALI'}} a las
                                                facturas</span>
                                            <ng-container *ngIf="invoice.isSunatProcess">
                                                <img class="bubble-opener pointer" style="margin-left: 4px;"
                                                    (click)="bubbleOpened='sunat'" width="24px" height="24px"
                                                    src="../../../../../../assets/icons/question-mark-box.svg">
                                                <div *ngIf="bubbleOpened==='sunat'">
                                                    <div class="bubble sb1" style="max-width: 370px;">
                                                        <p class="bubble-content"
                                                            style="font-size:14px; line-height: 20px;">
                                                            Recuerda que en el proceso SUNAT se tiene que dar la
                                                            conformidad en el portal de SUNAT y no en CAVALI.</p>
                                                        <br>
                                                        <p (click)="openLink('https://ayuda.finsmart.pe/es/collections/3263314-decreto-de-urgencia-n%C2%BA-013-2020')"
                                                            class="bubble-content"
                                                            style="font-size:14px; font-weight:400; text-align: right; color: #0256FF;cursor: pointer;">
                                                            Saber
                                                            más</p>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="client-check" *ngIf="invoice.isSunatProcess && !invoice.isConfirming">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.cavali?.checks?.payerEmailConformity)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Pagador da conformidad a monto y fecha de la
                                            operación
                                            por correo</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="client-checklist" style="margin-top:8px;" *ngIf="invoice.isConfirming">
                            <div class="panel-header pointer" style="font-size: 14px;" (click)="openPanel('warranty')">

                                <span>Garantía</span>
                                <div style="display: flex; align-items: center;">
                                    <span style="font-size: 12px;"
                                        *ngIf="invoice?.clientChecks?.checks?.warranty?.count.denied == 0 && 
                            invoice?.clientChecks?.checks?.warranty?.count.total != invoice?.clientChecks?.checks?.warranty?.count.approved;else showIconWarranty">
                                        {{invoice?.clientChecks?.checks?.warranty?.count.approved + '/' +
                                        invoice?.clientChecks?.checks?.warranty?.count.total}}
                                    </span>

                                    <ng-template #showIconWarranty>
                                        <img [src]="invoice?.clientChecks?.checks?.warranty?.count.denied > 0 ?
                                    '../../../../../../assets/icons/red-alert-circle.svg' 
                                : '../../../../../../assets/icons/green-check-circle.svg'" width="15px" height="15px">
                                    </ng-template>

                                    <lib-icon [ngClass]="{'rotate-down': activePanel=='warranty'}"
                                        style="margin-left: 10px;" color="black" icon="arrow_down" size="xxxs">
                                    </lib-icon>
                                </div>
                            </div>
                            <div [ngClass]="activePanel=='warranty' ? 'drawer-show' : 'drawer-hide'">
                                <div class="panel-body">
                                    <div class="client-check">
                                        <img style="margin-right: 10px;"
                                            [src]="getClientCheckIcon(invoice?.clientChecks?.checks?.warranty?.checks?.warranty)"
                                            width="15px" height="15px">
                                        <span style="font-size: 14px;">Validamos transferencia de la garantía</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="invoice.advancePaymentDate" class="client-checklist"
                    style="text-align: center;padding: 8px;margin-left: auto;margin-right: auto;max-width: 580px;margin-top: 25px;position: relative;">

                    <img class="pointer close-icon" width="11px" height="11px"
                        (click)="updateEstimatedTransfer(invoice)" src="../../../../../../assets/icons/cancel-icon.svg">

                    <p style="font-size: 14px; font-weight: 400;line-height:20px">La transferencia
                        {{invoice.isConfirming ? 'a tu proveedor' : 'de adelanto'}} fue
                        realizada el {{ formatTransferTime(invoice.advanceTransferAt) }}</p>
                    <p style="font-size: 14px;line-height:20px;margin-top: 4px;">{{getTransferDisclaimer(invoice)}}</p>
                    <div style="display: flex; justify-content: center; align-items: center; margin-top: 4px;">
                        <span style="font-size: 12px;">¿Está demorando en llegar?</span>
                        <img class="bubble-opener pointer" style="margin-left: 4px;"
                            (click)="bubbleOpened='estimatedTime'" width="24px" height="24px"
                            src="../../../../../../assets/icons/question-mark-box.svg">
                        <div *ngIf="bubbleOpened==='estimatedTime'">

                            <div class="bubble sb1" style="width: 330px;">
                                <p class="bubble-content" style="font-size:14px; line-height: 20px; text-align: left;">
                                    Existen factores fuera del control de Finsmart, como inconvenientes con el banco
                                    emisor y/o receptor, entre otros, que pueden generar que la transferencia demore o
                                    se revierta.</p>
                                <br>
                                <p (click)="openLink(invoice.isConfirming ? 
                                'https://ayuda.finsmart.pe/es/articles/5088264-en-cuanto-tiempo-mis-proveedores-recibiran-su-adelanto-y-por-que-vias'
                                 : 'https://ayuda.finsmart.pe/es/articles/4583649-en-cuanto-tiempo-y-donde-recibo-el-abono-del-adelanto-de-pago')"
                                    class="bubble-content"
                                    style="font-size:14px; font-weight:400; text-align: right; color: #0256FF;cursor: pointer;">
                                    Saber
                                    más</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template #paymentDateProgressBar>
                <div style="margin-top: 22px; padding-left: 3px;">
                    <div style="display: flex;align-items: center;">
                        <img matTooltipClass="fs-tooltip" matTooltip="Desembolso"
                            style="position: absolute;margin-top:-2px ; margin-left: -2px; z-index: 10;"
                            src="../../../../../../assets/icons/ellipse_blue.svg" width="12px" height="12px">
                        <mat-progress-bar class="audio-progress-bar" mode="determinate"
                            [value]="invoice?.toBeCollectedIn === 'En mora' ? 100 : datesPercentage(invoice?.advancePaymentDate, invoice?.paymentDate)">
                        </mat-progress-bar>
                        <img style="z-index: 10; margin-left: -3px;" width="32px" height="32px"
                            matTooltipClass="fs-tooltip" matTooltip="Pago"
                            [src]="(invoice?.toBeCollectedIn === 'En mora' || invoice?.toBeCollectedIn === 0) ? '../../../../../assets/icons/attach_credit_blue.svg' : '../../../../../assets/icons/attach_credit.svg'">
                    </div>
                    <span style="font-size: 12px; display: block; margin-top: -7px;"
                        [ngStyle]="{'color': getRemainingDays(invoice?.toBeCollectedIn, invoice?.moraDays).color}">
                        {{getRemainingDays(invoice?.toBeCollectedIn, invoice?.moraDays).text}}</span>
                </div>

            </ng-template>
        </div>

        <lib-tab-invoice-client-detail [selected]="0" (selectedTab)="updateTabSelected($event)">
            <div class="operation-detail">
                <lib-scroll-indicator text="Ver más" [scrollPercentage]="scrollSize"
                    [isVisible]="isScrollIndicatorVisible" [scrollLimit]="'90'" [width]="'70px'">
                    <div class="list">
                        <ng-container>
                            <div class="container-layout">
                                <div class="title-docs">
                                    <div class="container-title">
                                        <div class="container-icon">
                                            <lib-icon icon="description" size="xsx" color="gradient-dark-blue">
                                            </lib-icon>
                                        </div>
                                        <div style="margin-left: 12px;font-size: 16px;font-weight: 400; color:#2d2d2d">
                                            Documentos para
                                            descargar
                                        </div>
                                    </div>

                                </div>
                                <div [ngClass]="invoice.isConfirming ? 'move-end details-docs' : 'details-docs'">
                                    <div [ngClass]="invoice.isConfirming ? 'full-screen' : 'half-screen'">
                                        <div [ngClass]="checkForDisabled(invoice)" [matTooltipClass]="checkForDisabled(invoice) === 'disabled' ? 'fs-tooltip' : ''"
                                            [matTooltip]="checkForDisabled(invoice) === 'disabled' ? 'Podrás descargar la hoja de resumen cuando la operación esté en “Por cobrar” o “Cobrada”' : '' "
                                            class="summary-sheet-button"
                                            (click)="downloadSummary('summary_sheet', invoice)">
                                            Hoja de
                                            resumen
                                            <img style="margin-left: 4px;width: 19px;"
                                                src="../../../../../../assets/icons/file_download_black_24dp.svg" />
                                        </div>
                                        <div style="font-size: 12px;margin-top: 9px; line-height: 16px;color: #2D2D2D;">
                                            Resumen de las variables determinadas para la operación y las condiciones
                                            más importantes de tu contrato.</div>
                                    </div>
                                    <div style="max-width: 100%; flex-basis: 0; flex-grow: 1;"
                                        *ngIf="!invoice.isConfirming">
                                        <div style="display: flex; align-items: center;cursor: pointer;"
                                            (click)="downloadFormalDocument('transfer_rights')">
                                            <div style="color: #0256FF;font-size: 14px;font-weight: 400;">Carta de
                                                cesión de
                                                derechos</div>
                                            <img style="margin-left: 4px;width: 19px;"
                                                src="../../../../../../assets/icons/file_download_black_24dp.svg" />
                                        </div>
                                        <div style="font-size: 12px;margin-top: 9px;line-height: 16px;;color: #2D2D2D;">
                                            Notifica a la empresa pagadora la cesión de la(s) factura(s) de esta
                                            operación. Su envío no es obligatorio, pero puede ser solicitado por
                                            nosotros o tu cliente.</div>
                                    </div>
                                    <div style="max-width: 100%; flex-basis: 0; flex-grow: 1;margin-left: 18px;"
                                        *ngIf="invoice.invoicings?.length">
                                        <div style="display: flex; align-items: center;cursor: pointer;"
                                            (click)="downloadInvoicing(invoice)">
                                            <div style="color: #0256FF;font-size: 14px;font-weight: 400;">Facturas
                                                emitidas por el servicio</div>
                                            <img style="margin-left: 4px;width: 19px;"
                                                src="../../../../../../assets/icons/file_download_black_24dp.svg" />
                                        </div>
                                        <div style="font-size: 12px;margin-top: 9px;line-height: 16px;;color: #2D2D2D;">
                                            Facturas emitidas por Finsmart por concepto del servicio brindado</div>
                                    </div>
                                </div>
                            </div>

                            <hr class="hr-detail">
                            <ng-container *ngIf="service.calculateComission(invoice) as invoice">
                                <lib-operation-detail-view [dataPointOperation]="dataPointsOperation" [entity]="invoice"
                                    [operationType]="invoice?.isConfirming" [status]="invoice?.status"
                                    [deploymentDate]="deploymentDate">
                                </lib-operation-detail-view>

                                <hr class="hr-detail">

                                <lib-cost-detail-view [finalCostDisclaimer]="invoice.status === 'awaiting collection'"
                                    [showWarningDisclaimer]="invoiceNewStatus === 'finance' || invoiceNewStatus === 'in process'"
                                    [dataPointCost]="dataPointsCostDetail" [entity]="invoice"
                                    [deploymentDate]="deploymentDate">
                                </lib-cost-detail-view>

                            </ng-container>

                            <div class="button-container">
                                <ng-container *allowedRoles="['client']">
                                    <fs-ui-button class="client-button" *ngIf="invoice.status === 'configured'"
                                        (click)="acceptInvoiceConfiguredRates(invoice)"
                                        [label]="'Acepto las condiciones'"></fs-ui-button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </lib-scroll-indicator>
            </div>
            <div class="invoices-detail">
                <ng-container>
                    <div *allowedRoles="['client']" class="title-label client-title-lable">Grupo de facturas ({{
                        invoice?.physicalInvoices.length || 0 }})</div>
                </ng-container>
                <div *allowedRoles="['client']" class="code-table">
                    <table>
                        <tr>
                            <th>Código</th>
                            <th>Importe total</th>
                            <th>Detracción/retención</th>
                            <th>Monto neto</th>
                            <ng-container *allowedRoles="['admin']">
                                <th>CAVALI</th>
                                <th *ngIf="actionType === 'edit' && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'pending confirmation')"
                                    class="edit-table">Acción</th>
                                <th *ngIf="actionType === 'ungroup' && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'configured' || invoice.status === 'pending confirmation')"
                                    class="edit-table">Acción</th>
                            </ng-container>
                            <ng-container *allowedRoles="['client']">
                                <th>N. Crédito</th>
                            </ng-container>
                        </tr>
                        <tr *ngFor="let code of invoice.physicalInvoices">
                            <td>{{ code.code }}</td>
                            <td>{{ code.totalAmount | numberSpacing}}</td>
                            <td>
                                <div style="display: flex; align-items: center;">
                                    {{ code.retentionAmount | numberSpacing}}
                                    <lib-tooltip *ngIf="code.originalRetentionAmount" width="160"
                                        [content]="'Original ' + (code.originalRetentionAmount | numberSpacing)">
                                        <img style="margin-left: 8px;" class='info-icon' />
                                    </lib-tooltip>
                                </div>
                            </td>
                            <td>
                                <div style="display: flex; align-items: center;">
                                    {{ code.netAmount | numberSpacing}}
                                    <lib-tooltip *ngIf="code.originalNetAmount" width="160"
                                        [content]="'Original ' + (code.originalNetAmount | numberSpacing)">
                                        <img style="margin-left: 8px;" class='info-icon' />
                                    </lib-tooltip>
                                </div>
                            </td>
                            <ng-container *allowedRoles="['admin']">
                                <td>
                                    <ng-container *ngIf="!code.cavaliStatus || code.cavaliStatus==='withdrawn'">
                                        <span>---</span>
                                    </ng-container>
                                    <ng-container *ngIf="code.cavaliStatus">
                                        <div class="container-status" (click)="openCavaliModal(code)">
                                            <app-statuses-label [payerResponse]="code.cavaliPayerResponse"
                                                [status]="code.cavaliStatus"></app-statuses-label>
                                        </div>
                                    </ng-container>
                                </td>
                                <td
                                    *ngIf="actionType === 'edit' && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'pending confirmation')">
                                    <span
                                        (click)="openEditInvoiceModal(code.totalAmount, code.retentionAmount, code.code)"
                                        class="action-picker">
                                        <img src="../../../../../../assets/icons/edit-invoice.svg" />
                                        <span class="title edit-table">Editar</span>
                                    </span>
                                </td>
                                <td
                                    *ngIf="actionType === 'ungroup' && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'configured' || invoice.status === 'pending confirmation')">
                                    <app-checkbox class="e2e-ungroup-checkbox"
                                        (selectionChanged)="addInvoiceUngroupList(code.code)" [checked]="false"
                                        content="Desagrupar"></app-checkbox>
                                </td>
                            </ng-container>
                            <ng-container *allowedRoles="['client']">
                                <td>
                                    {{code.creditNote ? code.creditNote?.code : '---'}}
                                </td>
                            </ng-container>
                        </tr>
                        <tr class="totalRow">
                            <ng-container *allowedRoles="['client']">
                                <td>Total</td>
                                <td>{{ getTotalOf('totalAmount') | numberSpacing}}</td>
                                <td>{{ getTotalOf('retentionAmount') | numberSpacing}}</td>
                                <td>{{ getTotalOf('netAmount') | numberSpacing}}</td>
                            </ng-container>
                        </tr>
                    </table>
                </div>
            </div>
        </lib-tab-invoice-client-detail>
    </ng-container>
    <ng-container *ngIf="!emailPanel && !observationPanel">
        <!-- *ngIf="invoice.status !== 'approved' && invoice.status !== 'in process' && invoice.status !== 'disapproved' " -->
        <!-- 
        <div class="one-line">
            <ng-container *allowedRoles="['investor']" [ngSwitch]="invoice.status">
                <div class="readyonly" *ngSwitchCase="'finalized'">
                    <app-date title="Fecha efectiva de cobro" displayStyle='plain' [date]="invoice.actualPaymentDate">
                    </app-date>
                </div>
                <div *ngSwitchDefault class="readyonly">
                    <app-date title="Fecha proyectada de cobro" displayStyle='plain' [date]="invoice.paymentDate">
                    </app-date>
                </div>
            </ng-container>
        </div> -->

        <ng-container>
            <ng-container *allowedRoles="['admin', 'client happiness']">
                <div [ngSwitch]="invoice.status" class="readyonly">
                    <div class="one-line">
                        <div class="one-line">
                            <div class="readyonly">
                                <app-date title="Fecha de desembolso" displayStyle='plain'
                                    [date]="invoice.advancePaymentDate" titleColor="gray">
                                </app-date>
                            </div>
                        </div>

                        <div class="readyonly">
                            <span class="tiny-title">TCEM</span>
                            <div class="last-config">
                                <span class="value" *ngIf="invoice.tdmPercentage">{{ invoice.tdmPercentage }}%</span>
                                <span class="value" *ngIf="!invoice.tdmPercentage">-</span>
                                <lib-tooltip *ngIf="lastConfiguration?.tdmPercentage" width="160"
                                    [content]="'Anterior ' + lastConfiguration?.tdmPercentage + '%' ">
                                    <img style="margin-left: 8px;" class='info-icon' />
                                </lib-tooltip>
                            </div>
                        </div>
                    </div>


                    <div class="one-line">
                        <div class="one-line">
                            <div class="readyonly">
                                <div class="one-line button-actions">
                                    <form [formGroup]="form">
                                        <app-date-picker *ngIf="editPaymentFlag" [default]="invoice.paymentDate"
                                            formControlName="paymentDate" label="Fecha de cobro*"></app-date-picker>
                                    </form>
                                    <div class="one-line">
                                        <span (click)="updatePaymentDate()" class="edit-picker" *ngIf="editPaymentFlag">
                                            <img src="../../../../../../assets/icons/save.svg" />
                                            <span class="title">Guardar</span>
                                        </span>
                                        <span (click)="openDatePicker()" class="edit-picker" *ngIf="editPaymentFlag">
                                            <img src="../../../../../../assets/icons/cancel.svg" />
                                            <span class="title">Cancelar</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="date-edit-container">
                                    <div class="icon-text">
                                        <app-date *ngIf="!editPaymentFlag" title="Fecha de cobro" displayStyle='plain'
                                            titleColor="gray" [date]="invoice.paymentDate"></app-date>
                                        <lib-tooltip *ngIf="invoice.originalPaymentDate" width="160"
                                            [content]="'Original ' + (invoice.originalPaymentDate | formatDate)">
                                            <img style="margin-left: 8px;" class='info-icon' />
                                        </lib-tooltip>
                                    </div>
                                    <ng-container *allowedRoles="['admin']">
                                        <span (click)="openDatePicker()" class="edit-picker" style="margin-left: 15px;"
                                            *ngIf="!editPaymentFlag && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'pending confirmation')">
                                            <img src="../../../../../../assets/icons/edit_black_24dp.svg" />
                                            <span class="title">Editar</span>
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>


                        <div class="one-line">
                            <div class="readonly">
                                <div class="one-line">
                                    <div class="readyonly" style="margin-left: 15px;">
                                        <span class="tiny tiny-title" style="text-align: right;">
                                            Garantía
                                        </span>
                                        <div class="one-line" style="justify-content: right;">
                                            <ng-container *allowedRoles="['admin']">
                                                <span (click)="acceptWarranty()" class="edit-picker"
                                                    style="margin-right: 15px;"
                                                    *ngIf="invoice.isConfirming && invoice.reservationAmount && warrantyCheck">
                                                    <img height="16px" width="16px"
                                                        src="../../../../../../assets/icons/done_black_24dp.svg" />
                                                    <span class="accept-tittle">Aceptar</span>
                                                </span>
                                            </ng-container>
                                            <div class="last-config">
                                                <span class="value"
                                                    *ngIf="invoice.reservationPercentage">{{invoice.reservationAmount |
                                                    numberSpacing}} ({{invoice.reservationPercentage}}%)</span>
                                                <span class="value" *ngIf="!invoice.reservationPercentage">-</span>
                                                <lib-tooltip *ngIf="lastConfiguration?.reservationPercentage"
                                                    width="160"
                                                    [content]="'Anterior ' + lastConfiguration?.reservationPercentage + '%' ">
                                                    <img style="margin-left: 8px;" class='info-icon' />
                                                </lib-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="one-line">
                        <div class="readyonly" *ngSwitchCase="'finalized'">
                            <app-date title="Fecha de cobro real" displayStyle='plain'
                                [date]="invoice.actualPaymentDate">
                            </app-date>
                        </div>
                        <div class="readyonly" *ngSwitchCase="'collected'">
                            <app-date title="Fecha de cobro real" displayStyle='plain'
                                [date]="invoice.actualPaymentDate">
                            </app-date>
                        </div>
                        <div *ngSwitchDefault>
                        </div>
                        <div class="one-line">
                            <div class="readyonly">
                                <div class="one-line button-actions equilibrium-top">
                                    <div class="one-line">
                                        <span (click)="updateDistribution()" class="edit-picker"
                                            *ngIf="editDistributionFlag">
                                            <img src="../../../../../../assets/icons/save.svg" />
                                            <span class="title">Guardar</span>
                                        </span>
                                        <span (click)="openDistributionEditer()" class="edit-picker"
                                            *ngIf="editDistributionFlag">
                                            <img src="../../../../../../assets/icons/cancel.svg" />
                                            <span class="title">Cancelar</span>
                                        </span>
                                    </div>
                                    <form class="distributionform" [formGroup]="editDistributionForm">
                                        <app-input inputError="true" default="{{invoice.tea}}" type="number"
                                            label="Tasa anualizada" formControlName="newDistribution"
                                            *ngIf="editDistributionFlag"></app-input>
                                    </form>
                                </div>
                                <div class="one-line">

                                    <div class="readyonly">
                                        <span class="tiny tiny-title" style="text-align: right;"
                                            *ngIf="!editDistributionFlag">
                                            Tasa anualizada
                                        </span>

                                        <div *ngIf="!editDistributionFlag" class="last-config">
                                            <ng-container *allowedRoles="['client happiness']">
                                                <!-- <span *ngIf="showAcceptButton" (click)="acceptTea()"
                                                    class="done-tea-button" style="margin-right: 0;">
                                                    <img src="../../../../../../assets/icons/done_black_24dp.svg" />
                                                    <span class="title">Aceptar</span>
                                                </span> -->
                                                <span (click)="openDistributionEditer()"
                                                    class="edit-picker tea-edit-button"
                                                    *ngIf="!editDistributionFlag && (invoice.status === 'pending confirmation' || invoice.status === 'configured')">
                                                    <img src="../../../../../../assets/icons/edit_black_24dp.svg" />
                                                    <span class="title">Editar</span>
                                                </span>
                                            </ng-container>

                                            <ng-container style="margin-left: 15px;">
                                                <div class="teaContent">
                                                    <span class="teaValue"
                                                        *ngIf="!editDistributionFlag && invoice.distributionPercentage">{{
                                                        invoice.tea }}%
                                                    </span>
                                                    <span class="teaValue"
                                                        *ngIf="!editDistributionFlag && !invoice.distributionPercentage">-</span>
                                                    <lib-tooltip *ngIf="lastConfiguration?.tea" width="160"
                                                        [content]="'Anterior ' + lastConfiguration?.tea + '%' ">
                                                        <img style="margin-left: 8px;" class='info-icon' />
                                                    </lib-tooltip>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="one-line">
                        <div class="one-line">
                            <div class="readyonly">
                                <span *ngIf="!invoice.actualDuration" class="tiny-title">Duración mínima</span>
                                <span *ngIf="invoice.actualDuration" class="tiny-title">Duración</span>
                                <span class="value"
                                    *ngIf="invoice.actualDuration || invoice.minimumDuration; else na_value">
                                    {{ invoice.actualDuration || invoice.minimumDuration }} días
                                </span>
                            </div>
                        </div>

                        <div class="readyonly">
                            <span class="tiny-title">Tasa mensual inv.</span>
                            <span class="value" *ngIf="invoice.tem">{{ invoice.tem
                                }}%</span>
                            <span class="value" *ngIf="!invoice.tem">-</span>
                        </div>
                    </div>
                </div>

                <div class="one-line" *allowedRoles="['admin']">
                    <div class="readyonly large-bank">
                        <form [formGroup]="formbank">
                            <app-select *ngIf="!invoice.isConfirming" formControlName="bankAccount" [hasTooltip]=true
                                (OnSelectionChanged)="changeBankAccount($event)" [options]="bankAccounts"
                                [defaultOption]="selectedBankAccount" valueField="id" textField="bankSummary"
                                label="Cuenta bancaria asociada*"></app-select>

                            <app-select *ngIf="invoice.isConfirming" formControlName="bankAccount" [hasTooltip]=true
                                (OnSelectionChanged)="changeBankAccount($event)" [options]="bankAccounts"
                                [defaultOption]="selectedBankAccount" valueField="id" textField="bankSummary"
                                label="Cuenta bancaria pagador (garantía)"></app-select>
                        </form>
                    </div>

                    <div class="readyonly  large-bank" *ngIf="invoice.isConfirming">
                        <form [formGroup]="formbankSupplier">
                            <app-select *ngIf="invoice.isConfirming" formControlName="bankAccountSupplier"
                                [hasTooltip]=true (OnSelectionChanged)="changeBankSupplier($event)"
                                [options]=bankAccountsSupplier [defaultOption]="selectedBankAccountSupplier"
                                valueField="id" textField="bankSummary" label="Cuenta bancaria proveedor (adelanto)">
                            </app-select>
                        </form>
                    </div>
                </div>


            </ng-container>

        </ng-container>




        <div class="row-container" *allowedRoles="['admin']">
            <div class="left">
                <div class="readyonly">
                    <h5>Grupo de facturas ({{invoice.physicalInvoices.length}})</h5>
                </div>
            </div>
            <div class="right container-actions">
                <div class="available-action-ungroup"
                    *ngIf="invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'pending confirmation'">
                    <span class="action e2e-group-action" (click)="groupingInvoices(invoice)">
                        <!-- [routerLink]="'grouping'" -->
                        <img src="../../../../../../assets/icons/final/grouping.svg" />
                        <span class="title">Agrupar</span>
                    </span>
                </div>
                <div class="available-action-ungroup"
                    *ngIf="invoice?.physicalInvoices.length > 1 && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'configured' || invoice.status === 'pending confirmation')">
                    <div *ngIf="actionType !== 'ungroup'; else saveUngrouping">
                        <span (click)="activateUngrouping(invoice)" class="action e2e-ungroup-action">
                            <img src="../../../../../../assets/icons/fullscreen_black_24dp.svg" />
                            <span class="title">Desagrupar</span>
                        </span>
                    </div>
                    <ng-template #saveUngrouping>
                        <div div class="one-line">
                            <div class="left">
                                <span
                                    *ngIf="invoiceUngroupList.length && invoiceUngroupList.length !== invoice?.physicalInvoices.length"
                                    (click)="ungroupInvoices(invoice)" class="action e2e-ungroup-save">
                                    <img class="ungroup-icon"
                                        src="../../../../../../assets/icons/final/floppy-disk.png" />
                                    <span class="title-alt">Guardar</span>
                                </span>
                            </div>
                            <div class="right">
                                <span (click)="deactivateUngrouping()" class="action red-filter e2e-ungroup-cancel">
                                    <img class="ungroup-icon"
                                        src="../../../../../../assets/icons/final/bold-cancel.png" />
                                    <span class="title-alt">Cancelar</span>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div class="available-action-cavali" *ngIf="invoice && invoice.isSunatProcess">
                    <div class="mr-10">
                        <span (click)="creditNoteFile.click()" class="action">
                            <img [className]="iconClassCreditNote" />
                            <span class="title c-gray">Notas de crédito</span>
                        </span>
                        <input hidden type="file" #creditNoteFile (change)="uploadCreditNote($event.target.files)"
                            accept="text/xml" multiple (click)="creditNoteFile.value=null">
                    </div>
                </div>
                <div class="available-action-cavali"
                    *ngIf="(invoice.status === 'approved' || invoice.status === 'configured' || invoice.status === 'pending confirmation')">
                    <div *ngIf="showUploadToCavaliButton" class="mr-10">
                        <span (click)="uploadToCavali(invoice)" class="action">
                            <img [className]="iconClass" />
                            <span class="title c-blue">Subir a Cavali</span>
                        </span>
                    </div>
                    <div *ngIf="showRemoveCavaliButton">
                        <span (click)="removeFromCavali(invoice)" class="action">
                            <img [className]="iconClassWithdraw" />
                            <span class="title c-red">Retirar de Cavali</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>


        <ng-container *ngIf="(invoice.hasOwnProperty('cavaliPayerRegistered') && !invoice.cavaliPayerRegistered)">
            <div *allowedRoles="['admin']" class="container-payer">
                <span class="img-container">
                    <img src="../../../../../../assets/icons/info-white.svg" alt="" srcset="">
                </span>
                <span>
                    Empresa pagadora no está registrada en CAVALI.
                </span>
            </div>
        </ng-container>

        <div *allowedRoles="['admin']" class="code-table">
            <table>
                <tr>
                    <th>Código</th>
                    <th>Importe total</th>
                    <th>Detracción/retención</th>
                    <th>Monto neto</th>
                    <ng-container *allowedRoles="['admin']">
                        <th>CAVALI</th>
                        <th *ngIf="actionType === 'edit' && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'pending confirmation')"
                            class="edit-table">Acción</th>
                        <th *ngIf="actionType === 'ungroup' && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'configured' || invoice.status === 'pending confirmation')"
                            class="edit-table">Acción</th>
                    </ng-container>
                    <ng-container *allowedRoles="['admin']">
                        <th>N. Crédito</th>
                    </ng-container>
                </tr>
                <tr *ngFor="let code of invoice.physicalInvoices">
                    <td>{{ code.code }}</td>
                    <td>{{ code.totalAmount | numberSpacing}}</td>
                    <td>
                        <div style="display: flex; align-items: center;">
                            {{ code.retentionAmount | numberSpacing}}
                            <lib-tooltip *ngIf="code.originalRetentionAmount" width="160"
                                [content]="'Original ' + (code.originalRetentionAmount | numberSpacing)">
                                <img style="margin-left: 8px;" class='info-icon' />
                            </lib-tooltip>
                        </div>
                    </td>
                    <td>
                        <div style="display: flex; align-items: center;">
                            {{ code.netAmount | numberSpacing}}
                            <lib-tooltip *ngIf="code.originalNetAmount" width="160"
                                [content]="'Original ' + (code.originalNetAmount | numberSpacing)">
                                <img style="margin-left: 8px;" class='info-icon' />
                            </lib-tooltip>
                        </div>
                    </td>
                    <ng-container *allowedRoles="['admin']">
                        <td>
                            <ng-container *ngIf="!code.cavaliStatus || code.cavaliStatus==='withdrawn'">
                                <span>---</span>
                            </ng-container>
                            <ng-container *ngIf="code.cavaliStatus">
                                <div class="container-status" (click)="openCavaliModal(code)">
                                    <app-statuses-label [payerResponse]="code.cavaliPayerResponse"
                                        [status]="code.cavaliStatus"></app-statuses-label>
                                </div>
                            </ng-container>
                        </td>
                        <td
                            *ngIf="actionType === 'edit' && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'pending confirmation')">
                            <span (click)="openEditInvoiceModal(code.totalAmount, code.retentionAmount, code.code)"
                                class="action-picker">
                                <img src="../../../../../../assets/icons/edit-invoice.svg" />
                                <span class="title edit-table">Editar</span>
                            </span>
                        </td>
                        <td
                            *ngIf="actionType === 'ungroup' && (invoice.status === 'approved' || invoice.status === 'in process' || invoice.status === 'configured' || invoice.status === 'pending confirmation')">
                            <app-checkbox class="e2e-ungroup-checkbox"
                                (selectionChanged)="addInvoiceUngroupList(code.code)" [checked]="false"
                                content="Desagrupar"></app-checkbox>
                        </td>
                    </ng-container>
                    <ng-container *allowedRoles="['admin']">
                        <td>
                            {{code.creditNote ? code.creditNote?.code : '---'}}
                        </td>
                    </ng-container>
                </tr>
                <tr class="totalRow">
                    <ng-container *allowedRoles="['client']">
                        <td>Total</td>
                        <td>{{ getTotalOf('totalAmount') | numberSpacing}}</td>
                        <td>{{ getTotalOf('retentionAmount') | numberSpacing}}</td>
                        <td>{{ getTotalOf('netAmount') | numberSpacing}}</td>
                    </ng-container>
                </tr>
            </table>

        </div>
    </ng-container>

    <ng-container *ngIf="emailPanel">
        <div class="code-table">
            <table>
                <tr>
                    <th>Códigos</th>
                    <th>
                        <span *ngIf="!invoice.actualDuration" class="tiny-title">Duración mínima</span>
                        <span *ngIf="invoice.actualDuration" class="tiny-title">Duración</span>
                    </th>
                    <th>TCEM</th>
                    <th>Adelanto</th>
                    <th>Fecha de cobro</th>
                    <th>Colchón</th>
                </tr>
                <tr>
                    <td class="code-row">
                        <p *ngFor="let code of invoice.physicalInvoices">
                            {{code.code}}
                        </p>
                    </td>
                    <td>
                        {{ invoice.actualDuration || invoice.minimumDuration }} días
                    </td>
                    <td>{{ invoice.tdmPercentage }}</td>
                    <td>{{ invoice.advanceAmount | numberSpacing}}</td>
                    <td>{{ invoice.paymentDate | formatDate }}</td>
                    <td
                        [ngClass]="{'eval-red':invoice.evaluationDays >= 0 && invoice.evaluationDays <=15, 'eval-yellow': invoice.evaluationDays >15 && invoice.evaluationDays <=30, 'eval-green':invoice.evaluationDays > 30}">
                        {{ invoice.evaluationDays }} días</td>
                </tr>
            </table>
        </div>

        <h3 class="mb-30">ENVÍO DE CORREOS</h3>

        <div class="section-email-sent">
            <div class="one-line">
                <div>
                    <p class="title-email-p">Correos enviados</p>
                </div>
            </div>

            <ng-container *ngIf="invoice.emailSent.length > 0; else defaultSent">
                <ng-container *ngFor="let item of invoice.emailSent">
                    <app-email-row-operation [title]="item.title" [subject]="item.subject" [sendTo]="item.to"
                        [cc]="item.cc" [count]="item.count" [type]="item.type" [isExternal]="item.isExternal"
                        [status]="item.status" [detail]="item.detail" (senEmail)="send($event)">
                    </app-email-row-operation>
                </ng-container>
            </ng-container>
            <ng-template #defaultSent>
                <div class="container-disclaimer">
                    <p class="disclaimer">No hay correos enviados.</p>
                </div>
            </ng-template>
        </div>

        <div class="section-email-ready">
            <div class="one-line">
                <div>
                    <p class="title-email-p">Correos listo para enviar</p>
                </div>
            </div>
            <ng-container *ngIf="invoice.emailReady.length > 0; else default">
                <ng-container *ngFor="let item of invoice.emailReady">
                    <app-email-row-operation [title]="item.title" [subject]="item.subject" [sendTo]="item.to"
                        [cc]="item.cc" [type]="item.type" [isExternal]="item.isExternal" [status]="item.status"
                        (senEmail)="send($event)">
                    </app-email-row-operation>
                </ng-container>
            </ng-container>
            <ng-template #default>
                <div class="container-disclaimer">
                    <p class="disclaimer">No hay correos listos para enviar.</p>
                </div>
            </ng-template>
        </div>
    </ng-container>

    <ng-container *ngIf="observationPanel">

        <h3 class="mb-30">OBSERVACIONES</h3>

        <div class="section-email-sent">
            <ng-container *ngIf="userObservations.length > 0; else defaultObs">
                <div class="bank-and-currency row">
                    <ng-container *ngIf="userObservations | sort:('createdAt'):'closest' as obsSorted">
                        <table class="obs-table">
                            <thead>
                                <tr>
                                    <th class="col-invoice">Descripcion</th>
                                    <th class="col-amount">Monto</th>
                                    <th class="col-retention">Comentario</th>
                                    <th class="col-net-amount">¿IGV?</th>
                                    <th class="col-net-amount">Accion</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ob of obsSorted " class="table-row">
                                    <td>
                                        <ng-container *ngIf="ob.description ? ob.description?.length > 30: false">
                                            <p [ngClass]="{'font-tached' : ob.isDone === true}"
                                                class="tooltip-container-not-dots">
                                                <span class="noWrap">
                                                    {{(ob?.description | limitTo: '30') + (ob?.description.length > 30 ?
                                                    '...' :
                                                    '')}}
                                                </span>
                                                <span class="tooltiptext-left">{{ob?.description}}</span>
                                            </p>
                                        </ng-container>
                                        <ng-container *ngIf="ob.description?.length <= 30">
                                            <span [ngClass]="{'font-tached' : ob.isDone === true}">
                                                {{ob.description ? ob.description.trim() !== '' ? ob.description : '---'
                                                : '---'}}
                                            </span>
                                        </ng-container>
                                    </td>
                                    <td [ngClass]="{'font-tached' : ob.isDone === true}" class="noWrap">{{ ob.currency |
                                        uppercase }}
                                        {{
                                        ob.amount | numberSpacing}}</td>
                                    <td>
                                        <ng-container *ngIf="ob.comment ? ob.comment?.length > 30: false">
                                            <p class="tooltip-container-not-dots"
                                                [ngClass]="{'font-tached' : ob.isDone === true}">
                                                <span class="noWrap">
                                                    {{(ob?.comment | limitTo: '30') + (ob?.comment.length > 30 ? '...' :
                                                    '')}}
                                                </span>
                                                <span class="tooltiptext-left">{{ob?.comment}}</span>
                                            </p>
                                        </ng-container>
                                        <ng-container *ngIf="ob.comment?.length <= 30">
                                            <span [ngClass]="{'font-tached' : ob.isDone === true}">
                                                {{ob.comment ? ob.comment.trim() !== '' ? ob.comment : '---' : '---'}}
                                            </span>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <!-- <app-slide-toggle [firstLabel]="'si'" [secondLabel]="'no'" [checked]="!ob.isTaxable"
                                        (selectionChanged)="setIGV(ob)"></app-slide-toggle> -->
                                        <app-checkbox (selectionChanged)="setIGV(ob)" [checked]="ob.isTaxable"
                                            content="" color="accent">
                                        </app-checkbox>
                                    </td>
                                    <td>
                                        <div class="switch-icon">
                                            <!-- <div *ngIf="!ob.isDone" class="button-observation"
                                                (click)="activeObservation(ob)">
                                                <p>OFF</p>
                                            </div>
                                            <div *ngIf="ob.isDone" class="button-observation-on"
                                                (click)="activeObservation(ob)">
                                                <p>ON</p>
                                            </div> -->
                                            <app-slide-toggle [firstLabel]="'On'" [secondLabel]="'Off'"
                                                [checked]="ob.isDone" (selectionChanged)="activeObservation(ob)">
                                            </app-slide-toggle>
                                            <div class="button-pointer" (click)="deleteObs(ob)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                    viewBox="0 0 34 34">
                                                    <defs>
                                                        <style>
                                                            .a-delete {
                                                                fill: red;
                                                            }

                                                            .b-delete {
                                                                fill: none;
                                                            }

                                                            .c-delete {
                                                                fill: #f2f2f2;
                                                            }
                                                        </style>
                                                    </defs>
                                                    <g transform="translate(-1572 -473)">
                                                        <circle class="a-delete" cx="17" cy="17" r="17"
                                                            transform="translate(1572 473)" />
                                                        <g transform="translate(1579 480)">
                                                            <path class="b-delete" d="M0,0H20V20H0Z" />
                                                            <g transform="translate(4.167 2.5)">
                                                                <path class="c-delete"
                                                                    d="M5.833,16.333A1.672,1.672,0,0,0,7.5,18h6.667a1.672,1.672,0,0,0,1.667-1.667v-10h-10Zm10-12.5H13.75l-.592-.592A.841.841,0,0,0,12.575,3H9.092a.841.841,0,0,0-.583.242l-.592.592H5.833a.833.833,0,1,0,0,1.667h10a.833.833,0,0,0,0-1.667Z"
                                                                    transform="translate(-5 -3)" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                </div>
            </ng-container>
            <ng-template #defaultObs>
                <div class="container-disclaimer">
                    <p class="disclaimer">No hay observaciones.</p>
                </div>
            </ng-template>
        </div>

    </ng-container>

    <ng-container *allowedRoles="['client happiness']">
        <lib-tab-invoice-detail [selected]="selected" (selectedTab)="updateTabSelected($event)">
            <div class="invoices">
                <div class="row container-transaction">
                    <div>
                        <h6>Grupo de facturas ({{ invoice?.physicalInvoices.length || 0 }})</h6>
                    </div>
                    <div class="code-table">
                        <table>
                            <tr>
                                <th>Código</th>
                                <th>Importe total</th>
                                <th>Detracción/retención</th>
                                <th>Monto neto</th>
                                <ng-container *allowedRoles="['client happiness']">
                                    <th>CAVALI</th>
                                </ng-container>
                            </tr>
                            <tr *ngFor="let code of invoice.physicalInvoices">
                                <td>{{ code.code }}</td>
                                <td>{{ code.totalAmount | numberSpacing}}</td>
                                <td>{{ code.retentionAmount | numberSpacing}}</td>
                                <td>{{ code.netAmount | numberSpacing}}</td>
                                <ng-container *allowedRoles="['client happiness']">
                                    <td>
                                        <ng-container *ngIf="!code.cavaliStatus || code.cavaliStatus==='withdrawn'">
                                            <span>---</span>
                                        </ng-container>
                                        <ng-container *ngIf="code.cavaliStatus">
                                            <div class="container-status">
                                                <app-statuses-label [payerResponse]="code.cavaliPayerResponse"
                                                    [status]="code.cavaliStatus"></app-statuses-label>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
            <div class="investors">
                <div class="row container-transaction">
                    <div>
                        <h6>Detalle de inversionistas ({{ investments?.length || 0 }})</h6>
                    </div>
                    <div *ngIf="investments?.length > 0; else empty_table" class="code-table">
                        <table>
                            <tr>
                                <th>Inversionista</th>
                                <th>Correo</th>
                                <th>Celular</th>
                                <th>Monto invertido</th>
                                <th>Distribución</th>
                                <th></th>

                            </tr>
                            <tr *ngFor="let invoice of investments">
                                <td>
                                    <lib-text *ngIf="(invoice?.names + invoice?.familyNames).length > 25"
                                        [tooltipText]="invoice?.names + ' ' + invoice?.familyNames | capitalizeFirstLetters"
                                        tooltip="true" tooltipSize="160"
                                        [content]="invoice?.names + ' ' + invoice?.familyNames | limitTo: '25' | capitalizeFirstLetters">
                                    </lib-text>

                                    <lib-text *ngIf="(invoice.names + invoice.familyNames).length < 26"
                                        [content]="invoice.names + ' ' + invoice.familyNames | capitalizeFirstLetters">
                                    </lib-text>
                                </td>
                                <td>
                                    <lib-text *ngIf="invoice?.email.length > 25" [tooltipText]="invoice?.email"
                                        tooltip="true" tooltipSize="160" [content]="invoice?.email | limitTo: '25'">
                                    </lib-text>

                                    <lib-text *ngIf="invoice.email.length < 26" [content]="invoice.email">
                                    </lib-text>
                                </td>
                                <td>
                                    <lib-text *ngIf="invoice?.phone.length > 15" [tooltipText]="invoice?.phone"
                                        tooltip="true" tooltipSize="160" [content]="invoice?.phone | limitTo: '15'">
                                    </lib-text>

                                    <lib-text *ngIf="invoice.phone.length < 16" [content]="invoice.phone">
                                    </lib-text>
                                </td>
                                <td>
                                    <lib-text *ngIf="invoice?.amount.length > 15" [tooltipText]="invoice?.amount"
                                        tooltip="true" tooltipSize="160"
                                        [content]="invoice?.amount | numberSpacing | limitTo: '15' ">
                                    </lib-text>

                                    <lib-text *ngIf="invoice.amount.length < 16"
                                        [content]="invoice.amount | numberSpacing">
                                    </lib-text>

                                <td>
                                    <lib-text [content]="invoice.distributionPercentage + '%'">
                                    </lib-text>
                                </td>
                                <td>
                                    <div style="display: flex; flex-direction: row;">
                                        <lib-icon icon="annoucement"
                                            [color]="invoice?.isNewAnnouncement ? 'yellow' : 'dark-gray'" tooltip="true"
                                            tooltipWidth="160"
                                            [tooltipText]="invoice?.isNewAnnouncement ? 'Actualización por revisar' : 'Sin actualizaciones por revisar'">
                                        </lib-icon>
                                        <lib-icon (click)="resendEmail(invoice)" style="margin-left: 5px;" icon="mail"
                                            color="blue" tooltipWidth="100" tooltip="true"
                                            tooltipText="Reenviar correo">
                                        </lib-icon>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="annoucement">
                <div class="row container-transaction">
                    <div class="one-line">
                        <div class="icon-text">
                            <lib-icon icon="annoucement" size="m" [color]="'black'"></lib-icon>&nbsp;
                            <h6>
                                Actualizaciones de
                                cobranzas ({{announcements?.length}})
                            </h6>
                        </div>
                        <div (click)="newAnnouncement(invoice)" class="icon-text hyperlink">
                            <mat-icon class="new-announcement">add</mat-icon>
                            <a class="hyperlink">Nueva actualización</a>
                        </div>
                    </div>
                    <div *ngIf="announcements?.length > 0; else empty_announcements" class="announcements-component">
                        <ng-container *ngFor="let item of announcements  | sort:'createdAt':'desc'">
                            <app-announcement-card [announcement]="item" (editEvent)="editAnnouncement($event)"
                                (resendEvent)="resendAnnouncement($event)">
                            </app-announcement-card>
                        </ng-container>
                    </div>

                    <ng-template #empty_announcements>
                        <div class="investments">
                            <p class="disclaimer">No hay actualizaciones registradas.</p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </lib-tab-invoice-detail>
    </ng-container>

    <div class="controls button-container">


        <ng-container *allowedRoles="['admin']">
            <fs-ui-button class="remove-space" *ngIf="observationPanel" (click)="addObservation(invoice)"
                [label]="'Nueva observación'">
            </fs-ui-button>
        </ng-container>

        <fs-ui-button class="space-top" *ngIf="!emailPanel && !observationPanel" (click)="goBack()" [label]="'Regresar'"
            [type]="'tertiary'"></fs-ui-button>

        <fs-ui-button class="space-top" *ngIf="emailPanel" (click)="openEmailPanel()" [label]="'Regresar'"
            [type]="'tertiary'">
        </fs-ui-button>

        <fs-ui-button class="space-top" *ngIf="observationPanel" (click)="openObsPanel()" [label]="'Regresar'"
            [type]="'tertiary'">
        </fs-ui-button>
    </div>

</ng-container>
<ng-template #empty_table>
    <div class="investments">
        <p class="disclaimer">No hay inversiones registradas.</p>
    </div>
</ng-template>
<ng-template #na_value>
    <span class="value">NA</span>
</ng-template>