<div class="one-line-title">
    <app-page-title [title]="'Pagadores'"></app-page-title>
    <div class="button-container" *ngIf="loggedInUserRole == 'risk analyst'">
        <fs-ui-button (click)="uploadInput.click()" [label]="'Subir pagadores'"></fs-ui-button>
    </div>
</div>
<form [formGroup]="filtersForm" (ngSubmit)="applyFilter()">
    <div class="filter-row">
        <app-autocomplete label="Empresa pagadora" searchFor="debtor" [selectedOption]="selectedDebtor"
            formControlName="debtor" (OnSelectionChangedObject)="setDebtor($event)"> 
        </app-autocomplete>
        <app-autocomplete label="Empresa giradora" searchFor="debtor" [selectedOption]="selectedSupplier"
            formControlName="supplier" (OnSelectionChangedObject)="setSupplier($event)">
        </app-autocomplete>
    </div>
    <div class="filter-row">
        <mat-form-field appearance="fill">
            <mat-label>Ejecutivo de ventas</mat-label>
            <mat-select formControlName="accountManagers" multiple>
                <mat-option value="cartera">
                    Cartera
                </mat-option>
                <mat-option *ngFor="let accountManager of accountManagers" [value]="accountManager._id">
                    {{ accountManager.names + ' ' + accountManager.familyNames | capitalizeFirstLetters }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="filter-row">
            <mat-form-field appearance="fill">
                <mat-label>Línea otorgada (desde)</mat-label>
                <input matInput formControlName="creditLineFrom">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Línea otorgada (hasta)</mat-label>
                <input matInput formControlName="creditLineTo">
            </mat-form-field>
        </div>
    </div>

    <div class="filter-row">
        <input type="button" (click)="resetFiltersToDefaults()" class="button button-dark-blue" value="Predeterminados">
        <input type="submit" class="button button-blue" value="Aplicar">
        <input type="button" (click)="resetFilters()" class="button button-gray" value="Borrar">
    </div>
</form>

<div class="actions">
    <div>
        <h5>Empresas <span class="bold">({{pagination?.total}})</span></h5>
    </div>

    <div class="right click" (click)="exportExcel()" *ngIf="loggedInUserRole == 'risk analyst'">
        <img src="./../../../../../../assets/icons/excel.svg" class="export">
        <span class="tiny blue">Exportar a Excel</span>
    </div>
</div>

<div class="item" *ngFor="let debtor of debtors">
    <app-risk-debtor-item [routerLink]="debtor._id" [entity]="debtor">
    </app-risk-debtor-item>
</div>

<app-paginator [totalCount]="pagination.total" [pageSize]="pagination.limit" [skip]="pagination.skip"
    (pagination)="paginationHandler($event)">
</app-paginator>

<input 
#uploadInput
hidden="true"
class="form-control" 
type="file"
(click)="uploadInput.value=null" 
(change)="onFileChange($event)"
accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">