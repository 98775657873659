<h1>Correos de contacto</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <p class="medium xs text-dark-gray new-email-title">Nuevo correo</p>
        <app-input formControlName="emails" autocomplete="off" type="text" label="Correo"></app-input>
        <mat-hint *ngIf="emailValid">
            <p [ngClass]="{'custom-mat-invalid-style': emailValid === true}">Correo duplicado</p>
        </mat-hint>
    </div>

    <input type="submit" class="button button-dark-blue" value="Agregar correo" />

    <ng-container *ngIf="emails">
        <div class="emails-module">
            <p class="medium xs text-dark-gray title-emails">Lista de correos</p>
            <ng-container *ngFor='let email of emails; index as i'>
                <div class="row">
                    <div class="name">
                        <span class="title">
                            {{ email?.email }}
                        </span>
                    </div>
                    <div class="name">
                        <span class="title">
                            <img class="cancel-icon" (click)="deleteEmail(email?.email)" src="../../../../assets/icons/trash.svg" />
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>


    <input type="button" class="button button-gray" value="Regresar" (click)="cancel()" />
</form>