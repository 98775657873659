import { Component, OnInit, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-radiolist',
  templateUrl: './radiolist.control.html',
  styleUrls: ['./radiolist.control.scss']
})
export class RadiolistControl implements OnInit, ControlValueAccessor {
  @Input() options;
  @Input() defaultOption;

  _selectedValue;
  _errorStatus;
  
  errorMessage;

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    if(val !== undefined) {
      this._selectedValue = val;
      this.propagateChange(this._selectedValue);
    }
  }

  constructor (@Optional()@Self() private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }
  
  ngOnInit() {
    if(this.defaultOption) {
      this.selectedValue = this.defaultOption;
    }

    this.ngControl.control.parent.statusChanges.subscribe(formStatus => {
      if(formStatus === 'INVALID' && this.ngControl.errors && (this.ngControl.dirty || this.ngControl.touched)) {
        this._errorStatus = true;
        this.errorMessage = this.getErrorLabel();
      } else {
        this.errorMessage = '';
      }
    });
  }

  getErrorLabel() {
    if(this.ngControl.hasError('required')) {
      return 'Debe seleccionar uno';
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.selectedValue = value;
    }
  }

  changeSelection(selection) {
    this.selectedValue = selection;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

}
