import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DebtorService } from 'src/app/features/risk-debtors/services/debtor.service';

@Component({
  selector: 'app-cross-selling',
  template: '',
})
export class CrossSellingComponent implements OnInit {
  constructor(private route: ActivatedRoute, private service: DebtorService) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const { ruc } = params;
      this.service.createCrossSellingDeal(ruc).subscribe(
        () => {
          window.location.href = `https://api.whatsapp.com/send?phone=+51913150981&text=Hola,%20cu%C3%A9ntame%20m%C3%A1s%20sobre%20mi%20l%C3%ADnea%20aprobada%20de%20Confirming.%20Mi%20RUC%20es:%20${ruc}`;
        },
        (e) => {
          window.location.href = '/';
        }
      );
    });
  }
}
