import { UsersService } from 'src/app/features/users/services/users.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SuplierService } from '../../../../my-supliers/services/supliers.service';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import _ from 'lodash';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { ModalConfirmationCancelComponent } from '../../organisms/modal-confirmation-cancel/modal-confirmation-cancel.component';
import { Router } from '@angular/router';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { InvoicesActions } from '../../../state/invoices.action-types';
import { InvoiceSelectors } from '../../../state/invoices.selector-types';
import { operationType } from 'src/app/shared/enums/filter.enum';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss'],
})
export class AddInvoiceComponent implements OnInit, OnDestroy {
  dialogRef: MatDialogRef<ModalConfirmationCancelComponent>;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  isLoggedInUserAnAdmin: boolean;
  profile: any;
  clientRuc: string;
  userId: string;
  step = 0;

  isConfirming: boolean;
  operationType = '';
  invoiceGroups = [];
  invoiceErrors = [];
  invoices = [];
  _stepValid = false;
  nextButton = 'Continuar';
  errorMessage = '';
  readonly tourSteps2: IStepOption[] = [
    {
      anchorId: 'step1',
      content:
        'Las facturas por financiar pueden ser subidas en un solo grupo. Ya no es necesario subir las facturas de diferentes fechas de pago, monedas o proveedores/clientes por separado.',
      endBtnTitle: 'Entiendo',
      title: 'Sube todas tus facturas en un solo bloque',
      enableBackdrop: true,
    },
  ];

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private userService: UsersService,
    public supplierService: SuplierService,
    public tourService: TourService,
    private router: Router,
    private overlayService: OverlayService,
    private mixPanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this.store
      .select(UsersSelectors.profileProperty('role'))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loggedUserRole) => {
        if (loggedUserRole) {
          this.isLoggedInUserAnAdmin =
            loggedUserRole === Roles.ADMIN ||
            loggedUserRole === Roles.SUPER_ADMIN
              ? true
              : false;
        }

        if (!this.isLoggedInUserAnAdmin) {
          this.store
            .select(UsersSelectors.profileProperty('companyRuc'))
            .pipe(take(1))
            .subscribe((clientCompanyRuc) => {
              this.clientRuc = clientCompanyRuc;
            });
        }
      });
    this.tourService.initialize(this.tourSteps2, {
      route: '/invoices/create',
      isAsync: true,
      disableScrollToAnchor: true,
    });

    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;
          if (profile.role === 'client') {
            this.userId = profile._id;
          }
        }
      });

    this.tourService.end$.subscribe(() => {
      localStorage.setItem('productTourStart', 'start');
    });

    this.checkForFlagProductTour();
  }
  get stepValid(): boolean {
    return this._stepValid;
  }
  set stepValid(value: boolean) {
    if (value !== this._stepValid) {
      this._stepValid = value;
      this.resetErrorMesage(value);
    }
  }

  resetErrorMesage(value) {
    if (value) {
      this.errorMessage = '';
    }
  }

  selectedClientChanged(userId: string) {
    this.userId = userId;
    this.userService.byId(userId).subscribe((user: any) => {
      this.clientRuc = user.companyRuc;
    });
  }

  setUpload(upload: any) {
    switch (this.step) {
      case 0:
        if (
          upload.operationType &&
          !_.isEqual(this.operationType, upload.operationType)
        ) {
          this.operationType = _.cloneDeep(upload.operationType);
        }
        if (
          upload.invoiceGroups &&
          !_.isEqual(this.invoiceGroups, upload.invoiceGroups)
        ) {
          this.invoiceGroups = _.cloneDeep(upload.invoiceGroups);
       
        }
        if (
          upload.invoiceErrors &&
          !_.isEqual(this.invoiceErrors, upload.invoiceErrors)
        ) {
          this.invoiceErrors = _.cloneDeep(upload.invoiceErrors);
        }

        if (this.invoiceGroups.length || this.invoiceErrors.length) {
          this.step = 1;
        }

        if (
          this.invoiceErrors?.length > 0 &&
          this.invoiceGroups?.length === 0
        ) {
          this.sendEventPageView(
            'Error to upload invoices'
          );
        }
        this.isConfirming = upload.operationType === 'confirming';

        break;
      case 1:
        if (
          Array.isArray(upload.invoiceGroups) &&
          !_.isEqual(this.invoiceGroups, upload.invoiceGroups)
        ) {
          this.invoiceGroups = _.cloneDeep(upload.invoiceGroups);
        }

        if (
          Array.isArray(upload.invoiceErrors) &&
          !_.isEqual(this.invoiceErrors, upload.invoiceErrors)
        ) {
          this.invoiceErrors = _.cloneDeep(upload.invoiceErrors);
        }
        
        break;
      case 2:
        this.stepValid = true;
        break;
      case 3:
        this.stepValid = true;
        break;
    }
  }

  submit() {
    switch (this.step) {
      case 1:
        if (!this.stepValid) {
          this.errorMessage =
            'Completa la información de las facturas a financiar';
          return;
        }
        const mappedInvoices = [];

        this.invoiceGroups.forEach((invoiceGroup: []) => {
          const physicalInvoices = invoiceGroup.map((inv: any) => {
            const physicalInvoice: any = {
              code: inv.code,
              totalAmount: inv.amount,
              retentionAmount: inv.retentionAmount || '0.00',
              file: inv.file,
              emissionDate: inv.emissionDate,
              expirationDate: inv.dueDate,
              paymentDate: inv.paymentDate,
              totalAmountItem: inv.totalAmountItem,
              igvAmountItem: inv.igvAmountItem,
              netAmountItem: inv.netAmountItem,
              itemsDetail: inv.itemsDetail,
              isCash: inv.isCash,
              isSunatProcess: inv.isSunatProcess,
              currency: inv.currency,
              debtorRuc: inv.debtorRuc,
              debtorCompanyName: inv.debtorCompanyName,
              issuerRuc: inv.issuerRuc,
              issuerCompanyName: inv.issuerCompanyName,
              companyId: inv.companyId,
            };

            if (inv.isSunatProcess) {
              physicalInvoice.hasQuotas = inv.hasQuotas;
            }

            return physicalInvoice;
          });

          let groupedInvoices = physicalInvoices.reduce(
            (groups: any, inv: any) => {
              const key = `${inv.paymentDate}_${inv.isSunatProcess}`;
              if (!groups[key]) {
                groups[key] = [];
              }
              groups[key].push(inv);
              return groups;
            },
            {}
          );
          groupedInvoices = Object.values(groupedInvoices);

          groupedInvoices.forEach((physicalInvoices: any) => {
            const newInvoice = {
              issuer: this.userId,
              debtor: physicalInvoices[0].debtorRuc,
              supplier: physicalInvoices[0].issuerRuc,
              paymentDate: physicalInvoices[0].paymentDate,
              xmlPaymentDate: physicalInvoices[0].xmlPaymentDate,
              currency: physicalInvoices[0].currency,
              isSunatProcess: physicalInvoices[0].isSunatProcess,
              isConfirming: this.isConfirming,
              physicalInvoices,
              netAmount: physicalInvoices
                .reduce(
                  (acc: number, cur: any) =>
                    (acc +=
                      Number(cur.totalAmount) - Number(cur.retentionAmount)),
                  0
                )
                ?.toFixed(2),
              companyId: physicalInvoices[0].companyId,
              companyName: this.isConfirming
                ? physicalInvoices[0].issuerCompanyName
                : physicalInvoices[0].debtorCompanyName,
              companyRuc: this.isConfirming
                ? physicalInvoices[0].issuerRuc
                : physicalInvoices[0].debtorRuc,
            };

            mappedInvoices.push(newInvoice);
          });
        });

        this.step = 2;
        if (this.invoices?.length && this.invoices[0].bankAccount) {
          mappedInvoices.forEach((inv) => {
            inv.bankAccount = this.invoices.find(
              (invoice) => inv.currency === invoice.currency
            )?.bankAccount;
            return inv;
          });
        }

        this.invoices.forEach((invoice: any) => {
          if (invoice.supplierBankAccount) {
            const mappedInvoice = mappedInvoices.find(
              (mi: any) =>
                mi.supplier === invoice.supplier &&
                mi.currency === invoice.currency
            );
            mappedInvoice.supplierBankAccount = invoice.supplierBankAccount;
          }
        });

        this.invoices = mappedInvoices;
        this.stepValid = false;
        window.scrollTo({
          top: 0,
        });
        this.sendEventPageView('Add user bank accounts')
        break;
      case 2:
        if (!this.stepValid) {
          this.errorMessage = 'Selecciona las cuentas bancarias';
          return;
        }
        this.step = 3;
        let operationType = this.isConfirming ? 'Confirming' : 'Factoring'
        let section = this.isConfirming ? 'Complete provider info' : 'Complete debtor info'
        this.sendEventPageView(section, operationType)
        this.stepValid = false;
        window.scrollTo({
          top: 0,
        });
        this.nextButton = 'Enviar a financiar';
        break;
      case 3:
        if (!this.stepValid) {
          this.errorMessage = this.isConfirming
            ? 'Completa la información del proveedor'
            : 'Completa la información del cliente';
          return;
        }
        const createInvoiceMode = (() => {
          if (this.profile.role === 'client') {
            if (
              !this.profile.whatsappStatus ||
              this.profile.whatsappStatus == 'error'
            ) {
              return 'createInvoice';
            } else {
              return 'createInvoiceNew';
            }
          } else {
            return 'create';
          }
        })();
        this.overlayService.startConfirmation(
          { invoicesUpload: this.invoices },
          null,
          'resultOnly',
          createInvoiceMode,
          null,
          InvoicesActions.Saving,
          '/invoices',
          InvoiceSelectors.savingSuccessStateById,
          {},
          this.mixPanelTrack.bind(this)
        );
        break;
    }
    
  }
  mixPanelTrack(){
    let debtor_payer
    if (this.invoices[0].isConfirming){
      debtor_payer = 'provider count'
    }else{
      debtor_payer = 'debtor count'
    }
    const eventData = {
      create_date: new Date().toISOString(),
      operation_type: this.invoices[0].isConfirming
        ? 'confirming'
        : 'Factoring',
      groups_count: this.invoices.length,
      physical_invoices_count: this.countTotalPhysicalInvoices(this.invoices),
      [debtor_payer]: this.countCompanies(this.invoices),
      currency: this.checkCurrency(this.invoices),
    };
    this.mixPanelService.newEvent('Invoice upload',eventData)
  }

  countTotalPhysicalInvoices(invoices) {
    let totalInvoices = 0;
    invoices.forEach(inv => {
        totalInvoices += inv.physicalInvoices.length;
    });
    return totalInvoices;
  }

  countCompanies(invoices){
    let companies = [];
    for (let index = 0; index < invoices.length; index++) {
      const invoice = invoices[index];
      const company = companies.find(
        (company) => company._id === invoice.companyId
      );

      if (!company) {
        companies.push({
          name: invoice.companyName,
          ruc: invoice.companyRuc,
          _id: invoice.companyId,
          availableCurrency: [invoice.currency],
        });
      } else if (!company.availableCurrency.includes(invoice.currency)) {
        company.availableCurrency.push(invoice.currency);
      }
    }
    return companies.length
  }
  return() {
    if (this.step === 1) {
      const dialogRef = this.dialog.open(ModalConfirmationCancelComponent, {
        maxWidth: '444px',
        maxHeight: '100vh',
        height: 'auto',
        autoFocus: false,
        disableClose: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.returnToCreate();
        }
      });
    } else {
      this.nextButton = 'Continuar';
      this.step -= 1;
    }
  }

  goBack() {
    this.router.navigateByUrl('/invoices');
  }

  returnToCreate() {
    this.step = 0;
    this.isConfirming = undefined;
    this.operationType = '';
    this.invoiceGroups = [];
    this.invoiceErrors = [];
    this.errorMessage = '';
    this.invoices = [];
  }

  openIntercom() {
    (<any>window).Intercom('show');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkForFlagProductTour() {
    const localVariable = localStorage.getItem('productTourStart');
    if (
      (this.profile?.showBulkInvoiceUpdate ||
        !this.profile.hasOwnProperty('showBulkInvoiceUpdate')) &&
      this.profile.role === 'client' &&
      !localVariable
    ) {
      this.tourService.start();
    }
  }

  sendEventPageView(section, operationType?, currency?) {
    const baseUrl = environment.clientUrl;
    const eventName = 'page_view';
    const eventData: any = {
      page_url: baseUrl + '/invoices/create',
      page_title: 'New invoices',
      section_step: section,
      operationType: operationType,
      currency: currency,
    };
    this.mixPanelService.newEvent(eventName, eventData);
  }

  determineCurrency(array) {
    let results = []
    for (let subarray of array) {
      results.push(this.checkCurrency(subarray));
    }

    let uniqueCurrencies = new Set(results);
 
    let result;
    if (uniqueCurrencies.size === 1) {
      result = uniqueCurrencies.has('PEN') ? 'PEN' : 'USD';
    } else {
      result = 'PEN & USD';
    }

    return result;
  }

  checkCurrency(subArray){
    let hasPEN = subArray.some((obj) => obj.currency.toLowerCase() === 'pen');
    let hasUSD = subArray.some((obj) => obj.currency.toLowerCase() === 'usd');

    if (hasPEN && hasUSD) return 'PEN & USD';
    else if (hasPEN) return 'PEN';
    else if (hasUSD) return 'USD';
    else return 'None';

  }

  // sendEventPageView(step) {
  //   this.windowScrollService.sendNewEvent('Invoices', `New step ${step}`);
  // }

  // sendFailureEvent() {
  //   let event = {
  //     event: '[Platform][Invoice][Confirm][Error]',
  //     _inputName: '',
  //     _inputCategory: '',
  //     _inputError: this.formService.getFormValidationErrors(this.form),
  //   };
  //   event._inputName = this.isConfirming
  //     ? 'Clicked Confirmar In Confirming Error'
  //     : 'Cicked Confirmar In Factoring Error';

  //   event._inputCategory = this.isConfirming
  //     ? 'Invoice Upload / Confirming'
  //     : 'Invoice Upload / Factoring';

  //   this.gtmService.newEvent(event);
  // }

  // sendClickEvent() {
  //   let event = {
  //     event: '[Platform][Invoice][Confirm]',
  //     _inputName: '',
  //     _inputCategory: '',
  //   };
  //   event._inputName = this.isConfirming
  //     ? 'Cicked Confirmar In Confirming'
  //     : 'Cicked Confirmar In Factoring';

  //   event._inputCategory = this.isConfirming
  //     ? 'Invoice Upload / Confirming'
  //     : 'Invoice Upload / Factoring';

  //   this.gtmService.newEvent(event);
  // }

  // async trackAnalytics(result: any) {
  //   const role = await this.store
  //     .select(UsersSelectors.profileProperty('role'))
  //     .pipe(take(1))
  //     .toPromise();

  //   const isConfirming = await this.store
  //     .select(UsersSelectors.profileProperty('isConfirming'))
  //     .pipe(take(1))
  //     .toPromise();

  //   const companyName = await this.store
  //     .select(UsersSelectors.profileProperty('companyName'))
  //     .pipe(take(1))
  //     .toPromise();

  //   const email = await this.store
  //     .select(UsersSelectors.profileProperty('email'))
  //     .pipe(take(1))
  //     .toPromise();

  //   if (role === 'client') {
  //     this.store
  //       .select(InvoiceSelectors.all)
  //       .pipe(take(1))
  //       .subscribe((invoices) => {
  //         if (result) {
  //           const eventName = 'invoice_upload';

  //           const invoiceCopy = _.cloneDeep(invoices);

  //           invoiceCopy.sort(
  //             (a: any, b: any) =>
  //               new Date(b.createdAt).getTime() -
  //               new Date(a.createdAt).getTime()
  //           );

  //           const lastInvoice = invoiceCopy[0];
  //           const eventData = {
  //             create_date: new Date().toISOString(),
  //             operation_type: lastInvoice.isConfirming
  //               ? 'Confirming'
  //               : 'Factoring',
  //             currency: lastInvoice.currency.toUpperCase(),
  //             amount: lastInvoice.totalAmount,
  //             operation_id: lastInvoice._id,
  //             invoice_count: invoiceCopy.length,
  //             is_success: true,
  //             error_description: null,
  //           };
  //           this.gaService.sendEvent(eventName, eventData);
  //           this.mixpanelService.newEvent(eventName, eventData);
  //         }
  //       });

  //     this.sendEventPageView(3);
  //   }
  // }
}
