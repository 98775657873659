<h1>Nueva observación</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <app-input formControlName="description" autocomplete="off" type="text" label="Descripción"></app-input>
        <app-input formControlName="amount" autocomplete="off" type="number" label="Monto"></app-input>
        <app-input formControlName="comment" autocomplete="off" type="text" label="Comentario"></app-input>
        <div class="one-line border-b">
            <p>¿IGV?</p>
            <app-checkbox (selectionChanged)="setIGV()" [checked]="defaultIGV" color="accent" content=""></app-checkbox>
        </div>
        <app-radiolist formControlName="currency" [options]="currencyOptions"></app-radiolist>
        
    </div>

    <div class="button-container button-direction-column">
        <fs-ui-button  [action]="'submit'" (click)="onSubmit()"  [label]="'Agregar observación'" [type]="'secondary'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>