import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'formatDateTime',
})
export class FormatDateTimePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      return moment(value).format("DD/MMM, HH:mm").replace(".", "").toUpperCase();
    } else {
      return '-';
    }
  }
}