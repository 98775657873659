import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AppState } from 'src/app/app.states';
import { Store, Action } from '@ngrx/store';
import { tap, map, catchError, mergeMap, switchMap, shareReplay, concatMap, take } from 'rxjs/operators';
import { Observable, pipe, of } from 'rxjs';
import { Overlay } from '@angular/cdk/overlay';
import { TermsAndConditionsActions } from './terms-and-conditions.action-types';
import { TermsAndConditionsService } from '../services/terms-and-conditions.service';

@Injectable()
export class TermsAndConditionsEffects {
    $loadAll = createEffect(
        () => this.actions$
            .pipe(
                ofType(TermsAndConditionsActions.LoadAll),
                concatMap(action => this.termsAndConditionsService.getAll()),
                map(entities => TermsAndConditionsActions.LoadedAllSuccess({entities}))
            )
        );

    $saving = createEffect(
        () => this.actions$
            .pipe(
                ofType(TermsAndConditionsActions.Saving),
                concatMap(action => this.termsAndConditionsService.save(action.entity, action.mode)
                    .pipe(
                        map(receivedEntity => TermsAndConditionsActions.SaveSucceeded({result: receivedEntity})),
                        catchError(error => of(TermsAndConditionsActions.SaveFailed({error})))
                    )
                )
            ),
            {dispatch: true}
        );



    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private termsAndConditionsService: TermsAndConditionsService,
    ) { }
}