import { InvoiceService } from 'src/app/features/invoices/services/invoice.service';
import { Component, OnInit, Inject, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoneyValidator } from 'src/app/shared/validators/money.validator';
import { cloneDeep, clone, isEqual, eq } from 'lodash';

@Component({
  selector: 'app-edit-amounts-invoce-dialog',
  templateUrl: './edit-amounts-invoce-dialog.component.html',
  styleUrls: ['./edit-amounts-invoce-dialog.component.scss']
})
export class EditAmountsInvoceDialogComponent implements OnInit {

  form: FormGroup;
  invoice;
  netAmountError = "";
  initalValues;
  evaluationDays = null;
  showAlertDisclaimer = false;

  constructor(
    public dialogRef: MatDialogRef<EditAmountsInvoceDialogComponent>,
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.invoice = cloneDeep(this.data.invoice);

    this.form = this.formBuilder.group({
      paymentDate: [this.invoice.paymentDate, []],
      physicalInvoices: this.formBuilder.array(this.invoice.physicalInvoices.map((i) =>
        this.formBuilder.group({
          netAmount: [i.netAmount, [this.netAmountValidation(Number(i.totalAmount))]],
          code: [i.code, []]
        }))
      )
    });

    if (this.invoice.status !== 'approved') {
      this.form.valueChanges.subscribe((data) => {
        const targetInvoice = cloneDeep(this.data.invoice);

        targetInvoice.netAmount = data.physicalInvoices.reduce((acc: any, cur: any) => Number(acc) + Number(cur.netAmount), 0)?.toFixed(2);
        targetInvoice.paymentDate = data.paymentDate;

        this.evaluationDays = this.invoiceService.getEvaluation(targetInvoice);
        this.showAlertDisclaimer = Number(this.evaluationDays) < 0;
      });
    }

    this.initalValues = cloneDeep(this.form.value);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (!isEqual(this.initalValues, this.form.value) || this.data.wasEdited) {
      if (this.form.valid) {
        const paymentDate = this.form.value.paymentDate;
        const physicalInvoices = this.form.value.physicalInvoices;
        this.dialogRef.close({ paymentDate, physicalInvoices, evaluationDays: this.evaluationDays })
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  netAmountValidation(to: Number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = Number(control.value);

      if (value == 0) {
        return { netAmountZero: true };
      } if (value < 0) {
        return { netAmountLessThanZero: true };
      } else if (value > to) {
        return { netAmountLessThanTotal: true };
      } else if (isNaN(value)) {
        return { numbersOnly: true };
      }

      return null;
    };
  }

  getErrorMessage(errors: any) {
    if (errors.numbersOnly) {
      return "Debe ser un número"
    } else if (errors.netAmountLessThanTotal) {
      return "Debe ser menor al total"
    } else if (errors.netAmountLessThanZero) {
      return "No puede ser negativo"
    } else if (errors.netAmountZero) {
      return "Debe ser mayor a 0"
    }
  }

  getCushionColor(days) {
    if (!isNaN(days)) {
      if (Number(days) <= 15) {
        return "#FF0000";
      } else if (Number(days) <= 30) {
        return "#FFC400";
      } else {
        return "#12AB64";
      }
    }
  }
}
