<mat-form-field appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <input type="text" [formControl]="searchInput" placeholder="Ingresa el nombre o nro. de documento" matInput [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option.value)" [displayWith]="displayFn">
        <mat-option *ngIf="isLoading" class="is-loading">Cargando...</mat-option>
        <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
                <div class="user-search-result" *ngIf="option.role === 'investor' || option.role === 'admin'">
                    <div class="name">{{ option.names | capitalizeFirstLetters }} {{ option.familyNames | capitalizeFirstLetters  }}</div>
                    <div class="identity">
                        <span class="type">{{ option.idType | toSpanish | capitalizeFirstLetters }}</span>
                        <span class="number">{{ option.idNumber }}</span>
                    </div>
                </div>
                <div class="user-search-result" *ngIf="option.role === 'client'">
                    <div class="name">{{ option.companyName | uppercase }}</div>
                    <div class="identity">
                        <span class="type">RUC</span>
                        <span class="number">{{ option.companyRuc }}</span>
                    </div>
                </div>
                <div class="user-search-result" *ngIf="!option.role">
                    <div class="name">{{ option.companyName  || option.debtorCompanyName | uppercase }}</div>
                    <div class="identity">
                        <span class="type">RUC</span>
                        <span class="number">{{ option.companyRuc || option.debtorRuc }}</span>
                    </div>
                </div>
            </mat-option>
        </ng-container>
    </mat-autocomplete>

    <!--<mat-select>
        <mat-option value="pen">
            <div class="user-search-result">
                <div class="name">Mazen Takyeldin Fawzy Elkashef</div>
                <div class="identity">
                    <span class="type">Pasaporte</span>
                    <span class="number">993526576</span>
                </div>
            </div>
        </mat-option>
        <mat-option value="pen">
            <div class="user-search-result">
                <div class="name">Vanessa Vegas La Rossa</div>
                <div class="identity">
                    <span class="type">DNI</span>
                    <span class="number">9284723</span>
                </div>
            </div>
        </mat-option>
        <mat-option value="pen">
            <div class="user-search-result">
                <div class="name">Diego Escobal</div>
                <div class="identity">
                    <span class="type">CTE</span>
                    <span class="number">293473246</span>
                </div>
            </div>
        </mat-option>
    </mat-select>-->
</mat-form-field>