import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { Toaster } from 'ngx-toast-notifications';
import { LoadProfile } from './../../../../features/users/state/users.actions';
import { Store } from '@ngrx/store';
import { AppState } from './../../../../app.states';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from './../../../../features/users/services/users.service';
import { CountryISO, SearchCountryField, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { Validators, FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-phone-confirmation-modal',
  templateUrl: './phone-confirmation-modal.component.html',
  styleUrls: ['./phone-confirmation-modal.component.scss'],
})
export class PhoneConfirmationModalComponent implements OnInit {

  preferredCountries: CountryISO[] = [CountryISO.Peru, CountryISO.Spain];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  phoneForm: FormGroup;
  mode = 'validate';
  maxPhoneChars = 9;
  resultText = '';
  origin = '';
  originalPhoneNumber = '';
  isButtonDisabled = false;

  constructor(
    public dialogRef: MatDialogRef<PhoneConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private spinner: NgxSpinnerService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private toaster: Toaster,
    private mixpanelService: MixpanelService,
    private router: Router
  ) { }


  ngOnInit(): void {
    if (this.data.mode) {
      this.mode = this.data.mode;
    }

    this.resultText = this.mode == 'operation' ? 'Ir a mi operación' : 'Entendido'
    this.origin = this.mode == 'operation' ? 'Invoice upload' :
      this.mode == 'edit' ? 'Profile setting - edit number' : 'Profile settings - overview'

    if (this.mode == 'edit') {
      this.originalPhoneNumber = this.data?.user?.phone;
      this.isButtonDisabled = true;
      this.phoneForm = this.formBuilder.group({
        phone: [this.data?.user?.phone || '', [Validators.required]],
        whatsappStatus: [this.data?.user?.whatsappStatus === 'activated' || (!this.data?.user?.whatsappStatus || this.data?.user?.whatsappStatus == 'error')],
      });
      this.phoneForm.valueChanges.subscribe((formData: any) => {
        this.isButtonDisabled = formData?.phone?.number?.replace(/ /g, "") === this.originalPhoneNumber;
      });
    } else {
      this.phoneForm = this.formBuilder.group({
        phone: [this.data?.user?.phone || '', [Validators.required]],
      });
    }

    if (this.data?.user.phoneCode) {
      this.countryChange({ dialCode: this.data?.user?.phoneCode?.replace('+', '') });
    }

  }

  verifyNumber() {
    if (this.phoneForm.valid && !this.isButtonDisabled) {
      this.spinner.show();

      let data = {
        phone: this.phoneForm?.value.phone?.number?.replace(/ /g, ""),
        phoneCountry: this.phoneForm.value.phone.countryCode,
        phoneCode: this.phoneForm.value.phone.dialCode,
        whatsappStatus: this.mode === 'validate' || this.mode === 'operation' ? 'pending' :
          this.phoneForm.value.whatsappStatus ? 'pending' : ' '
      }

      this.userService.verifyNumber(data).subscribe(res => {
        this.spinner.hide();

        if (res.sent) {
          this.store.dispatch(LoadProfile());
          this.mode = 'result';

          const eventData: any = {
            page_url: environment.clientUrl + this.router.url,
            origin: this.origin,
            activated_before: this.data?.user?.whatsappActivatedOnce || false,
            is_success: true
          };
          this.mixpanelService.newEvent("Send whatsapp verification", eventData);

        } else if (res.errorCode) {
          const eventData: any = {
            page_url: environment.clientUrl + this.router.url,
            origin: this.origin,
            activated_before: this.data?.user?.whatsappActivatedOnce || false,
            is_success: false,
            error_description: res.errorCode === 131009 ? 'El número proporcionado no es válido o Whatsapp no está activado.' : 'Hubo un error, intentarlo más tarde',
          };
          this.mixpanelService.newEvent("Send whatsapp verification", eventData);

          this.toaster.open({
            caption: 'Error',
            text: res.errorCode === 131009 ? 'El número proporcionado no es válido o Whatsapp no está activado.' : 'Hubo un error, intentarlo más tarde',
            autoClose: false,
            position: 'top-right',
            type: 'danger',
            preventDuplicates: true
          });
        } else if (this.mode == 'edit') {
          this.dialogRef.close({ edit: true });
          this.store.dispatch(LoadProfile());
        }

      }, error => {
        this.spinner.hide();

        this.toaster.open({
          text: 'Hubo un error, intentarlo más tarde',
          caption: 'Error',
          autoClose: false,
          position: 'top-right',
          type: 'danger',
          preventDuplicates: true
        });
      });

    }
  }

  cancel() {
    this.dialogRef.close({ cancel: true });
  }

  close() {
    this.dialogRef.close({ close: true });
  }

  // phoneValidator(control: AbstractControl) {
  //   if (control.value && control.value.dialCode === '+51') {
  //     if (control.value.number.length != 9 || !String(control.value.number).startsWith('9')) {
  //       return { phoneValidator: true }
  //     }
  //   }
  //   return null;
  // }

  countryChange(event: any) {
    if (event?.dialCode == '51') {
      this.maxPhoneChars = 9;
    } else {
      this.maxPhoneChars = 15;
    }
  }

}
