import FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DebtorService } from '../../../services/debtor.service';
import moment from 'moment';
import b64toBlob from 'b64-to-blob';
import xlsx from 'xlsx';
import readXlsxFile from 'read-excel-file'
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { EvaluationsActions } from 'src/app/features/risk-pending-evaluations/states/evaluations.action-types';
import { EvaluationSelectors } from 'src/app/features/risk-pending-evaluations/states/evaluations.selector-types';
import { NewPayersConfirmationComponent } from '../../organisms/new-payers-confirmation/new-payers-confirmation.component';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-list-risk-debtor-template',
  templateUrl: './list-risk-debtor-template.component.html',
  styleUrls: ['./list-risk-debtor-template.component.scss'],
})
export class ListRiskDebtorTemplateComponent implements OnInit, OnDestroy {

  debtors = [];
  selectedDebtor;
  selectedSupplier;
  accountManagers;
  loggedInUserRole;

  filtersForm: FormGroup;
  pagination = {
    total: 0,
    limit: 10,
    skip: 0,
    filter: {
      selectedDebtor: {},
      selectedSupplier: {},
      supplier: undefined,
      debtor: undefined,
      accountManagers: undefined,
      creditLineFrom: undefined,
      creditLineTo: undefined,
    }
  }

  constructor(
    private overlayService: OverlayService,
    private service: DebtorService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private store: Store<AppState>,) { }

  ngOnInit(): void {
    this.store
      .select(UsersSelectors.profile())
      .pipe(take(1))
      .subscribe((loggedUser) => {
        if (loggedUser) {
          this.loggedInUserRole = loggedUser.role;
        }
      });

    this.service.getAccountManagers().subscribe(am => {
      this.accountManagers = am;
    });

    this.filtersForm = this.fb.group({
      supplier: undefined,
      debtor: undefined,
      accountManagers: undefined,
      creditLineFrom: undefined,
      creditLineTo: undefined,
      pagination: undefined
    });

    if (localStorage.getItem('risk-debtors-pagination')) {
      this.pagination = JSON.parse(
        localStorage.getItem('risk-debtors-pagination')
      );

      this.filtersForm.controls['supplier'].setValue(this.pagination.filter?.supplier);
      this.filtersForm.controls['debtor'].setValue(this.pagination.filter?.debtor);
      this.filtersForm.controls['creditLineFrom'].setValue(this.pagination.filter?.creditLineFrom);
      this.filtersForm.controls['creditLineTo'].setValue(this.pagination.filter?.creditLineTo);
      this.filtersForm.controls['accountManagers'].setValue(this.pagination.filter?.accountManagers);

      this.selectedDebtor = this.pagination.filter?.selectedDebtor;
      this.selectedSupplier = this.pagination.filter?.selectedSupplier;
    }

    this.refreshList();
  }
  ngOnDestroy(): void {
  }


  refreshList() {
    this.spinner.show();

    this.service.get(this.pagination.skip, this.pagination.limit, this.pagination.filter).subscribe((res: any) => {
      this.debtors = res.entities || [];
      this.pagination.total = res?.count;

      localStorage.setItem(
        'risk-debtors-pagination',
        JSON.stringify(this.pagination)
      );

      this.spinner.hide();
    });
  }

  exportExcel() {
    this.spinner.show();
    this.service.downloadExcel(this.pagination.filter).subscribe((data: any) => {
      const workBook = xlsx.utils.book_new();
      const sheet1 = xlsx.utils.json_to_sheet(data);

      xlsx.utils.book_append_sheet(workBook, sheet1, 'evaluaciones');
      const fileData = xlsx.write(workBook, { type: 'base64' });

      const contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const blob = b64toBlob(fileData, contentType);

      const date = moment().format('DD_MM_YYYY');

      FileSaver.saveAs(blob, `evaluaciones_${date}`);

      this.spinner.hide();
    });
  }

  applyFilter() {
    this.pagination = {
      total: 0,
      limit: 10,
      skip: 0,
      filter: {
        selectedDebtor: (this.filtersForm.value.debtor && this.pagination.filter?.selectedDebtor) ? this.pagination.filter?.selectedDebtor : {}
        , selectedSupplier: (this.filtersForm.value.supplier && this.pagination.filter?.selectedSupplier) ? this.pagination.filter?.selectedSupplier : {},
        ...this.filtersForm.value
      }
    }

    this.refreshList();
  }

  resetFiltersToDefaults() {
    this.pagination = {
      total: 0,
      limit: 10,
      skip: 0,
      filter: {
        selectedDebtor: {},
        selectedSupplier: {},
        supplier: undefined,
        debtor: undefined,
        accountManagers: undefined,
        creditLineFrom: undefined,
        creditLineTo: undefined,
      }
    }
    this.filtersForm.reset();
    this.refreshList();
  }

  resetFilters() {
    this.pagination = {
      total: 0,
      limit: 10,
      skip: 0,
      filter: {
        selectedDebtor: {},
        selectedSupplier: {},
        supplier: undefined,
        debtor: undefined,
        accountManagers: undefined,
        creditLineFrom: undefined,
        creditLineTo: undefined,
      }
    }
    this.filtersForm.reset();
    this.refreshList();
  }

  setSupplier(data) {
    this.pagination.filter.selectedSupplier = data ? data : {};
    localStorage.setItem(
      'risk-debtors-pagination',
      JSON.stringify(this.pagination)
    );
  }

  setDebtor(data) {
    this.pagination.filter.selectedDebtor = data ? data : {};
    localStorage.setItem(
      'risk-debtors-pagination',
      JSON.stringify(this.pagination)
    );
  }

  paginationHandler(data) {
    this.filtersForm.controls['pagination'].setValue(data.skip);
    this.pagination.skip = data.skip;
    this.pagination.limit = data.limit;
    this.refreshList();
  }

  onFileChange(ev) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      this.readXlsxFile(data, { sheet: "Riesgos" }).then((data) => {
        let parsed = this.parseExcel(data)
        this.sendPreEvaluation(parsed)
      })


    }
    reader.readAsBinaryString(file);
  }

  async readXlsxFile(file, { sheet: sheetName }) {
    return await readXlsxFile(file, { sheet: sheetName })
  }

  parseExcel(data) {
    const header = data.shift();
    // console.log(header)
    if (header[1] === 'RUC Deudor') {
      let result = data.map((row) => {
        let obj = { companyRuc: null }
        let a = row.map((col, index) => {
          obj['companyRuc'] = col?.toString()

        })
        return obj

      }).filter(d => d["companyRuc"] != undefined)
      return result
    } else {
      console.log('Error')
    }
  }

  sendPreEvaluation(evaluation) {
    // console.log(evaluation)

    let preEvaluation = {
      rucs: evaluation,
      validate: true
    }
    // console.log(preEvaluation)
    //Un commnet line 243 when BE is ready and also line 305
    this.service.requestEvaluation(preEvaluation).subscribe(evaluation => {
      if (evaluation) {
        //am cleaning the data response to send again only the valid rucs with flag validate:true
        let onlyValidPayers = evaluation.filter(item => item.validRuc)
        let completeEvaluation = {
          rucs: onlyValidPayers,
          validate: false
        }

        this.overlayService.startConfirmation(
          { ...completeEvaluation },
          {
            component: NewPayersConfirmationComponent,
            entity: evaluation,
          },
          'details',
          'create',
          null,
          EvaluationsActions.Request,
          '/',
          EvaluationSelectors.savingSuccessStateById,
          {
            onConfirm: {
              showCheck: true,
              title: '¿Estás seguro?',
            },
            onSuccess: {
              showCheck: true,
              title: 'Operación realizada',
            },
          },
        );
      }

    })
  }




}
