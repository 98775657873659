import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artwork-select-account',
  templateUrl: './artwork-select-account.component.html',
  styleUrls: ['./artwork-select-account.component.scss']
})
export class ArtworkSelectAccountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
