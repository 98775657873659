import { Component } from '@angular/core';

@Component({
  selector: 'app-risk-debtors-page',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class RiskDebtorsPageComponent {
  constructor() {}
}
