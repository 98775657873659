import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NumbersOnlyValidator } from 'src/app/shared/validators/numbers-only.validators';
import { Banks } from 'src/app/shared/enums/Banks.enum';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { bankAccounts, bankAccountByUserId } from 'src/app/features/bank-accounts/state/bank-accounts.selectors';
import { ShortenPipe } from 'src/app/shared/pipes/shorten.pipe';
import { takeUntil, tap, switchMap, startWith, filter, take } from 'rxjs/operators';
import { MoneyValidator } from 'src/app/shared/validators/money.validator';
import { SufficientBalanceValidator } from 'src/app/shared/validators/sufficient-balance.validator';
import { balanceAvailable } from '../../../state/financial-transactions.selectors';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { selectUsersByRole } from 'src/app/features/users/state/users.selectors';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { CurrencyNamePipe } from 'src/app/shared/pipes/currency-name.pipe';
import { GreaterThanZeroValidator } from 'src/app/shared/validators/greater-than-zero.validators';
import { BankAccountsService } from 'src/app/features/bank-accounts/services/bank-accounts.service';
import { SufficientBalanceValidator2 } from 'src/app/shared/validators/sufficient-balance.validator2';
import { FinancialtransactionsService } from '../../../services/financial-transactions.service';
import { UsersService } from 'src/app/features/users/services/users.service';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { FormService } from 'src/app/shared/services/form.service';


@Component({
  selector: 'app-financial-transaction-form',
  templateUrl: './financial-transaction-form.component.html',
  styleUrls: ['./financial-transaction-form.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialTransactionFormComponent implements OnInit, OnDestroy {
  @Input() mode: 'deposit' | 'withdraw' | 'donate';
  @Output() onSubmit = new EventEmitter();
  @Output() OnSelectedUserChanged = new EventEmitter();
  currencyOptions = ['pen', 'usd'];

  ngUnsubscribe: Subject<void> = new Subject<void>();
  formSubmitSubject$ = new Subject();
  bankAccounts;
  form: FormGroup;
  loggedInUserRole;
  loggedInUser;
  availableInvestors$;
  bankAccountsSubject: Observable<any>;
  noBankAccountsError;
  displayInput = false;
  displaySubReasons = false;
  reasonOptions = [
    { text: 'Solo quiero retirar mi ganancia.', value: 'Solo quiero retirar mi ganancia' },
    { text: 'Cuento con algunas facturas atrasadas.', value: 'Cuento con algunas facturas atrasadas' },

    { text: 'No he tenido muchas oportunidades para invertir.', value: 'No he tenido muchas oportunidades para invertir' },
    { text: 'Otros...', value: 'otros' }
  ]

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private shortenPipe: ShortenPipe,
    private currencyNamePipe: CurrencyNamePipe,
    private sufficientValidator: SufficientBalanceValidator,
    private bankAccountService: BankAccountsService,
    private financialService: FinancialtransactionsService,
    private userService: UsersService,
    private gtmService: GTMService,
    private formService: FormService,

  ) { }

  ngOnInit() {
    this.store.select(UsersSelectors.profile()).pipe(
      (takeUntil(this.ngUnsubscribe))
    ).subscribe(loggedUser => {
      if (loggedUser) {
        this.loggedInUser = loggedUser;
        this.loggedInUserRole = this.loggedInUser.role;
      }
    });


    if (this.loggedInUserRole === Roles.INVESTOR || this.loggedInUserRole === Roles.CLIENT) {
      this.noBankAccountsError = "No puedes realizar un depósito porque no tienes una cuenta bancaria.";
      this.setBankAccountsList();
    } else if (this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN) {
      this.noBankAccountsError = "El usuario aún no registró ninguna cuenta bancaria";
    }

    const baseFormGroup = {
      bankAccount: ['', [Validators.required]],
    }
    let depositFormGroup, withdrawFormGroup, donateFormGroup;

    if (this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN) {
      depositFormGroup = {
        ...baseFormGroup,
        voucher: ['', [Validators.required]],
        amount: ['', [Validators.required, MoneyValidator(1, 2)]],
        user: ['', [Validators.required]]
      }
      withdrawFormGroup = {
        ...baseFormGroup,
        amount: [
          '',
          [
            Validators.required,
            MoneyValidator(1, 2),
          ],
          [
            SufficientBalanceValidator2.createValidator(this.bankAccountService, this.financialService)
          ]
        ],
        user: ['', [Validators.required]],
        reason: [''],
        otherReason: [''],
      }
    } else {
      depositFormGroup = {
        ...baseFormGroup,
        voucher: ['', [Validators.required]],
        amount: ['', [Validators.required, MoneyValidator(1, 2), GreaterThanZeroValidator]],
      }
      withdrawFormGroup = {
        ...baseFormGroup,
        amount: ['', [Validators.required, MoneyValidator(1, 2), GreaterThanZeroValidator, this.sufficientValidator.isSufficient(this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN)]],
        reason: [''],
        otherReason: [''],

      }
      donateFormGroup = {
        amount: ['', [Validators.required, MoneyValidator(1, 2), GreaterThanZeroValidator, this.sufficientValidator.isSufficient(this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN)]],
        currency: ['', [Validators.required]],
      }
    }


    if (this.mode === 'deposit') {
      this.form = this.fb.group(depositFormGroup);
    } else if (this.mode === 'withdraw') {
      this.form = this.fb.group(withdrawFormGroup);
    } else if (this.mode === 'donate') {
      this.form = this.fb.group(donateFormGroup);
    }


    this.formSubmitSubject$
      .pipe(
        tap(() => {
          this.form.markAllAsTouched();
          Object.keys(this.form.controls).forEach(key => {
            this.form.controls[key].updateValueAndValidity();
          });
          let event = {
            event: '[Platform][Form][Input][Error]',
            _inputError: this.formService.getFormValidationErrors(this.form),
            _inputName: "Inversionista - confirmar - error -" + this.mode,
            _inputCategory: "Inversionista - transactions - " + this.mode,
          }
          this.gtmService.newEvent(event)
        }),
        switchMap(() =>
          this.form.statusChanges.pipe(
            startWith(this.form.status),
            filter(status => status !== 'PENDING'),
            take(1)
          )
        ),
        filter(status => status === 'VALID')
      )
      .subscribe(validationSuccessful => this.submit());

  }

  submit() {
    let currency;
    if (this.mode !== 'donate') {
      currency = this.bankAccounts.filter(account => account._id === this.form.value.bankAccount)[0].currency;
    }

    let entity;
    if (this.mode === 'donate') {
      entity = { type: this.mode, ...this.form.value };
    } else {
      entity = { type: this.mode, ...this.form.value, currency: currency };
    }

    if (this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN) {
      this.userService.getUserById(entity.user).subscribe(investor => {
        const newEntity = { ...entity, user: investor };
        this.onSubmit.emit(newEntity);
      });
    } else {
      this.onSubmit.emit(entity);
      let event = {
        event: '[Platform][Form][Input]',
        _inputName: "Inversionista - confirmar -" + this.mode,
        _inputCategory: "Inversionista - transactions - " + this.mode,
      }
      this.gtmService.newEvent(event)
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  selectedInvestorChanged(investorUserId) {
    if (investorUserId) {
      this.setBankAccountsList(investorUserId);
      this.OnSelectedUserChanged.emit(investorUserId);
    } else {
      this.OnSelectedUserChanged.emit(null);
    }
  }

  setBankAccountsList(userId?) {
    if (userId) {

      this.bankAccountService.getAllByUserId(userId).subscribe(result => {
        console.log(result)
        this.mapBankAccountsOptions(result.bankAccounts);
      })
      /*
      this.store.select(bankAccountByUserId(userId)).pipe(
        (takeUntil(this.ngUnsubscribe))
      ).subscribe((loadedBankAccounts) => {
        this.mapBankAccountsOptions(loadedBankAccounts);
      });
      */

    } else {
      this.store.select(bankAccounts).pipe(
        (takeUntil(this.ngUnsubscribe))
      ).subscribe((loadedBankAccounts) => {
        this.mapBankAccountsOptions(loadedBankAccounts);
      });
    }
  }

  mapBankAccountsOptions(loadedBankAccounts) {
    if (loadedBankAccounts) {
      this.bankAccounts = loadedBankAccounts.map(bankAccount => ({
        ...bankAccount,
        bankSummary: `
        ${bankAccount.name} 
        ${this.currencyNamePipe.transform(bankAccount.currency)}  
        ${this.shortenPipe.transform(bankAccount.number)}`
      }));
    }
  }

  bankAccountsLoaded() {
    if (this.bankAccounts) {
      if (Array.isArray(this.bankAccounts)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  userHasBankAccounts() {
    if (this.bankAccounts) {
      if (this.bankAccounts.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hideFormIfNoBankAccounts() {
    if (this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN) {
      return false;
    } else {
      return true;
    }
  }

  sendEvent(field) {
    let event;
    if (this.form.get(field).errors) {
      event = {
        event: '[Platform][Form][Input][Error]',
        _inputError: this.form.get(field).errors,
        _inputName: 'Transactions - depositar - ' + field + ' error',
        _inputCategory: "Transactions - depositar",
      }
    } else {
      event = {
        event: '[Platform][Form][Input]',
        _inputName: 'Transactions - depositar - ' + field,
        _inputCategory: 'Transactions - depositar',
      }
    }
    this.gtmService.newEvent(event)
  }

  changeReason(value) {
    if (value === 'otros') {
      this.displayInput = true;
      this.form.get('otherReason').setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.get('otherReason').updateValueAndValidity();
    } else {
      this.form.get('otherReason').setValidators([]);
      this.form.get('otherReason').updateValueAndValidity();
      this.displayInput = false;
    }
  }

  selectMainReason(value) {
    this.form.controls['reason'].setValue(value);

    if (value === 'No estoy satisfecho(a) con el servicio de Finsmart') {
      this.displaySubReasons = true;
    } else {
      this.displaySubReasons = false;
      this.form.controls['otherReason'].setValue("");
      this.form.get('otherReason').setValidators([]);
      this.form.get('otherReason').updateValueAndValidity();
      this.displayInput = false;
    }
  }

  

}
