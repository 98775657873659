<span class="error-label">{{errorMessage}}</span>
<mat-radio-group [ngClass]="{'custom-mat-invalid-style': _errorStatus === true}">
    <div [ngClass]="direction === 'row' ? 'one-line row' : 'one-line column'">
        <mat-radio-button
            *ngFor="let option of parsedOptions"
            [value]="option.value"
            [checked]="option.value === defaultOption"
            (change)="changeSelection($event.value)"
            [attr.data-value]="option.value">
            <span class="wrap-mat-radio-label">
                {{ option.text }}
            </span>
        </mat-radio-button>
    </div>
</mat-radio-group>