import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { take } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/features/authentication/state/auth-selector-types';
import { accessToken } from 'src/app/features/authentication/state/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationHeaderInterceptor {

  constructor(private store: Store<AppState>) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    if(req.url.startsWith(environment.endpoint)) {
      this.store.select(AuthSelectors.accessToken).pipe((take(1))).subscribe(token => {
        if(token){
          req = req.clone({
            setHeaders: {
              'Content-Type' : 'application/json; charset=utf-8',
              'Accept'       : 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
        }
      });  
    }

    return next.handle(req);
  }

  checkIfLoggedIn() {

  }
  getAuthToken() {

  }
}
