import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RESTService } from 'src/app/shared/services/rest.service';
import { calculateTokenValidityDuration } from 'src/app/shared/util/jwt.util';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { AuthActions } from '../state/auth.action-types';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  jwtHelper: JwtHelperService;
  sessionTimeout;

  constructor(
    private rest: RESTService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  createAuth(data): Observable<any> {
    return this.rest.post('/authentications', JSON.stringify(data));
  }

  createAccount(data): Observable<any> {
    console.log(data);
    return this.rest.post('/users', JSON.stringify(data));
  }

  resetPassword(data): Observable<any> {
    console.log(data);
    return this.rest.post('/users/password-reset', JSON.stringify(data));
  }

  getSystemTime(): Observable<any> {
    return this.rest.get('/system/time', { responseType: 'text' });
  }

  async setTimeoutForAccessToken(token) {
    //Ensures that there is a single timeout session
    this.clearSessionTimer();

    //Captures session time left from the token to work effeciently through page reloads
    const systemTime = await this.getSystemTime().toPromise();

    const validityTime = calculateTokenValidityDuration(token, systemTime);
    // console.log(
    //   `Token is valid for ${validityTime} | Token Expired: ${this.jwtHelper.isTokenExpired(
    //     token
    //   )}`
    // );
    //console.log("url is: ", this.router.url);

    //Don't set a timeout for an already exired token
    if (validityTime > -1) {
      this.sessionTimeout = setTimeout(() => {
        // console.log("current url is: ", this.router.url);
        if (
          this.router.url.toString() != '/' &&
          this.router.url.toString() != '/auth'
        ) {
          sessionStorage.setItem('redirectUrl', this.router.url.toString());
        }

        // console.log('Session validity timed out!');
        // console.log(
        //   `Token validity balance ms ${calculateTokenValidityDuration(
        //     token, systemTime
        //   )} | Token Expired: ${this.jwtHelper.isTokenExpired(token)}`
        // );
        this.store.dispatch(AuthActions.LogOut());
      }, validityTime);
    } else {
      if (
        this.router?.url &&
        this.router.url.toString() != '/' &&
        this.router.url.toString() != '/auth'
      ) {
        sessionStorage.setItem('redirectUrl', this.router.url.toString());
      }
      this.store.dispatch(AuthActions.LogOut());
    }
  }

  clearSessionTimer() {
    clearTimeout(this.sessionTimeout);
  }

  saveIncomplete(data): Observable<any> {
    return this.rest.post('/incomplete-registers', JSON.stringify(data));
  }

  saveIncompleteRole(data): Observable<any> {
    return this.rest.patch(`/incomplete-registers/${data._id}`, {
      role: data.role,
    });
  }
}
