import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-grouping-invoice',
  templateUrl: './grouping-invoice.component.html',
  styleUrls: ['./grouping-invoice.component.scss']
})
export class GroupingInvoiceComponent implements OnInit {

  title = "Agrupar facturas";
  subtitle = "Selecciona las facturas a agrupar:"
  groupList
  ungroupList = []

  constructor(
    public dialogRef: MatDialogRef<GroupingInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if(this.data.invoiceAlreadyGrouped){
      this.ungroupList = this.data.invoiceAlreadyGrouped
      .filter(inv => inv._id !== this.data.mainInvoice._id)

      this.groupList= this.data.invoicesGroup.filter(inv => {
        if(!this.data.invoiceAlreadyGrouped.some(invoice => invoice._id === inv._id)){
          return inv;
        } 
      })
    }else{
      this.groupList= this.data.invoicesGroup
    }
  
  }

  cancel(){
    this.dialogRef.close();
  }

  ungroupInvoice(invoice){
    this.groupList.push(invoice);
    const ungroup = this.ungroupList.filter(inv => inv._id != invoice._id)
    this.ungroupList = ungroup
  }

  groupInvoice(invoice){
    this.ungroupList.unshift(invoice)
    const group = this.groupList.filter(inv => inv._id !== invoice._id)
    this.groupList = group

  }

  submit(){
    this.ungroupList.unshift(this.data.mainInvoice)
    this.dialogRef.close(this.ungroupList);
  }
}
