export enum EmailType {
    'ACEPTACION_CONDICIONES' = 'Aceptacion de condiciones',
    'CONFIRMACION_PAGO_CLIENTE' = 'Confirmación de Pago al Cliente',
    'CONFORMIDAD_CAVALI' = 'Conformidad CAVALI',
    'DESEMBOLSO' = 'Desembolso',
    'DEVOLUCION_SALDO' = 'Devolución de saldo',
    "CONFIGURATION_NOTIFICATION" = "Factura configurada",
    'CONDITIONS_ACCEPTED' = 'Condiciones aceptadas',
    "INVOICE_CHANGE" = 'Pagador cambió datos',
    "OPERATION_START" = 'Conformidad SUNAT',
}