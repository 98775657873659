import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailValidator } from 'src/app/shared/validators/email.validators';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { FormService } from 'src/app/shared/services/form.service';
import { ClientService } from '../../../services/client.service';

@Component({
  selector: 'app-add-contact-dialog',
  templateUrl: './add-contact-dialog.component.html',
  styleUrls: ['./add-contact-dialog.component.scss'],
})
export class AddContactDialogComponent implements OnInit {
  form;
  isConfirming;
  emailValid;
  showDuplicateError = false;
  user;
  contactTypes = [];
  contactTypesValidation = true;
  constructor(
    public dialogRef: MatDialogRef<AddContactDialogComponent>,
    private formBuilder: FormBuilder,
    private gtmService: GTMService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formService: FormService,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    if (this.data?.data?.mode === 'edit') {
      if (this.data?.data?.contactTypes) {
        if (
          this.data?.data?.contactTypes[0] === 'all_tags' ||
          this.data?.data?.contactTypes.length > 1
        ) {
          this.contactTypes.push(
            { typeName: 'conformity' },
            { typeName: 'collection' }
          );
        } else if (
          this.data?.data?.contactTypes[0] &&
          this.data?.data?.contactTypes[0]?.typeName
        ) {
          this.contactTypes = this.data?.data?.contactTypes;
        } else {
          this.contactTypes.push({
            typeName: this.data?.data?.contactTypes[0],
          });
        }
      } else {
        this.contactTypes.push(
          { typeName: 'conformity' },
          { typeName: 'collection' }
        );
      }
    } else {
      if (this.data?.data?.contactTypes) {
        this.contactTypes = this.data?.data?.contactTypes;
      } else {
        this.contactTypes.push(
          { typeName: 'conformity' },
          { typeName: 'collection' }
        );
      }
    }

    if (this.data.hasOwnProperty('isConfirming')) {
      this.isConfirming = this.data.isConfirming;
    }

    if (this.data.hasOwnProperty('user') && this.data.user !== undefined) {
      this.user = this.data.user;
    }

    if (
      this.data?.data?.mode === 'validate' ||
      this.data?.data?.mode === 'edit'
    ) {
      this.form = this.formBuilder.group({
        name: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        jobTitle: ['', [Validators.required, Validators.maxLength(50)]],
      });
    } else {
      this.form = this.formBuilder.group({
        name: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        email: ['', [Validators.required, EmailValidator]],
        jobTitle: ['', [Validators.required, Validators.maxLength(50)]],
      });
    }
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.data.showContactTypes) {
      this.contactTypesValidation = this.contactTypesValid();
    }

    if (this.form.valid && this.contactTypesValidation) {
      if (this.data?.data?.mode === 'validate') {
        let result = {
          ...this.form.value,
          contactTypes: this.contactTypes,
          email: this.data?.data?.email,
        };
        this.dialogRef.close(result);
        this.sendSuccessEvent();
      }
      if (this.data?.data?.mode === 'edit') {
        let result = {
          ...this.form.value,
          contactTypes: this.contactTypes,
          email: this.data?.data?.email,
        };
        this.dialogRef.close(result);
        this.sendSuccessEvent();
      } else {
        let entity;
        if (this.data.debtor) {
          entity = {
            debtor: this.data.debtor,
            email: this.form.value.email,
            user: this.user,
          };
        } else {
          entity = {
            supplier: this.data.supplier,
            email: this.form.value.email,
            user: this.user,
          };
        }
        this.clientService.checkOnValidEmail(entity).subscribe(
          (contact) => {
            if (contact.isValid || contact.user === 'valid') {
              let result = {
                ...this.form.value,
                contactTypes: this.contactTypes,
              };

              if (this.user) {
                result.user = this.user;
              }
              this.dialogRef.close(result);
              this.sendSuccessEvent();
            } else {
              this.showDuplicateError = true;
            }
          },
          (err) => {
            this.showDuplicateError = true;
          }
        );
      }
    } else {
      this.sendFailureEvent();
    }
  }

  contactTypesValid() {
    if (this.contactTypes.length >= 1) {
      return true;
    }
    return false;
  }

  checkboxClicked($event, type) {
    if ($event.checked) {
      this.contactTypesValidation = true;
      this.contactTypes.push({ typeName: type });
    } else {
      this.contactTypes = this.contactTypes.filter((el) => el.typeName != type);
    }
  }

  getCheck(type) {
    if (this.contactTypes.find((el) => el.typeName === type)) {
      return true;
    } else {
      return false;
    }
  }

  sendSuccessEvent() {
    let event = {
      event: '[Platform][Form][Input]',
      _inputName: 'Clicked Guardar Contacto In New Contact',
      _inputCategory: 'Review Client / New Contact',
    };

    if (this.isConfirming !== undefined) {
      event._inputName = this.isConfirming
        ? 'Clicked Guardar Contacto In Confirming'
        : 'Clicked Guardar Contacto In Factoring';
      event._inputCategory = this.isConfirming
        ? 'Invoice Upload / Confirming / New contact'
        : 'Invoice Upload / Factoring / New contact';
    }

    this.gtmService.newEvent(event);
  }

  sendFailureEvent() {
    let event = {
      event: '[Platform][Form][Input][Error]',
      _inputName: 'Clicked Guardar Contacto In New Contact Error',
      _inputCategory: 'Review Client / New Contact',
      _inputError: this.formService.getFormValidationErrors(this.form),
    };

    if (this.isConfirming !== undefined) {
      event._inputName = this.isConfirming
        ? 'Clicked Guardar Contacto In Confirming Error'
        : 'Clicked Guardar Contacto In Factoring Error';
      event._inputCategory = this.isConfirming
        ? 'Invoice Upload / Confirming / New contact'
        : 'Invoice Upload / Factoring / New contact';
    }

    this.gtmService.newEvent(event);
  }

  cancel() {
    this.dialogRef.close();
    let event = {
      event: '[Platform][Contact][Cancel]',
      _inputName: 'Clicked Cancelar Contacto In New Contact',
      _inputCategory: 'Review Client / New Contact',
    };

    if (this.isConfirming !== undefined) {
      event._inputName = this.isConfirming
        ? 'Clicked Cancelar Contacto In Confirming'
        : 'Clicked Cancelar Contacto In Factoring';
      event._inputCategory = this.isConfirming
        ? 'Invoice Upload / Confirming / New contact'
        : 'Invoice Upload / Factoring / New contact';
    }

    this.gtmService.newEvent(event);
  }
}
