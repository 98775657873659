<div class="message-container">
    <p class="message">¿Aún no has hecho la transferencia a las cuentas de Finsmart?</p>
    <!-- <fs-ui-button (click)="showOurBankAccounts()" [label]="'Mostrar nuestras cuentas bancarias'" [type]="'outline'"></fs-ui-button> -->
    <lib-finsmart-banks class="button-outline" [data]="ourBankAccounts"></lib-finsmart-banks>
</div>


<app-page-title title="Depositar"></app-page-title>

<app-financial-transaction-form mode="deposit" (onSubmit)="submitHandler($event)"></app-financial-transaction-form>

<div class="button-container">
    <fs-ui-button (click)="goBack()" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
</div>