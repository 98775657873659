import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormService } from "src/app/shared/services/form.service";
import { ClientService } from "../../../services/client.service";

@Component({
  selector: "app-reject-contact-dialog",
  templateUrl: "./reject-contact-dialog.component.html",
  styleUrls: ["./reject-contact-dialog.component.scss"],
})
export class RejectContactDialogComponent implements OnInit {
  form;
  reasons: string[] = [
    "Ya no trabaja en la empresa",
    "Ya no realiza la función o cambió de área",
    "Otro",
  ];
  selectedReason: string;
  reasonComment: string;
  constructor(
    public dialogRef: MatDialogRef<RejectContactDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formService: FormService,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.form = this.formBuilder.group({
      // reason: ['', [Validators.required]],
      selectedReason: ["", [Validators.required]],
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      console.log("valid");
      if (this.selectedReason == "Otro")
        this.reasonComment = this.form.controls.reason.value;

      this.dialogRef.close({
        selectedReason: this.selectedReason,
        reasonComment: this.reasonComment,
      });
    } else {
      console.log("unvalid");
      // this.form.;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  radioChange($event) {
    this.selectedReason = $event.value;
    if (this.selectedReason == "Otro") {
      this.form.addControl(
        "reason",
        this.formBuilder.control("", [Validators.required])
      );
    } else {
      this.form.removeControl("reason");
    }
  }
}
