import { Component, OnInit, Input, EventEmitter, Output, ComponentFactoryResolver, ViewChild, ViewContainerRef, ComponentRef, ComponentFactory, Self } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';

@Component({
  selector: 'app-generic-overlay-host',
  templateUrl: './generic-overlay-host.component.html',
  styleUrls: ['./generic-overlay-host.component.scss']
})
export class GenericOverlayHostComponent implements OnInit {
  @Input() displayComponent?;
  @Input() payload;
  @Input() overlayRef: OverlayRef;
  @Output() onReturn = new EventEmitter();

  @ViewChild("entityDisplay", { read: ViewContainerRef, static: true }) container;
  componentRef: ComponentRef<any>;

  constructor(
    private resolver: ComponentFactoryResolver,
     private windowScrollService: WindowScrollService) { }

  ngOnInit() {
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.displayComponent);
    this.componentRef = this.container.createComponent(factory);
    
    this.componentRef.instance.payload = this.payload;

    if(this.componentRef.instance.destroySelf) {
      this.componentRef.instance.destroySelf.subscribe(() => {
        this.return();
      })
    }
  }

  return() {
    this.overlayRef.dispose();
    this.windowScrollService.closeDialogGeneric()
    
    document.getElementById("main-container").style.display = "";
    document.documentElement.style.overflowY = 'scroll';


      this.onReturn.emit(this.componentRef?.instance?.parentTab);
  }

}
