import { Component, OnInit, Input } from '@angular/core';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { TermsAndConditionsSelectors } from '../../../state/terms-and-conditions.selector-types';
import { TermsAndConditionsActions } from '../../../state/terms-and-conditions.action-types';

@Component({
  selector: 'app-edit-term-condition',
  templateUrl: './edit-term-condition.component.html',
  styleUrls: ['./edit-term-condition.component.scss']
})
export class EditTermConditionComponent implements OnInit {
  $termSelected;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private overlayService: OverlayService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.$termSelected = this.store.select(TermsAndConditionsSelectors.entityById(params.termsAndConditionsId));
    });
  }

  submitHandler(formValue) {
    this.overlayService.startConfirmation(
      formValue,
      null,
      'question',
      'edit',
      '',
      TermsAndConditionsActions.Saving,
      '/system-settings/terms-and-conditions',
      TermsAndConditionsSelectors.savingSuccessStateById
    )
  }

}
