import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
  ChangeDetectorRef,
  OnChanges,
  DoCheck,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { Observable, Subject } from 'rxjs';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { InvestInInvoiceComponent } from '../invest-in-invoice/invest-in-invoice.component';
import { filter, finalize, first, take, takeUntil, tap } from 'rxjs/operators';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { cloneDeep } from 'lodash';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-opportunities-list',
  templateUrl: './opportunities-list.component.html',
  styleUrls: ['./opportunities-list.component.scss'],
})
export class OpportunitiesListComponent
  implements OnInit, OnDestroy, AfterViewInit, DoCheck
{
  ngUnsubscribe: Subject<void> = new Subject<void>();
  invoices = [];
  opportunities = [];
  opportunitiesNotPublished;
  displayToggle = true;
  currency = 'pen';
  firtsLabel = 'PEN';
  secondLabel = 'USD';
  default = true;
  toggle = 'pen';
  tabPulished = true;
  tabNotPulished = false;
  tabOpportunitiesPEN;
  tabOpportunitiesUSD;
  selected = 0;
  @Output() openNewTabTest = new EventEmitter();
  constructor(
    private overlayService: OverlayService,
    private store: Store<AppState>,
    private windowScrollService: WindowScrollService,
    private cdRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}
  userStatus = '';
  isDisclaimerVisible = false;
  scrollVisibility = false;
  scrollVisibilityNotPublished = false;
  scrollDismissed = false;
  lastScrollPosition = 0;
  dontTriggerTabEvent = false;
  isMobile;
  loggedInUserRole;
  @ViewChild('invoicesList') invoicesList: ElementRef;

  ngOnInit() {
    this.store
      .select(UsersSelectors.profileProperty('role'))
      .pipe(take(1))
      .subscribe((role) => (this.loggedInUserRole = role));

    const currencyValue = this.route.snapshot.queryParamMap.get('currency');
    if (
      this.loggedInUserRole === 'investor' &&
      (currencyValue === 'usd' || currencyValue === 'pen')
    ) {
      this.setCurrency(currencyValue);
    }

    this.store
      .select(InvoiceSelectors.allOpportunities)
      .subscribe((data): any => {
        this.invoices = data;
        this.filterByCurrency();
      });

    this.store
      .select(UsersSelectors.profileProperty('status'))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => {
        this.userStatus = status;
      });

    this.store
      .select(UsersSelectors.profileProperty('isApprovedDismissed'))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isApprovedDismissed) => {
        this.isDisclaimerVisible = !isApprovedDismissed;
      });

    this.overlayService.getCloseEmitter().subscribe((obs) => {
      if (obs && obs === 'OpportunityDetail') {
        this.sendEventPageView(this.currency);
        this.defaultLauncher();
      }
    });

    this.getLocalStorage();
    this.getDefaultToggle();
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
          this.defaultLauncher();
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.lastScrollPosition) {
        window.scrollTo(0, this.lastScrollPosition);
      }

      this.windowScrollService.scrollY.subscribe((e) => {
        if (e >= 80) {
          this.scrollDismissed = true;
        }
        localStorage.setItem(
          'opportunities_scroll',
          document.documentElement.scrollTop.toString()
        );
      });
    }, 1000);
  }

  ngDoCheck() {
    this.setScrollVisibility();
    this.cdRef.detectChanges();
  }

  setScrollVisibility() {
    this.scrollVisibility = false;
    this.scrollVisibilityNotPublished = false;
    let body = document.body;
    let html = document.documentElement;

    const scrollSize = html.scrollHeight - html.clientHeight;

    if (scrollSize >= 50 && this.selected === 0) {
      this.scrollVisibility = true;
    }

    if (scrollSize >= 50 && this.selected === 1) {
      this.scrollVisibilityNotPublished = true;
    }
  }

  showInvest(invoice) {
    let selectedInvoiceCopy = cloneDeep(invoice);
    selectedInvoiceCopy.parentTab = this.getTabName();
    this.overlayService.showComponent(
      InvestInInvoiceComponent,
      selectedInvoiceCopy
    );

    // const dialogRef = this.dialog.open(InvestInInvoiceComponent, {
    //   height: 'auto',
    //   id: 'my-dialog',
    //   data: selectedInvoiceCopy
    // });

    // this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  setCurrency($event) {
    this.currency = $event;
    this.filterByCurrency();
    this.setLocalStorage();

    this.dontTriggerTabEvent = true;
    this.scrollDismissed = false;
    this.getTabLocalStorage();

    this.sendEventPageView(this.currency);
  }

  sendEventPageView(currency: string) {
    this.windowScrollService.sendNewEvent(
      'Opportunities',
      `Overview`,
      this.getTabName(),
      currency.toUpperCase()
    );
  }

  setLocalStorage() {
    localStorage.setItem('toggle_currency', this.currency);
  }

  getLocalStorage() {
    const toogleLocal = localStorage.getItem('toggle_currency');
    if (toogleLocal != null) {
      this.currency = toogleLocal;
      this.filterByCurrency();
    }

    toogleLocal === null ? (this.toggle = 'pen') : (this.toggle = toogleLocal);

    this.lastScrollPosition =
      Number(localStorage.getItem('opportunities_scroll')) || 0;
  }

  getTabLocalStorage() {
    this.tabOpportunitiesPEN = localStorage.getItem('tab_opportunities_pen');
    this.tabOpportunitiesUSD = localStorage.getItem('tab_opportunities_usd');
    if (this.currency === 'pen') {
      if (this.tabOpportunitiesPEN) {
        this.selected = Number(this.tabOpportunitiesPEN);
      } else {
        this.selected = 0;
      }
    } else if (this.currency === 'usd') {
      if (this.tabOpportunitiesUSD) {
        this.selected = Number(this.tabOpportunitiesUSD);
      } else {
        this.selected = 0;
      }
    }
  }

  getDefaultToggle() {
    this.toggle == 'pen' ? (this.default = false) : (this.default = true);
  }

  filterByCurrency() {
    this.opportunities = this.invoices;
    if (this.opportunities) {
      const opportunitieByCurrency = this.opportunities.filter(
        (oppor) => oppor.currency === this.currency
      );
      this.opportunities = opportunitieByCurrency;
      this.totalByCurrency();
      this.filterOnSale();
    }
  }

  totalByCurrency() {
    const opportunitieByPEN = this.invoices.filter(
      (oppor) => oppor.currency === 'pen'
    ).length;

    const opportunitieByUSD = this.invoices.filter(
      (oppor) => oppor.currency === 'usd'
    ).length;

    this.firtsLabel = '(' + opportunitieByPEN + ') PEN';
    this.secondLabel = 'USD (' + opportunitieByUSD + ')';
  }

  filterOnSale() {
    const opportunitieOnSale = this.opportunities.filter(
      (oppor) => oppor.onSale === undefined || oppor.onSale
    );

    const opportunitieOnSaleFalse = this.opportunities.filter(
      (oppor) => oppor.onSale !== undefined && !oppor.onSale
    );

    this.opportunities = opportunitieOnSale;
    this.opportunitiesNotPublished = opportunitieOnSaleFalse;
  }

  openPublished() {
    this.selected = 0;
  }

  openNotPublished() {
    this.selected = 1;
  }

  updateTabSelected($event) {
    this.selected = $event;
    let tabOption =
      this.currency === 'pen'
        ? 'tab_opportunities_pen'
        : 'tab_opportunities_usd';
    localStorage.setItem(tabOption, this.selected.toString());

    this.scrollDismissed = false;

    if (!this.dontTriggerTabEvent) {
      this.sendEventPageView(this.currency);
    } else {
      this.dontTriggerTabEvent = false;
    }
  }

  getTabName() {
    switch (this.selected) {
      case 0:
        return 'Publicadas';
      case 1:
        return 'Por publicar';
      default:
        return 'Publicadas';
    }
  }

  defaultLauncher() {
    if (this.isMobile) {
      this.windowScrollService.setDefaultLauncher(false);
    }
  }
}
