import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getDecimalSeparator } from '../util/locale.util';
import * as Big from 'big.js';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { Observable, of, pipe, timer } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
Big.DP = 2;
Big.RM = 2;


@Injectable({ providedIn: 'root' })
export class ObservableInvestmentValidator {
  constructor(private store: Store<AppState>) {}

  hasSufficientBalance(res) {
    return of(null);
  }

  public validate(InvoiceId, UserAvailableBalance, InvoiceCurrency): AsyncValidatorFn {
    return (control: AbstractControl) => {

      if(control.value) {
          //Temporary solution because aparently control is always valid even if it has error and
          //didn't pass the format criteria!
          const separator = '.';
          const pattern = new RegExp("^\\s*?\\d+(\\"+ separator+"\\d{"+ 1 + "," + 2 +"})?\\s*$");
          const isValidMonetaryValue = pattern.test(control.value);
  
          if (!isValidMonetaryValue) {
              return of(null);
          }

          return new Promise(resolve=>{
            this.store
            .select(InvoiceSelectors.opportunitiesById(InvoiceId))
            .pipe()
            .subscribe(
                (opportunity:any) => {
                 // console.log(opportunity)
                  const invoiceRemainingBalance = opportunity.availableBalanceAmount || opportunity.netAmount;
                  const investmentAmount = new Big(control.value);
                  const formattedAvailableBalanace = new Big(UserAvailableBalance);
                  //console.log("invoiceRemainingBalance",invoiceRemainingBalance)
                  const formattedInvoiceRemainingBalance = new Big(invoiceRemainingBalance);
              
                  const userHasEnoughBalance = investmentAmount.lte(formattedAvailableBalanace)
                  const invoiceHasSufficientBalance = investmentAmount.lte(formattedInvoiceRemainingBalance);
        
                  let minimumInvestmentMet;
                  let minimumInvestmentAmount;
        
                  if(InvoiceCurrency.toLowerCase() === 'pen') {
                    const minimumInvestmenPEN = 100;
                    minimumInvestmentMet = investmentAmount.gte(minimumInvestmenPEN) || investmentAmount.eq(formattedInvoiceRemainingBalance);
        
                    if(!minimumInvestmentMet) {
                      if(formattedInvoiceRemainingBalance.gt(minimumInvestmenPEN)) {
                        minimumInvestmentAmount = minimumInvestmenPEN;
                      } else if(formattedInvoiceRemainingBalance.lte(minimumInvestmenPEN)) {
                        minimumInvestmentAmount = "All"
                      } else {
                        minimumInvestmentAmount = "All"
                        console.error('Edge case');
                      }
                    } 
                    
                  } else if(InvoiceCurrency.toLowerCase() === 'usd') {
                    const minimumInvestmenUSD = 33;
                    console.log(investmentAmount.gte(minimumInvestmenUSD))
                    console.log(investmentAmount.eq(formattedInvoiceRemainingBalance))
                    minimumInvestmentMet = investmentAmount.gte(minimumInvestmenUSD) || investmentAmount.eq(formattedInvoiceRemainingBalance);
                    console.log(minimumInvestmentMet)
                    console.log(formattedInvoiceRemainingBalance.toString())
        
                    if(!minimumInvestmentMet) {
                      if(formattedInvoiceRemainingBalance.gt(minimumInvestmenUSD)) {
                        minimumInvestmentAmount = minimumInvestmenUSD;
                      } else if(formattedInvoiceRemainingBalance.lte(minimumInvestmenUSD)) {
                        minimumInvestmentAmount = "All"
                      } else {
                        minimumInvestmentAmount = "All"
                        console.error('Edge case');
                      }
                    }
        
                  }
    
                  if(!minimumInvestmentMet) {
                    let minInvestmentError = `minimumInvestmentAllowedis${minimumInvestmentAmount}`
                    resolve({ [minInvestmentError]: true});
                  }
                  if(!userHasEnoughBalance) {
                    resolve({sufficientBalance: true});
                  }
                  if (!invoiceHasSufficientBalance) {
                    resolve({ invoiceSufficientBalance: true });
                  }
    
                  resolve(null);
                })
              });

        }
    }
  }
}
