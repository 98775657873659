import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-confirmation-modal',
  templateUrl: './new-confirmation-modal.component.html',
  styleUrls: ['./new-confirmation-modal.component.scss'],
})
export class NewConfirmationModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NewConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
