<div class="intercom-success-container container">
    <!-- <span (click)="closePopUp()" class="close-button topright">&times;</span> -->

    <div class="container-grow">
        <ng-container *ngIf="data.state.savingSucceeded; else iconConfirmation">
            <div class="border-background">
                <img [src]="getImgSrc()">
            </div>
        </ng-container>
        <ng-template #iconConfirmation>
            <img [src]="getImgSrc()" [class]="getCssClass()">
        </ng-template>

        <h1 [class]="getCssClass()">{{getTitle()}}</h1>

        <div class="text" [innerHTML]="messsage" *ngIf="messsage"></div>
    </div>

    <div class="margin-vertical">
        <fs-ui-button (click)="closePopUp()" [label]="'Cerrar'"></fs-ui-button>
    </div>
</div>