<div>
    <div class="code-table" [ngClass]="withScroll ? 'scroll-two' : ''">
        <table>
            <tr>
                <th>Girador</th>
                <th>Códigos</th>
                <th>Fecha de pago</th>
                <th>Moneda</th>
                <th>Operación</th>
            </tr>
            <tr *ngFor="let code of invoices; let last = last;  let first = first">
                <td>
                    <ng-container *ngIf="code?.isConfirming && code?.supplier?.companyName?.length > 25">
                        <lib-tooltip  width="160" [content]="code?.supplier?.companyName | capitalizeFirstLetters">
                            {{ code.supplier?.companyName | uppercase | limitTo: '25'}}
                        </lib-tooltip>
                    </ng-container>
                    <ng-container *ngIf="code?.isConfirming && code?.supplier?.companyName?.length < 26">
                        {{ code.supplier?.companyName | uppercase }}
                    </ng-container>
    
                    <ng-container *ngIf="!code?.isConfirming && code.issuer?.companyName?.length > 25">
                        <lib-tooltip  width="160" [content]="code.issuer?.companyName | capitalizeFirstLetters">
                            {{ code.issuer?.companyName | uppercase | limitTo: '25'}}
                        </lib-tooltip>
                    </ng-container>
                    <ng-container *ngIf="!code?.isConfirming && code?.issuer?.companyName?.length < 26">
                        {{ code.issuer?.companyName | uppercase}}
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf="code?.physicalInvoices && code?.physicalInvoices.length > 2;">
                        <span [matTooltip]="getCodes(code)">
                            <span class="code"
                            *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                            <ng-container *ngIf="index<2">
                                {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                            </ng-container>
                            <ng-container *ngIf="isLast && index >= 2">
                                ...
                            </ng-container>
                            </span>
                        </span>
                        
                    </ng-container>
    
                    <ng-container *ngIf="code?.physicalInvoices && code?.physicalInvoices.length < 3;">
                        <span class="code"
                        *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                        <ng-container *ngIf="index<2">
                            {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                        </ng-container>
                        <ng-container *ngIf="isLast && index >= 2">
                            ...
                        </ng-container>
                        </span>
                    </ng-container>
                </td>
                <td>{{ code?.paymentDate | formatDate }}</td>
                <td>{{ code.currency | uppercase }}</td>
                <td>{{ code.isConfirming ? 'CONFIRMING' : 'FACTORING'}}</td>
            </tr>
        </table>
    </div>
</div>
