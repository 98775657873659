import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActionsSubject, Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppState } from './app.states';
import { AuthActions } from './features/authentication/state/auth.action-types';
import {
  isLoggedIn,
  showPop,
} from './features/authentication/state/auth.selectors';
import { GAService } from './shared/services/ga.service';
import { MixpanelService } from './shared/services/mixpanel.service';
import { SharedActions } from './shared/state/shared.action-types';
import {
  sideMenuVisibility,
  toggleMenuVisibility,
} from './shared/state/shared.selectors';

import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { OverlayRef } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
} from '@angular/router';
import { createPopup } from '@typeform/embed';
import Big from 'big.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from './features/users/services/users.service';
import { UsersActions } from './features/users/state/users.action-types';
import { UsersSelectors } from './features/users/state/users.selector-types';
import { ClientLegalInformationComponent } from './shared/UI/organisms/client-legal-information/client-legal-information.component';
import { InvertorResidencyDialogComponent } from './shared/UI/organisms/invertor-residency-dialog/invertor-residency-dialog.component';
import { debounce } from './shared/controls/decorators/debounce.decorator';
import { Roles } from './shared/enums/Roles.enum';
import { OverlayService } from './shared/modules/overlay/services/overlay.service';
import { GTMService } from './shared/services/gtm.service';
import { WindowScrollService } from './shared/services/window-scroll.service';

// import '@typeform/embed/build/css/widget.css'; //import widget css
// import '@typeform/embed/build/css/popup.css'; // import also popup css

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @HostListener('window:scroll', ['$event'])
  @debounce()
  onScrollEvent($event) {
    this.windowScrollService.scrollY.next(this.calculatePercentageScroll());
  }
  ngUnsubscribe: Subject<void> = new Subject<void>();
  isLoggedIn$: Observable<boolean>;
  showPopUp$: Observable<boolean>;
  actions$: Observable<any>;
  isSideMenuVisible$: Observable<boolean>;
  isMobile;
  isAdmin$: Observable<boolean>;
  isRisk$: Observable<boolean>;
  subsc;
  profile;
  showPopUp;
  private overlayRef: OverlayRef;
  clickEventsubscription;
  checkedByDefault;
  loading = false;
  resourceURI;
  pageTitle;
  toogleCurrency = 'PEN';
  displayFeeback = false;
  stepsSetupBar = [
    {
      title: 'Crear cuenta',
    },
    {
      title: 'Verificar correo',
    },
    {
      title: 'Subir factura',
      url: '/invoices/create',
    },
    {
      title: 'Firmar contrato',
    },
  ];

  stepsSetupBarInvestor = [
    {
      title: 'Crear cuenta',
    },
    {
      title: 'Verificar correo',
    },
    {
      title: 'Añadir cuenta bancaria',
      url: '/bank-accounts/create',
    },
    {
      title: 'Realizar depósito',
      url: '/financial-transactions/deposit',
    },
    {
      title: '¡Invertir!',
      url: '/opportunities',
    },
  ];
  stepsSetupBarGeneral;
  currenStepSetupBar = 2;
  titleSetupBar = '¡Termina tu registro!';
  displayCloseButton = false;
  hideByDefault = false;
  currentRoute;
  hideSetup = false;
  pageScroll = 0;
  lastScroll = 0;
  scrollY$: Observable<number>;
  defaultLauncherEvent$: Observable<boolean>;
  scrollTo;
  bodyScrollTo;
  scrollHeight;
  clientHeight;
  range_25 = false;
  range_50 = false;
  range_75 = false;
  range_100 = false;
  sectionTitle;
  toogleCurrencyInvestment = 'PEN';
  activeTab;
  pageCurrency;
  toogleCurrencyTransactions;
  userData;
  routeURL;
  previousUrl;
  constructor(
    private gtmService: GTMService,
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private router: Router,
    private spinner: NgxSpinnerService,
    private mixPanelService: MixpanelService,
    public dialog: MatDialog,
    private userService: UsersService,
    private actionsSubj: ActionsSubject,
    private overlayService: OverlayService,
    private route: ActivatedRoute,
    private gaService: GAService,
    private windowScrollService: WindowScrollService
  ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });

    this.clickEventsubscription = this.userService
      .getClickEvent()
      .subscribe(() => {
        this.showModal();
      });
  }

  navigationInterceptor(event: RouterEvent): void {
    //console.log(event)
    if (event instanceof NavigationStart) {
      this.routeURL = event.url;
      // console.log("current", this.routeURL.split('/')[1])
      this.spinner.show();
    }
    if (event instanceof NavigationEnd) {
      this.routeURL = event.url;
      this.previousUrl = this.currentRoute;
      this.currentRoute = event.url;
      this.store.dispatch(SharedActions.HideSideMenu());
      this.spinner.hide();
      this.gtmService.pageView(event.url);
      this.resourceURI = event.url;
      this.getCurrentToggle();
      this.getTitlePage();
      this.resetRange();
      this.sendEventPageView();
      this.hideOnSubpages(event.url);
      this.defaultLauncher();
      this.windowScrollService.setPreviousUrl(this.previousUrl);
    }
    if (event instanceof NavigationCancel) {
      this.routeURL = event.url;
      this.store.dispatch(SharedActions.HideSideMenu());
      this.spinner.hide();
    }
    if (event instanceof NavigationError) {
      this.routeURL = event.url;
      this.store.dispatch(SharedActions.HideSideMenu());
      this.spinner.hide();
    }
    // console.log("this.routeURL", this.routeURL)
  }

  ngOnInit() {
    // change to custom toFixed function globally
    Number.prototype.toFixed = function (digits = 2) {
      const precision = Math.pow(10, digits);
      let number = String(
        (Math.sign(Number(this)) *
          Math.round(Math.abs(Number(this)) * precision + 0.00000000001)) /
          precision
      );

      if (digits !== 0) {
        let trailingZeros = 0;
        if (!number.includes('.')) {
          number += '.';
          trailingZeros = number.length + digits;
        } else {
          trailingZeros = number.split('.')[0].length + 1 + digits;
        }

        number = number.padEnd(trailingZeros, '0');
      }

      return number;
    };

    Big.prototype.toFixed = function (digits = 2) {
      const precision = Math.pow(10, digits);
      let number = String(
        (Math.sign(Number(this)) *
          Math.round(Math.abs(Number(this)) * precision + 0.00000000001)) /
          precision
      );

      if (digits !== 0) {
        let trailingZeros = 0;
        if (!number.includes('.')) {
          number += '.';
          trailingZeros = number.length + digits;
        } else {
          trailingZeros = number.split('.')[0].length + 1 + digits;
        }

        number = number.padEnd(trailingZeros, '0');
      }

      return number;
    };

    this.mixPanelService.initialize();
    this.gaService.initialize();
    this.isAdmin$ = this.store.select(UsersSelectors.isAllowed());

    this.store.pipe(select(showPop)).subscribe((data) => {
      this.showPopUp = data;
    });

    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;
          if (this.profile.role === 'client') {
            this.setCurrentStep();
          }

          if (this.profile.role === 'investor') {
            this.setCurrentStepInvestor();
          }
          if (
            this.profile.role === 'client' &&
            !this.profile.legalRep &&
            this.profile.legalCounter < 5 &&
            this.showPopUp !== undefined &&
            this.showPopUp
          ) {
            this.showModal();
          }

          if (
            this.profile.gettingFromAPi &&
            this.profile.role === 'investor' &&
            (!this.profile.hasOwnProperty('taxResidency') ||
              this.profile.taxResidency === '') &&
            this.profile.idType != 'ruc'
          ) {
            this.showTaxRetentionModal();
          }

          this.userData = `user_id=${this.profile._id}, name=${this.profile.firstName}`;
        }
      });

    // this.store
    //   .select(UsersSelectors.profileProperty('taxResidency'))
    //   .subscribe((taxResidency) => {
    //     console.log("taxResidency",taxResidency);
    //     if (!taxResidency) {
    //       this.showTaxRetentionModal();
    //     }
    //   });

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });

    const existingSession = localStorage.getItem('access_token');
    if (existingSession) {
      this.store.dispatch(
        AuthActions.RestoreSession({
          token: existingSession,
        })
      );
    }

    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.isSideMenuVisible$ = this.store.pipe(select(sideMenuVisibility));

    this.store.pipe(select(toggleMenuVisibility)).subscribe((showToggle) => {
      this.checkedByDefault = showToggle;
    });

    this.windowScrollService.scrollY$.subscribe((scroll) => {
      if (scroll) {
        // check if it is scroll down
        this.trackScroll(scroll);
        this.lastScroll = this.scrollTo;
      }
    });

    this.windowScrollService.defaultLauncherEvent$.subscribe((state) => {
      this.displayFeeback = state;
    });

    this.getCurrentToggle();
    //console.log(this.toogleCurrency)
    //Get new page view event from subcomponents, now is signup component only
    this.windowScrollService.getNewEvent().subscribe((data) => {
      // console.log(data, this.activeTab)
      this.pageTitle = data.pageTitle;
      this.sectionTitle = data.pageSection;

      if (data.activeTab) this.activeTab = data.activeTab;
      if (data.pageCurrency) this.pageCurrency = data.pageCurrency;

      this.sendEventPageView();
    });

    this.windowScrollService.onClickFeedback().subscribe((data) => {
      this.openTypeform();
    });

    //Get new page view event from subcomponents, now is signup component only
    //   this.windowScrollService.onCloseGenericModal().subscribe(data => {
    //     if (this.pageTitle === 'Opportunities') {
    //       // this.toogleCurrency = localStorage.getItem('toggle_currency') || 'pen';
    //       this.sectionTitle = "Overview";
    //       this.pageCurrency = this.toogleCurrency || 'PEN'
    //       this.sendEventPageView()
    //     }

    //     if (this.pageTitle === 'My Investments') {
    //       this.toogleCurrencyInvestment = localStorage.getItem('toggle_currency_investment') || 'pen';
    //       if (this.toogleCurrencyInvestment != null) {
    //         this.sectionTitle = "Overview " + this.toogleCurrencyInvestment.toUpperCase();
    //       } else {
    //         this.sectionTitle = "Overview PEN";
    //       }
    //       this.sendEventPageView()
    //     }
    //   })
  }

  openTypeform() {
    const baseUrl = environment.clientUrl;
    const existingSession = localStorage.getItem('access_token');
    if (existingSession) {
      const pop = createPopup('kEqFv5UH', {
        width: 500,
        height: 500,
        hidden: {
          userid: this.profile?._id || '',
          username: this.getTypeformUserName(),
          role: this.profile?.role || '',
          pageurl: baseUrl + this.resourceURI || '',
        },
        keepSession: false,
      });

      pop.open(); // call open() on created popup
    }
    const entity = {
      feedbackAnimationClosed: true,
    };
    this.store.dispatch(
      UsersActions.SavingProfile({ entity: entity, mode: 'editOwn' })
    );
    this.store.dispatch(UsersActions.LoadProfile());
  }

  getTypeformUserName() {
    if (this.profile.role == 'client') {
      return this.profile.companyName;
    } else {
      return (
        (this.profile.names ? this.profile.names + ' ' : '') +
        (this.profile?.familyNames || '')
      );
    }
  }

  hideOnSubpages(url) {
    if (
      url !== '/invoices' &&
      url !== '/my-clients' &&
      url !== '/my-suppliers' &&
      url !== '/bank-accounts' &&
      url !== '/profile-settings' &&
      url !== '/opportunities' &&
      url !== '/my-investments' &&
      url !== '/financial-transactions'
    ) {
      this.hideSetup = true;
    } else {
      this.hideSetup = false;
    }
  }

  ngAfterViewInit() {
    this.gtmService.platformReady();
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: 'auto',
      id: 'my-dialog',
      data: data,
      disableClose: true,
    });

    return dialogRef;
  }

  openDialogHelperNotClose(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '90vw',
      maxHeight: '100vh',
      height: 'auto',
      id: 'my-dialog-residency',
      data: data,
      disableClose: true,
      autoFocus: false,
    });

    return dialogRef;
  }

  showModal() {
    if (!this.loading) {
      this.loading = true;
      let dialogRef = this.openDialogHelper(ClientLegalInformationComponent);
      this.sendEvent();
      this.connectDialogListeners(dialogRef, (result) => {
        if (result !== 'closed') {
          if (result.legalComment === '') {
            delete result.legalComment;
          }
          this.overlayService.startConfirmationModal(
            result,
            null,
            'resultOnly',
            'editOwn',
            null,
            UsersActions.SavingProfile,
            '/profile-settings',
            UsersSelectors.savingProfileSuccessState,
            {
              onSuccess: {
                showCheck: true,
                title: '¡Gracias!',
                message:
                  'Puedes revisar tu información en la sección <span class="link">Mis datos personales.</span>',
              },
            }
          );
        } else {
          this.loading = false;
        }
      });
    }
  }

  showTaxRetentionModal() {
    if (!this.loading) {
      this.loading = true;
      let dialogRef = this.openDialogHelperNotClose(
        InvertorResidencyDialogComponent
      );
      this.connectDialogListeners(dialogRef, (result) => {
        if (result !== 'closed') {
          this.overlayService.startConfirmationModal(
            result,
            null,
            'resultOnly',
            'editOwn',
            null,
            UsersActions.SavingProfile,
            '/profile-settings',
            UsersSelectors.savingProfileSuccessState,
            {
              onSuccess: {
                showCheck: true,
                title: '¡Eso ha sido todo!',
                message:
                  'Puedes revisar y modificar los datos ingresados en la sección <span class="link">"Mis datos personales"</span>',
              },
            }
          );
        } else {
          this.loading = false;
        }
      });
    }
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.profile = '';
  }

  getCurrentToggle() {
    this.toogleCurrency = localStorage.getItem('toggle_currency') || 'PEN';
    this.toogleCurrencyInvestment =
      localStorage.getItem('toggle_currency_investment') || 'PEN';
    this.toogleCurrencyTransactions =
      localStorage.getItem('toggle_transaction_currency') || 'PEN';
  }

  setCurrentStep() {
    this.stepsSetupBarGeneral = this.stepsSetupBar;
    if (this.profile.setupbarClosed === false) {
      this.hideByDefault = true;
      if (
        this.profile.role === 'client' &&
        this.profile.hasBanckAccount === false
      ) {
        this.currenStepSetupBar = 2;
        this.displayCloseButton = false;
      } else if (
        this.profile.role === 'client' &&
        this.profile.hasBanckAccount === true &&
        this.profile.hasInvoice === false
      ) {
        this.currenStepSetupBar = 3;
        this.displayCloseButton = false;
      } else if (
        this.profile.role === 'client' &&
        this.profile.hasBanckAccount === true &&
        this.profile.hasInvoice === true &&
        this.profile.hasSignContract === false
      ) {
        this.currenStepSetupBar = 4;
        this.displayCloseButton = false;
      } else if (
        this.profile.role === 'client' &&
        this.profile.hasBanckAccount === true &&
        this.profile.hasInvoice === true &&
        this.profile.hasSignContract === true
      ) {
        this.currenStepSetupBar = 5;
        this.displayCloseButton = true;
        this.titleSetupBar = '¡Pasos completados!';
        //this.sendLastSetupStep()
      }
    } else {
      this.hideByDefault = false;
    }
  }

  setCurrentStepInvestor() {
    this.stepsSetupBarGeneral = this.stepsSetupBarInvestor;
    if (this.profile.setupbarClosed === false) {
      this.hideByDefault = true;
      if (
        this.profile.role === 'investor' &&
        this.profile.hasBanckAccount === false
      ) {
        this.currenStepSetupBar = 2;
        this.displayCloseButton = false;
      } else if (
        this.profile.role === 'investor' &&
        this.profile.hasBanckAccount === true &&
        this.profile.hasDeposit === false
      ) {
        this.currenStepSetupBar = 3;
        this.displayCloseButton = false;
      } else if (
        this.profile.role === 'investor' &&
        this.profile.hasBanckAccount === true &&
        this.profile.hasDeposit === true &&
        this.profile.hasInvestment === false
      ) {
        this.currenStepSetupBar = 4;
        this.displayCloseButton = false;
      } else if (
        this.profile.role === 'investor' &&
        this.profile.hasBanckAccount === true &&
        this.profile.hasDeposit === true &&
        this.profile.hasInvestment === true
      ) {
        this.currenStepSetupBar = 5;
        this.displayCloseButton = true;
        this.titleSetupBar = '¡Pasos completados!';
      }
    } else {
      this.hideByDefault = false;
    }
  }

  sendEvent() {
    let event = {
      event: '[Platform][Modal][Legal]',
      _inputValue: this.profile._id,
    };
    this.gtmService.newEvent(event);

    this.sendEventModalView();
  }

  sendEventModalView() {
    this.pageTitle = 'General';
    this.sectionTitle = 'Account Modal';
    this.sendEventPageView();
  }

  closeSetup() {
    const entity = {
      setupbarClosed: true,
    };
    this.sendEventSetup();

    this.store.dispatch(
      UsersActions.SavingProfile({ entity: entity, mode: 'editOwn' })
    );
    this.store.dispatch(UsersActions.LoadProfile());
    this.store.dispatch(AuthActions.ShowPopUp());
  }

  sendEventSetup() {
    let event = {
      event: '[Platform][Setup][Bar]',
      _inputCategory: `General / Setup Bar`,
      _inputName: `Exit Setup Bar In Setup Bar`,
      _userId: this.profile._id,
    };
    this.gtmService.newEvent(event);
  }

  sendEventPageView() {
    if (this.resourceURI !== '/') {
      const baseUrl = environment.clientUrl;
      const eventName = 'page_view';
      const eventData: any = {
        page_url: baseUrl + this.resourceURI,
        page_title: this.pageTitle,
        section_step: this.sectionTitle,
      };
      if (this.activeTab) eventData.active_tab = this.activeTab;
      if (this.pageCurrency) eventData.page_currency = this.pageCurrency;

      if (this.profile?.role === Roles.INVESTOR) {
        this.gaService.sendEvent(eventName, eventData);
        this.mixPanelService.newEvent(eventName, eventData);
        // this.sendEventScroll();
      } else if (this.profile?.role === Roles.CLIENT) {
        this.gaService.sendEvent(eventName, eventData);
        if (
          eventData?.page_title !== 'Invoices' &&
          eventData?.page_title !== 'Profile settings'
        ) {
          this.mixPanelService.newEvent(eventName, eventData);
        }
      } else if (this.profile?.role === undefined) {
        this.mixPanelService.newEvent(eventName, eventData);
      }
    }
  }

  sendEventScroll() {
    if (this.resourceURI !== '/') {
      const baseUrl = environment.clientUrl;
      const eventName = 'page_scroll';
      const eventData: any = {
        page_url: baseUrl + this.resourceURI,
        page_title: this.pageTitle,
        section_step: this.sectionTitle,
        page_scroll: this.pageScroll,
      };

      if (this.activeTab) eventData.active_tab = this.activeTab;
      if (this.pageCurrency) eventData.page_currency = this.pageCurrency;
      if (
        this.profile?.role === Roles.CLIENT ||
        this.profile?.role === Roles.INVESTOR
      ) {
        this.gaService.sendEvent(eventName, eventData);
        this.mixPanelService.newEvent(eventName, eventData);
      }
    }
  }

  setPageTitle() {
    switch (this.resourceURI) {
      case '/my-clients':
        this.pageTitle = 'My clients';
        this.sectionTitle = 'Overview';
        this.resetTab();

        break;

      case '/invoices':
        this.pageTitle = 'Invoices';
        this.sectionTitle = 'Overview';
        this.resetTab();

        break;

      case '/my-suppliers':
        this.pageTitle = 'My suppliers';
        this.sectionTitle = 'Overview';
        this.resetTab();

        break;

      case '/bank-accounts':
        this.pageTitle = 'Bank accounts';
        this.sectionTitle = 'Overview';
        this.resetTab();

        break;

      case '/profile-settings':
        this.pageTitle = 'Profile settings';
        this.sectionTitle = 'Overview';
        this.resetTab();

        break;

      case '/opportunities':
        this.pageTitle = 'Opportunities';
        this.sectionTitle = 'Overview';
        this.pageCurrency = this.toogleCurrency.toUpperCase() || 'PEN';
        this.activeTab = 'Publicadas';
        //console.log( this.pageCurrency, this.activeTab, this.toogleCurrency )
        break;

      case '/my-investments':
        this.pageTitle = 'My Investments';
        this.sectionTitle = 'Overview ';
        this.pageCurrency =
          this.toogleCurrencyInvestment.toUpperCase() || 'PEN';
        this.activeTab = 'En curso';
        break;

      case '/financial-transactions':
        this.pageTitle = 'Financial Transactions';
        this.sectionTitle = 'Overview';
        this.pageCurrency =
          this.toogleCurrencyTransactions.toUpperCase() || 'PEN';
        this.activeTab = 'Depósitos';
        break;

      case '/financial-transactions/deposit':
        this.pageTitle = 'Financial transaction';
        this.sectionTitle = 'Deposit step 1';
        this.resetTab();

        break;

      case '/financial-transactions/withdraw':
        this.pageTitle = 'Financial transaction';
        this.sectionTitle = 'Withdraw step 1';
        this.resetTab();

        break;

      case '/financial-transactions/donate':
        this.pageTitle = 'Financial transaction';
        this.sectionTitle = 'Donate step 1';
        this.resetTab();

        break;

      case '/invoices/create':
        this.pageTitle = 'Invoice';
        this.sectionTitle = 'New step 1';
        this.resetTab();

        break;

      case '/invoices/create':
        this.pageTitle = 'Profile settings';
        this.sectionTitle = 'Password Change Step 1';
        this.resetTab();

        break;

      case '/signup':
        this.pageTitle = 'Signup';
        this.sectionTitle = 'Step 1';
        this.resetTab();

        break;

      case '/auth/forgot-password':
        this.pageTitle = 'Forgot Password';
        this.sectionTitle = 'Step 1';
        this.resetTab();

        break;

      default:
        this.pageTitle = 'General';
        this.sectionTitle = 'Broken Link';
        this.resetTab();

        break;
    }
  }

  calculatePercentageScroll() {
    this.scrollTo = document.documentElement.scrollTop;
    this.bodyScrollTo = document.body.scrollTop;
    this.scrollHeight = document.documentElement.scrollHeight;
    this.clientHeight = document.documentElement.clientHeight;
    let scroll = Math.max(
      Math.trunc(
        ((this.scrollTo + this.bodyScrollTo) /
          (this.scrollHeight - this.clientHeight)) *
          100
      )
    );
    return scroll;
  }

  resetRange() {
    this.scrollTo = 0;
    this.lastScroll = 0;
    this.pageScroll = 0;
    this.range_25 = false;
    this.range_50 = false;
    this.range_75 = false;
    this.range_100 = false;
  }

  getTitlePage() {
    let splitUrl = this.resourceURI.split('/');
    let splitUrlToken = this.resourceURI.split('?');
    if (splitUrlToken[0] === '/auth') {
      this.route.queryParams.subscribe((params) => {
        if (params.token) {
          this.pageTitle = 'Signup';
          this.sectionTitle = 'Step 5';
          this.resetTab();
        } else {
          this.pageTitle = 'Login';
          this.sectionTitle = 'Login';
          this.resetTab();
        }
      });
    } else if (splitUrlToken[0] === '/auth/forgot-password') {
      this.pageTitle = 'Forgot Password';
      this.sectionTitle = 'Step 1';
      this.resetTab();
    } else if (splitUrl.length === 2) {
      this.setPageTitle();
    } else {
      if (splitUrl[1] === 'my-clients' && splitUrl[2] === 'create') {
        this.pageTitle = 'My clients';
        this.sectionTitle = 'New step 1';
        this.resetTab();
      } else if (splitUrl[1] === 'my-clients' && splitUrl[2] !== 'create') {
        this.pageTitle = 'My clients';
        this.sectionTitle = 'Detail';
        this.resetTab();
      }
      if (splitUrl[1] === 'my-suppliers' && splitUrl[2] === 'create') {
        this.pageTitle = 'My suppliers';
        this.sectionTitle = 'New step 1';
        this.resetTab();
      } else if (splitUrl[1] === 'my-suppliers' && splitUrl[2] !== 'create') {
        this.pageTitle = 'My suppliers';
        this.sectionTitle = 'Detail';
        this.resetTab();
      }
      if (splitUrl[1] === 'invoices' && splitUrl[2] === 'create') {
        this.pageTitle = 'Invoices';
        this.sectionTitle = 'New step 1';
        this.resetTab();
      } else if (splitUrl[1] === 'invoices' && splitUrl[2] !== 'create') {
        //console.log('entra qui')
        this.pageTitle = 'Invoices';
        this.sectionTitle = 'Detail';
        this.resetTab();
      }
      if (splitUrl[1] === 'bank-accounts') {
        this.pageTitle = 'Bank Accounts';
        this.sectionTitle = 'New Step 1';
        this.resetTab();
      }
      if (
        splitUrl[1] === 'financial-transactions' &&
        splitUrl[2] === 'deposit'
      ) {
        this.pageTitle = 'Financial Transactions';
        this.sectionTitle = 'Deposit Step 1';
      } else if (
        splitUrl[1] === 'financial-transactions' &&
        splitUrl[2] === 'withdraw'
      ) {
        this.pageTitle = 'Financial Transactions';
        this.sectionTitle = 'Withdraw Step 1';
      } else if (
        splitUrl[1] === 'financial-transactions' &&
        splitUrl[2] === 'donate'
      ) {
        this.pageTitle = 'Financial Transactions';
        this.sectionTitle = 'Donate Step 1';
      }

      if (
        splitUrl[1] === 'profile-settings' &&
        splitUrl[2] === 'change-password'
      ) {
        this.pageTitle = 'Profile Settings';
        this.sectionTitle = 'Password Change Step 1';
        this.resetTab();
      }
    }
  }

  resetTab() {
    this.pageCurrency = null;
    this.activeTab = null;
  }

  trackScroll(scroll) {
    if (this.scrollTo > this.lastScroll) {
      if (scroll > 25 && scroll < 50) {
        this.pageScroll = 25;
        if (!this.range_25) {
          this.sendEventScroll();
        }
        this.range_25 = true;
        //this.sendEventPageView()
      } else if (scroll > 50 && scroll < 75) {
        this.pageScroll = 50;
        if (!this.range_50) {
          this.sendEventScroll();
        }
        this.range_50 = true;
      } else if (scroll > 75 && scroll < 100) {
        this.pageScroll = 75;
        if (!this.range_75) {
          this.sendEventScroll();
        }
        this.range_75 = true;
      } else if (scroll === 100) {
        this.pageScroll = 100;
        if (!this.range_100) {
          this.sendEventScroll();
        }
        this.range_100 = true;
      }
    }
  }
  defaultLauncher() {
    if (this.isMobile) {
      this.windowScrollService.setDefaultLauncher(false);
    }
  }
}
