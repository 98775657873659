import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-split-invoice-dialog',
  templateUrl: './split-invoice-dialog.component.html',
  styleUrls: ['./split-invoice-dialog.component.scss'],
})
export class SplitInvoiceDialogComponent implements OnInit {
  invoiceForm: FormGroup;
  totalAmount = 0.0;
  netAmount = 0.0;
  isWithInvestment = false;
  investmentAmount = 0.0;
  splitSecuence = '';
  initialSecuence;
  startSecuence;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SplitInvoiceDialogComponent>
  ) {}

  ngOnInit(): void {
    this.assignValues();
    this.createForm();
  }

  createForm() {
    this.invoiceForm = this.formBuilder.group({
      partitions: this.formBuilder.array([]),
    });
    if (this.data?.partitions) {
      this.data?.partitions.partitions.map((partition, index) => {
        let code = partition.code.split('-')[2];
        this.addPartition(index, partition.amount, code);
      });
    } else {
      this.createInitialForm();
    }
  }

  assignValues() {
    this.netAmount = this.data?.invoice?.netAmount;

    this.totalAmount = this.data?.invoice?.advanceAmount;

    // console.log(this.data?.invoice?.availableBalanceAmount);
    // console.log(this.data?.invoice?.advanceAmount);
    // debugger
    if (
      Number(this.data?.invoice?.availableBalanceAmount) <
      Number(this.data?.invoice?.advanceAmount)
    ) {
      this.isWithInvestment = true;
    } else {
      this.isWithInvestment = false;
    }

    this.investmentAmount =
      this.data?.invoice?.advanceAmount -
      this.data?.invoice?.availableBalanceAmount;

    this.splitSecuence = this.data?.invoice?.nextSplitSequence;

    this.initialSecuence =
      this.splitSecuence === ''
        ? this.data?.invoice?.physicalInvoices[0].code
        : this.data?.invoice?.physicalInvoices[0].code.split('-')[0] +
          '-' +
          this.data?.invoice?.physicalInvoices[0].code.split('-')[1];

    this.startSecuence =
      this.splitSecuence !== ''
        ? this.data?.invoice?.physicalInvoices[0].code.split('-')[2]
        : '';
  }

  createInitialForm() {
    if (this.isWithInvestment) {
      const balanceAmount = (this.totalAmount - this.investmentAmount).toFixed(
        2
      );
      if (this.startSecuence !== '') {
        this.addPartition(
          0,
          this.investmentAmount.toFixed(2),
          this.startSecuence
        );
      } else {
        this.addPartition(0, this.investmentAmount.toFixed(2));
      }
      this.addPartition(1, balanceAmount);
    } else {
      const amount = (this.totalAmount / 2).toFixed(2);
      if (this.startSecuence !== '') {
        this.addPartition(0, amount, this.startSecuence);
      } else {
        this.addPartition(0, amount);
      }
      this.addPartition(1, amount);
    }
  }

  get partitionControls() {
    return (this.invoiceForm.get('partitions') as FormArray).controls;
  }

  createPartition(): FormGroup {
    return this.formBuilder.group({
      amount: ['', [Validators.required, this.validateAmount]],
      secuence: [null],
      code: [null],
    });
  }

  newPartition(index) {
    // console.log(index);
    this.addPartition(index);
    const amountPerPartition = this.getAmountPerPartition();
    this.recalculateAmounts(amountPerPartition);
    this.updateLastPartition();
  }

  getAmountPerPartition() {
    let amountPerPartition;
    let numPartitions;
    const partitionsFormArray = this.invoiceForm.get('partitions') as FormArray;
    if (this.isWithInvestment) {
      numPartitions = partitionsFormArray.length - 1;

      amountPerPartition =
        (this.totalAmount - this.investmentAmount) / numPartitions;
    } else {
      numPartitions = partitionsFormArray.length;

      amountPerPartition = this.totalAmount / numPartitions;
    }

    return amountPerPartition.toFixed(2);
  }

  addPartition(index, amount?, secuence?) {
    let letter;
    if (secuence) {
      letter = secuence;
    } else {
      if (this.startSecuence) {
        letter = this.getLetterFromIndex(index - 1);
      } else {
        letter = this.getLetterFromIndex(index);
      }
    }

    const partition = this.formBuilder.group({
      amount: [amount || '', [Validators.required, this.validateAmount]],
      code: this.initialSecuence + '-' + letter.toLocaleLowerCase() || null,
    });

    const partitionsFormArray = this.invoiceForm.get('partitions') as FormArray;
    partitionsFormArray.push(partition);
  }

  removePartition() {
    const partitionsFormArray = this.invoiceForm.get('partitions') as FormArray;
    partitionsFormArray.removeAt(partitionsFormArray.length - 1);
    const amountPerPartition = this.getAmountPerPartition();
    this.recalculateAmounts(amountPerPartition);
    this.updateLastPartition();
  }

  recalculateAmounts(amountPerPartition) {
    const partitionsFormArray = this.invoiceForm.get('partitions') as FormArray;
    const indexValue = this.isWithInvestment ? 1 : 0;

    for (
      let index = indexValue;
      index < partitionsFormArray.controls.length - 1;
      index++
    ) {
      partitionsFormArray.controls[index].patchValue({
        amount: amountPerPartition,
      });
    }
  }

  updateLastPartition() {
    const partitionsFormArray = this.invoiceForm.get('partitions') as FormArray;
    const lastPartition = partitionsFormArray.at(
      partitionsFormArray.length - 1
    );
    const totalAmount = this.calculateLastAmount();
    lastPartition.patchValue({ amount: totalAmount });
  }

  validateAmount(control: AbstractControl): { [key: string]: boolean } | null {
    const amount = Number(control.value);
    if (amount === 0 || amount < 0) {
      return { zeroAmount: true };
    }
    return null;
  }

  calculateLastAmount() {
    const partitionsFormArray = this.invoiceForm.get('partitions') as FormArray;
    const indexValue = this.isWithInvestment ? 1 : 0;
    let totalAmount = this.isWithInvestment
      ? this.totalAmount - this.investmentAmount
      : this.totalAmount;
    for (
      let index = indexValue;
      index < partitionsFormArray.controls.length - 1;
      index++
    ) {
      const amount = partitionsFormArray.controls[index].get('amount').value;
      totalAmount -= amount;
    }

    return totalAmount.toFixed(2);
  }

  onSubmit() {
    this.invoiceForm.markAllAsTouched();
    this.invoiceForm.updateValueAndValidity();

    if (this.invoiceForm.valid) {
      this.dialogRef.close(this.invoiceForm.value);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  onInputChange(value, index?) {
    this.updateLastPartition();
  }

  getLetterFromIndex(index: number): string {
    const startSplitIndex =
      this.splitSecuence !== '' ? this.splitSecuence.charCodeAt(0) : 65; // A = 65, B = 66, C = 67, ...
    return String.fromCharCode(startSplitIndex + index).toUpperCase();
  }
}
