<!--Company Information-->
<ng-container *ngIf="supplierInformation">
    <app-client-information [debtorInformation]="supplierInformation">
    </app-client-information>
    <div class="row-flex mt-15">
        <div class="vl"></div>
        <p class="warning-message">Para modificar datos del proveedor, <a class="container-link"
                (click)="openIntercom()">escríbenos aquí.</a></p>
    </div>
</ng-container>
<!--Sumary operations-->
<ng-container *ngIf="supplierInformation">
    <div class="intercom-section-operations">
        <div class="one-line mt">
            <div>
                <h3>Resumen de operaciones</h3>
            </div>
        </div>
        <ng-container *ngIf="invoices">
            <app-invoice-operations-overview *ngIf="invoices.length > 0; else no_summary_invoices" [invoice]="invoices">
            </app-invoice-operations-overview>
            <ng-template #no_summary_invoices>
                <div class="container-disclaimer">
                    <p class="disclaimer">No tienes operaciones con este proveedor.</p>
                </div>
            </ng-template>
        </ng-container>
    </div>
</ng-container>


<!--Contacts client-->
<ng-container *ngIf="supplierInformation">
    <div class="one-line mt">
        <div>
            <h3>Contactos del proveedor</h3>
        </div>
        <div>
            <a class="add-contact" (click)="addContact(supplierInformation.companyRuc)"> <img
                    src="../../../../../../assets/icons/plus-black.svg" /></a>
        </div>
    </div>
    <ng-container *ngIf="contactsDebtor.length > 0; else no_contacts_client">
        <div class="contacts-section">
            <ng-container *ngIf="contactsDebtor.length > 0; else no_contacts_client">
                <ng-container *ngFor="let contact of contactsDebtor">
                    <lib-contact-item [name]="contact.name" [jobTitle]="contact.jobTitle" [email]="contact.email"
                        [phone]="contact.phone" [owner]="contact.owner">
                    </lib-contact-item>
                </ng-container>
            </ng-container>
        </div>
        <div class="row-flex mt">
            <div class="vl"></div>
            <p class="warning-message">Para editar o eliminar contactos, <a class="container-link"
                    (click)="openIntercom()">escríbenos aquí.</a></p>
        </div>
    </ng-container>
    <ng-template #no_contacts_client>
        <div class="container-disclaimer intercom-container-empty-contacts">
            <p class="disclaimer">Este proveedor no tiene contactos.</p>
            <div class="container-link icon-text-align" *ngIf="supplierInformation">
                <a class="cp" (click)="addContact(supplierInformation.companyRuc)">Nuevo
                    contacto </a>
                <mat-icon>add</mat-icon>
            </div>
        </div>
    </ng-template>
</ng-container>

<!--Banks client-->
<ng-container *ngIf="supplierInformation">
    <div class="one-line mt">
        <div>
            <h3>Cuentas bancarias del proveedor</h3>
        </div>
        <div>
            <a class="add-contact" (click)="addBankAccount(supplierInformation.companyRuc)"> <img
                    src="../../../../../../assets/icons/plus-black.svg" /></a>
        </div>
    </div>
    <ng-container *ngIf="bankAccountSupplier.length > 0 else no_banks_suppliers">
        <ng-container *ngFor="let bankAccount of bankAccountSupplier">
            <div class="bank-item">
                <app-bank-account-item [noControls]="true" [attr.data-bank-id]="bankAccount._id" [entity]="bankAccount">
                </app-bank-account-item>
            </div>
           
        </ng-container>
        <div class="row-flex mt-15">
            <div class="vl"></div>
            <p class="warning-message">Para editar o eliminar cuentas, <a class="container-link"
                    (click)="openIntercom()">escríbenos aquí.</a></p>
        </div>
    </ng-container>

    <ng-template #no_banks_suppliers>
        <div class="container-disclaimer intercom-container-empty-contacts">
            <p class="disclaimer">Este proveedor no tiene cuentas bancarias.</p>
            <div class="container-link icon-text-align" *ngIf="supplierInformation">
                <a class="cp" (click)="addBankAccount(supplierInformation.companyRuc)">Agregar cuenta </a>
                <mat-icon>add</mat-icon>
            </div>
        </div>
    </ng-template>
</ng-container>

<!--Collections dates-->
<ng-container *ngIf="supplierInformation">
    <ng-container *ngIf="invoices | filterIn:'status':actualInvoicesStatus as invoicesToBeCollected">
        <ng-container *ngIf="invoiceService.calculateCollectedDateForClient(invoicesToBeCollected) as invoicesFinal">
            <div *ngIf="invoicesFinal.length > 0" class="one-line mt">
                <div>
                    <h3>Fechas próximas de pago</h3>
                </div>
            </div>
            <app-collection-client-dates *ngIf="invoicesFinal.length > 0"
                [collections]="invoicesFinal | sort:'toBeCollectedInClient':'smallest'">
            </app-collection-client-dates>
        </ng-container>
    </ng-container>
</ng-container>


<!--Invoices by debtors-->
<ng-container *ngIf="supplierInformation">
    <div class="intercom-section-invoices">
        <div class="one-line mt-15">
            <div class="intercom-title-invoice">
                <h3>Facturas</h3>
            </div>
            <div>
                <a class="add-invoice" routerLink="/invoices/create"> <img
                        src="../../../../../../assets/icons/plus-black.svg" /></a>
            </div>
        </div>
        <ng-container *ngIf="invoices">
            <ng-container *ngIf="invoices.length > 0; else no_invoices_client">
                <div class="list-invoice">
                    <app-invoice-by-debtor-item *ngFor="let invoice of invoicesPaginated"
                        [routerLink]="['/invoices', invoice._id]" [entity]="invoice">
                    </app-invoice-by-debtor-item>

                    <app-paginator *ngIf="invoicesPaginated?.length > 0" [totalCount]="pagination.total" [pageSize]="pagination.limit" [skip]="pagination.skip"
                        (pagination)="paginationHandler($event)">
                    </app-paginator>
                </div>

            </ng-container>

            <ng-template #no_invoices_client>
                <p class="disclaimer">¡Aún no has subido ninguna factura!</p>
            </ng-template>
        </ng-container>
    </div>
</ng-container>

<div *ngIf="supplierInformation" class="button-group">
    <div class="form-actions button-container button-direction-column">
        <fs-ui-button routerLink="/my-suppliers" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</div>