import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { EmailValidator } from '../../../../../../app/shared/validators/email.validators';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { GAService } from 'src/app/shared/services/ga.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  showForm = true;
  success; // savingSucceeded lastErrorMessage
  resultConfig; //onSuccess.title onFailure.title

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private gtmService: GTMService,
    private mixPanelService: MixpanelService,
    private gaService: GAService,


  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, EmailValidator]]
    });
  }

  submit() {
    this.spinner.show();
    
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if(this.form.valid) {
      this.authService.resetPassword({
        email: this.form.value.email,
        clientUrl: `${environment.clientUrl}/${environment.passwordResetUri}`
      })
      .subscribe(
        res => {
          console.log(res);
          this.success = {
            savingSucceeded: true,
          }
          this.resultConfig = {
            onSuccess: {
              title: 'Si encontramos una cuenta activa asociada a este correo electrónico, te enviaremos un correo para restablecer su contraseña.'
            }
          }

          this.showForm =false;
          this.spinner.hide();
          let event = {
            event: '[Platform][Form][Input]',
            _inputName: "Forgot password",
            _inputCategory: "login-forgot-password",
          }
          this.gtmService.newEvent(event)
          this.sendPageViewEvent()
        },
        err => {
          this.success = {
            savingSucceeded: false,
            lastErrorMessage: err.error
          }

          this.showForm =false;
          this.spinner.hide();
        });
       
    } else {
      this.spinner.hide();
      let event = {
         event: '[Platform][Form][Input][Error]',
        _inputError: "formato incorrecto / email requerido",
        _inputName: "Forgot password restablecer error",
        _inputCategory: "login-forgot-password",
      }
      this.gtmService.newEvent(event)
    }
  }

  sendPageViewEvent(){
    const baseUrl = environment.clientUrl;
    const eventName = 'page_view';
    const eventData = {
      page_url: baseUrl + '/auth/forgot-password',
      page_title: 'Forgot Password',
      section_step: 'Step 2',
      page_scroll : 0
    };
    console.log(eventName, eventData)
    if(Roles.CLIENT || Roles.INVESTOR){
      this.gaService.sendEvent(eventName, eventData);
      this.mixPanelService.newEvent(eventName, eventData)
    }
  }

  backToAuth() {
    if(this.success.savingSucceeded) {
      this.router.navigateByUrl('/');
    } else {
      this.showForm = true;
    }
  }

}
