import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-operation-invoice',
  templateUrl: './operation-invoice.component.html',
  styleUrls: ['./operation-invoice.component.scss'],
})
export class OperationInvoiceComponent implements OnInit {
  @Input() isConfirming = false;
  @Input() mode: 'mobile' | 'desktop' = 'desktop';
  constructor() {}

  ngOnInit(): void {}
}
