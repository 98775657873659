import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year;
  constructor() { }

  ngOnInit(): void {
  }

  getYearTimeZone(){
    let offset = new Date().getTimezoneOffset();
    return this.getYearDateOnly(offset)
  }

  getYearDateOnly = (gmtOffset?) => {
      const serverTodaysDate = new Date();
      const todaysUTCDate = serverTodaysDate.getTime() + (serverTodaysDate.getTimezoneOffset() * 60000);
      const timeZoneOffset = gmtOffset || -5; //defaults to peru GMT offset

      const todaysDate = new Date(todaysUTCDate + (3600000*timeZoneOffset));
      const year = todaysDate.getFullYear();

      return year;
  }

}
