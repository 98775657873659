import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      //console.log(value)
      if (/^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(value)) {
        return formatDateOnlyString(value);
      } else {
        return formatDateTime(value);
      }
    } else {
      //console.log("entra")

      return '---';
    }
  }
}

function formatDateTime(date) {
  date = new Date(date);

  const day = date.getDate().toString().length === 2? date.getDate() : `0${date.getDate()}`;
  const month = getMonthName(date.getMonth());
  const year = date.getFullYear();

  //console.log( `${day}/${month}/${year}`);

  return `${day}/${month}/${year}`;
}
function formatDateOnlyString(date) {
  const splittedDate = date.split('-');
  const year = splittedDate[0];
  const month = getMonthName(parseInt(splittedDate[1]) - 1);
  const day = splittedDate[2];

  return `${day}/${month}/${year}`;
}

function getMonthName(monthId) {
  switch (monthId) {
    case 0:
      return 'ENE';
    case 1:
      return 'FEB';
    case 2:
      return 'MAR';
    case 3:
      return 'ABR';
    case 4:
      return 'MAY';
    case 5:
      return 'JUN';
    case 6:
      return 'JUL';
    case 7:
      return 'AGO';
    case 8:
      return 'SET';
    case 9:
      return 'OCT';
    case 10:
      return 'NOV';
    case 11:
      return 'DIC';
  }
}
