import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { RESTService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private rest: RESTService) {}

  getChannels(){
   const channels = [
     { label: 'Me contactó un ejecutivo comercial', value: 'sales_team' },
      { label: 'Por amigos o familiares', value: 'friends_relatives' },
      { label: 'Por Facebook', value: 'facebook' },
      { label: 'Por Google', value: 'google' },
      { label: 'Por Linkedin', value: 'linkedin' },
      { label: 'Por vídeos de Youtube', value: 'youtube' },
      { label: 'Por Instagram', value: 'instagram' },
      { label: 'Por contenidos de influenciadores', value: 'influencers' },
      { label: 'Por los medios de comunicación', value: 'media' },
    ];

    return channels;
  }

  getInvestorSegments(){
    const segments = [
      { label: 'Menos de S/ 5 000', value: 'menos de S/ 5 000' },
       { label: 'Entre S/ 5 000 a S/ 30 000', value: 'entre S/ 5 000 a S/ 30 000' },
       { label: 'Entre S/ 30 000 a S/ 100 000', value: 'entre S/ 30 000 a S/ 100 000' },
       { label: 'Más de S/ 100 000', value: 'mas de S/ 100 000' },
     ];
 
     return segments;
  }
  getFormValidationErrors(form: FormGroup) {
    let result = '';
    Object.keys(form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach((keyError) => {
          let newString = key + ':' + keyError + '/ ';
          result = newString + result;
          // result.push({
          //   'control': key,
          //   'error': keyError,
          //   'value': controlErrors[keyError]
          // });
        });
      }
    });

    return result;
  }

  getAllCountries() {
    return this.rest.get(`/countries`);
  }
}
