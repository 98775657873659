import { Injectable } from '@angular/core';
import { RESTService } from 'src/app/shared/services/rest.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {
  readonly featureBaseURI = "/terms-and-conditions";

  constructor(private rest: RESTService) { }

  getAll(): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}`);
  }
  getRegistrationTermsAndConditions(): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}?type=user account`);
  }
  getRegistrationTermFileById(termsId): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}/${termsId}?type=user account`);
  }
  getAcceptConfigurationTermsAndConditions(): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}?type=invoice configuration`);
  }
  getAcceptConfigurationTermsAndConditionsConfirming(): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}?type=invoice configuration confirming`);
  }
  getInvestmentTermsAndConditions(): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}?type=investment`);
  }
  getAcceptConfigurationTermFileById(termsId): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}/${termsId}?type=invoice configuration`);
  }
  getAcceptConfigurationConfirmingTermFileById(termsId): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}/${termsId}?type=invoice configuration confirming`);
  }
  getAcceptInvestmentTermFileById(termsId): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}/${termsId}?type=investment`);
  }
  getFileByTermsID(termsId): Observable<any> {
    return this.rest.get(`${this.featureBaseURI}/${termsId}`);
  }

  create(entity): Observable<any> {
    return this.rest.post(`${this.featureBaseURI}`, entity);
  }
  update(entity): Observable<any> {
    return this.rest.patch(`${this.featureBaseURI}/${entity._id}`, entity);
  }
  delete(terms): Observable<any> {
    return this.rest.delete(`${this.featureBaseURI}/${terms._id}`);
  }



  save(entity, mode): Observable<any> {
    switch(mode) {
      case 'create':
          return this.create(entity);
      case 'edit':
        return this.update(entity);
      case 'delete':
        return this.delete(entity);
      }
  }

}
