import { createAction, props } from '@ngrx/store';

export const LoadAll = createAction(
    '[Terms And Conditions] Load All',
);
export const LoadedAllSuccess = createAction(
    '[Terms And Conditions] Loaded All Success',
    props<{entities: any}>()
);
export const Reset = createAction(
    '[Terms And Conditions] Reset'
);
export const Saving = createAction(
    '[Terms And Conditions] Saving',
    props<{entity: any, mode: 'edit' | 'create', 'editOwn'}>()
);
export const SaveSucceeded = createAction(
    '[Terms And Conditions] Save Succeeded',
    props<{result: any}>()
);
export const SaveFailed = createAction(
    '[Terms And Conditions] Save Failed',
    props<{error: any}>()
);
