import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class WindowScrollService {
  private newPage = new Subject<any>();
  private closeDialog = new Subject<any>();
  private modalFeedback = new Subject<any>();

  scrollY = new BehaviorSubject(0);
  defaultLauncherEvent = new BehaviorSubject(false);
  scrollY$ = this.scrollY.asObservable();
  modalFeedback$ = this.modalFeedback.asObservable();
  defaultLauncherEvent$ = this.defaultLauncherEvent.asObservable();
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  constructor() {}

  updateScrollY(value: number): void {
    this.scrollY.next(value);
  }

  sendNewEvent(pageTitle, pageSection, activeTab?, pageCurrency?) {
    this.newPage.next({ pageTitle, pageSection, activeTab, pageCurrency });
  }

  closeDialogGeneric() {
    this.closeDialog.next();
  }

  openFeedbackModal() {
    this.modalFeedback.next();
  }

  getNewEvent(): Observable<any> {
    return this.newPage.asObservable();
  }

  onCloseGenericModal(): Observable<any> {
    return this.closeDialog.asObservable();
  }

  onClickFeedback(): Observable<any> {
    return this.modalFeedback.asObservable();
  }

  setDefaultLauncher(state) {
    if ((<any>window).Intercom)
      (<any>window).Intercom('update', {
        hide_default_launcher: state,
      });
    this.defaultLauncherEvent.next(state);
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
}
