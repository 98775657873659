import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NumbersOnlyValidator } from 'src/app/shared/validators/numbers-only.validators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { take } from 'rxjs/operators';
import { Banks } from 'src/app/shared/enums/Banks.enum';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { CCIExactLengthValidator } from 'src/app/shared/validators/cci-xact-length.validator';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-bank-account-form',
  templateUrl: './bank-account-form.organism.html',
  styleUrls: ['./bank-account-form.organism.scss'],
})
export class BankAccountFormOrganism implements OnInit {
  @Input() mode: 'edit' | 'create';
  @Input() selectedBankAccount?;
  @Input() availableCurrency = ['pen', 'usd'];
  @Input() isButtonFullWidth = false;
  @Output() onSubmit = new EventEmitter();
  @Output() errorSubmit = new EventEmitter();
  currencyOptions = ['pen', 'usd'];
  bankOptions = Object.keys(Banks);
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private gtmService: GTMService,
    private formService: FormService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      number: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(25),
          NumbersOnlyValidator,
        ],
      ],
      cci: [
        '',
        [
          Validators.required,
          CCIExactLengthValidator(20),
          NumbersOnlyValidator,
        ],
      ],
      currency: ['', [Validators.required]],
    });

    if (!this.availableCurrency.includes('pen')) {
      this.form.controls.currency.setValue('usd');
    } else if (!this.availableCurrency.includes('usd')) {
      this.form.controls.currency.setValue('pen');
    }
  }

  calculateSubmitLabel() {
    if (this.mode === 'create') {
      return 'Agregar Cuenta';
    } else if (this.mode === 'edit') {
      return 'Modificar Cuenta';
    }
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.onSubmit.emit({ ...this.selectedBankAccount, ...this.form.value });
    } else {
      this.errorSubmit.emit(this.form);
    }
  }
}
