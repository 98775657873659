import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvoiceService } from '../../../services/invoice.service';

@Component({
  selector: 'app-forwardannouncement',
  templateUrl: './forwardannouncement.component.html',
  styleUrls: ['./forwardannouncement.component.scss']
})
export class ForwardannouncementComponent implements OnInit {

  invoices
  checkedAll = false
  invoicesSelected
  codes
  linkedInvoices
  constructor(
    public dialogRef: MatDialogRef<ForwardannouncementComponent>,
    private formBuilder: FormBuilder,
    private service: InvoiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    //console.log(this.data)
    let announcementId = this.data?.announcementDetail?._id
      let mainInvoiceId = this.data?.announcementDetail?.mainInvoiceId?._id
      let entity = {
        announcementId : announcementId,
        _id : mainInvoiceId
      }
    
    this.service.getAllAnnouncementToForward(entity).subscribe(data => {
     // console.log(data)
      if(data){
        let newInvoices = data?.newInvoices.map(inv => ({...inv , selected : false}))
       // console.log(newInvoices)
        this.invoices = newInvoices
        this.linkedInvoices = data?.linkedInvoices
        this.getAllInvoices()
      }
    })

  }

  getAllInvoices(){
    if(this.data?.announcementDetail?.invoices){
      let checkInvoices = this.data?.announcementDetail?.invoices
      //console.log(this.data)
      this.invoices.map((e,i)=>{
        let temp = checkInvoices.find(element=> element._id === e._id)
        if(temp){
          e.selected = temp.selected;
        }
        return e;
      })
      this.linkedInvoices = this.data?.announcementDetail?.linkedInvoices
      this.invoiceSelected()
    }
  }

  getCodes(invoice){
    return invoice.physicalInvoices.map(i => i.code).join("\n");
  }

  onSubmit() {
    let saveInvoices = this.invoices.filter(invoice => invoice.selected)
    let saveInvoicesFiltered = this.invoices.filter(invoice => invoice.selected).map(inv => inv._id)
    if(this.invoicesSelected?.length > 0){
      let saveInvoicesIDs = {
        announcementId : this.data?.announcementDetail?._id,
        invoiceIds: saveInvoicesFiltered.toString(),
        invoices: saveInvoices,
        announcementDetail: this.data?.announcementDetail,
        linkedInvoices: this.linkedInvoices
      }
      this.dialogRef.close(saveInvoicesIDs);
    }
  }

  cancel() {
    this.dialogRef.close({ event: "Cancel" });
  }

  setSelection(invoice){
    this.checkedAll = false;
    let invoicesObjs = this.invoices, objIndex;
    objIndex = invoicesObjs.findIndex((ob) => ob._id == invoice._id);
    invoicesObjs[objIndex].selected = !invoicesObjs[objIndex].selected;
    this.invoices = invoicesObjs;
    this.checkIfAllselected()
    this.invoiceSelected()
  }

  selectAll(){
    this.checkedAll = !this.checkedAll 
    this.invoices.map(invoice => invoice.selected = this.checkedAll)
    this.invoiceSelected()
  }

  invoiceSelected(){
     this.invoicesSelected = this.invoices.filter(invoice => invoice.selected)
  }


  someComplete(): boolean {
    if (this.invoices == null) {
      return false;
    }
    return this.invoices.filter(inv => inv.selected).length > 0 && !this.checkedAll;
  }

  checkIfAllselected(){
    if(this.invoices.filter(inv => inv.selected).length === this.invoices?.length){
      this.checkedAll = true;
    }
  }
}
