import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-forward-announcement-confirmation',
  templateUrl: './forward-announcement-confirmation.component.html',
  styleUrls: ['./forward-announcement-confirmation.component.scss']
})
export class ForwardAnnouncementConfirmationComponent implements OnInit {

  @Input() entity;
  constructor() { 
  }

  ngOnInit(): void {
  }

}
