<app-page-title 
[title]="pageTitle"
[description]="pageDescription">
</app-page-title>
<app-bank-account-form 
  mode="create"
  (onSubmit)="submitHandler($event)"
  (errorSubmit)="sendEvent($event)">
</app-bank-account-form>

<div class="button-container">
  <fs-ui-button routerLink="/bank-accounts" class="back-step-one" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
</div>
