import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  constructor() { }

  newEvent(eventName: string, eventID?: string) {
    if ((window as any)?.hj) {
      if (eventID) {
        (window as any)?.fbq('track', eventName, {}, { eventID });
      } else {
        (window as any)?.fbq('track', eventName);
      }
    }
  }
}