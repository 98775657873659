import { Component, OnInit, Input } from '@angular/core';
import { USER_ACTION } from '../../../enums/USER_ACTION.enum'
@Component({
  selector: 'app-user-action',
  templateUrl: './user-action.component.html',
  styleUrls: ['./user-action.component.scss']
})
export class UserActionComponent implements OnInit {
  @Input() name;
  ACTIONS= USER_ACTION;

  constructor() { }

  ngOnInit(): void {
  }

}
