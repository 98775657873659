<app-page-title title="Mis proveedores" description="Revisa la información y operaciones de Confirming con tus proveedores.">
</app-page-title>

<ng-container *ngIf="isConfirming; else no_confirming_activated">

    <div class="button-container button-direction-column">
        <fs-ui-button class="intercom-button-add-client" routerLink="create" [label]="'Nuevo proveedor'"></fs-ui-button>
    </div>
              

    <app-client-item *ngFor="let client of clients; let i = index" [routerLink]="client._id" [myClient]="client"
        class="item intercom-item-{{i}}">
    </app-client-item>
</ng-container>

<ng-template #no_confirming_activated>
    <ng-container>
        <div class="row">
           <svg xmlns="http://www.w3.org/2000/svg" width="100" height="66" viewBox="0 0 100 66"><defs><style>.a{fill:#6c727c;}</style></defs><path class="a" d="M101.847,53.587,87.173,30.677a2.705,2.705,0,0,0-2.323-1.251H69.665v-8.7A2.725,2.725,0,0,0,66.946,18H5.02A2.725,2.725,0,0,0,2.3,20.729V73.085a2.725,2.725,0,0,0,2.72,2.729H15.671a10.876,10.876,0,0,0,21.076,0H71.082a10.876,10.876,0,0,0,21.077,0H99.58a2.725,2.725,0,0,0,2.72-2.729V55.065A3.458,3.458,0,0,0,101.847,53.587ZM31.592,73.426a5.386,5.386,0,0,1-5.043,5.059,5.29,5.29,0,0,1-4.193-1.592,5.45,5.45,0,0,1-1.586-4.207,5.386,5.386,0,0,1,5.042-5.059h.34a5.485,5.485,0,0,1,3.853,1.592A5.4,5.4,0,0,1,31.592,73.426ZM64.226,30.109H14.311a2.725,2.725,0,0,0-2.72,2.729,2.762,2.762,0,0,0,2.72,2.729H64.226V56.031H39.241a2.729,2.729,0,0,0,0,5.457H64.226V70.3H36.748a10.876,10.876,0,0,0-21.076,0H7.739V61.545h5.439a2.729,2.729,0,0,0,0-5.457H7.739V23.514H64.226ZM89.156,43.7l4.363,6.992H78.391V43.7ZM87,73.426a5.386,5.386,0,0,1-5.042,5.059h0a5.363,5.363,0,0,1-4.193-1.592,5.45,5.45,0,0,1-1.586-4.207,5.386,5.386,0,0,1,5.042-5.059h.34a5.485,5.485,0,0,1,3.853,1.592A5.4,5.4,0,0,1,87,73.426Zm9.8-12.393H94.651a2.729,2.729,0,0,0,0,5.457H96.8V70.3H92.158a10.876,10.876,0,0,0-21.076,0H69.665V34.941H83.376l2.21,3.3H75.614a2.725,2.725,0,0,0-2.72,2.729V53.473a2.725,2.725,0,0,0,2.72,2.729H96.748v4.832Z" transform="translate(-2.3 -18)"/></svg>
            <p class="p-title">¡Esta opción no está habilitada!</p>
            <p class="p-light">
                Si cuentas con proveedores a los que pagas a plazo, en Finsmart podemos adelantarles el dinero hoy a cambio de una
                comisión.
            </p>
            <p class="p-light">Si deseas activar esta opción de Confirming, <a  (click)="openIntercom()" class="p-link">comunícate con tu especialista</a> para que nuestra área de riesgos evalúe a tu
            empresa.</p>
        </div>
    </ng-container>
</ng-template>