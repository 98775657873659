import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-by-debtor-item',
  templateUrl: './invoice-by-debtor-item.component.html',
  styleUrls: ['./invoice-by-debtor-item.component.scss']
})
export class InvoiceByDebtorItemComponent implements OnInit {

  @Input() entity;
  
  constructor() { }

  ngOnInit(): void {
  }

}
