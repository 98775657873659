<div class="container" *ngIf="!isMobile">
    <div class="progress">
        <circle-progress
            [percent]="progress"
            [radius]="100"
            [outerStrokeWidth]="10"
            [innerStrokeWidth]="10"
            [space] = "-10"
            [outerStrokeColor]="'#0256FF'"
            [innerStrokeColor]="'#2D2D2D26'"
            [titleFontSize]= "48"
            [subtitleFontSize]= "36"
            [unitsFontSize]= "48"
            [showSubtitle] = "true"
            [subtitle] = "subtitle" 
            [animation]="true"
            [startFromZero]="false"
            [animationDuration]="300"
            [responsive]="true">
        </circle-progress>
    </div>
    <div class="container-steps">
        <div class="title">
            <p>{{titleSetupBar}}</p>
        </div>
        <div class="steps">
            <ng-container  *ngFor="let step of stepsSetupBar; let i = index; let last = last; let first = first" >
                    
                        <span [ngClass]="{'current': currenStepSetupBar  === i, 'completed': currenStepSetupBar > i, 'last': last, 'first': first}">
                            <ng-container *ngIf="!step.url">
                                {{step.title}}
                            </ng-container>
                            
                            <ng-container *ngIf="step.url && currenStepSetupBar  !== i">
                                {{step.title}}
                            </ng-container>

                            <a *ngIf="step.url && currenStepSetupBar  === i"  (click)="goToPage(step.url, i)">
                                {{step.title}}
                            </a>
                        </span>

            </ng-container>
        </div>
    </div>
</div>

<div class="container-mobile" *ngIf="isMobile">
    <ul>
        <li class="toggle-accordion">
            <input  (click)="openToggle()" type="checkbox" [checked]="checkedByDefault">
            <div class="head">
              <div class="head-title">
                  <span class="title-mobile">{{titleSetupBar}}</span>
                  <img *ngIf="checkedByDefault" src="../../../../../assets/icons/expand_more.svg" alt="">
                  <img *ngIf="!checkedByDefault" src="../../../../../assets/icons/expand_less.svg" alt="">
              </div>
              <div *ngIf="checkedByDefault" class="head-status">
                  <div class="container-actual-status">
                      <ng-container  *ngFor="let step of stepsSetupBar;let i = index; let last = last; let first = first" >
                          <span [ngClass]="{'current': currenStepSetupBar  === i, 'completed': currenStepSetupBar > i}">
                              <ng-container *ngIf="!step.url && currenStepSetupBar  === i">
                                  {{step.title}}
                              </ng-container>
                              <a *ngIf="step.url && currenStepSetupBar  === i" (click)="goToPage(step.url, i)">
                                  {{step.title}}
                              </a>
                              <ng-container *ngIf="currenStepSetupBar === stepsSetupBar.length && last">
                                {{step.title}}
                            </ng-container>
                          </span>
                      </ng-container>
                  </div>
                  <div class="container-dot">
                      <span class="dot"></span>
                  </div>
                  <div class="container-flex">
                      <span class="progress">{{progress}}%</span>
                  </div>
              </div>
            </div>
            <div class="body">
              <div class="progress-mobile">
                  <circle-progress
                      [percent]="progress"
                      [radius]="100"
                      [outerStrokeWidth]="10"
                      [innerStrokeWidth]="10"
                      [space] = "-10"
                      [outerStrokeColor]="'#0256FF'"
                      [innerStrokeColor]="'#2D2D2D26'"
                      [titleFontSize]= "32"
                      [subtitleFontSize]= "24"
                      [unitsFontSize]= "32"
                      [showSubtitle] = "true"
                      [subtitle] = "subtitle" 
                      [animation]="true"
                      [animationDuration]="300"
                      [startFromZero]="false"
                      [responsive]="true">
                  </circle-progress>
              </div>
                <div class="steps-mobile">
                    <ng-container  *ngFor="let step of stepsSetupBar; let i = index; let last = last; let first = first" >
                            
                        <span [ngClass]="{'current': currenStepSetupBar  === i, 'completed': currenStepSetupBar > i, 'last': last, 'first': first}">
                            <ng-container *ngIf="!step.url">
                                {{step.title}}
                            </ng-container>
                            
                            <ng-container *ngIf="step.url && currenStepSetupBar  !== i">
                                {{step.title}}
                            </ng-container>
        
                            <a *ngIf="step.url && currenStepSetupBar  === i"  [routerLink]="[step.url]">
                                {{step.title}}
                            </a>
                        </span>
        
                    </ng-container>
                </div>
            </div>
        </li>
      </ul>
</div>