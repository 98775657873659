<div class="header">
    <div class="return" (click)="return()">
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="25" viewBox="0 0 11 19"><g><g transform="rotate(-315 5.5 9.5)"><path fill="#6c727c" d="M2.79 3.655a1 1 0 0 1 1-1.005 1 1 0 0 1 1 .995l.048 10.257 9.924-.228a1 1 0 0 1 .046 1.999l-10.707.246a1 1 0 0 1-.794-.363 1 1 0 0 1-.466-.84zm12.995 11.018zm-12.707.247zm0 0z"/></g></g></svg>
    </div>
    <div>
        <img class="tiny-logo" src="../../../../../assets/logos/small.png">
    </div>
</div>

<template #entityDisplay></template>
