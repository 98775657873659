import {
  Component,
  OnInit,
  Optional,
  Self,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Input() default;
  @Input() initialLabel;
  @Input() getFileName = false;
  @Input() loadedLabel;
  @Input() accepts: 'image' | 'image+pdf' | 'xml' = 'image+pdf';
  @Output() onFileLoaded = new EventEmitter();
  @Output() fileNameLoaded = new EventEmitter();
  fileName;
  buttonLabel$ = new BehaviorSubject<any>(null);
  fileReader = new FileReader();
  _value;
  errorMessage;
  types;
  get value() {
    return this._value;
  }

  set value(val) {
    if (val !== undefined) {
      this._value = val;
      this.propagateChange(this._value);
    }
  }

  constructor(@Optional() @Self() private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes)
  }

  ngOnInit() {
    if (this.default) {
      this.value = this.default;
      this.buttonLabel$.next(this.loadedLabel);
    } else {
      this.buttonLabel$.next(this.initialLabel);
    }

    this.ngControl.control.parent.statusChanges.subscribe((formStatus) => {
      if (
        formStatus === 'INVALID' &&
        this.ngControl.errors &&
        (this.ngControl.dirty || this.ngControl.touched)
      ) {
        this.errorMessage = this.getErrorLabel();
      } else {
        this.errorMessage = '';
      }
    });

    this.setTypes()
  }

 
  getMimeTypes() {
    if (this.accepts === 'image') {
      return 'image/jpeg, image/png';
    } else if (this.accepts === 'image+pdf') {
      return 'image/jpeg, image/png, application/pdf';
    } else if (this.accepts === 'xml') {
      return '.xml';
    }
  }

  getErrorLabel() {
    if (this.ngControl.hasError('required')) {
      return 'Debe subir un archivo';
    }
  }

  setTypes(){
    if (this.accepts === 'image') {
      this.types =  ['image/jpeg', 'image/png'];
    } else if (this.accepts === 'image+pdf') {
      this.types = ['image/jpeg', 'image/png', 'application/pdf'];
    } else if (this.accepts === 'xml') {
      this.types =  ['.xml','application/xml'];
    }
  }

  cancelFile() {
    this.value = null;
    this.buttonLabel$.next(this.initialLabel);
  }
  fileLoaded() {
    this.buttonLabel$.next(this.loadedLabel);
    this.value = this.fileReader.result;
    this.onFileLoaded.emit(this.value);
    if (this.fileName) {
      this.fileNameLoaded.emit(this.fileName);
    }
  }
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (this.getFileName) {
        this.fileName = file.name.replace(/\.[^/.]+$/, '');
      }

      if (this.types.indexOf(file.type) > -1) {
        this.fileReader.readAsDataURL(file);
        this.fileReader.onload = () => {
          this.fileLoaded();
        };

        event.srcElement.value = null;
      }
    } else {
      console.log('no entra');
    }
  }

  writeValue(value: any) {
    this.value = value;
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}
}
