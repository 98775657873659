import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getDaysDiff } from 'src/app/shared/util/date.util';
import { GreaterThanZeroValidator } from 'src/app/shared/validators/greater-than-zero.validators';

@Component({
  selector: 'app-partial-payment-dialog',
  templateUrl: './partial-payment-dialog.component.html',
  styleUrls: ['./partial-payment-dialog.component.scss'],
})
export class PartialPaymentDialogComponent implements OnInit {
  form: FormGroup;
  showDateWarning;

  constructor(
    public dialogRef: MatDialogRef<PartialPaymentDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      partialPaymentDate: ['', [Validators.required]],
      partialAmount: ['', [Validators.required, GreaterThanZeroValidator]],
    });

    this.form
      .get('partialPaymentDate')
      .valueChanges.subscribe((selectedDate) => {
        const daysDiff = getDaysDiff(this.data.paymentDate, selectedDate);

        if (daysDiff < 0) {
          this.showDateWarning = true;
        } else {
          this.showDateWarning = false;
        }
      });
  }

  toggleWarning(newVisibility) {
    this.showDateWarning = newVisibility;
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
