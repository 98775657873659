import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeOrSpacing'
})
export class CapitalizeOrSpacingPipe implements PipeTransform {

  transform(value): string {
    if (value) {
      if (isNaN(value)) {
        return this.capitalizeFirstLitter(value);
      } else {
        return this.spaceing(value);
      }
    }
  }

  capitalizeFirstLitter(value){
    let first = value.substr(0, 1).toUpperCase();
      return first + value.substr(1);
  }

  spaceing(value){
    const splittedAmount = value?.toString().split('.');
    const whole = this.formatWholeNumber(splittedAmount[0]);
    const fraction = splittedAmount[1] || '';
    const result = fraction > 0 ? `${whole}.${fraction}` : `${whole}`
    
    return result;
  }

  formatWholeNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

}