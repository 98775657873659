<div class="container-toggle">
    <div class="lv">
        <p [ngClass]="checked === false ? 'active' : 'none'">{{firstLabel | uppercase}}</p>
    </div>
    <mat-slide-toggle [color]="'primary'" (change)="toggle($event)" class="example-margin"
        [checked]="checked">
    </mat-slide-toggle>
    <div class="lv rv">
        <p [ngClass]="checked === true ? 'active' : 'none'">{{secondLabel | uppercase}}</p>
    </div>
</div>