import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';


@Component({
  selector: 'app-ammount-balance',
  templateUrl: './ammount-balance.component.html',
  styleUrls: ['./ammount-balance.component.scss']
})
export class AmmountBalanceComponent implements OnInit {
@Input() amount;
  @Input() currency;
  @Input() mode: 'general' | 'balance' | 'tiny' = "general";
  @Input() direction: 'right' | 'left' | 'center' = 'left';
  @Input() color: 'text-blue' | 'text-gray' | 'text-black' = 'text-blue';
  @Input() weight: 'thin' | 'thick' = 'thick';
  @Input() animate = false;
  @Input() size : 'whole' | 'small' = 'whole';

  whole;
  fraction;
  pulsingTransition = {
    pulsing: false
  };

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const splittedAmount = changes.amount.currentValue?.toString().split('.');
    this.whole = splittedAmount[0]
    this.fraction = splittedAmount[1];
    if(this.animate) this.pulsingTransition.pulsing = true;
  }

  formatWholeNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  getCurrencyStyle() {
    if(this.mode === 'general') {
      return 'rectangle'
    } else if (this.mode === 'balance') {
      return 'plain'
    }
  }


}
