import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { transactionField } from 'src/app/features/financial-transactions/state/financial-transactions.selectors';
import { InvoiceService } from '../../../../invoices/services/invoice.service';
import { HistoricalInvestmentDialogComponent } from '../historical-investment-dialog/historical-investment-dialog.component';
@Component({
  selector: 'app-invoice-item-investor',
  templateUrl: './invoice-item-investor.component.html',
  styleUrls: ['./invoice-item-investor.component.scss'],
})
export class InvoiceItemInvestorComponent implements OnInit, OnChanges {
  @Input() entity;
  @Input() mode:
    | 'published'
    | 'not_published'
    | 'actual'
    | 'historic'
    | 'delayed' = 'published';
  investmentsSum$;
  @Input() key;
  field;
  constructor(
    public invoiceService: InvoiceService,
    private store: Store<AppState>,
    public dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes && changes.entity.currentValue.availableBalanceAmount === this.entity.availableBalanceAmount) {
    // }
  }

  ngOnInit(): void {
    this.investmentsSum$ = this.entity.investmentAmount;
    //  this.store.select(totalInvestedByInvoiceId(this.entity._id));
    this.store
      .select(transactionField(this.entity._id, 'isNewAnnouncement', true))
      .subscribe((data) => {
        this.field = data;
      });
  }

  openHistoric() {
    let dialogRef = this.openDialogHelper(HistoricalInvestmentDialogComponent, {
      data: this.entity?.debtor,
    });
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: data,
    });

    return dialogRef;
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }
}
