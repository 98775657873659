import { createSelector } from '@ngrx/store';

export const isLoggedIn = createSelector(
    state => state["auth"],
    (auth) => !!auth.accessToken
);
export const accessToken = createSelector(
    state => state["auth"],
    (auth) => {
        if(auth.accessToken) return auth.accessToken;
    }
);
export const authEmail = createSelector(
    state => state["auth"],
    (auth) => {
        if(auth.authEmail) return auth.authEmail;
    }
);

export const showPop = createSelector(
    state => state["auth"],
    (auth) => {
        if(auth.showPopUp) return auth.showPopUp;
    }
);
