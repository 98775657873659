<div class="container">
    <p>Vas a eliminar el siguiente archivo</p>
    
    <div class="row">
        <div class="name">
           <span class="title">
               Archivo adjunto {{entity?.index + 1}}.{{ getMimeType(entity?.itemDownload?.file) }}
           </span>
        </div>
    </div>
</div>
