<div class="item-container">
    <div class="item-flex margin-space" [ngClass]="{'big-screen': isLargeWindow}">
        <span class="tiny-title">Empresa pagadora</span>
        <span class="value highlighted tooltip-container-not-dots">
            <p *ngIf="!isLargeWindow">
                {{entity.companyName | limitTo: '30' | uppercase}}
            </p>
            <p *ngIf="isLargeWindow">
                {{entity.companyName | limitTo: '60' | uppercase}}
            </p>
            <span class="tooltiptext-right">RUC: {{entity.companyRuc | uppercase}}</span>
            <svg (click)="copyToClipboard($event)" class="svg-blue icon" xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 22 25">
                <g>
                    <g>
                        <path fill="#6c727c"
                            d="M19.163 6.818H6.767v15.91h12.396zm2.266 0v15.91c0 1.25-1.015 2.272-2.256 2.272H6.767c-1.24 0-2.256-1.023-2.256-2.273V6.818c0-1.25 1.015-2.273 2.256-2.273h12.406c1.24 0 2.256 1.023 2.256 2.273zm-5.64-4.545H2.256v15.909H0V2.272C0 1.023 1.015 0 2.256 0h13.533z" />
                    </g>
                </g>
            </svg>
            <span *ngIf="numberCopied | async" class="copied-message">¡Copiado al portapapeles!</span>
        </span>
    </div>
    <div class="item-flex credit margin-space">
        <div class="text credit-text">
            <span class="tiny-title">Línea otorgada</span>
            <span>
                {{(entity.lastEvaluation?.creditLine | numberSpacing) || '0.00' }}
            </span>
        </div>
        <div *ngIf="entity.lastEvaluation?.creditLineType && entity.lastEvaluation?.status !== 'denied'" class="icon tooltip-container-not-dots">
            <app-rounded-button-action [text]="mapCreditLine(entity.lastEvaluation?.creditLineType).icon" [background]="'black'">
            </app-rounded-button-action>
            <span class="tooltiptext-right">{{mapCreditLine(entity.lastEvaluation?.creditLineType).label}}</span>
        </div>

        <div *ngIf="!entity.lastEvaluation?.creditLineType || entity.lastEvaluation?.status === 'denied'" class="icon tooltip-container-not-dots">
            <app-rounded-button-action *ngIf="!entity.lastEvaluation?.creditLineType"  [text]="'-'" [background]="'black'">
            </app-rounded-button-action>
            <span class="tooltiptext-right">-</span>
        </div>
    </div>
    <div class="text item-flex w-150 margin-space">
        <span class="tiny-title credit-text">Línea utilizada</span>
        <span [ngClass]="getClassRed(entity)">
            {{entity.usedCreditLine | numberSpacing}}
        </span>
    </div>
    <div class="text item-flex margin-space">
        <span class="tiny-title space-text">Rating</span>
        <span>
            {{getFinalRating(entity.lastEvaluation?.rating)}}
        </span>
    </div>
    <div class="text item-flex margin-space">
        <span class="tiny-title">Última evaluación</span>


        <ng-container>
            <div *ngIf="!entity?.isObserve" class="tooltip-container-not-dots">

                <app-solid-label [content]="mapDate(entity.lastEvaluationDays)"
                [background]="mapStatus(entity.lastEvaluation?.status).color" [width]="'109'"></app-solid-label>
                <span class="tooltiptext-bottom">{{ entity.lastEvaluation?.evaluationFinishedAt ? formateDate(entity.lastEvaluation?.evaluationFinishedAt)  :  '---'}} <br>
                Dictamen: <b>{{ entity.lastEvaluation?.status ? (entity.lastEvaluation?.status | toSpanish : 'MALE' | capitalizeFirstLetters ) : '---'}}</b></span>
            </div>

            <div *ngIf="entity?.isObserve" class="tooltip-container-not-dots">

                <app-solid-label [content]="mapDate(entity.lastEvaluationDays)"
                [background]="mapStatus('observation').color" [width]="'109'"></app-solid-label>
                <span class="tooltiptext-bottom">{{ entity.lastEvaluation?.evaluationFinishedAt ? formateDate(entity.lastEvaluation?.evaluationFinishedAt)  :  '---'}} <br>
                Dictamen: <b>En observación</b></span>
            </div>
        </ng-container>
    </div>
</div>