import { AbstractControl } from '@angular/forms';

export function NumbersOnlyValidator(control: AbstractControl) {
    const pattern = new RegExp(/^-?(0|[0-9]\d*)?$/);
    const isNumbersOnly = pattern.test(control.value);

    if (!isNumbersOnly) {
      return { numbersOnly: true };
    }
    return null;
}
