import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { FinancialTransactionItemComponent } from '../../organisms/financial-transaction-item/financial-transaction-item.component';
import { FinancialTransactionActions } from '../../../state/financial-transactions.action-types';
import { savingSuccessStateById } from '../../../state/financial-transactions.selectors';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';

@Component({
  selector: 'app-create-financial-transaction-withdraw',
  templateUrl: './create-financial-transaction-withdraw.component.html',
  styleUrls: ['./create-financial-transaction-withdraw.component.scss'],
})
export class CreateFinancialTransactionWithdrawComponent implements OnInit {
  selectedUserId;
  userStatus;

  constructor(
    private overlayService: OverlayService,
    private windowScrollService: WindowScrollService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.setLocalStorage();

    this.store
      .select(UsersSelectors.profileProperty('status'))
      .pipe(take(1))
      .subscribe((status) => {
        this.userStatus = status;
      });
  }

  selectedUserChanged(userId) {
    if (userId) {
      this.selectedUserId = userId;
    }
  }

  openIntercom() {
    (<any>window).Intercom('show');
  }

  submitHandler(formValue) {
    this.sendEventPageView(2);
    this.overlayService.startConfirmation(
      formValue,
      FinancialTransactionItemComponent,
      'details',
      'create',
      'Dependiendo del banco, el retiro se podría hacer efectivo en tu cuenta en un promedio de 48 horas, aunque puede ser más rápido.\n\n\nRecuerda que cualquier costo o comisión por la transferencia será asumido por el inversionista.',
      FinancialTransactionActions.Saving,
      '/financial-transactions',
      savingSuccessStateById,
      {
        onSuccess: {
          showIcon: true,
          showCheck: false,
          iconName: 'withdraw',
          title: 'Retiro en proceso',
          messsage:
            '<span class="bold">Te llegará un correo confirmando la aprobación de tu retiro.</span> Recuerda que el retiro se podría hacer efectivo en tu cuenta en un promedio de 48 horas.',
        },
      },
      this.sendEventPage.bind(this)
    );
  }

  sendEventPage() {
    this.sendEventPageView(3);
  }

  setLocalStorage() {
    localStorage.setItem('tab_transactions', '1');
  }

  sendEventPageView(title) {
    this.windowScrollService.sendNewEvent(
      'Financial Transactions',
      `Withdraw Step ${title}`
    );
  }
}
