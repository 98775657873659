<div *ngIf="myClient?.companyName" class="container">
    <div class="item">
        <div class="row">
            <div>
                <p class="tiny blue title">{{myClient?.companyName | uppercase}}</p>
            </div>
        </div>
        <div class="row">
            <div>
                <p class="tiny">RUC: {{myClient?.companyRuc}}</p>
            </div>
        </div>
        <div class="row">
            <div class="one-line container-gray">
                <p class="tiny status">En curso</p>
                <div class="one-line child">
                    <div class="amount">
                        <app-amount mode="balance" [amount]="myClient?.awaitingInvoicesAmountPen" currency="pen" 
                            [color]="'text-black'" [weight]="'thin'">
                        </app-amount>
                    </div>
                    <div class="amount">
                        <app-amount mode="balance" [amount]="myClient?.awaitingInvoicesAmountUsd" currency="usd"
                            [color]="'text-black'" [weight]="'thin'">
                        </app-amount>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>