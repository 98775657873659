import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-counter',
  templateUrl: './input-counter.component.html',
  styleUrls: ['./input-counter.component.scss'],
})
export class InputCounterComponent implements OnInit {
  
  @Input() min = 2;
  @Input() max = 10;
  @Input() step = 1;
  @Input() counterValue: number;
  @Input() readonly: true;
  @Output() counterChange: EventEmitter<number>  = new EventEmitter();
  @Output() addChange: EventEmitter<any>  = new EventEmitter();
  @Output() removeChange: EventEmitter<any>  = new EventEmitter();

  ngOnInit() {
    this.counterValue = this.counterValue || this.min;
  }

  @Input()
  get counter() {
    return this.counterValue;
  }

  increment() {
    if (this.counterValue < this.max) {
      this.counterValue = this.counterValue + this.step;
      this.counterChange.emit(this.counterValue);
      this.addChange.emit(this.counterValue - 1);
    }
  }

  decrement() {
    if (this.counterValue > this.min) {
      this.counterValue = this.counterValue - this.step;
      this.counterChange.emit(this.counterValue);
      this.removeChange.emit(this.counterValue - 1);

    }
  }
}
