import { createReducer, on } from '@ngrx/store';
import { EvaluationsActions } from './evaluations.action-types';
import { updateStoreState } from 'src/app/shared/util/ngrx.util';

export interface State {
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  savingSucceeded: boolean;
  savingMode: 'create' | 'edit';
  savingEntityId: string;
  entities: any | null;
  lastErrorMessage: any | null;
  lastErrorStatus: any | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  saving: undefined,
  savingSucceeded: undefined,
  savingMode: undefined,
  savingEntityId: undefined,
  entities: undefined,
  lastErrorMessage: undefined,
  lastErrorStatus: undefined
};

export const _evaluationsRiskReducer = createReducer(
    initialState,
    on(EvaluationsActions.LoadAll, (state, action) => {
        return {
          ...state,
          loading: true
        }
    }),
    on(EvaluationsActions.LoadAllSuccess, (state, action) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          entities: action.entities
        }
    }),
    on(EvaluationsActions.Saving, (state, action) => {
        return {
          ...state,
          saving: true,
          savingSucceeded: undefined,
          savingMode: action.mode,
          savingEntityId: action.entity.id
        }
    }),
    on(EvaluationsActions.SaveSuccess, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: true,
          savingMode: undefined,
          entities: updateStoreState(state.entities, action.entity, state.savingMode)
        }
    }),
    on(EvaluationsActions.SaveFailed, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: false,
          savingMode: undefined,
          lastErrorMessage: action.error.error,
          lastErrorStatus: action.error.status
        }
    }),
    on(EvaluationsActions.Reset, (state, action) => {
      return initialState;
    }),
    on(EvaluationsActions.DeleteDocument, (state, action) => {
      return {
        ...state,
        saving: true,
        savingSucceeded: undefined,
        savingMode: action.mode,
        savingEntityId: action.entity.id
      }
    }),

    on(EvaluationsActions.SavingNote, (state, action) => {
      return {
        ...state,
        saving: true,
        savingSucceeded: undefined,
        savingMode: action.mode,
        savingEntityId: action.entity.id
      }
    }),

    on(EvaluationsActions.Request, (state, action) => {
      return {
        ...state,
        saving: true,
        savingSucceeded: undefined,
        savingMode: action.mode,
        savingEntityId: action.entity.id
      }
}),
);

export function evaluationsRiskReducer(state, action) {
  return _evaluationsRiskReducer(state, action)
}