<div class="bank-and-currency row" [ngClass]="{'margin-row': isHide === true}" >
    <div class="bank-container">
        <p *ngIf="!isHide" class="tiny bank-label">Banco</p>
        <p class="bank-name">{{entity.name}}</p>    
    </div>
    <div class="currency-container" *ngIf="!isHide">
        <span class="currency-type">{{entity.currency.toUpperCase()}}</span>
    </div>
</div>
<div class="row">
    <div class="account-number">
        <p class="tiny">Número de cuenta</p>
        <p class="big container-flex">{{entity.number}}
            <ng-container *ngIf="copyAccounts">
                <div class="copy" [ngClass]="{'our-bank': isHide === true}" (click)="copyNumber()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25"><g><g><path fill="#6c727c" d="M19.163 6.818H6.767v15.91h12.396zm2.266 0v15.91c0 1.25-1.015 2.272-2.256 2.272H6.767c-1.24 0-2.256-1.023-2.256-2.273V6.818c0-1.25 1.015-2.273 2.256-2.273h12.406c1.24 0 2.256 1.023 2.256 2.273zm-5.64-4.545H2.256v15.909H0V2.272C0 1.023 1.015 0 2.256 0h13.533z"/></g></g></svg>
                </div>
                <span *ngIf="numberCopied | async" class="copied-message">¡Copiado!</span>
            </ng-container>
        </p> 
           
    </div>
    
    <!--<ng-container *ngIf="!noControls">
        <div routerLink="edit/{{entity._id}}" class="edit icon-button">
            <img src="../../../../../../assets/icons/edit-icon.svg" />
        </div>
    </ng-container>-->
    <ng-container *ngIf="!noControls">
        <div *ngIf="!noControls" (click)="delete()" class="delete icon-button">
            <img src="../../../../../../assets/icons/trash.svg" />
        </div>
    </ng-container>
</div>
<div class="row">
    <div class="cci">
        <p class="tiny">Código de cuenta interbancario (CCI)</p>
        <p class="big container-flex">{{entity.cci}}
            <ng-container *ngIf="copyAccounts">
                <div class="copy" [ngClass]="{'our-bank': isHide === true}"  (click)="copyCCI()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25"><g><g><path fill="#6c727c" d="M19.163 6.818H6.767v15.91h12.396zm2.266 0v15.91c0 1.25-1.015 2.272-2.256 2.272H6.767c-1.24 0-2.256-1.023-2.256-2.273V6.818c0-1.25 1.015-2.273 2.256-2.273h12.406c1.24 0 2.256 1.023 2.256 2.273zm-5.64-4.545H2.256v15.909H0V2.272C0 1.023 1.015 0 2.256 0h13.533z"/></g></g></svg>
                </div>
                <span *ngIf="cciCopied | async" class="copied-message">¡Copiado!</span>
            </ng-container>
        </p>
    </div>
    

</div>
<div *ngIf="!noControls" class="row">
    <div class="creation-date">
        <app-date title="Fecha de registro" [date]="entity.createdAt" [oneLine]="true"></app-date>
    </div>
</div>