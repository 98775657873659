<mat-form-field>
  <button
    [ngClass]="counterValue <= min ? '' : 'c-pointer'"
    (click)="decrement()"
    [disabled]="counterValue <= min"
  >
    <mat-icon>remove</mat-icon>
  </button>
  <input matInput type="text" [value]="counterValue" [readonly]="true" />

  <button
    class="c-pointer"
    (click)="increment()"
    [disabled]="counterValue >= max"
  >
    <mat-icon>add</mat-icon>
  </button>
</mat-form-field>
