import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() totalCount;
  @Input() skip = 0;
  @Input() pageSize = 3;
  @Output() pagination = new EventEmitter();

  pageIndex;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.pageIndex = this.skip / this.pageSize;
  }

  ngOnInit(): void {
    this.pageIndex = this.skip / this.pageSize;
  }

  paginate(event) {
    const paginationParams = { 
      skip: event.pageIndex * this.pageSize,
      limit: this.pageSize,
    }
    this.pagination.emit(paginationParams);
  }
}
