import { Component, OnInit, Input, Optional, Self } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { createDateOnly, createDateOnly2 } from '../../util/date.util';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},    
  ]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() label;
  @Input() startOffSet;
  @Input() default;
  @Input() disabled = false;
  @Input() max = null;

  isControlInPendingStatus = false;
  _errorStatus: Boolean = false;
  _originalLabel;

  _value: string;

  constructor (
    @Optional()@Self() private ngControl: NgControl,
    private _adapter: DateAdapter<any>
  ) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this._originalLabel = this.label;
    this._adapter.setLocale('es-PE');

    if(this.default) {
      this._value = this.default;
      // this._value = new Date(this.default).toISOString();
      this.writeValue(this._value)
    }

    this.ngControl.control.parent.statusChanges.subscribe(formStatus => {
      if(formStatus === 'INVALID' && this.ngControl.errors && (this.ngControl.dirty || this.ngControl.touched)) {
        this._errorStatus = true;
        this.label = this.getErrorLabel();
      }
    });

    this.ngControl.statusChanges.subscribe(newStatus => {
      if(newStatus === 'PENDING') {
        this.isControlInPendingStatus = true;
      } else {
        this.isControlInPendingStatus = false;

        this._errorStatus = newStatus === 'VALID'? false : true;

        if(this._errorStatus) {
          this.label = this.getErrorLabel();
        } else {
          this.label = this._originalLabel;
        }
      }

    });
  }

  getErrorLabel() {
    if(this.ngControl.hasError('required')) {
      return this._originalLabel + ' (obligatorio)';
    } else if (this.ngControl.hasError('paymentDateIsNotBiggerOrEqualThan')) {
      return this._originalLabel + ' (debe ser igual o mayor que la fecha de vencimiento)';
    } else {
      console.log(this.ngControl.errors)
      console.log(this.ngControl.control.parent.status)
      return this._originalLabel + ' (inválido)';
    }
  }

  minimumDateFilter = (d: Date | null): boolean => {
    if(this.startOffSet) {
      const date = (d || new Date());
      let targetMinimumStart = new Date();
  
      targetMinimumStart.setDate(new Date().getDate() + 15);
  
      return date > targetMinimumStart;  
    } else {
      return true;
    }
  }

  writeValue(value: any) {
    this._value = value;
    
    const onlyDateString = createDateOnly2(value);
    
    this.propagateChange(onlyDateString);   
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}


}
