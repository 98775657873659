<ng-container *allowedRoles="['investor']">
    <div class="title-and-fetch">
        <div>
            <app-page-title [title]="pageTitle"></app-page-title>
        </div>
        <!--app-manual-reload-button></app-manual-reload-button-->
        <div class="row toggle toggle-desk" *ngIf="displayToggle">
            <app-slide-toggle [checked]="default" firstLabel="PEN" secondLabel="USD"
                (selectionChanged)="setCurrency($event)">
            </app-slide-toggle>
        </div>
    </div>
</ng-container>
<div class="row toggle toggle-mob" *ngIf="displayToggle">
    <app-slide-toggle [checked]="default" firstLabel="PEN" secondLabel="USD"
        (selectionChanged)="setCurrency($event)">
    </app-slide-toggle>
</div>
<ng-container *allowedRoles="['admin', 'super admin']">
    <app-page-title [title]="pageTitle"></app-page-title>
</ng-container>

<ng-container *allowedRoles="['investor']">
    <div class="disclaimer-container">
        <div>
            <div class="disclaimer-layout">
                <lib-icon icon="excel" size="m" style="display:block"></lib-icon>
                <p class="disclaimer-text ml-8">¿Quieres analizar tus transacciones?</p>
            </div>
        </div>
        <div>
            <div class="disclaimer-layout c-pointer" (click)="exportTransactions()">
                <p class="disclaimer-text download mr-8">Descargar transacciones</p>
                <lib-icon icon="download" color="blue"></lib-icon>
            </div>
        </div>
    </div>
    <lib-tab-financial-transactions [selected]="selected" [retention]="retentionLength.length || 0" [deposit]="depositLength.length || 0" [withdraw]="withdrawLength.length || 0" [investment]="investmentLength.length || 0" (selectedTab)="updateTabSelected($event)">
        <div class="deposits">
            <div class="row container-transaction">
                <div>
                    <h6>Depósitos ({{depositLength.length || '0'}})</h6>
                </div>
        
                <div class="new-button icon-row-inner clickable" routerLink="deposit">
                    <h1 class="new-button-text">Nuevo depósito</h1>
                    <img class="img-add" src="../../../../../../assets/icons/add_black_24dp.svg" alt="">
                </div>
            </div>
            <ng-container *ngIf="deposit.length > 0; else no_transactions">
                <ng-container *ngFor="let transaction of deposit">
                    <app-financial-transaction-item [role]="loggedInUserRole" [entity]="transaction"
                        (onShowAttachement)="showingAttachement($event)" (onApprove)="approved($event)"
                        (onReject)="rejected($event)"></app-financial-transaction-item>
                </ng-container>
            </ng-container>
        </div>
        <div class="withdraws">
            <div class="row container-transaction">
                <div>
                    <h6>Retiros ({{withdrawLength.length || '0'}})</h6>
                </div>
                <div class="new-button icon-row-inner clickable" routerLink="withdraw">
                    <h1 class="new-button-text">Nuevo retiro</h1>
                    <img class="img-add" src="../../../../../../assets/icons/add_black_24dp.svg" alt="">
                </div>
            </div>
            <ng-container *ngIf="withdraw.length > 0; else no_transactions">
                <ng-container *ngFor="let transaction of withdraw">
                    <app-financial-transaction-item [role]="loggedInUserRole" [entity]="transaction"
                        (onShowAttachement)="showingAttachement($event)" (onApprove)="approved($event)"
                        (onReject)="rejected($event)"></app-financial-transaction-item>
                </ng-container>
            </ng-container>
            
        </div>
        <div class="investments">
            <div class="row container-transaction">
                <div class="mt-30">
                    <h6>Inversiones y retorno ({{investmentLength.length}})</h6>
                </div>
            </div>
            <ng-container *ngIf="investment.length > 0; else no_transactions">
                <ng-container *ngFor="let transaction of investment">
                    <app-financial-transaction-item [role]="loggedInUserRole" [entity]="transaction"
                        (onShowAttachement)="showingAttachement($event)" (onApprove)="approved($event)"
                        (onReject)="rejected($event)"></app-financial-transaction-item>
                </ng-container>

                <div class="no-transactions-found">
                    <ng-container>
                        <p class="alert-investment"><span>💰 ¿Listo para reinvertir?</span> Revisa las <a class="hyperlink" routerLink="/opportunities">oportunidades de inversión.</a></p>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="retentions">
            <div class="row container-transaction">
                <div class="mt-30">
                    <h6>Retenciones ({{retention.length || '0'}})</h6>
                </div>
            </div>
            <ng-container *ngIf="retention.length > 0; else no_transactions">
                <ng-container *ngFor="let transaction of retention">
                    <app-financial-transaction-item [role]="loggedInUserRole" [entity]="transaction"
                        (onShowAttachement)="showingAttachement($event)" (onApprove)="approved($event)"
                        (onReject)="rejected($event)"></app-financial-transaction-item>
                </ng-container>
            </ng-container>
        </div>
    </lib-tab-financial-transactions>
    <ng-container *ngFor="let transaction of financialTransactioins$">
        <app-financial-transaction-item [role]="loggedInUserRole" [entity]="transaction"
            (onShowAttachement)="showingAttachement($event)" (onApprove)="approved($event)"
            (onReject)="rejected($event)"></app-financial-transaction-item>
    </ng-container>

    <app-paginator [totalCount]="pagination.total" [pageSize]="pagination.limit" [skip]="pagination.skip"
        (pagination)="paginationHandlerClient($event)">
    </app-paginator>
</ng-container>
<ng-container *allowedRoles="['admin', 'super admin']">

    <ng-container *ngIf="filtersForm">
        <form [formGroup]="filtersForm" (ngSubmit)="applyFilter()">
            <div class="filter-row">
                <mat-form-field appearance="fill">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="types" multiple>
                        <mat-option *ngFor="let type of typesList" [value]="type">
                            {{ type | toSpanish : 'MALE' | capitalizeFirstLetters }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Estado</mat-label>
                    <mat-select formControlName="statuses" multiple>
                        <mat-option *ngFor="let status of statusesList" [value]="status">
                            {{ status | toSpanish : 'MALE' | capitalizeFirstLetters }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="filter-row">
                <mat-form-field appearance="fill">
                    <mat-label>Fecha de creación</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Desde" formControlName="createdFrom">
                        <input matEndDate placeholder="Hasta" formControlName="createdTo">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <app-autocomplete searchFor='investor' label="Buscar inversionista" [selectedOption]="selectedInvestor"
                    formControlName="investor"></app-autocomplete>
            </div>
            <div class="filter-row">
                <mat-form-field appearance="fill">
                    <mat-label>Moneda</mat-label>
                    <mat-select formControlName="currencies" multiple>
                        <mat-option value="pen">PEN</mat-option>
                        <mat-option value="usd">USD</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Monto</mat-label>
                    <input formControlName="amount" type="number" matInput class="input">
                </mat-form-field>
            </div>
            <div class="filter-row">
                <fs-ui-button (onClick)="resetFiltersToDefaults()" [label]="'Predeterminados'" [type]="'secondary'">
                </fs-ui-button>
                <fs-ui-button (onClick)="applyFilter()" [label]="'Aplicar'"></fs-ui-button>
                <fs-ui-button (onClick)="resetFilters()" [label]="'Borrar'" [type]="'tertiary'"></fs-ui-button>
            </div>
            <!--
            <div class="status-filter">
                <h6>Estado</h6>
                <div>{{ 'investment' | toSpanish : 'MALE' }}</div>
            </div>
    
                <div class="filters">
                    <div>{{ 'investment' | toSpanish : 'MALE' | capitalizeFirstLetters }}</div>
                    <div>{{ 'investment return' | toSpanish : 'MALE' | capitalizeFirstLetters  }}</div>
                    <div>{{ 'deposit' | toSpanish : 'MALE' | capitalizeFirstLetters  }}</div>
                    <div>{{ 'withdraw' | toSpanish : 'MALE' | capitalizeFirstLetters  }}</div>
                    <div>{{ 'donate' | toSpanish : 'MALE' | capitalizeFirstLetters  }}</div>    
                </div>
            -->
            <!--
            Tipo: Investment | Investment Return | ((Deposit)) | ((Withdraw)) | Donate
            Estado (Deposit, Withdraw, Donate): ((In Process)) | Rejected | Approved
            Estado (Investment): Investment Start | In Progress | Capital Refunded | Cancelled
            Estado (Investment Return): NA
    
            Currency
            Text: investor name, DNI, amount
            Date Range for creation date (maybe update as well?)
            -->
        </form>
    </ng-container>


    <ng-container *ngIf="financialTransactioins; else no_items_found">

        <app-financial-transaction-item *ngFor="let transaction of financialTransactioins" [role]="loggedInUserRole"
            [entity]="transaction" (onShowAttachement)="showingAttachement($event)" (onApprove)="approved($event)"
            (onFilterByUser)="filterByUser($event)" (onReject)="rejected($event)"></app-financial-transaction-item>
    </ng-container>
    <div class="row">
        <fs-ui-button routerLink="deposit" [label]="'Depositar'"></fs-ui-button>
        <fs-ui-button routerLink="withdraw" [label]="'Retirar'" [type]="'secondary'"></fs-ui-button>
    </div>

    <app-paginator [totalCount]="pagination.total" [pageSize]="pagination.limit" [skip]="pagination.skip"
        (pagination)="paginationHandler($event)">
    </app-paginator>
</ng-container>

<ng-template #no_items_found>
    <div class="no-items-found">
        No se encontraron artículos con estos criterios
    </div>
</ng-template>

<ng-template #no_transactions>
    <div class="no-transactions-found">
       
        <ng-container *ngIf="selected !== 2">
            <lib-icon icon="price_change" size="xl"></lib-icon>
            <p>No has realizado {{ selected === 0 ? 'depósitos' : selected === 1 ? 'retiros' : 'inversiones'}}
            </p>
        </ng-container>

        <ng-container *ngIf="selected === 2">
            <lib-icon icon="retention" size="xl"></lib-icon>
            <p>No tienes retenciones
            </p>
        </ng-container>
        <ng-container *ngIf="selected === 3">
            <p class="alert-investment"><span>💰 ¿Listo para invertir?</span> Revisa las <a class="hyperlink" routerLink="/opportunities">oportunidades de inversión.</a></p>
        </ng-container>
    </div>
</ng-template>