import { createAction, props } from '@ngrx/store';

export const LoadAll = createAction(
    '[Bank Accounts] Load All',
);
export const LoadedAllSuccess = createAction(
    '[Bank Accounts] Loaded All Success',
    props<{bankAccounts: any}>()
);
export const ResetBankAccounts = createAction(
    '[Bank Accounts] Reset'
);
export const Saving = createAction(
    '[Bank Accounts] Saving',
    props<{entity: any, mode: 'edit' | 'create' | 'delete'}>()
);
export const SaveSucceeded = createAction(
    '[Bank Accounts] Save Succeeded',
    props<{result: any}>()
);
export const SaveFailed = createAction(
    '[Bank Accounts] Save Failed',
    props<{error: any}>()
);
