import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() { }

  initialize() {
    mixpanel.init(environment.mixpanelKey);
  }
  // {api_host: "http://18.217.67.231:8080"}

  setIdentity(_userId, _userEmail?, _userRole?, _name?, _userChannel?, _userNeswletter?, _userCompanyName?, _userStatus?, _userIsConfirming?, _userCompanyRuc?, _userConversionCount?, _userCoversionAmount?, _userConversionLastTime?, _segment?) {
    mixpanel.people.set({
      $name: _name,
      $userID: _userId,
      $email: _userEmail,
      $role: _userRole,
      $isConfirming: _userIsConfirming,
      $status: _userStatus,
      $company: _userCompanyName,
      $company_ruc: _userCompanyRuc,
      $user_email_marketing: _userNeswletter,
      $user_declared_source: _userChannel,
      $conversion_count: _userConversionCount,
      $conversion_amount: _userCoversionAmount,
      $conversion_last_completed: _userConversionLastTime,
      $segment: _segment
    });
    mixpanel.identify(_userId);
  }

  newEvent(eventName, data?) {
    if (eventName) {
      if (data) {
        //console.log(eventName,data)
        mixpanel.track(eventName, data);
      } else {
        //console.log(eventName)
        mixpanel.track(eventName);
      }
    }
  }

  register(first_params) {
    if (first_params) {
      mixpanel.people.set_once(first_params);
    }
  }

  reset() {
    mixpanel.reset();
  }
}
