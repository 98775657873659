import { RiskService } from 'src/app/features/risk-pending-evaluations/services/risk.service';
import { Component, Input, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-evaluation-init-list',
  templateUrl: './evaluation-init-list.component.html',
  styleUrls: ['./evaluation-init-list.component.scss']
})
export class EvaluationInitListComponent implements OnInit {
  @Input() evaluations;

  constructor(
    private router: Router,
    private riskService: RiskService
  ) { }

  ngOnInit(): void {
  }

  formateDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  evaluate(evaluation) {
    this.router.navigateByUrl(`/risk-debtors/${evaluation.debtor._id}`).then(() => {
      this.riskService.byId(evaluation._id).subscribe();
    });
  }

  formateDateShort(date) {
    return moment(date).format('DD/MM/YY');
  }

  getAccountName(ev: any) {
    // console.log(ev.invoiceNetAmount)
    if (ev.invoiceNetAmount) {
      return ev?.accountManagerName
    } else {
      return ev?.createdBy?.names + ' ' + ev.createdBy?.familyNames
    }
    // console.log(ev)
  }
}
