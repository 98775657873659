<h1>Mi historial</h1>
<p class="subtitle">Tu historial de inversiones con este pagador</p>

<div class="bank-container">
    <p class="tiny bank-label">Empresa pagadora</p>
    <p class="bank-name company-name mw-200">{{data?.data?.debtorCompanyName || data?.data?.companyName | uppercase }}
    </p>
</div>


<div class="row toggle toggle-desk">
    <app-slide-toggle [checked]="default" [firstLabel]="'pen'" [secondLabel]="'usd'"
        (selectionChanged)="setCurrency($event)">
    </app-slide-toggle>
</div>

<div *ngIf="!isLoading">
    <div class="row" *ngIf="!isEmpty; else empty_historical">
        <div class="row-title">
            <lib-icon icon="assignment" size="s"></lib-icon>
            <h5>Portafolio actual</h5>
        </div>
        <div class="row-container">
            <div class="row-item">
                <div class="wrap">
                    <div class="row-title-first title">
                        <lib-icon icon="receipt_long" size="s"></lib-icon>
                        Total por cobrar
                    </div>
                </div>
    
                <div>
                    <p class="tiny title">Facturas</p>
                    <p class="value black">{{ (historical?.totalInvoicesAwaitingCollectionCount) || '0' }}</p>
                </div>
                <div class="mw-100">
                    <p class="tiny title">Monto</p>
                    <p class="value black">{{ (historical?.totalAmountAwaitingCollection | numberSpacing) || '0.00' }}</p>
                </div>
            </div>
            <div class="row-item">
    
                <div class="vertical_dotted_line">
                    <span class="blue-dot"></span>
                </div>
                <div class="wrap">
                    <div class="dots-row">
                        <div class="dotsIcon">
                            <img src="../../../../../../assets/icons/time2.svg" alt="">
                        </div>
                        <div class="title dotsTitle"  style="margin-top: 7px;">
    
                            Vencidas
                        </div>
                    </div>
                </div>
    
                <div>
                    <p class="value delayed">{{ (historical?.delayedInvoicesCount) || '0' }}</p>
                </div>
                <div class="mw-100">
                    <p class="value" style="margin-top: 7px;">{{ (historical?.amountDelayed | numberSpacing) || '0.00' }}</p>
                </div>
            </div>
            <div class="row-item">
                <div class="wrap">
                    <div class="row-title-first title">
                        <lib-icon icon="donut" size="s"></lib-icon>
                        Concentración
                    </div>
                </div>
                <div>
                    <p class="tiny title">Facturas</p>
                    <p class="value black">{{ historical?.invoicesAwaitingCollectionCountPercentage + '%'|| '0%' }}</p>
                </div>
                <div class="mw-100">
                    <p class="tiny title mw-100">Monto</p>
                    <p class="value black">{{ historical?.amountAwaitingCollectionPercentage + '%'|| '0%' }}</p>
                </div>
            </div>
        </div>
    
    
        <div class="title row-title">
            <lib-icon icon="event" size="s"></lib-icon>
            <h5>Inversiones históricas</h5>
        </div>
    
        <div class="row-item">
            <div class="wrap">
                <div class="row-title-first title">
                    <lib-icon icon="history" size="s"></lib-icon>
                    Cobradas
                </div>
            </div>
    
            <div>
                <p class="tiny title">Facturas</p>
                <p class="value black">{{ (historical?.finalizedInvoicesCount) || '0' }}</p>
            </div>
            <div class="mw-100">
                <p class="tiny title">Monto</p>
                <p class="value black">{{ (historical?.amountFinalized | numberSpacing) || '0.00' }}</p>
            </div>
        </div>
    
        <div class="row-item">
            <div class="wrap">
                <div class="row-title-first title">
                    <lib-icon icon="savings" size="s"></lib-icon>
                    Retorno bruto
                </div>
            </div>
            <div class="mw-100">
                <p class="tiny title">Monto</p>
                <p class="value black">{{ (historical?.investmentReturned | numberSpacing) || '0.00' }}</p>
            </div>
        </div>
    
        <div class="row-item">
            <div class="wrap">
                <div class="row-title-first title">
                    <lib-icon icon="pending_action" size="s"></lib-icon>
                    Mora promedio
                </div>
            </div>
    
            <div>
                <p class="tiny title">Días</p>
                <p class="value black">{{ (historical?.averageDelay) || '0' }}</p>
            </div>
        </div>
    
    
    
    </div>
</div>


<ng-template #empty_historical>
    <div class="empty-opportunities container">
        <img class="" src='../../../../../assets/icons/only-publish.svg'>
        <p class="disclaimer">No tienes inversiones con este pagador en esta moneda.</p>
    </div>
</ng-template>


<div class="button-container">
    <fs-ui-button (click)="cancel()" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
</div>