import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AppState } from 'src/app/app.states';
import { Store, Action } from '@ngrx/store';
import {
  tap,
  map,
  catchError,
  mergeMap,
  switchMap,
  shareReplay,
  concatMap,
  take,
} from 'rxjs/operators';
import { Observable, pipe, of } from 'rxjs';
import { BankAccountsActions } from './bank-accounts.action-types';
import { BankAccountsService } from '../services/bank-accounts.service';
import { Overlay } from '@angular/cdk/overlay';
import { UsersActions } from '../../users/state/users.action-types';
import { AuthSelectors } from '../../authentication/state/auth-selector-types';
import { UsersSelectors } from '../../users/state/users.selector-types';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { bankAccounts } from './bank-accounts.selectors';
import { GAService } from './../../../shared/services/ga.service';


@Injectable()
export class BankAccountsEffects {
  $loadAll = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountsActions.LoadAll),
      concatMap((action) => this.bankAccountsService.getAll()),
      map((bankAccounts) =>
        BankAccountsActions.LoadedAllSuccess({ ...bankAccounts })
      )
    )
  );

  $saving = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BankAccountsActions.Saving),
        concatMap((action) =>
          this.bankAccountsService.saveForUser(action.entity, action.mode).pipe(
            switchMap((receivedEntity) => {
              return [
                BankAccountsActions.SaveSucceeded({ result: receivedEntity }),
                UsersActions.LoadProfile()
              ];
            }
            ),
            catchError((error) => {
              let eventData 
              this.store.select(bankAccounts).subscribe((banks) => {
                const eventName = 'new_bank_account';
                eventData = {
                  create_date: new Date().toISOString(),
                  bank_account_type: "User account",
                  currency: action.entity.currency.toUpperCase(),
                  bank_name: action.entity.name,
                  is_success : false,
                  error_description: `Unkown error ${error.status}`,

                };        
                this.gaService.sendEvent(eventName, eventData);
                this.mixpanelService.newEvent(eventName, eventData)
              });
              
              return of(BankAccountsActions.SaveFailed({ error }))
            })
          )
        )
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private bankAccountsService: BankAccountsService,
    private mixpanelService: MixpanelService,
    private gaService: GAService
  ) {}
}
