import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-telegram-confirmation',
  templateUrl: './telegram-confirmation.component.html',
  styleUrls: ['./telegram-confirmation.component.scss']
})
export class TelegramConfirmationComponent implements OnInit {

  @Input() entity;

  constructor() {
   }

  ngOnInit(): void {
    // console.log(this.entity)

  }

}
