import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

    transform(items: any[], key: string, term: string | boolean): any {
      return items.filter(item => item[key] === term)
    }

}