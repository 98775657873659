import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "currencyToSign",
})
export class CurrencyToSignPipe implements PipeTransform {
  transform(value: any): string {
    if (value) {
      if (value.toLowerCase() === "pen") {
        return `S/`;
      } else if (value.toLowerCase() === "usd") {
        return `$`;
      }
    } else {
      return `S/`;
    }
  }
}
