<p>Vas a poner en venta la siguiente factura:</p>
<div class="row">
    <div class="">
        <span class="tiny-title">Empresa pagadora</span>
        <span class="label">{{entity.debtor.companyName | uppercase}}</span>
    </div>
    <div class="">
        <span class="tiny-title">Fecha de publicación</span>
        <span class="tiny">{{getDate(entity.onSaleTime) | capitalizeOnlyFirstLetter}}</span>
    </div>

</div>
<ng-container *ngIf="invoices?.length > 0">
    <div class="code-table" [ngClass]="invoices.length > 1 ? 'small-height' : 'big-height'">
        <table>
            <tr>
                <th>Girador</th>
                <th>Códigos</th>
                <th>Operación</th>
                <th>Moneda</th>
                <th>Monto neto</th>
                <th>TEA</th>
            </tr>
            <tr *ngFor="let code of invoices;  let last = last;  let first = first  ">
                <td>
                    <ng-container *ngIf="code?.isConfirming && code?.supplier?.companyName?.length > 25">
                        <lib-tooltip width="160" [content]="code?.supplier?.companyName | capitalizeFirstLetters">
                            {{ code.supplier?.companyName | uppercase | limitTo: '25'}}
                        </lib-tooltip>
                    </ng-container>
                    <ng-container *ngIf="code?.isConfirming && code?.supplier?.companyName?.length < 26">
                        {{ code.supplier?.companyName | uppercase }}
                    </ng-container>

                    <ng-container *ngIf="!code?.isConfirming && code.issuer?.companyName?.length > 25">
                        <lib-tooltip width="160" [content]="code.issuer?.companyName | capitalizeFirstLetters">
                            {{ code.issuer?.companyName | uppercase | limitTo: '25'}}
                        </lib-tooltip>
                    </ng-container>
                    <ng-container *ngIf="!code?.isConfirming && code?.issuer?.companyName?.length < 26">
                        {{ code.issuer?.companyName | uppercase}}
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf="code?.physicalInvoices && code?.physicalInvoices?.length > 2;">
                        <span [matTooltip]="getCodes(code)">
                            <span class="code"
                                *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                                <ng-container *ngIf="index<2">
                                    {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                                </ng-container>
                                <ng-container *ngIf="isLast && index >= 2">
                                    ...
                                </ng-container>
                            </span>
                        </span>

                    </ng-container>

                    <ng-container *ngIf="code?.physicalInvoices && code?.physicalInvoices.length < 3;">
                        <span class="code"
                            *ngFor="let subInvoice of code?.physicalInvoices; let isLast = last; let index = index">
                            <ng-container *ngIf="index<2">
                                {{ subInvoice.code}}<span *ngIf="!isLast">, </span>
                            </ng-container>
                            <ng-container *ngIf="isLast && index >= 2">
                                ...
                            </ng-container>
                        </span>
                    </ng-container>
                </td>
                <td>{{ code.isConfirming ? 'CONFIRMING' : 'FACTORING'}}</td>
                <td>{{ code.currency == 'pen' ? 'PEN' : 'USD'}}</td>
                <td>{{ code.netAmount | numberSpacing }}</td>
                <td>{{ code?.tea || '-'}}</td>
            </tr>

        </table>
    </div>
</ng-container>
<div class="row">
    <app-risk-header-information [debtor]="entity.debtor" [entity]="entity?.evaluation"></app-risk-header-information>
</div>



<div class="telegram">
    <span class="telegram-title">Mensaje de Telegram</span>

    <div [innerHTML]="telegram" class="preview"></div>

    <!-- <app-email-preview-html [html]="telegram" class="preview"></app-email-preview-html> -->

    <div style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 15px;">
        <p>Fecha de actualización</p>
        <app-date displayStyle='plain' [date]="entity.debtor.detailUpdatedAt"></app-date>
    </div>
</div>