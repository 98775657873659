import { AbstractControl } from '@angular/forms';

export function MustBeTrue(control: AbstractControl) {
  let isTrue;

  if (control.value) {
    isTrue = control.value === true;
  }

  if (!isTrue) {
    return { invalid: true };
  }
  return null;
}
