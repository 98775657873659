import { WindowScrollService } from "src/app/shared/services/window-scroll.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.states";
import {
  financialTransactions,
  savingSuccessStateById,
} from "../../../state/financial-transactions.selectors";
import { Observable, Subject } from "rxjs";
import { OverlayService } from "src/app/shared/modules/overlay/services/overlay.service";
import { DisplayFileComponent } from "src/app/shared/UI/organisms/display-file/display-file.component";
import { FinancialTransactionActions } from "../../../state/financial-transactions.action-types";
import { FinancialTransactionItemComponent } from "../../organisms/financial-transaction-item/financial-transaction-item.component";
import { takeUntil, debounceTime, switchMap, filter } from "rxjs/operators";
import { UsersSelectors } from "src/app/features/users/state/users.selector-types";
import { FormGroup, FormBuilder } from "@angular/forms";
import { FinancialTransactionType } from "src/app/shared/enums/FinancialTransactionType.enum";
import { FinancialTransactionStatus } from "src/app/shared/enums/FinancialTransactionStatus.enum";
import { Roles } from "src/app/shared/enums/Roles.enum";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { createDateOnly } from "src/app/shared/util/date.util";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from "@angular/material-moment-adapter";
import { FinancialtransactionsService } from "../../../services/financial-transactions.service";
import { NgxSpinnerService } from "ngx-spinner";
import moment from "moment";
import FileSaver from "file-saver";
import b64toBlob from "b64-to-blob";
import { Actions, ofType } from "@ngrx/effects";
import { MatDialog } from "@angular/material/dialog";
import { MotiveDialogComponent } from "src/app/features/invoices/UI/organisms/motive-dialog/motive-dialog.component";
import { GTMService } from "src/app/shared/services/gtm.service";
import { ApproveDepositDialogComponent } from "src/app/features/invoices/UI/organisms/approve-deposit-dialog/approve-deposit-dialog.component";

@Component({
  selector: "app-list-financial-transactions-template",
  templateUrl: "./list-financial-transactions-template.component.html",
  styleUrls: ["./list-financial-transactions-template.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ListFinancialTransactionsTemplateComponent
  implements OnInit, OnDestroy {
  pageTitle = "Transacciones financieras";
  pageDescription =
    "Puedes depositar, retirar o revisar tus transacciones pendientes e históricas.";
  financialTransactioins$: Observable<any>;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  loggedInUserRole;
  transactions;
  filtersForm: FormGroup;
  typesList = Object.values(FinancialTransactionType);
  statusesList = Object.values(FinancialTransactionStatus);
  financialTransactioins;
  financialTransactioinsInvestor;
  selected = 0;
  pagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  defaultPagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  selectedInvestor;
  filterDefault = {
    types: [FinancialTransactionType.Deposit],
    statuses: [FinancialTransactionStatus["In Process"]],
    createdFrom: undefined,
    createdTo: undefined,
    currencies: undefined,
    amount: undefined,
    investor: undefined,
    pagination: undefined,
  };
  currency = "pen";
  firtsLabel;
  secondLabel;
  default = true;
  toggle;
  displayToggle = true;
  deposit;
  withdraw;
  tabTransactions;
  investment;
  withdrawLength;
  depositLength;
  investmentLength;
  retentionLength;
  retention;
  dontTriggerTabEvent = false;
  showMotiveDialog = false;
  selectedEntity = new Object();
  rejectionReason = "";
  subscription;
  showApproveDialog = false;
  documentNumber = "";
  constructor(
    private store: Store<AppState>,
    private overlayService: OverlayService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private service: FinancialtransactionsService,
    private _adapter: DateAdapter<any>,
    private spinner: NgxSpinnerService,
    private windowScrollService: WindowScrollService,
    public dialog: MatDialog,
    private gmtService: GTMService
  ) { }

  ngOnInit() {
    // const sendSurvey = localStorage.getItem("send_survey");
    // if (sendSurvey === "true") {
    //   console.log("send sprig");
    //   this.sendEventSprig();
    // }
    this.store
      .select(UsersSelectors.profileProperty("role"))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loggedInRole) => {
        if (loggedInRole) {
          this.loggedInUserRole = loggedInRole;
        }
      });

    if (this.loggedInUserRole === Roles.ADMIN || this.loggedInUserRole === Roles.SUPER_ADMIN) {
      this._adapter.setLocale("es-PE");

      this.filtersForm = this.fb.group({
        types: [this.filterDefault.types],
        statuses: [this.filterDefault.statuses],
        createdFrom: [this.filterDefault.createdFrom],
        createdTo: [this.filterDefault.createdTo],
        currencies: [this.filterDefault.currencies],
        amount: [this.filterDefault.amount],
        investor: [this.filterDefault.investor],
        pagination: [this.filterDefault.pagination],
      });

      const previousFilters = JSON.parse(
        localStorage.getItem("transactions_filter")
      );
      if (previousFilters) {
        this.filtersForm.controls["types"].setValue(previousFilters.types);
        this.filtersForm.controls["statuses"].setValue(
          previousFilters.statuses
        );
        if (previousFilters.createdFrom)
          this.filtersForm.controls["createdFrom"].setValue(
            moment(previousFilters["createdFrom"])
          );
        if (previousFilters.createdTo)
          this.filtersForm.controls["createdTo"].setValue(
            moment(previousFilters["createdTo"])
          );
        this.filtersForm.controls["currencies"].setValue(
          previousFilters.currencies
        );
        this.filtersForm.controls["amount"].setValue(previousFilters.amount);
        this.filtersForm.controls["pagination"].setValue(
          previousFilters.pagination
        );
        this.filtersForm.controls["investor"].setValue(
          previousFilters.investor_id
        );

        this.pagination = {
          total: 0,
          limit: 10,
          skip: previousFilters.pagination,
        };
        this.selectedInvestor = previousFilters.investor;
      }
      this.applyFilter(true);
    } else {
      // this.financialTransactioins$ = this.store.select(financialTransactions);
      this.listTransactions();
    }
    this.subscription = this.overlayService
      .getCloseEmitter()
      .subscribe((obs) => {

        if (this.showMotiveDialog && this.selectedEntity) {
          this.rejected(this.selectedEntity);
        } else if (this.showApproveDialog && this.selectedEntity) {
          this.approved(this.selectedEntity);
        }
      });
  }

  // sendEventSprig() {
  //   let event = {
  //     event: "[Platform][Create][Deposit]",
  //   };
  //   this.gmtService.newEvent(event);
  //   localStorage.removeItem("send_survey");
  // }
  setCurrency($event) {
    this.currency = $event;
    this.filterByCurrency();
    this.setLocalStorage();
    this.tabTransactions = 0;
    this.selected = 0;
    this.resetPagination();
    this.dontTriggerTabEvent = true;
    this.sendEventPageView(this.currency);
  }

  filterByCurrency() {
    this.financialTransactioins = this.transactions;
    if (this.financialTransactioins) {
      const transactionsByCurrencyDeposit = this.financialTransactioins.filter(
        (oppor) => oppor.currency === this.currency && oppor.type === "deposit"
      );

      const transactionsByCurrencyWithdraw = this.financialTransactioins.filter(
        (oppor) =>
          oppor.currency === this.currency &&
          (oppor.type === "withdraw" || oppor.type === "donate")
      );

      const transactionsByCurrencyRetention =
        this.financialTransactioins.filter(
          (oppor) =>
            oppor.currency === this.currency && oppor.type === "retention"
        );

      const transactionsByCurrencyInvestment =
        this.financialTransactioins.filter(
          (oppor) =>
            oppor.currency === this.currency &&
            (oppor.type === "investment" || oppor.type === "investment return")
        );
      this.deposit = transactionsByCurrencyDeposit;
      this.withdraw = transactionsByCurrencyWithdraw;
      this.investment = transactionsByCurrencyInvestment;
      this.retention = transactionsByCurrencyRetention;

      this.depositLength = transactionsByCurrencyDeposit;
      this.withdrawLength = transactionsByCurrencyWithdraw;
      this.investmentLength = transactionsByCurrencyInvestment;
      this.retentionLength = transactionsByCurrencyRetention;

      this.updatePagination();
    }
  }

  updatePagination() {
    if (this.selected === 0) {
      const deposit = this.deposit;
      this.pagination.total = deposit.length;
      this.deposit = deposit.slice(
        this.pagination.skip,
        this.pagination.limit + this.pagination.skip
      );
    } else if (this.selected === 1) {
      const withdraw = this.withdraw;
      this.pagination.total = withdraw.length;
      this.withdraw = withdraw.slice(
        this.pagination.skip,
        this.pagination.limit + this.pagination.skip
      );
    } else if (this.selected === 2) {
      const retention = this.retention;
      this.pagination.total = retention.length;
      this.retention = retention.slice(
        this.pagination.skip,
        this.pagination.limit + this.pagination.skip
      );
    } else if (this.selected === 3) {
      const investment = this.investment;
      this.pagination.total = investment.length;
      this.investment = investment.slice(
        this.pagination.skip,
        this.pagination.limit + this.pagination.skip
      );
    }
  }

  setLocalStorage() {
    localStorage.setItem("toggle_transaction_currency", this.currency);
    //localStorage.setItem('tab_transactions', this.selected.toString())
  }

  updateTabSelected($event) {
    // this.selected = 0
    // this.tabTransactions = 0
    this.resetPagination();
    this.selected = $event;
    //localStorage.setItem('tab_transactions', this.selected.toString())
    this.updatePagination();

    if (!this.dontTriggerTabEvent) {
      this.sendEventPageView(this.currency);
    } else {
      this.dontTriggerTabEvent = false;
    }
  }

  showingAttachement(itemId) {
    this.spinner.show();

    this.service.getDepositVoucher(itemId).subscribe((voucherFile) => {
      if (voucherFile.file) {
        this.overlayService.showComponent(
          DisplayFileComponent,
          voucherFile.file
        );
      } else {
        window.alert("¡No se encuentra el archivo!");
      }

      this.spinner.hide();
    });
  }

  listTransactions() {
    // const pagination = localStorage.getItem('transactions_pagination');
    // if (pagination != null) {
    //   this.pagination = JSON.parse(pagination);
    // }

    this.store.select(financialTransactions).subscribe((trx: any) => {
      //this.pagination.total = trx.length;
      this.transactions = trx;
      // console.log("transactions", this.transactions)
      this.getLocalStorage();
      this.filterByCurrency();
      this.getDefaultToggle();
    });
  }

  getDefaultToggle() {
    this.toggle == "pen" ? (this.default = false) : (this.default = true);
  }
  getLocalStorage() {
    this.tabTransactions = localStorage.getItem("tab_transactions");
    if (this.tabTransactions !== null && this.tabTransactions === "1") {
      this.selected = Number(this.tabTransactions);
      //localStorage.removeItem('tab_transactions')
    }

    const toogleLocal = localStorage.getItem("toggle_transaction_currency");
    if (toogleLocal != null) {
      this.currency = toogleLocal;
      this.filterByCurrency();
    }

    toogleLocal === null ? (this.toggle = "pen") : (this.toggle = toogleLocal);
  }

  approved(entity) {
    if (entity.type == "deposit") {
      this.selectedEntity = entity;
      const dialogRef = this.openDialogHelper(
        ApproveDepositDialogComponent,
        { entity: entity, documentNumber: this.documentNumber }
      );
      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          this.documentNumber = result.data;
          this.showApproveDialog = true;
          this.overlayService.startConfirmation(
            { ...entity, status: "approved", documentNumber: result.data },
            FinancialTransactionItemComponent,
            "details",
            "edit",
            null,
            FinancialTransactionActions.Saving,
            "/financial-transactions",
            savingSuccessStateById,
            null,
            this.reApplyFilters.bind(this)
          );
        }
        else {
          this.selectedEntity = new Object();
          this.showApproveDialog = false;
          this.documentNumber = "";
        }
      });
    } else {
      this.overlayService.startConfirmation(
        { ...entity, status: "approved" },
        FinancialTransactionItemComponent,
        "details",
        "edit",
        null,
        FinancialTransactionActions.Saving,
        "/financial-transactions",
        savingSuccessStateById,
        null,
        this.reApplyFilters.bind(this)
      );
    }
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      position: {
        left: "0",
      },
      autoFocus: false,
      data: data,
    });

    return dialogRef;
  }
  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  rejected(entity) {
    let type = entity.type;
    const dialogRef = this.openDialogHelper(MotiveDialogComponent, {
      data: this.rejectionReason,
      type: type,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result?.event == "cancel" || !result) {
        this.selectedEntity = new Object();
        this.showMotiveDialog = false;
        this.rejectionReason = "";
        return;
      }
      this.showMotiveDialog = true;
      this.selectedEntity = entity;
      this.rejectionReason = result?.data;
      this.overlayService.startConfirmation(
        {
          ...entity,
          status: "rejected",
          rejectionReason: this.rejectionReason,
        },
        FinancialTransactionItemComponent,
        "details",
        "edit",
        null,
        FinancialTransactionActions.Saving,
        "/financial-transactions",
        savingSuccessStateById,
        null,
        this.reApplyFilters.bind(this)
      );
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscription.unsubscribe();
  }

  paginationHandler(data) {
    this.filtersForm.controls["pagination"].setValue(data.skip);
    this.pagination.skip = data.skip;
    this.pagination.limit = data.limit;
    this.applyFilter(true);
  }

  paginationHandlerClient(data) {
    this.pagination.skip = data.skip;
    //localStorage.setItem('transactions_pagination', JSON.stringify(this.pagination));
    this.listTransactions();
  }

  checkSelectedTypes() { }

  reApplyFilters() {
    this.showMotiveDialog = false;
    this.showApproveDialog = false;

    this.documentNumber = "";
    this.rejectionReason = "";

    this.applyFilter(true);
  }

  applyFilter(paginate = false) {
    this.spinner.show();
    const { createdFrom, createdTo, ...rest } = this.filtersForm.value;
    let filterFormattedValue = rest;

    if (!paginate) {
      this.pagination = Object.assign({}, this.defaultPagination);
      filterFormattedValue["pagination"] = this.pagination.skip;
    }

    if (createdFrom)
      filterFormattedValue = {
        ...filterFormattedValue,
        createdFrom: createDateOnly(createdFrom.toDate().toISOString()),
      };
    if (createdTo)
      filterFormattedValue = {
        ...filterFormattedValue,
        createdTo: createDateOnly(createdTo.toDate().toISOString()),
      };

    this.service
      .get(this.pagination.skip, this.pagination.limit, filterFormattedValue)
      .subscribe((result: any) => {
        if (result && result?.financialTransactions?.entities?.length > 0) {
          this.financialTransactioins = result?.financialTransactions?.entities;
          this.pagination.total = result?.financialTransactions?.count;
          filterFormattedValue["pagination"] = this.pagination.skip;

          const keys = Object.keys(filterFormattedValue).filter(
            (key) =>
              filterFormattedValue[key] !== null &&
              filterFormattedValue[key] !== undefined
          );
          const transactionFilter = {};
          // console.log(result.financialTransactions.entities[0])
          keys.map((key) => {
            if (key === "investor") {
              transactionFilter["investor_id"] = filterFormattedValue.investor;
              transactionFilter["investor"] =
                result.financialTransactions.entities[0]["user"];
              return;
            }
            transactionFilter[key] = filterFormattedValue[key];
          });

          localStorage.setItem(
            "transactions_filter",
            JSON.stringify(transactionFilter)
          );
        } else {
          if (this.pagination.skip != 0) {
            this.pagination.skip -= 10;
            this.applyFilter(true);
          }
          this.financialTransactioins = undefined;
        }

        this.spinner.hide();
      });
  }
  resetFiltersToDefaults(reapply = true) {
    this.filtersForm.controls["statuses"].setValue(this.filterDefault.statuses);
    this.filtersForm.controls["types"].setValue(this.filterDefault.types);
    this.filtersForm.controls["createdFrom"].setValue(
      this.filterDefault.createdFrom
    );
    this.filtersForm.controls["createdTo"].setValue(
      this.filterDefault.createdTo
    );
    this.filtersForm.controls["investor"].setValue(this.filterDefault.investor);
    this.filtersForm.controls["currencies"].setValue(
      this.filterDefault.currencies
    );
    this.filtersForm.controls["amount"].setValue(this.filterDefault.amount);

    if (reapply) this.applyFilter();
  }
  resetFilters(reapply = true) {
    this.filtersForm.reset();
    this.selectedInvestor = undefined;

    if (reapply) this.applyFilter();
  }
  filterByUser(event) {
    this.resetFilters(false);

    this.selectedInvestor = event.transactionUser;
    this.filtersForm.controls["investor"].setValue(event.transactionUser._id);

    if (event.transactionType) {
      this.filtersForm.controls["types"].setValue([event.transactionType]);
    }

    this.reApplyFilters();
  }

  exportTransactions() {
    this.service.downloadExcel().subscribe((data: any) => {
      const contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = b64toBlob(data.base64, contentType);

      const date = moment().format("DD_MM_YYYY");

      FileSaver.saveAs(blob, `transacciones_${date}`);
    });
  }

  resetPagination() {
    this.pagination.total = 0;
    this.pagination.skip = 0;
    this.pagination.limit = 10;
    //localStorage.removeItem('transactions_pagination');
    this.listTransactions();
    localStorage.removeItem("tab_transactions");
  }

  sendEventPageView(currency: string) {
    this.windowScrollService.sendNewEvent(
      "Financial Transactions",
      `Overview`,
      this.getTabName(),
      currency.toUpperCase()
    );
  }

  getTabName() {
    switch (this.selected) {
      case 0:
        return "Depósitos";
      case 1:
        return "Retiros";
      case 2:
        return "Retenciones";
      case 3:
        return "Inversiones";
      default:
        return "Depósitos";
    }
  }
}
