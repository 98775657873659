<h1>Partir Factura</h1>
<p class="description margin-bottom-24">
  Ajusta los montos y agrega particiones como sea necesario.
</p>

<div class="d-flex flex-justify-between margin-bottom-24">
  <lib-data-point class="v-margin-group">
    <div class="title">
      <lib-text color="dark-gray" content="Monto de adelanto"></lib-text>
    </div>
    <div class="value">
      <lib-text
        size="s"
        [content]="
          (data?.invoice?.currency | currencyToSign) +
          ' ' +
          (totalAmount | numberSpacing)
        "
      >
      </lib-text>
    </div>
  </lib-data-point>
  <lib-data-point aligned="right" class="v-margin-group">
    <div class="title">
      <lib-text color="dark-gray" content="Monto neto"></lib-text>
    </div>
    <div class="value">
      <lib-text
        size="s"
        [content]="
          (data?.invoice?.currency | currencyToSign) +
          ' ' +
          (netAmount | numberSpacing)
        "
      >
      </lib-text>
    </div>
  </lib-data-point>
</div>

<div class="progress-section margin-bottom-24">
  <app-progress-bar
    class="investment-progress-ch"
    [progress]="100 - data?.invoice?.availableBalancePercentage"
  >
  </app-progress-bar>

  <p class="investment-progress-label">
    Invertido
    {{ data?.invoice?.currency | currencyToSign }}
    {{
      investmentAmount === 0
        ? '0.00'
        : (investmentAmount.toFixed(2) | numberSpacing)
    }}
  </p>
</div>

<div class="partitions-section">
  <h2>Particiones a crear</h2>
  <p class="description u-mb-3">
    Los montos son calculados en base al monto a adelantar al cliente.
  </p>
  <div class="margin-bottom-24">
    <app-input-counter
      [counterValue]="
        this.data?.partitions?.partitions
          ? this.data?.partitions?.partitions.length
          : ''
      "
      (removeChange)="removePartition()"
      (addChange)="newPartition($event)"
    ></app-input-counter>
  </div>

  <div [formGroup]="invoiceForm">
    <div formArrayName="partitions">
      <div
        *ngFor="
          let partition of partitionControls;
          let i = index;
          let isLast = last;
          let isFirst = first
        "
        [formGroupName]="i"
        class="partition-container"
      >
        <div class="partition-item">
          <div class="secuence">
            {{ partition.get('code').value.split('-')[2].toUpperCase() }}
          </div>
          <div class="amount">
            <p class="label">Monto de adelanto</p>

            <mat-form-field
              [ngClass]="[
                (isWithInvestment && isFirst) || isLast ? 'input-read-only' : ''
              ]"
              class="form-split"
            >
              <input
                (keyup)="onInputChange($event.target.value, i)"
                formControlName="amount"
                matInput
                type="text"
                [readonly]="(isWithInvestment && isFirst) || isLast"
              />
            </mat-form-field>

            <div
              *ngIf="partition.get('amount').errors?.zeroAmount"
              class="error-message"
            >
              El monto debe ser mayor cero.
            </div>
            <div *ngIf="isFirst && isWithInvestment" class="warning-message">
              Reservado para el monto invertido
            </div>
            <div
              *ngIf="isLast && !partition.get('amount').errors?.zeroAmount"
              class="warning-message"
            >
              Monto calculado automáticamente
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container button-direction-column">
      <fs-ui-button
        [isDisabled]="!invoiceForm.valid"
        [action]="'submit'"
        (click)="onSubmit()"
        [label]="'Continuar'"
        [type]="'secondary'"
      ></fs-ui-button>
      <fs-ui-button
        (click)="cancel()"
        [label]="'Cancelar'"
        [type]="'tertiary'"
      ></fs-ui-button>
    </div>
  </div>
</div>
