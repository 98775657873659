import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toSpanish'
})
export class ToSpanishPipe implements PipeTransform {
    transform(value: any, gender: any = GENDER.NA): any {
      if(value) {
        const translation = DICTIONARY[value][GENDER[gender]] || DICTIONARY[value][GENDER.NA];
        if(translation) {
          return translation;
        } else {
          console.error(`"${value}" doesn't exist in the dictionary, returning original value`);
          return value;
        }  
      }

    }
}

enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
  NA = 'na'
}

const DICTIONARY = {
  "male": { [GENDER.MALE]: 'masculino', [GENDER.FEMALE]: 'masculina'},
  "female": { [GENDER.MALE]: 'femenino', [GENDER.FEMALE]: 'femenina'},
  "passport": { [GENDER.NA]: 'pasaporte'},
  "investment": { [GENDER.NA]: 'inversión'},
  "invoice configuration": { [GENDER.NA]: 'configuración factura'},
  "invoice configuration confirming": { [GENDER.NA]: 'configuración factura'},
  "user account": { [GENDER.NA]: 'cuenta usuario'},
  "admin": { [GENDER.NA]: 'admin'},
  "investor": { [GENDER.NA]: 'inversionista'},
  "client": { [GENDER.NA]: 'cliente'},
  "created": { [GENDER.MALE]: 'creado', [GENDER.FEMALE]: 'creada'},
  "disapproved": { [GENDER.MALE]: 'desaprobado', [GENDER.FEMALE]: 'desaprobada'},
  "disapprove": { [GENDER.NA]: 'desaprobar'},
  "approved": { [GENDER.MALE]: 'aprobado', [GENDER.FEMALE]: 'aprobada'},
  "confirmed": { [GENDER.MALE]: 'confirmado', [GENDER.FEMALE]: 'confirmada'},
  "search engine": { [GENDER.NA]: 'buscadores'},
  "google": { [GENDER.NA]: 'google'},
  "facebook":{ [GENDER.NA]: 'facebook'},
  "instagram": { [GENDER.NA]: 'instagram'},
  "linkedin": { [GENDER.NA]: 'linkedin'},
  "youtube": { [GENDER.NA]: 'youtube'},
  "in process": { [GENDER.NA]: 'en proceso'},
  "pending confirmation": { [GENDER.NA]: 'pend. de conformidad'},
  "pending": { [GENDER.MALE]: 'pendiente'},
  "denied": { [GENDER.MALE]: 'denegado'},
  "error": { [GENDER.MALE]: 'error'},
  "observation": { [GENDER.MALE]: 'Observación'},
  "for sale": { [GENDER.NA]: 'en venta'},
  "investment start": { [GENDER.NA]: 'por iniciar'},
  "deposit": { [GENDER.NA]: 'depósito'},
  "withdraw": { [GENDER.NA]: 'retiro'},
  "donate": { [GENDER.NA]: 'donación'},
  "sale closed": { [GENDER.NA]: 'compra cerrada'},
  "partially sold": { [GENDER.NA]: 'compra parcial'},
  "awaiting collection": { [GENDER.NA]: 'espera cobranza'},
  "collected": { [GENDER.MALE]: 'cobrado', [GENDER.FEMALE]: 'cobrada'},
  "foreigner card": { [GENDER.NA]: 'carnet extranjería'},
  "national id": { [GENDER.NA]: 'DNI'},
  "finalized": { [GENDER.MALE]: 'finalizado', [GENDER.FEMALE]: 'finalizada'},
  "pending transfer": { [GENDER.MALE]: 'transferencia pendiente', [GENDER.FEMALE]: 'transferencia pendiente'},
  "investment return": { [GENDER.NA]: 'retorno inversión'},
  "capital refunded": { [GENDER.NA]: 'capital reembolsado'},
  "cancelled": { [GENDER.MALE]: 'cancelado', [GENDER.FEMALE]: 'cancelada'},
  "email verified": { [GENDER.NA]: 'correo verificado'},
  "in progress": { [GENDER.NA]: 'en curso'},
  "rejected": { [GENDER.MALE]: 'denegado', [GENDER.FEMALE]: 'denegada'},
  "configured": { [GENDER.MALE]: 'configurado', [GENDER.FEMALE]: 'configurada'},
  "friends": { [GENDER.NA]: 'amistades'},
  "friends_relatives": { [GENDER.NA]: 'amigos o familiares'},
  "sales_team": { [GENDER.NA]: 'Ejecutivo comercial'},
  "influencers": { [GENDER.NA]: 'Contenidos de influenciadores'},
  "media": { [GENDER.NA]: 'medios'},
  "retention": { [GENDER.NA]: 'retención'},
  "na": { [GENDER.NA]: '-'},
  "ruc": { [GENDER.NA]: 'RUC'},

  "companyName": { [GENDER.NA]: 'Tipo de sociedad'},
  "createdAt": { [GENDER.NA]: 'Fecha de creación'},
  "regimeType": { [GENDER.NA]: 'Padrón'},
  "billing": { [GENDER.NA]: 'Facturación'},
  "emplyeeCount": { [GENDER.NA]: 'Nro de trabajadores'},
  "employeeCount": { [GENDER.NA]: 'Nro de trabajadores'},
  "classification": { [GENDER.NA]: 'Clasificación'},
  "indirectDebt": { [GENDER.NA]: 'Deuda indirecta'},
  "directDebt": { [GENDER.NA]: 'Deuda directa '},
  "mortgageGuarantees": { [GENDER.NA]: 'Garantías hipotecarias'},
  "unclearProtests": { [GENDER.NA]: 'Protestos sin aclarar'},
  "ppp": { [GENDER.NA]: 'PPP'},

}
