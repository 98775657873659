export enum InvoiceStatus {
    "In Process" = "in process",
    "Approved" = "approved",
    "Configured" = "configured",
    "Disapproved" = "disapproved",
    "For Sale" = "for sale",
    "Pending Confirmation" = "pending confirmation",
    "Partially Sold" = 'partially sold',
    "Sale Closed" = 'sale closed',
    "Awaiting Collection" = 'awaiting collection',
    "Collected" = 'collected',
    "Finalized" = "finalized",
}
