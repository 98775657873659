import {
  Component,
  OnInit,
  Optional,
  Self,
  Input,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ControlValueAccessor, NgControl, FormControl } from '@angular/forms';
import {
  startWith,
  map,
  tap,
  debounceTime,
  switchMap,
  finalize,
} from 'rxjs/operators';
import { UsersService } from 'src/app/features/users/services/users.service';
import { capitalizeFirstLetters } from '../../util/string.util';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  filteredOptions = [];
  isLoading;
  searchInput = new FormControl();
  @Input() selectedOption;
  @Input() label;
  @Input() searchFor: 'investor' | 'client' | 'user' | 'debtor';
  @Output() OnSelectionChanged = new EventEmitter();
  @Output() OnSelectionChangedObject = new EventEmitter();

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private userService: UsersService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    const newValue = changes.currentValue || this.selectedOption;

    if (newValue) {
      this.optionSelected(newValue);
      this.searchInput.setValue(newValue);
    }
  }

  ngOnInit(): void {
    this.searchInput.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredOptions = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.callSearchFunction(value).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data) => {
        if (data.length > 0) {
          this.filteredOptions = data;
        } else {
          this.optionSelected(undefined);
        }
      });
  }

  displayFn(value: any): string {
    if (value && value?.names && value?.familyNames) {
      return `${capitalizeFirstLetters(value.names)} ${capitalizeFirstLetters(
        value.familyNames
      )}`;
    } else if (value && value?.companyName) {
      return value.companyName.toUpperCase();
    } else if (value && value?.debtorCompanyName) {
      return value.debtorCompanyName.toUpperCase();
    } else {
      this.searchInput?.setValue('');
      return '';
    }
  }

  optionSelected(event) {
    if (event) {
      if (event.hasOwnProperty('role')) {
        this.propagateChange(event._id);
        this.OnSelectionChanged.emit(event._id);
      } else {
        this.propagateChange(event._id);
        this.OnSelectionChanged.emit(event._id);
      }

      this.OnSelectionChangedObject.emit(event);
    } else {
      this.propagateChange(undefined);
      this.OnSelectionChanged.emit(undefined);
    }
  }

  propagateChange = (_: any) => {};

  writeValue(selectedOption: any) {
    if (!selectedOption) {
      this.displayFn(null);
    }
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}

  callSearchFunction(param) {
    switch (this.searchFor) {
      case 'client':
        return this.userService.searchClients(param);
      case 'user':
        return this.userService.searchUsers(param);
      case 'investor':
        return this.userService.searchInvestors(param);
      case 'debtor':
        return this.userService.searchDebtors(param);
      default:
        throw `Unkown target entity ${this.searchFor}`;
    }
  }
}
