import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { UsersService } from 'src/app/features/users/services/users.service';

@Injectable({ providedIn: 'root' })
export class UniqueUserByEmailAdminValidator {

    constructor(private usersService: UsersService) {}

    public isUserEmailUnique(originalEmail): AsyncValidatorFn {
        return (control: AbstractControl) => {
            return new Promise((resolve, reject) => {

                if(originalEmail !== control.value) {
                    return timer(0)
                    .pipe(switchMap(() => this.usersService.emailExists(control.value)))
                    .subscribe(
                        res => resolve(null),
                        err => resolve({isUserEmailUnique: true})
                    );
                } else {
                    return resolve(null);
                }
            });
        }  
      }
}
