import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyName'
})
export class CurrencyNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(value.toLowerCase()) {
      case 'pen':
        return 'Soles';
      case 'usd':
        return 'Dolares';
    }
  }

}
