import { AbstractControl, ValidatorFn } from '@angular/forms';

//We only enforce this on DNI to be exactly 8, CE is 9 and passport
//DNI and CE should be numbers only but passport is flexible (restrict it to 15 characters in general)
//and minimum 5
export function IDLengthValidator(control: AbstractControl) {
  

  if(control.parent) {
    const idType = control.parent.get('idType').value;

    if(idType === 'national id') {
      const exactLengthEight = new RegExp(/^[0-9]{8,8}$/).test(control.value);
      console.log(exactLengthEight)
      if(!exactLengthEight) {
        return {
          dniExactLength: true
        }
      }
    } else if(idType === 'ruc') {
      //Verified from https://sel.migraciones.gob.pe/servmig-valreg/VerificarCE
      const exactLengthEleven = new RegExp(/^[0-9]{11,11}$/).test(control.value);
      if(!exactLengthEleven) {
        return {
          rucExactLength: true
        }
      }
    } else if(idType === 'foreigner card') {
      //Verified from https://sel.migraciones.gob.pe/servmig-valreg/VerificarCE
      const exactLengthEight = new RegExp(/^[0-9]{9,9}$/).test(control.value);
      if(!exactLengthEight) {
        return {
          ceExactLength: true
        }
      }
    } else {
      //this is very variant so we will strict it to minimum 5 and maximum 15 characters
      //just to avoid malicious input
      //will allow numbers and english letters only
      const betweenFiveAndFifteen = new RegExp(/^[A-Za-z0-9]{5,15}$/).test(control.value);
      if(!betweenFiveAndFifteen) {
        return {
          passportLengthRange: true
        }
      }
    }
  }

  /*
  const pattern = new RegExp(/^[0-9]{8,8}$/);

  const isPatternValid = pattern.test(control.value);

  if (!isPatternValid) {
    return { dniExactLength: true };
  }
  return null;
  */
}