import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { EmailValidator } from '../../../../../shared/validators/email.validators';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() submitted = new EventEmitter();
  form: FormGroup;
  @Input() isVerified = false
  @Input() email
  constructor(
    private formBuilder: FormBuilder,
    private gtmService: GTMService,
    private formService: FormService
  ) { }

  ngOnInit() {
    if(this.isVerified){
      this.form = this.formBuilder.group({
        email: [this.email],
        actualPassword: ['', [Validators.required]]
    });
    }else{
      this.form = this.formBuilder.group({
        email: ['', [Validators.required, EmailValidator]],
        actualPassword: ['', [Validators.required]]
      });
    }
  }

  submit() {

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if(this.form.valid) {
      this.form.value.email.trim();
      this.submitted.emit(this.form.value);
    }else{
      let event = {
        event: '[Platform][Form][Input][Error]',
        _inputError: this.formService.getFormValidationErrors(this.form),
        _inputName: "Form acceder error",
        _inputCategory: "login-form",
      }
      this.gtmService.newEvent(event)
    }
  }

  sendEvent(field){
    let event;
    if(this.form.get(field).errors){
      event = {
        event: '[Platform][Form][Input][Error]',
        _inputError: this.form.get(field).errors,
        _inputName: "Form " + field + ' error',
        _inputCategory: "login-form",
      }
    }else{
      event = {
        event: '[Platform][Form][Input]',
        _inputName: "Form "+ field,
        _inputCategory: "login-form",
      }
    }
    this.gtmService.newEvent(event)
  }
  
}
