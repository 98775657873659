<p>Vas a crear los siguientes pagadores:</p>
<ng-container *ngIf="entity | filterBy:'validRuc':false as payersInvalid">
    <ng-container *ngIf='payersInvalid.length > 0'>
        <app-chip [imgIcon]="'error.svg'" [closedIcon]="false" [content]="getContent(payersInvalid)"></app-chip>
    </ng-container>
</ng-container>

<ng-container *ngIf="entity | filterBy:'validRuc':true as payers">
    <div  class="container-row">
        <ng-container *ngFor='let payer of payers; index as i'>
            <div class="row">
                <div class="name">
                    <span class="index">
                        #{{i+1}}
                    </span>
                   <span class="title">
                       {{payer.debtorName | uppercase}}
                   </span>
                </div>
                <div *ngIf="payer.initiateEvaluation" class="add">
                    <div class="tooltip-container-not-dots">
                        <button mat-mini-fab color="primary">
                            <mat-icon>check</mat-icon>
                        </button>
                        <span class="tooltiptext-bottom">Se iniciará una nueva evaluación</span>
                    </div>
                </div>
    
                <div *ngIf="!payer.initiateEvaluation" class="delete">
                    <div class="tooltip-container-not-dots">
                        <button mat-mini-fab color="accent">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <span class="tooltiptext-bottom">No se iniciará una nueva evaluación</span>
                    </div>
                </div>
              
            </div>
        </ng-container>
    </div>
</ng-container>