import { Component, Input, OnInit } from '@angular/core';
import { INVOICE_STATUS } from 'e2e/helpers/investment.helper';
import { InvoiceService } from 'src/app/features/invoices/services/invoice.service';


@Component({
  selector: 'app-invoice-operations-overview',
  templateUrl: './invoice-operations-overview.component.html',
  styleUrls: ['./invoice-operations-overview.component.scss']
})
export class InvoiceOperationsOverviewComponent implements OnInit {

  @Input() invoice;
  currency = 'pen';
  debtorStats;
  displayToggle = false;
  conditions : {
    'PEN', 'USD'
  };
  invoicePEN;
  invoiceUSD;
  stats

  constructor(
    public invoiceService: InvoiceService,
  ) { }

  ngOnInit() {
    this.checkForInvoiceCurrency();
    this.debtorStats = this.invoiceService.calculateDebtorStatsByRUC(this.invoice, this.currency);
  }


  setCurrency($event){
    this.currency = $event;
    this.filterByCurrency();
  }

  checkForInvoiceCurrency(){
    this.invoicePEN =  this.invoice.filter(
        (inv) => (inv.currency === 'pen')
    ).length;
    this.invoiceUSD =  this.invoice.filter(
        (inv) => (inv.currency === 'usd')
    ).length;
    this.currency = this.invoicePEN > 0 && this.invoiceUSD === 0
        ? 'pen'
        :  this.invoicePEN === 0 && this.invoiceUSD > 0
        ? 'usd'
        : 'pen';
    this.checkForDisableToggle();
  }

  checkForDisableToggle(){
    this.displayToggle = (this.invoicePEN >= 1 && this.invoiceUSD >= 1) ? true : false;
  }


  filterByCurrency(){
    this.debtorStats = this.invoiceService.calculateDebtorStatsByRUC(this.invoice, this.currency);
  }

}
