<h1 class="header">Número de documento</h1>


<div class="investor-name">
    <p class="tiny tiny-title">{{ ('deposit' | toSpanish) | capitalizeFirstLetters }}</p>
    <p class="bank-account-name">
        {{ data.entity.user.names | capitalizeFirstLetters }} {{ data.entity.user.familyNames |
        capitalizeFirstLetters }}
    </p>
</div>

<div class="amount">
    <p class="tiny tiny-title">Monto</p>
    <lib-money [amount]="data.entity.amount" [currency]="data.entity.currency || 'pen'"></lib-money>
</div>

<div class="date">
    <p class="tiny tiny-title">Fecha de transferencia</p>
    <lib-chip [content]="(data.entity.createdAt | formatDate)"></lib-chip>
</div>

<div class="form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="doc-num-input">
        <app-input formControlName="documentNumber" label="Número de documento" [default]="data.documentNumber">
        </app-input>
    </div>

    <div class="button-container button-direction-column">
        <fs-ui-button [action]="'submit'" (click)="submit()" [label]="'Siguiente'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</div>