import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-detail-box',
  templateUrl: './detail-box.component.html',
  styleUrls: ['./detail-box.component.scss']
})
export class DetailBoxComponent implements OnInit {
  
  @ViewChild(MatTooltip) myTooltip;
  constructor() { }

  @Input() label;
  @Input() value;
  @Input() others;
  @Input() tooltip;
  @Input() top = 25;
  @Input() isCustomValue =false;
  @Input() longValue;
  ngOnInit(): void {   
  }

  showTooltip() {
    this.myTooltip.toggle();
  }

}
