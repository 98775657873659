import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersActions } from 'src/app/features/users/state/users.action-types';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { GAService } from 'src/app/shared/services/ga.service';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { AuthService } from '../../../../authentication/services/auth.service';
@Component({
  selector: 'app-new-signup',
  templateUrl: './new-signup.component.html',
  styleUrls: ['./new-signup.component.scss'],
})
export class NewSignupComponent implements OnInit {
  userType: string = '';
  @Output() type = new EventEmitter();
  displayTypeUserForm = true;
  urlType;
  incompleteUserId;
  constructor(
    private overlayService: OverlayService,
    private authService: AuthService,
    public gtmService: GTMService,
    private router: Router,
    private route: ActivatedRoute,
    private gaService: GAService,
    private mixpanelService: MixpanelService,
    private windowScrollService: WindowScrollService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      try {
        if (params['userType'] === 'investor') {
          this.userType = 'investor';
          this.displayTypeUserForm = false;
          this.urlType = 'investor';
        } else if (params['userType'] === 'client') {
          this.userType = 'client';
          this.displayTypeUserForm = false;
          this.urlType = 'client';
        } else {
          this.urlType = 'general';
        }

        if (
          params['utm_source'] !== null &&
          params['utm_medium'] !== null &&
          params['utm_campaign'] !== null
        ) {
          let first_params = {
            'utm_source [first touch]': params['utm_source'],
            'utm_medium [first touch]': params['utm_medium'],
            'utm_campaign [first touch]': params['utm_campaign'],
          };
          this.mixpanelService.register(first_params);
        }
      } catch (ex) {}
    });

    this.sendEventLanded();
  }

  sendEventLanded() {
    let event = {
      event: '[Platform][Landed][Pages]',
      _inputName: `Landed On Step 1 Page`,
      _inputCategory: `Signup / Step 1`,
      _inputValue: `URL Type ${this.urlType}`,
    };
    this.sendEvent(event);
  }

  eventListner() {
    console.log('this event from consumer');
  }

  userTypeEvent(type) {
    this.userType = type;
    this.type.emit(type);
    this.sendTypeUserEvent(type);
    this.sendEventLandedStepTwo(type);
    this.sendNewPageEvent('Step 3', type);

    this.authService
      .saveIncompleteRole({ _id: this.incompleteUserId, role: type })
      .subscribe();
  }

  submitForm(data) {
    data.role = this.userType;
    data.urlType = this.urlType;
    delete data.termCondition;

    if (data?.companyAddress === '' || data?.companyAddress === '-') {
      delete data.companyAddress;
    }
    if (data?.companyName === '' || data?.companyName === '-') {
      delete data.companyName;
    }

    if (data?.companyDepartment === '' || data?.companyDepartment === '-') {
      delete data.companyDepartment;
    }

    this.setEvent(this.userType);

    this.overlayService.startConfirmation(
      {
        ...data,
        phone: data.cellphone?.number?.replace(/ /g, ''),
        phoneCode: data.cellphone.dialCode,
        phoneCountry: data.cellphone.countryCode,
      },
      null,
      'resultOnly',
      'create',
      null,
      UsersActions.Saving,
      '/',
      UsersSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          newRegistration: true,
          title: 'Revisa todas tus bandejas de entrada y confirma tu correo electrónico',
          messsage: `Hemos enviado un correo de confirmación a <span class="title-bold">${data.email}</span>.`,
        },
      },
      this.trackAnalytics.bind(this)
    );
  }

  async trackAnalytics(result: any) {
    if (result) {
      const eventName = 'new_account';
      const eventData = {
        create_date: new Date().toISOString(),
        page_url: this.urlType,
        account_type: this.userType,
        is_success: true,
        error_description: null,
      };
      this.gaService.sendEvent(eventName, eventData);
      this.mixpanelService.newEvent(eventName, eventData);
      this.sendNewPageEvent(`Step 4`);
    }
  }

  setEvent(typeUser) {
    let category =
      typeUser === 'investor'
        ? 'Signup Investor / Step 3'
        : 'Signup Client / Step 3';
    let event = {
      event: '[Platform][Next][Button]',
      _inputName: `Clicked Crear Mi Cuenta In Step 3`,
      _inputCategory: category,
    };
    this.sendEvent(event);
  }

  saveForm(data) {
    let password = data.password;
    delete data['password'];
    this.authService
      .saveIncomplete({ ...data, phone: data.cellphone.e164Number })
      .subscribe((data) => {
        this.incompleteUserId = data._id;
      });
    data.password = password;
  }

  sendTypeUserEvent(type) {
    let typeUser = type === 'investor' ? 'Inversionista' : 'Empresa';
    let event = {
      event: '[Platform][Next][Button]',
      _inputName: `Clicked Cuenta ${typeUser} In Step 2`,
      _inputCategory: `Signup / Step 2`,
    };
    this.sendEvent(event);
  }

  sendEventLandedStepTwo(type) {
    let typeUser = type === 'investor' ? 'Investor' : 'Client';
    let event = {
      event: '[Platform][Landed][Pages]',
      _inputName: `Landed On Step 3 Page`,
      _inputCategory: `Signup ${typeUser} / Step 3`,
      _inputValue: `URL Type ${this.urlType}`,
    };
    this.sendEvent(event);
  }

  sendEvent(event) {
    this.gtmService.newEvent(event);
  }

  sendNewPageEvent(step, type?) {
    let typeUser;
    if (type) {
      typeUser = type === 'investor' ? 'Inv' : 'Cli';
      this.windowScrollService.sendNewEvent('Signup', `${step} ${typeUser}`);
    } else {
      this.windowScrollService.sendNewEvent('Signup', `${step}`);
    }
  }
}
