import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store, select } from '@ngrx/store';
import { take, map, tap, filter, first, finalize } from 'rxjs/operators';
import { UsersSelectors } from '../state/users.selector-types';
import { UsersActions } from '../state/users.action-types';

@Injectable({
  providedIn: 'root'
})
export class UserProfileResolver implements Resolve<boolean> {
    loading = false;

    constructor (private store: Store<AppState>) { }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<boolean> {

                return this.store
                .pipe(
                    select(UsersSelectors.userProfileLoaded()),
                    tap(_loadedProfile => {
                        if(!this.loading && !_loadedProfile) {
                            this.loading = true;
                            this.store.dispatch(UsersActions.LoadProfile());
                        }
                    }),
                    //filter(_loadedProfile => _loadedProfile), because it's not an array
                    first(),
                    finalize(() => this.loading = false)
                );
    }
}
