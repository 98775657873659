import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { InvoiceSelectors } from '../../../../invoices/state/invoices.selector-types';
import { INVOICE_STATUS } from 'src/app/features/invoices/UI/enums/INVOICE_STATUS.enum';
import { InvoiceService } from 'src/app/features/invoices/services/invoice.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddContactDialogComponent } from '../../../../my-clients/UI/organisms/add-contact-dialog/add-contact-dialog.component';
import { SuplierService } from './../../../../my-supliers/services/supliers.service';
import { ActivatedRoute } from '@angular/router';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddBankDialogComponent } from './../../../../my-supliers/UI/organisms/add-bank-dialog/add-bank-dialog.component';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { InvoicesActions } from 'src/app/features/invoices/state/invoices.action-types';
import { AddContactConfirmationComponent } from 'src/app/features/my-clients/UI/organisms/add-contact-confirmation/add-contact-confirmation.component';

@Component({
  selector: 'app-supplier-detail',
  templateUrl: './supplier-detail.component.html',
  styleUrls: ['./supplier-detail.component.scss'],
})
export class SupplierDetailComponent implements OnInit {
  supplierId;
  debtorStats;
  invoices$;
  supplierInformation;
  contactsDebtor = [];
  bankAccountSupplier = [];
  actualInvoicesStatus = [
    INVOICE_STATUS.AWAITING_COLLECTION,
    INVOICE_STATUS.COLLECTED,
  ];
  loggedInUserID;
  invoices;
  subscription;
  openModalContact;
  contactInformation;
  invoicesPaginated;

  pagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  defaultPagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };

  constructor(
    private store: Store<AppState>,
    public invoiceService: InvoiceService,
    public dialog: MatDialog,
    private supplierService: SuplierService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private windowScrollService: WindowScrollService,
    private overlayService: OverlayService
  ) {}

  ngOnInit() {
    this.store
      .select(UsersSelectors.profileProperty('_id'))
      .pipe(take(1))
      .subscribe((role) => (this.loggedInUserID = role));

    this.subscription = this.overlayService
      .getCloseEmitter()
      .subscribe((obs) => {
        if (this.openModalContact) {
          this.addContact(
            this.supplierInformation?.companyRuc,
            this.contactInformation
          );
        }
      });

    this.route.params.subscribe((params) => {
      this.supplierId = params.supplierId;
      if (this.supplierId) {
        this.spinner.show();
        this.fetchDebtor();
      }
    });
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      minWidth: '448px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: data,
    });

    return dialogRef;
  }

  fetchDebtor() {
    this.openModalContact = false;
    this.supplierService.byRuc(this.supplierId).subscribe((supplier) => {
      this.supplierInformation = supplier;
      this.fetchContact();
      this.fetchInvoice();
      this.fetchBanks();
    });
  }

  addContact(ruc, data?) {
    let dialogRef = this.openDialogHelper(AddContactDialogComponent, {
      ruc,
      supplier: this.supplierInformation._id,
      data,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.openModalContact = true;

        this.contactInformation = { ...result };
        result.supplier = this.supplierInformation._id;

        this.overlayService.startConfirmation(
          { ...result, _id: this.supplierInformation._id },
          {
            component: AddContactConfirmationComponent,
            entity: { ...result },
          },
          'details',
          'create',
          null,
          InvoicesActions.SaveContact,
          `my-suppliers/${this.supplierInformation._id}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onConfirm: {
              title: 'Confirmación datos del contacto',
            },
            onSuccess: {
              showCheck: true,
              title: 'Se agregó el contacto correctamente',
            },
          },
          this.fetchDebtor.bind(this)
        );
        // this.clientService.createContactDebtor(result).subscribe((contact) => {
        //   this.fetchDebtor();
        // });
      } else {
        this.openModalContact = true;
      }
    });
    // this.supplierService
    //   .createContactDebtor(result)
    //   .subscribe((contact) => {
    //     this.fetchDebtor();
    //   });

    this.sendEventPageView('Detail New Contact');
  }

  addBankAccount(ruc) {
    let dialogRef = this.openDialogHelper(AddBankDialogComponent, {
      ruc,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        result.debtor = this.supplierInformation._id;
        console.log(result);
        this.supplierService.createBankAccount(result).subscribe((contact) => {
          this.fetchDebtor();
        });
      }
    });
    this.sendEventPageView('Detail New Bank Account');
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  openIntercom() {
    (<any>window).Intercom('show');
  }

  fetchContact() {
    this.supplierService
      .getAllContacts(this.supplierInformation._id)
      .subscribe((data: any) => {
        this.contactsDebtor = data;
      });
  }

  fetchInvoice() {
    this.store.select(InvoiceSelectors.all).subscribe((invoiceDB) => {
      const debtorRuc = this.supplierInformation.companyRuc;
      this.invoices = invoiceDB.filter(function (item) {
        if (item['supplier']) {
          return item['supplier'].companyRuc === debtorRuc;
        }
      });

      this.pagination.total = this.invoices.length || 0;

      this.invoicesPaginated = this.invoices;

      this.invoicesPaginated = this.invoicesPaginated.slice(
        this.pagination.skip,
        this.pagination.limit + this.pagination.skip
      );

      this.spinner.hide();
    });
  }

  fetchBanks() {
    this.supplierService
      .getBanks(this.supplierInformation._id)
      .subscribe((data: any) => {
        this.bankAccountSupplier = data.bankAccounts;
      });
  }

  sendEventPageView(title) {
    this.windowScrollService.sendNewEvent('My Suppliers', title);
  }

  paginationHandler(event) {
    this.pagination.skip = event.skip;
    this.invoicesPaginated = this.invoices;
    this.invoicesPaginated = this.invoicesPaginated.slice(
      this.pagination.skip,
      this.pagination.limit + this.pagination.skip
    );
  }
}
