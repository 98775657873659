import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { UsersService } from 'src/app/features/users/services/users.service';

@Injectable({ providedIn: 'root' })
export class UniqueUserByRuclValidator {

    constructor(private usersService: UsersService) {}

    public isUserRucUnique(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            return new Promise((resolve, reject) => {

                return timer(500)
                .pipe(switchMap(() => this.usersService.rucExists(control.value)))
                .subscribe(
                    res => resolve(null),
                    err => resolve({isUserRucUnique: true})
                );

            });

        }  
      }
}
