<div class="container">
    <div class="tooltip-container-not-dots">
        <app-solid-label [content]="getFormatDate(entity.evaluationFinishedAt)"
            [background]="mapStatus(entity.status).color" [width]="'109'"></app-solid-label>

        <span class="tooltiptext-bottom"> Dictamen: {{ entity.status !== 'pending' ? (entity.status | toSpanish : 'MALE'
            | capitalizeFirstLetters) : '---' }}</span>
    </div>
    <div class="opposite-container">
        <div *ngIf="entity.comment" class="tooltip-container-not-dots comment-icon">
            <app-rounded-button-action *ngIf="entity.comment" [background]="'dark-gray'" [imgIcon]="'comments.svg'">
            </app-rounded-button-action>

            <span class="tooltiptext-bottom">{{entity.comment | capitalizeOnlyFirstLetter}}</span>
        </div>
        <div class="ac-icon">
            <div [ngClass]="entity?.invoiceNetAmount ? 'one-line-flex' : ''">
                <ng-container *ngIf="entity?.invoiceNetAmount">
                    <div class="rounded">
                        <div class="tooltip-container-not-dots">
                            <img src="../../../../../../assets/icons/person.svg" alt="" srcset="">
                            <span *ngIf="entity?.userCompanyName" class="tooltiptext-bottom">{{
                                entity?.userCompanyName | capitalizeFirstLetters}}</span>
                        </div>

                    </div>
                </ng-container>
                <div class="tooltip-container-not-dots">
                    <app-account-manager-icon [accountName]="getAccountName(entity)" [isActive]="true"
                        [isCartera]="entity.createdBy?.role == 'risk analyst'">
                    </app-account-manager-icon>
                    <span *ngIf="!(entity.createdBy?.role == 'risk analyst')"
                        class="tooltiptext-bottom">{{getAccountName(entity) | capitalizeFirstLetters}}
                    </span>
                    <span *ngIf="entity.createdBy?.role == 'risk analyst'" class="tooltiptext-bottom">Cartera</span>
                </div>
            </div>
        </div>
    </div>

</div>