<div class="allContent" *ngIf="userType == 'client'">
    <div class="leftContent">
        <app-artwork-display [display]="'weight'" [imageName]="'artwork-signup-3.svg'"
            [content]="'Haz Factoring o Confirming de manera 100% digital, rápida y sin tanto papeleo.'">
        </app-artwork-display>
        <app-artwork-client></app-artwork-client>
    </div>
    <div class="rightContent">
        <app-signup-client-step (nextStep)="forward($event)" (previousStep)="backward()"></app-signup-client-step>
    </div>
</div>
<div class="allContent" *ngIf="userType == 'investor'">
    <div class="leftContent">
        <app-artwork-display [display]="'weight'" [imageName]="'artwork-signup-4.svg'"
            [content]="'¡Diversifica e invierte en facturas negociables!'">
        </app-artwork-display>
        <app-artwork-investor></app-artwork-investor>
    </div>
    <div class="rightContent">
        <app-sigup-investor-form (nextStep)="forward($event)" (previousStep)="backward()"></app-sigup-investor-form>
    </div>
</div>

    