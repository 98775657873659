<app-page-title title="Mis Datos Personales"></app-page-title>

<div style="margin-top: 40px;">
    <span class="password-button" routerLink="change-password">
        Cambiar mi contraseña
    </span>
</div>

<div class="profile">
    <!-- CLIENT ONLY -->
    <div class="one-line mb-20" *allowedRoles="['client']">
        <div class="readyonly">
            <span class="tiny-title">Nombre de la empresa</span>
            <span class="value">{{ profile.companyName | uppercase }}</span>
        </div>
        <div class="readyonly">
            <span class="tiny-title">Departamento</span>
            <span class="value">{{ profile.companyAddress | capitalizeFirstLetters }}</span>
        </div>
    </div>

    <div class="one-line mb-20" *allowedRoles="['client']">
        <div class="readyonly">
            <span class="tiny-title">RUC</span>
            <span class="value">{{ profile.companyRuc }}</span>
        </div>
        <div class="readyonly">
            <span class="tiny-title">Dirección de la empresa</span>
            <span class="value">{{ profile.companyDepartment | capitalizeFirstLetters }}</span>
        </div>
    </div>
    <ng-container *allowedRoles="['client']">
        <div class="one-line">
            <div class="readyonly inline">
                <span class="tiny-title ft-medium">
                    Representante legal:
                    {{profile.hasOwnProperty('legalRep') ? '' : '---'}}
                    <img *ngIf="profile.hasOwnProperty('legalRep') && profile.legalRep === true"
                        src="../../../../../../assets/icons/check_circle_outline.svg" alt="" srcset="">
                    <img *ngIf="profile.hasOwnProperty('legalRep') && profile.legalRep === false"
                        src="../../../../../../assets/icons/highlight_off_black.svg" alt="" srcset="">
                </span>
            </div>
            <div class="readyonly inline"
                *ngIf="!profile.hasOwnProperty('legalRep') && !profile.hasOwnProperty('profession') ">
                <a (click)="showPopUp()" class="tiny-title ft-medium goto">Completa tu información
                    <img class="show" src="../../../../../../assets/icons/expand_more-24px.svg" alt="" srcset="">
                </a>
            </div>
        </div>
    </ng-container>
    <hr *allowedRoles="['client']" />
    <div class="one-line">
        <div class="readyonly">
            <span class="tiny-title">Nombres</span>
            <span class="value">{{ profile.names | capitalizeFirstLetters }}</span>
        </div>
        <div class="readyonly">
            <span class="tiny-title">Apellidos</span>
            <span class="value">{{ profile.familyNames | capitalizeFirstLetters }}</span>
        </div>
    </div>
    <div class="one-line" *allowedRoles="['investor']">
        <div class="readyonly">
            <span class="tiny-title">Tipo de documento identidad</span>
            <span class="value">{{ profile.idType | toSpanish : 'MALE' | capitalizeFirstLetters }}</span>
        </div>
        <div class="readyonly">
            <span class="tiny-title">Número de documento</span>
            <span class="value">{{ profile.idNumber }}</span>
        </div>
    </div>
    <div class="one-line">
        <div class="readyonly">
            <span class="tiny-title">Correo electrónico</span>
            <span class="value">{{ profile.email}}</span>
        </div>
        <div class="readyonly" *allowedRoles="['admin']">
            <span class="tiny-title">Departamento</span>
            <span class="value">{{ profile.department | capitalizeFirstLetters }}</span>
        </div>
        <div class="readyonly" *allowedRoles="['client']">
            <span class="tiny-title">Cargo</span>
            <ng-container *ngIf="profile.hasOwnProperty('profession'); else na_value">
                <span class="value" *ngIf="profile.profession !== 'otro'">
                    {{ getProfession(profile.profession) }}
                </span>
                <span class="value" *ngIf="profile.profession === 'otro'">
                    {{ profile.legalComment | titlecase}}
                </span>
            </ng-container>
        </div>
    </div>
    <div class="one-line">
        <div class="readyonly">
            <span class="tiny-title">Teléfono celular</span>
            <div style="display: flex;">
                <span class="value">{{ profile.phoneCode}}{{ profile.phone}}</span>
                <lib-edit-save *allowedRoles="['client']" style="margin-left: 10px;" [isEditOn]="false"
                    (event)="openPhoneModal({action: 'edit'})" mode="modal">
                </lib-edit-save>
            </div>
        </div>
    </div>
    <ng-container *allowedRoles="['client']">
        <app-ws-widget *ngIf="profile.whatsappStatus != 'activated' && profile.whatsappStatus != 'disabled'"
            [state]="getWSWidgetStatus(profile).state" (clickButton)="openPhoneModal($event)"
            [body]="getWSWidgetStatus(profile).body" [captionMsg]="resendTime">
        </app-ws-widget>

        <div class="whatsapp-toggle" style="display: flex; align-items: center;margin-top: -20px;"
            *ngIf="profile.whatsappStatus == 'activated' || profile.whatsappStatus == 'disabled'">
            <span style="color: #2d2d2d;font-size: 14px;font-weight: 400;">Notificaciones por Whatsapp</span>
            <mat-slide-toggle style="margin-left: 8px;" (change)="updateWhatsappStatus($event)"
                [checked]="profile.whatsappStatus === 'activated'">
            </mat-slide-toggle>
        </div>
    </ng-container>
</div>
<ng-container *ngIf="profile.idType !== 'ruc' && profile.role === 'investor'">
    <form [formGroup]="form" (ngSubmit)="updateProfile()">
        <div class="form-container">
            <div class="body">
                <h6 class="mt-20">Información tributaria</h6>
                <div class="container-flex">
                    <div>
                        <p class="question">¿Es Perú mi país de residencia fiscal?</p>
                    </div>
                    <div>
                        <app-slide-toggle [firstLabel]="'Sí'" [secondLabel]="'No'" [checked]="!isPeru"
                            (selectionChanged)="setResindecyCountry($event)">
                        </app-slide-toggle>
                    </div>

                </div>

                <!-- <h6>¿Cuál es tu cargo?</h6> -->
                <app-select *ngIf="!isPeru"
                    [defaultOption]="(profile.newTaxResidency && profile.newTaxResidency !== 'Perú') ? profile.newTaxResidency : profile.taxResidency && profile.taxResidency !== 'Perú' ? profile.taxResidency : ''"
                    formControlName="taxResidency" valueField="nameSpanish" textField="nameSpanish"
                    [options]="countries" label="País de residencia fiscal"></app-select>

                <p [ngClass]="isPeru ? 'know-more' : 'know-more margin-reduce'">Conoce más sobre las retenciones <a
                        class="hyperlink" href="https://ayuda.finsmart.pe/es/collections/2499163-inversion"
                        target="_blank" rel="noopener noreferrer">aquí.</a></p>
            </div>
        </div>

        <div class="button-container">
            <p class="message-save" *ngIf="profile.idType !== 'ruc' && profile.role === 'investor'">Al Guardar, declaro
                bajo
                juramento que la información
                es verdadera y
                actualizada, y que informaré cualquier
                cambio inmediatamente.</p>
            <fs-ui-button [isDisabled]="disabled" (click)="updateProfile()" [label]="'Guardar Cambios'"></fs-ui-button>
        </div>
    </form>
</ng-container>



<p *allowedRoles="['investor']" class="message">Si quieres modificar tus nombres, apellidos, correo electrónico, <a
        class="container-link" (click)="openIntercom()">escríbenos aquí</a> o mándanos un correo a
    soporte@finsmart.pe.</p>


<p *allowedRoles="['client']" class="message">Si quieres modificar tus nombres, apellidos, correo electrónico,
    cargo, RUC o dirección de la empresa, <a class="container-link" (click)="openIntercom()">escríbenos aquí</a> o
    mándanos un correo a
    <a class="container-link" href="mailto:soporte@finsmart.pe">soporte@finsmart.pe.</a>
</p>


<ng-template #na_value>
    <span class="value">---</span>
</ng-template>