import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RiskService } from '../../../services/risk.service';

@Component({
  selector: 'app-risk-complete-information-dialog',
  templateUrl: './risk-complete-information-dialog.component.html',
  styleUrls: ['./risk-complete-information-dialog.component.scss']
})
export class RiskCompleteInformationDialogComponent implements OnInit {

  sectorDisabled: Boolean = false;

  form;
  regimeTypes = [
    { label: 'Agente de retención', value: 'agente de retención' },
    { label: 'Buen contribuyente', value: 'buen contribuyente' },
    { label: 'Agente de percepción', value: 'agente de percepción'},
    { label: 'No padrón', value: '---'},
  ];

  sectors = ['ABOGADOS',
    'ADMINISTRACION PUBLICA',
    'AGROINDUSTRIA Y GANADERIA',
    'ALQUILERES (MAQUINARIA)',
    'AUTOMOTRIZ',
    'BEBIDAS',
    'CAUCHO Y PLASTICO',
    'COMERCIALIZADORAS',
    'CONSTRUCTORAS',
    'CONSULTORIAS Y SERVICIOS',
    'CORREOS',
    'CURTIDURIA',
    'EDITORIALES',
    'EDUCACION',
    'ENERGIA Y AGUA',
    'ESPARCIMIENTO',
    'FORESTAL Y SILVICULTURA',
    'HIDROCARBUROS',
    'IMPRESIÓN',
    'INDUSTRIA ALIMENTARIA',
    'INFORMATICA',
    'INMOBILIARIAS',
    'INTERMEDIACION FINANCIERA',
    'INVESTIGACION Y DESARROLLO',
    'LABORATORIOS',
    'METALMECANICA',
    'MINERALES NO METALICOS',
    'MINERIA',
    'ORGANIZACIONES',
    'OTROS SERVICIOS',
    'OTROS SERVICIOS',
    'PAPEL',
    'PESCA',
    'QUIMICAS',
    'RECICLAJE',
    'SALUD',
    'TELECOMUNICACIONES',
    'TEXTILES',
    'TRANSPORTE',
    'TURISMO',
    'VETERINARIAS'
  ]

  

  constructor(
    public dialogRef: MatDialogRef<RiskCompleteInformationDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service : RiskService
  ) { }

  ngOnInit(): void {
    if (this.data?.evaluation?.debtor?.sector && !this.sectors.includes(this.data?.evaluation?.debtor?.sector)) {
      this.sectorDisabled = false;
      this.data.evaluation.debtor.sector = '';
    }else if (this.data?.evaluation?.debtor?.sector) {
      this.sectorDisabled = true;
    }

    this.form = this.formBuilder.group({
      sectorFilled: ['',  [Validators.required]],
      activity: ['',  [Validators.required]],
      regimeType: ['',  [Validators.required]],
      employeeCount: ['',  [Validators.required]],
    });
  }

  onSubmit() {

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if(this.form.valid) {
      this.dialogRef.close(this.form.value)
    }
  }

  cancel() {

    this.dialogRef.close('closed');
  }
}
