<ng-container  *ngIf="(penBalance && usdBalance && isLoggedInUserAnAdmin) || !isLoggedInUserAnAdmin">
    <div class="d-flex flex-items-center u-mb-4">
        <div class="u-mr-18">
            <h3 class="text-color-dark-base" *ngIf="!isLoggedInUserAnAdmin">Saldo disponible:</h3>
            <h3 class="text-color-dark-base" *ngIf="isLoggedInUserAnAdmin">Saldo disponible del usuario:</h3>
        </div>
        <div class="d-flex flex-items-center c-pointer" routerLink="/financial-transactions/deposit">
            <img src="../../../../../assets/icons/add-plus.svg" alt="">
            <span class="body-paragraph-s-light text-color-primary-base u-ml-new">Añadir fondos</span>
        </div>
        <!-- <lib-ui-button
              variant="text"
              [fullWidth]="false"
              
              size="s"
              [label]="'Añadir fondos'"
        ></lib-ui-button> -->
    </div>
    <div class="d-flex u-mb-6 container-amount flex-wrap">
        <div class="amount">
            <app-amount *ngIf="penBalance" mode="balance" [amount]="penBalance" currency="pen"></app-amount>
        </div>
        <div class="amount">
            <app-amount *ngIf="usdBalance" mode="balance" [amount]="usdBalance" currency="usd"></app-amount>
        </div>
    </div>
</ng-container>
