import { MatDialog } from '@angular/material/dialog';
import { DisplayFileComponent } from 'src/app/shared/UI/organisms/display-file/display-file.component';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { InvoiceService } from './../../../services/invoice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter, AfterViewInit } from '@angular/core';
import { FormatDateTimePipe } from 'src/app/shared/pipes/format-date-time';

@Component({
  selector: 'app-announcement-card',
  templateUrl: './announcement-card.component.html',
  styleUrls: ['./announcement-card.component.scss'],
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class AnnouncementCardComponent implements OnInit {
  @Input() announcement;
  @Input() showActions = true;
  @Output() editEvent = new EventEmitter();
  @Output() resendEvent = new EventEmitter();

  message;

  constructor(private spinner: NgxSpinnerService,
    private service: InvoiceService,
    private overlayService: OverlayService,
    public dialog: MatDialog,
    private dateTimePipe: FormatDateTimePipe,
  ) { }

  ngOnInit(): void {
      this.message = this.announcement.updatedAt ?
      this.announcement.message + `<span class="edit">  &#8226; Editado el ${this.dateTimePipe.transform(this.announcement?.updatedAt)}</span>` :
      this.announcement.message;
  }

  showFile() {
    this.spinner.show();

    this.service.getAnnouncementFile(this.announcement.file).subscribe((voucherFile: any) => {
      if (voucherFile.file) {
        this.overlayService.showComponent(
          DisplayFileComponent,
          voucherFile.file
        );
      } else {
        window.alert('¡No se encuentra el archivo!');
      }

      this.spinner.hide();
    });
  }

  editAnnouncement() {
    this.editEvent.emit(this.announcement);
  }


  resendAnnouncement() {
    this.resendEvent.emit(this.announcement);
  }

}
