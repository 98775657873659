<div class="container">
    <div class="page-header">
        <div class="text item-flex margin-space">
            <app-solid-label *ngIf="!debtor?.isObserve" [content]="mapStatus(entity?.status).label"
                [background]="mapStatus(entity?.status).color" [width]="'109'"></app-solid-label>
    
            <app-solid-label *ngIf="debtor?.isObserve" [content]="mapStatus('observation').label"
                [background]="mapStatus('observation').color" [width]="'109'"></app-solid-label>
        </div>
        <div class="text item-flex margin-space">
            <span class="tiny-title">Rating</span>
            <span class="value">
                {{entity?.rating || '---'}}
            </span>
        </div>
        <div class="item-flex line-assing">
            <div class="text">
                <span class="tiny-title">Línea otorgada</span>
                <span class="value">
                    {{(entity?.creditLine | numberSpacing) || '---' }}
                </span>
            </div>
            <div class="icon-line ">
                <div class="icon tooltip-container-not-dots" *ngIf="entity?.creditLineType">
                    <app-rounded-button-action [text]="mapCreditLine(entity?.creditLineType).icon"
                        [background]="'black'">
                    </app-rounded-button-action>
                    <span class="tooltiptext-bottom">{{mapCreditLine(entity?.creditLineType).label}}</span>
                </div>
            </div>
        </div>
        <div class="text item-flex margin-space">
            <span class="tiny-title">Línea utilizada</span>
            <span [ngClass]="getClassRed(entity)" class="value">
                {{(entity?.usedCreditLine | numberSpacing) || '---' }}
            </span>
        </div>

        <div class="text item-flex margin-space">
            <span class="tiny-title">Monto en proceso</span>
            <span class="value" [ngClass]="getClassRedAmount(entity)">
                {{(entity?.amountInProcess | numberSpacing) || '---' }}
            </span>
        </div>
    
        <div class="text item-flex margin-space">
            <span class="tiny-title">Última evaluación</span>
            <span
                [ngClass]="{'eval-green value':(entity?.lastEvaluationDays >= 0  && entity?.lastEvaluationDays <= 60 ), 'eval-red value':(entity?.lastEvaluationDays > 60)}">
                {{entity && entity?.lastEvaluationDays >= 0 ? entity?.lastEvaluationDays + ' días' : '---'}}
            </span>
        </div>
        
    </div>
</div>