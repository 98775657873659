export const filtersOptions = [
  {
    label: 'Empresa pagadora',
    value: 'debtor_company',
    type: 'text',
    icon: 'type-text',
  },
  {
    label: 'Empresa giradora',
    value: 'supplier_company',
    type: 'text',
    icon: 'type-text',
  },
  {
    label: 'Ejecutivo',
    value: 'account_manager',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Facturas',
    value: 'invoice_code',
    type: 'value',
    icon: 'type-text',
  },
  { label: 'Duración', value: 'duration', type: 'number', icon: 'type-number' },
  {
    label: 'Monto neto',
    value: 'net_amount',
    type: 'number',
    icon: 'type-number',
  },
  {
    label: 'Operación',
    value: 'operationType',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Moneda',
    value: 'currencyType',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Proceso',
    value: 'processType',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Documentos',
    value: 'documents',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Condiciones',
    value: 'conditions',
    type: 'dropdown',
    icon: 'type-operation',
  },
  { label: 'Riesgos', value: 'risk', type: 'dropdown', icon: 'type-operation' },
  { label: 'TEA', value: 'tea', type: 'dropdown', icon: 'type-operation' },
  {
    label: 'CAVALI',
    value: 'cavali',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Garantía',
    value: 'warranty',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Estimado para hoy',
    value: 'estimatedToTransfer',
    type: 'dropdown',
    icon: 'type-operation',
  },
];

export const filtersOptionsAM = [
  {
    label: 'Empresa pagadora',
    value: 'debtor_company',
    type: 'text',
    icon: 'type-text',
  },
  {
    label: 'Empresa giradora',
    value: 'supplier_company',
    type: 'text',
    icon: 'type-text',
  },
  {
    label: 'Facturas',
    value: 'invoice_code',
    type: 'value',
    icon: 'type-text',
  },
  { label: 'Duración', value: 'duration', type: 'number', icon: 'type-number' },
  {
    label: 'Monto neto',
    value: 'net_amount',
    type: 'number',
    icon: 'type-number',
  },
  {
    label: 'Operación',
    value: 'operationType',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Moneda',
    value: 'currencyType',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Proceso',
    value: 'processType',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Documentos',
    value: 'documents',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Condiciones',
    value: 'conditions',
    type: 'dropdown',
    icon: 'type-operation',
  },
  { label: 'Riesgos', value: 'risk', type: 'dropdown', icon: 'type-operation' },
  { label: 'TEA', value: 'tea', type: 'dropdown', icon: 'type-operation' },
  {
    label: 'CAVALI',
    value: 'cavali',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Garantía',
    value: 'warranty',
    type: 'dropdown',
    icon: 'type-operation',
  },
  {
    label: 'Estimado para hoy',
    value: 'estimatedToTransfer',
    type: 'dropdown',
    icon: 'type-operation',
  },
];

export const operatorsOptios = [
  { label: 'Es', value: 'equal', type: 'text' },
  { label: 'Contiene', value: 'contains', type: 'text' },
  { label: 'No contiene', value: 'not_contains', type: 'text' },
  { label: 'Contiene', value: 'contains', type: 'value' },
  { label: 'Es', value: 'equal', type: 'value' },
  { label: 'Es', value: 'equal', type: 'number' },
  { label: 'Es menor a', value: 'less_than', type: 'number' },
  { label: 'Es mayor a', value: 'greater_than', type: 'number' },
  { label: 'Es', value: 'is_equal', type: 'dropdown' },
  { label: 'No es', value: 'not_equal', type: 'dropdown' },
];

export const operationType = [
  { label: 'Factoring', value: 'factoring' },
  { label: 'Confirming', value: 'confirming' },
];
export const processType = [
  { label: 'SUNAT', value: 'sunat' },
  { label: 'CAVALI', value: 'cavali' },
];

export const currencyType = [
  { label: 'PEN', value: 'pen' },
  { label: 'USD', value: 'usd' },
];

export const checkListStatus = [
  { label: 'Por iniciar', value: 'pending', icon: 'check-box-empty' },
  { label: 'En proceso', value: 'in process', icon: 'check-box-pending' },
  { label: 'Denegado', value: 'denied', icon: 'check-box-denied' },
  { label: 'Aprobado', value: 'approved', icon: 'check-box-approved' },
];

let arrayTest = [];
for (let index = 0; index < 100; index++) {
  arrayTest.push({
    key: index,
    companyRuc: index,
    companyName: Math.random().toString(36).substr(2, 5),
  });
}

export const companiesList = arrayTest;

let arrayAm = [];
for (let index = 0; index < 10; index++) {
  arrayAm.push({
    key: index,
    value: index,
    label: Math.random().toString(36).substr(2, 5),
  });
}

export const accountManagerList = arrayAm;
