<div class="container row">
    <div>
        <h3>Términos y condiciones</h3>
    </div>
</div>

<ng-container *ngFor="let term of $termsAndConditions | async">
    <app-terms-conditions-item 
        [entity]="term"
        (onShowFile)="showFile($event)"
        (onDelete)="deleteHandler(term._id)">
    </app-terms-conditions-item>
</ng-container>

<div class="controls">
    <fs-ui-button routerLink="create"  [label]="'Agregar Nuevo'"></fs-ui-button>

    <fs-ui-button   routerLink="/system-settings" [type]="'tertiary'" [label]="'Regresar'"></fs-ui-button>
</div>

