import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-risk-debtor-item',
  templateUrl: './risk-debtor-item.component.html',
  styleUrls: ['./risk-debtor-item.component.scss']
})
export class RiskDebtorItem implements OnInit {
  @Input() entity;
  numberCopied = new BehaviorSubject<any>(false);
  isLargeWindow
  constructor(
    private breakpointObserver: BreakpointObserver,
    private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.breakpointObserver
    .observe([Breakpoints.XLarge])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isLargeWindow = true;
      } else {
        this.isLargeWindow = false;
      }
    });
  }

  mapStatus(val) {
    switch (val) {
      case 'approved':
        return { label: "Aprobado", color: 'green' };
      case 'rejected':
        return { label: "Denegado", color: 'red' };

      case 'pending':
        return { label: "Pendiente", color: 'yellow' };

      case 'observation':
        return { label: "En observación", color: 'dark-gray' };

      default:
        return { label: "Sin evaluación", color: 'dark-gray' };
    }
  }

  mapCreditLine(val) {
    switch (val) {
      case 'line':
        return { label: 'Línea', icon: 'L' };
      case 'punctual':
        return { label: 'Puntual', icon: 'P' };
      default:
        return { label: '-', icon: '-' };
    }
  }

  mapDate(date) {
    if (date >= 0) {
      return `Hace ${date} días`
    } else {
      return 'Sin evaluación'
    }
  }

  copyToClipboard($event) {
    $event.stopPropagation();
    this.clipboard.copy(this.entity.companyRuc);
    this.numberCopied.next(true)
    setTimeout(() => {
      this.numberCopied.next(false)
    }, 1000);
  }

  formateDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  getClassRed(entity) {
    if (Number(entity?.usedCreditLine || 0) > Number(entity.lastEvaluation?.creditLine || 0)) {
      return 'eval-red'
    }
  }

  getFinalRating(rating) {
    if (rating && rating !== 'error') {
      return rating
    } else {
      return "---";
    }
  }
}
