<ng-container
    *ngIf="currency === 'pen' && ((penInInvestment$ | async) != 0 || (penAvailable$ | async) != 0 ||  (penInInvestment$ | async) != 0 || (penInInvestmentStart$ | async) != 0 || (penInEvaluation$ | async) != 0 || (penBalance$ | async) != 0 )">
    <div class="container-balance">
        <div class="amount">
            <div class="title-rensponsive">
                <span class="tiny tiny-title balance-title">Retorno neto<span class="two-dots">:</span></span>
                <app-ammount-balance class="value-responsive" mode="general" [amount]="penBalance$ | async">
                </app-ammount-balance>
            </div>
            <div class="row money-status">
                <p class="tiny tiny-title"><span class="bold">{{ penGrossBalance$ | async | numberSpacing}}</span> Bruto
                    &nbsp;|&nbsp; <span class="bold">{{
                        penTotalRetention$ |
                        async | numberSpacing}}</span> Retención</p>
            </div>
        </div>

        <hr>

        <div class="amount">
            <div class="title-rensponsive">
                <span class="tiny tiny-title balance-title">Invertido<span class="two-dots">:</span></span>
                <app-ammount-balance class="value-responsive" mode="general" [amount]="penInInvestment$ | async">
                </app-ammount-balance>
            </div>

            <div class="row money-status">
                <p class="tiny tiny-title"><span class="bold">{{ penInInvestmentStart$ | async | numberSpacing}}</span>
                    por iniciar</p>
            </div>
        </div>

        <hr>

        <div class="amount">
            <div class="title-rensponsive">
                <span class="tiny tiny-title balance-title">Disponible<span class="two-dots">:</span></span>
                <app-ammount-balance class="value-responsive" mode="general" [amount]="penAvailable$ | async">
                </app-ammount-balance>
            </div>
            <div class="row money-status">
                <p class="tiny tiny-title"><span class="bold">{{ penInEvaluation$ | async | numberSpacing}}</span> en
                    proceso</p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="currency === 'usd'">
    <div class="container-balance">
        <div class="amount">
            <div class="title-rensponsive">
                <span class="tiny tiny-title balance-title">Retorno neto<span class="two-dots">:</span></span>
                <app-ammount-balance class="value-responsive" mode="general" [amount]="usdBalance$ | async">
                </app-ammount-balance>
            </div>
            <div class="row money-status">
                <p class="tiny tiny-title"><span class="bold">{{ usdGrossBalance$ | async | numberSpacing}}</span> Bruto
                    &nbsp;|&nbsp; <span class="bold">{{
                        usdTotalRetention$ |
                        async | numberSpacing}}</span> Retención</p>
            </div>
        </div>

        <hr>

        <div class="amount">
            <div class="title-rensponsive">
                <span class="tiny tiny-title balance-title">Invertido<span class="two-dots">:</span></span>
                <app-ammount-balance class="value-responsive" mode="general" [amount]="usdInInvestment$ | async">
                </app-ammount-balance>
            </div>

            <div class="row money-status">
                <p class="tiny tiny-title"><span class="bold">{{ usdInInvestmentStart$ | async | numberSpacing}}</span>
                    por iniciar</p>
            </div>
        </div>

        <hr>

        <div class="amount">
            <div class="title-rensponsive">
                <span class="tiny tiny-title balance-title">Disponible<span class="two-dots">:</span></span>
                <app-ammount-balance class="value-responsive" mode="general" [amount]="usdAvailable$ | async">
                </app-ammount-balance>
            </div>
            <div class="row money-status">
                <p class="tiny tiny-title"><span class="bold">{{ usdInEvaluation$ | async | numberSpacing}}</span> en
                    proceso</p>
            </div>
        </div>
    </div>
</ng-container>

<!-- <ng-container *ngIf="currency === 'pen' && ((penInInvestment$ | async) != 0 || (penAvailable$ | async) != 0 ||  (penInInvestment$ | async) != 0 || (penInInvestmentStart$ | async) != 0 || (penInEvaluation$ | async) != 0 || (penBalance$ | async) != 0 )">
    <div class="container-balance">
        <div class="amount">
            <span class="tiny tiny-title">Ganancia:</span>
            <app-ammount-balance  mode="general" [amount]="penBalance$ | async"></app-ammount-balance>
        </div>
        <div class="amount">
            <span class="tiny tiny-title">Disponible:</span>
            <app-ammount-balance mode="general" [amount]="penAvailable$ | async"></app-ammount-balance>
        </div>
        <div class="amount">
            <span class="tiny tiny-title">Invertido:</span>
            <app-ammount-balance class="amount-invest" mode="general" [amount]="penInInvestment$ | async">
            </app-ammount-balance>
        </div>
    </div>

    <hr>

    <div class="row money-status">
        <p class="tiny tiny-title">{{ penInEvaluation$ | async | numberSpacing}} en proceso | {{ penInInvestmentStart$ |
            async | numberSpacing}} por iniciar</p>
    </div>
</ng-container>

<ng-container *ngIf="currency === 'usd' && ((usdInInvestment$ | async) != 0 || (usdAvailable$ | async) != 0 || (usdInInvestmentStart$ | async) != 0 || (usdInEvaluation$ | async) != 0 || (usdBalance$ | async) != 0 )">
    <div class="container-balance">
        <div class="amount">
            <span class="tiny tiny-title">Ganancia:</span>
            <app-ammount-balance mode="general" [amount]="usdBalance$ | async"></app-ammount-balance>
        </div>
        <div class="amount">
            <span class="tiny tiny-title">Disponible:</span>
            <app-ammount-balance mode="general"  direction="center" [amount]="usdAvailable$ | async"></app-ammount-balance>
        </div>
        <div class="amount invest">
    
            <span class="tiny tiny-title">Invertido:</span>
            <app-ammount-balance class="amount-invest" direction="right" mode="general" [amount]="usdInInvestment$ | async">
            </app-ammount-balance>
        </div>
    </div>
    
    <hr>
    
    <div class="row money-status">
        <p class="tiny tiny-title">{{ usdInEvaluation$ | async | numberSpacing}} en proceso | {{ usdInInvestmentStart$ |
            async | numberSpacing}} por iniciar</p>
    </div>
</ng-container> -->