import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store, select } from '@ngrx/store';
import { take, map, tap, filter, first, finalize } from 'rxjs/operators';
import { InvoiceSelectors } from '../../invoices/state/invoices.selector-types';
import { InvoicesActions } from '../../invoices/state/invoices.action-types';
import { financialTransactionsLoaded } from '../../financial-transactions/state/financial-transactions.selectors';
import { FinancialTransactionActions } from '../../financial-transactions/state/financial-transactions.action-types';

@Injectable({
  providedIn: 'root'
})
export class OperationsResolver implements Resolve<boolean> {
  operationsLoading = false;
  financialTransactionsLoading = false;

  constructor(private store: Store<AppState>) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> {

        return combineLatest(
            this.store.select(InvoiceSelectors.operationsLoaded),
          )
          .pipe(tap(
              ([_loadedOperationEntites]) => {
                  if(!_loadedOperationEntites && !this.operationsLoading) { 
                      this.operationsLoading = true;
                      this.store.dispatch(InvoicesActions.LoadAllOperations());
                  }
                 
              }),
              filter(([_loadedOperationEntites]) => _loadedOperationEntites),
              first(),
              finalize(() => this.operationsLoading = false)
          );

  }
}
