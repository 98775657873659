export enum Roles {
    ADMIN = 'admin',
    SUPER_ADMIN = 'super admin',
    INVESTOR = 'investor',
    CLIENT = 'client',
    ANONYMOUS = 'anonymous',
    RISK_ANALYST = "risk analyst",
    CLIENT_HAPPINESS = "client happiness",
    ACCOUNT_MANGER = "account manager",
    ACCOUNT_MANAGER_SUPERVISOR = "account manager supervisor",
}
