import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-roi',
  templateUrl: './roi.component.html',
  styleUrls: ['./roi.component.scss']
})
export class RoiComponent implements OnInit, OnChanges {

  @Input() amount;
  @Input() currency;
  @Input() mode: 'general' | 'balance' = "general";

  whole;
  fraction;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    let amount = '0.00';

    if(changes.amount.currentValue) {
      amount = changes.amount.currentValue;
    }
    
    const splittedAmount = amount?.toString().split('.');
    this.whole = splittedAmount[0]
    this.fraction = splittedAmount[1];

  }

  formatWholeNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  getWholeClass() {
    return `whole ${this.mode}`
  }
  getFractionClass() {
    return `fraction ${this.mode}`
  }
  getCurrencyStyle() {
    if(this.mode === 'general') {
      return 'rectangle'
    } else if (this.mode === 'balance') {
      return 'plain'
    }
  }


}
