<app-stepper [urlType]="urlType" (submit)="submitForm($event)" (saveIncomplete)="saveForm($event)">
    <app-step>
        <div class="left">
            <app-artwork-display [display]="'weight'" [imageName]="'artwork-signup-1.svg'"
                [content]="'¡Encuentra la solución financiera que más beneficie a tu negocio o a tus finanzas personales!'">
            </app-artwork-display>
            <app-artwork-found-solution>
            </app-artwork-found-solution>
        </div>
        <div class="right">
            <app-signup-general-form stepContent></app-signup-general-form>
        </div>
    </app-step>
    <app-step *ngIf="displayTypeUserForm">
        <div class="left">
            <app-artwork-display [display]="'weight'" [imageName]="'artwork-signup-2.svg'"
                [content]="'¿Listo para conseguir financiamiento o invertir?'">
            </app-artwork-display>
            <app-artwork-select-account></app-artwork-select-account>
        </div>
        <div class="right">
            <app-signup-type-user-step stepContent  (userTypeEvent)="userTypeEvent($event)"></app-signup-type-user-step>
        </div>
    </app-step>
    <app-step>
        <app-signup-get-type-form class="full" stepContent [userType]="userType"></app-signup-get-type-form>
    </app-step>

</app-stepper>