import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-email-preview-html',
  templateUrl: './email-preview-html.component.html',
  styleUrls: ['./email-preview-html.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EmailPreviewHtmlComponent implements OnInit {
  @Input() html;

  ngOnInit(): void {
  }
}
