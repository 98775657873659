import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.states';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { SharedActions } from 'src/app/shared/state/shared.action-types';

@Component({
  selector: 'app-setup-bar',
  templateUrl: './setup-bar.component.html',
  styleUrls: ['./setup-bar.component.scss']
})
export class SetupBarComponent implements OnInit {

  @Input() titleSetupBar;
  @Input() currenStepSetupBar;
  @Input() stepsSetupBar;
  @Input() checkedByDefault;
  progress
  subtitle
  isMobile
  toggle : boolean = true;
  hideSubPages = true
  ngUnsubscribe: Subject<void> = new Subject<void>();
  userId
  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private router : Router,
    public gtmService: GTMService,

  ) { }

  ngOnInit(): void {

    this.store
    .select(UsersSelectors.profileProperty('_id'))
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((userId) => {
      if (userId) {
        this.userId = userId;
      }
    });
    
    this.calculateProgress()

    this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });

  }

  openToggle(){
    if(this.checkedByDefault){
      this.store.dispatch(SharedActions.ShowToggleMenu());
    }else{
      this.store.dispatch(SharedActions.HideToggleMenu());
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'currenStepSetupBar': {
            this.currenStepSetupBar = changes.currenStepSetupBar.currentValue;
            this.calculateProgress()
          }
        }
      }
    }
  }

  calculateProgress(){
    this.progress = (this.currenStepSetupBar / this.stepsSetupBar.length) * 100;
    this.subtitle = `${this.currenStepSetupBar} de ${this.stepsSetupBar.length}`

  }

  goToPage(url, index){
    let newIndex = index + 1;
    this.router.navigateByUrl(url);
    this.sendEvent(newIndex)
  }

  sendEvent(index){
    let event = {
      event: '[Platform][Setup][Bar]',
      _inputCategory: `General / Setup Bar`,
      _inputName: `Clicked Link ${index} In Setup Bar`,
      _userId: this.userId
    };
    this.sendGtmEvent(event);
  }

  sendGtmEvent(event){
    //console.log(event);
    this.gtmService.newEvent(event);
  }

}
