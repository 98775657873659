import { ShortenPipe } from 'src/app/shared/pipes/shorten.pipe';
import { CurrencyNamePipe } from 'src/app/shared/pipes/currency-name.pipe';
import { BankAccountsService } from 'src/app/features/bank-accounts/services/bank-accounts.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-configure-invoice-bank-dialog',
  templateUrl: './configure-invoice-bank-dialog.component.html',
  styleUrls: ['./configure-invoice-bank-dialog.component.scss'],
})
export class ConfigureInvoiceBankDialogComponent implements OnInit {
  form;
  title = '';
  bankAccounts;
  selectedBankAccount;
  linkToCreateAccount = environment.clientUrl + "/bank-accounts/create";

  constructor(
    public dialogRef: MatDialogRef<ConfigureInvoiceBankDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bankService: BankAccountsService,
    private shortenPipe: ShortenPipe,
    private currencyNamePipe: CurrencyNamePipe
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.form = this.formBuilder.group({
      bankAccount: ['', Validators.required],
    });

    this.bankService.getAll().subscribe((data) => {
      if (data.bankAccounts) {
        data.bankAccounts = data.bankAccounts.filter(
          (account: any) =>
            account.currency === this.data.bankAccount.currency &&
            account._id !== this.data.bankAccount._id
        );

        this.bankAccounts = data.bankAccounts.map((bankAccount: any) => ({
          ...bankAccount,
          bankSummary: `    
    ${bankAccount.name} 
    ${this.currencyNamePipe.transform(bankAccount.currency)}  
    ${this.shortenPipe.transform(bankAccount.number)}`,
        }));
      }
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      const result = {
        newBankAccount: this.form.value.bankAccount,
        bankAccount: this.data.bankAccount,
      };

      this.dialogRef.close(result);
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
