import { Component, OnInit, Input } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { TermsAndConditionsService } from "src/app/features/system-settings/services/terms-and-conditions.service";
import { OverlayService } from "src/app/shared/modules/overlay/services/overlay.service";
import { DisplayFileComponent } from "src/app/shared/UI/organisms/display-file/display-file.component";
import { InvoiceService } from "../../../services/invoice.service";

@Component({
  selector: "app-review-rates",
  templateUrl: "./review-rates.component.html",
  styleUrls: ["./review-rates.component.scss"],
})
export class ReviewRatesComponent implements OnInit {
  @Input() entity;
  documentClicked: Boolean = false;
  fileURL: string = "";

  constructor(
    private spinner: NgxSpinnerService,
    public service: InvoiceService
  ) {}

  ngOnInit(): void {
    this.service
      .downloadInvoiceDocument(this.entity._id, "summary_sheet")
      .subscribe((result) => {
        if (result) {
          const blob = new Blob([new Uint8Array(result.file.data)], {
            type: "application/pdf",
          });
          this.fileURL = window.URL.createObjectURL(blob);
          if (this.documentClicked) {
            this.spinner.hide();
            let tab = window.open();
            tab.location.href = this.fileURL;
          }
        }
      });
  }

  openConditionsDocument() {
    this.spinner.show();
    if (this.fileURL == "") {
      this.documentClicked = true;
    } else {
      this.spinner.hide();
      let tab = window.open();
      tab.location.href = this.fileURL;
    }
  }
}
