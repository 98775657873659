import { HotjarService } from 'src/app/shared/services/hotjar.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MixpanelService } from './../../../../../shared/services/mixpanel.service';
import { Toaster } from 'ngx-toast-notifications';
import { LoadProfile } from './../../../state/users.actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { PhoneConfirmationModalComponent } from './../../../../../shared/UI/molecules/phone-confirmation-modal/phone-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { UsersSelectors } from '../../../state/users.selector-types';
import { takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { UsersActions } from '../../../state/users.action-types';
import { RequiredValidator } from 'src/app/shared/validators/required.validator';
import { NumbersOnlyValidator } from 'src/app/shared/validators/numbers-only.validators';
import { UsersService } from '../../../services/users.service';
import { FormService } from 'src/app/shared/services/form.service';
import moment from 'moment';

@Component({
  selector: 'app-user-profile-template',
  templateUrl: './user-profile-template.component.html',
  styleUrls: ['./user-profile-template.component.scss'],
})
export class UserProfileTemplateComponent implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  form;
  profile;
  countries;
  isPeru = true;
  disabled = true;
  professionList = [
    { label: 'Gerente General', value: 'gerente general' },
    { label: 'Jefe/Gerente de Finanzas', value: 'jefe de finanzas' },
    { label: 'Jefe/Gerente de Contabilidad', value: 'jefe de contabilidad' },
    { label: 'Tesorero', value: 'tesorero' },
    { label: 'Contador', value: 'contador' },
    { label: 'Analista', value: 'analista' },
    { label: 'Asistente', value: 'asistente' },
    { label: 'Otro...', value: 'otro' },
  ];
  mixPanelStatus = "";
  private subscription: Subscription;

  resendTime = '';

  storeSelectCounter = 0;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private overlayService: OverlayService,
    private userService: UsersService,
    private service: FormService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private mixpanelService: MixpanelService,
    private router: Router,
    private hotjarService: HotjarService
  ) { }

  ngOnInit() {
    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;

          if (profile.role === Roles.INVESTOR) {
            this.getAllCountries();
            this.getTaxResidency();
          }

          if (profile.role === Roles.CLIENT && profile.hasOwnProperty('createdAt') && this.mixPanelStatus !== this.getWSWidgetStatus(profile)?.mixpanel) {
            this.mixPanelStatus = this.getWSWidgetStatus(profile).mixpanel;

            const eventData: any = {
              page_url: environment.clientUrl + this.router.url,
              page_title: "Profile settings",
              section_step: "Overview",
              whatsapp_widget_status: this.getWSWidgetStatus(profile).mixpanel,
              activated_before: this.profile.whatsappActivatedOnce || false,
            };

            this.mixpanelService.newEvent("page_view", eventData);

            this.subscription = interval(1000)
              .subscribe(() => {
                this.setResendTime();
              });
          }

        }
      });


    this.form = this.formBuilder.group({
      taxResidency: [''],
    });

    // this.form.controls['phone'].valueChanges.subscribe((value) => {
    //   if (value === this.profile?.phone) {
    //     this.disabled = true;
    //   } else {
    //     this.disabled = false;
    //   }
    // });


    if (this.profile.role === Roles.INVESTOR) {
      this.form.controls['taxResidency'].valueChanges.subscribe((value) => {
        if (
          (this.profile?.hasOwnProperty('newTaxResidency') &&
            value === this.profile?.newTaxResidency) ||
          (!this.profile.hasOwnProperty('newTaxResidency') &&
            value === this.profile?.taxResidency)
        ) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }

      });
    }


  }

  getTaxResidency() {
    if (
      (!this.profile?.hasOwnProperty('newTaxResidency') &&
        this.profile?.taxResidency !== 'Perú') ||
      (this.profile?.hasOwnProperty('newTaxResidency') &&
        this.profile?.newTaxResidency !== 'Perú')
    ) {
      this.isPeru = false;
    } else {
      this.isPeru = true;
    }
  }

  updateProfile() {
    this.form.markAllAsTouched();

    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.valid) {
      if (this.isPeru && this.profile.role == "investor") {
        this.form.controls['taxResidency'].setValue('Perú');
      }

      this.overlayService.startConfirmation(
        this.form.value,
        null, //details view
        'resultOnly',
        'editOwn',
        null, //disclaimer message
        UsersActions.SavingProfile,
        '/profile-settings',
        UsersSelectors.savingProfileSuccessState
      );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscription?.unsubscribe()
  }

  getProfession(professionName) {
    return this.professionList
      .filter((proffession) => proffession.value === professionName)
      .map((item) => {
        return item.label;
      });
  }

  showPopUp() {
    this.userService.sendClickEvent();
  }

  openIntercom() {
    (<any>window).Intercom('show');
  }

  getAllCountries() {
    this.service.getAllCountries().subscribe((data: any) => {
      this.countries = data;
    });
  }

  setResindecyCountry(option) {
    this.isPeru = !this.isPeru;
    this.disabled = false;

    if (!this.isPeru) {
      this.form.get('taxResidency').setValidators([Validators.required]);
      this.form.get('taxResidency').updateValueAndValidity();
    } else {
      //this.form.controls['taxResidency'].value = 'Perú';
      this.form.get('taxResidency').setValidators([]);
      this.form.get('taxResidency').updateValueAndValidity();
    }

    this.getDisableState();
  }

  getDisableState() {
    if (
      (this.profile.hasOwnProperty('newTaxResidency') &&
        this.profile.newTaxResidency === this.form.get('taxResidency').value &&
        !(
          (this.profile.newTaxResidency !== 'Perú' ||
            this.profile.taxResidency !== 'Perú') &&
          this.isPeru
        )) ||
      (!this.profile.hasOwnProperty('newTaxResidency') &&
        this.profile.taxResidency === this.form.get('taxResidency').value &&
        !(
          (this.profile.newTaxResidency !== 'Perú' ||
            this.profile.taxResidency !== 'Perú') &&
          this.isPeru
        ))
    ) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  openPhoneModal(event: any) {
    if (event.action == 'activate') {
      const phoneDialog = this.dialog.open(PhoneConfirmationModalComponent, {
        maxWidth: "720px",
        height: "auto",
        autoFocus: false,
        disableClose: true,
        panelClass: 'custom-modalbox',
        data: {
          user: this.profile
        }
      });

      const eventData: any = {
        page_url: environment.clientUrl + this.router.url,
        origin: "Profile settings - overview",
        activated_before: this.profile.whatsappActivatedOnce || false,
      };

      this.mixpanelService.newEvent("Open whatsapp modal", eventData);
    } else if (event.action == 'edit') {
      const phoneDialog = this.dialog.open(PhoneConfirmationModalComponent, {
        maxWidth: "720px",
        height: "auto",
        autoFocus: false,
        disableClose: true,
        panelClass: 'custom-modalbox',
        data: {
          mode: 'edit',
          user: this.profile
        }
      });

      phoneDialog.afterClosed().subscribe(result => {
        if (result.edit) {
          this.toaster.open({
            caption: 'Cambiaste tu número de celular',
            text: 'Se guardó tu nuevo número correctamente',
            duration: 4000,
            autoClose: true,
            position: 'top-right',
            type: 'success',
            preventDuplicates: true
          });
        }
      })
    } else if (event.action == 'resend') {
      this.spinner.show();

      this.userService.verifyNumber(
        {
          phone: this.profile.phone,
          phoneCode: this.profile.phoneCode,
          phoneCountry: this.profile.phoneCountry,
          whatsappStatus: 'resent'
        }
      ).subscribe((res: any) => {
        if (res.sent) {
          this.store.dispatch(LoadProfile());

          const phoneDialog = this.dialog.open(PhoneConfirmationModalComponent, {
            maxWidth: "720px",
            height: "auto",
            autoFocus: false,
            disableClose: true,
            panelClass: 'custom-modalbox',
            data: {
              mode: 'result',
              user: this.profile
            }
          });
        } else {
          this.toaster.open({
            caption: 'Error',
            text: res.errorCode === 131009 ? 'El número proporcionado no es válido o Whatsapp no está activado.' : 'Hubo un error, intentarlo más tarde',
            autoClose: false,
            position: 'top-right',
            type: 'danger',
            preventDuplicates: true
          });
        }

        this.spinner.hide();
      });
    }
  }

  getWSWidgetStatus(profile: any) {
    switch (profile.whatsappStatus) {
      case 'pending':
        return {
          state: 'activated',
          body: "Para recibir notificaciones, <span class='body-paragraph-xs-regular'>haz clic en “Sí”</span> en el mensaje que te enviamos por WhatsApp.",
          mixpanel: 'limbo(1st)'
        };
      case 'resent':
        if (profile.whatsappActivationResentAt && moment.utc().diff(moment.utc(profile.whatsappActivationResentAt), 'minutes') < 10) {
          return {
            state: 'blocked',
            body: "Para recibir notificaciones, <span class='body-paragraph-xs-regular'>haz clic en “Sí”</span> en el mensaje que te enviamos por WhatsApp.",
            mixpanel: 'limbo(2st)'
          };
        } else {
          return {
            state: 'activated',
            body: "Para recibir notificaciones, <span class='body-paragraph-xs-regular'>haz clic en “Sí”</span> en el mensaje que te enviamos por WhatsApp.",
            mixpanel: 'limbo(1st)'
          };
        }
      case 'activated':
        return {
          state: '',
          body: "",
          mixpanel: 'none'
        };
      default:
        return {
          state: 'default',
          body: 'Activa las notificaciones por Whatsapp y entérate en tiempo real sobre lo que falta para financiar tus facturas.',
          mixpanel: '0-state'
        };
    }

  }

  setResendTime() {
    if (this.profile?.whatsappActivationResentAt) {
      const finalTime = moment.utc(this.profile.whatsappActivationResentAt).add(10, 'minutes');
      const duration = moment.duration(finalTime.diff(moment.utc()))

      const minutes = duration.minutes() > 0 ? String(duration.minutes()).padStart(2, '0') : '00';
      const seconds = duration.seconds() > 0 ? String(duration.seconds()).padStart(2, '0') : '00';

      this.resendTime = `Vuelve a enviar en ${minutes}:${seconds}`
    }
  }

  updateWhatsappStatus(event: any) {
    this.userService.verifyNumber({ whatsappStatus: event.checked ? 'activated' : 'disabled' }).subscribe((res) => {
      if (res) {
        this.toaster.open({
          text: event.checked ? 'Se enviarán mensajes por Whatsapp' : 'Ya no se enviarán mensajes por Whatsapp',
          caption: event.checked ? 'Prendiste las notificaciones' : 'Apagaste las notificaciones',
          duration: 4000,
          autoClose: true,
          position: 'top-right',
          type: 'success',
          preventDuplicates: true
        });

        this.store.dispatch(LoadProfile());

        if (!event.checked) {
          this.hotjarService.newEvent("deactivate_whatsapp_toggle");
        }

      }
    });

  }
}
