import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cavali-response-dialog',
  templateUrl: './cavali-response-dialog.component.html',
  styleUrls: ['./cavali-response-dialog.component.scss']
})
export class CavaliResponseDialogComponent implements OnInit {

  iconClass="upload-icon"
  iconClassWithdraw = "download-off"
  action
  constructor(
    public dialogRef: MatDialogRef<CavaliResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    // console.log('data', this.data);
    
  }

  cancel() {
    this.dialogRef.close();
  }

  uploadToCavali(){
    this.data.action = 'upload'
    this.dialogRef.close(this.data);
  }

  removeFromCavali(){
    this.data.action = 'remove'
    this.dialogRef.close(this.data);
  }

}
