<h5>Evaluaciones pendientes</h5>
<div class="code-table matrix-table">
  <table>
    <tr>
      <th></th>
      <th>Empresa pagadora</th>
      <th>Puntaje parcial</th>
      <th>Última evaluación</th>
      <th>Fecha de creación</th>
      <th></th>
    </tr>
    <ng-container *ngIf="evaluations.length > 0">
      <tr *ngFor="let eval of evaluations">
        <td>
          <div [ngClass]="eval?.invoiceNetAmount ? 'one-line-flex' : ''">
            <ng-container *ngIf="eval?.invoiceNetAmount">
              <div class="rounded">
                <div class="tooltip-container-not-dots">
                  <img
                    src="../../../../../../assets/icons/person.svg"
                    alt=""
                    srcset=""
                  />
                  <span
                    *ngIf="eval?.userCompanyName"
                    class="tooltiptext-bottom"
                    >{{ eval?.userCompanyName | capitalizeFirstLetters }}</span
                  >
                </div>
              </div>
            </ng-container>
            <div class="tooltip-container-not-dots">
              <app-account-manager-icon
                [accountName]="getAccountName(eval)"
                [isActive]="true"
                [isCartera]="eval.createdBy.role == 'risk analyst'"
              >
              </app-account-manager-icon>
              <span
                *ngIf="!(eval.createdBy.role == 'risk analyst')"
                class="tooltiptext-bottom"
                >{{ getAccountName(eval) | capitalizeFirstLetters }}</span
              >
              <span
                *ngIf="eval.createdBy.role == 'risk analyst'"
                class="tooltiptext-bottom"
                >Cartera</span
              >
            </div>
          </div>
        </td>

        <td>
          <div class="tooltip-container-not-dots">
            {{ eval.debtor.companyName | limitTo : "30" | uppercase }}
            <span class="tooltiptext-bottom"
              >RUC: {{ eval.debtor.companyRuc }}</span
            >
          </div>
        </td>
        <td>{{ eval.totalValue }}%</td>
        <td>
          <ng-container *ngIf="eval.lastEvaluationDate && eval.lastStatus">
            <div class="tooltip-container-not-dots">
              <app-last-evaluation
                [date]="eval.lastEvaluationDate"
                [status]="eval.lastStatus"
              >
              </app-last-evaluation>

              <span class="tooltiptext-bottom"
                >{{ formateDate(eval.lastEvaluationDate) }} <br />
                Dictamen:
                <b>{{
                  eval.lastStatus | toSpanish : "MALE" | capitalizeFirstLetters
                }}</b></span
              >
            </div>
          </ng-container>

          <ng-container
            *ngIf="!eval.lastEvaluationDate && !eval.lastEvaluationStatus"
          >
            <app-last-evaluation
              [date]="eval.lastEvaluationDate"
              [status]="eval.lastEvaluationStatus"
            >
            </app-last-evaluation>
          </ng-container>
        </td>
        <td>
          {{ formateDateShort(eval.createdAt) }}
        </td>
        <td>
          <div (click)="evaluate(eval)" class="evaluateButton">E</div>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
