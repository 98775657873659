import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-artwork-display',
  templateUrl: './artwork-display.component.html',
  styleUrls: ['./artwork-display.component.scss'],
})
export class ArtworkDisplayComponent implements OnInit {
  @Input() imageName;
  @Input() content;
  @Input() display: 'normal' | 'bold' = 'normal';

  constructor() {}

  ngOnInit(): void {}
}
