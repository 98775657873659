import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmat-set-conditions',
  templateUrl: './confirmat-set-conditions.component.html',
  styleUrls: ['./confirmat-set-conditions.component.scss']
})
export class ConfirmatSetConditionsComponent implements OnInit {

  @Input() entity;

  constructor() { }

  ngOnInit(): void {
  }

}
