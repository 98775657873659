import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { balanceAvailable, balanceAvailableByUserId } from 'src/app/features/financial-transactions/state/financial-transactions.selectors';
import { bankAccounts } from 'src/app/features/bank-accounts/state/bank-accounts.selectors';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { FinancialtransactionsService } from 'src/app/features/financial-transactions/services/financial-transactions.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-available-balance',
  templateUrl: './available-balance.component.html',
  styleUrls: ['./available-balance.component.scss']
})
export class AvailableBalanceComponent implements OnInit, OnChanges, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() forUserId;
  isLoggedInUserAnAdmin: boolean;
  penBalance;
  usdBalance;

  constructor(
    private store: Store<AppState>,
    private service: FinancialtransactionsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.store.select(UsersSelectors.profileProperty('role')).pipe(
      (takeUntil(this.ngUnsubscribe))
    ).subscribe(loggedUserRole => {
      if (loggedUserRole) {
        this.isLoggedInUserAnAdmin = loggedUserRole === Roles.ADMIN || loggedUserRole === Roles.SUPER_ADMIN || loggedUserRole === Roles.CLIENT_HAPPINESS ? true : false;
      }
    });

    if (!this.isLoggedInUserAnAdmin) {
      this.store.select(balanceAvailable('pen')).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((balance) => {
          this.penBalance = balance.toString();
        });
      this.store.select(balanceAvailable('usd')).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((balance) => {
          this.usdBalance = balance.toString();
        });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.forUserId && changes.forUserId.currentValue && this.isLoggedInUserAnAdmin) {
      this.spinner.show();

      this.penBalance = undefined;
      this.usdBalance = undefined;

      this.service.getInvestorAvailableBalance(changes.forUserId.currentValue).subscribe(balance => {
        this.penBalance = balance.pen.toString();
        this.usdBalance = balance.usd.toString();

        this.spinner.hide();
      });
    }
  }

  formatWholeNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
