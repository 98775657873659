import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-display-file',
  templateUrl: './display-file.component.html',
  styleUrls: ['./display-file.component.scss']
})
export class DisplayFileComponent implements OnInit {
  @Input() payload;
  src;
  extention;

  constructor() { }

  ngOnInit() {
    this.extention = this.payload.substring(this.payload.indexOf("/") + 1, this.payload.indexOf(';'))
    this.src = this.payload;
  }

}
