import { createSelector } from '@ngrx/store';
import { Roles } from 'src/app/shared/enums/Roles.enum';


export const profile = () => createSelector(
    state => state["users"],
    (state) => state.profile
);

export const isAllowed = () => createSelector(
    state => state["users"]["profile"],
    (profile) => {
        if (profile) {
            return (profile.role === Roles.ADMIN || profile.role === Roles.SUPER_ADMIN) || (profile.role === Roles.RISK_ANALYST) || (profile.role === Roles.CLIENT_HAPPINESS)
        }
    }
);



export const profileProperty = (property) => createSelector(
    state => state["users"]["profile"],
    (userProfile) => {
        if (userProfile) return userProfile[property];
    }
);

export const userProfileLoaded = () => createSelector(
    state => state["users"],
    (state) => state.loadedProfile
);

export const usersLoaded = () => createSelector(
    state => state["users"],
    (state) => state.loaded
);
export const selectUsers = createSelector(
    state => state["users"],
    (state) => state.entities
);
export const selectUserById = (id) => createSelector(
    selectUsers,
    (items) => {
        return items.filter(user => user._id === id)[0]
    }
);
export const selectUsersByRole = (roleName) => createSelector(
    selectUsers,
    (items) => {
        if (items) {
            return items.filter(user => user.role === roleName)
        } else {
            return null;
        }
    }
);
export const savingSuccessStateById = (savingEntityId) => createSelector(
    state => {
        return {
            saving: state['users']['saving'],
            savingEntityId: state['users']['savingEntityId'],
            savingSucceeded: state['users']['savingSucceeded'],
            lastErrorMessage: state['users']['lastErrorMessage'],
            lastErrorStatus: state['users']['lastErrorStatus']
        }
    },
    (savingState) => {
        if (savingEntityId === savingState.savingEntityId && savingState.saving === false) {
            return {
                savingSucceeded: savingState.savingSucceeded,
                lastErrorMessage: savingState.lastErrorMessage,
                lastErrorStatus: savingState.lastErrorStatus
            }

        }
    }
);
export const savingProfileSuccessState = () => createSelector(
    state => {
        return {
            saving: state['users']['savingProfile'],
            savingSucceeded: state['users']['savingProfileSucceeded'],
            lastErrorMessage: state['users']['lastErrorMessage'],
            lastErrorStatus: state['users']['lastErrorStatus']
        }
    },
    (savingState) => {
        console.log(savingState.saving)
        if (savingState.saving === false) {
            return {
                savingSucceeded: savingState.savingSucceeded,
                lastErrorMessage: savingState.lastErrorMessage,
                lastErrorStatus: savingState.lastErrorStatus
            };
        }
    }
);
