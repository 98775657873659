import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-announcement-dialog',
  templateUrl: './add-announcement-dialog.component.html',
  styleUrls: ['./add-announcement-dialog.component.scss'],
})
export class AddAnnouncementDialogComponent implements OnInit {
  form;
  fileName;
  message;
  originalMessage;
  mode;
  hasFileChanged = false;
  isMessageChanges = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    translate: 'no',
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Comentario...',
    defaultParagraphSeparator: 'p',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    sanitize: false,
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo'],
      ['toggleEditorMode'],
    ],
    // customClasses: [
    //   {
    //     name: 'bold',
    //     class: 'bold',
    //     tag: 'b',
    //   },
    // ],
  };

  constructor(
    public dialogRef: MatDialogRef<AddAnnouncementDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data?.announcementDetail?.message) {
      this.message = this.data.announcementDetail.message;
    }
    if (this.data?.announcementDetail?.filename) {
      this.fileName = this.data.announcementDetail.filename;
    }

    if (this.data?.mode) {
      this.mode = this.data.mode;
    }

    this.form = this.formBuilder.group({
      message: ['', [Validators.required]],
      file: [''],
    });

    this.originalMessage = _.cloneDeep(this.data);
    if (!localStorage.getItem('originalMessage') && this.mode === 'edit') {
      localStorage.setItem(
        'originalMessage',
        this.originalMessage.announcementDetail.message
      );
      localStorage.setItem(
        'originalFileName',
        this.originalMessage.announcementDetail.filename
      );
    }
  }

  onSubmit() {
    if (
      this.mode === 'edit' &&
      !this.messageChanged(this.form.get('message').value) &&
      !this.fileChanged()
    ) {
      console.log('return')
      return;
    }

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      console.log('VALID', this.form.get('message').value)
      let file = this.form.get('file').value || '';
      let message = this.form.get('message').value;
      let filename = file !== '' ? this.fileName : '';

      var objForm: any = {
        file: file,
        message: message,
        filename: filename,
      };

      if (this.data?.announcementDetail?._id) {
        objForm.announcementId = this.data?.announcementDetail?._id;
        objForm.originalMessage = {
          ...this.originalMessage.announcementDetail,
        };
      }

      this.dialogRef.close(objForm);
    }else{
      console.log('invalid')
    }
  }

  cancel() {
    localStorage.removeItem('originalMessage');
    localStorage.removeItem('originalFileName');
    this.dialogRef.close({ event: 'Cancel' });
  }

  setFileName(fileName) {
    this.hasFileChanged = true;
    this.fileName = fileName;
  }

  messageChanged(value) {
    if (localStorage.getItem('originalMessage').trim() == value.trim()) {
      return false;
    } else {
      return true;
    }
  }

  fileChanged() {
    let originalFileName =
      localStorage.getItem('originalFileName') == 'null'
        ? ''
        : localStorage.getItem('originalFileName');
    let currentFileName = this.form.get('file').value ? this.fileName : '';

    if (originalFileName.trim() == currentFileName.trim()) {
      return false;
    } else {
      return true;
    }
  }
}
