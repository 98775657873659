import {
  Component,
  OnInit,
  Input,
  ViewChild,
  forwardRef,
  Optional,
  Host,
  SkipSelf,
  ChangeDetectionStrategy,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  ControlContainer,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { getDecimalSeparator } from '../../util/locale.util';

@Component({
  selector: 'app-input',
  templateUrl: './input.control.html',
  styleUrls: ['./input.control.scss'],
})
export class InputControl implements ControlValueAccessor, OnInit {
  @Input() label;
  @Input() encrypted = false;
  @Input() placeholder;
  @Input() type;
  @Input() default;
  @Input() helperText;
  amount;
  @Input() autocomplete: 'on' | 'off' = 'on';
  @Input() appearance: 'legacy' | 'outline' | 'fill' = 'legacy';
  @Input() isDisabled = false;
  _encryptedVisibility: Boolean = false;
  _value: String;
  _errorStatus: Boolean = false;
  _originalLabel;
  isControlInPendingStatus = false;
  @Input() inputError = false;
  constructor(@Optional() @Self() private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this._originalLabel = this.label;
    if (this.default) {
      this.value = this.default;
    }

    this.ngControl.control.parent.statusChanges.subscribe((formStatus) => {
      if (
        formStatus === 'INVALID' &&
        this.ngControl.errors &&
        (this.ngControl.dirty || this.ngControl.touched)
      ) {
        this._errorStatus = true;
        this.label = this.getErrorLabel();
      }
    });

    this.ngControl.statusChanges.subscribe((newStatus) => {
      if (newStatus === 'PENDING') {
        this.isControlInPendingStatus = true;
      } else {
        this.isControlInPendingStatus = false;

        this._errorStatus = newStatus === 'VALID' ? false : true;

        if (this._errorStatus) {
          this.label = this.getErrorLabel();
        } else {
          this.label = this._originalLabel;
        }
      }
    });
  }

  getErrorLabel() {
    if (this.ngControl.hasError('required')) {
      return this._originalLabel + ' (obligatorio)';
    } else if (this.ngControl.hasError('email')) {
      return this._originalLabel + ' (formato incorrecto)';
    } else if (this.ngControl.hasError('isUserEmailUnique')) {
      return this._originalLabel + ' (ya existe este correo)';
    } else if (this.ngControl.hasError('lettersOnly')) {
      return this._originalLabel + ' (solo letras y espacios)';
    } else if (this.ngControl.hasError('numbersOnly')) {
      return this._originalLabel + ' (solo números)';
    } else if (this.ngControl.hasError('minlength')) {
      return this._originalLabel + ' (muy corto)';
    } else if (this.ngControl.hasError('maxlength')) {
      return this._originalLabel + ' (muy largo)';
    } else if (this.ngControl.hasError('cciExactLength')) {
      return this._originalLabel + ' (debe tener 20 dígitos)';
    } else if (this.ngControl.hasError('isUserRucUnique')) {
      return (
        this._originalLabel + ' (Ya existe una cuenta asociada a este RUC)'
      );
    } else if (this.ngControl.hasError('money')) {
      //const separator = getDecimalSeparator();
      return this._originalLabel + ` (formato incorrecto: ejemplo 1000.55)`;
    } else if (this.ngControl.hasError('sufficientBalance')) {
      return this._originalLabel + ' (fondos insuficientes)';
    } else if (this.ngControl.hasError('invoiceSufficientBalance')) {
      return (
        this._originalLabel + ' (no puede ser mayor que el monto disponible)'
      );
    } else if (this.ngControl.hasError('mustMatch')) {
      return this._originalLabel + ' (Contraseña no coincide)';
    } else if (this.ngControl.hasError('rucExactLength')) {
      return this._originalLabel + ' (debe tener 11 dígitos)';
    } else if (this.ngControl.hasError('dniExactLength')) {
      return this._originalLabel + ' (debe tener 8 dígitos)';
    } else if (this.ngControl.hasError('ceExactLength')) {
      return this._originalLabel + ' (debe tener 9 dígitos)';
    } else if (this.ngControl.hasError('phoneExactLength')) {
      return this._originalLabel + ' (debe tener 9 dígitos)';
    } else if (this.ngControl.hasError('passportLengthRange')) {
      return this._originalLabel + ' (debe tener entre 5 y 15 caracteres)';
    } else if (this.ngControl.hasError('numberMustBeGreaterThanZero')) {
      return this._originalLabel + ` (debe ser mayor que 0)`;
    } else if (this.ngControl.hasError('minimumInvestmentAllowedisAll')) {
      return (
        this._originalLabel +
        ` (debe ser igual al monto disponible en la factura)`
      );
    } else if (this.ngControl.hasError('minimumInvestmentAllowedis33')) {
      return this._originalLabel + ` (debe ser mayor que 33 USD)`;
    } else if (this.ngControl.hasError('minimumInvestmentAllowedis100')) {
      return this._originalLabel + ` (debe ser mayor que 100 PEN)`;
    } else if (this.ngControl.hasError('passwordCriteria')) {
      return this._originalLabel + ` (contraseña no segura)`;
    } else if (this.ngControl.hasError('min')) {
      return this._originalLabel + ` (mínimo valor no permitido)`;
    } else if (this.ngControl.hasError('max')) {
      return this._originalLabel + ` (máximo valor no permitido)`;
    } else if (this.ngControl.hasError('isDuplicateNumber')) {
      return 'El código del movimiento está duplicado';
    } else if (this.ngControl.hasError('tcemMinValue')) {
      return this._originalLabel + ' (El valor debe ser mayor o igual a 1.3%)';
    } else if (this.ngControl.hasError('tcemMaxValue')) {
      return this._originalLabel + ' (El valor debe ser menor que 10%)';
    } else if (this.ngControl.hasError('teaValidation')) {
      return 'El valor debe ser menor o igual a 18%';
    } else if (this.ngControl.hasError('reservaValidation')) {
      return this._originalLabel + ' (El valor debe ser menor o igual a 20%)';
    } else if (this.ngControl.hasError('isUniqueDocument')) {
      return this._originalLabel + ' (Ya existe este documento)';
    } else {
      console.log(this.ngControl.errors);
      console.log(this.ngControl.control.parent.status);
      return this._originalLabel + ' (inválido)';
    }
  }

  getCleanErrorLabel() {
    if (this.ngControl.hasError('required')) {
      return 'obligatorio';
    } else if (this.ngControl.hasError('email')) {
      return 'formato incorrecto';
    } else if (this.ngControl.hasError('isUserEmailUnique')) {
      return 'ya existe este correo';
    } else if (this.ngControl.hasError('lettersOnly')) {
      return 'solo letras y espacios';
    } else if (this.ngControl.hasError('numbersOnly')) {
      return 'solo números';
    } else if (this.ngControl.hasError('minlength')) {
      return 'muy corto';
    } else if (this.ngControl.hasError('maxlength')) {
      return 'muy largo';
    } else if (this.ngControl.hasError('cciExactLength')) {
      return 'debe tener 20 dígitos';
    } else if (this.ngControl.hasError('isUserRucUnique')) {
      return 'ya existe una cuenta asociada a este RUC';
    } else if (this.ngControl.hasError('money')) {
      //const separator = getDecimalSeparator();
      return `formato incorrecto: ejemplo 1000.55`;
    } else if (this.ngControl.hasError('sufficientBalance')) {
      return 'fondos insuficientes';
    } else if (this.ngControl.hasError('invoiceSufficientBalance')) {
      return 'no puede ser mayor que el monto disponible';
    } else if (this.ngControl.hasError('mustMatch')) {
      return 'contraseña no coincide';
    } else if (this.ngControl.hasError('rucExactLength')) {
      return 'debe tener 11 dígitos';
    } else if (this.ngControl.hasError('dniExactLength')) {
      return 'debe tener 8 dígitos';
    } else if (this.ngControl.hasError('ceExactLength')) {
      return 'debe tener 9 dígitos';
    } else if (this.ngControl.hasError('phoneExactLength')) {
      return 'debe tener 9 dígitos';
    } else if (this.ngControl.hasError('passportLengthRange')) {
      return 'debe tener entre 5 y 15 caracteres)';
    } else if (this.ngControl.hasError('numberMustBeGreaterThanZero')) {
      return `debe ser mayor que 0`;
    } else if (this.ngControl.hasError('minimumInvestmentAllowedisAll')) {
      return (
        this._originalLabel + `debe ser igual al monto disponible en la factura`
      );
    } else if (this.ngControl.hasError('minimumInvestmentAllowedis33')) {
      return `debe ser mayor que 33 USD`;
    } else if (this.ngControl.hasError('minimumInvestmentAllowedis100')) {
      return `debe ser mayor que 100 PEN`;
    } else if (this.ngControl.hasError('passwordCriteria')) {
      return `contraseña no segura`;
    } else if (this.ngControl.hasError('min')) {
      return `mínimo valor no permitido`;
    } else if (this.ngControl.hasError('max')) {
      return `máximo valor no permitido`;
    } else if (this.ngControl.hasError('tcemMinValue')) {
      return this._originalLabel + ' (El valor debe ser mayor o igual a 1.3%)';
    } else if (this.ngControl.hasError('tcemMaxValue')) {
      return this._originalLabel + ' (El valor debe ser menor que 10%)';
    } else if (this.ngControl.hasError('teaValidation')) {
      return 'El valor debe ser menor o igual a 18%';
    } else if (this.ngControl.hasError('reservaValidation')) {
      return this._originalLabel + ' (El valor debe ser menor o igual a 20%)';
    } else if (this.ngControl.hasError('isUniqueDocument')) {
      return this._originalLabel + ' (Ya existe este documento)';
    } else {
      return 'inválido)';
    }
  }

  toggleEncrypted() {
    this._encryptedVisibility = !this._encryptedVisibility;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    if (val !== undefined) {
      this._value = val;
      this.propagateChange(this._value);
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  calculateType() {
    if (this.encrypted && !this._encryptedVisibility) {
      return 'password';
    } else if (this.type == 'number') {
      return 'number';
    } else {
      return 'text';
    }
  }
}
