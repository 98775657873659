import { JwtHelperService } from '@auth0/angular-jwt';

export const calculateTokenValidityDuration = (token, systemTime) => {
    const jwtHelper = new JwtHelperService();
    
    const expirationTimestamp = jwtHelper.getTokenExpirationDate(token).getTime();
    // console.log(`expires at: ${expirationTimestamp}`);
    const timeNow = systemTime ? new Date(systemTime).getTime() : new Date().getTime();
    // console.log(`timeNow: ${timeNow}`);

    return expirationTimestamp - timeNow;
}
