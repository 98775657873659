<div class="footer" *allowedRoles="['investor', 'client']">
    <p>© {{getYearTimeZone()}} Finsmart</p>
    <div class="menu">
        <a target="_blank" href="https://storage.googleapis.com/finsmart-websitefiles/legal/T%26C%203.2.pdf">Términos y condiciones</a>
        <span>|</span>
        <ng-container *allowedRoles="['client']">
            <a target="_blank" href="https://finsmart.pe/legal">Contrato de empresa</a>
        </ng-container>
        <ng-container *allowedRoles="['investor']">
            <a target="_blank"
                href="https://storage.googleapis.com/finsmart-websitefiles/legal/Contrato%20del%20inversionista%20v3.2.docx.pdf">Contrato
                del inversionista</a>
        </ng-container>

        <span>|</span>
        <a target="_blank" href="https://finsmart.pe/legal/riesgos-de-inversion">Riesgos de inversión</a>
    </div>
</div>