import {
  AbstractControl,
  ValidatorFn,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { EmailValidator } from '../../../../../finsmart-web/src/app/shared/validators/email.validators';


function validateEmails(emails: string) {
  return (
    emails
      .split(',')
      .map((email) => EmailValidator(<AbstractControl>{ value: email }))
      .find((_) => _ !== null) === undefined
  );
}

export function emailsValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value === '' || !control.value || validateEmails(control.value)) {
    return null;
  }
  return { email: true };
}
