import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSpacing',
})
export class NumberSpacingPipe implements PipeTransform {
  transform(value: any, noDecimal?: boolean): any {
    if (!value) {
      return '';
    }
    
    const splittedAmount = value?.toString().split('.');
    const whole = this.formatWholeNumber(splittedAmount[0]);
    const fraction = splittedAmount[1] || '00';
    return noDecimal ? whole : `${whole}.${fraction}`;
  }

  formatWholeNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
