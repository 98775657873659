<div class="container">
    <pdf-viewer *ngIf="extention === 'pdf'" [src]="src" 
    [render-text]="false"
    style="display: block;"
    [original-size]="false"
    ></pdf-viewer>

    <div class="previewed">
        <div class="image-box">
           <span class="helper"></span><img *ngIf="extention !== 'pdf'" [src]="src" />
        </div>
    </div>
</div>