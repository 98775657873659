import { AbstractControl, ValidatorFn } from '@angular/forms';
import { getDecimalSeparator } from '../util/locale.util';

export function MoneyValidator(
  MinimumPlaces: Number,
  MaximumPlaces
): ValidatorFn {
  return (control: AbstractControl) => {
    //const separator = getDecimalSeparator();
    const separator = '.';

    const pattern = new RegExp(
      '^\\s*?\\d+(\\' +
        separator +
        '\\d{' +
        MinimumPlaces +
        ',' +
        MaximumPlaces +
        '})?\\s*$'
    );

    const isValidMonetaryValue = pattern.test(control.value);

    if (!isValidMonetaryValue) {
      return { money: true };
    }
    return null;
  };
}
