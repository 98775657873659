import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { AuthSelectors } from '../../authentication/state/auth-selector-types';
import { UsersSelectors } from '../../users/state/users.selector-types';
import { take, takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(private router: Router, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile) => {
        if (profile) {
          if (
            profile.role === Roles.ADMIN ||
            profile.role === Roles.SUPER_ADMIN ||
            profile.role === Roles.CLIENT_HAPPINESS ||
            profile.role === Roles.RISK_ANALYST ||
            profile.role === Roles.ACCOUNT_MANGER ||
            profile.role === Roles.ACCOUNT_MANAGER_SUPERVISOR
          ) {
            this.router.navigateByUrl('/operations');
          } else if (profile.role === Roles.CLIENT) {
            this.router.navigateByUrl('/invoices');
          } else if (profile.role === Roles.INVESTOR) {
            this.router.navigateByUrl('/opportunities');
          } else {
            console.log('Unkown role!');
          }
        }
      });
 
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
