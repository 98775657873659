<div class="amount" [ngClass]="direction">
    <ng-container *ngIf="currency">
        <app-currency [style]="getCurrencyStyle()" [currency]="currency"></app-currency>
    </ng-container>
    <span>
        <h1 [ngClass]="[size, mode, pulsingTransition.pulsing? 'pulsing': '', color, weight]"
            (animationend)="pulsingTransition.pulsing=false">{{ formatWholeNumber(whole) }}</h1>
        <span *ngIf="fraction" [ngClass]="['fraction', mode, pulsingTransition.pulsing? 'pulsing': '', color, weight]"
            (animationend)="pulsingTransition.pulsing=false">{{ fraction }}</span>
    </span>
</div>