import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { StepComponent } from '../step/step.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, AfterContentInit {
  @ContentChildren(StepComponent) steps: QueryList<StepComponent>;
  @Output() submit = new EventEmitter();
  @Input() urlType;
  @Output() saveIncomplete = new EventEmitter();
  totalSteps;
  currentStepIndex = 1;
  formValue;

  constructor(
    public gtmService: GTMService,
    private windowScrollService: WindowScrollService
  ) {}

  ngAfterContentInit(): void {
    this.totalSteps = this.steps.length;
    this.navigation();

    this.steps.forEach((item: StepComponent, index) => {
      item.stepContent?.onNext?.subscribe((x) => {
        this.next(x);
      });
      item.stepContent?.onPrevious.subscribe((x) => {
        this.previous();
      });
    });
  }

  sendEventLanded(){
    console.log(this.urlType);
    let step = this.urlType === 'general' ? this.currentStepIndex : this.currentStepIndex + 1;
    
    let event = {
      event: '[Platform][Landed][Pages]',
      _inputName: `Landed On Step ${step} Page`,
      _inputCategory: `Signup / Step ${step}`,
      _inputValue: `URL Type ${this.urlType}`,
    };
    this.gtmService.newEvent(event);
    this.sendNewPageEvent(step)
  }

  sendNewPageEvent(step){
    this.windowScrollService.sendNewEvent('Signup', `Step ${step}`)
  }


  navigation() {
    this.steps.forEach((item: StepComponent, index: number) => {
      if (index + 1 == this.currentStepIndex) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
  }

  next(data) {
    if (this.currentStepIndex != this.totalSteps) {
      this.currentStepIndex++;
      this.navigation();
      this.setForm(data);
      let eventName = '[Platform][Next][Button]';
      if(this.currentStepIndex != 3){
        this.sendEvent(eventName);
        this.sendEventLanded()
      }
    } else {
      this.setForm(data);
      this.submit.emit(this.formValue);
    }
  }

  previous() {
    let eventName = '[Platform][Back][Button]';
    if(this.currentStepIndex != this.totalSteps){
      this.sendBackEvent(eventName);
    }
    if (this.currentStepIndex != 1) {
      this.currentStepIndex--;
      this.navigation();
    }
  }

  setForm(data) {
    if (data !== undefined) {
      if (this.formValue !== undefined) {
        let values = this.formValue;
        let newValues = Object.assign(values, data);
        this.formValue = newValues;
      } else {
        this.formValue = Object.assign({}, data);
      }

      if (this.currentStepIndex == 2) {
        this.setIncomplete(this.formValue);
      }
    }
  }

  setIncomplete(data) {
    this.saveIncomplete.emit(data);
  }

  ngOnInit(): void {}

  sendEvent(eventName) {
    let step = this.currentStepIndex;
    step -= 1;
    let event = {
      event: eventName,
      _inputName: `Clicked Siguiente In Step ${step}`,
      _inputCategory: `Signup / Step ${step}`,
    };
    this.gtmService.newEvent(event);
  }

  sendBackEvent(eventName) {
    let step = this.currentStepIndex;
    let event = {
      event: eventName,
      _inputName: `Clicked Atrás In Step ${step}`,
      _inputCategory: `Signup / Step ${step}`,
    };
    this.gtmService.newEvent(event);
  }
}
