<div class="container" *ngIf="invoice$ | async as invoice">
    <div class="item">
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny bank-label">Empresa pagadora</p>
                <p class="bank-name">{{invoice.debtorCompanyName || invoice.debtor.companyName | uppercase}}</p>    
            </div>
            <div class="status">
                <app-currency [currency]="invoice.currency"></app-currency>
            </div>
        </div>
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny bank-label">Monto a invertir</p>
                <app-amount [amount]="entity.amount"></app-amount>
            </div>
            <div class="bank-container right">
                <p class="tiny bank-label">Retorno esperado</p>
                <app-amount mode="balance" direction="right" [amount]="invoiceService.calculateROI(entity.amount, invoice.tem, invoice.minimumDuration)"></app-amount>
            </div>
        </div>
        <br>
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny bank-label">Fecha proyectada de cobro</p>
            </div>
            <div class="bank-container right">
                <app-date [date]="invoice.paymentDate"></app-date>
            </div>
        </div>
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny bank-label">Plazo</p>
            </div>
            <div class="bank-container right">
                <span class="label minimumDuration">{{invoice.minimumDuration}} DÍAS</span>
            </div>
        </div>
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny bank-label">Tasa mensual</p>
            </div>
            <div class="bank-container right">
                <span class="label tem">{{invoice.tem}}%</span>
            </div>
        </div>
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny bank-label">Tasa anualizada</p>
            </div>
            <div class="bank-container right">
                <span class="label tea">{{invoice.tea}}%</span>
            </div>
        </div>
        <div class="bank-and-currency row">
            <div>
                <p *ngIf="invoice.physicalInvoices.length === 1" class="tiny tiny-title">Código de la factura</p>
                <p *ngIf="invoice.physicalInvoices.length > 1" class="tiny tiny-title">Códigos de las facturas ({{invoice.physicalInvoices.length}})</p>
                <span class="code tiny" *ngFor="let subInvoice of invoice.physicalInvoices; let isLast = last; let index = index">
                    <ng-container *ngIf="index<4">
                        {{ subInvoice.code}}<span *ngIf="!isLast">, </span>    
                    </ng-container>
                    <ng-container *ngIf="isLast && index >= 4">
                        ...
                    </ng-container>
                </span>
            </div>
        </div>

        <div class="terms">
            <p>
                Al confirmar, señalas que conoces los riesgos y las condiciones de Inversión de acuerdo a esta 
                <ng-container *ngFor="let term of terms; let isLast = last">
                    <a (click)="showTerm(term._id)">{{ term.description }}</a>
                    <span *ngIf="!isLast">, </span>
                </ng-container>
            </p>    
        </div>        
    </div>
</div>
