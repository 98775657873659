import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statuses-label',
  templateUrl: './statuses-label.component.html',
  styleUrls: ['./statuses-label.component.scss']
})
export class StatusesLabelComponent implements OnInit {

  @Input() payerResponse = null;
  @Input() status;
  backgroundColor
  constructor() { }

  ngOnInit(): void {
    if(this.payerResponse !== null) {
      this.setPayerBackground()
    }else{
      this.setInputs()
    }
  }

  getContent() {
    let statusName;
    switch (this.status) {
      case 'in progress':
        statusName = 'Sin respuesta';
        //this.statusLabel = 'Aprobada';
        break;
      case 'error':
        statusName = 'Error al subir';
        //this.statusLabel = 'Cancelada';
        break;
      case 'loading':
        statusName = 'Procesando';
        //this.statusLabel = 'Cancelada';
        break;
      default:
        statusName = '---';
        break;
    }

    return statusName;
  }

  getPayerContent() {
    let statusName;
    switch (this.payerResponse) {
      case 'express compliance':
        statusName = 'Expresa';
        this.backgroundColor = 'green';
        break;
      case 'disagreement':
        statusName = 'Disconformidad';
        this.backgroundColor = 'red';
        break;
      case 'presumption compliance':
        statusName = 'Presunta';
        this.backgroundColor = 'yellow';
        break;
      default:
        statusName = '---';
        break;
    }

    return statusName;
  }

  setInputs() {
    switch (this.status) {
      case 'in progress':
        this.backgroundColor = 'dark-gray';
        //this.statusLabel = 'Aprobada';
        break;

      default:
        this.backgroundColor = 'dark-gray';
        break;
    }
  }

  setPayerBackground() {
    switch (this.payerResponse) {
      case 0:
        this.backgroundColor = 'green';
        //this.statusLabel = 'Aprobada';
        break;

      case 1:
        this.backgroundColor = 'red';
        //this.statusLabel = 'Aprobada';
        break;

      case 2:
          this.backgroundColor = 'yellow';
          //this.statusLabel = 'Aprobada';
          break;

      default:
        this.backgroundColor = 'dark-gray';
        break;
    }
  }
}
