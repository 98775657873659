import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-payers-confirmation',
  templateUrl: './new-payers-confirmation.component.html',
  styleUrls: ['./new-payers-confirmation.component.scss']
})
export class NewPayersConfirmationComponent implements OnInit {

  @Input() entity;
  constructor() { }

  ngOnInit(): void {
    console.log(this.entity)
  }

  getContent(payersInvalid){
    if(payersInvalid){
      let parsedRucs = "";
      for (const iterator of payersInvalid) {
        parsedRucs = iterator.companyRuc.toString()  + ", " + parsedRucs
      }
      parsedRucs = parsedRucs.replace(/,\s*$/, "");
      
      let comment = payersInvalid.length > 0 ? `${payersInvalid.length} RUCs no existen (${parsedRucs})` : `${payersInvalid.length} RUC no existe (${parsedRucs})`;
      return comment;
    }
  }

}
