import { createReducer, on } from '@ngrx/store';
import { SharedActions } from './shared.action-types';

export interface State {
  showSideMenu: boolean | null;
  showCollapsed: boolean | null;
}

export const initialState: State = {
  showSideMenu: false,
  showCollapsed: false,
};

export const _sharedReducer = createReducer(
    initialState,
    on(SharedActions.ShowSideMenu, (state, action) => {
      return {
        ...state,
        showSideMenu: true,
      };
    }),
    on(SharedActions.HideSideMenu, (state, action) => {
      return {
        ...state,
        showSideMenu: false,
      };
      
    }),
    on(SharedActions.HideToggleMenu, (state, action) => {
      return {
        ...state,
        showCollapsed: true,
      };
    }),
    on(SharedActions.ShowToggleMenu, (state, action) => {
      return {
        ...state,
        showCollapsed: false,
      };
    }),
    on(SharedActions.ResetShared, (state, action) => {
      return initialState;
    })
);

export function sharedReducer (state, action) {
  return _sharedReducer(state, action)
}