import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { SuplierService } from '../../../../my-supliers/services/supliers.service';
import { AddBankDialogComponent } from '../../../../my-supliers/UI/organisms/add-bank-dialog/add-bank-dialog.component';
import { TourService } from 'ngx-ui-tour-md-menu';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { BankAccountItemOrganism } from 'src/app/features/bank-accounts/UI/molecules/bank-account-item/bank-account-item.organism';
import { BankAccountsActions } from 'src/app/features/bank-accounts/state/bank-accounts.action-types';
import { savingSuccessStateById } from 'src/app/features/bank-accounts/state/bank-accounts.selectors';
import { BankAccountsService } from 'src/app/features/bank-accounts/services/bank-accounts.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { takeUntil } from 'rxjs/operators';
import { bankAccounts } from 'src/app/features/bank-accounts/state/bank-accounts.selectors';
import { ShortenPipe } from 'src/app/shared/pipes/shorten.pipe';
import { CurrencyNamePipe } from 'src/app/shared/pipes/currency-name.pipe';

@Component({
  selector: 'app-add-invoice-step2',
  templateUrl: './add-invoice-step2.component.html',
  styleUrls: ['./add-invoice-step2.component.scss'],
  providers: [],
})
export class AddInvoiceStep2Component implements OnInit, OnDestroy {
  @Input() processType = '';
  @Input() invoices = [];
  @Input() userId = '';
  @Input() isLoggedInUserAnAdmin: boolean;
  @Output() updateInvoices = new EventEmitter();

  form: FormGroup;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  penBankAccounts = [];
  usdBankAccounts = [];
  availableCurrency = [];
  bankAccounts = [];

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public supplierService: SuplierService,
    public tourService: TourService,
    private overlayService: OverlayService,
    private bankAccountService: BankAccountsService,
    private store: Store<AppState>,
    private shortenPipe: ShortenPipe,
    private currencyNamePipe: CurrencyNamePipe
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({});
    if (this.invoices.some((i) => i.currency === 'pen')) {
      this.availableCurrency.push('pen');
      this.form.addControl(
        'bankAccountPEN',
        new FormControl('', [Validators.required])
      );
    }

    if (this.invoices.some((i) => i.currency === 'usd')) {
      this.availableCurrency.push('usd');
      this.form.addControl(
        'bankAccountUSD',
        new FormControl('', [Validators.required])
      );
    }

    if (this.invoices?.length) {
      this.availableCurrency.forEach((currency) => {
        const invoice = this.invoices.find(
          (invoice) => invoice.currency === currency
        );

        if (invoice?.bankAccount) {
          this.form.controls[
            currency === 'usd' ? 'bankAccountUSD' : 'bankAccountPEN'
          ].setValue(invoice?.bankAccount);
        }
      });
    }

    this.form.valueChanges.subscribe((changes) => {
      if (this.form.valid) {
        this.invoices = this.invoices.map((invoice: any) => {
          invoice.bankAccount =
            invoice.currency === 'pen'
              ? this.form.controls['bankAccountPEN'].value
              : this.form.controls['bankAccountUSD'].value;

          return invoice;
        });

        this.updateInvoices.emit(this.invoices);
      }
    });

    this.getBankAccounts();
  }

  getBankAccounts() {
    if (!this.isLoggedInUserAnAdmin) {
      this.store
        .select(bankAccounts)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((bankAccounts) => {
          this.mapBankAccountsOptions(bankAccounts);
          this.filterBankAccounts();
        });
    } else {
      if (this.userId) {
        this.bankAccountService
          .getAllByUserId(this.userId)
          .subscribe((result) => {
            this.mapBankAccountsOptions(result.bankAccounts);
            this.filterBankAccounts();
          });
      }
    }

    if (
      this.availableCurrency.includes('pen') &&
      this.penBankAccounts.length === 1
    ) {
      this.form.controls['bankAccountPEN'].setValue(
        this.penBankAccounts[0]._id
      );
    }

    if (
      this.availableCurrency.includes('usd') &&
      this.usdBankAccounts.length === 1
    ) {
      this.form.controls['bankAccountUSD'].setValue(
        this.usdBankAccounts[0]._id
      );
    }
  }

  filterBankAccounts() {
    this.penBankAccounts = this.bankAccounts.filter(
      (account: any) => account.currency === 'pen'
    );
    this.usdBankAccounts = this.bankAccounts.filter(
      (account: any) => account.currency === 'usd'
    );
  }

  mapBankAccountsOptions(loadedBankAccounts: any) {
    if (loadedBankAccounts) {
      this.bankAccounts = loadedBankAccounts.map((bankAccount) => ({
        ...bankAccount,
        bankSummary: `
        ${bankAccount.name} 
        ${this.currencyNamePipe.transform(bankAccount.currency)}  
        ${this.shortenPipe.transform(bankAccount.number)}`,
      }));
    }
  }

  addBankAccount() {
    const dialogRef = this.dialog.open(AddBankDialogComponent, {
      minWidth: '448px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: {
        availableCurrency: this.availableCurrency,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.overlayService.startConfirmation(
          { ...result, user: this.userId },
          BankAccountItemOrganism,
          'details',
          'create',
          'Recuerda que la cuenta bancaria debe estar a tu nombre, si no será denegada. Revisa atentamente la información antes de confirmar, recuerda que eres responsable único de que la información brindada es la correcta.',
          BankAccountsActions.Saving,
          null,
          savingSuccessStateById,
          null,
          this.getBankAccounts.bind(this)
        );
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
