import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { take, map, tap, filter, first, finalize } from 'rxjs/operators';
import { bankAccountsLoaded } from '../../bank-accounts/state/bank-accounts.selectors';
import { financialTransactionsLoaded } from '../state/financial-transactions.selectors';
import { LoadAll as LoadAllBankAccounts } from '../../bank-accounts/state/bank-accounts.actions';
import { LoadAll as LoadAllFinancialTransactions } from '../state/financial-transactions.actions';
import { usersLoaded } from '../../users/state/users.selectors';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { LoadAll } from '../../users/state/users.actions';
import { UsersSelectors } from '../../users/state/users.selector-types';
import { InvoiceSelectors } from '../../invoices/state/invoices.selector-types';
import { InvoicesActions } from '../../invoices/state/invoices.action-types';
import { FinancialtransactionsService } from './financial-transactions.service';
import { FinancialTransactionType } from 'src/app/shared/enums/FinancialTransactionType.enum';
import { FinancialTransactionStatus } from 'src/app/shared/enums/FinancialTransactionStatus.enum';

@Injectable({
  providedIn: 'root'
})
export class FinancialTransactionsResolver {
    bankAccountsLoading = false;
    financialTransactionsLoading = false;
    invoicesLoading = false;
    usersLoading = false;

  constructor(private store: Store<AppState>, private service: FinancialtransactionsService) { }

    resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        let userRole;
        
        this.store.select(UsersSelectors.profileProperty('role')).pipe(take(1)).subscribe(loggedInUserRole => {
            userRole = loggedInUserRole;
        });

        if(userRole === Roles.ADMIN || userRole === Roles.SUPER_ADMIN) {
            return this.resolveForAdmin();
        } else if(userRole === Roles.INVESTOR) {
            return this.resolveForInvestor();
        }
        //} else if(userRole === Roles.CLIENT) {
        //    return this.resolveForClient();
        //}
    }

    resolveForInvestor() {
        return combineLatest(
            this.store.select(bankAccountsLoaded), 
            this.store.select(financialTransactionsLoaded),
        )
        .pipe(tap(
            ([_bankAccountsLoaaded, _financialTransactionsLoaded]) => {
                if(!_bankAccountsLoaaded && !this.bankAccountsLoading) { 
                    this.bankAccountsLoading = true;
                    this.store.dispatch(LoadAllBankAccounts());
                }
                if(!_financialTransactionsLoaded && !this.financialTransactionsLoading) { 
                    this.financialTransactionsLoading = true;
                    this.store.dispatch(LoadAllFinancialTransactions());
                }
            }),
            filter(([_bankAccountsLoaaded, _financialTransactionsLoaded]) => _bankAccountsLoaaded && _financialTransactionsLoaded),
            first(),
            finalize(() => this.bankAccountsLoading = this.financialTransactionsLoading = false)
        );
    }
    resolveForClient() {
        /*
        CLIENT CURRENTLY SHOULD NOT HAVE ACCESS TO TRANSACTIONS
        
        return combineLatest(
            this.store.select(bankAccountsLoaded), 
            this.store.select(financialTransactionsLoaded),
            this.store.select(usersLoaded)
        )
        .pipe(tap(
            ([_bankAccountsLoaaded, _financialTransactionsLoaded]) => {
                if(!_bankAccountsLoaaded && !this.bankAccountsLoading) { 
                    this.bankAccountsLoading = true;
                    this.store.dispatch(LoadAllBankAccounts());
                    }
                if(!_financialTransactionsLoaded && !this.financialTransactionsLoading) { 
                    this.financialTransactionsLoading = true;
                    this.store.dispatch(LoadAllFinancialTransactions());
                }
            }),
            filter(([_bankAccountsLoaaded, _financialTransactionsLoaded]) => _bankAccountsLoaaded && _financialTransactionsLoaded),
            first(),
            finalize(() => this.bankAccountsLoading = this.financialTransactionsLoading = false)
        );
        */
    }
    resolveForAdmin() {
        return of(true);
        
        return combineLatest(
            this.store.select(bankAccountsLoaded), 
            this.store.select(financialTransactionsLoaded),
            this.store.select(UsersSelectors.usersLoaded())
        )
        .pipe(tap(
            ([_bankAccountsLoaaded, _financialTransactionsLoaded, _usersLoaded]) => {
                if(!_bankAccountsLoaaded && !this.bankAccountsLoading) { 
                    this.bankAccountsLoading = true;
                    this.store.dispatch(LoadAllBankAccounts());
                }
                if(!_financialTransactionsLoaded && !this.financialTransactionsLoading) { 
                    this.financialTransactionsLoading = true;
                    this.store.dispatch(LoadAllFinancialTransactions());
                }
                if(!_usersLoaded && !this.usersLoading) { 
                    this.usersLoading = true;
                    this.store.dispatch(LoadAll());
                }
            }),
            filter(([_bankAccountsLoaaded, _financialTransactionsLoaded, _usersLoaded]) => _bankAccountsLoaaded && _financialTransactionsLoaded && _usersLoaded),
            first(),
            finalize(() => this.bankAccountsLoading = this.financialTransactionsLoading = this.usersLoading = false)
        );
    }
}