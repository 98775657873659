<h1>Pagar adelanto</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <app-date-picker [max]="today" formControlName="advancePaymentDate" label="Fecha desembolso"></app-date-picker>
    </div>

    <div class="button-container button-direction-column">
        <fs-ui-button  [action]="'submit'" (click)="onSubmit()"  [label]="'Siguiente'" [type]="'secondary'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>
