import { Toaster } from "ngx-toast-notifications";

let toast;

export const showToast = (toaster: Toaster, msg, success, autoclose?) => {
    if(toast) {
        hideToast();
        createToastHelper(toaster, msg, success, autoclose);
    } else {
        createToastHelper(toaster, msg, success, autoclose);
    }
}
export const hideToast = () => {
    if(toast) {
        toast.close();
    }
}

export const createToastHelper = (toaster: Toaster, msg, success, autoclose?) => {
    toast = toaster.open({
        text: msg || 'Hubo un error', 
        caption: success? 'Listo!' : 'Error', 
        duration: 4000,
        autoClose: autoclose || false,
        position: 'top-right',
        type: success? 'success' : 'danger',
        preventDuplicates: true
    });
}