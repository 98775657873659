import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-add-invoice-step1',
  templateUrl: './add-invoice-step1.component.html',
  styleUrls: ['./add-invoice-step1.component.scss'],
})
export class AddInvoiceStep1Component implements OnInit, OnChanges {
  @Input() operationType = '';
  @Input() invoiceGroups = [];
  @Input() invoiceErrors = [];
  @Output() goBackEvent = new EventEmitter();
  @Output() setOperationsUploadEvent = new EventEmitter();
  @Output() validation = new EventEmitter();
  allGroupsValid: boolean[] = [];
  _operationType = '';
  _invoiceGroups = [];
  _invoiceErrors = [];
  constructor() {}

  ngOnInit(): void {
    this._operationType = _.cloneDeep(this.operationType);
    this._invoiceGroups = _.cloneDeep(this.invoiceGroups);
    this._invoiceErrors = _.cloneDeep(this.invoiceErrors);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoiceGroups?.currentValue) {
      this._invoiceGroups =
        _.cloneDeep(changes.invoiceGroups?.currentValue) || [];
    }

    if (changes.invoiceErrors?.currentValue) {
      this._invoiceErrors =
        _.cloneDeep(changes.invoiceErrors?.currentValue) || [];
    }

    if (changes.operationType?.currentValue) {
      this._operationType =
        _.cloneDeep(changes.operationType?.currentValue) || [];
    }
  }

  validationGroups(data: any) {
    this.allGroupsValid[data.index] = data.isValid;
    this.allTableGroupsValidation();
  }

  removeArray(index: number) {
    this._invoiceGroups.splice(index, 1);
    this.allGroupsValid.splice(index, 1);

    this.allTableGroupsValidation();
    if (this._invoiceGroups.length === 0 && this._invoiceErrors.length === 0) {
      this.goBackEvent.emit(true);
    }
  }

  allTableGroupsValidation() {
    const isValid = this.allGroupsValid.every((isValid) => isValid);
    this.setOperationsUploadEvent.emit({
      operationType: this._operationType,
      invoiceGroups: this._invoiceGroups,
      invoiceErrors: this._invoiceErrors,
    });

    this.validation.emit(isValid);
  }

  goBack() {
    this.goBackEvent.emit(true);
  }

  removeBanner() {
    this._invoiceErrors = [];
    this.setOperationsUploadEvent.emit({
      operationType: this._operationType,
      invoiceGroups: this._invoiceGroups,
      invoiceErrors: this._invoiceErrors,
    });
  }
}
