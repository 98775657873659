<p class="subtitle-page">Este es el detalle de la nueva agrupación:</p>
<div class="container-table">
    <app-invoice-descript-operation  [entity]="entity.mainInvoice"></app-invoice-descript-operation>
</div>

<div class="container-table">
    <h1>Detalles de agrupación</h1>
    <div class="container" *ngIf="entity.mainInvoice">
        <table>
            <tr class="gray">
                <th>Fecha de cobro</th>
                <th>TCEM</th>
                <th>Reserva</th>
                <th>Reparto</th>
                <th>Correo de contacto</th>
            </tr>
            <tr>
                <td>
                    <span>{{ entity.mainInvoice.paymentDate  | formatDate }}</span>
                    <span class="value" *ngIf="!entity.mainInvoice.paymentDate">-</span>
                </td>
                <td>
                    <span *ngIf="entity.mainInvoice.tdmPercentage">{{ entity.mainInvoice.tdmPercentage }}%</span>
                    <span class="value" *ngIf="!entity.mainInvoice.tdmPercentage">-</span>
                </td>
                <td>
                    <span *ngIf="entity.mainInvoice.reservationPercentage"> {{ entity.mainInvoice.reservationPercentage }}%</span>
                    <span class="value" *ngIf="!entity.mainInvoice.reservationPercentage">-</span>

                </td>
                <td>
                    <span *ngIf="entity.mainInvoice.distributionPercentage"> 
                        {{ entity.mainInvoice.distributionPercentage }}%
                    </span>
                    <span class="value" *ngIf="!entity.mainInvoice.distributionPercentage">-</span>
                </td>
                <td>
                    <span class="value" *ngIf="entity.mainInvoice.isConfirming">{{
                        entity.mainInvoice.supplierContact?.email }}
                    </span>
                    <span class="value" *ngIf="!entity.mainInvoice.isConfirming">{{
                        entity.mainInvoice.debtorContact?.email }}
                    </span>
                </td>
            </tr>
        </table>
    </div>
</div>
<ng-container *ngIf="entity">
    <h1>Grupo de facturas ({{entity.physicalInvoices.length}})</h1>
    <app-physical-invoice-table [color]="'gray'" [entity]="entity"></app-physical-invoice-table>
</ng-container>