<div class="mt-25">
    <div class="container pl-20">
        <div class="content">
            <img class="icon" [src]="'../../../../../assets/artwork/icono-money.svg'">
            <p>Obtén una <span>tasa anualizada de hasta 18%.</span></p>    
        </div>
    
        <div class="content mt-25">
            <img class="icon" [src]="'../../../../../assets/artwork/icono-trending.svg'">
            <p>Recibe un <span>mayor retorno</span> que en <span>cajas de ahorro</span> o <span>bolsa de valores.</span></p>    
        </div>
    </div>
</div>
