<form class="mt" [formGroup]="form" (ngSubmit)="formSubmitSubject$.next()">
    <app-autocomplete *allowedRoles="['admin', 'super admin', 'client happiness']" searchFor='investor'
        (OnSelectionChanged)="selectedInvestorChanged($event)" label="Elige un inversionista" formControlName="user">
    </app-autocomplete>
    <ng-container *ngIf="investorUserId">
        <div>
            <div>
                <app-input formControlName="amount" autocomplete="off" type="number" label="Monto"></app-input>
            </div>
        </div>
        <div class="row">
            <div>
                <span class="tiny-title">Monto disponible de la factura</span>
                <lib-money [currency]="invoice?.currency || 'pen'" [amount]="availableAmount || '0.00'"></lib-money>
            </div>

            <div>
                <span class="tiny-title">Porcentaje invertido</span>
                <div class="percentage">%&nbsp;<app-amount [amount]="investedPercentage"></app-amount>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div></div>
            <div class="right">
                <span class="tiny-title">Teléfono</span>
                <span class="blue-c f-size">{{ investor?.phone || '-'}}</span>
            </div>
        </div>
        <div class="intercom-button-upload">
            <app-upload formControlName="supportFile" initialLabel="Subir sustento" loadedLabel="Sustento cargado">
            </app-upload>
        </div>
        <div class="button-container">
            <fs-ui-button (click)="formSubmitSubject$.next()" [label]="'Invertir'"></fs-ui-button>
        </div>

    </ng-container>
</form>