import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-email-confirmation-template',
  templateUrl: './email-confirmation-template.component.html',
  styleUrls: ['./email-confirmation-template.component.scss']
})
export class EmailConfirmationTemplateComponent implements OnInit {
  jwtHelper: JwtHelperService;
  confirmationToken;
  message;
  decodedToken;
  serverResponded = false;
  serverRespondedError = false;
  serverRespondedExpired = false;
  serverRespondedInvalid = false;
  title;
  userRole
  email
  token
  successConfirmation = false
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UsersService,
    private gtmService: GTMService,
    private windowScrollService: WindowScrollService

  ) {
    this.jwtHelper = new JwtHelperService();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      try {
        // console.log(this.decodedToken);
        // if(this.jwtHelper.isTokenExpired(params.token)) throw('Link expired')
        this.token = params.token
        this.decodedToken = this.jwtHelper.decodeToken(params.token);
        this.userRole = this.decodedToken.role
        this.email = this.decodedToken.email
        this.userService.confirmUserEmail(params.token, this.decodedToken._id).subscribe(
          res => {
            this.serverResponded = true;
            if (this.userRole === 'investor' || this.userRole === 'client') {
              // this.sendEvenlanded()
              // this.router.navigateByUrl(`/auth?token=${params.token}`);
              // this.title = "Cuenta en proceso de activación"
              // this.message = `Tu cuenta en Finsmart se ha creado con éxito. Nuestro equipo revisará la información enviada lo mas pronto posible y te enviaremos un e-mail cuando este activa.`;
              // }else if(this.userRole === 'client'){
              console.log(params.token);

              this.handleSuccess(params.token)
            } else {
              this.handleInvalidTokenOrStatus()
            }
          },
          err => {
            const statusCode = err.status
            console.log('err', err)
            switch (statusCode) {
              case 404:
                this.handleInvalidTokenOrStatus()
                break;

              case 401:
                this.handleExpired()
                break;

              case 304:
                if (this.userRole === 'investor' || this.userRole === 'client') {
                  //   this.serverResponded = true;
                  //   this.title = "Cuenta en proceso de activación"
                  //   this.message = `Tu cuenta en Finsmart se ha creado con éxito. Nuestro equipo revisará la información enviada lo mas pronto posible y te enviaremos un e-mail cuando este activa.`;
                  // }else if(this.userRole === 'client'){
                  this.handleSuccess(this.token)
                } else {
                  this.handleInvalidTokenOrStatus()
                }
                break;

              case 500:
                this.handleServerError()
                break;
              default:
                this.handleInvalidTokenOrStatus()
                break;
            }
          }
        );

      } catch (ex) {
        console.error(ex);
        this.handleInvalidTokenOrStatus()
        //this.setFailure();
      }
    });
  }

  handleSuccess(token) {
    this.sendEvenlanded()
    this.router.navigateByUrl(`/auth?token=${token}`);
  }

  handleExpired() {
    this.serverResponded = true
    this.serverRespondedExpired = true;
    this.title = "¡El enlace ha caducado!";
    this.sendExpiredEvent()
    this.sendEventNewPage('Signup', 'Broken Link')
  }

  handleInvalidTokenOrStatus() {
    this.serverResponded = true
    this.serverRespondedInvalid = true;
    this.title = "Hubo un error!"
    this.message = "Este enlace es incorrecto.";
  }

  resendConfirmation() {
    this.sendEventNewlink()
    this.userService.resendEmailConfirmation(this.token, this.decodedToken._id).subscribe(res => {
      this.successConfirmation = true
      this.title = "¡Enlace enviado!"
      this.sendEventNewPage('Signup', 'New Link')
    },
      err => {
        const statusCode = err.status
        console.log('err', err)
        this.sendEventNewlinkError(err)
        this.handleInvalidTokenOrStatus()
      })
  }

  handleServerError() {
    this.serverRespondedError = true
    this.serverResponded = true
    this.title = "Hubo un error!"
    this.message = "Por favor intenta más tarde.";
  }

  sendExpiredEvent() {
    let event = {
      event: "[Platform][Address][Verification]",
      _inputCategory: "Signup / Address Verification",
      _inputName: "Landed On Broken Link Page In Address Verification",
      _inputValue: this.email,

    }
    this.sendEvent(event)
  }

  sendEvenlanded() {
    let event = {
      event: "[Platform][Address][Verification]",
      _inputCategory: "Signup / Address Verification",
      _inputName: "Landed On Correo Verificado Page In Address Verification",
      _inputValue: this.email,
    }
    this.sendEvent(event)
  }

  sendEventNewlink() {
    let event = {
      event: "[Platform][Address][Verification]",
      _inputCategory: "Signup / Address Verification",
      _inputName: "Clicked Enviar Nuevo Enlace In Address Verification",
      _inputValue: this.email,

    }
    this.sendEvent(event)
  }

  sendEventNewlinkError(err) {
    let event = {
      event: "[Platform][Address][Verification][Error]",
      _inputCategory: "Signup / Address Verification",
      _inputName: "Clicked Enviar Nuevo Enlace In Address Verification Error",
      _inputValue: this.email,
      _inputError: err
    }
    this.sendEvent(event)
  }

  sendEvent(event) {
    this.gtmService.newEvent(event)
  }


  sendEventNewPage(title, section) {
    this.windowScrollService.sendNewEvent(title, section)
  }

  // setSuccess() {
  //   this.serverResponded = true;
  //   this.success = true;
  //   this.title = "Cuenta en proceso de activación"
  //   this.message = `Tu cuenta en Finsmart se ha creado con éxito. Nuestro equipo revisará la información enviada lo mas pronto posible y te enviaremos un e-mail cuando este activa.`;
  // }
  // setFailure() {
  //   this.serverResponded = true;
  //   this.success = false;
  //   this.title = "Hubo un error!"
  //   this.message = "Este enlace ha caducado o es incorrecto.";
  // }
}
