<h3>Modificar Término</h3>
<app-term-condition-form *ngIf="$termSelected | async"
  mode="edit"
  (onSubmit)="submitHandler($event)"
  [termSelected]="$termSelected | async">
</app-term-condition-form>
<div *ngIf="!$termSelected" class="not-found">
  <h1>No pudimos encontrar esta término!</h1>
</div>

<div class="button-container button-direction-column">
  <fs-ui-button routerLink="/system-settings/terms-and-conditions" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
</div>