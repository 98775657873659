import { RucService } from './../../../services/ruc.service';
import { RucExactLengthValidator } from 'src/app/shared/validators/ruc-exact-length.validator';
import { NumbersOnlyValidator } from 'src/app/shared/validators/numbers-only.validators';
import { RequiredValidator } from 'src/app/shared/validators/required.validator';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ruc-search',
  templateUrl: './ruc-search.component.html',
  styleUrls: ['./ruc-search.component.scss'],
})
export class RucSearchComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Input() type = 'debtor';
  form: FormGroup;
  iconClass = 'search-icon';
  isAlert = false;
  errorMessage = '';
  alertMessage = '';
  label = 'Ingresar RUC';
  originalLabel = 'Ingresar RUC';

  constructor(private fb: FormBuilder, private rucService: RucService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      ruc: [
        '',
        [RequiredValidator, NumbersOnlyValidator, RucExactLengthValidator(11)],
      ],
    });

    this.form.controls.ruc.statusChanges.subscribe((formStatus) => {
      if (
        formStatus === 'INVALID' &&
        this.form.controls.ruc.errors &&
        (this.form.controls.ruc.dirty || this.form.controls.ruc.touched)
      ) {
        this.label = this.getErrorLabel();
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
      } else {
        this.label = this.originalLabel;
        this.form.markAsUntouched();
      }
    });
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.iconClass = 'loader-icon';
      this.isAlert = false;
      this.errorMessage = '';
      this.alertMessage = '';

      const ruc = this.form.value.ruc;
      this.rucService.getSunatRUC(ruc).subscribe(
        (company: any) => {
          if (company.exists) {
            this.alertMessage = 'Esta Razón social ya existe';
          }

          this.iconClass = 'success-icon';
          this.onSubmit.emit(company);
        },
        (res: any) => {
          this.iconClass = 'alert-icon';
          this.isAlert = true;
          this.onSubmit.emit(null);
          this.form.controls.ruc.setErrors({ incorrect: true });
          this.label = this.originalLabel;

          if (res.error === 'OBJECT.OBJECT_DOESNT_EXIST') {
            this.errorMessage =
              'El número de RUC no existe, intenta con otro RUC.';
          } else {
            this.errorMessage = 'Algo salió mal, inténtalo en unos minutos.';
          }

          // this.errorMessage =
          //   'Se excedió el tiempo de respuesta, intenta nuevamente.';
        }
      );
    }
  }

  onKeydown(evt: any) {
    this.errorMessage = '';
    this.alertMessage = '';
    this.iconClass = 'search-icon-colored';
  }

  getErrorLabel() {
    if (this.form.controls.ruc.hasError('required')) {
      return this.originalLabel + ' (obligatorio)';
    } else if (this.form.controls.ruc.hasError('numbersOnly')) {
      return this.originalLabel + ' (solo números)';
    } else if (this.form.controls.ruc.hasError('rucExactLength')) {
      return this.originalLabel + ' (debe tener 11 dígitos)';
    }
  }
}
