<app-page-title title="Actividades pendientes">
</app-page-title>
<ng-container *ngIf="evaluations">
    <app-evaluation-init-list [evaluations]="evaluations"></app-evaluation-init-list>
    <app-paginator [totalCount]="pagination.total" [pageSize]="pagination.limit" [skip]="pagination.skip"
        (pagination)="paginationHandler($event)">
    </app-paginator>
</ng-container>


<ng-container *ngIf="evaluationsMonitoring">
    <app-montoring-active-debtors-table 
    [evaluations]="evaluationsMonitoring"
    [active]="active"
    [usedCreditLine]="usedCreditLine"
    [evaluationFinishedAt]="evaluationFinishedAt"
    (pospone)="posponeEval($event)" 
    (sortEval)="sortEvaluation($event)"></app-montoring-active-debtors-table>
    
    <app-paginator  [totalCount]="paginationMonitoring.total" [pageSize]="paginationMonitoring.limit" [skip]="paginationMonitoring.skip"
        (pagination)="paginationHandlerMonitoring($event)">
    </app-paginator>
</ng-container>

