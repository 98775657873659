<ng-container *ngIf="status === 'in progress' && payerResponse == null">
    <app-solid-label 
        width="100" 
        [background]="backgroundColor" 
        [content]="getContent()">
    </app-solid-label>
</ng-container>

<ng-container *ngIf="payerResponse != null && status === 'in progress'">
    <app-solid-label 
        width="100" 
        [background]="backgroundColor" 
        [content]="getPayerContent()">
    </app-solid-label>
</ng-container>

<ng-container *ngIf="status === 'error'">
    <app-icon-label
        width="100" 
        iconClass="info-icon" 
        color="red" 
        [content]="getContent()">
    </app-icon-label>
</ng-container>

<ng-container *ngIf="status === 'loading' && payerResponse == null">
    <app-icon-label
        width="100" 
        iconClass="loader-icon" 
        [content]="getContent()">
    </app-icon-label>
</ng-container>