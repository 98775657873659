import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Big } from 'big.js';
import _ from 'lodash';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.states';
import { InvoicesActions } from 'src/app/features/invoices/state/invoices.action-types';
import { UsersService } from 'src/app/features/users/services/users.service';
import { UsersActions } from 'src/app/features/users/state/users.action-types';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { DisclaimerModalComponent } from 'src/app/shared/UI/molecules/disclaimer-modal/disclaimer-modal.component';
import { InvoiceStatus } from 'src/app/shared/enums/InvoiceStatus.enum';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import {
  createDateOnly,
  getDaysDiff,
  getTodayDateOnly,
} from 'src/app/shared/util/date.util';
import { environment } from 'src/environments/environment';
import { InvestInInvoiceComponent } from '../../../../opportunities/UI/templates/invest-in-invoice/invest-in-invoice.component';
import { InvoiceService } from '../../../services/invoice.service';
import { InvoiceSelectors } from '../../../state/invoices.selector-types';
import b64toBlob from 'b64-to-blob';
import FileSaver from 'file-saver';

@Component({
  selector: 'app-list-invoices',
  templateUrl: './list-invoices.component.html',
  styleUrls: ['./list-invoices.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ListInvoicesComponent implements OnInit, AfterContentChecked {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  invoices$;

  filtersForm: FormGroup;
  invoices;
  statusesList: Array<String> = Object.values(InvoiceStatus).filter(
    (status) => status != 'in process'
  );
  pagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  defaultPagination = {
    total: 0,
    limit: 10,
    skip: 0,
  };
  filterDefault = {
    statuses: [
      InvoiceStatus.Approved,
      InvoiceStatus.Configured,
      InvoiceStatus['In Process'],
      InvoiceStatus['Pending Confirmation'],
    ],
    createdFrom: undefined,
    createdTo: undefined,
    issuer: undefined,
    debtor: undefined,
    invoiceCode: undefined,
    isConfirming: false,
    pagination: undefined,
  };

  filterDefaultCH = {
    statuses: [InvoiceStatus['Awaiting Collection']],
    createdFrom: undefined,
    createdTo: undefined,
    issuer: undefined,
    debtor: undefined,
    invoiceCode: undefined,
    isConfirming: false,
    pagination: undefined,
  };
  searchinType = 'client';
  selectedIssuer;
  selectedDebtor;
  invoiceType = 'factoring';
  isConfirming = false;
  role;
  filterApplyedForCH = false;
  selectedTab = 0;

  inProcessPagination = {
    total: 0,
    skip: 0,
    limit: 10,
    currentPage: 1,
    activeSort: 'progress',
    isSortAsc: true,
  };

  forCollectionPagination = {
    total: 0,
    skip: 0,
    limit: 10,
    currentPage: 1,
    activeSort: 'paymentDate',
    isSortAsc: true,
  };

  collectedPagination = {
    total: 0,
    skip: 0,
    limit: 10,
    currentPage: 1,
    activeSort: 'actualPaymentDate',
    isSortAsc: true,
  };

  disapprovedPagination = {
    total: 0,
    skip: 0,
    limit: 10,
    currentPage: 1,
    activeSort: 'hasNotification',
    isSortAsc: true,
  };
  showNewAnimationOnDownloadButton = false
  constructor(
    private overlayService: OverlayService,
    private store: Store<AppState>,
    public service: InvoiceService,
    private fb: FormBuilder,
    private router: Router,
    private _adapter: DateAdapter<any>,
    private spinner: NgxSpinnerService,
    private socketService: SocketService,
    private cdref: ChangeDetectorRef,
    private mixpanelService: MixpanelService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UsersService
  ) {}

  ngOnInit() {
    this.store
      .select(UsersSelectors.profileProperty('role'))
      .pipe(take(1))
      .subscribe((loggedInUserRole) => {
        this.role = loggedInUserRole;
        if (
          loggedInUserRole === Roles.ADMIN ||
          loggedInUserRole === Roles.SUPER_ADMIN
        ) {
          this._adapter.setLocale('es-PE');

          this.statusesList.push('pending transfer');

          const previousFilters = JSON.parse(
            localStorage.getItem('invoices_filter')
          );

          this.filtersForm = this.fb.group({
            statuses: [this.filterDefault.statuses],
            createdFrom: [this.filterDefault.createdFrom],
            createdTo: [this.filterDefault.createdTo],
            issuer: [this.filterDefault.issuer],
            debtor: [this.filterDefault.debtor],
            invoiceCode: [this.filterDefault.invoiceCode],
            isConfirming: [this.filterDefault.isConfirming],
            pagination: [this.filterDefault.pagination],
          });

          if (previousFilters) {
            if (previousFilters.pendingTransfer)
              previousFilters.statuses.push('pending transfer');

            this.filtersForm.controls['statuses'].setValue(
              previousFilters.statuses
            );
            if (previousFilters.createdFrom)
              this.filtersForm.controls['createdFrom'].setValue(
                moment(previousFilters['createdFrom'])
              );
            if (previousFilters.createdTo)
              this.filtersForm.controls['createdTo'].setValue(
                moment(previousFilters['createdTo'])
              );
            this.filtersForm.controls['debtor'].setValue(
              previousFilters.debtor_ruc
            );
            this.filtersForm.controls['invoiceCode'].setValue(
              previousFilters.invoiceCode
            );

            this.filtersForm.controls['pagination'].setValue(
              previousFilters.pagination
            );

            this.selectedDebtor = previousFilters.debtor;
            this.isConfirming =
              previousFilters.isConfirming == 'true' ? true : false;

            this.pagination = {
              total: 0,
              limit: 10,
              skip: previousFilters.pagination,
            };

            if (this.isConfirming) {
              this.invoiceType = 'confirming';
              this.filtersForm.controls['issuer'].setValue(
                previousFilters.supplier_id
              );
              this.selectedIssuer = previousFilters.supplier;
            } else {
              this.filtersForm.controls['issuer'].setValue(
                previousFilters.issuer_id
              );
              this.selectedIssuer = previousFilters.issuer;
            }
          }

          this.applyFilter(true);
        } else if (loggedInUserRole === Roles.CLIENT_HAPPINESS) {
          const filteredStatuses = _.pick(InvoiceStatus, [
            'Pending Confirmation',
            'For Sale',
            'Partially Sold',
            'Sale Closed',
            'Awaiting Collection',
            'Collected',
            'Finalized',
          ]);

          this.statusesList = Object.values(filteredStatuses);

          this._adapter.setLocale('es-PE');
          const previousFilters = JSON.parse(
            localStorage.getItem('invoices_filter')
          );
          this.filtersForm = this.fb.group({
            statuses: [this.filterDefaultCH.statuses],
            createdFrom: [this.filterDefaultCH.createdFrom],
            createdTo: [this.filterDefaultCH.createdTo],
            issuer: [this.filterDefaultCH.issuer],
            debtor: [this.filterDefaultCH.debtor],
            invoiceCode: [this.filterDefaultCH.invoiceCode],
            isConfirming: [this.filterDefaultCH.isConfirming],
            pagination: [this.filterDefaultCH.pagination],
          });
          if (previousFilters) {
            this.filtersForm.controls['statuses'].setValue(
              previousFilters.statuses
            );
            if (previousFilters.createdFrom)
              this.filtersForm.controls['createdFrom'].setValue(
                moment(previousFilters['createdFrom'])
              );
            if (previousFilters.createdTo)
              this.filtersForm.controls['createdTo'].setValue(
                moment(previousFilters['createdTo'])
              );
            this.filtersForm.controls['debtor'].setValue(
              previousFilters.debtor_ruc
            );
            this.filtersForm.controls['invoiceCode'].setValue(
              previousFilters.invoiceCode
            );

            this.filtersForm.controls['pagination'].setValue(
              previousFilters.pagination
            );
            this.selectedDebtor = previousFilters.debtor;
            this.isConfirming =
              previousFilters.isConfirming == 'true' ? true : false;

            this.pagination = {
              total: 0,
              limit: 10,
              skip: previousFilters.pagination,
            };
            if (this.isConfirming) {
              this.invoiceType = 'confirming';
              this.filtersForm.controls['issuer'].setValue(
                previousFilters.supplier_id
              );
              this.selectedIssuer = previousFilters.supplier;
            } else {
              this.filtersForm.controls['issuer'].setValue(
                previousFilters.issuer_id
              );
              this.selectedIssuer = previousFilters.issuer;
            }
          }
          this.applyFilter(true);
        } else {
          if (loggedInUserRole === Roles.CLIENT) {
            let tableConfig = JSON.parse(
              localStorage.getItem('client_invoices_table')
            );

            if (tableConfig) {
              this.selectedTab = tableConfig.tab;
              this.inProcessPagination = tableConfig.inProcessPagination;
              this.forCollectionPagination =
                tableConfig.forCollectionPagination;
              this.collectedPagination = tableConfig.collectedPagination;
              this.disapprovedPagination = tableConfig.disapprovedPagination;
            }

            this.sendMixpanelEvent({ index: this.selectedTab });

            this.store.select(InvoiceSelectors.all).subscribe((res: any) => {
              this.invoices$ = res;
              this.filterTabs({ index: this.selectedTab });
            });
          } else {
            this.invoices$ = this.store.select(InvoiceSelectors.all);
          }
        }
      });

    this.store
      .select(UsersSelectors.profile())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((profile: any) => {
        this.showNewAnimationOnDownloadButton = profile.showNewAnimationOnDownloadButton
        if (profile?.role === 'client' && !profile?.isContractUpdateSeen) {
          this.dialog
            .open(DisclaimerModalComponent, {
              maxWidth: '376px',
              height: 'auto',
              autoFocus: false,
              disableClose: true,
              panelClass: 'custom-modalbox',
            })
            .afterClosed()
            .subscribe((result: any) => {
              this.userService
                .updateOwn({ isContractUpdateSeen: true })
                .subscribe(() => {
                  this.store.dispatch(UsersActions.LoadProfile());
                });
            });
        }
      });

    this.route.queryParams.subscribe((params) => {
      if (params.template_message) {
        const eventData: any = {
          template_message: params.template_message,
        };

        this.mixpanelService.newEvent('Whatsapp link clicked', eventData);
      }
    });

    this.socketService.socketEvent.subscribe((event) => {
      if (event == 'update_client') {
        this.store.dispatch(InvoicesActions.LoadAll());
      }
    });
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  setInvoiceType($event) {
    let res = $event;
    if (res === 'pen') {
      this.invoiceType = 'factoring';
      this.searchinType = 'client';
      this.isConfirming = false;
    } else {
      this.invoiceType = 'confirming';
      this.searchinType = 'debtor';
      this.isConfirming = true;
    }
  }

  showInvest(invoice) {
    if (invoice.status === 'for sale' || invoice.status === 'partially sold') {
      this.overlayService.showComponent(InvestInInvoiceComponent, invoice);
    }
  }

  paginationHandler(data) {
    this.filtersForm.controls['pagination'].setValue(data.skip);
    this.pagination.skip = data.skip;
    this.pagination.limit = data.limit;
    this.applyFilter(true);
  }

  paginationHandlerClient(data, tabIndex) {
    switch (tabIndex) {
      case 0:
        this.inProcessPagination.skip = data.skip;
        this.inProcessPagination.currentPage = data.currentPage;
        break;

      case 1:
        this.forCollectionPagination.skip = data.skip;
        this.forCollectionPagination.currentPage = data.currentPage;
        break;

      case 2:
        this.collectedPagination.skip = data.skip;
        this.collectedPagination.currentPage = data.currentPage;
        break;

      case 3:
        this.disapprovedPagination.skip = data.skip;
        this.disapprovedPagination.currentPage = data.currentPage;
        break;
    }

    this.filterTabs({ index: tabIndex });
  }

  resetFiltersToDefaults(reapply = true) {
    if (this.role === 'admin' || this.role === 'super admin') {
      this.filtersForm.controls['statuses'].setValue(
        this.filterDefault.statuses
      );
      this.filtersForm.controls['createdFrom'].setValue(
        this.filterDefault.createdFrom
      );
      this.filtersForm.controls['createdTo'].setValue(
        this.filterDefault.createdTo
      );
      this.filtersForm.controls['issuer'].setValue(this.filterDefault.issuer);
      this.filtersForm.controls['debtor'].setValue(this.filterDefault.debtor);
      this.filtersForm.controls['pagination'].setValue(
        this.filterDefault.pagination
      );
      this.filtersForm.controls['invoiceCode'].setValue(
        this.filterDefault.invoiceCode
      );
      this.filtersForm.controls['isConfirming'].setValue(false);

      this.isConfirming = false;
    } else {
      this.filtersForm.controls['statuses'].setValue(
        this.filterDefaultCH.statuses
      );
      this.filtersForm.controls['createdFrom'].setValue(
        this.filterDefaultCH.createdFrom
      );
      this.filtersForm.controls['createdTo'].setValue(
        this.filterDefaultCH.createdTo
      );
      this.filtersForm.controls['issuer'].setValue(this.filterDefaultCH.issuer);
      this.filtersForm.controls['debtor'].setValue(this.filterDefaultCH.debtor);
      this.filtersForm.controls['pagination'].setValue(
        this.filterDefaultCH.pagination
      );
      this.filtersForm.controls['invoiceCode'].setValue(
        this.filterDefaultCH.invoiceCode
      );
      this.filtersForm.controls['isConfirming'].setValue(false);
      this.isConfirming = false;
    }

    if (reapply) this.applyFilter();
  }
  resetFilters(reapply = true) {
    this.filtersForm.reset();
    this.selectedDebtor = undefined;
    this.selectedIssuer = undefined;
    this.isConfirming = false;
    if (reapply) this.applyFilter();
  }

  applyFilter(paginate = false) {
    this.spinner.show();

    if (
      this.role === Roles.CLIENT_HAPPINESS &&
      (this.filtersForm.value.statuses == null ||
        this.filtersForm.value.statuses?.length == 0)
    ) {
      this.filterApplyedForCH = true;
      this.filtersForm.value.statuses = [];
      this.filtersForm.value.statuses.push('awaiting collection');
      this.filtersForm.value.statuses.push('collected');
      this.filtersForm.value.statuses.push('finalized');
    }

    const { createdFrom, createdTo, ...rest } = this.filtersForm.value;

    let filterFormattedValue = rest;

    if (
      filterFormattedValue?.statuses?.includes('pending transfer') ||
      filterFormattedValue.pendingTransfer
    ) {
      filterFormattedValue.statuses = filterFormattedValue?.statuses?.filter(
        (status) => status != 'pending transfer'
      );
      filterFormattedValue.pendingTransfer = true;
    }

    if (!paginate) {
      this.pagination = Object.assign({}, this.defaultPagination);
      filterFormattedValue['pagination'] = this.pagination.skip;
    }

    if (this.invoiceType === 'confirming') {
      filterFormattedValue['supplier'] = filterFormattedValue['issuer'];
      delete filterFormattedValue['issuer'];
    }
    filterFormattedValue['isConfirming'] =
      this.isConfirming == false ? 'false' : 'true';
    if (filterFormattedValue.invoiceCode === '')
      delete filterFormattedValue.invoiceCode;
    if (createdFrom)
      filterFormattedValue = {
        ...filterFormattedValue,
        createdFrom: createDateOnly(createdFrom.toDate().toISOString()),
      };
    if (createdTo)
      filterFormattedValue = {
        ...filterFormattedValue,
        createdTo: createDateOnly(createdTo.toDate().toISOString()),
      };

    this.service
      .get(this.pagination.skip, this.pagination.limit, filterFormattedValue)
      .subscribe((result: any) => {
        if (result && result?.entities?.length > 0) {
          this.invoices = result.entities;
          this.pagination.total = result.count;
          if (this.role === Roles.CLIENT_HAPPINESS) {
            result.entities = result.entities.map((entity) => {
              entity.announcementCount = entity.announcements?.length;
              return entity;
            });
          }

          const keys = Object.keys(filterFormattedValue).filter(
            (key) => filterFormattedValue[key]
          );
          const invoiceFilter = {};
          invoiceFilter['isConfirming'] =
            this.isConfirming == false ? 'false' : 'true';

          if (this.filterApplyedForCH) {
            this.filterApplyedForCH = false;
            filterFormattedValue['statuses'] = [];
          }

          keys.map((key) => {
            if (key === 'issuer') {
              invoiceFilter['issuer_id'] = filterFormattedValue.issuer;
              invoiceFilter['issuer'] = result.entities[0]['issuer'];
              return;
            }
            if (key === 'supplier') {
              invoiceFilter['supplier_id'] = filterFormattedValue.supplier;
              invoiceFilter['supplier'] = result.entities[0]['supplier'];
              return;
            }
            if (key === 'debtor') {
              invoiceFilter['debtor_ruc'] = filterFormattedValue.debtor;
              invoiceFilter['debtor'] = {
                companyName: result.entities[0].debtor['companyName'],
                companyRuc: result.entities[0].debtor['companyRuc'],
                _id: result.entities[0].debtor['_id'],
              };
              return;
            }

            invoiceFilter[key] = filterFormattedValue[key];
          });

          localStorage.setItem(
            'invoices_filter',
            JSON.stringify(invoiceFilter)
          );
        } else {
          if (this.pagination.skip != 0 && this.role === Roles.INVESTOR) {
            this.pagination.skip -= 10;
            this.applyFilter(true);
          }
          this.invoices = undefined;
        }

        this.spinner.hide();
      });
  }

  reApplyFilters() {
    this.applyFilter();
  }

  filterByClient(event) {
    this.resetFilters(false);
    this.selectedIssuer = event.client;
    this.filtersForm.controls['issuer'].setValue(event.client._id);

    if (event.status) {
      this.filtersForm.controls['statuses'].setValue([event.status]);
    }

    this.reApplyFilters();
  }

  filterByDebtor(event) {
    this.resetFilters(false);

    this.selectedDebtor = event;
    this.filtersForm.controls['debtor'].setValue(event._id);

    this.reApplyFilters();
  }

  getCodes(invoice: any) {
    return invoice.physicalInvoices.map((i: any) => i.code).join(', ');
  }

  filterTabs(tab: any, column?: string) {
    switch (tab.index) {
      case 0:
        this.invoices = this.invoices$.filter(
          (invoice: any) =>
            this.isStatusInProcess(invoice.status) &&
            !invoice.advancePaymentDate && !invoice.clientChecks.isFinanceReady
        );
        this.inProcessPagination.total = this.invoices.length;

        if (column) {
          this.inProcessPagination.activeSort = column;
          this.inProcessPagination.isSortAsc =
            !this.inProcessPagination.isSortAsc;
        }

        this.invoices = this.sortInvoices(
          this.invoices,
          this.inProcessPagination.activeSort,
          this.inProcessPagination.isSortAsc
        );

        this.invoices = this.invoices.slice(
          this.inProcessPagination.skip,
          this.inProcessPagination.skip + this.inProcessPagination.limit
        );
        break;
      case 1:
         this.invoices = this.invoices$.filter(
          (invoice: any) =>
            this.isStatusInProcess(invoice.status) &&
          (invoice.clientChecks.progress === 100 || (invoice.clientChecks.isFinanceReady && !invoice.advancePaymentDate) || invoice.advancePaymentDate)
        );

        this.inProcessPagination.total = this.invoices.length;

        if (column) {
          this.inProcessPagination.activeSort = column;
          this.inProcessPagination.isSortAsc =
            !this.inProcessPagination.isSortAsc;
        }

        this.invoices = this.sortInvoices(
          this.invoices,
          this.inProcessPagination.activeSort,
          this.inProcessPagination.isSortAsc
        );

        this.invoices = this.invoices.slice(
          this.inProcessPagination.skip,
          this.inProcessPagination.skip + this.inProcessPagination.limit
        );
        break;
      case 2:
        this.invoices = this.invoices$.filter(
          (invoice: any) =>
            invoice.status === 'awaiting collection'
        );

        // && invoice.clientChecks.isFinanceReady
        this.forCollectionPagination.total = this.invoices.length;

        if (column) {
          this.forCollectionPagination.activeSort = column;
          this.forCollectionPagination.isSortAsc =
            !this.forCollectionPagination.isSortAsc;
        }

        this.invoices = this.sortInvoices(
          this.invoices,
          this.forCollectionPagination.activeSort,
          this.forCollectionPagination.isSortAsc
        );

        this.invoices = this.invoices.slice(
          this.forCollectionPagination.skip,
          this.forCollectionPagination.skip + this.forCollectionPagination.limit
        );
        break;
      case 3:
        this.invoices = this.invoices$.filter(
          (invoice: any) =>
            invoice.status === 'collected' || invoice.status === 'finalized'
        );
        this.collectedPagination.total = this.invoices.length;

        if (column) {
          this.collectedPagination.activeSort = column;
          this.collectedPagination.isSortAsc =
            !this.collectedPagination.isSortAsc;
        }

        this.invoices = this.sortInvoices(
          this.invoices,
          this.collectedPagination.activeSort,
          this.collectedPagination.isSortAsc
        );

        this.invoices = this.invoices.slice(
          this.collectedPagination.skip,
          this.collectedPagination.skip + this.collectedPagination.limit
        );
        break;
      case 4:
        this.invoices = this.invoices$.filter(
          (invoice: any) => invoice.status === 'disapproved'
        );
        this.disapprovedPagination.total = this.invoices.length;

        if (column) {
          this.disapprovedPagination.activeSort = column;
          this.disapprovedPagination.isSortAsc =
            !this.disapprovedPagination.isSortAsc;
        }

        this.invoices = this.sortInvoices(
          this.invoices,
          this.disapprovedPagination.activeSort,
          this.disapprovedPagination.isSortAsc
        );

        this.invoices = this.invoices.slice(
          this.disapprovedPagination.skip,
          this.disapprovedPagination.skip + this.disapprovedPagination.limit
        );
        break;
    }

    this.updateTableConfig();
  }

  isStatusInProcess(status: string) {
    return [
      'in process',
      'approved',
      'configured',
      'pending confirmation',
      'for sale',
      'partially sold',
      'sale closed',
    ].includes(status);
  }

  sortInvoices(invoices: any, column: string, isSortAsc: boolean) {
    switch (column) {
      case 'companyName':
        return invoices.sort((a: any, b: any) => {
          if (a.debtor.companyName < b.debtor.companyName) {
            return isSortAsc ? -1 : 1;
          }
          if (a.debtor.companyName > b.debtor.companyName) {
            return isSortAsc ? 1 : -1;
          }
          return 0;
        });

      case 'netAmount':
        return invoices.sort((a: any, b: any) => {
          if (Number(a.netAmount) < Number(b.netAmount)) {
            return isSortAsc ? -1 : 1;
          }
          if (Number(a.netAmount) > Number(b.netAmount)) {
            return isSortAsc ? 1 : -1;
          }
          return 0;
        });

      case 'paymentDate':
        return invoices.sort((a: any, b: any) => {
          if (isSortAsc)
            return moment(a.paymentDate).diff(moment(b.paymentDate));
          if (!isSortAsc)
            return moment(b.paymentDate).diff(moment(a.paymentDate));
        });

      case 'actualPaymentDate':
        return invoices.sort((a: any, b: any) => {
          if (isSortAsc)
            return moment(a.actualPaymentDate).diff(
              moment(b.actualPaymentDate)
            );
          if (!isSortAsc)
            return moment(b.actualPaymentDate).diff(
              moment(a.actualPaymentDate)
            );
        });

      case 'hasNotification':
        return invoices.sort((a: any, b: any) => {
          return (
            (b.hasOwnProperty('hasNotification') ? b.hasNotification : 0) -
            (a.hasOwnProperty('hasNotification') ? a.hasNotification : 0)
          );
        });

      case 'progress':
        return invoices.sort((a: any, b: any) => {
          if (a.clientChecks?.progress < b.clientChecks?.progress) {
            return isSortAsc ? -1 : 1;
          }
          if (a.clientChecks?.progress > b.clientChecks?.progress) {
            return isSortAsc ? 1 : -1;
          }
          return 0;
        });

      case 'days progress':
        return invoices.sort((a: any, b: any) => {
          if (a.totalDuration < b.totalDuration) {
            return isSortAsc ? -1 : 1;
          }
          if (a.totalDuration > b.totalDuration) {
            return isSortAsc ? 1 : -1;
          }
          return 0;
        });
    }
  }

  updateTableConfig() {
    let tableConfig = {
      tab: this.selectedTab,
      inProcessPagination: this.inProcessPagination,
      forCollectionPagination: this.forCollectionPagination,
      collectedPagination: this.collectedPagination,
      disapprovedPagination: this.disapprovedPagination,
    };
    localStorage.setItem('client_invoices_table', JSON.stringify(tableConfig));
  }

  totalDeniedChecksCount() {
    const totalCount = this.invoices$
      .filter(
        (invoice: any) =>
          this.isStatusInProcess(invoice.status) &&
          !invoice.advancePaymentDate &&
          invoice.checklist?.some((i: any) => i.status !== 'approved')
      )
      .reduce((acc, cur) => {
        return acc + cur?.clientChecks?.totalDenied || 0;
      }, 0);

    return totalCount > 9 ? '9+' : totalCount;
  }

  totalForCollectionCount() {
    const totalCount = this.invoices$
      .filter(
        (invoice: any) =>
          invoice.status !== 'collected' &&
          invoice.status !== 'finalized' &&
          invoice.status !== 'disapproved' &&
          invoice.advancePaymentDate &&
          invoice.checklist?.every((i: any) => i.status === 'approved')
      )
      .reduce((acc, cur) => {
        return acc + (cur.hasNotification ? 1 : 0);
      }, 0);

    return totalCount > 9 ? '9+' : totalCount;
  }

  totalCollectedCount() {
    const totalCount = this.invoices$
      .filter(
        (invoice: any) =>
          invoice.status === 'collected' || invoice.status === 'finalized'
      )
      .reduce((acc, cur) => {
        return acc + (cur.hasNotification ? 1 : 0);
      }, 0);

    return totalCount > 9 ? '9+' : totalCount;
  }

  totalDisapprovedCount() {
    const totalCount = this.invoices$
      .filter((invoice: any) => invoice.status === 'disapproved')
      .reduce((acc, cur) => {
        return acc + (cur.hasNotification ? 1 : 0);
      }, 0);

    return totalCount > 9 ? '9+' : totalCount;
  }

  updateNotification(invoice: any) {
    if (this.role === Roles.CLIENT && invoice.hasNotification) {
      this.service
        .update({ _id: invoice._id, hasNotification: false })
        .subscribe();
    }
  }

  checkOverflow(el: any) {
    var curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

    var isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  }

  getRemainingDays(toBeCollectedIn: any, duration: number) {
    const daysLabel = duration === 1 ? 'día' : 'días';
    const quedaLabel = duration === 1 ? 'Queda' : 'Quedan';

    if (toBeCollectedIn === 'En mora') {
      switch (true) {
        case duration >= 1 && duration < 31:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#FFB330',
          };
        case duration >= 31:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#FF0000',
          };

        default:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#2D2D2D',
          };
      }
    } else {
      return {
        text: `${quedaLabel} ${duration} ${daysLabel}`,
        color: '#2D2D2D',
      };
    }
  }

  datesPercentage(initialDate: any, finalDate: any) {
    if (initialDate && finalDate) {
      const todaysDateInPeru = getTodayDateOnly();
      let initDate = getDaysDiff(finalDate, todaysDateInPeru);

      const final = getDaysDiff(finalDate, initialDate);

      const result = new Big(initDate)
        .abs()
        .div(final)
        .abs()
        .minus(1)
        .abs()
        .times(100)
        .toFixed(2);

      return result;
    } else {
      return 0;
    }
  }

  getCompanyName(invoice: any) {
    return invoice.isConfirming
      ? invoice?.supplier?.companyName
      : invoice?.debtor?.companyName;
  }

  getTabName(index: number) {
    switch (index) {
      case 0:
        return 'En proceso';
      case 1:
        return 'Por cobrar';
      case 2:
        return 'Cobrada';
      case 3:
        return 'Desaprobada';
      default:
        return '';
    }
  }

  sendMixpanelEvent(tab: any) {
    const eventData: any = {
      page_url: environment.clientUrl + this.router.url,
      page_title: 'Operation',
      section_step: 'Overview',
      active_tab: `Operation - ${this.getTabName(tab.index)}`,
    };

    this.mixpanelService.newEvent('page_view', eventData);
  }

  downloadInvoicing(event: any, invoice: any) {
    event.preventDefault();
    event.stopPropagation();

    this.spinner.show();

    const eventData: any = {
      page_url: environment.clientUrl + this.router.url,
      operation_id: invoice._id,
    };

    this.mixpanelService.newEvent('Service invoice download', eventData);

    this.service.downloadInvoicingsFiles(invoice._id).subscribe((result) => {
      if (result.file) {
        const fileName = `${(
          invoice.supplier?.companyName || invoice.debtor?.companyName
        ).toUpperCase()} ${invoice.physicalInvoices
          .map((i: any) => i.code)
          .join(' ')}.zip`;
        saveAs(result.file, fileName);
      } else {
        window.alert('¡No se encuentra el archivo!');
      }

      this.spinner.hide();
    });
  }

  getAvailableBalance(invoice){
      return Big(100)
      .minus(invoice?.availableBalancePercentage || 0)
  }
  exportOperations() {
    this.spinner.show();
    this.service.downloadOperationsExcel().subscribe((data: any) => {
      const contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = b64toBlob(data.base64, contentType);
      FileSaver.saveAs(blob, data.fileName);
      this.spinner.hide();
    });

    const entity = {
      showNewAnimationOnDownloadButton: false,
    };
    this.store.dispatch(
      UsersActions.SavingProfile({ entity: entity, mode: 'editOwn' })
    );
  }
}
