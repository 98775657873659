import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AppState } from 'src/app/app.states';
import { Store, Action } from '@ngrx/store';
import { tap, map, catchError, mergeMap, switchMap, shareReplay, concatMap, take, skipWhile } from 'rxjs/operators';
import { Observable, pipe, of } from 'rxjs';
import { RiskService } from '../services/risk.service';
import { EvaluationsActions } from './evaluations.action-types';


@Injectable()
export class EvaluationsEffects {

    $saving = createEffect(
        () =>
          this.actions$.pipe(
            ofType(EvaluationsActions.Saving),
            concatMap((action) =>
            this.riskService.save(action.entity, action.mode).pipe(
                switchMap((receivedEntity) => {
                    return [
                        EvaluationsActions.SaveSuccess({ entity: receivedEntity }),
                    ];
                }
                ),
                catchError((error) =>{
                    return of(EvaluationsActions.SaveFailed({ error }))
                }
            )
          )
        )
          ),
        { dispatch: true }
    );

    $savingNote = createEffect(
        () =>
          this.actions$.pipe(
            ofType(EvaluationsActions.SavingNote),
            concatMap((action) =>
            this.riskService.saveNote(action.entity, action.mode).pipe(
                switchMap((receivedEntity) => {
                    return [
                        EvaluationsActions.SaveSuccess({ entity: receivedEntity }),
                    ];
                }
                ),
                catchError((error) =>{
                    return of(EvaluationsActions.SaveFailed({ error }))
                }
            )
          )
        )
          ),
        { dispatch: true }
    );

    $request = createEffect(
        () =>
          this.actions$.pipe(
            ofType(EvaluationsActions.Request),
            concatMap((action) =>
            this.riskService.requestEvaluation(action.entity, action.mode).pipe(
                switchMap((receivedEntity) => {
                    return [
                        EvaluationsActions.SaveSuccess({ entity: receivedEntity }),
                    ];
                }
                ),
                catchError((error) =>{
                    return of(EvaluationsActions.SaveFailed({ error }))
                }
            )
          )
        )
          ),
        { dispatch: true }
    );

    $deleteDocument = createEffect(
        () =>
          this.actions$.pipe(
            ofType(EvaluationsActions.DeleteDocument),
            concatMap((action) =>
            this.riskService.deleteOneDocument(action.entity, action.mode).pipe(
                switchMap((receivedEntity) => {
                    return [
                        EvaluationsActions.SaveSuccess({ entity: receivedEntity }),
                    ];
                }
                ),
                catchError((error) =>{
                    return of(EvaluationsActions.SaveFailed({ error }))
                }
            )
          )
        )
          ),
        { dispatch: true }
    );
    

    $loadAll = createEffect(
        () => this.actions$
            .pipe(
                ofType(EvaluationsActions.LoadAll),
                concatMap(action => this.riskService.getAll()),
                map(evaluations => EvaluationsActions.LoadAllSuccess({ entities: evaluations }))
            )
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private riskService: RiskService,
    ) { }
}