import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit {

  @Output() selectionChanged = new EventEmitter();
  @Input() firstLabel;
  @Input() secondLabel;
  @Input() checked = false;
  currency = 'pen';
  constructor() { }

  ngOnInit() {
  }

  toggle(event): void {
    this.currency = (event.checked === false) ? 'pen' : 'usd';
    this.checked = event.checked;
    this.selectionChanged.emit(this.currency);
  }

}
