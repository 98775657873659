import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-confirm-invoice-for-sale',
  templateUrl: './confirm-invoice-for-sale.component.html',
  styleUrls: ['./confirm-invoice-for-sale.component.scss']
})
export class ConfirmInvoiceForSaleComponent implements OnInit {

  @Input() entity;
  @Input() onSaleTime;
  telegram;
  invoices;

  constructor(
  ) { }

  ngOnInit(): void {
    moment.locale('es');
    // this.invoiceCodes = this.entity.physicalInvoices
    //   .map((invoice: any) => invoice.code)
    //   .toString();


    this.invoices = this.entity.selectedInvoices || [];
    this.invoices = this.invoices.concat({
      physicalInvoices: this.entity.physicalInvoices,
      supplier: this.entity.supplier,
      debtor: this.entity.debtor,
      issuer: this.entity.issuer,
      isConfirming: this.entity.isConfirming,
      currency: this.entity.currency,
      netAmount: this.entity.netAmount,
      tea: this.entity?.tea
    });

    this.telegram = this.entity.telegramMessage;
  }

  getDate(date) {
    return moment(date).format('dddd DD [de] MMMM, HH:mm')
  }


  getCodes(invoice) {
    return invoice.physicalInvoices.map(i => i.code).join("\n");
  }
}
