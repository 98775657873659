<app-page-title title="Nuevo proveedor" description="Ingresa el RUC de tu proveedor y buscaremos los datos de la SUNAT.">
</app-page-title>
<app-ruc-search [type]="'supplier'" (onSubmit)="setCompanyInfo($event)"></app-ruc-search>

<div class="container">
    <p class="tiny" *ngIf="companyInfo?.companyName">Resultado de la búsqueda</p>
</div>
<app-sunat-ruc-item [companyInfo]="companyInfo"></app-sunat-ruc-item>
<div class="button-group intercom-button-save button-container button-direction-column">
    <fs-ui-button  *ngIf="companyInfo?.companyName && !companyInfo?.exists" [label]="'Guardar proveedor'" (click)="saveClient()"></fs-ui-button>
    <fs-ui-button routerLink=".." [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
</div>