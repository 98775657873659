<div class="container" *ngIf="profile">
    <img (click)="showMenu()" class="menu-toggle" src='../../../../../assets/icons/menu-icon.svg'>
    <!--<img (click)="hideMenu()" class="menu-toggle" src='../../../../../assets/icons/asd.svg'>-->
    <div class="hello-and-logo">
        <span class="span-container">

            <ng-container *allowedRoles="['client', 'investor']">
                <ng-container *ngIf="hideByDefault">
                    <div *ngIf="!profile.setupbarClosed" class="tooltip-container-not-dots">
                        <img *ngIf="isMobile && profile.status === 'approved'"
                            src="../../../../../assets/icons/check_circle_outline_black_24dp (2).svg" alt="">
                        <img *ngIf="isMobile && profile.status === 'email verified'"
                            src="../../../../../assets/icons/hourglass_top_black_24dp.svg" alt="">
                        <span class="tooltiptext-bottom">{{ profile.status === 'email verified' ? 'Cuenta por aprobar.
                            Explora la plataforma sin problemas' : 'Cuenta aprobada'}}</span>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *allowedRoles="['investor']">
                <h5>Hola, {{ userFirstName$ | async | capitalizeFirstLetters }}</h5>
            </ng-container>
        </span>
        <img class="tiny-logo" src='../../../../../assets/logos/small.png'>
    </div>
    <ng-container *allowedRoles="['client', 'investor']">
        <ng-container *ngIf="hideByDefault">
            <ng-container *ngIf="!isMobile && !profile.setupbarClosed" [ngSwitch]="profile.status">
                <div class="tooltip-container-not-dots">
                    <ng-container *ngSwitchCase="'approved'">
                        <div class="container-status approved">
                            <img src="../../../../../assets/icons/check_circle_outline_black_24dp (2).svg" alt="">
                            <p>Cuenta aprobada</p>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'email verified'">
                        <div class="container-status verified">
                            <img src="../../../../../assets/icons/hourglass_top_black_24dp.svg" alt="">
                            <p>Cuenta por aprobar</p>
                        </div>
                    </ng-container>
                    <span *ngIf="isMobile" class="tooltiptext-bottom">{{ profile.status === 'email verified' ? 'Cuenta
                        por aprobar. Explora la plataforma sin problemas.' : 'Cuenta aprobada'}}</span>
                    <span *ngIf="!isMobile && profile.status === 'email verified'" class="tooltiptext-bottom">{{
                        profile.status === 'email verified' ? 'Cuenta por aprobar. Explora la plataforma sin problemas.'
                        : 'Cuenta aprobada'}}</span>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>