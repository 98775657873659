import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-header-information',
  templateUrl: './risk-header-information.component.html',
  styleUrls: ['./risk-header-information.component.scss']
})
export class RiskHeaderInformationComponent implements OnInit {

  @Input() entity;
  @Input() debtor;
  constructor() { }

  ngOnInit(): void {
  }

  getClassRed(entity) {
    if (Number(entity?.usedCreditLine || 0) > Number(entity?.creditLine || 0)) {
      return 'eval-red'
    }
  }

  getClassRedAmount(entity) {
    if ((Number(entity?.amountInProcess || 0) + Number(entity?.usedCreditLine || 0)) > Number(entity?.creditLine || 0)) {
      return 'eval-red'
    }
  }

  mapCreditLine(val) {
    switch (val) {
      case 'line':
        return { label: 'Línea', icon: 'L' };
      case 'punctual':
        return { label: 'Puntual', icon: 'P' };
      default:
        return { label: '-', icon: '-' };
    }
  }

  mapStatus(val) {
    switch (val) {
      case 'approved':
        return { label: "Aprobado", color: 'green' };
      case 'rejected':
        return { label: "Denegado", color: 'red' };
      case 'pending':
        return { label: "Pendiente", color: 'yellow' };
      case 'pending':
        return { label: "Pendiente", color: 'yellow' };
      case 'observation':
        return { label: "En observación", color: 'dark-gray' };
      default:
        return { label: "Sin evaluación", color: 'dark-gray' };
    }
  }

}
