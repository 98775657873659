<p class="subtitle">Vas a redirigir la siguiente actualización:</p>

<app-announcement-card 
    [announcement]="entity?.announcementDetail" 
    [showActions]="false">
</app-announcement-card>

<div class="container-flex">
    <p class="title-operation-obs">Operaciones seleccionadas</p>
    <div class="container-box"><p>{{ entity?.invoices?.length || '---'}}</p></div>
</div>

<app-invoice-announcement-table 
    [invoices]="entity?.invoices">
</app-invoice-announcement-table>