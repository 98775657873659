import { Component, OnInit, Input, Optional, Self, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-radiolist-circle',
  templateUrl: './radiolist-circle.component.html',
  styleUrls: ['./radiolist-circle.component.scss']
})
export class RadiolistCircleComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() options;
  @Input() defaultOption;
  @Input() valueField;
  @Input() textField;
  @Input() direction : 'row' | 'column' = 'row';
  @Output() OnSelectionChanged = new EventEmitter();

  parsedOptions;
  _selectedValue;
  _errorStatus;
  
  errorMessage;

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    if(val !== undefined) {
      this.OnSelectionChanged.emit(val);
      this._selectedValue = val;
      this.propagateChange(this._selectedValue);
    }
  }

  constructor (@Optional()@Self() private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if(changes.options) {
      this.mapOptions(changes.options.currentValue);

      if(this.defaultOption) {
        this.selectedValue = this.defaultOption;
      }  
    }
  }

  ngOnInit(): void {
    /*
    console.log(this.options)
    console.log(this.defaultOption)
    if(this.defaultOption) {
      this.selectedValue = this.defaultOption;
    }*/

    this.ngControl.control.parent.statusChanges.subscribe(formStatus => {
      if(formStatus === 'INVALID' && this.ngControl.errors && (this.ngControl.dirty || this.ngControl.touched)) {
        this._errorStatus = true;
        this.errorMessage = this.getErrorLabel();
      } else {
        this.errorMessage = '';
        this._errorStatus = false;
      }
    });
  }

  getErrorLabel() {
    console.log('ERROR', this.ngControl.hasError('required'));
    if(this.ngControl.hasError('required')) {
      return 'Obligatorio';
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.selectedValue = value;
    }
  }
  
  changeSelection(selection) {
    this.selectedValue = selection;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  mapOptions (optionsList) {
    this.parsedOptions = optionsList.map(option => ({
      value: option[this.valueField] || option.value || option,
      text: option[this.textField] || option.text || option
    }))
  }

  registerOnTouched() {}
}
