import { Component } from '@angular/core';

@Component({
  selector: 'app-risk-pending-evaluations-page',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class RiskPendingEvaluationsPageComponent {
  pageTitle = 'Actividades pendientes';
  constructor() {}
}
