import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeOnlyFirstLetter'
})
export class CapitalizeOnlyFirstLetter implements PipeTransform {


  transform(value: string): string {
    if (value) {
      let first = value.substr(0, 1).toUpperCase();
      return first + value.substr(1);
    }
  }

}
