import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store, select } from '@ngrx/store';
import { take, map, tap, filter, first, finalize } from 'rxjs/operators';
import { InvoiceSelectors } from '../../invoices/state/invoices.selector-types';
import { InvoicesActions } from '../../invoices/state/invoices.action-types';
import { financialTransactionsLoaded } from '../../financial-transactions/state/financial-transactions.selectors';
import { FinancialTransactionActions } from '../../financial-transactions/state/financial-transactions.action-types';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesResolver implements Resolve<boolean> {
  opportunitiesLoading = false;
  financialTransactionsLoading = false;

  constructor(private store: Store<AppState>) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> {

        return combineLatest(
            this.store.select(InvoiceSelectors.opportunitiesLoaded),
            this.store.select(financialTransactionsLoaded),
          )
          .pipe(tap(
              ([_loadedOpportunityEntites, _financialTransactionsLoaded]) => {
                  if(!_loadedOpportunityEntites && !this.opportunitiesLoading) { 
                      this.opportunitiesLoading = true;
                      this.store.dispatch(InvoicesActions.LoadAllOpportunities());
                  }
                  if(!_financialTransactionsLoaded && !this.financialTransactionsLoading) { 
                    this.financialTransactionsLoading = true;
                    this.store.dispatch(FinancialTransactionActions.LoadAll());
                }
              }),
              filter(([_loadedOpportunityEntites, _financialTransactionsLoaded]) => _loadedOpportunityEntites && _financialTransactionsLoaded),
              first(),
              finalize(() => this.opportunitiesLoading = this.financialTransactionsLoading = false)
          );

  }
}
