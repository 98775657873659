import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {

  @Input() success;
  @Input() mode;
  @Input() resultConfig;
  @Output() onDone = new EventEmitter();
  messsage  
  
  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    if(this.data.state.savingSucceeded && this.data.resultConfig) {
      this.messsage = this.data.resultConfig.onSuccess.message;
    }
  }

  getTitle() {
    if(this.data.state.savingSucceeded) {
      try {
        return this.data.resultConfig.onSuccess.title;
      } catch (ex) {
        return this.getGenericSuccessTitle();
      }
    } else {
      try {
        return this.data.resultConfig.onFailure.title;
      } catch (ex) {
        return this.getGenericFailureTitle();
      }
    }
  }

  getGenericSuccessTitle() {
    let postfix = ' Realizada';
    let action;

    switch (this.data.mode) {
      case 'create':
        action = 'Creación';
        break;
      case 'edit':
        action = 'Modificación';
        break;
      case 'editOwn':
        action = 'Modificación';
        break;
      case 'delete':
        action = 'Eliminación';
        break;
    } 
    return action + postfix; 
  }
  getGenericFailureTitle() {
    let errorMessage
    switch(this.data.state.lastErrorMessage) {
      case "PROFILE.INCORRECT_CURRENT_PASSWORD":
        errorMessage = 'La contraseña actual es incorrecta.';
        break;
      case "PASSWORD_RESET.TOKEN_NOT_FOUND_OR_ALREADY_USED":
        errorMessage = 'El enlace ha caducado o ya se ha utilizado.';
        break;
      case "PASSWORD_RESET_REQUEST.A_VALID_TOKEN_ALREADY_EXISTS":
        errorMessage = 'Ya ha solicitado un restablecimiento de contraseña, verifique su correo electrónico o vuelva a intentarlo más tarde.';
        break;
      
      case "INVESTMENTS.TARGET_INVOICE_NOT_FOUND":
        errorMessage = 'La factura en la que está intentando invertir ya no está disponible.';
        break;
      case "INVESTMENTS.TARGET_INVOICE_STATUS_DOESNT_ALLOW_NEW_INVESTMENTS":
        errorMessage = 'La factura en la que está intentando invertir parece no estar disponible de forma permanente o temporal, actualice y vuelva a comprobar.';
        break;
      case "INVESTMENTS.TARGET_INVOICE_AVAILABLE_BALANCE_IS_ZERO":
        errorMessage = 'El monto disponible de la factura en la que deseas invertir está en 0. Ya no se puede invertir.';
        break;
      case "INVESTMENTS.INVESTMENT_AMOUNT_IS_BIGGER_THAN_TARGET_INVOICE_AVAILABLE_BALANCE":
        errorMessage = 'El monto a invertir debe ser menor o igual al monto disponible de la factura.';
        break;
      case "INVESTMENTS.INVESTMENT_AMOUNT_IS_BIGGER_THAN_USER_AVAILABLE_BALANCE":
        errorMessage = 'No cuentas con suficiente saldo.';
        break;
      case "TRANSACTION_IS_DUPLICATED":
        errorMessage = 'El código del movimiento está duplicado.'
        break;
      case "EVALUATION.EVALUATION_ALREADY_COMPLEATED":
        errorMessage = 'Otro analista ya llenó los datos'
        break;
      
      default:
        errorMessage = 'Algo mal sucedió, por favor intente nuevamente más tarde!';
    }

    return errorMessage;
  }


  done() {
    this.onDone.emit();
  }

  getImgSrc() {
    const baseLocation = '../../../../../../assets/icons/';

    if(this.data.state.savingSucceeded) {
      return baseLocation + 'done_black.svg'
    } else {
      return baseLocation + 'big-cancel-icon.svg'
    }
  }

  getCssClass() {
    if(this.data.state.savingSucceeded) {
      return 'success'
    } else {
      return 'failure'
    }
  }

  closePopUp(){
    this.dialogRef.close('closed')
  }

}
