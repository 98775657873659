import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { tap, map, filter } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/features/authentication/state/auth-selector-types';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate{
constructor (
    private router : Router,
    private store: Store<AppState>
    ) {}

canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let allowedRoles = next.data.allowedRoles;

    return this.store
    .pipe(
      select(UsersSelectors.profileProperty('role')),
      //filter(value => !undefined),
      tap((loggedInUserRole: any) => {
          if(!allowedRoles.includes(loggedInUserRole)) {
            this.router.navigateByUrl('/');
            //TODO: should be redirected to an error page or unauthorized page
          }
      }),
      map(role => allowedRoles.includes(role))
    );
  }
}
