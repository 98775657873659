<div class="container">
    <p>Vas a guardar los siguientes datos:</p>
    <div class="one-line">
        <div class="readyonly">
            <div class="label">Sector
            </div>
        </div>
        <div class="readyonly">
            {{entity?.sector || entity?.sectorFilled | lowercase | capitalizeOnlyFirstLetter}}
        </div>
    </div>
    <div class="one-line">
        <div class="readyonly">
            <div class="label">Actividad
            </div>
        </div>
        <div class="readyonly">
            {{entity?.debtor?.activity | uppercase}}
        </div>
    </div>
    <div class="one-line">
        <div class="readyonly">
            <div class="label">Padrón
            </div>
        </div>
        <div class="readyonly">
            {{entity?.regimeType?.data === '---' ? 'No padrón' : entity?.regimeType }}
        </div>
    </div>
    <div class="one-line">
        <div class="readyonly">
            <div class="label">Nro de trabajadores
            </div>
        </div>
        <div class="readyonly">
            {{entity?.employeeCount}}
        </div>
    </div>
    <!--div class="one-line">
        <div class="readyonly">
            <div class="label">P. parcial
            </div>
        </div>
        <div class="readyonly">
            {{entity?.totalValue}}%
        </div>
    </div-->
</div>
