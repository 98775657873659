import {
  Component,
  OnInit,
  Self,
  Optional,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { xml2json } from 'xml-js';
import { NumbersOnlyValidator } from '../../validators/numbers-only.validators';
import { IDLengthValidator } from '../../validators/id-length.validator';
import { RequiredValidator } from '../../validators/required.validator';
import { IDFormatValidator } from '../../validators/id-format.validator';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { Roles } from '../../../shared/enums/Roles.enum';
@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss'],
})
export class IdentificationComponent implements ControlValueAccessor, OnInit {
  @Input() label;
  @Input() default;
  @Input() parentForm: FormGroup;
  @Input() idTypeSelected;
  @Input() role;
  roles = Roles;
  form: FormGroup;
  _value;
  selectedIdType;

  idOptions = [
    { value: 'national id', text: 'DNI' },
    { value: 'foreigner card', text: 'Carnet de Extranjería' },
    { value: 'passport', text: 'Pasaporte' },
    { value: 'ruc', text: 'RUC' },
  ];

  errorMessage;

  get value(): any {
    return this._value;
  }

  set value(newValue: any) {
    this._value = newValue;
    this.propagateChange(this._value);
  }

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private fb: FormBuilder,
    private gtmService: GTMService
  ) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.ngControl.control.setValidators([this.validate.bind(this)]);
    this.ngControl.control.updateValueAndValidity();

    if (this.idTypeSelected === 'ruc' && this.role === this.roles.INVESTOR) {
      this.form = this.fb.group({
        idType: ['', [Validators.required]],
        idNumber: [
          '',
          [RequiredValidator, IDFormatValidator, IDLengthValidator],
        ],
        idFrontPicture: [''],
        idBackPicture: [''],
      });
    } else {
      this.form = this.fb.group({
        idType: ['', [Validators.required]],
        idNumber: [
          '',
          [RequiredValidator, IDFormatValidator, IDLengthValidator],
        ],
        idFrontPicture: ['', [Validators.required]],
        idBackPicture: ['', [Validators.required]],
      });
    }

    this.form.valueChanges.subscribe((values) => {
      if (values) {
        this.value = values;
      }
    });

    if (this.idTypeSelected) {
      this.selectedIdType = this.idTypeSelected;
    }
  }

  validate({ value }: FormControl) {
    let isValid;

    if (value) {
      if (this.selectedIdType === 'ruc' && this.role === this.roles.INVESTOR) {
        if (this.value.idType && this.form.get('idNumber').valid) {
          return null;
        } else {
          isValid = false;
        }
      } else {
        if (this.selectedIdType === 'passport') {
          if (
            this.value.idType &&
            this.form.get('idNumber').valid &&
            this.value.idFrontPicture
          ) {
            return null;
          } else {
            isValid = false;
          }
        } else {
          if (
            this.value.idType &&
            this.form.get('idNumber').valid &&
            this.value.idFrontPicture &&
            this.value.idBackPicture
          ) {
            return null;
          } else {
            isValid = false;
          }
        }
      }
    } else {
      isValid = false;
    }

    if (!isValid) {
      return {
        invalid: true,
      };
    }
  }

  checkIfValid() {}

  writeValue(value: any) {
    if (value !== undefined && value) {
      this.value = value;
    }
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}

  idTypeChanged(newType) {
    this.form.patchValue({ idNumber: '' });
    this.selectedIdType = newType;
    if (this.role === this.roles.INVESTOR && this.selectedIdType === 'ruc') {
      this.form.get('idFrontPicture').setValidators([]);
      this.form.get('idBackPicture').setValidators([]);
      this.form.get('idFrontPicture').updateValueAndValidity();
      this.form.get('idBackPicture').updateValueAndValidity();
    }
    if (this.selectedIdType === 'passport') {
      this.form.get('idFrontPicture').setValidators([Validators.required]);
      this.form.get('idFrontPicture').updateValueAndValidity();
    } else {
      this.form.get('idFrontPicture').setValidators([Validators.required]);
      this.form.get('idBackPicture').setValidators([Validators.required]);
      this.form.get('idFrontPicture').updateValueAndValidity();
      this.form.get('idBackPicture').updateValueAndValidity();
    }
    this.form.markAllAsTouched();
    let event = {
      event: '[Platform][Form][Input]',
      _inputName: 'Inversionista paso 4 -  Nro doc',
      _inputCategory: 'signup - inversionista - paso 4',
    };
    this.gtmService.newEvent(event);
  }
}
