import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { Observable } from 'rxjs';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';

@Component({
  selector: 'app-invoices-page',
  template: `
    <!--<app-page-title [title]="pageTitle"></app-page-title>-->
    <!--<app-search-form></app-search-form>-->
    <router-outlet></router-outlet>
  `
})
export class InvoicesPageComponent implements OnInit {
  pageTitle = "Facturas";
  userFirstName$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.userFirstName$ = this.store
    .pipe(
      select(UsersSelectors.profileProperty('names'))
    );
   }


}
