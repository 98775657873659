import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.action-types';

export interface State {
  accessToken: any | null;
  authEmail: string | null;
  showPopUp: boolean | null;
}

export const initialState: State = {
  accessToken: undefined,
  authEmail: undefined,
  showPopUp: false,
};

export const _authReducer = createReducer(
    initialState,
    on(AuthActions.LogIn, (state, action) => {
      return {
        authEmail: action.loginInfo.email,
        showPopUp: false,
      }
    }),
    on(AuthActions.LogInSuccess, (state, action) => {
        return {
          ...state,
          accessToken: action.accessToken,
          showPopUp: true,
        }
    }),
    on(AuthActions.LogOut, (state, action) => {
        return initialState
    }),
    on(AuthActions.SessionReinistated, (state, action) => {
      return {
        ...state,
        accessToken: action.accessToken,
        showPopUp: false,
      }
    }),
    on(AuthActions.ShowPopUp, (state, action) => {
      return {
        ...state,
        showPopUp: false,
      }
    })
);

export function authReducer(state, action) {
  return _authReducer(state, action)
}