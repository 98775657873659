import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-terms-conditions-item',
  templateUrl: './terms-conditions-item.component.html',
  styleUrls: ['./terms-conditions-item.component.scss']
})
export class TermsConditionsItemComponent implements OnInit {

  @Input() entity;
  @Output() onDelete = new EventEmitter();
  @Output() onShowFile = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  delete() {
    this.onDelete.emit(this.entity.id)
  }

  showFileClicked () {
    this.onShowFile.emit(this.entity.file);
  }

}
