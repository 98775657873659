export enum Departments {
    "Amazonas" = "Amazonas",
    "Ancash" = "Ancash",
    "Apurimac" = "Apurimac",
    "Arequipa" = "Arequipa",
    "Ayacucho" = "Ayacucho",
    "Cajamarca" = "Cajamarca",
    "Callao" = "Callao",
    "Cusco" = "Cusco",
    "Huancavelica" = "Huancavelica",
    "Huanuco" = "Huanuco",
    "Ica" = "Ica",
    "Junin" = "Junin",
    "La Libertad" = "La Libertad",
    "Lambayeque" = "Lambayeque",
    "Lima" = "Lima",
    "Loreto" = "Loreto",
    "Madre De Dios" = "Madre De Dios",
    "Moquegua" = "Moquegua",
    "Pasco" = "Pasco",
    "Piura" = "Piura",
    "Puno" = "Puno",
    "San Martin" = "San Martin",
    "Tacna" = "Tacna",
    "Tumbes" = "Tumbes",
    "Ucayali" = "Ucayali"
}
