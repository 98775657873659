<div class="container c-logo">
    <img class="logo" src="../../../../../../assets/icons/Grupo919.svg" alt="">
</div>

<ng-container *ngIf="!successConfirmation">
    <ng-container *ngIf="serverResponded &&  userRole=== 'investor'">
        <div *ngIf="!serverRespondedInvalid && !serverRespondedError && !serverRespondedExpired" class="container-40">
            <h1 *ngIf="title" class="mt-50 no-image">{{ title }}</h1>
            <p class="p-gray mb-50">{{ message }}</p>
            <div class="button-container button-direction-column options">
                <fs-ui-button  routerLink="/" [label]="'Regresar'" [type]="'secondary'"></fs-ui-button>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="serverResponded && !serverRespondedExpired && (serverRespondedInvalid || serverRespondedError)">
        <div class="container">
            <h1  class="mt-50 no-image" *ngIf="title">{{ title }}</h1>
            <p class="p-gray mb-30">{{ message }}</p>
    
            <div class="button-container button-direction-column options">
                <fs-ui-button  routerLink="/" [label]="'Regresar al Login'"></fs-ui-button>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="serverResponded && serverRespondedExpired && !serverRespondedInvalid">
        <div class="container">
            <img class="img-icon" src="../../../../../../assets/icons/link_off.svg" alt="">
            <h1 *ngIf="title">{{ title }}</h1>
            <div class="button-container button-direction-column options">
                <fs-ui-button (click)="resendConfirmation()" [label]="'Enviar nuevo enlace'"></fs-ui-button>
            </div>
            <p class="p-gray">Recuerda que el enlace tendrá <span>1 hora de vigencia.</span> </p>
            <div class="footer">
                <p class="mw-80">Si llegaste a esta página por error, <a routerLink="/auth" class="a-ref" href="/">regresa al Login.</a> </p>
            </div>
        </div>
    </ng-container>
</ng-container>


<ng-container *ngIf="successConfirmation">
    <div class="container">
        <img class="img-icon" src="../../../../../../assets/icons/link_black.svg" alt="">
        <h1 *ngIf="title">{{ title }}</h1>
        <p class="p-gray lw  mt-50">Te hemos reenviado el correo de confirmación a <span>{{email}}.</span></p>
        <p class="p-gray mb-40">Recuerda que el enlace tendrá <span>1 hora de vigencia.</span> </p>

        <div class="options">
            <div class="button-container button-direction-column options">
                <fs-ui-button  routerLink="/" [label]="'Regresar al Login'"></fs-ui-button>
            </div>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="!serverResponded">
    <p>Verificando...</p>
</ng-container>