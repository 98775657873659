import { createReducer, on } from '@ngrx/store';
import { BankAccountsActions } from './bank-accounts.action-types';
import { updateStoreState } from 'src/app/shared/util/ngrx.util';

export interface State {
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  savingSucceeded: boolean;
  savingMode: 'create' | 'edit' | 'delete';
  savingEntityId: string;
  bankAccounts: any | null;
  lastErrorMessage: any | null;
  lastErrorStatus: any | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  saving: undefined,
  savingSucceeded: undefined,
  savingMode: undefined,
  savingEntityId: undefined,
  bankAccounts: undefined,
  lastErrorMessage: undefined,
  lastErrorStatus: undefined,
};

export const _bankAccountsReducer = createReducer(
  initialState,
  on(BankAccountsActions.LoadAll, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(BankAccountsActions.LoadedAllSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      bankAccounts: action.bankAccounts,
    };
  }),
  on(BankAccountsActions.Saving, (state, action) => {
    const result = {
      ...state,
      saving: true,
      savingSucceeded: undefined,
      savingMode: action.mode,
      savingEntityId: action.entity.id,
    };
    return result;
  }),
  on(BankAccountsActions.SaveSucceeded, (state, action) => {
    return {
      ...state,
      saving: false,
      savingSucceeded: true,
      savingMode: undefined,
      bankAccounts: updateStoreState(
        state.bankAccounts,
        action.result,
        state.savingMode
      ),
    };
  }),
  on(BankAccountsActions.SaveFailed, (state, action) => {
    return {
      ...state,
      saving: false,
      savingSucceeded: false,
      savingMode: undefined,
      lastErrorMessage: action.error.error,
      lastErrorStatus: action.error.status,
    };
  }),
  on(BankAccountsActions.ResetBankAccounts, (state, action) => {
    return initialState;
  })
);

export function bankAccountsReducer(state, action) {
  return _bankAccountsReducer(state, action);
}
