import { createReducer, on } from '@ngrx/store';
import { UsersActions } from './users.action-types';
import { updateProfile, updateStoreState } from 'src/app/shared/util/ngrx.util';
import { Roles } from 'src/app/shared/enums/Roles.enum';

export interface State {
  loading: boolean;
  loaded: boolean;
  loadingProfile: boolean;
  loadedProfile: boolean;
  saving: boolean;
  savingSucceeded: boolean;
  savingProfile: undefined;
  savingProfileSucceeded: undefined;
  savingProfileMode: 'editOwn';
  savingMode: 'create' | 'edit' | 'delete';
  savingEntityId: string;
  entities: any | null;
  profile: any | null;
  lastErrorMessage: any | null;
  lastErrorStatus: any | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  loadingProfile: false,
  loadedProfile: false,
  saving: undefined,
  savingSucceeded: undefined,
  savingProfile: undefined,
  savingProfileSucceeded: undefined,
  savingProfileMode: undefined,
  savingMode: undefined,
  savingEntityId: undefined,
  entities: undefined,
  profile: undefined,
  lastErrorMessage: undefined,
  lastErrorStatus: undefined
};

export const _usersReducer = createReducer(
    initialState,
    on(UsersActions.SetProfile, (state, action) => {
      if(action.role === Roles.CLIENT) {
        return {
          ...state,
          profile: {
            _id: action._id,
            role: action.role,
            names: action.names,
            isBlocked: action.isBlocked,
            status: action.status,
            companyRuc: action.companyRuc,
            isConfirming: action.isConfirming,
            legalRep: action.legalRep,
            profession: action.profession,
            legalCounter: action.legalCounter,
            legalComment: action.legalComment,
            feedbackAnimationClosed: action.feedbackAnimationClosed,
            showNewAnimationOnDownloadButton: action.showNewAnimationOnDownloadButton,
            isContractUpdateSeen: action.isContractUpdateSeen
          }
        }
      } else {
        return {
          ...state,
          profile: {
            _id: action._id,
            role: action.role,
            names: action.names,
            isBlocked: action.isBlocked,
            status: action.status,
            balance: action.balance,
            segment: action.segment,
            taxResidency: action.taxResidency,
            idType: action.idType,
            feedbackAnimationClosed: action.feedbackAnimationClosed,
            showNewAnimationOnDownloadButton: action.showNewAnimationOnDownloadButton,

          }
        }
      }
    }),
    on(UsersActions.UpdateProfile, (state, action) => {
      return {
        ...state,
        profile: updateProfile(state.profile, action),
      };
    }),
    on(UsersActions.LoadAll, (state, action) => {
        return {
          ...state,
          loading: true
        }
    }),
    on(UsersActions.LoadedAllSuccess, (state, action) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          entities: action.users
        }
    }),
    on(UsersActions.LoadProfile, (state, action) => {
        return {
          ...state,
          loadingProfile: true
        }
    }),
    on(UsersActions.LoadProfileSuccess, (state, action) => {
        return {
          ...state,
          loadingProfile: false,
          loadedProfile: true,
          profile: action.entity
        }
    }),
    on(UsersActions.Saving, (state, action) => {
        return {
          ...state,
          saving: true,
          savingSucceeded: undefined,
          savingMode: action.mode,
          savingEntityId: action.entity.id
        }
    }),
    on(UsersActions.SaveSucceeded, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: true,
          savingMode: undefined,
          entities: updateStoreState(state.entities, action.result, state.savingMode)
        }
    }),
    on(UsersActions.SaveFailed, (state, action) => {
        return {
          ...state,
          saving: false,
          savingSucceeded: false,
          savingMode: undefined,
          lastErrorMessage: action.error.error,
          lastErrorStatus: action.error.status
        }
    }),


    on(UsersActions.SavingProfile, (state, action) => {
      return {
        ...state,
        savingProfile: true,
        savingProfileSucceeded: undefined,
        savingProfileMode: action.mode,
      }
  }),
  on(UsersActions.SaveProfileSucceeded, (state, action) => {
      return {
        ...state,
        savingProfile: false,
        savingProfileSucceeded: true,
        savingProfileMode: undefined,
        profile: updateStoreState(state.profile, action.result, state.savingProfileMode)
      }
  }),
  on(UsersActions.SaveProfileFailed, (state, action) => {
      return {
        ...state,
        savingProfile: false,
        savingProfileSucceeded: false,
        savingProfileMode: undefined,
        lastErrorMessage: action.error.error,
        lastErrorStatus: action.error.status
      }
  }),

    on(UsersActions.Reset, (state, action) => {
      return initialState;
    })
);

export function usersReducer(state, action) {
  return _usersReducer(state, action)
}
