<div class="container">
  <div class="title">
    <lib-data-point class="title__data-point">
      <div class="title">
        <lib-text weight="label" [content]="'Pagador'"></lib-text>
      </div>
      <div class="value">
        <lib-text
          size="s"
          weight="label"
          [content]="evaluation.debtor.companyName | uppercase"
        ></lib-text>
      </div>
    </lib-data-point>
    <lib-icon icon="close" [isClickable]="true" (onClick)="close()"></lib-icon>
  </div>
  <div class="body">
    <lib-data-point class="">
      <div class="title">
        <lib-text weight="label" [content]="'Línea propuesta'"></lib-text>
      </div>
      <div class="value">
        <lib-text
          size="xs"
          [content]="
            evaluation?.debtorLineProposalV4 &&
            evaluation?.debtorLineProposalV4 !== ''
              ? 'S/ ' + (evaluation?.debtorLineProposalV4 | numberSpacing)
              : '---'
          "
        ></lib-text>
      </div>
    </lib-data-point>
    <lib-data-point class="">
      <div class="title">
        <lib-text weight="label" [content]="'Sector'"></lib-text>
      </div>
      <div class="value">
        <lib-text
          size="xs"
          [content]="
            evaluation?.debtorSector && evaluation?.debtorSector !== ''
              ? evaluation?.debtorSector
              : '---'
          "
        ></lib-text>
      </div>
    </lib-data-point>
    <lib-data-point class="">
      <div class="title">
        <lib-text weight="label" [content]="'Actividad'"></lib-text>
      </div>
      <div class="value">
        <lib-text
          size="xs"
          [content]="
            evaluation?.debtorActivity && evaluation?.debtorActivity !== ''
              ? evaluation?.debtorActivity
              : '---'
          "
        ></lib-text>
      </div>
    </lib-data-point>
    <lib-data-point class="">
      <div class="title">
        <lib-text weight="label" [content]="'Página web'"></lib-text>
      </div>
      <div class="value">
        <lib-text
          size="xs"
          [content]="
            evaluation?.website && evaluation?.website !== ''
              ? evaluation?.website
              : '---'
          "
        ></lib-text>
      </div>
    </lib-data-point>
    <lib-data-point class="">
      <div class="title">
        <lib-text weight="label" [content]="'Redes sociales'"></lib-text>
      </div>
      <div class="value">
        <lib-text
          *ngIf="evaluation?.socialMedia?.length === 0"
          size="xs"
          [content]="'---'"
        ></lib-text>
        <div *ngIf="evaluation?.socialMedia?.length > 0" class="list-values">
          <lib-text
            *ngFor="let socialMedia of stringToList(evaluation.socialMedia)"
            size="xs"
            [content]="socialMedia"
          ></lib-text>
        </div>
      </div>
    </lib-data-point>
    <lib-data-point class="">
      <div class="title">
        <lib-text weight="label" [content]="'Resumen de la empresa'"></lib-text>
      </div>
      <div class="value">
        <lib-text
          size="xs"
          [content]="
            evaluation?.companyDetail && evaluation?.companyDetail !== ''
              ? evaluation?.companyDetail
              : '---'
          "
        ></lib-text>
      </div>
    </lib-data-point>
    <lib-data-point class="">
      <div *ngIf="evaluation?.debtorDocuments" class="title">
        <lib-text weight="label" [content]="'Documentos'"></lib-text>
      </div>
      <div class="value">
        <lib-text
          size="xs"
          [content]="
            getDocuments(evaluation.debtorDocuments) || '---'
          "
        ></lib-text>
      </div>
    </lib-data-point>
    <div *ngIf="evaluation?.debtorDocuments && evaluation?.debtorDocuments.length > 0" class="bottom">
      <p
        (click)="downloadDocuments()"
        class="information-text"
      >
        Descargar documentos
      </p>
    </div>
  </div>
</div>
