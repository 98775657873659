import { InvoiceTeaConfigurationDialogComponent } from "./features/invoices/UI/organisms/invoice-tea-configuration/invoice-tea-configuration-dialog.component";
import { EditAmountConfirmationComponent } from "./features/risk-debtors/UI/organisms/edit-amount-confirmation/edit-amount-confirmation.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { PhoneConfirmationModalComponent } from "./shared/UI/molecules/phone-confirmation-modal/phone-confirmation-modal.component";
import { ClientInvoiceCardComponent } from "./features/invoices/UI/templates/client-invoice-card/client-invoice-card.component";
import { NewConfirmationModalComponent } from "./shared/UI/molecules/new-confirmation-modal/new-confirmation-modal.component";
import { CurrencySymbolPipe } from "./shared/pipes/currency-symbol.pipe";
import { ConfirmingEditAmountsInvoceDialogComponent } from "./features/invoices/UI/organisms/confirming-edit-amounts-invoce-dialog/confirming-edit-amounts-invoce-dialog.component";
import { ChecklistModalComponent } from "./shared/UI/organisms/checklist-modal/checklist-modal.component";
import { CreditNoteConfirmationComponent } from "./features/invoices/UI/organisms/credit-note-confirmation/credit-note-confirmation.component";
import { SpecialFormattingPipe } from "./shared/pipes/special-formatting.pipe";
import { DetailBoxComponent } from "./shared/UI/organisms/detail-box/detail-box.component";
import { RiskDebtorDetailComponent } from "./features/risk-debtors/UI/organisms/risk-debtor-detail/risk-debtor-detail.component";
import { RiskDebtorItem } from "./features/risk-debtors/UI/organisms/risk-debtor-item/risk-debtor-item.component";
import { ListRiskDebtorTemplateComponent } from "./features/risk-debtors/UI/templates/list-risk-debtor/list-risk-debtor-template.component";
import { RiskDebtorsPageComponent } from "./features/risk-debtors/UI/pages/risk-debtors/risk-debtors-page.component";
import { StringNextLinePipe } from "./shared/pipes/string-next-line.pipe";
import { ShortNamePipe } from "./shared/pipes//shortName.pipe";
import { EmailPreviewHtmlComponent } from "./features/invoices/UI/organisms/email-preview-html/email-preview-html.component";
import { SafeHtmlPipe } from "./shared/pipes/safeHtml.pipe";
import { FinancialTransactionInvestItemComponent } from "./features/financial-transactions/UI/organisms/financial-transaction-invest-item/financial-transaction-invest-item.component";
import { FinancialTransactionInvestFormComponent } from "./features/financial-transactions/UI/organisms/financial-transaction-invest-form/financial-transaction-invest-form.component";
import { CreateFinancialTransactionInvestComponent } from "./features/financial-transactions/UI/templates/create-financial-transaction-invest/create-financial-transaction-invest.component";
import { DragDropDirective } from "./shared/directives/DragDropDirective";
import { ClientItemComponent } from "./features/my-clients/UI/organisms/client-item/client-item.component";
import { SunatRucItemComponent } from "./shared/UI/molecules/sunat-ruc-item/sunat-ruc-item.component";
import { RucSearchComponent } from "./shared/UI/molecules/ruc-search/ruc-search.component";
import { AddClientComponent } from "./features/my-clients/UI/templates/add-clients/add-client.component";
import { ListClientsComponent } from "./features/my-clients/UI/templates/list-clients/list-clients.component";
//Core Modules./features/my-clients/UI/pages/my-clients-page/my-clients-page.component
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

//Core Angular Files
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

//NGRX
import { StoreModule } from "@ngrx/store";
import { reducers, effects } from "./app.states";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";

//Authentications
import { AuthEffects } from "./features/authentication/state/auth.effects";
import { RegisterComponent } from "./features/authentication/UI/templates/register/register.component";
import { ForgotPasswordComponent } from "./features/authentication/UI/templates/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./features/authentication/UI/templates/reset-password/reset-password.component";
import { LoginComponent } from "./features/authentication/UI/templates/login/login.component";
import { AuthPage } from "./features/authentication/UI/pages/auth.page";
import { LoginFormComponent } from "./features/authentication/UI/organisms/login-form/login-form.component";
import { RegisterFormComponent } from "./features/authentication/UI/organisms/register-form/register-form.component";

//Bank Accounts
import { BankAccountsPage } from "./features/bank-accounts/UI/pages/bank-accounts.page";
import { ListBankAccountsTemplate } from "./features/bank-accounts/UI/templates/list-bank-accounts/list-bank-accounts.template";
import { CreateBankAccountsTemplate } from "./features/bank-accounts/UI/templates/create-bank-accounts/create-bank-accounts.template";
import { EditBankAccountsTemplate } from "./features/bank-accounts/UI/templates/edit-bank-accounts/edit-bank-accounts.template";
import { BankAccountItemOrganism } from "./features/bank-accounts/UI/molecules/bank-account-item/bank-account-item.organism";
import { BankAccountsEffects } from "./features/bank-accounts/state/bank-accounts.effects";
import { BankAccountFormOrganism } from "./features/bank-accounts/UI/organisms/bank-account-form/bank-account-form.organism";

//Financial transaction
import { FinancialTransactionsPageComponent } from "./features/financial-transactions/UI/pages/financial-transactions-page/financial-transactions-page.component";
import { ListFinancialTransactionsTemplateComponent } from "./features/financial-transactions/UI/templates/list-financial-transactions-template/list-financial-transactions-template.component";
import { FinancialTransactionItemComponent } from "./features/financial-transactions/UI/organisms/financial-transaction-item/financial-transaction-item.component";
import { FinancialTransactionFormComponent } from "./features/financial-transactions/UI/organisms/financial-transaction-form/financial-transaction-form.component";
import { CreateFinancialTransactionDepositComponent } from "./features/financial-transactions/UI/templates/create-financial-transaction-deposit/create-financial-transaction-deposit.component";
import { CreateFinancialTransactionWithdrawComponent } from "./features/financial-transactions/UI/templates/create-financial-transaction-withdraw/create-financial-transaction-withdraw.component";
import { FinancialTransactionAdminFormComponent } from "./features/financial-transactions/UI/organisms/financial-transaction-admin-form/financial-transaction-admin-form.component";

//Invoice
import { InvoicesPageComponent } from "./features/invoices/UI/pages/invoices-page/invoices-page.component";
import { InvestmentsPageComponent } from "./features/my-investments/UI/pages/investments-page/investments-page.component";
import { ListInvoicesComponent } from "./features/invoices/UI/templates/list-invoices/list-invoices.component";
import { CreateInvoiceComponent } from "./features/invoices/UI/templates/create-invoice/create-invoice.component";

//operations
import { OperationsPageComponent } from "./features/operations/UI/pages/operations-page.component";

//System settings
import { SystemSettingsPageComponent } from "./features/system-settings/UI/pages/system-setting/system-settings.page.component";
import { ListTermsConditionsComponent } from "./features/system-settings/UI/templates/list-terms-conditions/list-terms-conditions.component";
import { CreateTermConditionComponent } from "./features/system-settings/UI/templates/create-term-condition/create-term-condition.component";
import { TermConditionFormComponent } from "./features/system-settings/UI/organisms/term-condition-form/term-condition-form.component";
import { TermsConditionsItemComponent } from "./features/system-settings/UI/molecules/terms-conditions-item/terms-conditions-item.component";
import { EditTermConditionComponent } from "./features/system-settings/UI/templates/edit-term-condition/edit-term-condition.component";
//Shared
import { PageTitleMolecule } from "./shared/UI/molecules/header/page-title.molecule";
import { TopBarOrganism } from "./shared/UI/organisms/top-bar/top-bar.organism";
import { SideMenuOrganism } from "./shared/UI/organisms/side-menu/side-menu.organism";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InputControl } from "./shared/controls/input/input.control";
import { AngularMaterialModule } from "./shared/modules/angular-material/AngularMaterialModule";
import { NotFoundComponent } from "./shared/UI/pages/not-found/not-found.component";
import { SideMenuItemComponent } from "./shared/UI/molecules/side-menu-item/side-menu-item.component";
import { AllowedRoleDirective } from "./shared/directives/allowed-role.directive";
import { SafePipe } from "./shared/pipes/safe.pipe";
import { SelectControl } from "./shared/controls/select/select.control";
import { RadiolistControl } from "./shared/controls/radiolist/radiolist.control";
import { SaveResultComponent } from "./shared/modules/overlay/ui/save-result/save-result.component";
import { SaveConfirmationTemplateComponent } from "./shared/modules/overlay/ui/save-confirmation-template/save-confirmation-template.component";
import { SpinnerComponent } from "./shared/modules/overlay/ui/spinner/spinner.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastNotificationsModule } from "ngx-toast-notifications";
import { CheckboxComponent } from "./shared/controls/checkbox/checkbox.component";
import { UploadComponent } from "./shared/controls/upload/upload.component";
import { AvailableBalanceComponent } from "./shared/UI/molecules/available-balance/available-balance.component";
import { SystemSettingsComponent } from "./features/system-settings/system-settings.component";
import { AmountComponent } from "./shared/UI/molecules/amount/amount.component";
import { CurrencyComponent } from "./shared/UI/atoms/currency/currency.component";
import { InvoiceStatusComponent } from "./shared/UI/molecules/invoice-status/invoice-status.component";
import { FinancialTransactionStatusComponent } from "./shared/UI/molecules/financial-transaction-status/financial-transaction-status.component";
import { SolidLabelComponent } from "./shared/UI/atoms/solid-label/solid-label.component";
import { DateComponent } from "./shared/UI/molecules/date/date.component";
import { ShortenPipe } from "./shared/pipes/shorten.pipe";
import { OpporunitiesPageComponent } from "./features/opportunities/UI/pages/opporunities-page/opporunities-page.component";
import { AuthorizationHeaderInterceptor } from "./shared/interceptors/authorization-header.interceptor";
import { CurrencyNamePipe } from "./shared/pipes/currency-name.pipe";
import { FinancialTransactionsEffects } from "./features/financial-transactions/state/financial-transactions.effects";
import { GenericOverlayHostComponent } from "./shared/modules/overlay/ui/generic-overlay-host/generic-overlay-host.component";
import { DisplayFileComponent } from "./shared/UI/organisms/display-file/display-file.component";
import { OurBankAccountsComponent } from "./shared/UI/organisms/our-bank-accounts/our-bank-accounts.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { RoiComponent } from "./shared/UI/molecules/roi/roi.component";
import { ProgressBarComponent } from "./shared/controls/progress-bar/progress-bar.component";
import { InvestInInvoiceComponent } from "./features/opportunities/UI/templates/invest-in-invoice/invest-in-invoice.component";
import { PayerComponent } from "./shared/UI/molecules/payer/payer.component";
import { PayerDescriptionComponent } from "./shared/UI/molecules/payer-description/payer-description.component";
import { InvoicesProgressBarComponent } from "./shared/UI/molecules/invoices-progress-bar/invoices-progress-bar.component";
import { ListInvestmentsComponent } from "./features/my-investments/UI/templates/list-investments/list-investments.component";
import { DatePickerComponent } from "./shared/controls/date/date-picker.component";
import { InvoiceUploadComponent } from "./shared/controls/invoice-upload/invoice-upload.component";
import { AvailableToInvestBalanceComponent } from "./shared/UI/molecules/available-to-invest-balance/available-to-invest-balance.component";
import { CollectionDatesComponent } from "./shared/UI/molecules/collection-dates/collection-dates.component";
import { AmountTotalComponent } from "./shared/UI/molecules/amount-total/amount-total.component";
import { InvestInfoDetailComponent } from "./shared/UI/organisms/invest-info-detail/invest-info-detail.component";
import { FastFormerComponent } from "./shared/modules/ngx-fast-former/fast-former/fast-former.component";
import { FastFormerFormComponent } from "./shared/modules/ngx-fast-former/fast-former-form/fast-former-form.component";
import { RoleSelectorComponent } from "./features/users/UI/templates/role-selector/role-selector.component";
import { FirstNameComponent } from "./shared/modules/finsmart-elements/inputs/first-name/first-name.component";
import { RadiolistCircleComponent } from "./shared/controls/radiolist-circle/radiolist-circle.component";
import { HowDidHearAboutUsComponent } from "./features/authentication/UI/organisms/how-did-hear-about-us/how-did-hear-about-us.component";
import { SignupPageComponent } from "./features/users/UI/pages/signup-page/signup-page.component";
import { UsersPage } from "./features/users/UI/pages/users-page/users-page.component";
import { InvestorSignupTemplateComponent } from "./features/users/UI/templates/investor-signup-template/investor-signup-template.component";
import { ClientSignupTemplateComponent } from "./features/users/UI/templates/client-signup-template/client-signup-template.component";
import { SignupAlmostDoneComponent } from "./features/users/UI/templates/signup-almost-done/signup-almost-done.component";
import { SearchFormComponent } from "./shared/UI/organisms/search-form/search-form.component";
import { FilterSelectComponent } from "./shared/UI/molecules/filter-select/filter-select.component";
import { UserProfilePageComponent } from "./features/users/UI/pages/user-profile-page/user-profile-page.component";
import { UserProfileTemplateComponent } from "./features/users/UI/templates/user-profile-template/user-profile-template.component";
import { ChangePasswordTemplateComponent } from "./features/users/UI/templates/change-password-template/change-password-template.component";
import { UpdateProfileFormComponent } from "./features/users/UI/organisms/update-profile-form/update-profile-form.component";
import { ChangePasswordFormComponent } from "./features/users/UI/organisms/change-password-form/change-password-form.component";
import { EmptyOverlayHostComponent } from "./shared/modules/overlay/ui/empty-overlay-host/empty-overlay-host.component";
import { ToSpanishPipe } from "./shared/pipes/to-spanish.pipe";
import { CapitalizeFirstLettersPipe } from "./shared/pipes/capitalize-first-letters.pipe";
import { IdentificationComponent } from "./shared/controls/identification/identification.component";
import { UsersListTemplateComponent } from "./features/users/UI/templates/users-list-template/users-list-template.component";
import { CreateUserTemplateComponent } from "./features/users/UI/templates/create-user-template/create-user-template.component";
import { EditUserTemplateComponent } from "./features/users/UI/templates/edit-user-template/edit-user-template.component";
import { UserItemComponent } from "./features/users/UI/organisms/user-item/user-item.component";
import { FilterByPipe } from "./shared/pipes/filter-by.pipe";
import { EditActionComponent } from "./features/users/UI/molecules/edit-action/edit-action.component";
import { ApproveActionComponent } from "./features/users/UI/molecules/approve-action/approve-action.component";
import { UserActionComponent } from "./features/users/UI/organisms/user-action/user-action.component";
import { UserFullDetailsComponent } from "./features/users/UI/templates/user-full-details/user-full-details.component";
import { AllowedActionsPipe } from "./shared/pipes/allowed-actions.pipe";
import { EmailConfirmationTemplateComponent } from "./features/users/UI/templates/email-confirmation-template/email-confirmation-template.component";
import { InvoiceCardClientComponent } from "./features/invoices/UI/organisms/invoice-card-client/invoice-card-client.component";
import { AddInvoiceComponent } from "./features/invoices/UI/templates/add-invoice/add-invoice.component";
import { InvoiceListControlComponent } from "./shared/controls/invoice-list-control/invoice-list-control.component";
import { InvoiceDetailsComponent } from "./features/invoices/UI/templates/invoice-details/invoice-details.component";
import { InvoiceItemInvestorComponent } from "./features/opportunities/UI/organisms/invoice-item-investor/invoice-item-investor.component";
import { InvoiceItemClientComponent } from "./features/invoices/UI/organisms/invoice-item-client/invoice-item-client.component";
import { InvoiceItemAdminComponent } from "./features/invoices/UI/organisms/invoice-item-admin/invoice-item-admin.component";
import { ConfigureInvoiceDialogComponent } from "./features/invoices/UI/organisms/configure-invoice-dialog/configure-invoice-dialog.component";
import { ReviewRatesComponent } from "./features/invoices/UI/organisms/review-rates/review-rates.component";
import { InvoiceDurationComponent } from "./shared/UI/atoms/invoice-duration/invoice-duration.component";
import { ConfirmInvestmentComponent } from "./features/opportunities/UI/organisms/confirm-investment/confirm-investment.component";
import { FilterOutByPipe } from "./shared/pipes/filterOut-by.pipe";
import { PayClientAdvanceDialogComponent } from "./features/invoices/UI/organisms/pay-client-advance-dialog/pay-client-advance-dialog.component";
import { CollectInvoiceDialogComponent } from "./features/invoices/UI/organisms/collect-invoice-dialog/collect-invoice-dialog.component";
import { HomeComponent } from "./features/home/home/home.component";
import { OpportunitiesListComponent } from "./features/opportunities/UI/templates/opportunities-list/opportunities-list.component";
import { FilterInPipe } from "./shared/pipes/filter-in.pipe";
import { SortPipe } from "./shared/pipes/sort.pipe";
import { SlicePipe } from "@angular/common";
import { PasswordResetFormComponent } from "./features/users/UI/organisms/password-reset-form/password-reset-form.component";
import { ArtworkDisplayComponent } from "./shared/modules/ngx-fast-former/artwork-display/artwork-display.component";
import { NumberSpacingPipe } from "./shared/pipes/number-spacing.pipe";
import { TruncatePipe } from "./shared/pipes/limitTo.pipe";
import { CapitalizeOnlyFirstLetter } from "./shared/pipes/capitalize-only-first-letter.pipe";
import { FooterComponent } from "./shared/UI/organisms/footer/footer.component";
import { FormatDatePipe } from "./shared/pipes/format-date.pipe";
import { CreateFinancialTransactionDonateComponent } from "./features/financial-transactions/UI/templates/create-financial-transaction-donate/create-financial-transaction-donate.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { getSpanishPaginatorIntl } from "./shared/modules/angular-material/spanish-paginator-intl";
import { PaginatorComponent } from "./shared/UI/organisms/paginator/paginator.component";
import { FilterComponent } from "./shared/UI/organisms/filter/filter.component";
import { AutocompleteComponent } from "./shared/controls/autocomplete/autocomplete.component";
import { NoPropagationDirective } from "./shared/directives/no-propagation.directive.ts";
import { EditAmountsInvoceDialogComponent } from "./features/invoices/UI/organisms/edit-amounts-invoce-dialog/edit-amounts-invoce-dialog.component";
import { PhysicalInvoiceTableOrganism } from "./features/invoices/UI/organisms/physical-invoice-table/physical-invoice-table.organism";
import { RealtimeConnectionStatusComponent } from "./shared/UI/organisms/realtime-connection-status/realtime-connection-status.component";
import { ManualReloadButtonComponent } from "./shared/UI/organisms/manual-reload-button/manual-reload-button.component";
import { MyClientsPageComponent } from "./features/my-clients/UI/pages/my-clients-page/my-clients-page.component";
import { ClientDetailComponent } from "./features/my-clients/UI/templates/client-detail/client-detail.component";
import { ClientInformationComponent } from "./shared/UI/organisms/client-information/client-information.component";
import { ContactDebtorItemComponent } from "./shared/UI/organisms/contact-debtor-item/contact-debtor-item.component";
import { InvoiceOperationsOverviewComponent } from "./features/my-clients/UI/organisms/invoice-operations-overview/invoice-operations-overview.component";
import { CollectionClientDatesComponent } from "./shared/UI/molecules/collection-client-dates/collection-client-dates.component";
import { InvoiceByDebtorItemComponent } from "./features/my-clients/UI/organisms/invoice-by-debtor-item/invoice-by-debtor-item.component";
import { AddContactDialogComponent } from "./features/my-clients/UI/organisms/add-contact-dialog/add-contact-dialog.component";
import { SlideToggleComponent } from "./shared/controls/slide-toggle/slide-toggle.component";
import { InvoiceAmountItemComponent } from "./features/my-clients/UI/molecules/invoice-amount-item/invoice-amount-item.component";
import { InvoiceInfoInvestmentComponent } from "./shared/UI/molecules/invoice-info-investment/invoice-info-investment.component";
import { AmmountBalanceComponent } from "./shared/UI/molecules/ammount-balance/ammount-balance.component";
import { MySupliersPageComponent } from "./features/my-supliers/UI/pages/my-supliers-page.component";
import { ListSuppliersComponent } from "./features/my-supliers/UI/templates/list-suppliers/list-suppliers.component";
import { AddSupplierComponent } from "./features/my-supliers/UI/templates/add-supplier/add-supplier.component";
import { SupplierDetailComponent } from "./features/my-supliers/UI/templates/supplier-detail/supplier-detail.component";
import { OperationInvoiceComponent } from "./shared/UI/atoms/operation-invoice/operation-invoice.component";
import { AddBankDialogComponent } from "./features/my-supliers/UI/organisms/add-bank-dialog/add-bank-dialog.component";
import { EmailRowOperationComponent } from "./shared/UI/molecules/email-row-operation/email-row-operation.component";
import { EmailBoDialogComponent } from "./features/invoices/UI/organisms/email-bo-dialog/email-bo-dialog.component";
import { EmailPreviewComponent } from "./features/invoices/UI/organisms/email-preview/email-preview.component";
import { NewObservationDialogComponent } from "./features/invoices/UI/organisms/new-observation-dialog/new-observation-dialog.component";

import { DeleteBankAccountsTemplate } from "./features/bank-accounts/UI/templates/delete-bank-accounts/delete-bank-accounts.template";
import { ConfigureInvoiceBankDialogComponent } from "./features/invoices/UI/organisms/configure-invoice-bank/configure-invoice-bank-dialog.component";
import { SignupSideImageComponent } from "./features/authentication/UI/organisms/signup-side-image/signup-side-image.component";
import { SignupGeneralFormComponent } from "./features/authentication/UI/organisms/signup-general-form/signup-general-form.component";
import { SigupInvestorFormComponent } from "./features/authentication/UI/organisms/sigup-investor-form/sigup-investor-form.component";
import { SignupClientStepComponent } from "./features/authentication/UI/organisms/signup-client-step/signup-client-step.component";
import { SignupTypeUserStepComponent } from "./features/authentication/UI/organisms/signup-type-user-step/signup-type-user-step.component";
import { StepComponent } from "./shared/UI/organisms/stepper/step/step.component";
import { StepperComponent } from "./shared/UI/organisms/stepper/stepper/stepper.component";
import { NewSignupComponent } from "./features/authentication/UI/templates/new-signup/new-signup.component";
import { NewSginupFormComponent } from "./features/authentication/UI/organisms/new-sginup-form/new-sginup-form.component";
import { StepContentDirective } from "./shared/UI/organisms/stepper/step-content.directive";
import { SignupGetTypeFormComponent } from "./features/authentication/UI/organisms/signup-get-type-form/signup-get-type-form.component";
import { TabStepperComponent } from "./shared/UI/organisms/stepper/tab-stepper/tab-stepper.component";
import { ArtworkFoundSolutionComponent } from "./features/authentication/UI/molecules/artwork-found-solution/artwork-found-solution.component";
import { ArtworkSelectAccountComponent } from "./features/authentication/UI/molecules/artwork-select-account/artwork-select-account.component";
import { ArtworkClientComponent } from "./features/authentication/UI/molecules/artwork-client/artwork-client.component";
import { ArtworkInvestorComponent } from "./features/authentication/UI/molecules/artwork-investor/artwork-investor.component";
import { SunatRucClientComponent } from "./shared/UI/molecules/sunat-ruc-client/sunat-ruc-client.component";
import { ClientLegalInformationComponent } from "./shared/UI/organisms/client-legal-information/client-legal-information.component";
import { ConfirmationModalComponent } from "./shared/UI/molecules/confirmation-modal/confirmation-modal.component";
import { SetupBarComponent } from "./shared/UI/organisms/setup-bar/setup-bar.component";

import { NgCircleProgressModule } from "ng-circle-progress";
import { InvoiceToCavaliComponent } from "./features/invoices/UI/organisms/invoice-to-cavali/invoice-to-cavali.component";
import { StatusesLabelComponent } from "./shared/UI/molecules/statuses-label/statuses-label.component";
import { IconLabelComponent } from "./shared/UI/atoms/icon-label/icon-label.component";
import { CavaliResponseDialogComponent } from "./features/invoices/UI/organisms/cavali-response-dialog/cavali-response-dialog.component";
import { GroupingInvoiceComponent } from "./features/invoices/UI/templates/grouping-invoice/grouping-invoice.component";
import { GroupingInvoicesCardComponent } from "./features/invoices/UI/organisms/grouping-invoices-card/grouping-invoices-card.component";
import { InvoiceGroupConfirmationComponent } from "./features/invoices/UI/organisms/invoice-group-confirmation/invoice-group-confirmation.component";
import { InvoiceDescriptOperationComponent } from "./features/invoices/UI/organisms/invoice-descript-operation/invoice-descript-operation.component";
import { investEmailVerifiedDisclaimerComponent } from "./shared/UI/molecules/invest-email-verified-disclaimer/invest-email-verified-disclaimer.component";
import { RiskPendingEvaluationsPageComponent } from "./features/risk-pending-evaluations/UI/pages/risk-pending-evaluations/risk-pending-evaluations-page.component";
import { ListEvaluationsComponent } from "./features/risk-pending-evaluations/UI/templates/list-evaluations/list-evaluations.component";
import { EvaluationDetailComponent } from "./features/risk-pending-evaluations/UI/templates/evaluation-detail/evaluation-detail.component";
import { CardUserRiskInformationComponent } from "./shared/UI/organisms/card-user-risk-information/card-user-risk-information.component";
import { TemplateUserDetailComponent } from "./features/risk-pending-evaluations/UI/organismos/template-user-detail/template-user-detail.component";
import { RoundedButtonActionComponent } from "./shared/UI/atoms/rounded-button-action/rounded-button-action.component";
import { AccountManagerIconComponent } from "./shared/UI/atoms/account-manager-icon/account-manager-icon.component";
import { LastEvaluationComponent } from "./shared/UI/atoms/last-evaluation/last-evaluation.component";
import { EvaluationInitListComponent } from "./features/risk-pending-evaluations/UI/organismos/evaluation-init-list/evaluation-init-list.component";
import { MontoringActiveDebtorsTableComponent } from "./features/risk-pending-evaluations/UI/organismos/montoring-active-debtors-table/montoring-active-debtors-table.component";
import { EvaluationsTableComponent } from "./features/risk-debtors/UI/organisms/evaluations-table/evaluations-table.component";
import { DateDictamentComponent } from "./shared/UI/organisms/date-dictament/date-dictament.component";
import { UiLibModule } from "@finsmart/ui-lib";
import { AddNoteDialogComponent } from "./features/risk-debtors/UI/organisms/add-note-dialog/add-note-dialog.component";
import { ChipComponent } from "./shared/UI/molecules/chip/chip.component";
import { FinalizeEvaluationComponent } from "./features/risk-pending-evaluations/UI/organismos/finalize-evaluation/finalize-evaluation.component";
import { EvaluationItemComponent } from "./features/risk-pending-evaluations/UI/organismos/evaluation-item/evaluation-item.component";
import { ReverseFilterByPipe } from "./shared/pipes/reverse-filter-by.pipe";
import { CapitalizeOrSpacingPipe } from "./shared/pipes/capitalize-or-spaceing.pipe";
import { ObservePayerComponent } from "./features/risk-debtors/UI/organisms/observe-payer/observe-payer.component";
import { EvaluationDocumentsDialogComponent } from "./features/risk-pending-evaluations/UI/organismos/evaluation-documents-dialog/evaluation-documents-dialog.component";
import { DeleteDocConfirmationComponent } from "./features/risk-pending-evaluations/UI/organismos/delete-doc-confirmation/delete-doc-confirmation.component";
import { NewPayersConfirmationComponent } from "./features/risk-debtors/UI/organisms/new-payers-confirmation/new-payers-confirmation.component";
import { TabComponent } from "./shared/UI/organisms/tabs/tab/tab.component";
import { TabItemComponent } from "./shared/UI/organisms/tabs/tab-item/tab-item.component";
import { TabBodyComponent } from "./shared/UI/organisms/tabs/tab-body/tab-body.component";
import { TabLabelComponent } from "./shared/UI/organisms/tabs/tab-label/tab-label.component";
import { InvoiceForSaleDialogComponent } from "./features/invoices/UI/organisms/invoice-for-sale-dialog/invoice-for-sale-dialog.component";
import { ConfirmInvoiceForSaleComponent } from "./features/invoices/UI/organisms/confirm-invoice-for-sale/confirm-invoice-for-sale.component";
import { RiskHeaderInformationComponent } from "./features/invoices/UI/organisms/risk-header-information/risk-header-information.component";
import { InvestScheduleAlertComponent } from "./features/opportunities/UI/organisms/invest-schedule-alert/invest-schedule-alert.component";
import { FormatDatePipe2 } from "./shared/pipes/format-date2.pipe";
import { RiskCompleteInformationDialogComponent } from "./features/risk-pending-evaluations/UI/organismos/risk-complete-information-dialog/risk-complete-information-dialog.component";
import { UpdatePadronConfirmationScreenComponent } from "./features/risk-pending-evaluations/UI/organismos/update-padron-confirmation-screen/update-padron-confirmation-screen.component";
import { OutlineButtonComponent } from "./shared/UI/atoms/outline-button/outline-button.component";
import { HistoricalInvestmentDialogComponent } from "./features/opportunities/UI/organisms/historical-investment-dialog/historical-investment-dialog.component";
import { AnnouncementCardComponent } from "./features/invoices/UI/organisms/announcement-card/announcement-card.component";
import { FormatTimePipe } from "./shared/pipes/format-time";
import { AddAnnouncementDialogComponent } from "./features/invoices/UI/organisms/add-announcement-dialog/add-announcement-dialog.component";
import { CommentOnConfirmationComponent } from "./features/invoices/UI/organisms/comment-on-confirmation/comment-on-confirmation.component";
import { FormatDateTimePipe } from "./shared/pipes/format-date-time";
import { ForwardannouncementComponent } from "./features/invoices/UI/organisms/forwardannouncement/forwardannouncement.component";
import { ForwardAnnouncementConfirmationComponent } from "./features/invoices/UI/organisms/forward-announcement-confirmation/forward-announcement-confirmation.component";
import { InvoiceAnnouncementTableComponent } from "./features/invoices/UI/organisms/invoice-announcement-table/invoice-announcement-table.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { InvertorResidencyDialogComponent } from "./shared/UI/organisms/invertor-residency-dialog/invertor-residency-dialog.component";
import { InfoSunatProcessComponent } from "./features/invoices/UI/organisms/info-sunat-process/info-sunat-process.component";
import { MotiveDialogComponent } from "./features/invoices/UI/organisms/motive-dialog/motive-dialog.component";
import { InvoiceMotiveConfirmationComponent } from "./features/invoices/UI/organisms/invoice-motive-confirmation/invoice-motive-confirmation.component";
import { AddContactsEmailsComponent } from "./features/risk-debtors/UI/organisms/add-contacts-emails/add-contacts-emails.component";
import { AddtelegramComponent } from "./features/risk-debtors/UI/organisms/addtelegram/addtelegram.component";
import { TelegramConfirmationComponent } from "./features/risk-debtors/UI/organisms/telegram-confirmation/telegram-confirmation.component";
import { ListOperationsComponent } from "./features/operations/UI/templates/list-operations/list-operations.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ApproveDepositDialogComponent } from "./features/invoices/UI/organisms/approve-deposit-dialog/approve-deposit-dialog.component";
import { OperationDetailComponent } from "./features/operations/UI/templates/operation-detail/operation-detail.component";
import { ConfirmatSetConditionsComponent } from "./features/invoices/UI/organisms/confirmat-set-conditions/confirmat-set-conditions.component";
import { AddContactConfirmationComponent } from "./features/my-clients/UI/organisms/add-contact-confirmation/add-contact-confirmation.component";
import { InvoiceDetailLandingComponent } from "./features/invoices/UI/templates/invoice-detail-landing/invoice-detail-landing.component";
import { PartialPaymentDialogComponent } from "./features/invoices/UI/organisms/partial-payment-dialog/partial-payment-dialog.component";
import { PartialPaymentConfirmationComponent } from "./features/invoices/UI/organisms/partial-payment-confirmation/partial-payment-confirmation.component";
import { ChooseEmailsDialogComponent } from "./features/invoices/UI/organisms/choose-emails-dialog/choose-emails-dialog.component";
import { NewPaginatorComponent } from "./shared/UI/organisms/new-paginator/new-paginator.component";
import { WsWidgetComponent } from "./shared/UI/molecules/ws-widget/ws-widget.component";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { CurrencyToSignPipe } from "./shared/pipes/currencySign.pipe";
import { RejectContactDialogComponent } from "./features/my-clients/UI/organisms/reject-contact-dialog/reject-contact-dialog.component";
import { SplitInvoiceDialogComponent } from "./features/invoices/UI/organisms/split-invoice-dialog/split-invoice-dialog.component";
import { InputCounterComponent } from "./shared/UI/organisms/input-counter/input-counter.component";
import { PartionItemComponent } from "./shared/UI/organisms/partion-item/partion-item.component";
import { ConfirmationSplitInvoiceComponent } from "./features/invoices/UI/templates/confirmation-split-invoice/confirmation-split-invoice.component";
import { AddInvoiceStep2Component } from "./features/invoices/UI/templates/add-invoice-step2/add-invoice-step2.component";
import { AddInvoiceStep1Component } from "./features/invoices/UI/templates/add-invoice-step1/add-invoice-step1.component";
import { ModalConfirmationCancelComponent } from "./features/invoices/UI/organisms/modal-confirmation-cancel/modal-confirmation-cancel.component";
import { AddInvoiceStep3Component } from "./features/invoices/UI/templates/add-invoice-step3/add-invoice-step3.component";
import { CrossSellingComponent } from "./shared/UI/pages/crossSelling/cross-selling.component";
import { ConfirmationDonwloadPopupComponent } from "./features/invoices/UI/organisms/confirmation-donwload-popup/confirmation-donwload-popup.component";
import { DisclaimerModalComponent } from "./shared/UI/molecules/disclaimer-modal/disclaimer-modal.component";
import { EvaluationInformationPopupComponent } from "./features/risk-pending-evaluations/UI/organismos/evaluation-information-popup/evaluation-information-popup.component";

import { ClickoutsideDirective } from "./shared/directives/clickoutside.directive";

@NgModule({
  declarations: [
    AppComponent,

    BankAccountsPage,
    ListBankAccountsTemplate,
    CreateBankAccountsTemplate,
    EditBankAccountsTemplate,
    BankAccountItemOrganism,

    PageTitleMolecule,
    TopBarOrganism,
    SideMenuOrganism,
    AuthPage,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,

    InputControl,
    SelectControl,
    RadiolistControl,
    CheckboxComponent,
    UploadComponent,
    DatePickerComponent,

    LoginFormComponent,

    NotFoundComponent,
    CrossSellingComponent,

    OpportunitiesListComponent,

    PayClientAdvanceDialogComponent,
    SideMenuItemComponent,

    AllowedRoleDirective,
    NoPropagationDirective,
    DragDropDirective,

    SafePipe,
    ShortNamePipe,
    NumberSpacingPipe,
    TruncatePipe,
    CapitalizeOnlyFirstLetter,
    FilterByPipe,
    FilterInPipe,
    FilterOutByPipe,
    SortPipe,
    FormatDatePipe,
    FormatDatePipe2,
    StringNextLinePipe,
    FormatTimePipe,
    FormatDateTimePipe,
    SpecialFormattingPipe,
    BankAccountFormOrganism,
    SaveConfirmationTemplateComponent,
    SaveResultComponent,
    SpinnerComponent,
    FinancialTransactionsPageComponent,
    ListFinancialTransactionsTemplateComponent,
    FinancialTransactionItemComponent,
    FinancialTransactionInvestItemComponent,
    FinancialTransactionFormComponent,
    FinancialTransactionInvestFormComponent,
    CreateFinancialTransactionDepositComponent,
    CreateFinancialTransactionWithdrawComponent,
    CreateFinancialTransactionInvestComponent,
    AvailableBalanceComponent,
    OperationsPageComponent,
    InvoicesPageComponent,
    MyClientsPageComponent,
    MySupliersPageComponent,
    ListInvoicesComponent,
    CreateInvoiceComponent,
    InvoiceItemClientComponent,
    InvoiceItemAdminComponent,
    InvoiceItemInvestorComponent,
    SystemSettingsComponent,
    AmountComponent,
    CurrencyComponent,
    InvoiceStatusComponent,
    FinancialTransactionStatusComponent,
    SolidLabelComponent,
    DateComponent,
    ShortenPipe,
    ToSpanishPipe,
    OpporunitiesPageComponent,
    CurrencyNamePipe,
    CurrencySymbolPipe,
    GenericOverlayHostComponent,
    DisplayFileComponent,
    OurBankAccountsComponent,
    UserProfilePageComponent,
    UserProfileTemplateComponent,
    ChangePasswordTemplateComponent,
    UpdateProfileFormComponent,
    ChangePasswordFormComponent,
    RoiComponent,
    ProgressBarComponent,
    InvestInInvoiceComponent,
    PayerComponent,
    PayerDescriptionComponent,
    InvoicesProgressBarComponent,
    InvestmentsPageComponent,
    ListInvestmentsComponent,
    RegisterFormComponent,
    InvoiceUploadComponent,
    InvoicesProgressBarComponent,
    RegisterFormComponent,
    FinancialTransactionAdminFormComponent,
    AvailableToInvestBalanceComponent,
    CollectionDatesComponent,
    AmountTotalComponent,
    InvestInfoDetailComponent,
    FastFormerComponent,
    FastFormerFormComponent,
    RoleSelectorComponent,
    FirstNameComponent,
    RadiolistCircleComponent,
    HowDidHearAboutUsComponent,
    SignupPageComponent,
    UsersPage,
    InvestorSignupTemplateComponent,
    ClientSignupTemplateComponent,
    SignupAlmostDoneComponent,
    SearchFormComponent,
    FilterSelectComponent,
    SystemSettingsPageComponent,
    ListTermsConditionsComponent,
    TermsConditionsItemComponent,
    CreateTermConditionComponent,
    TermConditionFormComponent,
    EditTermConditionComponent,
    EmptyOverlayHostComponent,
    CapitalizeFirstLettersPipe,
    IdentificationComponent,
    UsersListTemplateComponent,
    CreateUserTemplateComponent,
    EditUserTemplateComponent,
    UserItemComponent,
    EditActionComponent,
    ApproveActionComponent,
    UserActionComponent,
    UserFullDetailsComponent,
    AllowedActionsPipe,
    EmailConfirmationTemplateComponent,
    InvoiceCardClientComponent,
    AddInvoiceComponent,
    AddInvoiceStep1Component,
    AddInvoiceStep2Component,
    InvoiceListControlComponent,
    InvoiceDetailsComponent,
    InvoiceItemInvestorComponent,
    ConfigureInvoiceDialogComponent,
    ReviewRatesComponent,
    InvoiceDurationComponent,
    OperationInvoiceComponent,
    ConfirmInvestmentComponent,
    CollectInvoiceDialogComponent,
    HomeComponent,
    PasswordResetFormComponent,
    ArtworkDisplayComponent,
    FooterComponent,
    CreateFinancialTransactionDonateComponent,
    PaginatorComponent,
    FilterComponent,
    AutocompleteComponent,
    EditAmountsInvoceDialogComponent,
    ConfirmingEditAmountsInvoceDialogComponent,
    PhysicalInvoiceTableOrganism,
    RealtimeConnectionStatusComponent,
    ManualReloadButtonComponent,
    ListClientsComponent,
    AddClientComponent,
    RucSearchComponent,
    SunatRucItemComponent,
    ClientItemComponent,
    ClientDetailComponent,
    ClientInformationComponent,
    ContactDebtorItemComponent,
    InvoiceOperationsOverviewComponent,
    CollectionClientDatesComponent,
    InvoiceByDebtorItemComponent,
    AddContactDialogComponent,
    SlideToggleComponent,
    InvoiceAmountItemComponent,
    InvoiceInfoInvestmentComponent,
    AmmountBalanceComponent,
    ListSuppliersComponent,
    AddSupplierComponent,
    SupplierDetailComponent,
    OperationInvoiceComponent,
    AddBankDialogComponent,
    EmailRowOperationComponent,
    EmailBoDialogComponent,
    EmailPreviewComponent,
    NewObservationDialogComponent,
    SafeHtmlPipe,
    ConfigureInvoiceBankDialogComponent,
    DeleteBankAccountsTemplate,
    EmailPreviewHtmlComponent,
    SignupSideImageComponent,
    SignupGeneralFormComponent,
    SigupInvestorFormComponent,
    SignupClientStepComponent,
    SignupTypeUserStepComponent,
    StepComponent,
    StepperComponent,
    NewSignupComponent,
    NewSginupFormComponent,
    StepContentDirective,
    SignupGetTypeFormComponent,
    TabStepperComponent,
    ArtworkFoundSolutionComponent,
    ArtworkSelectAccountComponent,
    ArtworkClientComponent,
    ArtworkInvestorComponent,
    SunatRucClientComponent,
    ClientLegalInformationComponent,
    ConfirmationModalComponent,
    NewConfirmationModalComponent,
    PhoneConfirmationModalComponent,
    DisclaimerModalComponent,
    SetupBarComponent,
    InvoiceToCavaliComponent,
    StatusesLabelComponent,
    IconLabelComponent,
    CavaliResponseDialogComponent,
    GroupingInvoiceComponent,
    GroupingInvoicesCardComponent,
    InvoiceGroupConfirmationComponent,
    InvoiceDescriptOperationComponent,
    investEmailVerifiedDisclaimerComponent,
    RiskPendingEvaluationsPageComponent,
    RiskDebtorsPageComponent,
    ListEvaluationsComponent,
    EvaluationDetailComponent,
    CardUserRiskInformationComponent,
    TemplateUserDetailComponent,
    RoundedButtonActionComponent,
    AccountManagerIconComponent,
    ListRiskDebtorTemplateComponent,
    RiskDebtorItem,
    LastEvaluationComponent,
    EvaluationInitListComponent,
    MontoringActiveDebtorsTableComponent,
    RiskDebtorDetailComponent,
    EvaluationsTableComponent,
    DateDictamentComponent,
    AddNoteDialogComponent,
    ChipComponent,
    FinalizeEvaluationComponent,
    EvaluationItemComponent,
    ReverseFilterByPipe,
    CapitalizeOrSpacingPipe,
    ObservePayerComponent,
    EvaluationDocumentsDialogComponent,
    DeleteDocConfirmationComponent,
    NewPayersConfirmationComponent,
    TabComponent,
    TabItemComponent,
    TabBodyComponent,
    TabLabelComponent,
    InvoiceForSaleDialogComponent,
    ConfirmInvoiceForSaleComponent,
    RiskHeaderInformationComponent,
    InvestScheduleAlertComponent,
    DetailBoxComponent,
    RiskCompleteInformationDialogComponent,
    UpdatePadronConfirmationScreenComponent,
    OutlineButtonComponent,
    HistoricalInvestmentDialogComponent,
    AnnouncementCardComponent,
    AddAnnouncementDialogComponent,
    CommentOnConfirmationComponent,
    ForwardannouncementComponent,
    ForwardAnnouncementConfirmationComponent,
    InvoiceAnnouncementTableComponent,
    InvertorResidencyDialogComponent,
    InfoSunatProcessComponent,
    CreditNoteConfirmationComponent,
    MotiveDialogComponent,
    InvoiceMotiveConfirmationComponent,
    AddContactsEmailsComponent,
    AddtelegramComponent,
    TelegramConfirmationComponent,
    ListOperationsComponent,
    ChecklistModalComponent,
    ApproveDepositDialogComponent,
    OperationDetailComponent,
    ConfirmatSetConditionsComponent,
    AddContactConfirmationComponent,
    InvoiceDetailLandingComponent,
    PartialPaymentDialogComponent,
    PartialPaymentConfirmationComponent,
    ChooseEmailsDialogComponent,
    NewPaginatorComponent,
    ClientInvoiceCardComponent,
    WsWidgetComponent,
    EditAmountConfirmationComponent,
    CurrencyToSignPipe,
    RejectContactDialogComponent,
    SplitInvoiceDialogComponent,
    InputCounterComponent,
    PartionItemComponent,
    ConfirmationSplitInvoiceComponent,
    InvoiceTeaConfigurationDialogComponent,
    ModalConfirmationCancelComponent,
    AddInvoiceStep3Component,
    ConfirmationDonwloadPopupComponent,
    ClickoutsideDirective,
    EvaluationInformationPopupComponent,
  ],
  entryComponents: [
    SaveConfirmationTemplateComponent,
    BankAccountItemOrganism,
    SaveResultComponent,
    FinancialTransactionItemComponent,
    FinancialTransactionInvestItemComponent,
    DisplayFileComponent,
    GenericOverlayHostComponent,
    OurBankAccountsComponent,
    RoleSelectorComponent,
    ReviewRatesComponent,
    PhysicalInvoiceTableOrganism,
    ConfigureInvoiceBankDialogComponent,
    DeleteBankAccountsTemplate,
    CreditNoteConfirmationComponent,
    ChecklistModalComponent,
    EditAmountConfirmationComponent,
    InvoiceTeaConfigurationDialogComponent,
  ],
  imports: [
    ScrollingModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxSpinnerModule,
    HttpClientModule,
    ToastNotificationsModule,
    PdfViewerModule,
    AngularEditorModule,
    NgxIntlTelInputModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(effects),
    BrowserAnimationsModule,
    NgCircleProgressModule.forRoot({}),
    UiLibModule,
    TourMatMenuModule.forRoot(),
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeaderInterceptor,
      multi: true,
    },
    {
      provide: "googleTagManagerId",
      useValue: environment.googleTagManagerKey,
    },
    ShortenPipe,
    CurrencyNamePipe,
    CurrencySymbolPipe,
    ToSpanishPipe,
    FilterByPipe,
    FilterInPipe,
    SortPipe,
    NumberSpacingPipe,
    TruncatePipe,
    CapitalizeFirstLettersPipe,
    CapitalizeOnlyFirstLetter,
    FormatDatePipe,
    SafeHtmlPipe,
    StringNextLinePipe,
    FormatDatePipe2,
    FormatTimePipe,
    FormatDateTimePipe,
    SpecialFormattingPipe,
    CurrencyToSignPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
