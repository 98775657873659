<div class="modal">
    <div class="info">
        <ng-container *ngIf="mode=='validate' || mode=='operation'">
            <img width="48px" height="48px" [src]="'../../../../../assets/logos/whatsapp_logo.png'">
            <span style="margin-top: 12px;" class="info-title">Notificaciones por Whatsapp</span>
            <span style="margin-top: 12px;" class="info-instructions">Ingresa tu número de celular. Luego recibirás un
                mensaje vía Whatsapp para
                aceptar ¡Y listo! Empezarás a recibir notificaciones sobre tus operaciones con Finsmart.</span>
        </ng-container>
        <ng-container *ngIf="mode=='result'">
            <img [src]="'../../../../../assets/images/whatsapp_activation.png'" class="modal-image">
        </ng-container>
        <ng-container *ngIf="mode=='edit'">
            <img width="48px" height="48px" [src]="'../../../../../assets/icons/edit-new.svg'">
            <span style="margin-top: 12px;" class="info-title">Edita tu número de celular</span>
            <span style="margin-top: 12px;" class="info-instructions">Usamos tu número para que nuestros ejecutivos
                puedan ayudarte en todo el proceso del Factoring o Confirming.</span>
        </ng-container>
    </div>
    <div class="data">
        <ng-container *ngIf="mode=='validate' || mode=='edit' || mode=='operation'">
            <span class="data-title">{{mode=='edit' ? 'Ingresa tu nuevo
                número': 'Verifica tu número de celular'}}</span>

            <form [formGroup]="phoneForm" style="margin-top: 30px;">
                <div style="display: flex; justify-content: center;">
                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                        customPlaceholder="Ingresa tu número" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="data.user?.phoneCountry || CountryISO.Peru" [maxLength]="maxPhoneChars"
                        [phoneValidation]="true" [separateDialCode]="true"
                        [numberFormat]="PhoneNumberFormat.International" searchCountryPlaceholder="Elegir país"
                        formControlName="phone" (countryChange)="countryChange($event)">
                    </ngx-intl-tel-input>
                </div>
                <!-- data.user?.phoneCountry ||  -->
                <div *ngIf="mode=='validate' || mode=='operation'" class="disclaimer" style="margin-top: 20px;">
                    <lib-icon style="margin-right: 18px;" icon="info" color="blue"></lib-icon>
                    Podrás modificar el número cuando quieras en la sección "Mis datos personales”.
                </div>

                <div *ngIf="mode=='edit'" style="display: flex;align-items: center;margin-top: 20px;">
                    <app-checkbox formControlName="whatsappStatus" color="primary"
                        [checked]="!(data?.user?.whatsappStatus === 'disabled')">
                    </app-checkbox>
                    <span class="info-instructions">Deseo recibir notificaciones por Whatsapp de mis operaciones a este
                        nuevo número.</span>
                </div>
            </form>

            <div [ngClass]="isButtonDisabled ?  'validate-button-disabled' : 'validate-button'"
                style="margin-top: 16px;" (click)="verifyNumber()">{{data.mode == 'edit' ?
                'Guardar número':'Validar número'}}</div>

            <span class="cancel-button" style="margin-top: 28px;" (click)="cancel()">Cancelar</span>
        </ng-container>
        <ng-container *ngIf="mode=='result'">
            <span class="info-title result">Revisa tu Whatsapp</span>
            <span style="margin-top: 12px;" class="info-instructions">Hemos enviado un mensaje al número <span
                    style="font-weight: 400;">{{phoneForm.value.phone.dialCode || data.user.phoneCode}}
                    {{phoneForm.value.phone.number || data.user.phone}}</span>.
                Para empezar a recibir notificaciones, haz clic en <span style="font-weight: 400;">“Sí”</span>.</span>
            <div class="close-button" style="margin-top: 16px;" (click)="close()">{{resultText}}</div>
        </ng-container>
    </div>
</div>