<ng-container *ngIf="step === 0">
  <div  tourAnchor="step1" class="upload-file-container u-mt-4" (click)="fileInput.click()" appDragDrop
    (onFileDropped)="fileUpload($event)">
    <div class="upload-text">
      <p class="blue-text">Sube todas tus facturas a financiar</p>
      <lib-icon style="margin-left: 8px" icon="add-file" color="blue" size="m"></lib-icon>
    </div>

    <input hidden type="file" #fileInput (change)="fileUpload($event.target.files)" accept="text/xml" multiple
      (click)="fileInput.value = null" />
  </div>
  <div class="u-mt-2">
    <p style="font-weight: 400; font-size: 12px; color: #6c727c" class="u-mb-2">
      El formato de las facturas debe ser en .XMLs
    </p>
    <span class="time-upload">El tiempo para procesar las facturas dependerá de la cantidad de archivos.</span>
  </div>
</ng-container>

<ng-container *ngIf="step === 1">
  <div class="one-line space flex-items-center">
    <div>
      <p class="invoice-title">Facturas subidas</p>
    </div>

    <div class="d-flex flex-items-center">
      <div (click)="fileInput.click()" class="c-pointer button-title d-flex flex-items-center">
        <p class="u-mr-1">Subir más facturas</p>
        <img src="../../../../assets/icons/upload.png" alt="" srcset="" />
        <input hidden type="file" #fileInput (change)="fileUpload($event.target.files)" accept=".xml" multiple
          (click)="fileInput.value = null" />
      </div>
    </div>
  </div>
</ng-container>

