import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[stepContent]',
})
export class StepContentDirective {
  @Output() onNext = new EventEmitter();
  @Output() onPrevious = new EventEmitter();

  @HostListener('previousStep', ['$event']) previousEvent(data) {
    this.onPrevious.emit(data);
  }
  @HostListener('nextStep', ['$event']) forwordEvent(data) {
    this.onNext.emit(data);
  }

  constructor() {}
}
