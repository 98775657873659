import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  constructor() { }

  setIdentity(userId: string, data: any) {
    if ((window as any)?.hj) {
      (window as any)?.hj('identify', userId, data);
    }
  }

  newEvent(eventName: any) {
    if ((window as any)?.hj) {
      (window as any)?.hj('event', eventName);
    }
  }
}
