<h1>Agregar nota</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <app-input formControlName="note" [default]="data?.note" autocomplete="off" type="text" label="Nota"></app-input>
    </div>

    <input type="submit" class="button button-dark-blue" value="Confirmar" />
    <input type="button" class="button button-gray" value="Cancelar" (click)="cancel()" />
</form>
