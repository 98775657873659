import { createAction, props } from '@ngrx/store';

export const LoadAll = createAction('[Invoices] Load All');
export const LoadAllSuccess = createAction(
  '[Invoices] Loaded All Success',
  props<{ entities: any }>()
);
export const LoadAllOpportunities = createAction(
  '[Invoices] Load All Opportunities'
);
export const LoadAllOpportunitiesSuccess = createAction(
  '[Invoices] Loaded All Opportunities Success',
  props<{ opportunities: any }>()
);
export const LoadAllOpportunitiesFailed = createAction(
  '[Invoices] Loaded All Opportunities Failed',
  props<{ error: any }>()
);

export const LoadAllOperations = createAction('[Invoices] Load All Operations');
export const LoadAllOperationsSuccess = createAction(
  '[Invoices] Loaded All Operations Success',
  props<{ operations: any }>()
);
export const LoadAllOperationsFailed = createAction(
  '[Invoices] Loaded All Operations Failed',
  props<{ error: any }>()
);

export const UpdateOperations = createAction(
  '[Invoices] UpdateOperations',
  props<{ operation: any }>()
);

export const RemoveOperations = createAction(
  '[Invoices] RemoveOperations',
  props<{ operation: any }>()
);

export const Reset = createAction('[Invoices] Reset');
export const Saving = createAction(
  '[Invoices] Saving',
  props<{
    entity: any;
    mode: 'edit' | 'create' | 'createInvoice' | 'createInvoiceNew';
  }>()
);
export const SaveSuccess = createAction(
  '[Invoices] Save Success',
  props<{ entity: any }>()
);
export const SaveFailed = createAction(
  '[Invoices] Save Failed',
  props<{ error: any }>()
);
export const OpportunityUpdated = createAction(
  '[Invoices] OpportunityUpdated',
  props<{ changes: any }>()
);

export const InvestmentsUpdated = createAction(
  '[Invoices] InvestmentsUpdated',
  props<{ changes: any }>()
);

export const SavingEmail = createAction(
  '[Invoices] Email Saving',
  props<{ entity: any; mode: 'edit' | 'create' }>()
);

export const SaveContact = createAction(
  '[Invoices] Contact Saving',
  props<{ entity: any; mode: 'edit' | 'create' | 'delete' }>()
);
export const ValidContact = createAction(
  '[Invoices] Contact Valid',
  props<{ entity: any; mode: 'edit' | 'create' | 'delete' }>()
);

export const DeleteObs = createAction(
  '[Invoices] Delete Obs',
  props<{ entity: any; mode: 'edit' | 'create' }>()
);

export const SendingCavali = createAction(
  '[Invoices] Sending cavali',
  props<{ entity: any; mode: 'edit' | 'create' }>()
);

export const SaveAnnouncement = createAction(
  '[Invoices] Announcement saving',
  props<{ entity: any; mode: 'edit' | 'create' }>()
);

export const ForwardAnnouncement = createAction(
  '[Invoices] Announcement forward',
  props<{ entity: any; mode: 'edit' | 'create' }>()
);

export const ResendAnnouncementEmail = createAction(
  '[Invoices] Resending announcement email',
  props<{ entity: any; mode: 'create' }>()
);
