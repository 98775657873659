import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-evaluation-item',
  templateUrl: './evaluation-item.component.html',
  styleUrls: ['./evaluation-item.component.scss']
})
export class EvaluationItemComponent implements OnInit {

  @Input() entity;
  constructor() { }

  ngOnInit(): void {
    console.log(this.entity)
  }

  mapCreditLine(val) {
    switch (val) {
      case 'line':
        return 'L';
      case 'punctual':
        return 'P';
      default:
        return '-';
    }
  }

  getDate(){
    if(this.entity[0].detailUpdatedAt){
      return this.entity[0].detailUpdatedAt
    }else if(this.entity[0].debtor?.detailUpdatedAt){
      return this.entity[0].debtor?.detailUpdatedAt
    }else {
      return ''
    }
  }

}
