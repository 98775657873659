import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-invoice-motive-confirmation',
  templateUrl: './invoice-motive-confirmation.component.html',
  styleUrls: ['./invoice-motive-confirmation.component.scss']
})
export class InvoiceMotiveConfirmationComponent implements OnInit {
  @Output() onApprove = new EventEmitter();
  @Output() onReject = new EventEmitter();
  @Input() entity;

  constructor() { 
   
  }

  ngOnInit(): void {
    console.log("this.rejectionReason", this.entity)
  }

  approve() {
    this.onApprove.emit();
  }
  reject() {
    this.onReject.emit();
  }

}
