export enum INVOICE_STATUS {
    "IN_PROCESS" = 'in process',
    "APPROVED" = 'approved',
    "DISAPPROVED" = 'disapproved',
    "PENDING CONFIRMATION" = 'pending confirmation',
    "FOR SALE" = 'for sale',
    "PARTIALLY SOLD" = 'partially sold',
    "SALE_CLOSED" = 'sale closed',
    "AWAITING_COLLECTION" = 'awaiting collection',
    "COLLECTED" = 'collected',
    "FINALIZED" = 'finalized'
}
