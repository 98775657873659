import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, AfterContentChecked, AfterViewChecked, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-save-confirmation-template',
  templateUrl: './save-confirmation-template.component.html',
  styleUrls: ['./save-confirmation-template.component.scss']
})
export class SaveConfirmationTemplateComponent implements OnInit {
  @Input() entity?;
  @Input() entityDisplayComponent?;
  @Input() title;
  @Input() width = '40%';
  @Input() buttonTitle = 'Confirmar';
  @Input() buttonBackTitle = 'Cancelar';
  //@Input() mode: 'edit' | 'create' | 'delete';
  @Input() method: 'details' | 'question';
  @Input() entityType;
  @Input() disclaimer;
  @Input() returnUrl;
  @Input() isDisabled = false;
  @Output() onConfirmationCancelled = new EventEmitter();
  @Output() onConfirmation = new EventEmitter();
  @Output() onReturn = new EventEmitter();

  @ViewChild("entityDisplay", { read: ViewContainerRef, static: true }) container;
  componentRef: ComponentRef<any>;
  displayErrorInvestment
  constructor(private resolver: ComponentFactoryResolver) { }

  ngOnInit() {
    if (this.method === 'details') {
      this.container.clear();

      if (this.resolver && this.entityDisplayComponent) {
        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.entityDisplayComponent);

        this.componentRef = this.container.createComponent(factory);

        this.componentRef.instance.entity = this.entity;
        this.componentRef.instance.noControls = true;
        this.componentRef.instance.isDynamical = true;
      }

    }
  }

  cancel() {
    this.onConfirmationCancelled.emit();
  }
  confirm() {
    if (this.componentRef?.instance?.needsConfirmation) {
      if (this.componentRef?.instance?.checked) {
        if (!this.isDisabled) {
          this.onConfirmation.emit();
        }
        this.isDisabled = true;
      } else {
        this.componentRef.instance.hasError = true;
      }
    } else if(this.componentRef?.instance?.displayErrorInvestment){
        this.isDisabled = true;
        this.displayErrorInvestment = true
    } else{
      if (!this.isDisabled) {
        this.onConfirmation.emit();
      }

      this.isDisabled = true;
    }
  }

}
