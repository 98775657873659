<h1>Completa los datos faltantes</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <h6>Información del pagador</h6>
        <app-select formControlName="sectorFilled" [defaultOption]="data?.evaluation?.debtor?.sector || data?.evaluation?.sectorFilled" [options]="sectors" valueField="sector" [isDisabled]="sectorDisabled"  textField="Sector"
            label="Sector">
        </app-select>

        <app-input formControlName="activity" [default]="data?.evaluation?.debtor?.activity || data?.evaluation?.activity"  label="Actividad"></app-input>

        <h6>Información de SUNAT</h6>

        <app-select formControlName="regimeType" [options]="regimeTypes"  [defaultOption]="data?.evaluation?.regimeType"  valueField="value" textField="label"
        label="Padrón">
        </app-select>

        <app-input type="number" [default]="data?.evaluation?.employeeCount" formControlName="employeeCount" label="Nro de trabajadores"></app-input>
    </div>

    <div class="button-container button-direction-column">
        <fs-ui-button  [action]="'submit'" (click)="onSubmit()"  [label]="'Siguiente'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>