import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../../state/auth.action-types';
import { TermsAndConditionsService } from 'src/app/features/system-settings/services/terms-and-conditions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { capitalizeFirstLetters } from '../../../../../shared/util/string.util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  jwtHelper: JwtHelperService;

  pageTitle = "Iniciar sesión";
  // callToAction = {
  //   text: '¿No tienes cuenta?',
  //   linkLabel: 'Regístrate ahora',
  //   link: '../signup'
  // }
  decodedToken
  showSucces =false
  email
  name
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,


  ) {
    this.jwtHelper = new JwtHelperService();
   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      try {
        this.decodedToken = this.jwtHelper.decodeToken(params.token);
        if(this.decodedToken){
          this.name = this.decodedToken.names
          this.email = this.decodedToken.email
          if(this.decodedToken && this.email && this.name){
            this.showSucces = true
            this.pageTitle = `Bienvenido, ${capitalizeFirstLetters(this.name)}`
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    });
  }

  submitted(formValue) {
    //console.log("send", formValue)
    this.store.dispatch(AuthActions.LogIn({loginInfo: formValue}));
  }

}
