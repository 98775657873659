import { createAction, props } from '@ngrx/store';

export const LoadAll = createAction(
    '[Evaluations] Load All',
);
export const LoadAllSuccess = createAction(
    '[Evaluations] Loaded All Success',
    props<{ entities: any }>()

);
export const Reset = createAction(
    '[Evaluations] Reset'
);
export const Saving = createAction(
    '[Evaluations] Saving',
    props<{entity: any, mode: 'edit' | 'create' }>()
);
export const DeleteDocument = createAction(
    '[Evaluations] Delete document',
    props<{entity: any, mode: 'edit' }>()
);
export const SaveSuccess = createAction(
    '[Evaluations] Save Success',
    props<{entity: any}>()
);
export const SaveFailed = createAction(
    '[Evaluations] Save Failed',
    props<{error: any}>()
);

export const SavingNote = createAction(
    '[Evaluations] Saving Note',
    props<{entity: any, mode: 'edit' | 'create' }>()
);

export const Request = createAction(
    '[Evaluations] Request evaluation',
    props<{entity: any, mode: 'edit' | 'create' }>()
);

