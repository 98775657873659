<h1>{{mode === 'edit' ? 'Editar ingreso' : 'Nuevo ingreso'}}</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <ng-container>
            <div class="comment-container">
                <h6>Registra la actualización</h6>
                <!-- <mat-form-field [style.fontSize]="12" appearance="outline">
                        <textarea matInput
                        placeholder="Comentario..."
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        formControlName="message"
                        cdkAutosizeMinRows="15"
                        cdkAutosizeMaxRows="15"
                        [(ngModel)]="message">
                        </textarea>
                        <mat-hint *ngIf="form.controls.message?.touched && form.controls.message?.invalid">
                            <p [ngClass]="{'custom-mat-invalid-style': form.controls.message?.invalid === true}">Comentario (obligatorio)</p>
                        </mat-hint>
                </mat-form-field> -->

                <div class="previewBox">
                    <angular-editor formControlName="message" [config]="editorConfig" [(ngModel)]="message"></angular-editor>
                </div>
                <mat-hint *ngIf="form.controls.message?.touched && form.controls.message?.invalid">
                    <p class="custom-mat-invalid-style" >Obligatorio</p>
                </mat-hint>
            </div>
            <div class="intercom-button-upload">
                <app-upload formControlName="file" [default]="data?.announcementDetail?.file" [getFileName]="true"
                    (fileNameLoaded)="setFileName($event)" initialLabel="Adjuntar archivo"
                    loadedLabel="Archivo cargado"></app-upload>
            </div>
        </ng-container>

    </div>


    <div class="button-container button-direction-column">
        <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Siguiente'"></fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>