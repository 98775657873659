<form class="container" [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <div style="margin-top: 24px;" class="intercom-form-banks" class="container">
        <app-select formControlName="name" [defaultOption]="selectedBankAccount?.name" [options]="bankOptions"
            label="Banco">
        </app-select>
        <app-input formControlName="number" autocomplete="off" [default]="selectedBankAccount?.number" type="number"
            label="Número de cuenta"></app-input>
        <app-input formControlName="cci" autocomplete="off" [default]="selectedBankAccount?.cci" type="number"
            label="Código de cuenta interbancario"></app-input>
    </div>
    <div class="intercom-bank-currencies">
        <mat-radio-group
            [ngClass]="{ 'invalid-radio-button': (form.controls.currency.invalid && form.controls.currency.touched) }"
            formControlName="currency">
            <mat-radio-button [disabled]="!availableCurrency.includes('pen')" value="pen"><span class="title"
                    [style.color]="!availableCurrency.includes('pen') ? '#6C727C' : 'inherit'">PEN</span></mat-radio-button>
            <mat-radio-button [disabled]="!availableCurrency.includes('usd')" style="margin-left: 48px;"
                value="usd"><span [style.color]="!availableCurrency.includes('usd') ? '#6C727C' : 'inherit'"
                    class="title">USD</span></mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="form-actions">
        <fs-ui-button [ngStyle]="{ 'width': isButtonFullWidth ? '100%' : '' }" (click)="submit()"
            class="add-bank-step-one" [label]="'Guardar'"></fs-ui-button>
    </div>
</form>