<table style="margin-top: 66px;" *ngIf="entity?.originalInvoice?.paymentDate !== entity?.newData?.paymentDate">
    <thead>
        <th style="width: 30%;">
            <span class="table-title">Fecha de pago</span>
        </th>
        <th style="width: 35%;">
            Anterior
        </th>
        <th style="width: 35%;">
            Actual
        </th>
    </thead>
    <tbody>
        <tr>
            <td></td>
            <td>
                <span>{{entity?.originalInvoice?.paymentDate | formatDate}}</span>
            </td>
            <td>
                <span>{{entity?.newData?.paymentDate | formatDate}}</span>
            </td>
        </tr>
    </tbody>
</table>

<table style="margin-top: 66px;" *ngIf="amountChanges.length">
    <thead>
        <th style="width: 30%;">
            <span class="table-title">Facturas</span>
        </th>
        <th style="width: 35%;">
            Monto neto anterior
        </th>
        <th style="width: 35%;">
            Monto neto actual
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of amountChanges">
            <td>{{item.code}}</td>
            <td>
                <span>{{item.originalAmount}}</span>
            </td>
            <td>
                <span>{{item.newAmount}}</span>
            </td>
        </tr>
    </tbody>
</table>

<div class="cushion" style="margin-top: 30px;">
    <span>Colchón de días</span>
    <span style="font-weight: 400;margin-left: 16px;"
        [ngStyle]="{'color': getCushionColor(evaluationDays)}">{{evaluationDays}}
        {{evaluationDays == '1' ? 'día'
        : 'días'}}</span>
</div>