<div class="container" class="intercom-item{{key}}">
    <div class="item">
        <ng-container *ngIf="mode === 'published' || mode ===  'not_published'">
            <lib-opportunity-card [entity]="entity" context="opportunities" [mode]="mode"
                (showHistorical)="openHistoric()">
            </lib-opportunity-card>
        </ng-container>
        <!--div class="bank-and-currency row-operation">
            <div class="bank-container">
                <p class="tiny bank-label">Empresa pagadora</p>
                <p class="bank-name company-name mw-200">{{entity.debtorCompanyName || entity.debtor.companyName | uppercase }}
                </p>
            </div>
           
            <div class="bank-container row-operation" *ngIf="mode === 'opportunity'">
                <div class="bank-container row-button">
                    <app-outline-button (onClick)="openHistoric($event)" label="Mi historial"></app-outline-button>
                </div>
                <lib-rating *ngIf="entity?.evaluation" [rating]="entity?.evaluation?.rating"></lib-rating>
            </div>
            <div class="bank-container row-operation" *ngIf="mode !== 'opportunity'">
                <app-operation-invoice [mode]="'mobile'" [isConfirming]="entity.isConfirming"></app-operation-invoice>
            </div>
        </div-->
        <ng-container *ngIf="mode === 'actual' || mode === 'delayed' || mode === 'historic'">
            <ng-container *ngIf="investmentsSum$ as investmentsSum">
                <lib-investment-card [investmentSum]="investmentsSum" [isNewAnnouncement]="field?.isNewAnnouncement"
                    [entity]="entity" context="investment" [mode]="mode">
                </lib-investment-card>

                <!--app-invoices-progress-bar [status]="entity.status" [amount]="investmentsSum"
                    [roi]="this.invoiceService.calculateROI(investmentsSum, entity.tem, entity.minimumDuration)"
                    [percentage]="this.invoiceService.datesPercentage(entity.advancePaymentDate, entity.paymentDate)"
                    [dates]="entity.minimumDuration" [toBeCollectedIn]="entity.toBeCollectedIn"
                    [totalAmount]="entity.advanceAmount" [moraDays]="entity.moraDays" [type]="mode">
                </app-invoices-progress-bar-->
            </ng-container>
        </ng-container>

    </div>
</div>