import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { Observable } from 'rxjs';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';

@Component({
  selector: 'app-opporunities-page',
  template: `
  <div class="intercom-container-op">
    <router-outlet></router-outlet>
  </div>
  `
})
export class OpporunitiesPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
