import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Injector, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.states';
import { bankAccountById } from 'src/app/features/bank-accounts/state/bank-accounts.selectors';
import { balanceAvailable, balanceAvailableByUserId } from 'src/app/features/financial-transactions/state/financial-transactions.selectors';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SufficientBalanceValidator {

    constructor(private store: Store<AppState>) { }

    checkBalanceViaBankAccount(amount, selectedBankAccountId, isAdmin, forUserId) {
        let hasSufficientBalance = false;

        this.store.select(bankAccountById(selectedBankAccountId))
        .pipe(take(1))
        .subscribe(bankAccount => {
            let currency = bankAccount.currency;
            hasSufficientBalance = this.checkBalance(amount, currency, isAdmin, forUserId);
        });

        return hasSufficientBalance;
    }

    checkBalanceViaCurrency(amount, currency, isAdmin, forUserId) {
        return this.checkBalance(amount, currency, isAdmin, forUserId);
    }


    checkBalance(amount, currency, isAdmin, forUserId) {
        let hasSufficientBalance = false;

        if(isAdmin) {
            if(forUserId) {
                this.store.select(balanceAvailableByUserId(currency, forUserId))
                .pipe(take(1))
                .subscribe(balance => {
                    hasSufficientBalance = (balance >= amount) ? true : false;
                });    
            } else {
                hasSufficientBalance =  false;
            }
        } else {
            this.store.select(balanceAvailable(currency))
            .pipe(take(1))
            .subscribe(balance => {
                hasSufficientBalance = (balance >= amount) ? true : false;
            });    
        }

        return hasSufficientBalance;
    }


    isSufficient(isAdmin): ValidatorFn {
        return (control: AbstractControl) => {
            let hasSufficientBalance = false;

            if(control.parent) {
                const selectedBankAccountId = control.parent.controls['bankAccount']?.value;
                const selectedCurrency = control.parent.controls['currency']?.value;

                let forUserId;

                if(isAdmin) {
                    forUserId = control.parent.controls['user'].value;
                }

                if(selectedBankAccountId) {
                    hasSufficientBalance = this.checkBalanceViaBankAccount(Number(control.value), selectedBankAccountId, isAdmin, forUserId)
                } else if(selectedCurrency) {
                    hasSufficientBalance = this.checkBalanceViaCurrency(Number(control.value), selectedCurrency, isAdmin, forUserId)                
                } else {
                    return null;
                }
            }
            
            if (!hasSufficientBalance) {
                return { sufficientBalance: true };
            }

            return null;
        }  
    }
}
