import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-date-dictament',
  templateUrl: './date-dictament.component.html',
  styleUrls: ['./date-dictament.component.scss']
})
export class DateDictamentComponent implements OnInit {

  @Input() entity;
  constructor() { }

  ngOnInit(): void {
  }

  mapStatus(val) {
    switch (val) {
      case 'approved':
        return { label: "Aprobado", color: 'green' };
      case 'rejected':
        return { label: "Denegado", color: 'red' };
      case 'pending':
          return { label: "Pendiente", color: 'yellow' };
      case 'observation':
        return { label: "En observación", color: 'dark-gray' };
      default:  
        return { label: "Sin evaluación", color: 'dark-gray' };
    }
  }

  getFormatDate(momentDate) {
    if(momentDate){
      const date = moment(momentDate).format('DD/MMM/YYYY');
      let month = date.substring(
        date.indexOf("/") + 1,
        date.lastIndexOf("/")
      );
      month = month.toUpperCase().replace('.', '');
      return date.substr(0, date.indexOf("/") + 1) + month + date.substr(date.lastIndexOf("/"))
    }else{
      return '---';
    }
  }

  getAccountName(ev:any){
    // console.log(ev.invoiceNetAmount)
     if(ev.invoiceNetAmount){
       return ev?.accountManagerName
     }else{
       return ev?.createdBy?.names + ' ' + ev.createdBy?.familyNames
     }
    // console.log(ev)
   }
  


}
