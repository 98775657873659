import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-term-condition-form',
  templateUrl: './term-condition-form.component.html',
  styleUrls: ['./term-condition-form.component.scss'],
})
export class TermConditionFormComponent implements OnInit {
  form: FormGroup;
  @Input() termSelected;
  @Input() mode: 'edit' | 'create';
  @Output() onSubmit = new EventEmitter();

  termsOptions = [
    { label: 'Cuenta usuario', value: 'user account' },
    { label: 'Inversión', value: 'investment' },
    {
      label: 'Configuración Factura Factoring',
      value: 'invoice configuration',
    },
    {
      label: 'Configuración Factura Confirming',
      value: 'invoice configuration confirming',
    },
  ];
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.mode === 'create') {
      this.form = this.fb.group({
        type: ['', [Validators.required]],
        title: ['', [Validators.required]],
        description: ['', [Validators.required]],
        file: ['', [Validators.required]],
      });
    } else if (this.mode === 'edit') {
      this.form = this.fb.group({
        title: ['', [Validators.required]],
        description: ['', [Validators.required]],
        file: ['', [Validators.required]],
      });
    }
  }

  submit(evt) {
    evt.preventDefault();

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      if (this.mode === 'create') {
        this.onSubmit.emit({ ...this.form.value });
      } else if (this.mode === 'edit') {
        this.onSubmit.emit({ _id: this.termSelected._id, ...this.form.value });
      }
    }
  }

  calculateSubmitLabel() {
    if (this.mode === 'create') {
      return 'Crear Término';
    } else if (this.mode === 'edit') {
      return 'Modificar Término';
    }
  }
}
