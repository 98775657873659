<div class="container">
    <div class="item">
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny tiny-title">{{ (transaction.type === 'investment return' && transaction.retention) ?
                    'Retorno Neto' : (transaction.type | toSpanish : 'MALE' | capitalizeFirstLetters) }}</p>
                <ng-container *allowedRoles="['admin','super admin']">
                    <p class="bank-account-number">
                        {{ transaction.user.names | capitalizeFirstLetters }} {{ transaction.user.familyNames |
                        capitalizeFirstLetters }}
                        <span *ngIf="!noControls">
                            <div class="tooltip-container">
                                <img class="sub-filter-icon" (click)="filter(transaction.user)"
                                    src="../../../../../../assets/icons/filters/user.png" />
                                <span class="tooltiptext">Filtrar por inversionista</span>
                            </div>
                            <div class="tooltip-container">
                                <img class="sub-filter-icon" (click)="filter(transaction.user, transaction.type)"
                                    src="../../../../../../assets/icons/filters/filter.png" />
                                <span class="tooltiptext">Filtrar por inversionista y el mismo tipo</span>
                            </div>
                        </span>
                    </p>
                </ng-container>
                <ng-container *allowedRoles="['investor']">
                    <p *ngIf="transaction.type === 'investment return' || transaction.type === 'investment'"
                        class="bank-account-number">{{ transaction.invoice.debtorCompanyName ||
                        transaction.invoice.debtor.companyName | uppercase }} ({{
                        transaction.invoice.physicalInvoices.length > 1 ? transaction.invoice.physicalInvoices[0].code +
                        '..' : transaction.invoice.physicalInvoices[0].code }})</p>
                    <p *ngIf="transaction.type === 'deposit' || transaction.type === 'withdraw'"
                        class="bank-account-number">{{ transaction.bankAccount.name | uppercase }} {{
                        transaction.bankAccount.number | shorten }}</p>
                    <p *ngIf="transaction.type === 'donate'" class="bank-account-number">Acción Emprendedora</p>
                    <p *ngIf="transaction.type === 'retention'" class="bank-account-number">Retención SUNAT</p>
                </ng-container>

                <ng-container *ngIf="noControls">
                    <ng-container *allowedRoles="['admin','super admin']">
                        <p *ngIf="transaction.type === 'deposit' || transaction.type === 'withdraw'"
                            class="confirm-bank-account-number">{{ transaction.bankAccount.name | uppercase }} {{
                            transaction.bankAccount.number | shorten }}</p>
                    </ng-container>
                </ng-container>
            </div>
            <div *ngIf="!noControls" class="creation-date">
                <app-date title="Fecha de transferencia" [date]="transaction.createdAt"></app-date>
            </div>
        </div>

        <div class="row">
            <div class="return-amount">
                <div>
                    <p class="tiny tiny-title">Monto</p>
                    <lib-money [amount]="transaction.netAmount || transaction.amount"
                        [currency]="transaction.currency || 'pen'"></lib-money>
                </div>
                <lib-tooltip width="200" [content]="getRetentionText()" *ngIf="transaction.retention"
                    class="retention-tooltip">
                    <div class="retention">
                        <img class="retention-icon" src="../../../../../../assets/icons/final/retention.svg" />
                        <span class="tiny-amount">{{ transaction.retention.amount }}</span>
                    </div>
                </lib-tooltip>
                <lib-tooltip *ngIf="transaction.fiscalCredit" width="200" [content]="getFiscalCreditText()"
                    class="retention-tooltip">
                    <lib-icon style="margin-left: 4px;" class="info-icon" icon="info"></lib-icon>
                </lib-tooltip>
            </div>
            <div>
                <div *ngIf="entity.status" class="status">
                    <!-- <lib-tooltip *ngIf="entity.rejectionReason; else noRejectionReason" width="200"
                        [content]="entity.rejectionReason | capitalizeOnlyFirstLetter" class="retention-tooltip">

                        <app-financial-transaction-status [status]="transaction.status">
                        </app-financial-transaction-status>
                    </lib-tooltip> -->

                    <!-- <ng-template #noRejectionReason> -->
                    <app-financial-transaction-status [status]="transaction.status">
                    </app-financial-transaction-status>
                    <!-- </ng-template> -->
                </div>
                <ng-container *allowedRoles="['admin','super admin']">
                    <div *ngIf="entity.documentNumber" class="document-number">
                        <p class="tiny tiny-title">Número de documento</p>
                        <p class="doc-num">{{ entity.documentNumber }}</p>
                    </div>
                    <div *ngIf="entity.rejectionReason && isDynamical" style="margin-top: 7px;font-size: 12px;">
                        {{entity.rejectionReason | capitalizeOnlyFirstLetter}}
                    </div>
                </ng-container>
            </div>

        </div>



        <div *ngIf="transaction.type === 'withdraw' && noControls" class="row reason-row">
            <div>
                <p class="tiny tiny-title">Motivo</p>
                <span class="reason">{{ (transaction.reason && transaction.reason !== "" && transaction.reason !==
                    "otros" ) ?
                    (transaction.reason + '.' | capitalizeOnlyFirstLetter) : (transaction.otherReason !== "" &&
                    transaction.reason === "otros") ? (transaction.otherReason + '.' | capitalizeOnlyFirstLetter) :
                    "---" }}</span>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!noControls">
        <div class="manage" *allowedRoles="allowedRoles">
            <span class="action-icon action-accept" *ngIf="entity.status === 'in process'" (click)="approve()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <g fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M2 20 L12 28 30 4" />
                    </g>
                </svg>
            </span>
            <span class="action-icon action-reject" *ngIf="entity.status === 'in process'" (click)="reject()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <g fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M2 30 L30 2 M30 30 L2 2" />
                    </g>
                </svg>
            </span>
            <span class="action-icon action-show-file" *ngIf="entity.type === 'deposit'" (click)="showAttachement()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <g fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M6 2 L6 30 26 30 26 10 18 2 Z M18 2 L18 10 26 10" />
                    </g>
                </svg>
            </span>
        </div>
    </ng-container>
</div>