<ng-container *ngIf="debtorStats">
    <ng-container>
        
        <div class="container">
            <div class="row toggle" *ngIf="displayToggle">
                <app-slide-toggle  [firstLabel]="'pen'" [secondLabel]="'usd'" (selectionChanged)="setCurrency($event)"></app-slide-toggle>
            </div>
            <app-invoice-amount-item [debtorStats]="debtorStats"></app-invoice-amount-item>
            <div class="row-left mt">
                <div>
                    <p *ngIf="currency == 'usd'" class="lh">Promedio de mora en operaciones finalizadas (USD)</p>
                    <p *ngIf="currency == 'pen'" class="lh">Promedio de mora en operaciones finalizadas (PEN)</p>
                </div>
                <div class="amount" *ngIf="debtorStats">
                    <app-solid-label width="100" background="dark-gray" [content]="debtorStats.averageDelayOfFinalizedInvoices">
                    </app-solid-label>
                </div>
            </div>
        </div>
        
    </ng-container>

    <ng-template #no_summary_invoices>
        <div class="container-disclaimer">
            <p class="disclaimer">Este cliente no tiene operaciones</p>
        </div>
    </ng-template>
</ng-container>