import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { SaveConfirmationTemplateComponent } from '../ui/save-confirmation-template/save-confirmation-template.component';

export const createOverlay = (component: ComponentType<any>, overlay: Overlay) => {
    let overlayRef;
    let portalComponent;
    let componentRef;

    portalComponent = new ComponentPortal(component);
    document.documentElement.style.overflow = 'hidden';
    document.getElementById("main-container").style.display = "none";
    overlayRef = overlay.create({
        hasBackdrop: true,
        backdropClass: 'dark-backdrop',
        panelClass: ['full-width', 'reset-display'],
    });
    componentRef = overlayRef.attach(portalComponent);
    return {
        component: componentRef.instance,
        overlayRef: overlayRef
    }
}

export const createOverlayModal = (component: ComponentType<any>, overlay: Overlay) => {
    let overlayRef;
    let portalComponent;
    let componentRef;

    portalComponent = new ComponentPortal(component);

    overlayRef = overlay.create({
        hasBackdrop: false,
        backdropClass: '',
        panelClass: ['full-width', 'reset-display'],
    });
    componentRef = overlayRef.attach(portalComponent);
    return {
        component: componentRef.instance,
        overlayRef: overlayRef
    }
}
export const switchPortal = (component: ComponentType<any>, overlayRef: OverlayRef) => {
    const portalComponent = new ComponentPortal(component);
    overlayRef.detach();
    const portalRef = overlayRef.attach(portalComponent);

    return portalRef;
}

export const deleteOverlay = (overlayRef: OverlayRef) => {
    console.log(overlayRef);
    overlayRef.detach();
    overlayRef.dispose();
}

export const resolveDisplayComponent = (component, resolver, entity) => {

}
export const getConfirmationTitle = (mode, config) => {
    if(config) {
        if(config.onConfirm) {
            if(config.onConfirm.title) {
                return config.onConfirm.title;
            }
        }
    }
    switch (mode) {
        case 'create':
            return "Confirmación de creación";
        case 'edit':
            return "Confirmación de modificación";
        case 'delete':
            return "Confirmación de eliminación";
        case 'invest':
            return "Confirmación de inversión";
        case 'accept':
            return "Estas segura";
    }
}

export const getConfirmationTemplateComponent = (method: 'details' | 'question') => {
    if (method === 'details') {
        return SaveConfirmationTemplateComponent;
    } else if (method === 'question') {
        return
    }
}