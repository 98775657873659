import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmountValidator } from 'src/app/shared/validators//amount.validator';
import { GreaterThanZeroValidator } from 'src/app/shared/validators/greater-than-zero.validators';

@Component({
  selector: 'app-new-observation-dialog',
  templateUrl: './new-observation-dialog.component.html',
  styleUrls: ['./new-observation-dialog.component.scss'],
})
export class NewObservationDialogComponent implements OnInit {
  form: FormGroup;
  currencyOptions = ['pen', 'usd'];
  observations = {
    description: undefined,
    amount: undefined,
    comment: undefined,
    isTaxable: undefined,
    currency: undefined,
    client: undefined,
    invoice: undefined,
  };
  defaultIGV = false;

  constructor(
    public dialogRef: MatDialogRef<NewObservationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      description: ['', [Validators.required]],
      amount: ['', [Validators.required, AmountValidator(1, 2)]],
      comment: [''],
      currency: ['', [Validators.required]],
    });
  }
  setIGV() {
    this.defaultIGV = !this.defaultIGV;
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.observations.description = this.form.value.description;
      this.observations.amount = this.form.value.amount;
      this.observations.comment = this.form.value.comment;
      this.observations.currency = this.form.value.currency;
      this.observations.client = this.data.data.userId;
      this.observations.invoice = this.data.data.invoiceId;
      this.observations.isTaxable = this.defaultIGV;

      this.dialogRef.close(this.observations);
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
