import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-comment-on-confirmation',
  templateUrl: './comment-on-confirmation.component.html',
  styleUrls: ['./comment-on-confirmation.component.scss'],
  // encapsulation: ViewEncapsulation.ShadowDom

})
export class CommentOnConfirmationComponent implements OnInit {
  @Input() entity;

  constructor() { }

  ngOnInit(): void {
  }

}
