import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-amount-item',
  templateUrl: './invoice-amount-item.component.html',
  styleUrls: ['./invoice-amount-item.component.scss']
})
export class InvoiceAmountItemComponent implements OnInit {

  @Input() debtorStats;

  constructor() { }

  ngOnInit(): void {
  }

}
