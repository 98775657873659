import { AbstractControl, ValidatorFn } from '@angular/forms';

export function MustMatchValidator(FormFieldName: string): ValidatorFn {
  return (control: AbstractControl) => {
      
    let isMatching = false;

    if(control.parent) {
        let fieldToMatch = control.parent.get(FormFieldName).value;
        let  thisField = control.value;
    
        isMatching = fieldToMatch ===  thisField;    
    }
  
    if (!isMatching) {
      return { mustMatch: true };
    }
    return null;
  }
}
