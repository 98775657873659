<ng-container [ngSwitch]="name">
    <div class="container" *ngSwitchCase="ACTIONS.APPROVE">
        <span class="action-icon action-approve">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M2 20 L12 28 30 4" />
                </g>
            </svg>
        </span>
    </div>
    <div class="container" *ngSwitchCase="ACTIONS.DISAPPROVE">
        <span class="action-icon action-disapprove">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M2 30 L30 2 M30 30 L2 2" />
                </g>
            </svg>
        </span>
    </div>
    <div class="container" *ngSwitchCase="ACTIONS.EDIT">
        <span class="action-icon show-details">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><g><g><g><path d="M19.661 19a.345.345 0 0 1-.09-.012l-8.323-2.048a.318.318 0 0 1-.236-.213.277.277 0 0 1 .088-.29l6.018-5.349a.368.368 0 0 1 .326-.078.321.321 0 0 1 .239.21l2.304 7.398a.277.277 0 0 1-.086.293.362.362 0 0 1-.24.089zm-7.671-2.502l7.185 1.768-1.989-6.387z"/></g><g><path d="M11.85 18a.322.322 0 0 1-.232-.097L.097 6.383a.327.327 0 0 1 0-.463L5.919.095a.326.326 0 0 1 .463 0L17.903 11.62a.324.324 0 0 1 0 .463l-5.822 5.821a.322.322 0 0 1-.231.097zM.792 6.15L11.85 17.21l5.359-5.359L6.15.792z"/></g><g><path d="M24.673 25H.328A.322.322 0 0 1 0 24.685v-7.617c0-.173.147-.313.328-.313a.32.32 0 0 1 .328.313v7.303h23.69V1.63h-7.607a.32.32 0 0 1-.327-.315A.32.32 0 0 1 16.74 1h7.934c.18 0 .327.14.327.314v23.371a.32.32 0 0 1-.327.315z"/></g></g></g></svg>
        </span>
    </div>
    <div class="container" *ngSwitchCase="ACTIONS.BLOCK">
        <span class="action-icon show-details">
            <!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" version="1.1" viewBox="0 0 270.92 270.92" width="2.7092mm" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><style type="text/css">
            </style></defs><g id="Layer_x0020_1"><path class="fil0" d="M135.46 245.27c-28.39,0 -54.21,-10.93 -73.72,-28.67l154.86 -154.86c17.74,19.51 28.67,45.33 28.67,73.72 0,60.55 -49.26,109.81 -109.81,109.81zm0 -219.62c29.24,0 55.78,11.56 75.47,30.25l-155.02 155.03c-18.7,-19.7 -30.25,-46.23 -30.25,-75.47 0,-60.55 49.26,-109.81 109.8,-109.81zm84.55 27.76c-0.12,-0.16 -0.18,-0.35 -0.33,-0.5 -0.1,-0.09 -0.22,-0.12 -0.32,-0.2 -21.4,-21.7 -51.09,-35.19 -83.9,-35.19 -65.03,0 -117.94,52.91 -117.94,117.94 0,32.81 13.5,62.52 35.2,83.91 0.08,0.09 0.11,0.22 0.2,0.31 0.14,0.14 0.33,0.2 0.49,0.32 21.24,20.63 50.17,33.4 82.05,33.4 65.03,0 117.94,-52.91 117.94,-117.94 0,-31.88 -12.77,-60.8 -33.39,-82.05z"/></g></svg>
        </span>
    </div>
    <div class="container" *ngSwitchCase="ACTIONS.RESEND_CONFIRMATION_EMAIL">
        <span class="action-icon show-details">
            <svg style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Layer_1"><path d="M25,1C18.068,1,11.53,3.993,7,9.126V3H5v10h10v-2H8.042C12.205,5.963,18.405,3,25,3c12.131,0,22,9.869,22,22   s-9.869,22-22,22S3,37.131,3,25c0-2.633,0.46-5.208,1.367-7.652l-1.875-0.696C1.502,19.32,1,22.128,1,25c0,13.233,10.767,24,24,24   s24-10.767,24-24S38.233,1,25,1z"/></g><g/></svg>
        </span>
    </div>
</ng-container>
