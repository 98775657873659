

<div class="payer-and-currency row">
    <div class="payer-container" flex="70">
        <p class="tiny tiny-title">{{title}}</p>
        <p class="payer-name">{{payer | uppercase}}</p>
    </div>
    <div class="currency" flex='30'>
        <app-solid-label width="50" background="blue" [content]="currency"></app-solid-label>
    </div>
</div>