<form [formGroup]="form" (ngSubmit)="submit($event)" novalidate>
    <app-select *ngIf="mode === 'create'" formControlName="type"  [defaultOption]="termSelected?.type" [options]="termsOptions" valueField="value" textField="label" label="Tipo de termino"></app-select>
    <app-input formControlName="title" autocomplete="off"  type="text"  [default]="termSelected?.title" label="Nombre"></app-input>
    <app-input formControlName="description" autocomplete="off"  [default]="termSelected?.description" type="text" label="Descripcion"></app-input>
    <app-upload formControlName="file" [default]="termSelected?.file" initialLabel="Subir Archivo" loadedLabel="Archivo cargado"></app-upload>
    <div class="form-actions">
        <fs-ui-button *ngIf="mode === 'create'" [action]="'submit'" (click)="submit($event)"  [type]="'secondary'" [label]="'Crear Término'"></fs-ui-button>
        <fs-ui-button *ngIf="mode === 'edit'" [action]="'submit'" (click)="submit($event)"  [type]="'secondary'" [label]="'Modificar Término'"></fs-ui-button>
    </div>
</form>
