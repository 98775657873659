import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-amount-confirmation',
  templateUrl: './edit-amount-confirmation.component.html',
  styleUrls: ['./edit-amount-confirmation.component.scss']
})
export class EditAmountConfirmationComponent implements OnInit {

  @Input() entity;
  evaluationDays = '1';
  amountChanges = [];

  columns = ['Fecha de pago', 'Anterior', 'Actual'];

  constructor() {
  }

  ngOnInit(): void {
    this.evaluationDays = this.entity?.newData?.evaluationDays;
    this.amountChanges = this.entity?.originalInvoice?.physicalInvoices.map((inv: any) => {
      const invChanged = this.entity?.newData?.physicalInvoices?.find((i: any) => i.code === inv.code);

      if (invChanged.netAmount !== inv.netAmount) {
        return { code: inv.code, originalAmount: inv.netAmount, newAmount: invChanged.netAmount };
      }
    }).filter((i: any) => i);
  }

  getCushionColor(days) {
    if (!isNaN(days)) {
      if (Number(days) <= 15) {
        return "#FF0000";
      } else if (Number(days) <= 30) {
        return "#FFC400";
      } else {
        return "#12AB64";
      }
    }
  }

}
