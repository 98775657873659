import moment from 'moment';

export const getTodayDateOnly = (gmtOffset?) => {
    const serverTodaysDate = new Date();
    const todaysUTCDate = serverTodaysDate.getTime() + (serverTodaysDate.getTimezoneOffset() * 60000);
    const timeZoneOffset = gmtOffset || -5; //defaults to peru GMT offset

    const todaysDate = new Date(todaysUTCDate + (3600000 * timeZoneOffset));

    const year = todaysDate.getFullYear();
    const month = ('0' + (todaysDate.getMonth() + 1)).slice(-2);
    const day = ('0' + todaysDate.getDate()).slice(-2);

    const onlyDateString = `${year}-${month}-${day}`;

    return onlyDateString;
}

export const getDaysDiffFromToday = (targetDate) => {
    const todaysDate = getTodayDateOnly();
    targetDate = createDateOnly(targetDate)
    return getDaysDiff(todaysDate, targetDate);
}
export const getDaysDiff = (first, second) => {
    const from = moment(second);
    const to = moment(first);

    return from.diff(to, 'days');
}

//Try to not use this function and migrate to createDateOnly2
export function createDateOnly(date) {
    const dateObj = new Date(date)

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);

    const onlyDateString = `${year}-${month}-${day}`;

    return onlyDateString;
}

export function createDateOnly2(date) {
    const dateObj = moment(date);
    return dateObj.format("YYYY-MM-DD");
}