import { createSelector } from '@ngrx/store';
import { reduce, filter } from 'rxjs/operators';
import * as Big from 'big.js';
Big.RM = 2;
Big.DP = 2;

export const financialTransactionsLoaded = createSelector(
    state => state["financialTransactions"],
    (state) => state.loaded
);
export const financialTransactions = createSelector(
    state => state["financialTransactions"],
    (state) => state.financialTransactions
);
export const financialTransactionById = (id) => createSelector(
    financialTransactions,
    (items) => items.filter(transaction => transaction._id === id)[0]
);
export const financialTransactionsByUserId = (userId) => createSelector(
    financialTransactions,
    (items) => {
        if (items) return items.filter(transaction => transaction.user._id === userId)
    }
);

export const savingSuccessStateById = (savingEntityId) => createSelector(
    state => {
        return {
            saving: state['financialTransactions']['saving'],
            savingEntityId: state['financialTransactions']['savingEntityId'],
            savingSucceeded: state['financialTransactions']['savingSucceeded'],
            lastErrorMessage: state['financialTransactions']['lastErrorMessage'],
            lastErrorStatus: state['financialTransactions']['lastErrorStatus']
        }
    },
    (savingState) => {
        if (savingEntityId === savingState.savingEntityId && savingState.saving === false) {
            return {
                savingSucceeded: savingState.savingSucceeded,
                lastErrorMessage: savingState.lastErrorMessage,
                lastErrorStatus: savingState.lastErrorStatus
            }
        }
    }
);

export const minusTransactions = () => createSelector(
    financialTransactions,
    (transactions) => transactions.filter(transaction =>
        (transaction.status === 'approved' || transaction.type === 'investment')
    )
);


export const balanceAvailable = (currency) => createSelector(
    financialTransactions,
    transactions => {
        let balance = 0;

        if (transactions) {
            transactions = transactions.filter(transaction => transaction.currency === currency);

            if (transactions.length > 0) {
                let minus = transactions
                    .filter(transaction =>
                    (
                        (transaction.type === 'withdraw' &&
                            (transaction.status === 'approved' || transaction.status === 'in process')
                        )
                        || (transaction.type === 'retention' &&
                            (transaction.status === 'approved' || transaction.status === 'in process')
                        )
                        || (transaction.type === 'donate' &&
                            (transaction.status === 'approved' || transaction.status === 'in process')
                        )
                        || (
                            transaction.type === 'investment' &&
                            (transaction.status === 'investment start' || transaction.status === 'in progress')
                        )
                    )
                    )
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);


                let minusNewRetentions = transactions
                    .filter(transaction =>
                        transaction.type === 'investment return' && transaction.retention
                    )
                    .map(transaction => Number(transaction?.retention?.amount) || 0)
                    .reduce((a, b) => a + b, 0) || 0;

                let positive = transactions
                    .filter(transaction => (
                        (transaction.type === 'deposit' && transaction.status === 'approved')
                        || (
                            transaction.type === 'investment return' &&
                            (transaction.status === 'approved')
                        )
                    ))
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);


                balance = new Big(positive).minus(minus).minus(minusNewRetentions);
            }
        }

        balance = new Big(balance).toFixed(2);

        //THIS REMOVES THE -VE ZERO WHEN THERE IS 0.0000001 DIFFERENCE
        const result = Number(balance).toFixed(2);
        return result;
    }
);
export const balanceAvailableByUserId = (currency, userId) => createSelector(
    financialTransactionsByUserId(userId),
    transactions => {
        let balance = 0;

        if (transactions) {
            transactions = transactions.filter(transaction => transaction.currency === currency);

            if (transactions.length > 0) {
                let minus = transactions
                    .filter(transaction =>
                    (
                        (transaction.type === 'withdraw' &&
                            (transaction.status === 'approved' || transaction.status === 'in process')
                        )
                        || (transaction.type === 'retention' &&
                            (transaction.status === 'approved' || transaction.status === 'in process')
                        )
                        || (transaction.type === 'donate' &&
                            (transaction.status === 'approved' || transaction.status === 'in process')
                        )
                        || (
                            transaction.type === 'investment' &&
                            (transaction.status === 'investment start' || transaction.status === 'in progress')
                        )
                    )
                    )
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);

                let positive = transactions
                    .filter(transaction => (
                        (transaction.type === 'deposit' && transaction.status === 'approved')
                        || (
                            transaction.type === 'investment return' &&
                            (transaction.status === 'approved')
                        )
                    ))
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);

                balance = new Big(positive).minus(minus);
            }
        }

        balance = new Big(balance).toFixed(2);

        //THIS REMOVES THE -VE ZERO WHEN THERE IS 0.0000001 DIFFERENCE
        const result = Number(balance).toFixed(2);
        return result;
    }
);

export const totalProfit = (currency) => createSelector(
    financialTransactions,
    transactions => {
        let total = 0;

        if (transactions) {
            const totalLegacyRetention = transactions.filter(transaction => transaction.currency === currency && transaction.type === "retention" && !transaction.transaction).
                map(transaction => Number(transaction.amount)).
                reduce((a, b) => a + b, 0);

            transactions = transactions.filter(transaction => transaction.currency === currency);

            if (transactions.length > 0) {
                total = transactions
                    .filter(transaction => (
                        transaction.type === 'investment return' &&
                        (transaction.status === 'approved')
                    )
                    )
                    .map(transaction => transaction.netAmount ? Number(transaction.netAmount) : Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);
            }

            total = total - totalLegacyRetention;

        }


        total = new Big(total).toFixed(2);
        return total;
    }
);

export const grossProfit = (currency) => createSelector(
    financialTransactions,
    transactions => {
        let total = 0;

        if (transactions) {
            transactions = transactions.filter(transaction => transaction.currency === currency);

            if (transactions.length > 0) {
                total = transactions
                    .filter(transaction => (
                        transaction.type === 'investment return' &&
                        (transaction.status === 'approved')
                    )
                    )
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);
            }
        }

        total = new Big(total).toFixed(2);
        return total;

    }
);

export const totalRetention = (currency) => createSelector(
    financialTransactions,
    transactions => {
        let total = 0;

        if (transactions) {
            transactions = transactions.filter(transaction => transaction.currency === currency);

            if (transactions.length > 0) {
                total = transactions
                    .filter(transaction => (
                        transaction.type === 'retention' &&
                        (transaction.status === 'approved')
                    )
                    )
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);
            }
            if (transactions.length > 0) {
                total += transactions
                    .filter(transaction => transaction.retention
                    )
                    .map(transaction => Number(transaction.retention.amount))
                    .reduce((a, b) => a + b, 0);
            }
        }

        total = new Big(total).toFixed(2);
        return total;

    }
);



export const totalInInvestment = (currency) => createSelector(
    financialTransactions,
    transactions => {
        let total = 0;

        if (transactions) {
            transactions = transactions.filter(transaction => transaction.currency === currency);

            if (transactions.length > 0) {
                total = transactions
                    .filter(transaction => (
                        transaction.type === 'investment' && transaction.status === 'in progress')
                    )
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);
            }
        }

        total = new Big(total).toFixed(2);
        return total;

    }
);

export const totalInPending = (currency) => createSelector(
    financialTransactions,
    transactions => {
        let total = 0;

        if (transactions) {
            transactions = transactions.filter(transaction => transaction.currency === currency);

            if (transactions.length > 0) {
                let minus = transactions
                    .filter(transaction =>
                        (transaction.type === 'withdraw' && transaction.status === 'in process')
                        || (transaction.type === 'donate' && transaction.status === 'in process')
                        || (transaction.type === 'retention' && transaction.status === 'in process')
                    )
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);

                let positive = transactions
                    .filter(transaction => (
                        transaction.type === 'deposit' && transaction.status === 'in process')
                    )
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);

                total = new Big(positive).minus(minus);
            }
        }

        total = new Big(total).toFixed(2);
        return total;

    }
);
export const totalInInvestmentStart = (currency) => createSelector(
    financialTransactions,
    transactions => {
        let total = 0;

        if (transactions) {
            transactions = transactions.filter(transaction => transaction.currency === currency);

            if (transactions.length > 0) {
                total = transactions
                    .filter(transaction => (
                        transaction.type === 'investment' && transaction.status === 'investment start')
                    )
                    .map(transaction => Number(transaction.amount))
                    .reduce((a, b) => a + b, 0);
            }
        }

        total = new Big(total).toFixed(2);
        return total;

    }
);
export const investmentTransactions = createSelector(
    financialTransactions,
    transactions => {
        if (transactions) {
            return transactions.filter(transaction => transaction.type === 'investment')
        }
    }
)

export const totalInvestedByInvoiceId = (invoiceId) => createSelector(
    investmentTransactions,
    transactions => {
        if (transactions) {
            let total = 0;

            total = transactions
                .filter(transaction =>
                    transaction?.invoice?._id === invoiceId &&
                    (
                        transaction.status === 'investment start' ||
                        transaction.status === 'in progress' ||
                        transaction.status === 'capital refunded'
                    )
                )
                .map(transaction => Number(transaction.amount))
                .reduce((a, b) => a + b, 0);

            total = new Big(total).toFixed(2);
            return total;
        }
    }
);
export const transactionField = (invoiceId, key, value) => createSelector(
    investmentTransactions,
    transactions => {
        if (transactions) {
            let field;
            field = transactions
                .find(transaction => (
                    transaction?.invoice?._id === invoiceId &&
                    (
                        transaction[key] === value
                    )
                )
                )
            return field;
        }
    }
);
/*

temp1.reduce((c, p) => {

    return Number(c.amount) + Number(p.amount)
})

*/