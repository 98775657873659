import { AbstractControl, ValidatorFn } from '@angular/forms';

//Must find a better way to to resue our validators (the problem was the error message and length in error msg)
//If we improve and centralize implemntation better it will be easieer
export function MinTcemValue(value: Number): ValidatorFn {
    return (control: AbstractControl) => {
  
  
      const isValueValid = control.value >= value;
    
      if (!isValueValid) {
        return { tcemMinValue: true };
      }
      return null;
    }  
}