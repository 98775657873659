<app-page-title title="Nueva inversión"></app-page-title>

<app-available-balance [forUserId]="selectedUserId"></app-available-balance>

<app-financial-transaction-invest-form
    [invoice]="invoice"
    (OnSelectedUserChanged)="selectedUserChanged($event)"
    (onSubmit)="submitHandler($event)">
</app-financial-transaction-invest-form>

<div class="button-container">
    <fs-ui-button routerLink="/invoices/{{invoice._id}}" [label]="'Regresar'" [type]="'tertiary'"></fs-ui-button>
</div>