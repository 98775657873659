import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-motive-dialog",
  templateUrl: "./motive-dialog.component.html",
  styleUrls: ["./motive-dialog.component.scss"],
})
export class MotiveDialogComponent implements OnInit {
  displayInput = false;
  form: FormGroup;
  reason = "";
  reasonOptions;
  depositReasons = [
    {
      text: "No llegaron los fondos.",
      value: "no llegaron los fondos.",
    },
    { text: "Llegó en moneda distinta.", value: "llegó en moneda distinta." },
    {
      text: "Voucher no pertenece al depósito.",
      value: "voucher no pertenece al depósito.",
    },
    {
      text: "Voucher no contiene número de operación.",
      value: "voucher no contiene número de operación.",
    },
    { text: "Otros...", value: "otros" },
  ];

  withdrawalReasons = [
    {
      text: "Cuenta destino no existe.",
      value: "cuenta destino no existe.",
    },
    {
      text: "Titular distinto a usuario.",
      value: "titular distinto a usuario.",
    },
    {
      text: "Número de documento no coincide.",
      value: "número de documento no coincide.",
    },
    {
      text: "Solicitud de cancelación de usuario.",
      value: "solicitud de cancelación de usuario.",
    },
    { text: "Otros...", value: "otros" },
  ];

  invoiceReasons = [
    {
      text: "Denegación Riesgos.",
      value: "denegación Riesgos.",
    },
    {
      text: "Denegación por solicitud de usuario - deudor ya pagó.",
      value: "denegación por solicitud de usuario - deudor ya pagó.",
    },
    {
      text: "Denegación por solicitud de usuario - emisor anuló factura.",
      value: "denegación por solicitud de usuario - emisor anuló factura.",
    },
    {
      text: "Denegación por solicitud de usuario - precio demasiado alto.",
      value: "denegación por solicitud de usuario - precio demasiado alto.",
    },
    {
      text: "Denegación por solicitud de usuario - Disconforme por comisión mínima",
      value: "Denegación por solicitud de usuario - Disconforme por comisión mínima",
    },
    {
      text: "Denegación por pagador.",
      value: "denegación por pagador.",
    },
    {
      text: "Operación menor a 1K PEN/USD.",
      value: "operación menor a 1K PEN/USD.",
    },
    {
      text: "Operación menor a 15 días.",
      value: "operación menor a 15 días.",
    },
    { text: "Otros...", value: "otros" },
  ];

  constructor(
    public dialogRef: MatDialogRef<MotiveDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.fetchData();
    this.form = this.formBuilder.group({
      reason: [""],
      otherReason: [""],
    });
    this.form.get("reason").setValidators([Validators.required]);
    this.checkData();
  }

  changeReason(value) {
    if (value === "otros") {
      this.displayInput = true;
      this.form
        .get("otherReason")
        .setValidators([Validators.minLength(3), Validators.required]);
      this.form.get("otherReason").updateValueAndValidity();
    } else {
      this.form.get("otherReason").setValidators([]);
      this.form.get("otherReason").updateValueAndValidity();
      this.displayInput = false;
    }
    this.reason = value;
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      if (this.reason == "otros") {
        this.reason = this.form.get("otherReason").value;
      }
      this.dialogRef.close({ data: this.reason });
    }
  }

  cancel() {
    this.dialogRef.close({ event: "cancel" });
  }

  checkData() {
    if (this.data.data) {
      let reason = this.reasonOptions.find((re) => re.value == this.data.data);
      if (!reason) {
        let comingData = this.data.data;
        this.reason = "otros";
        this.form.get("otherReason").setValue(comingData);
      } else {
        this.reason = reason.value;
      }
    }
  }
  fetchData() {
    switch (this.data.type) {
      case "deposit":
        this.reasonOptions = this.depositReasons;
        break;
      case "withdraw":
        this.reasonOptions = this.withdrawalReasons;
        break;
      case "invoice":
        this.reasonOptions = this.invoiceReasons;
        break;
      default:
        break;
    }
  }
}
