import {
  Component,
  OnInit,
  Input,
  Self,
  Optional,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
} from '@angular/forms';
import { of, Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.control.html',
  styleUrls: ['./select.control.scss'],
})
export class SelectControl implements ControlValueAccessor, OnInit, OnChanges {
  @Input() label;
  @Input() isDisabled = false;
  @Input() autoComplete = false;
  @Input() options;
  @Input() defaultOption;
  @Input() valueField;
  @Input() textField;
  @Input() hasTooltip = false;
  @Output() OnSelectionChanged = new EventEmitter();
  filteredOptions = new BehaviorSubject<any[]>([]);

  _selectedValue;
  _errorStatus;
  _originalLabel;

  errorMessage;
  parsedOptions;
  parsedOptionsTexts;

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    if (val !== undefined) {
      this.OnSelectionChanged.emit(val);
      this._selectedValue = val;
      this.propagateChange(this._selectedValue);
    }
  }

  constructor(@Optional() @Self() private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.ngControl.control.parent.statusChanges.subscribe((formStatus) => {
      if (
        formStatus === 'INVALID' &&
        this.ngControl.errors &&
        (this.ngControl.dirty || this.ngControl.touched)
      ) {
        this._errorStatus = true;
        this.label = this.getErrorLabel();
      }
    });

    this.ngControl.statusChanges.subscribe((newStatus) => {
      this._errorStatus = newStatus === 'VALID' ? false : true;

      if (this._errorStatus) {
        this.label = this.getErrorLabel();
      } else {
        this.label = this._originalLabel;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this._originalLabel = this.label;

    if (changes.options) {
      this.mapOptions(changes.options.currentValue);

      // if(this.defaultOption) {
      //   this.selectedValue = this.defaultOption;
      // }
    }
  }

  getErrorLabel() {
    if (this.ngControl.hasError('required')) {
      return this._originalLabel + ' (obligatorio)';
    } else {
      return this._originalLabel + ' (inválido)';
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.selectedValue = value;
      this.propagateChange(this.selectedValue);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  selectionChanged(value) {
    this.selectedValue = value;
    this.propagateChange(this.selectedValue);
  }

  mapOptions(optionsList) {
    if (optionsList) {
      this.parsedOptions = optionsList.map((option) => ({
        value: option[this.valueField] || option.value || option,
        text: option[this.textField] || option.text || option,
      }));

      if (this.defaultOption) {
        this.selectedValue = this.defaultOption;
      }
    }
  }

  displayFn(item) {
    /*
    console.log(item)
    //this.selectedValue = item.value;
    this.selectionChanged('test');
    */
    return item && item.text ? item.text : '';
  }

  autoCompleteValueChanged(value) {
    const _filteredOptions = this.parsedOptions.filter((option) => {
      const filterValue = value.toLowerCase();
      return option.text.toLowerCase().indexOf(filterValue) === 0;
    });

    this.filteredOptions.next(_filteredOptions);
  }

  autcompleteFocusOut(value) {
    const isOptionValid = this.parsedOptions.some(
      (option) => option.value === value
    );
    if (!isOptionValid) {
      this.selectionChanged(null);
    }
  }
}
