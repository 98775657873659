<div class="container">
    <h1>Cuentas Bancarias Finsmart</h1>

    <p class="subtitle">Finsmart S.A.C. | RUC: 20603506180</p>
    <div class="disclaimer-container">
        <div>
            <div class="disclaimer-layout">
                <div class="currency-container">
                    <span class="currency-type">PEN</span>
                </div>
                <p class="disclaimer-text ml-8">Cuentas en soles</p>
            </div>
        </div>
    </div>
    <ng-container *ngIf="ourBankAccounts | filterBy: 'currency': 'pen' as banks">
        <div class="item">
            <ng-container *ngFor="let bankAccount of banks">
                    <app-bank-account-item
                    copyAccounts="true"
                    [isHide]="true"
                    [entity]="bankAccount"
                    ></app-bank-account-item>
            </ng-container>
        </div>
    </ng-container>
    

    <div class="disclaimer-container">
        <div>
            <div class="disclaimer-layout">
                <div class="currency-container">
                    <span class="currency-type">USD</span>
                </div>
                <p class="disclaimer-text ml-8">Cuentas en dólares</p>
            </div>
        </div>
    </div>

    <ng-container *ngIf="ourBankAccounts | filterBy: 'currency': 'usd' as banks">
        <div class="item">
            <ng-container *ngFor="let bankAccount of banks">
                    <app-bank-account-item
                    copyAccounts="true"
                    [isHide]="true"
                    [entity]="bankAccount"
                    ></app-bank-account-item>
            </ng-container>
         </div>

    </ng-container>
    
</div>