import { Injectable, EventEmitter } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import * as overlayHelpers from './overlay.helpers';
import { SaveConfirmationTemplateComponent } from '../ui/save-confirmation-template/save-confirmation-template.component';
import { Action, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.states';
import { take, filter } from 'rxjs/operators';
import { SaveResultComponent } from '../ui/save-result/save-result.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericOverlayHostComponent } from '../ui/generic-overlay-host/generic-overlay-host.component';
import { EmptyOverlayHostComponent } from '../ui/empty-overlay-host/empty-overlay-host.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/shared/UI/molecules/confirmation-modal/confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private overlayRef: OverlayRef;
  private nonConfirmationOverlayRef: OverlayRef;
  private activePortalComponentInstance: any;
  private nonConfirmationActivePortalComponentInstance: any;
  onClosechange: EventEmitter<any> = new EventEmitter();
  entity;
  counter = 0;

  constructor(
    private overlay: Overlay,
    private router: Router,
    private store: Store<AppState>,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}

  showComponent(displayComponent, payload?) {
    const result = overlayHelpers.createOverlay(
      GenericOverlayHostComponent,
      this.overlay
    );
    this.nonConfirmationOverlayRef = result.overlayRef;
    this.nonConfirmationActivePortalComponentInstance = result.component;

    this.nonConfirmationActivePortalComponentInstance.displayComponent =
      displayComponent;
    this.nonConfirmationActivePortalComponentInstance.payload = payload;
    this.nonConfirmationActivePortalComponentInstance.overlayRef =
      result.overlayRef;

    this.nonConfirmationActivePortalComponentInstance.onReturn.subscribe(
      (data: any) => {
        if (data == 'Publicadas' || data == 'Por publicar') {
          this.onClosechange.emit('OpportunityDetail');
        }

        if (data == 'En curso' || data == 'Vencidas' || data == 'Cobradas') {
          this.onClosechange.emit('InvestmentDetail');
        }
        //this.nonConfirmationOverlayRef.dispose();
      }
    );
  }

  startConfirmation(
    entity: any,
    entityDisplay: any,
    method: 'details' | 'question' | 'resultOnly',
    mode:
      | 'create'
      | 'edit'
      | 'delete'
      | 'invest'
      | 'editOwn'
      | 'createInvoice'
      | 'createInvoiceNew',
    disclaimer: string,
    saveStoreAction: Action,
    returnUrl: string,
    saveStateSelector,
    resultConfig?,
    callback?,
    width?,
    buttonTitle = 'Confirmar',
    buttonBackTitle = 'Cancelar',
    reloadOnCancel = false,
    isDisabled?: boolean
  ) {
    this.entity = entity;
    if (method === 'resultOnly') {
      const result = overlayHelpers.createOverlay(
        EmptyOverlayHostComponent,
        this.overlay
      );
      this.overlayRef = result.overlayRef;

      this.handleConfirmation(
        saveStoreAction,
        entity,
        mode,
        returnUrl,
        saveStateSelector,
        resultConfig,
        callback
      );
      // this.handleResult(
      //   entity.id,
      //   mode,
      //   returnUrl,
      //   saveStateSelector,
      //   resultConfig,
      //   callback
      // );
    } else {
      const result = overlayHelpers.createOverlay(
        SaveConfirmationTemplateComponent,
        this.overlay
      );
      this.overlayRef = result.overlayRef;
      this.activePortalComponentInstance = result.component;

      const confirmationTitle = overlayHelpers.getConfirmationTitle(
        mode,
        resultConfig
      );

      if (entityDisplay) {
        if (!entityDisplay.component && !entityDisplay.entity) {
          this.activePortalComponentInstance.entityDisplayComponent =
            entityDisplay;
          this.activePortalComponentInstance.entity = entity;
        } else {
          this.activePortalComponentInstance.entityDisplayComponent =
            entityDisplay.component;
          this.activePortalComponentInstance.entity = entityDisplay.entity;
        }
      }

      this.activePortalComponentInstance.method = method;
      this.activePortalComponentInstance.title = confirmationTitle;
      this.activePortalComponentInstance.width = width;
      this.activePortalComponentInstance.buttonTitle = buttonTitle;
      this.activePortalComponentInstance.buttonBackTitle = buttonBackTitle;
      this.activePortalComponentInstance.disclaimer = disclaimer;
      this.activePortalComponentInstance.returnUrl = returnUrl;
      this.activePortalComponentInstance.isDisabled = isDisabled;

      this.activePortalComponentInstance.onConfirmationCancelled.subscribe(
        this.goBackHandle.bind(this, returnUrl, null, reloadOnCancel)
      );
      this.activePortalComponentInstance.onConfirmation.subscribe(
        this.handleConfirmation.bind(
          this,
          saveStoreAction,
          entity,
          mode,
          returnUrl,
          saveStateSelector,
          resultConfig,
          callback
        )
      );
    }
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  startConfirmationModal(
    entity: any,
    entityDisplay: any,
    method: 'details' | 'question' | 'resultOnly',
    mode: 'create' | 'edit' | 'delete' | 'invest' | 'editOwn',
    disclaimer: string,
    saveStoreAction: Action,
    returnUrl: string,
    saveStateSelector,
    resultConfig?,
    callback?,
    width?,
    buttonTitle = 'Confirmar',
    buttonBackTitle = 'Cancelar',
    reloadOnCancel = false
  ) {
    this.entity = entity;
    if (method === 'resultOnly') {
      const result = overlayHelpers.createOverlayModal(
        EmptyOverlayHostComponent,
        this.overlay
      );
      this.overlayRef = result.overlayRef;

      this.handleConfirmationModal(
        saveStoreAction,
        entity,
        mode,
        returnUrl,
        saveStateSelector,
        resultConfig,
        callback
      );
      this.handleResultModal(
        entity.id,
        mode,
        returnUrl,
        saveStateSelector,
        resultConfig,
        callback
      );
    }
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '90vw',
      maxHeight: '100vh',
      height: 'auto',
      id: 'my-dialog-confirmation',
      data: data,
    });

    return dialogRef;
  }
  destroy() {
    this.overlayRef.dispose();
    document.documentElement.style.overflowY = 'scroll';
    document.getElementById('main-container').style.display = '';
  }

  goBackHandle(returnUrl, state, reloadOnCancel) {
    this.destroy();

    if (state && state.savingSucceeded) {
      this.router.navigateByUrl(returnUrl);
    }

    if (reloadOnCancel) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([returnUrl]);
      });
    }

    if (
      this.activePortalComponentInstance?.title === 'Confirmación de inversión'
    ) {
      this.onClosechange.emit('ConfirmInvestmentComponent');
    }

    this.spinner.hide();
    this.emitOnCloseEvent();
  }

  goBackHandleSaveResult(returnUrl, state, reloadOnCancel) {
    this.destroy();

    if (returnUrl) {
      if (state && state.savingSucceeded) {
        this.router.navigateByUrl(returnUrl);
      }
    }

    if (reloadOnCancel) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([returnUrl]);
      });
    }

    this.spinner.hide();
    this.emitOnCloseEvent();
  }

  emitOnCloseEvent() {
    if (
      this.activePortalComponentInstance?.componentRef?.instance?.entity
        ?.observations
    ) {
      this.onClosechange.emit(
        this.activePortalComponentInstance?.componentRef?.instance?.entity
          ?.observations
      );
    } else {
      this.onClosechange.emit();
    }

    // else if (this.activePortalComponentInstance?.title === 'Confirmación de inversión') {
    //   // this.onClosechange.emit("ConfirmInvestmentComponent");
    // }
  }

  getCloseEmitter() {
    return this.onClosechange;
  }

  handleConfirmation(
    saveStoreAction,
    entity,
    mode,
    returnUrl,
    saveStateSelector,
    resultConfig,
    callback
  ) {
    this.spinner.show();

    this.store.dispatch(
      saveStoreAction({
        entity: entity,
        mode: mode,
      })
    );

    this.handleResult(
      entity.id,
      mode,
      returnUrl,
      saveStateSelector,
      resultConfig,
      callback
    );
  }

  handleConfirmationModal(
    saveStoreAction,
    entity,
    mode,
    returnUrl,
    saveStateSelector,
    resultConfig,
    callback
  ) {
    this.spinner.show();

    this.store.dispatch(
      saveStoreAction({
        entity: entity,
        mode: mode,
      })
    );

    this.handleResultModal(
      entity.id,
      mode,
      returnUrl,
      saveStateSelector,
      resultConfig,
      callback
    );
  }

  handleResult(
    entityId,
    mode,
    returnUrl,
    saveStateSelector,
    resultConfig,
    callback
  ) {
    this.store
      .select(saveStateSelector(entityId))
      .pipe(
        filter((success) => success !== undefined),
        take(1)
      )
      .subscribe((state) => {
        const resultPortalCompRef = overlayHelpers.switchPortal(
          SaveResultComponent,
          this.overlayRef
        );
        resultPortalCompRef.instance.success = state;
        resultPortalCompRef.instance.mode = mode;
        resultPortalCompRef.instance.resultConfig = resultConfig;

        if (callback) {
          resultPortalCompRef.instance.onDone.subscribe(
            this.goBackHandleSaveResult.bind(this, returnUrl, state),
            callback(state.savingSucceeded)
          );
        } else {
          resultPortalCompRef.instance.onDone.subscribe(
            this.goBackHandleSaveResult.bind(this, returnUrl, state)
          );
        }

        this.spinner.hide();

        // if (callback) {
        //   callback(state.savingSucceeded);
        // }
      });
  }

  handleResultModal(
    entityId,
    mode,
    returnUrl,
    saveStateSelector,
    resultConfig,
    callback
  ) {
    this.store
      .select(saveStateSelector(entityId))
      .pipe(
        filter((success) => success !== undefined),
        take(1)
      )
      .subscribe((state) => {
        if (state) {
          this.counter += 1;
          if (this.counter === 1) {
            // this.overlayRef.dispose()
            this.destroy();
            let dialogRef = this.openDialogHelper(ConfirmationModalComponent, {
              state,
              mode,
              resultConfig,
            });
            this.spinner.hide();
            this.connectDialogListeners(dialogRef, (result) => {
              if (result === 'closed') {
                dialogRef.close();
                this.counter = 0;
              }
              if (callback) {
                this.counter = 0;
                callback(state.savingSucceeded);
              }
            });
          }
        }
      });
  }
}
