export const DataPointsOperation = [
  {
    name: "netAmount",
    title: "monto neto",
    description: [
      "<p class='body xxs'>Monto que nos transfiere tu cliente en la fecha de pago de la operación. Para llegar a este monto se restan las detracciones, retenciones y otros conceptos distintos al IGV del monto total de cada factura.</p>",
    ],
    formula: [
      "<p class='body xxs  margin-vertically-xl'><span class='medium xxs'>Monto neto</span> = [Monto total] - [Retención y/o detracción]</p>",
    ],
    pagenumber: "9",
    isConfirming: false,
  },
  {
    name: "advanceAmount",
    title: "adelanto",
    description: [
      "<p class='body xxs'>Monto que desembolsamos a tu favor por la operación. <br/> Recuerda que asumes cualquier costo o comisión por la transferencia (por ejemplo: comisión interplaza).</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'>Porcentaje de adelanto:</span> Determina cuánto del monto neto te será adelantado.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Adelanto</span> = [Monto neto] * [% de adelanto]</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'>% de adelanto</span> = 1 - [% de reserva]</p>",
    ],
    pagenumber: "10",
    isConfirming: false,
  },
  {
    name: "reservationAmount",
    title: "garantía",
    description: [
      "<p class='body xxs'>Monto no adelantado que usamos como reserva del pago de la operación. Cuando tu cliente cancele el monto neto, se utiliza para cubrir el costo de factoring de la operación y otro conceptos en caso apliquen.</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'> Porcentaje de reserva:</span> Determina cuánto del monto neto será usado como garantía.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Garantía</span> = [Monto neto] - [Adelanto]</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'> % de reserva</span> = 1 - [% de adelanto]</p>",
    ],
    pagenumber: "10",
    isConfirming: false,
  },
  {
    name: "balanceToPaid",
    title: "saldo a abonar al pago",
    description: [
      "<p class='body xxs'>Saldo de la garantía que te entregamos luego de que tu cliente pague el 100% del monto neto de la operación. Este saldo varía según se descuente el costo de factoring y otros conceptos en caso apliquen (por ejemplo: comisión interplaza o deudas vencidas).</p>",
      "<p class='body xxs margin-vertically-xl'>Recuerda que el costo de factoring aumenta por los días de atraso. </p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Saldo a abonar al pago</span> = [Garantía] - [Costo de Factoring] - [Otros Conceptos]</p>",
    ],
    pagenumber: "",
    isConfirming: false,
  },
  {
    name: "actualDuration",
    title: "plazo estimado",
    description: [
      "<p class='body xxs'>Días transcurridos de la operación desde la fecha de desembolso hasta la fecha esperada o efectiva de pago. Sirve para el cálculo del costo de factoring.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Previo al desembolso</span><br>[Fecha esperada de pago] - [Hoy]</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'>Posterior al desembolso (antes del pago de la factura)</span><br>[Fecha esperada de pago] - [Fecha de desembolso]</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'>Posterior al pago de la factura</span><br>[Fecha efectiva de pago] - [Fecha de desembolso]</p>",
    ],
    pagenumber: "9",
    isConfirming: false,
  },
  {
    name: "advancePaymentDate",
    title: "Fecha de desembolso",
    description: [
      "<p class='body xxs'>Fecha en la que te transferimos el adelanto de la factura.</p>",
    ],
    formula: [],
    pagenumber: "",
    isConfirming: false,
  },
  {
    name: "paymentDate",
    title: "fecha de pago esperada",
    description: [
      "<p class='body xxs'>Fecha en la que se espera que tu cliente nos transfiera el 100% del monto neto. Indicaste esta fecha al momento de subir la operación a nuestra plataforma.</p>",
    ],
    formula: [],
    pagenumber: "10",
    isConfirming: false,
  },
  {
    name: "actualPaymentDate",
    title: "fecha de pago real",
    description: [
      "<p class='body xxs'>Fecha en la que tu cliente nos transfiere el 100% del monto neto a nuestras o las cuentas de Cavali ICLV.</p>",
    ],
    formula: [],
    pagenumber: "",
    isConfirming: false,
  },
  {
    name: "netAmount",
    title: "monto neto",
    description: [
      "<p class='body xxs'>Monto que nos transfieres en la fecha de pago de la operación. Para llegar a este monto se restan las detracciones, retenciones y otros conceptos distintos al IGV del monto total de cada factura.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Monto neto</span> = [Monto total] - [Retención y/o detracción]</p>",
    ],
    pagenumber: "10",
    isConfirming: true,
  },
  {
    name: "amountForSupplier",
    title: "monto a pagar al proveedor",
    description: [
      "<p class='body xxs'>Monto que recibe tu proveedor en la fecha de desembolso de la operación. Es igual al monto neto debido a que el proveedor recibe el 100% en una operación de confirming.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Monto a pagar al proveedor</span> = [Monto neto]</p>",
    ],
    pagenumber: "11",
    isConfirming: true,
  },
  {
    name: "advanceAmount",
    title: "monto de financiamiento",
    description: [
      "<p class='body xxs'>Monto que financian los inversionistas en nuestra plataforma. Se usa para el cálculo del costo de financiamiento.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Monto de financiamiento</span> = [Monto neto] - [Garantía]</p>",
    ],
    pagenumber: "11",
    isConfirming: true,
  },
  {
    name: "reservationAmount",
    title: "garantía",
    description: [
      "<p class='body xxs'>Monto que nos transfieres al inicio de la operación. Lo usamos como reserva del pago de la operación. Cuando canceles el monto neto en la fecha de pago, se utiliza para cubrir el costo de confirming de la operación y otros conceptos en caso apliquen.</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'> Porcentaje de reserva:</span> Determina cuánto del monto neto será requerido como garantía.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Garantía</span> = [Monto neto] - [Monto de financiamiento]</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'> % de reserva</span> = 1 - [% de financiamiento]</p>",
    ],
    pagenumber: "10",
    isConfirming: true,
  },
  {
    name: "balanceToPaid",
    title: "saldo a abonar al pago",
    description: [
      "<p class='body xxs'>Saldo de la garantía que te entregamos luego de que pagues el 100% del monto neto de la operación. Este saldo varía según se descuente el costo de confirming y otros conceptos en caso apliquen (por ejemplo: comisión interplaza o deudas vencidas).</p>",
      "<p class='body xxs margin-vertically-xl''>Recuerda que el costo de confirming aumenta por los días de atraso.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Saldo a abonar al pago</span> = [Garantía] - [Costo de Confirming] - [Otros Conceptos]</p>",
    ],
    pagenumber: "",
    isConfirming: true,
  },
  {
    name: "actualDuration",
    title: "plazo estimado",
    description: [
      "<p class='body xxs'>Días transcurridos de la operación desde la fecha de desembolso hasta la fecha esperada o efectiva de pago. Sirve para el cálculo del costo de confirming.</p>",
    ],
    formula: [
      "<p class='body xxs'><span class='medium xxs'>Previo al desembolso</span><br>[Fecha esperada de pago] - [Hoy]</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'>Posterior al desembolso (antes del pago de la factura)</span><br>[Fecha esperada de pago] - [Fecha de desembolso]</p>",
      "<p class='body xxs margin-vertically-xl''><span class='medium xxs'>Posterior al pago de la factura</span><br>[Fecha efectiva de pago] - [Fecha de desembolso]</p>",
    ],
    pagenumber: "10",
    isConfirming: true,
  },
  {
    name: "advancePaymentDate",
    title: "Fecha de desembolso",
    description: [
      "<p class='body xxs'>Fecha en la que transferimos el monto a pagar al proveedor.</p>",
    ],
    formula: [],
    pagenumber: "11",
    isConfirming: true,
  },
  {
    name: "paymentDate",
    title: "fecha pago esperada",
    description: [
      "<p class='body xxs'>Fecha en la que se espera que nos transfieras el 100% del monto neto. Indicaste esta fecha al momento de subir la factura a nuestra plataforma.</p>",
    ],
    formula: [],
    pagenumber: "10",
    isConfirming: true,
  },
  {
    name: "actualPaymentDate",
    title: "fecha de pago real",
    description: [
      "<p class='body xxs'>Fecha en la que nos transfieres el 100% del monto neto a nuestras o las cuentas de Cavali ICLV.</p>",
    ],
    formula: [],
    pagenumber: "",
    isConfirming: true,
  },
];

export const DataPointsCostDetail = [
    {
      name: "costWithoutIGV",
      description: [
        "<p class='body xxs'>Es el costo total de la operación, sin incluir el IGV, se desglosa en dos conceptos: Costo de financiamiento y Comisión Finsmart.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Costo sin IGV</span> = [Monto neto] * ([Costo mensual de Confirming]*[Duración]/30)</p>",
      ],
      pagenumber: "",
      isConfirming: true,
    },
    {
      name: "costWithoutIGV",
      description: [
        "<p class='body xxs'>Es el costo total de la operación, sin incluir el IGV, se desglosa en dos conceptos: Costo de financiamiento y Comisión Finsmart.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Costo sin IGV</span> = [Monto neto] * ([Costo mensual de Factoring]*[Duración]/30)</p>",
      ],
      pagenumber: "",
      isConfirming: false,
    },
     {
      name: "finsmartCommissionAmount",
      description: [
        "<p class='body xs'>Es la comisión que recibimos por la operación</p><br /><p class='body xxs'>En caso la suma entre Comisión Finsmart + Costo financiamiento es menor a S/ 150.00 o $ 40.00. Se aplicará un ajuste para llegar a ese monto mínimo.</p>",
      ],
      formula: [],
      pagenumber: "",
      isConfirming: false,
    },
    {
      name: "finsmartCommissionAmount",
      description: [
        "<p class='body xxs'>Es la comisión que recibimos por la operación.</p>",
      ],
      formula: [],
      pagenumber: "",
      isConfirming: true,
    },
    {
      name: 'lateFee',
      description: [
        "<p class='body xxs'>Los intereses moratorios se empiezan a calcular desde el primer día de retraso según el siguiente detalle:</p>",
      ],
      formula: [],
      pagenumber: '',
      isConfirming: true,
    },
    {
      name: 'lateFee',
      description: [
        "<p class='body xxs'>Los intereses moratorios se empiezan a calcular desde el primer día de retraso según el siguiente detalle:</p>",
      ],
      formula: [],
      pagenumber: '',
      isConfirming: false,
    },
    {
      name: 'tdmPercentage',
      description: [
        "<p class='body xxs'>Tasa mensualizada que cobra Finsmart por la operación.<br>Única tasa efectiva que resume todos los costos operativos (permite conocer el costo total de la operación sin IGV).</p>",
      ],
      formula: [],
      pagenumber: '9',
      isConfirming: false,
      isAfterDeployment: false,
    },
    {
      name: 'tdmPercentage',
      description: [
        "<p class='body xxs'>El costo mensual de factoring se utiliza para calcular el precio sin IGV de la operación, que es la suma del costo de financiamiento y la comisión de Finsmart. Los intereses moratorios, el ajuste de la comisión de Finsmart y el IGV están excluidos.</p>",
      ],
      formula: [],
      pagenumber: '',
      isConfirming: false,
      isAfterDeployment: true,
    },
    {
      name: 'tdmPercentage',
      description: [
        "<p class='body xxs'>Costo mensual de Confirming es el precio total mensual representado en una tasa, dicha tasa no incluye IGV ni intereses moratorios.</p>",
      ],
      formula: [],
      pagenumber: '',
      isConfirming: true,
      isAfterDeployment: true,
    },
    {
      name: 'investorsReturnAmount',
      description: [
        "<p class='body xxs'>Rentabilidad de los inversionistas al fondear la operación. Generalmente, no está afecto a IGV.</p>",
        // "<p class='body xxs margin-vertically-xl''><span class='medium xxs'>Tasa mensual de financiamiento:</span> Tasa mensual a la que los inversionistas fondean la operación. Esta tasa se define en base a la TCEM, la oferta de facturas, y la demanda de inversionistas, entre otros.</p>"
      ],
      formula: [],
      pagenumber: '',
      isConfirming: false,
    },
    {
      name: 'finsmartRevenueAmount',
      description: [
        "<p class='body xxs'>Es la comisión que recibimos por la operación.</p>",
      ],
      formula: [],
      pagenumber: '',
      isConfirming: false,
    },
    {
      name: ' ',
      description: [
        "<p class='body xxs'>IGV generalmente solo aplicable sobre nuestra comisión.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>IGV</span> = [Comisión Finsmart] * 0.18</p>",
      ],
      pagenumber: '',
      isConfirming: false,
      afterDecember: true,
    },
    {
      name: 'factoringCost',
      description: [
        "<p class='body xxs'>Costo total de la operación de factoring. Si hay demoras en el pago, recuerda que el costo de factoring aumenta dependiendo de los días de atraso.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Costo de Factoring</span> = [Monto neto] * [Plazo (estimado)] * [TCEM/30] + [IGV]</p>",
      ],
      pagenumber: '9',
      isConfirming: false,
      isAfterDeployment: false,
    },
    {
      name: 'factoringCost',
      description: [
        "<p class='body xxs'>Es el precio total de la operación. Si hay demoras en el pago, recuerda que el precio total aumenta dependiendo de los días de retraso.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Precio total</span> = [Costo sin IGV] + [Intereses moratorios] + [IGV]</p>",
      ],
      pagenumber: '',
      isConfirming: false,
      isAfterDeployment: true,
    },
    {
      name: 'commissionAmount',
      description: [
        "<p class='body xxs'>Costo total de la operación excluyendo el IGV.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Comisión</span> = [Monto neto] * [Plazo (estimado) + 1] * [TCEM/30]</p>",
      ],
      pagenumber: '',
      isConfirming: false,
    },
    {
      name: 'commissionAdjustment',
      description: [
        "<p class='body xxs'>Para garantizar la cobertura de los gastos operativos mínimos, la comisión de Finsmart debe alcanzar S/150.00 o $40.00. Si la comisión Finsmart no alcanza estos montos, se realizará un ajuste para alcanzar dicha cifra.</p>",
      ],
      formula: [],
      pagenumber: '',
      isConfirming: false,
    },
    {
      name: 'finsmartRevenueTaxAmount',
      description: [
        "<p class='body xxs'>IGV generalmente solo aplicable sobre nuestra comisión.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>IGV</span> = [Comisión Finsmart] * 0.18</p>",
      ],
      pagenumber: '',
      isConfirming: false,
      afterDecember: true,
      isAfterDeployment: false,
    },
    {
      name: 'finsmartRevenueTaxAmount',
      description: [
        "<p class='body xxs'>IGV se aplica sobre nuestra comisión.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>IGV</span> = [Comisión Finsmart] * 0.18</p>",
      ],
      pagenumber: '',
      isConfirming: false,
      afterDecember: true,
      isAfterDeployment: true,
    },
    {
      name: 'finsmartRevenueTaxAmount_beforeDecember',
      description: ["<p class='body xxs'>IGV aplicable sobre la comisión.</p>"],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>IGV</span> = [Comisión] * 0.18</p>",
      ],
      pagenumber: '',
      isConfirming: false,
      afterDecember: false,
    },
    {
      name: 'salePriceAmount',
      description: [
        "<p class='body xxs'>Costo total de la operación de factoring.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Precio venta </span>= [Comisión] + [IGV]</p>",
      ],
      pagenumber: '',
      isConfirming: false,
    },
    {
      name: 'tdmPercentage',
      description: [
        "<p class='body xxs'>Tasa mensualizada que cobra Finsmart por la operación.<br>Única tasa efectiva que resume todos los costos operativos (permite conocer el costo total de la operación sin IGV).</p>",
      ],
      formula: [],
      pagenumber: '10',
      isConfirming: true,
      isAfterDeployment: false,
    },
    {
      name: 'investorsReturnAmount',
      description: [
        "<p class='body xxs'>Rentabilidad de los inversionistas al fondear la operación. Generalmente, no está afecto a IGV.</p>",
        // "<p class='body xxs margin-vertically-xl''><span class='medium xxs'>Tasa mensual de financiamiento:</span> Tasa mensual a la que los inversionistas fondean la operación. Esta tasa se define en base a la TCEM, la oferta de facturas, y la demanda de inversionistas, entre otros.</p>",
      ],
      formula: [],
      pagenumber: '',
      isConfirming: true,
    },
    {
      name: 'commissionAmount',
      description: [
        "<p class='body xxs'>Costo total de la operación excluyendo el IGV.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Comisión</span> = [Monto neto] * [Plazo (estimado) + 1] * [TCEM/30]</p>",
      ],
      pagenumber: '',
      isConfirming: true,
    },
    {
      name: 'finsmartRevenueAmount',
      description: [
        "<p class='body xxs'>Es la comisión que recibimos por la operación.</p>",
      ],
      formula: [],
      pagenumber: '',
      isConfirming: true,
    },
    {
      name: 'finsmartRevenueTaxAmount',
      description: [
        "<p class='body xxs'>IGV generalmente solo aplicable sobre nuestra comisión.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>IGV</span> = [Comisión Finsmart] * 0.18</p>",
      ],
      pagenumber: '',
      isConfirming: true,
      isAfterDeployment: false,
    },
    {
      name: 'finsmartRevenueTaxAmount',
      description: [
        "<p class='body xxs'>IGV se aplica sobre nuestra comisión.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>IGV</span> = [Comisión Finsmart]*0.18</p>",
      ],
      pagenumber: '',
      isConfirming: true,
      isAfterDeployment: true,
    },
    {
      name: 'finsmartRevenueTaxAmount_beforeDecember',
      description: ["<p class='body xxs'>IGV aplicable sobre la comisión.</p>"],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>IGV</span> = [Comisión] * 0.18</p>",
      ],
      pagenumber: '',
      isConfirming: true,
    },
    {
      name: 'factoringCost',
      description: [
        "<p class='body xxs'>Costo total de la operación de confirming. Si hay demoras en el pago, recuerda que el costo de confirming aumenta dependiendo de los días de atraso.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Costo de Confirming</span> = [Monto neto] * [Plazo (estimado)] * [TCEM/30] + [IGV]</p>",
      ],
      pagenumber: '10',
      isConfirming: true,
      isAfterDeployment: false,
    },
    {
      name: 'salePriceAmount',
      description: [
        "<p class='body xxs'>Costo total de la operación de confirming.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Precio venta </span>= [Comisión] + [IGV]</p>",
      ],
      pagenumber: '',
      isConfirming: true,
    },
    {
      name: 'factoringCost',
      description: [
        "<p class='body xxs'>Es el precio total de la operación. Si hay demoras en el pago, recuerda que el precio total aumenta dependiendo de los días de retraso.</p>",
      ],
      formula: [
        "<p class='body xxs'><span class='medium xxs'>Precio total</span> = [Costo sin IGV] + [Intereses moratorios] + [IGV]</p>",
      ],
      pagenumber: '',
      isConfirming: true,
      isAfterDeployment: true,
    },
];
