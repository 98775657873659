import { AbstractControl, ValidatorFn } from '@angular/forms';

export function GreaterThanZeroValidator(control: AbstractControl) {
  //if(!control.value) return null;

  const value = Number(control.value);
  
  if(0 >= value) {
    return { numberMustBeGreaterThanZero: true };
  }
  return null;
}
