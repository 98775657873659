import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-split-invoice',
  templateUrl: './confirmation-split-invoice.component.html',
  styleUrls: ['./confirmation-split-invoice.component.scss'],
})
export class ConfirmationSplitInvoiceComponent implements OnInit {
  @Input() entity;
  totalAmount = 0.0;
  netAmount = 0.0;
  isWithInvestment = false;
  investmentAmount = 0.0;
  splitSecuence = '';
  initialSecuence;

  constructor() {}

  ngOnInit(): void {
    this.assignValues();
  }

  assignValues() {
    this.netAmount = this.entity?.netAmount;

    this.totalAmount = this.entity?.advanceAmount;

    this.isWithInvestment =
      this.entity?.availableBalanceAmount < this.entity?.advanceAmount;

    this.investmentAmount =
      this.entity?.advanceAmount - this.entity?.availableBalanceAmount;
  }

  calculateNetAmount(amount, isLast) {
    if (isLast) {
      let lastValue = this.netAmount;
      const partitions = this.entity?.partitions;
      for (let index = 0; index < partitions.length - 1; index++) {
        const netAmountPartition =
          ((partitions[index].amount / this.totalAmount) * this.netAmount).toFixed(2);
        lastValue -= Number(netAmountPartition);
      }
      return lastValue.toFixed(2);
    } else {
      return ((amount / this.totalAmount) * this.netAmount).toFixed(2);
    }
  }
}
