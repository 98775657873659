import { Pipe, PipeTransform } from '@angular/core';
// import showdown from 'showdown';

@Pipe({
  name: 'specialFormatting'
})
export class SpecialFormattingPipe implements PipeTransform {
  // converter = new showdown.Converter({ simpleLineBreaks: true, underline: true });

  // transform(value: string): string {
  //   if (value) {
  //     return this.converter.makeHtml(value);
  //   }
  // }

  transform(value: string): string {
    if (value) {
      let html = this.replaceList(value);
      html = this.replaceItalic(html);
      html = this.replaceUnderline(html);
      html = this.replaceBold(html);
      html = this.replaceBreakLine(html);
      return html;
    }
  }


  replaceBold(str) {
    return str.replace(/\*.+?\*/g, (char, index) => {
      return `<span style="font-weight:400">${char.replace(/\*/g, "")}</span>`
    });
  }

  replaceItalic(str) {
    return str.replace(/\/\/.+?\/\//g, (char, index) => {
      return `<span style="font-style: italic;">${char.replace(/\/\//g, "")}</span>`
    });
  }

  replaceUnderline(str) {
    return str.replace(/\_.+?\_/g, (char, index) => {
      return `<span style="text-decoration: underline;">${char.replace(/\_/g, "")}</span>`
    });
  }

  replaceBreakLine(str) {
    return str.replace(/\n/g, "<br>");
  }

  replaceList(str) {
    let words = [];

    let lastIndex;
    str.replace(/\~.+\n[^\~]/g, (char, index) => {
      let init = lastIndex ? str.indexOf("~", lastIndex) : str.indexOf("~");
      let finish = index + char.length - 1;
      words.push(str.substring(init, finish));
      lastIndex = finish;

    });
    
    words.forEach(w => {
      const parsedWord = `<ul>${w.split("\n").slice(0, -1).map(c => `<li>${c.replace("~", "")}</li>`).join("")}</ul>`
      str = str.replace(w, parsedWord);
    })


    if (str.includes("~")) {
     // console.log(str.split("~")[0])
      let init = str.indexOf("~");
      let finish = str.length;

      const word = str.substring(init, finish)
      const parsedWord = `<ul>${word.split("\n").map(c => `<li>${c.replace("~", "")}</li>`).join("")}</ul>`
      str = str.replace(word, parsedWord);
    }

    return str;
  }

}
