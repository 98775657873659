<h5>Monitoreo de Cartera activa</h5>
<div class="code-table matrix-table" *ngIf="evaluations.length > 0">
    <table>
        <tr>
            <th style="width: 30%">Empresa pagadora</th>
            <th style="width: 30%">Sector</th>
            <th style="width: 20%">
                <div class="container">
                    <span class="c-pointer" (click)="sortByCreditLine('usedCreditLine')">Linea utilizada
                        <img *ngIf="active === 'usedCreditLine'" [className]="usedCreditLine ? 'arrow-upward' : 'arrow-downward'" alt="">
                    </span> 
                    
                </div>
            </th>
            <th style="width:20%">
                <div class="container">
                    <span class="c-pointer" (click)="sortByEvaluation('evaluationFinishedAt')">Última evaluación
                        <img *ngIf="active === 'evaluationFinishedAt'"  [className]="evaluationFinishedAt ? 'arrow-upward' : 'arrow-downward'" alt="">
                    </span> 
                   
                </div>
            </th>
            <th></th>
        </tr>
        <ng-container>
            <ng-container *ngFor="let eval of evaluations">

            <tr>
                    <td class="cursor-pointer" (click)="goTo(eval)">
                        <div class="tooltip-container-not-dots">{{eval.debtor?.companyName || '' | limitTo:"20" | uppercase}}
                            <span class="tooltiptext-bottom">RUC: {{ eval.debtor?.companyRuc }}</span>
                        </div>
                    </td>
                    <td class="cursor-pointer" (click)="goTo(eval)">
                        <div class="tooltip-container-not-dots">
                            <span *ngIf="eval.debtor?.sector">{{eval.debtor.sector}}</span>
                            <span *ngIf="!eval.debtor?.sector">---</span>

                            <span class="tooltiptext-bottom">Actividad: {{eval.debtor?.activity ? eval.debtor.activity : '---'}}</span>
                        </div>
                        
                    </td>
                    <td class="cursor-pointer" (click)="goTo(eval)">
                        <span *ngIf="eval.usedCreditLine">{{eval.usedCreditLine | numberSpacing}}</span>
                        <span *ngIf="!eval.usedCreditLine">{{eval.usedCreditLine === 0 ? '0.00' : '---' }}</span>
                    </td>
                    <td class="cursor-pointer" (click)="goTo(eval)">
                        <ng-container>
                            <div *ngIf="!eval.debtor?.isObserve" class="tooltip-container-not-dots">
                                <app-last-evaluation [date]="eval.evaluationFinishedAt" [status]="eval.status"></app-last-evaluation>
        
                                <span class="tooltiptext-bottom">{{formateDate(eval.evaluationFinishedAt)}} <br>
                                Dictamen: <b>{{ eval.status | toSpanish : 'MALE' | capitalizeFirstLetters }}</b></span>
                            </div>

                            <div *ngIf="eval.debtor?.isObserve"  class="tooltip-container-not-dots">
                                <app-last-evaluation [date]="eval.evaluationFinishedAt" [status]="'En observación'"></app-last-evaluation>
        
                                <span class="tooltiptext-bottom">{{formateDate(eval.evaluationFinishedAt)}} <br>
                                <b>En observación</b></span>
                            </div>
                        </ng-container>
                    
                    </td>
                    <td>
                        <div class="actions">
                            <div class="refresh" (click)="posponeEvaluation(eval)">
                                <img src="../../../../../assets/icons/autorenew_black_24dp.svg" alt="refresh icon">
                            </div>
                            <div class="goTo" (click)="goTo(eval)">
                                <img src="../../../../../assets/icons/chevron_right_black_24dp.svg" alt="refresh icon">
                            </div>
                        </div>
                    </td>
                    
                </tr>
            </ng-container>
        </ng-container>
    </table>
</div>