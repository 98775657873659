import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.states';

import {tap, map} from "rxjs/operators";
import {noop, Observable} from "rxjs";
import {Router} from "@angular/router";
import { AuthActions } from '../../state/auth.action-types';
import { isLoggedIn } from '../../state/auth.selectors';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss']
})
export class AuthPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
